import { useRecoilCallback } from 'recoil';
import { DistantConnection } from '@modules/common/types/connections';

import { allDistantConnectionsSelector, allDistconIds, updateConnectionsSelector } from '../store';

export type UndoRedoDistantConnectionsState = {
  connections: DistantConnection[];
};

export const useUndoRedoDistantConnections = () => {
  const getEditState = useRecoilCallback(
    ({ snapshot }) =>
      async (): Promise<UndoRedoDistantConnectionsState> => ({
        connections: await snapshot.getPromise(allDistantConnectionsSelector),
      }),
    [],
  );

  const getCreateState = useRecoilCallback(
    ({ snapshot }) =>
      async (
        ids: string[],
      ): Promise<{
        newState: UndoRedoDistantConnectionsState;
        oldState: UndoRedoDistantConnectionsState;
      }> => {
        const connections = await snapshot.getPromise(allDistantConnectionsSelector);

        return {
          oldState: { connections: connections.filter((item) => !ids.includes(item.id)) },
          newState: { connections },
        };
      },
    [],
  );

  const getDistantConnectionDeleteState = useRecoilCallback(
    ({ snapshot }) =>
      async (
        ids: string[],
      ): Promise<{
        newState: UndoRedoDistantConnectionsState;
        oldState: UndoRedoDistantConnectionsState;
      }> => {
        const connections = await snapshot.getPromise(allDistantConnectionsSelector);

        return {
          newState: { connections: connections.filter((item) => !ids.includes(item.id)) },
          oldState: { connections },
        };
      },
    [],
  );

  const restoreDistantConnectionsState = useRecoilCallback(
    ({ snapshot, set }) =>
      async ({ connections }: UndoRedoDistantConnectionsState) => {
        const existingIds = await snapshot.getPromise(allDistconIds);
        const newIds = connections.map((item) => item.id);
        const idsToRemove = existingIds.filter((item) => !newIds.includes(item));

        set(updateConnectionsSelector, {
          connections,
          connectionIdsToRemove: new Set(idsToRemove),
        });
      },
    [],
  );

  return {
    getCreateState,
    getEditState,
    getDistantConnectionDeleteState,
    restoreDistantConnectionsState,
  };
};
