import { CONTEXT, KEYCODE } from '@recoil/input';

const directSetStateKeysMap = [
  KEYCODE.ENTER,
  KEYCODE.DELETE,
  KEYCODE.BACKSPACE,
  KEYCODE.ESCAPE,
  KEYCODE.W,
  KEYCODE.H,
  KEYCODE.O,
  KEYCODE.P,
  KEYCODE.V,
  KEYCODE.D,
  KEYCODE.S,
  KEYCODE.C,
  KEYCODE.I,
  KEYCODE.L,
  KEYCODE.R,
  KEYCODE.U,
  KEYCODE.F,
  KEYCODE.SPACE,
  KEYCODE.ARROW_LEFT,
  KEYCODE.ARROW_UP,
  KEYCODE.ARROW_RIGHT,
  KEYCODE.ARROW_DOWN,
];

export function keyDown(keyboardEvent, setKeyboardState, context) {
  const {
    code: keyCode,
    ctrlKey: leftOrRightCTRL,
    shiftKey: leftOrRightSHIFT,
    metaKey: leftOrRightCMD,
    repeat: isPressed,
  } = keyboardEvent;

  let isArrowPressed = false;

  switch (keyCode) {
    case KEYCODE.ARROW_LEFT:
    case KEYCODE.ARROW_UP:
    case KEYCODE.ARROW_RIGHT:
    case KEYCODE.ARROW_DOWN:
      isArrowPressed = true;
      break;
    default:
      isArrowPressed = false;
      break;
  }

  if (isPressed) {
    if (isArrowPressed) {
      let debounce = null;
      const debounceDelay = 100;
      if (debounce) clearTimeout(debounce);
      debounce = setTimeout(async () => {
        setKeyboardState(KEYCODE.NONE);
      }, debounceDelay);
    } else {
      return;
    }
  }

  if (leftOrRightSHIFT && (leftOrRightCTRL || leftOrRightCMD)) {
    keyboardEvent.preventDefault();
    switch (keyCode) {
      case KEYCODE.G:
        setKeyboardState(KEYCODE.UNGROUP);
        break;
      default:
        break;
    }
    return;
  }

  if (leftOrRightSHIFT) {
    if (!isArrowPressed) setKeyboardState(KEYCODE.SHIFT);
    switch (keyCode) {
      case KEYCODE.ARROW_LEFT:
        setKeyboardState(KEYCODE.ARROW_LEFT);
        break;
      case KEYCODE.ARROW_UP:
        setKeyboardState(KEYCODE.ARROW_UP);
        break;
      case KEYCODE.ARROW_RIGHT:
        setKeyboardState(KEYCODE.ARROW_RIGHT);
        break;
      case KEYCODE.ARROW_DOWN:
        setKeyboardState(KEYCODE.ARROW_DOWN);
        break;
      case KEYCODE.H:
        setKeyboardState(KEYCODE.SHIFT_H);
        break;
      case KEYCODE.P:
        setKeyboardState(KEYCODE.SHIFT_P);
        break;
      case KEYCODE.R:
        if (!leftOrRightCTRL) setKeyboardState(KEYCODE.SHIFT_R);
        break;
      case KEYCODE.W:
        setKeyboardState(KEYCODE.SHIFT_W);
        break;
      case KEYCODE.C:
        setKeyboardState(KEYCODE.SHIFT_C);
        break;
      default:
        break;
    }
    return;
  }

  if (leftOrRightCTRL || leftOrRightCMD) {
    if (context !== CONTEXT.PROPERTYPANEL) keyboardEvent.preventDefault();

    setKeyboardState(KEYCODE.CONTROL);
    switch (keyCode) {
      case KEYCODE.C:
        setKeyboardState(KEYCODE.COPY);
        break;
      case KEYCODE.D:
        setKeyboardState(KEYCODE.CTRL_D);
        break;
      case KEYCODE.G:
        setKeyboardState(KEYCODE.GROUP);
        break;
      case KEYCODE.S:
        setKeyboardState(KEYCODE.SAVE);
        break;
      case KEYCODE.V:
        setKeyboardState(KEYCODE.PASTE);
        break;
      case KEYCODE.Y:
        setKeyboardState(KEYCODE.REDO);
        break;
      case KEYCODE.Z:
        setKeyboardState(KEYCODE.UNDO);
        break;
      default:
        break;
    }
    return;
  }

  if (directSetStateKeysMap.includes(keyCode)) {
    setKeyboardState(keyCode);
  }
}

export function keyUp(setKeyboardState) {
  setKeyboardState(KEYCODE.NONE);
}
