import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbarStore } from '@/modules/snackbar/store/useSnackbarStore';

export const useVisualizationNotification = () => {
  const { t } = useTranslation(['interface', 'common']);
  const { showSnackbar } = useSnackbarStore();

  const showVikingOpenFileSuccess = useCallback(() => {
    showSnackbar(
      t('visualization.notifications.viking_open_file_success')
    );
  }, [showSnackbar, t]);

  const showVikingOpenFileNotSupported = useCallback(
      (simulationId: string) => {
        showSnackbar(
          t('visualization.notifications.viking_open_file_not_supported', { simulationId })
        );
      },
      [showSnackbar, t],
  );

  const showVikingOpenFileFailed = useCallback(
    (simulationId: string) => {
      showSnackbar(
        t('visualization.notifications.viking_open_file_failed', { simulationId })
      );
    },
    [showSnackbar, t],
  );

  const showVikingLowEventsWarning = useCallback(
    (numberOfEvents: number) => {
      showSnackbar(
        t('visualization.notifications.viking_open_file_warning_low_event', {
          numberOfEvents,
        })
      );
    },
    [showSnackbar, t],
  );

  return { showVikingOpenFileSuccess, showVikingOpenFileNotSupported, showVikingOpenFileFailed, showVikingLowEventsWarning };
};
