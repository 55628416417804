import { selectorFamily } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../common';
import toolButtonState from './toolButtonState';
import { ToolMenuItem } from './types';
import { findDescendantTools } from './helpers';

export const shouldToolMenuItemBeHighlightedSelector = selectorFamily({
  key: 'toolItem/isSelected',
  get:
    (menuItems: ToolMenuItem[]) =>
    ({ get }) => {
      const currentTool = get(toolButtonState);
      return findDescendantTools(menuItems).some((item) => item === currentTool);
    },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
