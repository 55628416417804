import { useCallback } from 'react';
import { useBaseProjectApi } from './useBaseProjectApi';

const createBaseUrl = (projectId: string) => `/v2/${projectId}/floorPlanGroup`;

export const useFloorPlanGroupApi = () => {
  const projectApi = useBaseProjectApi();

  const create = useCallback(
    async (projectId: string, name: string): Promise<FloorPlanGroup> =>
      mapToGroup((await projectApi.post(createBaseUrl(projectId), { name })).data),
    [projectApi],
  );

  const fetch = useCallback(
    async (projectId: string, groupId: string): Promise<FloorPlanGroup> =>
      mapToGroup((await projectApi.get(`${createBaseUrl(projectId)}/${groupId}`)).data),
    [projectApi],
  );

  const fetchAll = useCallback(
    async (projectId: string): Promise<FloorPlanGroup[]> =>
      (await projectApi.get(createBaseUrl(projectId))).data.map(mapToGroup),
    [projectApi],
  );

  const remove = useCallback(
    (projectId: string, id: string) => projectApi.delete(`${createBaseUrl(projectId)}/${id}`),
    [projectApi],
  );

  const duplicate = useCallback(
    async (projectId: string, groupId: string, name: string): Promise<FloorPlanGroup> =>
      mapToGroup(
        (await projectApi.post(`${createBaseUrl(projectId)}/${groupId}/duplicate`, { name })).data,
      ),
    [projectApi],
  );

  return {
    fetch,
    create,
    remove,
    duplicate,
    fetchAll,
  };
};

const mapToGroup = (response: any): FloorPlanGroup => ({
  created: new Date(response.created),
  floorPlanId: response.floorPlanId,
  id: response.id,
  lastUpdated: new Date(response.lastUpdated),
  lastUpdatedBy: response.lastUpdatedBy,
  name: response.name,
  projectId: response.projectId,
  thumbnail: response.thumbnail,
  version: response.version,
});

export type FloorPlanGroup = {
  created: Date;
  floorPlanId: string;
  id: string;
  lastUpdated: Date;
  lastUpdatedBy: string;
  name: string;
  projectId: string;
  thumbnail: string;
  version: number;
};
