import { useRecoilValue } from 'recoil';
import { useCallback } from 'react';
import { Stack, Divider, Box } from '@mui/material';
import { Module } from '@/modules/common/types/navigation';
import navAtom from '@/store/recoil/nav/atom';
import { useNavigation } from '@/modules/common/hooks';
import { TabProps, TabsComponent } from '@/components/PropertiesPanel/Tabs';

import { Gates } from './gate/Gates';
import { Layout } from './layout/Layout';

export const TabsCommissioning = () => {
  const { goToModule } = useNavigation();
  const nav = useRecoilValue(navAtom);

  const onChange = useCallback(
    (nextModule: Module) => {
      goToModule(nextModule);
    },
    [goToModule],
  );

  return (
    <Stack
      sx={{
        overflowY: 'hidden',
      }}
    >
      <TabsComponent tabLabels={tabLabels} onChange={onChange} sx={{ padding: '8px 16px 0' }} />

      <Stack
        divider={<Divider />}
        sx={{
          flex: '1 1 auto',
          overflowY: 'scroll',
          scrollbarWidth: 'none',
          '&:hover': { cursor: 'default' },
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {nav === Module.COMMISSIONING_SETUP && (
          <>
            <Layout />
            <Divider />
          </>
        )}
        {nav === Module.COMMISSIONING_GATE && (
          <>
            <Gates />
            <Divider />
          </>
        )}
        {nav === Module.COMMISSIONING_SPLINE && (
          <>
            <Box sx={{ p: 2 }}>
              <div>Spline content</div>
            </Box>
            <Divider />
          </>
        )}
      </Stack>
    </Stack>
  );
};

const tabLabels: TabProps[] = [
  {
    label: 'Setup',
    trans: 'tabs.commissioning_setup',
    disableTab: false,
    id: Module.COMMISSIONING_SETUP,
  },
  {
    label: 'Gate',
    trans: 'tabs.commissioning_gate',
    disableTab: false,
    id: Module.COMMISSIONING_GATE,
  },
  {
    label: 'Spline',
    trans: 'tabs.commissioning_spline',
    disableTab: false,
    id: Module.COMMISSIONING_SPLINE,
  },
];
