import { Accordion } from '@common/components/Accordion';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DeleteAllConnectionsButton } from './DeleteAllConnectionsButton copy';
import { ExportButton } from './ExportButton';
import { KeepAllConnections } from './KeepAllConnectionsButton';

export default function Tools() {
  const { t } = useTranslation(['interface']);

  return (
    <Accordion
      title={t('interface:3te25.tools.title')}
      titleSx={{ textTransform: 'capitalize' }}
      defaultExpanded
    >
      <Stack direction='column' spacing={1}>
        <Typography>Press key 2 to Delete</Typography>
        <DeleteAllConnectionsButton />
        <Typography>Press key 1 to Keep</Typography>
        <KeepAllConnections />
        <ExportButton />
      </Stack>
    </Accordion>
  );
}
