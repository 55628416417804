import { t } from 'i18next';
import { Reflector, ReflectorType } from '../helpers/types';
import { Vector3 } from 'three';

export const deserialize = (text: string): Reflector[] => {
  const lines = text.trim().split(/\r?\n/);

  if (!text || !lines.length) {
    throw new Error(t('errors:commissioning.reflector_map.validation.empty_file'));
  }

  return lines.slice(1).map(parseLine);
};

const parseLine = (line: string, index: number): Reflector => {
  const elements = line.trim().split(',');
  const lineNumber = index + 1;

  if (elements.length < 7) {
    throw new Error(
      t('errors:commissioning.reflector_map.validation.incorrect_format', { line: lineNumber }),
    );
  }

  return {
    type: validateType(elements, lineNumber),
    position: validateLocation(elements, lineNumber),
    angle: validateAngle(elements, lineNumber),
    id: elements[0].toString(),
  };
};

const validateLocation = (elements: string[], lineNumber: number) =>
  new Vector3(
    validateCoordinate(elements, 1, lineNumber),
    validateCoordinate(elements, 2, lineNumber),
  );

const validateCoordinate = (elements: string[], index: number, line: number) => {
  const value = parseNumber(elements[index]);

  if (!Number.isInteger(value)) {
    throw new Error(
      t('errors:commissioning.reflector_map.validation.coordinate.notANumber', {
        position: index,
        line,
      }),
    );
  }

  if (value < 0) {
    throw new Error(
      t('errors:commissioning.reflector_map.validation.coordinate.notANumber', {
        position: index,
        line,
      }),
    );
  }

  return value;
};

const validateAngle = (elements: string[], line: number): ReflectorType => {
  const elementIndex = 3;
  const angle = parseNumber(elements[elementIndex]);

  if (!Number.isInteger(angle)) {
    throw new Error(
      t('errors:commissioning.reflector_map.validation.angle.notANumber', {
        position: elementIndex + 1,
        line,
      }),
    );
  }

  if (angle < 0) {
    throw new Error(
      t('errors:commissioning.reflector_map.validation.angle.toSmall', {
        position: elementIndex + 1,
        line,
      }),
    );
  }

  if (angle > 359) {
    throw new Error(
      t('errors:commissioning.reflector_map.validation.angle.toBig', {
        position: elementIndex + 1,
        line,
      }),
    );
  }

  return angle;
};

const validateType = (elements: string[], line: number): ReflectorType => {
  const elementIndex = 6;
  const type = ReflectorType[elements[elementIndex]];

  if (!type) {
    throw new Error(
      t('errors:commissioning.reflector_map.validation.type.invalid', {
        position: elementIndex + 1,
        line,
        values: [1, 2, 5],
      }),
    );
  }

  return Number(elements[elementIndex]) as ReflectorType;
};

const parseNumber = (value: string) => (value === '' ? NaN : Number(value));
