import { UserPreferenceName } from '@/modules/userPreferences';
import { useUserPreference } from '@/modules/userPreferences/hooks';
import { Theme } from '@modules/common/types/general';
import { Box, Button, Dialog, Stack, Typography } from '@mui/material';

type Props = {
  yesLabel: string;
  noLabel: string;
  title: string;
  content: string;
  confirmation?: string;

  open: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  onDecline?: () => void;
};

export function ConfirmDialog({
  yesLabel,
  noLabel,
  title,
  content,
  confirmation,
  open,
  onClose,
  onConfirm,
  onDecline,
}: Props) {
  const theme = useUserPreference(UserPreferenceName.GENERAL_THEME);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth='xs'
      className={theme === Theme.DARK ? 'dark' : ''}
      sx={{ '&:hover': { cursor: 'default' } }}
    >
      <Box padding={2} sx={{ border: '1px solid', borderColor: 'shades.light' }}>
        <Typography fontSize={18} fontWeight={500} marginBottom={1}>
          {title}
        </Typography>
        <Typography fontSize={12} fontWeight={300} marginBottom={3}>
          {confirmation ? <>{confirmation} <br/></> : null}
          {content}
        </Typography>
        <Stack direction='row' gap={1} justifyContent='stretch'>
          <Button onClick={onDecline} sx={{ flex: '1 1 49%' }}>
            {noLabel}
          </Button>
          <Button variant='secondary' onClick={onConfirm} sx={{ flex: '1 1 49%' }}>
            {yesLabel}
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
}
