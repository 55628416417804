import { OnChangeHandler, TextInput } from '@common/components/inputs';
import {
  Box,
  SxProps,
  filledInputClasses,
  formControlClasses,
  inputBaseClasses,
  textFieldClasses,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { OverflowTypography } from './OverflowTypography';
import { theme } from '@/modules/common/components/theme';

type Props = {
  value: string;
  error?: boolean;
  helperText?: string;
  editable?: boolean;
  fontSize?: number | string;
  color?: string;
  fontWeight?: number;
  lineHeight?: string;
  sx?: SxProps;
  onClick?: (e) => void;
  onBlur?: () => void;
  onChange?: OnChangeHandler<string>;
  onFocus?: () => void;
  multiline?: boolean;
  maxRowsActive?: number;
  maxRowsPassive?: number;
};

export function PropertiesTitle({
  value,
  error,
  helperText,
  editable = false,
  fontSize = 18,
  color = theme.palette.neutral.darker,
  fontWeight = 600,
  lineHeight = '1.5rem',
  sx,
  onClick,
  onBlur,
  onChange,
  onFocus,
  multiline = false,
  maxRowsActive = 1,
  maxRowsPassive = 1,
}: Props) {
  const [isFocused, setIsFocused] = useState(false);

  const onFocusInner = useCallback(
    (e) => {
      onFocus?.();
      setIsFocused(true);

      const lengthOfInput = e.target.value.length;

      // workaround bug from MUI multiline which sets cursor at the start => set cursor at the end
      if (multiline) e.target.setSelectionRange(lengthOfInput, lengthOfInput);
    },
    [onFocus, multiline],
  );

  const onBlurInner = useCallback(() => {
    onBlur?.();
    setIsFocused(false);
  }, [onBlur]);

  const onClickInner = useCallback(
    (e) => {
      if (!isFocused) setIsFocused(true);
      onClick?.(e);
    },
    [isFocused, onClick],
  );

  const renderTextInput = editable && isFocused;

  return (
    <Box
      sx={{
        ...sx,
        cursor: editable ? 'text' : 'default',

        [`& .${formControlClasses.root}.${textFieldClasses.root} *`]: {
          overflowY: 'auto',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        },
      }}
      onClick={onClickInner}
    >
      {renderTextInput ? (
        <TextInput
          value={value}
          onBlur={onBlurInner}
          onChange={onChange}
          onFocus={onFocusInner}
          autoFocus
          error={error}
          helperText={helperText}
          errorType='floating'
          multiline={multiline}
          maxRows={maxRowsActive}
          sx={{
            backgroundColor: 'transparent',
            width: '100%',
            height: 'auto',

            [`& .${inputBaseClasses.root}:hover`]: {
              backgroundColor: 'transparent',
              borderBottomColor: error ? 'error.main' : 'primary.main',
            },

            [`& .${inputBaseClasses.root}`]: {
              backgroundColor: 'transparent',
              padding: '4px 1px',
              lineHeight,
              height: 'auto',
            },

            [`& .${filledInputClasses.focused}.${inputBaseClasses.focused}`]: {
              backgroundColor: 'primary.light',
              borderColor: 'transparent',
              borderBottomColor: error ? 'none' : 'primary.main',

              [`.${filledInputClasses.input}`]: {
                backgroundColor: 'transparent',
                color,
                height: 'auto',
              },
            },

            [`.${inputBaseClasses.root}.${filledInputClasses.root}`]: {
              borderWidth: '0 0 1px 0',
              borderRadius: '0px',
            },

            [`& .${filledInputClasses.input}`]: {
              fontSize,
              color,
              fontWeight,
            },

            [`& .${filledInputClasses.focused} + .MuiFormHelperText-root`]: {
              backgroundColor: 'error.main',
              color: 'error.light',
              marginTop: 0,
              marginLeft: 0,
              marginRight: 0,
              paddingX: '4px',
              width: 'full',
            },
          }}
        />
      ) : (
        <OverflowTypography
          sx={{
            fontSize,
            color,
            fontWeight,
            lineHeight,
            padding: '4px 1px',
            borderBottom: '1px solid transparent',
            '&:hover': {
              borderBottomColor: editable ? (error ? 'error.main' : 'primary.main') : '',
            },

            overflow: 'hidden',
            textOverflow: 'ellipsis',
            overflowWrap: 'anywhere',
            wordWrap: 'break-word', // fix lacking mobile support
            whiteSpace: 'pre-wrap',
            wordBreak: multiline ? 'break-word' : 'break-all',
            display: '-webkit-box',
            WebkitLineClamp: multiline ? maxRowsPassive : 1,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {value}
        </OverflowTypography>
      )}
    </Box>
  );
}
