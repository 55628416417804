import { Group } from 'react-konva';
import { useRecoilValue } from 'recoil';
import shapeState, { AreaShape } from '@recoil/shape';

import { Gate } from './Gate';

type Props = {
  id: string;
};

export const Gates = ({ id }: Props) => {
  const shape = useRecoilValue(shapeState(id)) as AreaShape;

  if (!shape?.gates?.length) {
    return null;
  }

  return (
    <>
      {!shape.isLoading && (
        <Group listening={false}>
          {shape.gates.map((value) => (
            <Gate key={value.id} gate={value} />
          ))}
        </Group>
      )}
    </>
  );
};
