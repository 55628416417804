import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../common';
import selectedShapesIdsState from './selectedShapesIdsState';

const selectedShapesAmountSelector = selector<number>({
  key: 'selectedShapes/amount',
  get: ({ get }) => {
    const selectedShapeIds = get(selectedShapesIdsState);
    return selectedShapeIds.length;
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default selectedShapesAmountSelector;
