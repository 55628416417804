import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import { Simulation } from '../../helpers/types';
import { simulationsIdsAtom, simulationAtom } from './simulationAtom';

/**
 * Currently loaded simulation group
 */
export const currentGroupSimulationsSelector = selector<Simulation[]>({
  key: 'simulations/group/simulations',
  get: ({ get }) => get(simulationsIdsAtom).map((item) => get(simulationAtom(item))),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
