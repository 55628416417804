import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { PlusIcon } from '@/assets/icons';
import { FloorplanGridItem } from './FloorplanGridItem';
import { isCreatorOpenState, selectedFloorplanIdsState } from './store';
import {theme} from '@modules/common/components/theme';

const containerStyle = {
  marginBottom: '50px',
};

const listItemStyle = {
  width: '100%',
  height: 230,
  borderRadius: 0,
  backgroundColor: theme.palette.others.lighter,
  border: '1px solid',
  borderColor: theme.palette.neutral.grey,
  padding: 0,
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.others.lighter,
    borderColor: theme.palette.primary.main,
  },
};

type Props = {
  floorPlanGroupIds: string[];
};

export const FloorplanGrid = ({ floorPlanGroupIds }: Props) => {
  const { t } = useTranslation();
  const deselectAllFloorplans = useResetRecoilState(selectedFloorplanIdsState);
  const setCreatorIsOpen = useSetRecoilState(isCreatorOpenState);

  const openCreator = useCallback(() => {
    setCreatorIsOpen(true);
  }, [setCreatorIsOpen]);

  const handleBackdropCick = useCallback(() => {
    deselectAllFloorplans();
  }, [deselectAllFloorplans]);

  return (
    <Box
      display='grid'
      gridTemplateColumns='repeat(auto-fill,minmax(300px,1fr))'
      gap={4}
      data-testid='floorplans-list'
      onClick={handleBackdropCick}
      sx={containerStyle}
    >
      <Box>
        <Button onClick={openCreator} sx={listItemStyle} data-testid='create floorplan grid button'>
          <Stack direction='row' justifyContent='center' alignItems='center'>
            <PlusIcon sx={{ width: 25, height: 25, color: theme.palette.neutral.darker }} />
            <Typography fontSize={14} fontWeight={500} sx={{ ml: 1 }}>
              {t('interface:home.new_floorplan', 'New Floorplan')}
            </Typography>
          </Stack>
        </Button>
      </Box>
      {floorPlanGroupIds.length > 0 &&
        floorPlanGroupIds.map((id) => <FloorplanGridItem key={id} id={id} />)}
    </Box>
  );
};
