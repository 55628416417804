import { memo, useEffect, useState } from 'react';
import { Line } from 'react-konva';
import { useRecoilValue } from 'recoil';
import { Vector2 } from 'three';

import { ControlPoint, ShapeType } from '@modules/common/types/shapes';
import { getShapeColor } from '@modules/workspace/helpers/colors';
import { angleBetweenVectors, vectorsAlongHighway } from '@modules/workspace/helpers/shape';
import { enabledVehiclesWidthSelector } from '@/modules/vehicles';
import { SHAPE_TO_CANVAS_SCALE } from '../../workspace/helpers/konva';
import { getFlatPointsArrayFromControlPoints } from '@/modules/shapes/controlPointsShapes/helpers';

const breakUpPoint = (deltaAngle: number, amount: number, index: number): boolean =>
  Math.abs(deltaAngle) > Math.PI / 3 &&
  ((Math.sign(deltaAngle) > 0 && index < amount / 2) ||
    (Math.sign(deltaAngle) < 0 && index >= amount / 2));

export type AngledHighwayRenderProps = {
  points: ControlPoint[];
  width: number;
  margin: number;
  gap: number;
};

const AngledHighwayRendererComponent: React.FC<AngledHighwayRenderProps> = ({
  points,
  width,
  margin,
  gap,
}) => {
  // recoil state
  const vehicleWidth = useRecoilValue(enabledVehiclesWidthSelector);

  // local state
  const [lanes, setLanes] = useState([]);
  const [canvasPoints, setCanvasPoints] = useState<number[]>(null);

  useEffect(() => {
    setCanvasPoints(
      getFlatPointsArrayFromControlPoints(points).map((num) => num * SHAPE_TO_CANVAS_SCALE),
    );
  }, [points]);

  useEffect(() => {
    if (!vehicleWidth) return;

    const outLane = [];

    let amount = 0;
    while (amount * vehicleWidth + (amount - 1) * gap + 2 * margin < width) {
      amount += 1;
    }
    amount -= 1;

    if (amount == 0 || gap < 0 || margin < 0) {
      setLanes([]);
      return;
    }

    // set parameters for the lanes along the highway
    const laneSpace = amount * vehicleWidth + (amount - 1) * gap;
    const offset = vehicleWidth / 2 - laneSpace / 2;
    const stepSize = vehicleWidth + gap;
    const positions = points
      .map((point: ControlPoint) => point.position)
      .map((position: Vector2) =>
        new Vector2(position.x, position.y).multiplyScalar(SHAPE_TO_CANVAS_SCALE),
      );
    const vectors = vectorsAlongHighway(positions);
    const anglesBetweenVectors = vectors.reduce(
      (accumulator: number[], currentValue, currentIndex, array) => {
        if (currentIndex % 2 === 0) {
          accumulator.push(angleBetweenVectors(array[currentIndex + 1], currentValue));
        }
        return accumulator;
      },
      [],
    );
    const adustedAngles = anglesBetweenVectors.map((deltaAngle) => deltaAngle / 2 + Math.PI / 2);
    const middleAngles = adustedAngles.map(
      (adustedAngle, index) =>
        adustedAngle + Math.atan2(vectors[index * 2].y, vectors[index * 2].x),
    );

    // building lanes
    for (let j = 0; j < amount; j++) {
      const lanePoints = [];
      const baseOffset = offset + stepSize * j;

      positions.forEach((position, index) => {
        const { x, y } = position;
        const deltaAngle = anglesBetweenVectors[index];
        const middleAngle = middleAngles[index];

        if (breakUpPoint(deltaAngle, amount, j)) {
          const beforeAngle = middleAngle - deltaAngle / 2 + Math.PI;
          const afterAngle = middleAngle + deltaAngle / 2 + Math.PI;

          lanePoints.push(x + Math.cos(beforeAngle) * -baseOffset);
          lanePoints.push(y + Math.sin(beforeAngle) * -baseOffset);
          lanePoints.push(x + Math.cos(middleAngle) * baseOffset);
          lanePoints.push(y + Math.sin(middleAngle) * baseOffset);
          lanePoints.push(x + Math.cos(afterAngle) * -baseOffset);
          lanePoints.push(y + Math.sin(afterAngle) * -baseOffset);
        } else {
          const pointOffset = baseOffset / Math.sin(adustedAngles[index]);
          lanePoints.push(x + Math.cos(middleAngle) * pointOffset);
          lanePoints.push(y + Math.sin(middleAngle) * pointOffset);
        }
      });

      const lane = {
        id: `lane_${j}`,
        key: `${j}`,
        points: lanePoints,
      };

      outLane.push(lane);
    }

    setLanes(outLane);
  }, [margin, gap, vehicleWidth, width, points]);

  return (
    <>
      {/* highway  */}
      <Line
        points={canvasPoints}
        stroke={getShapeColor(ShapeType.HIGHWAY_ANGLED, true)}
        strokeWidth={width}
        lineCap='butt'
        lineJoin='round'
        listening={false}
      />

      {/* lanes */}
      {lanes &&
        lanes.map((l) => (
          <Line
            key={l.key}
            points={l.points}
            stroke='#0000001A'
            strokeWidth={vehicleWidth}
            lineCap='butt'
            lineJoin='round'
            listening={false}
          />
        ))}
    </>
  );
};

export const AngledHighwayRenderer = memo(AngledHighwayRendererComponent);
