import { Layer } from 'konva/lib/Layer';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { stageRefSelector } from '../../../store/recoil/workspace';
import { TempLayerId } from '../helpers/konva';

export const useActiveArrowCleanUp = () => {
  const stageRef = useRecoilValue(stageRefSelector);

  const cleanUpActiveArrow = useCallback(() => {
    if(!stageRef) return;
    
    const allLayers = stageRef.getLayers();
    const activeArrowLayer = allLayers.find(
      (layer) => layer.id() === TempLayerId.ACTIVE_ARROW_LAYER,
    ) as Layer;

    if (!activeArrowLayer) return;

    activeArrowLayer.destroyChildren();
    activeArrowLayer.destroy();
  }, [stageRef]);

  return cleanUpActiveArrow;
};
