import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../common';
import areasAtom from './atom';

export const deleteAreaIdsSelector = selector<string[]>({
  key: 'area/ids/delete',
  get: () => null,
  set: ({ get, set }, deleteIds: string[]) => {
    const ids = new Set(get(areasAtom));
    deleteIds.forEach((id) => ids.delete(id));
    set(areasAtom, Array.from(ids));
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
