import { SimulationStatus } from './types';

export const SIMULATION_DURATION_MIN = 1;
export const SIMULATION_DURATION_MAX = 24;
export const VEHICLE_MIN = 0;
export const FLOW_MIN = 1;

export const IN_PROGRESS_STATUSES = [
  SimulationStatus.SCHEDULED,
  SimulationStatus.RUNNING,
  SimulationStatus.PREPARING,
];

enum simulationEditErrorCode {
  NAME_TOO_SHORT = 'NAME_TOO_SHORT',
}

export const SIMULATION_EDIT_ERROR = {
  [simulationEditErrorCode.NAME_TOO_SHORT]: {
    code: simulationEditErrorCode.NAME_TOO_SHORT,
    i18nMessageKey: 'errors:simulation.edit.run_name.required',
  },
};
