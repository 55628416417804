import { useRecoilCallback } from 'recoil';
import { getRelatedAndUnrelatedConnectionIds } from '../../common/helpers';
import { allCrossingSelector, removeCrossingsSelector } from '../store';

export const useRemoveCrossings = () => {
  const removeCrossingsByShapes = useRecoilCallback(
    ({ snapshot, set }) =>
      async (shapeIds: string[]) => {
        const { relatedConnections } = getRelatedAndUnrelatedConnectionIds(
          await snapshot.getPromise(allCrossingSelector),
          shapeIds,
        );

        set(
          removeCrossingsSelector,
          relatedConnections.map((item) => item.id),
        );
      },
    [],
  );

  return {
    removeCrossingsByShapes,
  };
};
