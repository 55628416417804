import { SVGProps, memo } from 'react';

function SvgComponent(props: SVGProps<SVGSVGElement>) {
  return (
    <svg  xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='m20.61 21.086-4.34-4.339m-2.636 1.612a.5.5 0 0 0 .827.2l3.806-3.806a.5.5 0 0 0-.2-.827l-5.708-1.9a.5.5 0 0 0-.633.633l1.908 5.7Z'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.2 11V4.5a2 2 0 0 0-2-2h-9a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h9.5'
        strokeLinecap='round'
      />
    </svg>
  );
}

const Memo = memo(SvgComponent);
export default Memo;
