import { ListItemButton, ListItemIcon } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import { memo, useCallback, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ArrowSmallDown, ArrowSmallRight } from '@/assets/icons';
import { LayerNames } from '@/modules/common/types/layers';
import { Label } from '@/modules/layers/components/common/Label';
import { ShapeNameItem } from '@/modules/layers/components/names/ShapeNameItem';
import { useUpdateLayerUserPreference } from '@/modules/layers/hooks';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { useAutoSave } from '@modules/floorplan';
import {
  layerContainsSelectedShapes,
  layersShowSelector,
} from '@modules/layers/store/layersSelector';
import { VisibilityBtn } from '../VisibilityBtn';

type LayerNameItemProps = {
  name: LayerNames;
  label: string;
  elements?: any[];
};

const LayerNameItemComponent = ({ name, label, elements = [] }: LayerNameItemProps) => {
  const { save } = useAutoSave();
  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(false);
  const selected = useRecoilValue(layerContainsSelectedShapes(name));
  const mode = useRecoilValue(modeSelector);
  const [layerIsVisible, setLayerIsVisible] = useRecoilState(layersShowSelector(name));
  const { updateVisibility } = useUpdateLayerUserPreference();

  const toggleShow = useCallback(() => {
    setLayerIsVisible(!layerIsVisible);
    updateVisibility(name, !layerIsVisible);
    if (mode == WorkspaceMode.EDITABLE) {
      save();
    }
  }, [mode, save, name, updateVisibility, setLayerIsVisible, layerIsVisible]);

  const startHover = useCallback(() => {
    setHover(true);
  }, []);

  const endHover = useCallback(() => {
    setHover(false);
  }, []);

  const handleClick = useCallback(() => {
    setOpen((open) => !open);
  }, [setOpen]);

  const containerStyle = useMemo(
    () => ({
      paddingLeft: 2,
      color: !layerIsVisible ? 'neutral.main' : 'neutral.darkest',
      backgroundColor: selected ? 'neutral.grey' : 'shades.light',
      borderColor: selected ? 'neutral.grey' : 'shades.light',

      '&:hover': {
        backgroundColor: selected ? 'neutral.grey' : 'shades.light',
      },
    }),
    [layerIsVisible, selected],
  );

  return (
    <>
      <ListItemButton
        alignItems='center'
        onMouseOver={startHover}
        onMouseLeave={endHover}
        disableRipple
        sx={containerStyle}
      >
        <ListItemIcon
          onClick={handleClick}
          sx={{
            minWidth: (theme) => theme.spacing(3),
            fontSize: 24,
            marginRight: 1,
          }}
        >
          {elements.length > 0 ? open ? <ArrowSmallDown /> : <ArrowSmallRight /> : null}
        </ListItemIcon>
        <Label labelText={label} />

        {(!layerIsVisible || hover) && (
          <VisibilityBtn eyeCrossed={!layerIsVisible} onClick={toggleShow} />
        )}
      </ListItemButton>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding sx={{ marginLeft: 0 }}>
          {elements.map((shape) => (
            <ShapeNameItem key={shape.id} label={shape.name} id={shape.id} />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export const LayerNameItem = memo(LayerNameItemComponent);
