import { useRecoilCallback } from 'recoil';
import { shapeGroupState } from '../../shapeGroups';
import { FlowStopType } from '../types';
import { layoutFlowSelector } from '../store/layout';

export const useGetShapeIdsRelatedToFlowId = () => {
  const getRelatedShapeIds = useRecoilCallback(({ snapshot }) => async (flowId: string): Promise<string[]> => {
    const flow = await snapshot.getPromise(layoutFlowSelector(flowId));

    const shapeIds: string[] = [];
    if (flow.intakeFlowStop) {
      if (flow.intakeFlowStop.type === FlowStopType.AREA) {
        shapeIds.push(flow.intakeFlowStop.id);
      } else if (flow.intakeFlowStop.type === FlowStopType.PROCESS) {
        shapeIds.push(flow.intakeFlowStop.id);
      } else {
        const shapeGroup = await snapshot.getPromise(shapeGroupState(flow.intakeFlowStop.id));
        shapeIds.push(...shapeGroup.children);
      }
    }
    if (flow.deliveryFlowStop) {
      if (flow.deliveryFlowStop.type === FlowStopType.AREA) {
        shapeIds.push(flow.deliveryFlowStop.id);
      } else if (flow.deliveryFlowStop.type === FlowStopType.PROCESS) {
        shapeIds.push(flow.deliveryFlowStop.id);
      } else {
        const shapeGroup = await snapshot.getPromise(shapeGroupState(flow.deliveryFlowStop.id));
        shapeIds.push(...shapeGroup.children);
      }
    }
    return shapeIds;
  });

  return getRelatedShapeIds;
};
