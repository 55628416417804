import { useMemo } from 'react';
import { Rect } from 'react-konva';
import { useRecoilValue } from 'recoil';
import { Vector2 } from 'three';

import { calculateOrientedBoundingBoxExtent } from '@/modules/common/helpers/boundingBox';
import { isArea } from '@/modules/common/helpers/shapes';
import { useColors } from '@/modules/workspace/hooks';
import shapeState, { shapePropertyCanvas } from '@recoil/shape';
import { GateArtifacts } from '@/modules/artefacts';

type Props = {
  gate: GateArtifacts;
};

export const Gate = ({ gate }: Props) => {
  const { station, shapeId, loads, id } = gate;
  const areaProperties = useRecoilValue(shapePropertyCanvas(shapeId));
  const shape = useRecoilValue(shapeState(shapeId));
  const { loadBoxColor, laneColor } = useColors(shapeId);

  const boundingBox = useMemo(
    () => calculateOrientedBoundingBoxExtent(areaProperties),
    [areaProperties],
  );

  const shapeOffset = useMemo(
    () => new Vector2(boundingBox.x, boundingBox.y + boundingBox.height),
    [boundingBox.height, boundingBox.x, boundingBox.y],
  );

  if (!isArea(shape.type) || shape.isTransforming) return null;

  return (
    <>
      <Rect
        x={shapeOffset.x + station.rectangle.centerX / 10}
        y={shapeOffset.y - station.rectangle.centerY / 10}
        width={station.rectangle.length / 10}
        height={station.rectangle.width / 10}
        offsetX={station.rectangle.length / 20}
        offsetY={station.rectangle.width / 20}
        rotation={-station.rectangle.angleInDegrees}
        strokeEnabled={false}
        fill={laneColor}
        listening={false}
        shadowForStrokeEnabled={false}
        hitStrokeWidth={0}
      />
      {loads.map((load) => (
        <Rect
          x={shapeOffset.x + load.rectangle.centerX / 10}
          y={shapeOffset.y - load.rectangle.centerY / 10}
          width={load.rectangle.length / 10}
          height={load.rectangle.width / 10}
          offsetX={load.rectangle.length / 20}
          offsetY={load.rectangle.width / 20}
          rotation={-load.rectangle.angleInDegrees}
          strokeEnabled={false}
          fill={loadBoxColor}
          listening={false}
          shadowForStrokeEnabled={false}
          hitStrokeWidth={0}
          key={`${id}_${load.loadPositionName}`}
        />
      ))}
    </>
  );
};
