import { SxProps, TextField, Theme } from '@mui/material';
import { InputProps } from '@mui/material/Input/Input';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useChangeEvent } from '../hooks/useChangeEvent';
import { useEnterBlur } from '../hooks/useEnterBlur';
import { OnChangeHandler } from '../types';
import { StyledTooltip } from '@/modules/common/components/StyledTooltip';
import { useTranslation } from 'react-i18next';

const escapeSpecialRegexCharsRegex = /[.*+?^${}()|[\]\\]/gm;
const BANNED_TEXT_INPUT_CHARS_DEFAULT = '\\/|:;,?"<>*+(){}[]';

type Props = {
  ariaLabel?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  error?: boolean;
  errorType?: 'normal' | 'floating';
  helperText?: string;
  inputProps?: Partial<InputProps>;
  name?: string;
  onBlur?: () => void;
  onChange?: OnChangeHandler<string>;
  onFocus?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  placeholder?: string;
  sx?: SxProps<Theme>;
  value?: string;
  multiline?: boolean;
  maxRows?: number;
  bannedCharacters?: string;
};

export function TextInput({
  ariaLabel,
  disabled = false,
  autoFocus = false,
  errorType = 'normal',
  name,
  placeholder,
  inputProps,
  sx,
  value,
  onBlur,
  onChange,
  onFocus,
  error,
  helperText,
  multiline = false,
  maxRows,
  bannedCharacters = BANNED_TEXT_INPUT_CHARS_DEFAULT,
}: Props) {
  const { t } = useTranslation();
  const [showInvalidCharMsg, setShowInvalidCharMsg] = useState(false);
  const ref = useRef<HTMLInputElement>();
  const { onChangeInner, innerValue, onBlurInner, innerError } = useChangeEvent(
    value,
    onBlur,
    onChange,
    ref,
    multiline,
  );
  const { onKeyUp } = useEnterBlur(ref);

  const forbiddenCharRegex = useMemo(() => {
    const escapedCharString = bannedCharacters.replace(escapeSpecialRegexCharsRegex, '\\$&');
    return new RegExp(`[${escapedCharString}]`, 'gm');
  }, [bannedCharacters]);

  const handleFocus = useCallback(
    (evt) => {
      evt.target.select();
      if (onFocus) {
        onFocus(evt);
      }
    },
    [onFocus],
  );

  return (
    <StyledTooltip
      placement='bottom'
      title={t('common:invalid_characters', { charactersStr: bannedCharacters })}
      forceState={showInvalidCharMsg ? 'open' : 'close'}
    >
      <TextField
        inputRef={ref}
        autoFocus={autoFocus}
        disabled={disabled}
        error={!!innerError || error}
        helperText={helperText || innerError}
        name={name}
        onBlur={() => {
          onBlurInner();
          setShowInvalidCharMsg(false);
        }}
        onChange={(e) => {
          let newValue = e.target.value;
          const containsForbiddenChar = forbiddenCharRegex.test(newValue);
          if (containsForbiddenChar) {
            setShowInvalidCharMsg(true);
            onChangeInner(newValue.replace(forbiddenCharRegex, ''));
            return;
          }

          setShowInvalidCharMsg(false);
          return onChangeInner(e.target.value);
        }}
        onFocus={handleFocus}
        placeholder={placeholder}
        onKeyUp={onKeyUp}
        sx={sx}
        value={innerValue}
        multiline={multiline}
        maxRows={maxRows}
        variant='filled'
        InputProps={{
          ...inputProps,
          inputProps: {
            'aria-label': ariaLabel,
          },
          disableUnderline: true,
        }}
        FormHelperTextProps={{
          className: errorType === 'floating' ? 'Mui-error-floating' : '',
        }}
      />
    </StyledTooltip>
  );
}
