import { selectorFamily } from "recoil";
import { RECOIL_SELECTOR_CACHE_POLICY } from "../../../../store/recoil/common";
import { selectedFloorplanIdsState } from "../state";

export const isFloorplanSelected = selectorFamily<boolean, string>({
  key: 'floorplanAdmin/floorplans/id/isSelected',
  get:
    (id: string) =>
    ({ get }) =>
      get(selectedFloorplanIdsState).has(id),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
