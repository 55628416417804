import { Divider, Stack } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { FlowsPanel } from '@/modules/flows/simulation';
import { existsSelector, useSimulationCallbacks } from '@modules/simulation';
import { Buttons } from './components/Buttons';
import { Header } from './components/Header';
import { TimeForm } from './components/TimeForm';
import { VehicleTypes } from './components/VehicleTypes';
import { ExperimentalSettings } from '../ExperimentalSettings';

export const EditPanel = () => {
  const { loadDetails } = useSimulationCallbacks();
  const currentSimulationExists = useRecoilValue(existsSelector);

  const load = useCallback(() => loadDetails(), [loadDetails]);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!currentSimulationExists) {
    return null;
  }

  return (
    <Stack gap={1} divider={<Divider />}>
      <Header />
      <TimeForm />
      <VehicleTypes />
      <FlowsPanel />
      <ExperimentalSettings />
      <Buttons />
    </Stack>
  );
};
