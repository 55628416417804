import { devtools } from 'zustand/middleware';
import { getRecoilPromise } from 'recoil-nexus';
import { Box3, Vector3 } from 'three';
import { create } from 'zustand';
import { CircleElement, RectElement } from '@thive/canvas';

import { GateArtifacts, groupByGate, parseGateId } from '@/modules/artefacts';
import { download } from '@/modules/common/helpers/browser';
import { isAngledHighwayShape, isHighwayShape } from '@/modules/common/types/guards';
import { groupNameSelector, projectNameSelector } from '@/modules/floorplan';
import { displayVersionSelector } from '@/modules/floorplan/store/floorPlan';
import { GeneratedFloorPlanArtefacts } from '@/modules/floorplanService';
import { allShapesSelector } from '@/store/recoil/shapes';
import { useCanvasStore } from '@modules/canvas';
import { mod } from '@/modules/common/helpers/math';
import { Result } from '@/modules/common/types/general';
import { DEVTOOLS_OPTIONS } from '@/modules/debug/constants/zustand';

import { useLayoutStore } from './useLayoutStore';
import { END_POINT_ID_SUFFIX } from '../helpers/constants';
import { Gate } from '../helpers/types';
import { createExcelTemplate, readExcelFile } from '../import-export/gates';

type GateState = {
  adjustments: Map<string, Vector3>;
  currentAdjustmentError: { x: boolean; y: boolean; z: boolean };
  currentAdjustment: Vector3;
  currentAngle: number;
  currentId: string;
  currentPosition: Vector3;
  currentShapeName: string;
  currentEndpointName: string;
  editableGates: GateArtifacts[];
  gateDict: Map<string, GateArtifacts>;
};

type GateActions = {
  adjustX(value: number): void;
  adjustY(value: number): void;
  adjustZ(value: number): void;
  applyCurrentAdjustment(): { prevAdjustment: Vector3; updatedAdjustment: Vector3 };
  downloadExcelTemplate(): Promise<void>;
  initialize(artifacts: GeneratedFloorPlanArtefacts, savedGates: Gate[]): Promise<void>;
  resetCurrentAdjustment(): void;
  reset(): void;
  selectGate(id: string): void;
  updateCurrentPosition(): void;
  uploadExcel(file: File): Promise<Result<any>>;
  updateAdjustment(id: string, adjustment: Vector3): void;
  validateCurrentAdjustment(): void;
};

const INITIAL_STATE: GateState = {
  adjustments: new Map(),
  currentAdjustmentError: {
    x: null,
    y: null,
    z: null,
  },
  currentAngle: 0,
  currentPosition: new Vector3(),
  currentAdjustment: new Vector3(),
  editableGates: [],
  gateDict: new Map(),
  currentId: null,
  currentShapeName: null,
  currentEndpointName: null,
};

export const useGateStore = create<GateState & GateActions>()(
  devtools(
    (set, get) => ({
      ...INITIAL_STATE,

      adjustX(value) {
        set(
          {
            currentAdjustment: get().currentAdjustment.clone().setX(value),
          },
          undefined,
          {
            type: 'adjustX',
          },
        );
        get().validateCurrentAdjustment();
      },

      adjustY(value) {
        set(
          {
            currentAdjustment: get().currentAdjustment.clone().setY(value),
          },
          undefined,
          {
            type: 'adjustY',
          },
        );
        get().validateCurrentAdjustment();
      },

      adjustZ(value) {
        set(
          {
            currentAdjustment: get().currentAdjustment.clone().setZ(value),
          },
          undefined,
          {
            type: 'adjustZ',
          },
        );
        get().validateCurrentAdjustment();
      },

      applyCurrentAdjustment() {
        const { currentId: selectedId } = get();

        if (!selectedId) {
          return;
        }

        const { currentAdjustment } = get();
        const prevAdjustment = get().adjustments.get(selectedId) ?? new Vector3();
        const updatedAdjustment = currentAdjustment.clone().add(prevAdjustment);

        get().updateAdjustment(selectedId, updatedAdjustment);
        get().resetCurrentAdjustment();
        get().updateCurrentPosition();

        return { prevAdjustment, updatedAdjustment };
      },

      async downloadExcelTemplate() {
        const projectName = await getRecoilPromise(projectNameSelector);
        const floorplanName = await getRecoilPromise(groupNameSelector);
        const version = await getRecoilPromise(displayVersionSelector);
        const shapes = await getRecoilPromise(allShapesSelector);
        const layoutDelta = useLayoutStore.getState().delta;
        const blob = await createExcelTemplate(
          projectName,
          floorplanName,
          version,
          get().editableGates,
          get().adjustments,
          shapes,
          layoutDelta,
        );

        download(blob, `Stations-${floorplanName}-v${version}.xlsx`);
      },

      async initialize(artefacts, savedGates) {
        const adjustments = new Map<string, Vector3>();
        const gateDict = groupByGate(artefacts);
        const shapes = await getRecoilPromise(allShapesSelector);

        savedGates.forEach((item) => {
          if (!gateDict.has(item.id)) {
            return;
          }

          const canvas = useCanvasStore.getState().instance;
          const element = canvas.getElement(item.id) as RectElement;

          adjustments.set(item.id, item.delta);
          canvas.updateTransformation(item.id, {
            position: element.position.clone().add(item.delta),
          });
        });

        const shapeDict = new Map(shapes.map((item) => [item.id, item]));
        const editableGates = Array.from(gateDict.values())
          .filter((item) => {
            const shape = shapeDict.get(item.shapeId);
            return !isHighwayShape(shape) && !isAngledHighwayShape(shape);
          })
          .sort((a, b) => a.id.localeCompare(b.id));

        set(
          {
            adjustments,
            gateDict,
            editableGates,
          },
          undefined,
          {
            type: 'initialize',
          },
        );
      },

      resetCurrentAdjustment() {
        // set({

        // });
        set(
          {
            currentAdjustment: new Vector3(),
            currentAdjustmentError: {
              x: null,
              y: null,
              z: null,
            },
          },
          undefined,
          {
            type: 'resetCurrentAdjustment',
          },
        );
      },

      reset() {
        set(INITIAL_STATE, undefined, {
          type: 'reset',
        });
      },

      selectGate(id: string) {
        set(
          {
            currentId: get().gateDict.has(id) ? id : null,
          },
          undefined,
          {
            type: 'selectGate',
          },
        );
        get().updateCurrentPosition();
      },

      updateCurrentPosition() {
        const id = get().currentId;
        if (!id) {
          return;
        }

        const { gateDict } = get();
        const pointId = gateDict.get(id).point.locationName;
        const canvas = useCanvasStore.getState().instance;

        const gateElement = canvas.getElement(id) as RectElement;
        const pointElement = canvas.getElement(`${pointId}${END_POINT_ID_SUFFIX}`) as CircleElement;

        const { endpointName, shapeName } = parseGateId(id);

        set(
          {
            currentShapeName: shapeName,
            currentEndpointName: endpointName,
            currentPosition: pointElement.center.clone().floor().setZ(0),
            currentAngle: mod(Math.round(gateElement.rotation.z), 360), // TODO fix in canvas
          },
          undefined,
          {
            type: 'updateCurrentPosition',
          },
        );
      },

      updateAdjustment(id: string, adjustment: Vector3) {
        const canvas = useCanvasStore.getState()?.instance;
        if (!canvas) {
          return;
        }

        const prevAdjustment = get().adjustments.get(id) ?? new Vector3();
        const diff = adjustment.clone().sub(prevAdjustment);

        const newAdjustments = new Map(get().adjustments);
        if (adjustment.equals(new Vector3())) {
          newAdjustments.delete(id);
        } else {
          newAdjustments.set(id, adjustment.clone());
        }
        set(
          {
            adjustments: newAdjustments,
          },
          undefined,
          {
            type: 'updateAdjustment',
          },
        );

        const gateElement = canvas.getElement(id) as RectElement;
        const newPos = gateElement.position.clone().add(diff);
        canvas.updateTransformation(id, { position: newPos });
      },

      async uploadExcel(file) {
        const result = await readExcelFile(file);
        const layoutDelta = useLayoutStore.getState().delta;

        if (result.status === 'error') {
          return result;
        }

        const gates = get().gateDict;
        const excelRowsDict = new Map(
          result.value.map((item) => [`${item.shapeId}_${item.endPoint}`, item]),
        );

        gates.forEach((gate, id) => {
          // TODO endpoint name to gateDict / remove parseGateId
          const { endpointName } = parseGateId(id);
          const rowId = `${gate.shapeId}_${endpointName}`;
          const excelRow = excelRowsDict.get(rowId);
          if (!excelRow) {
            return;
          }

          // TODO vector
          const totalX = excelRow.x + excelRow.dX - layoutDelta.x;
          const totalY = excelRow.y + excelRow.dY - layoutDelta.y;
          const adjustment = get().adjustments.get(id) ?? new Vector3();
          // TODO gate.point to vector
          const newAdjustment = adjustment
            .clone()
            .set(totalX - gate.point.x, totalY - gate.point.y, 0);

          get().updateAdjustment(id, newAdjustment);
        });

        return result;
      },

      validateCurrentAdjustment() {
        const id = get().currentId;
        const { instance } = useCanvasStore.getState();
        const { gateDict } = get();
        const gateElement = instance.getElement(id) as RectElement;
        const shapeElement = instance.getElement(gateDict.get(id).shapeId) as RectElement;
        const adjustment = get().currentAdjustment;

        set(
          {
            currentAdjustmentError: isGateOutsideShape(shapeElement, gateElement, adjustment),
          },
          undefined,
          {
            type: 'validateCurrentAdjustment',
          },
        );
      },
    }),
    { store: 'commissioning/gateStore', ...DEVTOOLS_OPTIONS },
  ),
);

const isGateOutsideShape = (
  shapeElement: RectElement,
  gateElement: RectElement,
  gateDelta: Vector3,
) => {
  let gatePosition = gateElement.position.clone();

  if (gateDelta) {
    gatePosition.add(gateDelta);
  }

  const shapeBB = getShapeBoundingBox(shapeElement);

  return {
    x: gatePosition.x < shapeBB.min.x || gatePosition.x > shapeBB.max.x,
    y: gatePosition.y < shapeBB.min.y || gatePosition.y > shapeBB.max.y,
    z: false,
  };
};

const getShapeBoundingBox = (shapeElement: RectElement) => {
  const halves = shapeElement.size.clone().divideScalar(2);

  return new Box3(
    shapeElement.position.clone().sub(halves),
    shapeElement.position.clone().add(halves),
  );
};
