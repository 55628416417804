import { FlowStopType, LayoutFlow } from '@/modules/flows/types';
import { selectedGroupIdsState, shapeGroupState } from '@modules/shapeGroups';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import shapeAtom from '@recoil/shape';
import { selector, selectorFamily } from 'recoil';
import { selectedShapesIdsState } from '../../../../store/recoil/shapes/selected';
import { allFlowIds, flow } from './atom';

export const allFlowIdsAmountSelector = selector({
  key: 'flow/layout/allFlowIds/amount',
  get: ({ get }) => get(allFlowIds).length,
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const flowSelector = selectorFamily<LayoutFlow, string>({
  key: 'flow/layout/flowSelector',
  get:
    (id: string) =>
    ({ get }) => {
      const currentFlow = get(flow(id));

      let sourceName = '';
      let targetName = '';

      if (currentFlow.intakeFlowStop?.type === FlowStopType.AREA_GROUP)
        sourceName = get(shapeGroupState(currentFlow.intakeFlowStop.id))?.name;
      else if (currentFlow.intakeFlowStop?.type === FlowStopType.AREA) {
        sourceName = get(shapeAtom(currentFlow.intakeFlowStop.id))?.name;
      }
      else if (currentFlow.intakeFlowStop?.type === FlowStopType.PROCESS) {
        sourceName = `${get(shapeAtom(currentFlow.intakeFlowStop.id))?.name}.TwoStationProcessing.`;
      }

      if (currentFlow.deliveryFlowStop?.type === FlowStopType.AREA_GROUP)
        targetName = get(shapeGroupState(currentFlow.deliveryFlowStop.id))?.name;
      else if (currentFlow.deliveryFlowStop?.type === FlowStopType.AREA) {
        targetName = get(shapeAtom(currentFlow.deliveryFlowStop.id))?.name;
      }
      else if (currentFlow.deliveryFlowStop?.type === FlowStopType.PROCESS) {
        targetName = `${get(shapeAtom(currentFlow.deliveryFlowStop.id))?.name}.TwoStationProcessing.`;
      }

      return {
        ...currentFlow,
        sourceName,
        targetName,
      };
    },
  set:
    (id: string) =>
    ({ set }, newValue: any) => {
      set(flow(id), newValue);
    },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const existingFlowsIdsSelector = selector<string[]>({
  key: 'flow/layout/existingFlowIdsSelector',
  get: ({ get }) => get(layoutFlowsSelector).map((item) => item.id),
  set: ({ set }, ids: string[]) => {
    set(allFlowIds, ids);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const layoutFlowsSelector = selector<LayoutFlow[]>({
  key: 'flow/layout/flows',
  get: ({ get }) => {
    const out = get(allFlowIds).map((id) => get(flowSelector(id)));
    return out;
  },
  set: ({ set }, flows: any) => {
    const newFlowIds = flows.map((flow: any) => flow.id);
    // eslint-disable-next-line no-restricted-syntax
    for (const flow of flows) {
      set(flowSelector(flow.id), flow);
    }
    set(existingFlowsIdsSelector, newFlowIds);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const highlightFlowSelector = selectorFamily({
  key: 'flow/layout/highlightFlowSelector',
  get:
    (flowId: string) =>
    ({ get }) => {
      const { intakeFlowStop, deliveryFlowStop } = get(flow(flowId));
      if (!intakeFlowStop || !deliveryFlowStop) return false;

      const selectedShapeIds = get(selectedShapesIdsState);
      const selectedGroupIds = get(selectedGroupIdsState);

      // check if either flow start or flow stop is selected
      if (
        (intakeFlowStop.type === FlowStopType.AREA &&
          selectedShapeIds.includes(intakeFlowStop.id)) ||
        (intakeFlowStop.type === FlowStopType.AREA_GROUP &&
          selectedGroupIds.includes(intakeFlowStop.id)) ||
        (deliveryFlowStop.type === FlowStopType.AREA &&
          selectedShapeIds.includes(deliveryFlowStop.id)) ||
        (deliveryFlowStop.type === FlowStopType.AREA_GROUP &&
          selectedGroupIds.includes(deliveryFlowStop.id))
      ) {
        return true;
      }

      return false;
    },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
