import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { WorkspaceMode } from '@modules/common/types/general';

import { workspaceAtom } from './workspaceAtom';

export const modeSelector = selector<WorkspaceMode>({
  key: 'common/workspace/mode',
  get: ({ get }) => get(workspaceAtom).mode,
  set: ({ get, set }, mode: WorkspaceMode) => {
    set(workspaceAtom, { ...get(workspaceAtom), mode });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
