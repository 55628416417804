import { memo } from 'react';
import { useRecoilValue } from 'recoil';
import { Group } from 'react-konva';
import { LayerNames } from '@modules/common/types/layers';
import { layersLockSelector, layersShowSelector } from '@/modules/layers';

import { allDistconIds } from '../store';
import { DistantConnectionLine } from './DistconLine';

type Props = {
  saveFloorPlan: () => Promise<void>;
};

const DistantConnectionsGroupComponent = ({ saveFloorPlan }: Props) => {
  const show = useRecoilValue(layersShowSelector(LayerNames.CONNECTIONS));
  const locked = useRecoilValue(layersLockSelector(LayerNames.CONNECTIONS));
  const ids = useRecoilValue(allDistconIds);

  if (!show || !ids?.length) return null;

  return (
    <Group listening={!locked}>
      {ids.map((id: string) => (
        <DistantConnectionLine id={id} key={id} saveFloorPlan={saveFloorPlan} />
      ))}
    </Group>
  );
};

export const DistantConnectionsGroup = memo(DistantConnectionsGroupComponent);
