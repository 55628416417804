import { UserMenu } from '@/components/NavigationBar/UserMenu';
import { projectNameSelector } from '@/modules/floorplan';
import { OverflowTypography } from '@common/components/OverflowTypography';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { memo, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { AppMode } from '../types/general';
import { TopBar } from './TopBar';
import { Logo } from '@/components/NavigationBar/Logo/Logo';

type Props = {
  showLoading?: boolean;
};

export const DashboardTopBarComponent = ({ showLoading = false }: Props) => {
  const projectName = useRecoilValue(projectNameSelector);
  const title = useMemo(() => {
    const appName = 'DigitalTwin';

    if (projectName) {
      return `${appName} / ${projectName}`;
    }

    return appName;
  }, [projectName]);

  return (
    <TopBar sx={{ zIndex: 2 }}>
      <Stack
        gap={2}
        justifyContent='space-between'
        direction='row'
        alignItems='center'
        width='100%'
      >
        <Stack flexDirection='row' height='100%' width='10%' flex='0 0 auto'>
          <Logo displayTooltip={false} />
        </Stack>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='center'
          gap={0}
          position='relative'
          maxWidth='40%'
          sx={{
            maxWidth: {
              xs: '40%',
              sm: '80%',
            },
          }}
          flex='0 1 auto'
        >
          <OverflowTypography textAlign='center' fontSize={14}>
            {title}
          </OverflowTypography>
          {showLoading ? (
            <Stack direction='row' position='absolute' left='100%'>
              <Typography align='left' fontSize={14} whiteSpace='pre'>
                {` / `}
              </Typography>
              <CircularProgress variant='indeterminate' size={22} />
            </Stack>
          ) : null}
        </Stack>
        <Stack width='10%' alignItems='flex-end' flex='0 0 auto'>
          <UserMenu appMode={AppMode.FloorplanAdmin} />
        </Stack>
      </Stack>
    </TopBar>
  );
};

export const DashboardTopBar = memo(DashboardTopBarComponent);
