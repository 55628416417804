import { selector } from 'recoil';
import { Unit } from '@modules/common/types/general';

import { RECOIL_SELECTOR_CACHE_POLICY } from '../common';

export const unitSelector = selector<Unit>({
  key: 'unitSelector',
  get: () => Unit.MILLIMETERS, // getUserPreference(UserPreferenceName.GENERAL_UNIT),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
