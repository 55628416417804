import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import { simulationIdsAtom } from './simulationsAtom';

export const nextSimulationNameSelector = selector<string>({
  key: 'simulations/draft/nextSimulationName',
  get: ({ get }) => `Run ${get(simulationIdsAtom).length + 1}`,
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
