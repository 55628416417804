import { CropIcon, DeleteIcon } from '@/assets/icons';
import { floorPlanIdSelector, projectIdSelector } from '@/modules/floorplan';
import { OverflowTypography } from '@common/components/OverflowTypography';
import { StyledTooltip } from '@common/components/StyledTooltip';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { useFloorPlanState } from '@modules/floorplan/hooks/useFloorPlanState';
import { useReferenceImage } from '@modules/referenceImage/hooks';
import { useZoomButton } from '@modules/workspace/components/ZoomButton/useZoomButton';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import { Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import { useCallback } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  referenceMode,
  referenceName,
  referenceStatus,
  referenceType,
  refernceFileName,
} from '../../../store';
import { Mode, REFERENCE_TYPE, Status } from '../../../types';
import { Opacity } from './Opacity';

export function FileStatus() {
  let referenceFileName = useRecoilValue(refernceFileName);
  let referenceFileName2 = useRecoilValue(referenceName);
  referenceFileName = referenceFileName2 ?? referenceFileName;

  const status = useRecoilValue(referenceStatus);
  const [mode, setMode] = useRecoilState(referenceMode);
  const { saveFloorPlan } = useFloorPlanState();
  const { deleteReference } = useReferenceImage();
  const workspaceMode = useRecoilValue(modeSelector);
  const disabled = workspaceMode !== WorkspaceMode.EDITABLE;
  const showCropButton = useRecoilValue(referenceType) === REFERENCE_TYPE.IMAGE;
  const { zoomFitReference } = useZoomButton();
  const { saveReferenceSettings } = useReferenceImage();
  const setModeState = useSetRecoilState(referenceMode);

  const onDeleteClick = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const projectId = await snapshot.getPromise(projectIdSelector);
        const floorPlanId = await snapshot.getPromise(floorPlanIdSelector);
        await deleteReference(projectId, floorPlanId);
        await saveFloorPlan();
      },
    [deleteReference, saveFloorPlan],
  );

  const confirmCrop = useCallback(() => {
    setModeState(undefined);
    saveReferenceSettings();
  }, [saveReferenceSettings, setModeState]);

  const onCropClick = useCallback(() => {
    if (mode === Mode.CROP) {
      confirmCrop();
    } else {
      zoomFitReference();
      setMode(Mode.CROP);
    }
  }, [confirmCrop, mode, setMode, zoomFitReference]);

  return (
    <Stack spacing={1} sx={{ width: '100%' }}>
      <Stack direction='row' alignItems='center' gap={1} sx={{ width: '100%' }}>
        <CropOriginalIcon sx={{ width: '24px', height: '24px' }} />
        <OverflowTypography
          sx={{
            display: 'flex',
            fontSize: 12,
            mr: 'auto',
          }}
        >
          {referenceFileName ?? 'referenceImage'}
        </OverflowTypography>

        {status === Status.LOADING && <CircularProgress size={18} />}

        {showCropButton && (
          <StyledTooltip title='Crop' disabled={disabled}>
            <IconButton
              disabled={disabled}
              onClick={onCropClick}
              sx={{p:0}}
            >
              <CropIcon />
            </IconButton>
          </StyledTooltip>
        )}
        <StyledTooltip title='Delete file' disabled={disabled}>
          <IconButton disabled={disabled} onClick={onDeleteClick} sx={{p:0}}>
            <DeleteIcon />
          </IconButton>
        </StyledTooltip>
      </Stack>
      <Opacity />
    </Stack>
  );
}
