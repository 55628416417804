import { styled } from '@mui/system';
import { IconButton, IconButtonProps } from '@mui/material';
import { theme } from '@/modules/common/components/theme';

export const ToolbarIconButton = styled((props: IconButtonProps) => <IconButton {...props} />)(
  (props) => ({
    backgroundColor: theme.palette.shades.light,
    color: theme.palette.neutral.darker,
    fontSize: '24px',
    margin: '4px',
    borderRadius: '4px',
    padding: '4px',
    '& .MuiSvgIcon-root': {
      height: 24,
      width: 24,
    },
    '&[type="Selected"]': {
      color: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.light,
    },
    '&[type="Deselected"]': {
      color: 'unset',
      backgroundColor: 'unset',
    },
    '&:hover': {
      color: theme.palette.neutral.darker,
      backgroundColor: theme.palette.neutral.greyLight,
    },
    '&:disabled': {
      backgroundColor: theme.palette.shades.light,
      color: theme.palette.disabled.main,
    },
    '&.submenu-open': {
      color: theme.palette.shades.light,
      backgroundColor: theme.palette.primary.main,
    },
  }),
);