import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Bar, BarChart, Cell } from 'recharts';

import { theme } from '@/modules/common/components/theme';
import { MAX_ORDER_PROFILE_HOURS } from '@/modules/common/constants/orderProfile';
import { durationToHours } from '@/modules/common/helpers/date';
import { durationSelector } from '@/modules/simulation/store/draft';
import { FlowScope } from '../types';

type Props = {
  loadProfile: number[];
  flowScope: FlowScope;
};

type ChartData = {
  loads: number;
};

export const FlowGraph = ({ loadProfile, flowScope }: Props) => {
  const duration = useRecoilValue(durationSelector);
  const [chartData, setChartData] = useState<ChartData[]>([]);
  const [focusBar, setFocusBar] = useState(null);

  const prepareChartData = useCallback(
    async (loadProfile: number[]) => {
      let data: ChartData[] = loadProfile.map((value) => ({
        loads: value,
      }));
      // pad with 0 loads
      if (data.length < MAX_ORDER_PROFILE_HOURS) {
        const padCount = MAX_ORDER_PROFILE_HOURS - data.length;
        for (let i = 0; i < padCount; ++i) {
          data.push({
            loads: 0,
          });
        }
      } else if (data.length > MAX_ORDER_PROFILE_HOURS) {
        data = data.slice(0, MAX_ORDER_PROFILE_HOURS);
      }
      setChartData(data);
    },
    [setChartData],
  );

  useEffect(() => {
    const hours =
      flowScope === FlowScope.LAYOUT ? MAX_ORDER_PROFILE_HOURS : durationToHours(duration);
    const slicedProfile = loadProfile.slice(0, hours);
    prepareChartData(slicedProfile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadProfile, flowScope, duration]);

  return (
    <BarChart
      width={150}
      height={50}
      data={chartData}
      onMouseMove={(state) => {
        if (!state?.activeTooltipIndex && state?.activeTooltipIndex !== 0) {
          setFocusBar(null);
        } else {
          setFocusBar(state.activeTooltipIndex);
        }
      }}
      onMouseLeave={() => setFocusBar(null)}
      style={{ marginTop: '-5px' }}
    >
      <Bar dataKey='loads'>
        {chartData.map((entry, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Cell key={`cell-${index}`} fill={focusBar === index ? theme.palette.primary.main : theme.palette.neutral.lighter} />
        ))}
      </Bar>
    </BarChart>
  );
};
