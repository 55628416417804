import { IconButton } from '@mui/material';
import { memo } from 'react';

import { LockIcon, LockOpenIcon } from '@/assets/icons';
import { theme } from '@common/components/theme';

type LockProps = {
  onClick: () => void;
  isLocked: boolean;
  isVisible: boolean;
  disabled?: boolean;
};

const LockBtnComponent = ({ onClick, isLocked, isVisible, disabled = false }: LockProps) => (
  <IconButton
    onClick={onClick}
    disabled={disabled}
    className='btn-icon'
    sx={{
      padding: 0,
      marginRight: 1,
      borderRadius: '4px',
      color: isVisible ? theme.palette.neutral.darker : theme.palette.disabled.main,
      '&:hover': {
        color: theme.palette.neutral.darker,
      },
    }}
  >
    {isLocked ? (
      <LockIcon data-testid='Lock icon' />
    ) : (
      <LockOpenIcon data-testid='Lock Open icon' />
    )}
  </IconButton>
);

export const LockBtn = memo(LockBtnComponent);
