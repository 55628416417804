import { capitalizeFirstLetter } from '../../../helpers/string';
import { formatShapeType } from '../../../store/recoil/floorplan/helper';
import { ShapeType } from '../../common/types/shapes';

export const generateNewGroupName = (shapeType: ShapeType): string => {
  const groupType = formatShapeType(shapeType);
  const storageKey = `${groupType}_group`;

  const index = parseInt(sessionStorage.getItem(storageKey) ?? '1');
  sessionStorage.setItem(storageKey, (index + 1).toString());
  return `${storageKey
    .split('_')
    .map((item) => capitalizeFirstLetter(item))
    .join(' ')} ${index}`;
};
