import { Divider, Stack } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { useSimulationGroupCallbacks, useSimulationGroupUpdater } from '@modules/simulation/hooks';
import { currentGroupSimulationIdsSelector } from '@modules/simulation/store/group';
import { SimulationList } from '../SimulationList';
import { GroupPanelCard } from './components/GroupPanelCard';
import { Header } from './components/Header';

export const GroupPanel = () => {
  const { loadAll } = useSimulationGroupCallbacks();
  const currentGroupSimulationIds = useRecoilValue(currentGroupSimulationIdsSelector);
  const { stop, start } = useSimulationGroupUpdater();

  const load = useCallback(async () => {
    await loadAll();
    return start();
  }, [start, loadAll]);

  useEffect(() => {
    load();

    return () => {
      stop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack gap={1} data-testid='GroupPanel' divider={<Divider />} sx={{ pt: 1 }}>
      <Header />
      <SimulationList
        ids={currentGroupSimulationIds}
        hasRunningSimulations={false}
        renderCard={(id) => <GroupPanelCard key={id} id={id} />}
      />
    </Stack>
  );
};
