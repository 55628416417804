/* eslint-disable import/no-named-as-default */
import { Unit } from '@modules/common/types/general';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { selectorFamily } from 'recoil';
import { unitSelector } from './unitSelector';

export const unitValueSelector = selectorFamily<number, number>({
  key: 'unitValueSelector',
  get:
    (value: number) =>
    ({ get }) => {
      const unit = get(unitSelector);

      if (unit === Unit.CENTIMETERS) {
        return value / 10;
      }
      if (unit === Unit.METERS) {
        return value / 1000;
      }

      return value;
    },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
