import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { selector } from 'recoil';

import { DependentFlow } from '../../helpers/types';
import { simulationAtom } from './simulationAtom';

export const dependentFlowsSelector = selector<DependentFlow[]>({
  key: 'simulations/draft/dependentFlows',
  get: ({ get }) => get(simulationAtom)?.dependentFlows ?? [],
  set: ({ set }, dependentFlows: DependentFlow[]) => set(simulationAtom, (state) => ({ ...state, dependentFlows })),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
