import { processTwoEndPointIdsState } from '@/modules/processTwoEndPoint/store/state';
import { ProcessTwoEPShape } from '@/modules/processTwoEndPoint/types';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { setAtomFamilyWithIdsAtom } from '@recoil/common/helper';
import shapeAtom from '@recoil/shape/atom';
import { selector } from 'recoil';

export const allProcessTwoEndPointSelector = selector({
  key: 'allProcessTwoEndPointSelector',
  get: ({ get }) => get(processTwoEndPointIdsState).map((id) => get(shapeAtom(id))) as ProcessTwoEPShape[],
  set: (getSetResetRecoilState, newProcessTwoEPs: ProcessTwoEPShape[]) => {
    setAtomFamilyWithIdsAtom(
      getSetResetRecoilState,
      shapeAtom,
      processTwoEndPointIdsState,
      newProcessTwoEPs,
    );
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
