import { SvgIcon } from '@mui/material';

export default function WallIcon(props) {
  return (
    <SvgIcon width="20" height="18" viewBox="0 0 20 18" fill="none" stroke="none" {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.5 1.5C1.22386 1.5 1 1.72386 1 2V4.5H4V1.5H1.5ZM1.5 0.5C0.67157 0.5 0 1.17157 0 2V16C0 16.8284 0.67157 17.5 1.5 17.5H18.5C19.3284 17.5 20 16.8284 20 16V2C20 1.17157 19.3284 0.5 18.5 0.5H1.5ZM5 1.5V4.5H9.5V1.5H5ZM10.5 1.5V4.5H15V1.5H10.5ZM16 1.5V4.5H19V2C19 1.72386 18.7761 1.5 18.5 1.5H16ZM19 5.5H14V8.5H19V5.5ZM19 9.5H16V12.5H19V9.5ZM19 13.5H14V16.5H18.5C18.7761 16.5 19 16.2761 19 16V13.5ZM13 16.5V13.5H7V16.5H13ZM6 16.5V13.5H1V16C1 16.2761 1.22386 16.5 1.5 16.5H6ZM1 12.5H4V9.5H1V12.5ZM1 8.5H6V5.5H1V8.5ZM7 5.5V8.5H13V5.5H7ZM10.5 9.5V12.5H15V9.5H10.5ZM9.5 12.5V9.5H5V12.5H9.5Z'
      />
    </SvgIcon>
  );
}
