import ExcelJS, { Row } from 'exceljs';
import { useRecoilCallback } from 'recoil';

import { download } from '@/modules/common/helpers/browser';
import { durationToHours } from '@/modules/common/helpers/date';
import { OrderProfile } from '@/modules/common/types/orderProfile';
import { MAX_ORDER_PROFILE_HOURS } from '@/modules/common/constants/orderProfile';
import { groupNameSelector, projectNameSelector, useFloorPlanState } from '@/modules/floorplan';
import { displayVersionSelector } from '@/modules/floorplan/store/floorPlan';
import { openFlowPanelAtom } from '@/modules/flows';
import { getScopedFlowsSelector } from '@/modules/flows/helpers';
import { DEFAULT_FLOW_LOADS_AMOUNT } from '@/modules/flows/store/layout/constants';
import { FlowScope, LayoutFlow } from '@/modules/flows/types';
import { durationSelector } from '@/modules/simulation/store/draft';
import { HistoryManager } from '@/store/recoil/history';
import { removeZeroOrderFlows } from '../helpers';
import { orderProfileAtom } from '../store/orderProfileAtom';
import { useOrderProfile } from './useOrderProfile';
import {
  markCellModifiable,
  writeHeaderRow,
  writeInfoSection,
} from '@/modules/common/helpers/excel';

const EXCEL_SHEET_NAME = 'OrderProfile';
const EXCEL_HEADER_FLOW_ID = 'Flow id';
const EXCEL_HEADER_FLOW_NAME = 'Flow name';

type OrderProfileHistory = {
  flows: LayoutFlow[];
  orderProfile: OrderProfile;
};

export type UploadExcelResult = {
  success: boolean;
  message?: string;
};

export const useOrderProfileExcel = () => {
  const { saveFloorPlan } = useFloorPlanState();
  const { resetOverwriteFilename } = useOrderProfile();

  const createExcel = useRecoilCallback(
    ({ snapshot }) =>
      async (flowScope: FlowScope): Promise<Blob> => {
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet(EXCEL_SHEET_NAME);
        const flowsSelector = getScopedFlowsSelector(flowScope);
        let allFlows = await snapshot.getPromise(flowsSelector);
        const projectName = await snapshot.getPromise(projectNameSelector);
        const floorplanName = await snapshot.getPromise(groupNameSelector);
        const version = await snapshot.getPromise(displayVersionSelector);
        let rowIndex = writeInfoSection(sheet, projectName, floorplanName, version);

        rowIndex += 1;
        // hours
        let hours = MAX_ORDER_PROFILE_HOURS;
        if (flowScope === FlowScope.SIMULATION) {
          hours = durationToHours(await snapshot.getPromise(durationSelector));
        }

        // order profile table: header
        const header: string[] = [EXCEL_HEADER_FLOW_NAME, 'From', 'To'];
        for (let i = 1; i <= hours; ++i) {
          header.push(i.toString());
        }
        header.push(EXCEL_HEADER_FLOW_ID);

        writeHeaderRow(sheet, rowIndex, header);

        sheet.columns = header.map((h, index) => {
          let width = index < 3 ? 20 : 5;
          return { width };
        });
        ++rowIndex;

        // order profile table: flows
        // eslint-disable-next-line no-restricted-syntax
        for (const flow of allFlows) {
          let rowValue = [flow.name, flow.sourceName, flow.targetName];
          let items: string[] = [];
          for (let i = 0; i < hours; ++i) {
            let value = flow.totalNumLoads;
            if (flow.loadProfile && i < flow.loadProfile.length) value = flow.loadProfile[i];
            items.push(value.toString());
          }
          rowValue = rowValue.concat(items);
          rowValue.push(flow.id);
          const row = sheet.getRow(rowIndex);
          row.values = rowValue;

          // fill background for modifiable cells
          markCellModifiable(row.getCell(1));
          for (let i = 4; i < rowValue.length; ++i) {
            markCellModifiable(row.getCell(i));
          }
          ++rowIndex;
        }

        const excelBuffer = await workbook.xlsx.writeBuffer();
        const excelBlob = new Blob([excelBuffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        return excelBlob;
      },
    [],
  );

  const downloadExcel = useRecoilCallback(
    ({ snapshot }) =>
      async (excelBlob: Blob) => {
        const floorplanName = await snapshot.getPromise(groupNameSelector);
        const version = await snapshot.getPromise(displayVersionSelector);
        download(excelBlob, `OrderProfile-${floorplanName}-v${version}.xlsx`);
      },
    [],
  );

  const findValueCellIndex = (row: Row, value: string) => {
    for (let i = 1; i <= row.cellCount; ++i) {
      if (row.getCell(i).value === value) return i;
    }
    return null;
  };

  const uploadExcel = useRecoilCallback(
    ({ snapshot, set }) =>
      async (
        buffer: ArrayBuffer,
        filename: string,
        flowScope: FlowScope,
      ): Promise<UploadExcelResult> => {
        const workbook = new ExcelJS.Workbook();
        workbook.calcProperties.fullCalcOnLoad = true;

        try {
          await workbook.xlsx.load(buffer);
        } catch {
          return {
            success: false,
            message: 'interface:order_profile.upload_result.bad_excel',
          };
        }

        const sheet = workbook.getWorksheet(EXCEL_SHEET_NAME);
        if (!sheet) {
          return {
            success: false,
            message: 'interface:order_profile.upload_result.bad_excel',
          };
        }

        // add key back to column
        let rowIndex = 1;

        // find the table header row
        for (let i = rowIndex; i < sheet.rowCount; ++i) {
          if (sheet.getCell(`A${i}`).value === EXCEL_HEADER_FLOW_NAME) {
            rowIndex = i;
            break;
          }
        }

        // flow id index
        const flowIdCol = findValueCellIndex(sheet.getRow(rowIndex), EXCEL_HEADER_FLOW_ID);
        if (!flowIdCol)
          return {
            success: false,
            message: 'interface:order_profile.upload_result.bad_excel',
          };
        ++rowIndex;

        // update allFlows
        const flowsSelector = getScopedFlowsSelector(flowScope);
        const oldFlows = await snapshot.getPromise(flowsSelector);
        const oldFlowsIds = new Set(oldFlows.map((flow) => flow.id));
        let newFlows = [];
        for (let i = rowIndex; i <= sheet.rowCount; ++i) {
          const row = sheet.getRow(i);
          const flowId = row.getCell(flowIdCol).value as string;
          if (!oldFlowsIds.has(flowId)) {
            return {
              success: false,
              message: 'interface:order_profile.upload_result.flow_mismatch',
            };
          }

          // create new flow
          const thisFlow = oldFlows.find((flow) => flow.id === flowId);
          const newFlow = { ...thisFlow };
          newFlow.name = row.getCell(1).value as string;
          const orderProfile: number[] = [];
          for (let col = 4; col < flowIdCol; ++col) {
            const value = row.getCell(col).value as string;
            orderProfile.push(value ? parseInt(value) : 0);
          }
          newFlow.loadProfile = orderProfile;
          newFlows.push(newFlow);
        }
        const oldOrderProfile: OrderProfile = await snapshot.getPromise(orderProfileAtom);
        let newOrderProfile: OrderProfile;

        if (flowScope === FlowScope.LAYOUT) {
          newOrderProfile = {
            uploadedFileName: filename,
          };
        } else {
          // simulation flow
          newOrderProfile = {
            ...oldOrderProfile,
            overwriteFileName: filename,
          };
          // remove all flows that are empty
          newFlows = removeZeroOrderFlows(newFlows);
        }

        // update state
        set(flowsSelector, newFlows);
        set(orderProfileAtom, newOrderProfile);
        set(openFlowPanelAtom, true);
        saveFloorPlan();

        // history tracking
        const newHistory: OrderProfileHistory = {
          flows: newFlows,
          orderProfile: newOrderProfile,
        };
        const oldHistory: OrderProfileHistory = {
          flows: oldFlows,
          orderProfile: oldOrderProfile,
        };
        HistoryManager.track(
          `orderprofile upload`,
          newHistory,
          oldHistory,
          (value: OrderProfileHistory) => {
            set(flowsSelector, value.flows);
            set(orderProfileAtom, value.orderProfile);
          },
        );
        return { success: true, message: 'interface:order_profile.upload_result.success' };
      },
    [saveFloorPlan],
  );

  const removeOrderProfile = useRecoilCallback(
    ({ set, snapshot }) =>
      async (flowScope: FlowScope) => {
        const flowsSelector = getScopedFlowsSelector(flowScope);
        const oldFlows = await snapshot.getPromise(flowsSelector);
        const oldOrderProfile = await snapshot.getPromise(orderProfileAtom);
        const newFlows: LayoutFlow[] = oldFlows.map((flow) => {
          const newFlow: LayoutFlow = { ...flow };
          newFlow.loadProfile = null;
          newFlow.totalNumLoads = newFlow.totalNumLoads ?? DEFAULT_FLOW_LOADS_AMOUNT;
          return newFlow;
        });
        set(flowsSelector, newFlows);
        let newOrderProfile: OrderProfile = null;
        if (flowScope === FlowScope.LAYOUT) {
          set(orderProfileAtom, newOrderProfile);
          saveFloorPlan();
        } else {
          newOrderProfile = {
            uploadedFileName: oldOrderProfile.uploadedFileName,
          };
          resetOverwriteFilename();
        }

        // history tracking
        const newHistory: OrderProfileHistory = {
          flows: newFlows,
          orderProfile: newOrderProfile,
        };
        const oldHistory: OrderProfileHistory = {
          flows: oldFlows,
          orderProfile: oldOrderProfile,
        };
        HistoryManager.track(
          `orderprofile remove`,
          newHistory,
          oldHistory,
          (value: OrderProfileHistory) => {
            set(flowsSelector, value.flows);
            set(orderProfileAtom, value.orderProfile);
          },
        );
      },
    [saveFloorPlan, resetOverwriteFilename],
  );

  return { createExcel, downloadExcel, uploadExcel, removeOrderProfile };
};
