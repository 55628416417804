import {
  MutableRefObject,
  useState,
  useCallback,
  useEffect,
  memo,
  ChangeEvent,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
import { Typography } from '@mui/material';
import { Stack, SxProps } from '@mui/system';

import { PropertiesPopup } from '@/modules/common/components/PropertiesPopup';
import { PropertiesTitle } from '@/modules/common/components/PropertiesTitle';
import { NumberInput } from '@/modules/common/components/inputs';
import { contextState, CONTEXT } from '@/store/recoil/input';
import { unitSelector, unitValueSelector } from '@/store/recoil/workspace';
import Label from '@/modules/common/components/Label';
import { areaRackProperties } from '@/components/PropertiesPanel/store/area';
import { MIN_RACK } from '@/modules/common/constants/storage';
import { useFloorPlanState } from '@/modules/floorplan';
import { useArtefacts } from '@/modules/artefacts';
import { selectedShapesIdsState } from '@/store/recoil/shapes/selected';
import { useDebouncedCallback } from '@/modules/common/hooks';

type Props = {
  anchor: MutableRefObject<HTMLElement>;
  isOpen: boolean;
  toggleOpen: () => void;
};

const RackPropertiesAdvancedComponent = ({ anchor, isOpen, toggleOpen }: Props) => {
  const { t } = useTranslation();
  const { saveFloorPlan } = useFloorPlanState();
  const unit = useRecoilValue(unitSelector);
  const setContext = useSetRecoilState(contextState);
  const [focusedInputName, setFocusedInputName] = useState(null);
  const [image, setImage] = useState('/racking/default.png');
  const { updateDebounced: updateArtefacts } = useArtefacts();
  const selectedShapeIds = useRecoilValue(selectedShapesIdsState);
  const setImageDebounced = useDebouncedCallback((value: string) => setImage(value), 50);
  
  const onFocus = useCallback(
    (evt) => {
      setFocusedInputName(evt.target.name);
      setContext(CONTEXT.PROPERTYPANEL);
    },
    [setContext, setFocusedInputName],
  );

  const onBlur = useCallback(() => {
    setFocusedInputName(null);
    setContext(CONTEXT.WORKSPACE);
  }, [setContext, setFocusedInputName]);

  useEffect(() => {
    setImageDebounced(mapInputToImage(focusedInputName));
  }, [focusedInputName, setImageDebounced]);

  const [rackProperties, setRackProperties] = useRecoilState(areaRackProperties);

  const buildMinValueErrorObj = useCallback(
    (minValue: number) => ({
      error: t('common:minimum_value', {
        minValue,
      }),
    }),
    [t],
  );

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      const newValue = Number(value);

      switch (name) {
        case InputName.SIDE_MARGIN:
          if (newValue < MIN_RACK.CLEARANCE_SIDE)
            return buildMinValueErrorObj(MIN_RACK.CLEARANCE_SIDE);

          setRackProperties({ clearanceSide: newValue });
          break;
        case InputName.TOP_MARGIN:
          if (newValue < MIN_RACK.CLEARANCE_TOP)
            return buildMinValueErrorObj(MIN_RACK.CLEARANCE_TOP);

          setRackProperties({ clearanceTop: newValue });
          break;
        case InputName.GAP_BETWEEN_LOADS:
          if (newValue < MIN_RACK.CLEARANCE_IN_BETWEEN)
            return buildMinValueErrorObj(MIN_RACK.CLEARANCE_IN_BETWEEN);

          setRackProperties({ clearanceInBetween: newValue });
          break;
        case InputName.BEAM:
          if (newValue < MIN_RACK.BEAM_HEIGHT) return buildMinValueErrorObj(MIN_RACK.BEAM_HEIGHT);

          setRackProperties({ beamHeight: newValue });
          break;
        case InputName.POST_WIDTH:
          if (newValue < MIN_RACK.POST_WIDTH)
            return buildMinValueErrorObj(MIN_RACK.POST_WIDTH);

          setRackProperties({ postWidth: newValue });
          break;
        case InputName.FIRST_SHELF_HEIGHT:
          if (newValue < MIN_RACK.FIRST_SHELF_HEIGHT)
            return buildMinValueErrorObj(MIN_RACK.FIRST_SHELF_HEIGHT);

          setRackProperties({ firstShelfHeight: newValue });
          break;
        default: {
          console.error('Unexpected input name');
          break;
        }
      }

      saveFloorPlan();
      updateArtefacts(selectedShapeIds);
    },
    [saveFloorPlan, buildMinValueErrorObj, setRackProperties, updateArtefacts, selectedShapeIds],
  );

  const measurementUnit = useMemo(() => t(`interface:settings.units.${unit}`, unit), [unit, t]);

  return (
    <PropertiesPopup
      anchor={anchor}
      open={isOpen}
      onClose={toggleOpen}
      sx={{
        width: '320px',
        height: 'auto',
      }}
      header={
        <PropertiesTitle
          fontSize={14}
          fontWeight={400}
          value={t('interface:properties.area.rack.racking_preferences')}
        />
      }
    >
      <Stack gap={0}>
        <Stack alignItems='center'>
          {image && <img src={image} alt='' width={203} height={223} />}
        </Stack>

        <Stack gap={1}>
          <Label
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1,
            }}
            label={
              <Typography fontSize='10px'>
                {t('interface:properties.area.rack.advanced.side_margin', 'Side margin')}
              </Typography>
            }
          >
            <NumberInput
              value={useRecoilValue(unitValueSelector(rackProperties.clearanceSide))}
              unit={measurementUnit}
              name={InputName.SIDE_MARGIN}
              onChange={onChangeHandler}
              onBlur={onBlur}
              onFocus={onFocus}
              changeParamType='event'
              sx={inputStyle}
              errorType='floating'
              dataType='integer'
            />
          </Label>
          <Label
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1,
            }}
            label={
              <Typography fontSize='10px'>
                {t(
                  'interface:properties.area.rack.advanced.gap_between_loads',
                  'Gap between loads',
                )}
              </Typography>
            }
          >
            <NumberInput
              value={useRecoilValue(unitValueSelector(rackProperties.clearanceInBetween))}
              unit={measurementUnit}
              name={InputName.GAP_BETWEEN_LOADS}
              onChange={onChangeHandler}
              onBlur={onBlur}
              onFocus={onFocus}
              changeParamType='event'
              sx={inputStyle}
              errorType='floating'
              dataType='integer'
            />
          </Label>
          <Label
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1,
            }}
            label={
              <Typography fontSize='10px'>
                {t('interface:properties.area.rack.advanced.top_margin', 'Top margin')}
              </Typography>
            }
          >
            <NumberInput
              value={useRecoilValue(unitValueSelector(rackProperties.clearanceTop))}
              unit={measurementUnit}
              name={InputName.TOP_MARGIN}
              onChange={onChangeHandler}
              onBlur={onBlur}
              onFocus={onFocus}
              changeParamType='event'
              sx={inputStyle}
              errorType='floating'
              dataType='integer'
            />
          </Label>
          <Label
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1,
            }}
            label={
              <Typography fontSize='10px'>
                {t('interface:properties.area.rack.advanced.beam', 'Beam')}
              </Typography>
            }
          >
            <NumberInput
              value={useRecoilValue(unitValueSelector(rackProperties.beamHeight))}
              unit={measurementUnit}
              name={InputName.BEAM}
              onChange={onChangeHandler}
              onBlur={onBlur}
              onFocus={onFocus}
              changeParamType='event'
              sx={inputStyle}
              errorType='floating'
              dataType='integer'
            />
          </Label>
          <Label
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1,
            }}
            label={
              <Typography fontSize='10px'>
                {t('interface:properties.area.rack.advanced.post_width', 'Post width')}
              </Typography>
            }
          >
            <NumberInput
              value={useRecoilValue(unitValueSelector(rackProperties.postWidth))}
              unit={measurementUnit}
              name={InputName.POST_WIDTH}
              onChange={onChangeHandler}
              onBlur={onBlur}
              onFocus={onFocus}
              changeParamType='event'
              sx={inputStyle}
              errorType='floating'
              dataType='integer'
            />
          </Label>
          <Label
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1,
            }}
            label={
              <Typography fontSize='10px'>
                {t('interface:properties.area.rack.advanced.first_shelf_height', 'First shelf height')}
              </Typography>
            }
          >
            <NumberInput
              value={useRecoilValue(unitValueSelector(rackProperties.firstShelfHeight))}
              unit={measurementUnit}
              name={InputName.FIRST_SHELF_HEIGHT}
              onChange={onChangeHandler}
              onBlur={onBlur}
              onFocus={onFocus}
              changeParamType='event'
              sx={inputStyle}
              errorType='floating'
              dataType='integer'
            />
          </Label>
        </Stack>
      </Stack>
    </PropertiesPopup>
  );
};

const mapInputToImage = (inputName: InputName | null) => {
  switch (inputName) {
    case InputName.SIDE_MARGIN:
      return '/racking/side-margin.png';
    case InputName.TOP_MARGIN:
      return '/racking/top-margin.png';
    case InputName.GAP_BETWEEN_LOADS:
      return '/racking/gap-between-loads.png';
    case InputName.BEAM:
      return '/racking/beam.png';
    case InputName.POST_WIDTH:
      return '/racking/post-width.png';
    case InputName.FIRST_SHELF_HEIGHT:
      return '/racking/first-shelf-height.png';
    default:
      return '/racking/default.png';
  }
};

enum InputName {
  BEAM = 'beam',
  TOP_MARGIN = 'top_margin',
  SIDE_MARGIN = 'side_margin',
  GAP_BETWEEN_LOADS = 'gap_between_loads',
  POST_WIDTH = 'post_width',
  FIRST_SHELF_HEIGHT = 'first_shelf_height',
}

const inputStyle: SxProps = { width: '180px', flexShrink: 0 };

export const RackPropertiesAdvanced = memo(RackPropertiesAdvancedComponent);
