import { GeneratedLoadPosition, GeneratedLocation } from '@modules/floorplanService';
import { Vector3 } from 'three';

import { useFloorPlanStore, useGateStore, useKollmorgenStore, useLayoutStore } from '../store';
import {
  FloorPlanAdjustment,
  LoadPositionAdjustment,
  LocationAdjustment,
  Reflector,
} from './types';

export const getFloorPlanServiceData = (): {
  floorPlanAdjustment: FloorPlanAdjustment;
  reflectors: Reflector[];
} => {
  const { delta, deltaAngle, initialDelta } = useLayoutStore.getState();
  const { reflectors } = useKollmorgenStore.getState();

  return {
    floorPlanAdjustment: {
      transformationDeltaX: delta.x - initialDelta.x,
      transformationDeltaY: delta.y - initialDelta.y,
      transformationAngle: deltaAngle,
    },
    reflectors: [...reflectors],
  };
};

export const getCurrentArtefactAdjustments = (): {
  locationAdjustments: LocationAdjustment[];
  loadPositionAdjustments: LoadPositionAdjustment[];
} => {
  const { adjustments } = useGateStore.getState();
  const { locations, loadPositions } = useFloorPlanStore.getState();

  const allLocationAdjustments = [];
  const allLoadPositionAdjustments = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const [id, delta] of adjustments.entries()) {
    const relatedEndPointName = id.substring(0, id.lastIndexOf('_'));
    // TODO: for racking: get reachable loadPositions dynamically
    const relatedLoadPositionName = id.substring(0, id.lastIndexOf('_'));

    // get artefacts (the adjusted location + any related locations/loadPositions)
    const relatedLoadPositionsNames = [relatedLoadPositionName];
    const relatedLocationsNames = [relatedEndPointName, id];
    const relatedLoadPositions = loadPositions.filter((item) =>
      relatedLoadPositionsNames.includes(item.loadPositionName),
    );
    const relatedLocations = locations.filter((item) =>
      relatedLocationsNames.includes(item.locationName),
    );

    const {
      locationAdjustments: relatedLocationAdjustments,
      loadPositionAdjustments: relatedLoadPositionAdjustments,
    } = mapArtefactsWithDelta(delta, relatedLocations, relatedLoadPositions);

    allLocationAdjustments.push(...relatedLocationAdjustments);
    allLoadPositionAdjustments.push(...relatedLoadPositionAdjustments);
  }

  return {
    locationAdjustments: allLocationAdjustments,
    loadPositionAdjustments: allLoadPositionAdjustments,
  };
};

export const mapArtefactsWithDelta = (
  gateDelta: Vector3,
  relatedLocations: GeneratedLocation[],
  relatedLoadPositions: GeneratedLoadPosition[],
): {
  locationAdjustments: LocationAdjustment[];
  loadPositionAdjustments: LoadPositionAdjustment[];
} => {
  const locationAdjustments = relatedLocations.map((item) => ({
    locationName: item.locationName,
    deltaX: gateDelta.x,
    deltaY: gateDelta.y,
    angleInDegrees: 0,
  }));

  // TODO account for altered delta caused by rotation
  const loadPositionAdjustments = relatedLoadPositions.map((item) => ({
    loadPositionName: item.loadPositionName,
    deltaX: gateDelta.x,
    deltaY: gateDelta.y,
    angleInDegrees: 0,
  }));

  return {
    locationAdjustments,
    loadPositionAdjustments,
  };
};
