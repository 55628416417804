import { selector } from 'recoil';
import { angledHighwayIdsState } from '../state';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../../../store/recoil/common';

export const deleteAngledHighwayIdsSelector = selector<string[]>({
  key: 'angledHighway/ids/delete',
  get: () => null,
  set: ({ get, set }, deleteIds: string[]) => {
    const ids = new Set(get(angledHighwayIdsState));
    deleteIds.forEach((id) => ids.delete(id));
    set(angledHighwayIdsState, Array.from(ids));
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
