import { useCallback, useMemo } from 'react';

export const useDefaultMouseHandler = () => {
  const onMouseDown = useCallback(async () => {}, []);
  const onMouseMove = useCallback(async () => {}, []);
  const onMouseUp = useCallback(async () => {}, []);
  const onClick = useCallback(async () => {}, []);
  const onDoubleClick = useCallback(async () => {}, []);

  const mouseHandler = useMemo(
    () => ({
      onMouseDown,
      onMouseMove,
      onMouseUp,
      onClick,
      onDoubleClick,
    }),
    [onMouseDown, onMouseMove, onMouseUp, onClick, onDoubleClick],
  );

  return mouseHandler;
};
