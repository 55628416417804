import { useShallow } from 'zustand/react/shallow';
import { MutableRefObject, useEffect, useRef } from 'react';
import { Canvas as THiveCanvas } from '@thive/canvas';

import { useCanvasStore } from '../store';

export const useCanvasSetup = (elementRef: MutableRefObject<HTMLDivElement>) => {
  const { initialize, dispose } = useCanvasStore(
    useShallow((state) => ({ initialize: state.initialize, dispose: state.dispose })),
  );

  const isInitialized = useRef(false);
  useEffect(() => {
    if (isInitialized.current) {
      return;
    }

    const canvas = THiveCanvas.createFromElement(elementRef.current, {
      showAxisIndicator: true,
    });
    initialize(canvas);
    isInitialized.current = true;
    return () => {
      dispose();
    };
  }, [dispose, elementRef, initialize]);
};
