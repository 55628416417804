import { OverflowTypography } from '@common/components/OverflowTypography';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import {
  displayVersionSelector,
  hasUnsavedChangesSelector,
  isLatestSelector,
  isLoadedSelector,
} from '@modules/floorplan/store/floorPlan';
import { groupNameSelector, projectNameSelector } from '@modules/floorplan';
import { useTranslation } from 'react-i18next';

export const Title = () => {
  const isFloorPlanLoaded = useRecoilValue(isLoadedSelector);
  const isLatest = useRecoilValue(isLatestSelector);
  const projectName = useRecoilValue(projectNameSelector);
  const groupName = useRecoilValue(groupNameSelector);
  const version = useRecoilValue(displayVersionSelector);
  const hasUnsavedChanges = useRecoilValue(hasUnsavedChangesSelector);
  const { t } = useTranslation(['interface', 'common']);

  const UNSAVED_CHARACTER = '*';
  const EMPTY_VERSION_CHARACTER = '-';

  const title = useMemo(() => {
    const parts = [
      projectName,
      groupName,
      isFloorPlanLoaded
        ? `${t('interface:versioning.version', 'Version')} ${version || EMPTY_VERSION_CHARACTER}`
        : '',
    ];

    const unsavedStar = hasUnsavedChanges ? UNSAVED_CHARACTER : '';
    const readonly = isLatest ? '' : ` (${t('interface:versioning.readonly', 'Readonly')})`;

    return parts.join(' / ') + readonly + unsavedStar;
  }, [isLatest, projectName, groupName, isFloorPlanLoaded, t, hasUnsavedChanges, version]);

  return (
    <OverflowTypography
      sx={{
        fontSize: '14px',
        textAlign: 'center',
        '&:hover': { cursor: 'default' },
      }}
    >
      {title}
    </OverflowTypography>
  );
};
