import { Box, SxProps, Typography } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  children?: ReactNode;
  label?: ReactNode;
  sx?: SxProps;
};

function Label({ children, label, sx }: Props) {
  return (
    <>
      {label ? (
        <Box component='label' sx={{ display: 'flex', alignItems: 'center', ...sx }}>
          {renderLabel(label)}
          {children}
        </Box>
      ) : (
        children
      )}
    </>
  );
}

const renderLabel = (label: ReactNode) => {
  if (typeof label === 'string' || typeof label === 'number') {
    return <Typography sx={{ marginRight: 1 }}>{label}</Typography>;
  }

  return label;
};

export default Label;
