import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@/store/recoil/common';

import { projectState } from './projectState';

export const nameSelector = selector({
  key: 'project/name',
  get: ({ get }) => get(projectState).name,
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
