import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import { groupAtom } from './atom';

/**
 * Id of the latest version in the loaded floor plan group
 */
export const latestFloorPlanIdSelector = selector<string>({
  key: 'group/latestFloorPlanId',
  get: ({ get }) => get(groupAtom).latestFloorPlanId,
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
