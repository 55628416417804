import DeleteIcon from '@/assets/icons/DeleteIcon';
import IndeterminateCheckBoxOutlined from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { IconButton, Stack, Typography } from '@mui/material';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { numberSelectedSimulationsSelector, simulationIdsSelector, simulationSelector } from '../store/simulations';
import { useCallback, useState } from 'react';
import { DeleteConfirmDialog } from './ConfirmDialog';
import { useDialog } from '@/modules/common/hooks';
import { useTranslation } from 'react-i18next';
import { useSimulationCallbacks } from '../hooks';

const iconAction = {
    color: 'neutral.dark',
    borderRadius: 0,
    '&:active, &:hover, &.active': {
        color: 'primary.main',
    },
};

function SelectedItemsInfo({ sx }) {
    const { t } = useTranslation('interface');
    const selectedSimulations = useRecoilValue(numberSelectedSimulationsSelector);
    const [selectButtonState, setSelectButtonState] = useState(false);
    const { open: deleteDialogOpen, setOpen: toggleDeleteDialog } = useDialog();
    const { remove } = useSimulationCallbacks();

    const onDeleteConfirm = useCallback(() => selectedSimulations.forEach(async simulation => await remove(simulation)),
        [selectedSimulations, remove]);

    const selectAllSimulations = useRecoilCallback(
        ({ set, snapshot }) =>
            async () => {
                setSelectButtonState(!selectButtonState);
                const ids = await snapshot.getPromise(simulationIdsSelector);
                ids.forEach((item) => {
                    set(simulationSelector(item), (state) => ({ ...state, isSelected: !selectButtonState }));
                });
            },
        [selectButtonState],
    );

    const onDeleteSelectedSimulationClick = useCallback(() => toggleDeleteDialog(true), [toggleDeleteDialog]);

    return (
        <>
            {selectedSimulations.length > 0
                &&
                <Stack direction='row' gap={1} justifyContent='space-between' sx={sx}>
                    <Stack direction='row' alignItems='center'>
                        <IconButton onClick={selectAllSimulations} sx={iconAction}>
                            <IndeterminateCheckBoxOutlined />
                        </IconButton>
                        <Typography align='left' variant='h4'>
                            {selectedSimulations.length} selected
                        </Typography>
                    </Stack>
                    <IconButton sx={iconAction} onClick={onDeleteSelectedSimulationClick}>
                        <DeleteIcon />
                    </IconButton>
                </Stack>
            }
            <DeleteConfirmDialog
                open={deleteDialogOpen}
                runName={t('simulation.simulation_list.header')}
                confirmation={t('simulation.delete_dialog.confirmation')}
                onConfirm={() => {
                    onDeleteConfirm();
                    toggleDeleteDialog(false);
                }}
                onCancel={() => toggleDeleteDialog(false)}
            />
        </>
    );
}

export default SelectedItemsInfo;