export const allEqual = (directions) =>
  directions.every((direction) => direction === directions[0]);

export const difference = <T>(arr1: T[], arr2: T[]) => arr1.filter((x) => !arr2.includes(x));

export const range = (start: number, end: number) =>
  Array.from({ length: end - start + 1 }, (_, i) => start + i);

export const removeItem = <T>(array: T[], item: T) => array.filter((e) => e !== item);
export const removeItems = <T>(array: T[], items: T[]) => array.filter((e) => !items.includes(e));
export const unique = <T>(array: T[]) => [...new Set(array)];
export const isUnique = <T>(array: T[]) => new Set(array).size == 1;

export const toMap = <K, V>(array: V[], keySelector: (item: V) => K) => {
  const map = new Map<K, V>();
  array.forEach((item) => map.set(keySelector(item), item));
  return map;
};

export const updateItem = <T>(array: T[], data: Partial<T>, predicate?: (item: T) => boolean) => {
  if (predicate) {
    const newArray = [...array];
    const index = array.findIndex(predicate);

    if (index === -1) {
      return newArray;
    }

    if (typeof newArray[index] == 'object') {
      newArray[index] = {
        ...newArray[index],
        ...data,
      };
    } else {
      newArray[index] = data as T;
    }
    return newArray;
  }
  return array.map((item) => ({ ...item, ...data }));
};

export const wrap = <T>(item: T | T[]): T[] => (Array.isArray(item) ? item : [item]);

export const intersect = <T>(array1: T[], array2: T[]): T[] =>
  array1.filter((value) => array2.includes(value));

export const isIntersect = <T>(array1: T[], array2: T[]): boolean =>
  intersect(array1, array2).length > 0;

type PrimitiveContentArray = (string | number | boolean | null | undefined)[];
export const primitiveArraysHaveEqualContents = (
  arr1: PrimitiveContentArray,
  arr2: PrimitiveContentArray,
): boolean => {
  const arr1Length = arr1.length;
  const arr2Length = arr2.length;

  if (arr1Length === 0 && arr2Length === 0) return true;
  if (arr1Length !== arr2Length) return false;

  const arr1Sorted = [...arr1].sort();
  const arr2Sorted = [...arr2].sort();

  return arr1Sorted.every((arr1Item, i) => arr1Item === arr2Sorted[i]);
};
