import { memo } from 'react';
import { Group } from 'react-konva';
import { useRecoilValue } from 'recoil';

import { LayerNames } from '@modules/common/types/layers';
import { crossingIdsState } from '@modules/connections/crossings/store';
import { layersLockSelector, layersShowSelector } from '@modules/layers';
import CrossingBubble from './CrossingBubble';

const CrossingsGroupComponent = () => {
  const show = useRecoilValue(layersShowSelector(LayerNames.CONNECTIONS));
  const locked = useRecoilValue(layersLockSelector(LayerNames.CONNECTIONS));
  const ids = useRecoilValue(crossingIdsState);

  if (!show && ids?.length) return null;

  return (
    <Group listening={!locked}>
      {ids.map((id) => (
        <CrossingBubble key={id} crossingId={id} />
      ))}
    </Group>
  );
};

export const CrossingsGroup = memo(CrossingsGroupComponent);
