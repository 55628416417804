export type BaseNotification = {
  id: string;
  description: string;
  read: boolean;
  type: NotificationType;
  timestamp: Date;
  title?: string;
};

export type Notification = BaseNotification & {
  type: NotificationType.INFO | NotificationType.SUCCESS | NotificationType.WARNING;
};

export enum NotificationType {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export enum SupportedLanguages {
  ENGLISH = 'en',
  JAPANESE = 'ja',
}

export type NotificationDescription = {
  language: SupportedLanguages;
  description: string;
};

export type ErrorMessageResponse = {
  code: string;
  errorType: string;
  system: string;
  context: string | null;
  descriptions: NotificationDescription[];
};

export interface ErrorNotification extends BaseNotification {
  type: NotificationType.ERROR;
  errorType?: ErrorType;
  context?: string;
  code?: string;
  system?: string;
}

export enum ErrorType {
  GENERAL = 'GENERAL',
  BUSINESS_LOGIC = 'BUSINESS_LOGIC ',
}
