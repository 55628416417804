import shapeAtom from '@/store/recoil/shape/atom';
import { theme } from '@common/components/theme';
import { layoutFlowSelector, layoutFlowsSelector } from '@modules/flows/store/layout';
import {
  shapeBoundingBoxSelector,
  shapeGroupBoundingBoxSelector,
  shapeGroupState,
} from '@modules/shapeGroups';
import { FlowScope, FlowStopType } from './types';
import { simulationFlowsSelector } from './store/simulation/selector';
import { simulationFlowAtom } from './store/simulation/atom';

export const getScopedFlowSelector = (scope: FlowScope) => {
  switch (scope) {
    case FlowScope.SIMULATION:
      return simulationFlowAtom;
    case FlowScope.LAYOUT:
    default:
      return layoutFlowSelector;
  }
};

export const getScopedFlowsSelector = (scope: FlowScope) => {
  switch (scope) {
    case FlowScope.SIMULATION:
      return simulationFlowsSelector;
    case FlowScope.LAYOUT:
    default:
      return layoutFlowsSelector;
  }
};

export const getFlowArrowPerpendicularLine = (
  points: [number, number, number, number],
  direction: -1 | 1,
  length: number,
  normalizedZoom: number,
) => {
  const vector = { x: points[2] - points[0], y: points[3] - points[1] };
  const magnitude = Math.sqrt(vector.x * vector.x + vector.y * vector.y);

  if (magnitude === 0) {
    return null;
  }

  const normalizedVector = { x: vector.x / magnitude, y: vector.y / magnitude };
  const angle = Math.atan2(normalizedVector.y, normalizedVector.x);
  const diffAngle = angle - Math.PI / 4;
  const arrowLength = length / normalizedZoom;

  return direction === -1
    ? [
        points[2],
        points[3],
        points[2] - Math.cos(diffAngle) * arrowLength,
        points[3] - Math.sin(diffAngle) * arrowLength,
      ]
    : [
        points[2],
        points[3],
        points[2] + Math.cos(diffAngle - Math.PI * 0.5) * arrowLength,
        points[3] + Math.sin(diffAngle - Math.PI * 0.5) * arrowLength,
      ];
};

export const generateActiveFlowLinePoints = (startPoint, mousePosition) => [
  startPoint.x,
  startPoint.y,
  mousePosition.x,
  mousePosition.y,
];

export const getFlowStopSelectorByFlowStopType = (flowStopType: FlowStopType) => {
  switch (flowStopType) {
    case FlowStopType.AREA:
      return shapeAtom;
    case FlowStopType.AREA_GROUP:
    default:
      return shapeGroupState;
  }
};

// gets a selector by which we can get the bounding box of the flow stop based on its type
export const genDynamicFlowStopBoundingBoxSelector = (type: FlowStopType) => {
  switch (type) {
    case FlowStopType.AREA:
      return shapeBoundingBoxSelector;
    case FlowStopType.AREA_GROUP:
    default:
      return shapeGroupBoundingBoxSelector;
  }
};

export const FLOW_LINE_COLOR = theme.palette.status.scheduled;
export const FLOW_LINE_SELECTED_OPACITY = 100;
