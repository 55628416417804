import { Stack } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { MaterialType } from '@/modules/common/types/shapes';
import { useFloorPlanState } from '@/modules/floorplan/hooks/useFloorPlanState';
import { UserPreferenceName } from '@/modules/userPreferences';
import { useUpdateUserPreferences } from '@/modules/userPreferences/hooks';
import { unitSelector, unitValueSelector } from '@/store/recoil/workspace';
import { Accordion } from '@common/components/Accordion';
import { NumberInput, SelectInput } from '@common/components/inputs';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { CONTEXT, contextState } from '@recoil/input';
import Label from '../Label';
import { wallsHeightState, wallsMaterialState, wallsWidthState } from '../store/wall';

export default function WallProperties() {
  const { saveFloorPlan } = useFloorPlanState();
  const [thickness, setThickness] = useRecoilState(wallsWidthState);
  const [height, setHeight] = useRecoilState(wallsHeightState);
  const [material, setMaterial] = useRecoilState(wallsMaterialState);
  const unit = useRecoilValue(unitSelector);
  const { t } = useTranslation(['interface', 'common']);
  const setContext = useSetRecoilState(contextState);
  const { updateUserPreference } = useUpdateUserPreferences();
  const mode = useRecoilValue(modeSelector);

  const onThicknessChange = useCallback(
    (value) => {
      try {
        setThickness(value);
        saveFloorPlan();
        updateUserPreference(UserPreferenceName.BUILDING_WALL_THICKNESS, value);
      } catch (e) {
        return { error: e.message };
      }
    },
    [updateUserPreference, setThickness, saveFloorPlan],
  );

  const onHeightChange = useCallback(
    (value) => {
      try {
        setHeight(value);
        saveFloorPlan();
        updateUserPreference(UserPreferenceName.BUILDING_WALL_HEIGHT, value);
      } catch (e) {
        return { error: e.message };
      }
    },
    [setHeight, saveFloorPlan, updateUserPreference],
  );

  const onMaterialChange = useCallback(
    (value) => {
      try {
        setMaterial(value);
        saveFloorPlan();
        updateUserPreference(UserPreferenceName.BUILDING_WALL_MATERIAL, value);
      } catch (e) {
        return { error: e.message };
      }
    },
    [setMaterial, saveFloorPlan, updateUserPreference],
  );

  return (
    <Accordion
      title={t('interface:properties.wall.wall_title', 'Wall')}
      defaultExpanded
      sx={{ px: 2 }}
    >
      <Stack direction='column' spacing={1} sx={{ '&:hover': { cursor: 'default' } }}>
        <Label label={t('interface:properties.wall.thickness', 'Thickness')}>
          <NumberInput
            disabled={mode !== WorkspaceMode.EDITABLE}
            value={useRecoilValue(unitValueSelector(thickness))}
            errorType='floating'
            unit={t(`interface:settings.units.${unit}`, unit)}
            onFocus={() => setContext(CONTEXT.PROPERTYPANEL)}
            onBlur={() => setContext(CONTEXT.WORKSPACE)}
            onChange={onThicknessChange}
          />
        </Label>
        <Label label={t('interface:properties.wall.height', 'Height')}>
          <NumberInput
            disabled={mode !== WorkspaceMode.EDITABLE}
            value={useRecoilValue(unitValueSelector(height))}
            errorType='floating'
            unit={t(`interface:settings.units.${unit}`, unit)}
            onFocus={() => setContext(CONTEXT.PROPERTYPANEL)}
            onBlur={() => setContext(CONTEXT.WORKSPACE)}
            onChange={onHeightChange}
          />
        </Label>
        <Label label={t('interface:properties.wall.material', 'Material')}>
          <SelectInput
            disabled={mode !== WorkspaceMode.EDITABLE}
            options={Object.entries(MaterialType).map(([k, v]) => ({ label: k, value: v }))}
            onChange={onMaterialChange}
            value={material}
          />
        </Label>
      </Stack>
    </Accordion>
  );
}
