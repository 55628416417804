import * as React from 'react';

function Visibility({ className = '', ...rest }) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      style={{ fill: 'currentColor' }}
      className={className}
      {...rest}
    >
      <path d='M12 15.15C13.0166 15.15 13.8793 14.7957 14.588 14.087C15.296 13.379 15.65 12.5167 15.65 11.5C15.65 10.4833 15.296 9.62067 14.588 8.912C13.8793 8.204 13.0166 7.85 12 7.85C10.9833 7.85 10.121 8.204 9.41295 8.912C8.70428 9.62067 8.34995 10.4833 8.34995 11.5C8.34995 12.5167 8.70428 13.379 9.41295 14.087C10.121 14.7957 10.9833 15.15 12 15.15ZM12 14.2C11.25 14.2 10.6126 13.9373 10.088 13.412C9.56262 12.8873 9.29995 12.25 9.29995 11.5C9.29995 10.75 9.56262 10.1123 10.088 9.587C10.6126 9.06233 11.25 8.8 12 8.8C12.75 8.8 13.3876 9.06233 13.913 9.587C14.4376 10.1123 14.7 10.75 14.7 11.5C14.7 12.25 14.4376 12.8873 13.913 13.412C13.3876 13.9373 12.75 14.2 12 14.2ZM12 18C9.83328 18 7.85828 17.4127 6.07495 16.238C4.29162 15.0627 2.95828 13.4833 2.07495 11.5C2.95828 9.51667 4.29162 7.93733 6.07495 6.762C7.85828 5.58733 9.83328 5 12 5C14.1666 5 16.1416 5.58733 17.925 6.762C19.7083 7.93733 21.0416 9.51667 21.9249 11.5C21.0416 13.4833 19.7083 15.0627 17.925 16.238C16.1416 17.4127 14.1666 18 12 18ZM12 17C13.8833 17 15.6126 16.504 17.188 15.512C18.7626 14.5207 19.9666 13.1833 20.7999 11.5C19.9666 9.81667 18.7626 8.479 17.188 7.487C15.6126 6.49567 13.8833 6 12 6C10.1166 6 8.38728 6.49567 6.81195 7.487C5.23728 8.479 4.03328 9.81667 3.19995 11.5C4.03328 13.1833 5.23728 14.5207 6.81195 15.512C8.38728 16.504 10.1166 17 12 17Z' />
    </svg>
  );
}

const VisibilityIcon = React.memo(Visibility);
export default VisibilityIcon;
