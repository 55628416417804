import { SvgIcon } from '@mui/material';

export default function AreaIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d='M 12.036946,15.673887 V 3.1879852'
        stroke='#ffffff'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M 8.4695579,6.0699441 12.036946,2.5025553 15.604334,6.0699441'
        stroke='#ffffff'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M 1.3347822,21.230896 H 22.73911'
        stroke='#ffffff'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  );
}
