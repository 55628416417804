import { selector } from 'recoil';
import { StageProps } from '@helpers/types';
import workspaceAtom from './atom';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../common';

export const stageSelector = selector<StageProps>({
  key: 'stageSelector',
  get: ({ get }) => get(workspaceAtom).stageProps,
  set: ({ get, set }, newValue: StageProps) => {
    set(workspaceAtom, { ...get(workspaceAtom), stageProps: newValue });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
