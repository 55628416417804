import { IconButton, Stack, Typography, Divider } from '@mui/material';
import { StyledTooltip } from '@common/components/StyledTooltip';
import { CloseIcon } from '@/assets/icons';
import { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  labelKey: string;
  children: ReactNode;
  onCloseClick: () => void;
  contentScroll?: boolean;
};

const containerSx = {
  height: '100%',
};

const headerSx = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  p: 2,
  flex: '0 0 auto',
};

const contentSx = {
  flex: '1 1 auto',
  overflowY: 'scroll',
  scrollbarWidth: 'none',
  '&:hover': { cursor: 'default' },
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

export const PropertyPanelContainer = ({
  children,
  labelKey,
  onCloseClick,
  contentScroll = true,
}: Props) => {
  const { t } = useTranslation('common');

  return (
    <Stack sx={containerSx}>
      <Stack sx={headerSx}>
        <Typography variant='subtitle2'>
          <Trans i18nKey={labelKey} />
        </Typography>
        <StyledTooltip title={t('close')}>
          <IconButton onClick={onCloseClick} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
        </StyledTooltip>
      </Stack>
      <Divider />
      {contentScroll ? <Stack sx={contentScroll ? contentSx : null}>{children}</Stack> : children}
    </Stack>
  );
};
