import { selector } from 'recoil';
import { Vector2, Vector3 } from 'three';

import { valueCheck } from '@/helpers/input';
import { unitConverterSelector } from '@/store/recoil/workspace';
import { calculateTranslateVector } from '@helpers/utils';
import { calculateShapesBoundingBox } from '@modules/common/helpers/shapes';
import {
  isAngledHighwayShape,
  isPointsProperties,
  isPointsShape,
  isProcessAreaTwoEp
} from '@modules/common/types/guards';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { shapeProperty } from '@recoil/shape';
import { selectedShapesRotationState, selectedShapesState } from '@recoil/shapes/selected';

export const shapesPositionState = selector<Partial<Vector3>>({
  key: 'propertiesPanelShapesPosition',
  get: ({ get }) => {
    const selectedShapes = get(selectedShapesState);
    let position = { x: 0, y: 0 };

    if (selectedShapes.length === 0) {
      return position;
    }

    if (selectedShapes.length > 1) {
      position = calculateShapesBoundingBox(get(selectedShapesState));
    } else if (isPointsShape(selectedShapes[0]) || isProcessAreaTwoEp(selectedShapes[0])) {
      position = calculateShapesBoundingBox(get(selectedShapesState));
    } else {
      position = selectedShapes[0].properties;
    }

    return new Vector3(position.x, position.y, 0);
  },
  set: ({ get, set }, { x, y }: Partial<Vector3>) => {
    x = x === undefined ? undefined : valueCheck(x);
    y = y === undefined ? undefined : valueCheck(y);

    const selectedShapes = get(selectedShapesState);

    if (
      selectedShapes.length === 1 &&
      !isPointsShape(selectedShapes[0]) &&
      !isProcessAreaTwoEp(selectedShapes[0])
    ) {
      const selectedShape = selectedShapes[0];
      set(shapeProperty(selectedShape.id), {
        ...selectedShape.properties,
        x: x === undefined ? selectedShape.properties.x : Math.floor(get(unitConverterSelector(x))),
        y: y === undefined ? selectedShape.properties.y : Math.floor(get(unitConverterSelector(y))),
      });
      return;
    }
    const boundingBox = calculateShapesBoundingBox(selectedShapes);
    const translateVector = calculateTranslateVector(
      {
        x: x === undefined ? boundingBox.x : get(unitConverterSelector(x)),
        y: y === undefined ? boundingBox.y : get(unitConverterSelector(y)),
      },
      { x: boundingBox.x, y: boundingBox.y },
    );

    selectedShapes.forEach(({ id, properties }) => {
      if (isPointsProperties(properties)) {
        const newControlPoints = properties.controlPoints.map((item) => {
          const newPosition = new Vector2(item.position.x, item.position.y).add(translateVector);
          return {
            ...item,
            position: newPosition,
          };
        });

        set(shapeProperty(id), {
          ...properties,
          controlPoints: newControlPoints,
        });

        return;
      }

      const potentialNewPosition = new Vector2(properties.x, properties.y).add(translateVector);

      set(shapeProperty(id), {
        ...properties,
        ...potentialNewPosition,
      });
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const shapesHeightState = selector<number>({
  key: 'propertiesPanelShapesHeight',
  get: ({ get }) => {
    const selectedShapes = get(selectedShapesState);

    if (selectedShapes.length === 0) {
      return 0;
    }

    if (selectedShapes.length > 1) {
      return calculateShapesBoundingBox(get(selectedShapesState)).height;
    }

    if (isPointsShape(selectedShapes[0]) || isProcessAreaTwoEp(selectedShapes[0])) {
      return calculateShapesBoundingBox(get(selectedShapesState)).height;
    }

    return selectedShapes[0].properties.height;
  },
  set: ({ get, set }, value: number) => {
    value = valueCheck(value);
    const selectedShapes = get(selectedShapesState);
    const valueInMillimeter = get(unitConverterSelector(value));

    if (selectedShapes.length === 1) {
      const shape = selectedShapes[0];
      if (isAngledHighwayShape(shape)) return;
      set(shapeProperty(shape.id), {
        ...shape.properties,
        height: Math.round(valueInMillimeter),
      });
    }
    // else { // TODO: define behaviour for scaling a selection with angled shapes
    //   const boundingBox = calculateShapesBoundingBox(selectedShapes);
    //   const { height: boundingBoxHeight } = boundingBox;
    //   const ratio = valueInMillimeter / boundingBoxHeight;

    //   selectedShapes.forEach(({ id, properties }) => {
    //     if (isAngledHighwayProperties(properties)) {
    //       // TODO: angled highway support
    //       return;
    //     }

    //     const { height, y } = scale(properties, ratio, { origin: boundingBox });

    //     set(shapeProperty(id), {
    //       ...properties,
    //       height: Math.round(height),
    //       y,
    //     });
    //   });
    // }
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const shapesWidthState = selector<number>({
  key: 'propertiesPanelShapesWidth',
  get: ({ get }) => {
    const selectedShapes = get(selectedShapesState);

    if (selectedShapes.length === 0) {
      return 0;
    }

    if (selectedShapes.length > 1) {
      return calculateShapesBoundingBox(get(selectedShapesState)).width;
    }

    if (isPointsShape(selectedShapes[0]) || isProcessAreaTwoEp(selectedShapes[0])) {
      return calculateShapesBoundingBox(get(selectedShapesState)).width;
    }

    return selectedShapes[0].properties.width;
  },
  set: ({ get, set }, value: number) => {
    value = valueCheck(value);
    const selectedShapes = get(selectedShapesState);
    const valueInMillimeter = get(unitConverterSelector(value));

    if (selectedShapes.length === 1) {
      const shape = selectedShapes[0];
      if (isAngledHighwayShape(shape)) return;
      set(shapeProperty(shape.id), {
        ...shape.properties,
        width: Math.round(valueInMillimeter),
      });
    }
    // else { // TODO: define behaviour for scaling a selection with angled shapes
    //   const boundingBox = calculateShapesBoundingBox(selectedShapes);
    //   const { width: boundingBoxWidth } = boundingBox;
    //   const ratio = valueInMillimeter / boundingBoxWidth;

    //   selectedShapes.forEach(({ id, properties }) => {
    //     if (isAngledHighwayProperties(properties)) {
    //       // TODO: angled highway support
    //       return;
    //     }

    //     const { width, x } = scale(properties, ratio, { origin: boundingBox });
    //     set(shapeProperty(id), {
    //       ...properties,
    //       width: Math.round(width),
    //       x,
    //     });
    //   });
    // }
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const shapesAngleState = selector<number>({
  key: 'propertiesPanelShapesAngle',
  get: ({ get }) => {
    const selectedShapes = get(selectedShapesState);
    if (selectedShapes.length > 1) return get(selectedShapesRotationState);
    if (isPointsShape(selectedShapes[0]) || isProcessAreaTwoEp(selectedShapes[0])) return 0;

    return selectedShapes[0].properties.r;
  },
  set: ({ get, set }, value: number) => {
    value = valueCheck(value);
    const selectedShapes = get(selectedShapesState);

    selectedShapes.forEach(({ id, properties }) => {
      if (isPointsProperties(properties)) {
        // TODO: angled highway support
        return;
      }

      set(shapeProperty(id), {
        ...properties,
        r: value,
      });
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
