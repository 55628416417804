import { Status } from '@module/simulation/components/Status';
import { TopButtons } from '@module/simulation/components/TopButtons';
import { Simulation } from '@module/simulation/helpers/types';
import { useSimulationCallbacks } from '@modules/simulation';
import { Stack } from '@mui/material';
import { useCallback } from 'react';

type Props = {
  simulation: Simulation;
  isHover: boolean;
};

export const CardHeader = ({ simulation, isHover }: Props) => {
  const { openFleetTracker } = useSimulationCallbacks();

  const onViewClick = useCallback(
    async () => await openFleetTracker(simulation),
    [simulation, openFleetTracker],
  );

  return (
    <Stack
      sx={{
        alignItems: 'center',
        flexDirection: 'row',
        gap: 1,
        mb: 1,
      }}
    >
      <Status simulation={simulation} />
      <Stack
        sx={{ alignItems: 'center', flexDirection: 'row', ml: 'auto', gap: 2, height: '32px' }}
      >
        {isHover && <TopButtons simulation={simulation} onViewClick={onViewClick} />}
      </Stack>
    </Stack>
  );
};
