import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { layersLockSelector, layersShowSelector } from '@modules/layers';
import { LayerNames } from '@/modules/common/types/layers';

import { referenceSelected } from '../../store';

export const useLayers = () => {
  const locked = useRecoilValue(layersLockSelector(LayerNames.REFERENCE));
  const visible = useRecoilValue(layersShowSelector(LayerNames.REFERENCE));
  const setIsReferenceSelected = useSetRecoilState(referenceSelected);

  useEffect(() => {
    setIsReferenceSelected(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locked]);

  return {
    visible,
    locked,
  };
};
