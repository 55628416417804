import { useRecoilCallback } from 'recoil';
import { getRelatedAndUnrelatedConnectionIds } from '../../common/helpers';
import { allConnectionsSelector, removeConnectionsSelector } from '../store';

export const useRemoveConnections = () => {
  const removeConnectionsByShapes = useRecoilCallback(
    ({ snapshot, set }) =>
      async (shapeIds: string[]) => {
        const { relatedConnections } = getRelatedAndUnrelatedConnectionIds(
          await snapshot.getPromise(allConnectionsSelector),
          shapeIds,
        );

        set(
          removeConnectionsSelector,
          relatedConnections.map((item) => item.id),
        );
      },
    [],
  );

  return {
    removeConnectionsByShapes,
  };
};
