import { useCallback } from 'react';
import { useRecoilCallback } from 'recoil';

import {
  allConnectionsSelector,
  UndoRedoConnectionsState,
  useUndoRedoConnections as useUndoRedoConnectionsInner,
} from '@modules/connections/connections';
import {
  allCrossingSelector,
  UndoRedoCrossingsState,
  useUndoRedoCrossings,
} from '@modules/connections/crossings';
import {
  allDistantConnectionsSelector,
  UndoRedoDistantConnectionsState,
  useUndoRedoDistantConnections,
} from '@modules/connections/distant';
import { getRelatedAndUnrelatedConnectionIds } from '../helpers';

export type UndoRedoState = {
  connections: UndoRedoConnectionsState;
  distantConnections: UndoRedoDistantConnectionsState;
  crossings: UndoRedoCrossingsState;
};

export const useUndoRedoConnections = () => {
  const { restoreDistantConnectionsState } = useUndoRedoDistantConnections();
  const { restoreConnectionsState } = useUndoRedoConnectionsInner();
  const { restoreCrossingsState } = useUndoRedoCrossings();

  const getUndoRedoState = useRecoilCallback(
    ({ snapshot }) =>
      async (
        shapeIds: string[],
      ): Promise<{ newConnectionState: UndoRedoState; oldConnectionState: UndoRedoState }> => {
        const connections = await snapshot.getPromise(allConnectionsSelector);
        const { unrelatedConnections } = getRelatedAndUnrelatedConnectionIds(connections, shapeIds);

        const distantConnections = await snapshot.getPromise(allDistantConnectionsSelector);
        const { unrelatedConnections: unrelatedDistantConnections } =
          getRelatedAndUnrelatedConnectionIds(distantConnections, shapeIds);

        const crossings = await snapshot.getPromise(allCrossingSelector);
        
        const { unrelatedConnections: unrelatedCrossings } = getRelatedAndUnrelatedConnectionIds(
          crossings,
          shapeIds,
        );

        return {
          newConnectionState: {
            connections: {
              connections: unrelatedConnections,
            },
            distantConnections: {
              connections: unrelatedDistantConnections,
            },
            crossings: {
              crossings: unrelatedCrossings,
            },
          },
          oldConnectionState: {
            connections: {
              connections,
            },
            distantConnections: {
              connections: distantConnections,
            },
            crossings: {
              crossings,
            },
          },
        };
      },
    [],
  );

  const restoreUndoRedoState = useCallback(
    async (state: UndoRedoState) => {
      await restoreConnectionsState(state.connections);
      await restoreDistantConnectionsState(state.distantConnections);
      await restoreCrossingsState(state.crossings);
    },
    [restoreCrossingsState, restoreConnectionsState, restoreDistantConnectionsState],
  );

  return {
    getUndoRedoState,
    restoreUndoRedoState,
  };
};
