import { supportsLoadCarriers } from '@/modules/common/helpers/shapes';
import { ShapeType, AreaLoadCarrierOrientation } from '@modules/common/types/shapes';
import { UserPreferenceName, getUserPreference } from '@modules/userPreferences';

export const carrierSide = (shapeType: ShapeType): AreaLoadCarrierOrientation | null => {
  if (!supportsLoadCarriers(shapeType)) return null;

  switch (shapeType) {
    case ShapeType.INTAKE:
    case ShapeType.INTAKE_POSITION:
      return getUserPreference(UserPreferenceName.INTAKE_CARRIER_SIDE);
    case ShapeType.STORAGE:
    case ShapeType.STORAGE_POSITION:
      return getUserPreference(UserPreferenceName.STORAGE_CARRIER_SIDE);
    case ShapeType.DELIVERY:
    case ShapeType.DELIVERY_POSITION:
      return getUserPreference(UserPreferenceName.DELIVERY_CARRIER_SIDE);
    case ShapeType.PROCESS_ONE_EP:
    case ShapeType.PROCESS_ONE_EP_POSITION:
      return getUserPreference(UserPreferenceName.PROCESS_CARRIER_SIDE);
    default:
      return AreaLoadCarrierOrientation.SHORT_SIDE;
  }
};
