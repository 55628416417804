import { getTransparentBackgroundExtension } from '../extensions';
import { AuthInfo } from '../types';

export const initializeGlobals = (tokenCallback: () => Promise<AuthInfo>) => {
  const options: Autodesk.Viewing.InitializerOptions = {
    env: 'AutodeskProduction2',
    api: 'streamingV2_EU',
    getAccessToken: async (onTokenReady: (token: string, time: number) => void) => {
      const { accessToken, expiresIn } = await tokenCallback();

      onTokenReady(accessToken, expiresIn);
    },
  };

  // Transparent background
  Autodesk.Viewing.theExtensionManager.registerExtension(
    'TransparentBackgroundExtension',
    getTransparentBackgroundExtension(),
  );
  (Autodesk.Viewing as any).Private.InitParametersSetting.alpha = true;

  return new Promise<void>((resolve) => {
    Autodesk.Viewing.Initializer(options, () => resolve());
  });
};

export const initializedViewer = (element: HTMLElement, gui: boolean) => {
  const ViewerClass = gui ? Autodesk.Viewing.GuiViewer3D : Autodesk.Viewing.Viewer3D;
  const viewer = new ViewerClass(element, {
    extensions: ['TransparentBackgroundExtension'],
  });

  const startedCode = viewer.start();
  const { Viewing }: any = Autodesk;
  const profileSettings = Viewing.ProfileSettings.clone(Viewing.ProfileSettings.Default);
  profileSettings.settings.reverseMouseZoomDir = true;
  profileSettings.settings.loadingAnimation = false;
  profileSettings.settings.progressiveRendering = true;
  (viewer as any).setProfile(new Viewing.Profile(profileSettings));

  if (!gui) {
    const { toolController } = viewer as any;
    toolController.deregisterTool(toolController.getTool('hotkeys'));
    toolController.deregisterTool(toolController.getTool('gestures'));
    toolController.deregisterTool(toolController.getTool('pan'));
  }

  if (startedCode > 0) {
    console.error('Failed to create a Viewer: WebGL not supported.');
  }

  return viewer;
};

export const waitForViewerLoaded = (viewer: Autodesk.Viewing.Viewer3D, callback: () => void) => {
  const events = [
    Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
    Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT,
  ];

  let loadedCount = 0;
  const callbacks = [];

  events.forEach((event) => {
    const cb = () => {
      loadedCount += 1;
      if (loadedCount === 2) {
        callback();
      }
    };

    callbacks.push({ event, cb });
    viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, cb);
  });

  return () => {
    callbacks.forEach(({ event, cb }) => viewer.removeEventListener(event, cb));
  };
};
