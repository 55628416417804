import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { supportsLoadCarriers } from '@/modules/common/helpers/shapes';
import { AreaError } from '@/modules/common/types/shapes';
import {
  supportedVehiclesLengthSelector,
  supportedVehiclesWidthSelector,
} from '@/modules/vehicles';
import shapeState, { AreaShape, areaParameters } from '@recoil/shape';

export const useError = (id: string) => {
  const shape = useRecoilValue(shapeState(id)) as AreaShape;
  const params = useRecoilValue(areaParameters(id));
  const vehicleLength = useRecoilValue(
    supportedVehiclesLengthSelector(params.supportedVehicleIds || []),
  );
  const vehicleWidth = useRecoilValue(
    supportedVehiclesWidthSelector(params.supportedVehicleIds || []),
  );

  return useMemo(() => {
    if (!shape || !params) {
      return null;
    }

    if (
      supportsLoadCarriers(shape.type) &&
      (!shape.parameters.supportedLoadCarriersIds ||
        shape.parameters.supportedLoadCarriersIds.length === 0)
    ) {
      return AreaError.AbsentLoadCarrier;
    }

    if (!vehicleLength || !vehicleWidth) {
      return AreaError.AbsentVehicle;
    }

    return shape.error ?? null;
  }, [params, shape, vehicleLength, vehicleWidth]);
};
