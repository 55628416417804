import { useRecoilValue } from 'recoil';
import { PreValidationContent } from './PreValidationContent';
import { preValidationShowSelector } from '../store';

export const PreValidation = () => {
  const show = useRecoilValue(preValidationShowSelector);

  if (!show) return null;

  return <PreValidationContent />;
};
