import { selector } from 'recoil';
import shapeAtom from '../../shape/atom';
import areasAtom from './atom';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../common';
import { AreaShape } from '../../shape';
import { setAtomFamilyWithIdsAtom } from '../../common/helper';

const allAreasSelector = selector<AreaShape[]>({
  key: 'allAreasSelector',
  get: ({ get }) => get(areasAtom).map((id) => get(shapeAtom(id))) as AreaShape[],
  set: (getSetResetRecoilState, newAreas: AreaShape[]) => {
    setAtomFamilyWithIdsAtom(getSetResetRecoilState, shapeAtom, areasAtom, newAreas);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default allAreasSelector;
