import { MaterialType, ObstacleType, ShapeType } from '@modules/common/types/shapes';
import { TextureVariant, Variant } from '../enum';

/**
 * Treat positions the same as shapes
 */
export const mapPositionTypeToAreaType = (type: ShapeType) => {
  switch (type) {
    case ShapeType.CHARGING_POSITION:
      return ShapeType.CHARGING;
    case ShapeType.DELIVERY_POSITION:
      return ShapeType.DELIVERY;
    case ShapeType.INTAKE_POSITION:
      return ShapeType.INTAKE;
    case ShapeType.PROCESS_ONE_EP_POSITION:
      return ShapeType.PROCESS_ONE_EP;
    case ShapeType.PARKING_POSITION:
      return ShapeType.PARKING;
    case ShapeType.STORAGE_POSITION:
      return ShapeType.STORAGE;
    default:
      return type;
  }
};

export const getMaterialTexture = (material: MaterialType | ObstacleType): TextureVariant => {
  const materialTypeToTextureVariant = {
    [MaterialType.Brick]: TextureVariant.Brick,
    [MaterialType.Concrete]: TextureVariant.Concrete,
    [MaterialType.Stone]: TextureVariant.Stone,
    [ObstacleType.Warning_Zone]: TextureVariant.WarningZone,
  };

  return materialTypeToTextureVariant[material];
};

export const getObstacleVariant = (type: ObstacleType): Variant => {
  const obstacleTypeToVariant = {
    undefined: Variant.Pillar,
    [ObstacleType.Pillar]: Variant.Pillar,
    [ObstacleType.Box]: Variant.Rectangle,
    [ObstacleType.Warning_Zone]: Variant.Plane,
  };

  return obstacleTypeToVariant[type];
};
