import { Module } from '@/modules/common/types/navigation';
import { atom, selector } from 'recoil';
import {
  isReferenceScaledSelector,
  isReferencePresentSelector,
} from '../../../modules/referenceImage/store';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../common';

const navAtom = atom<Module>({
  key: 'nav',
  default: selector({
    key: 'nav/default',
    get: ({ get }) => {
      const referenceIsPresent = get(isReferencePresentSelector);
      const referenceIsScaled = get(isReferenceScaledSelector)

      if (referenceIsPresent && !referenceIsScaled) {
        return Module.SETUP;
      }

      return Module.LAYOUT;
    },
    cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
  }),
});

export default navAtom;
