import { LegacyAdditionalData, LoadCarrierType } from '@modules/common/types/floorPlan';
import { createLoadCarrierId } from '@modules/loadCarriers';
import { createVehicleTypeId, DEFAULT_ENABLED_VEHICLE_TYPE_IDS } from '@modules/vehicles';
import { DEFAULT_ENABLED_LOAD_TYPE_IDS } from '@modules/common/constants/loadTypes';
import { LayerNames } from '@/modules/common/types/layers';

export const useBackwardCompatibility = () => {
  const convertLoadTypes = (additionalData: LegacyAdditionalData) =>
    additionalData.loadTypes
      ? additionalData.loadTypes?.map((item: string | LoadCarrierType) =>
          typeof item === 'string' ? item : item.id,
        )
      : typeof additionalData.loadType === 'string'
      ? [createLoadCarrierId(additionalData.loadType)]
      : DEFAULT_ENABLED_LOAD_TYPE_IDS;

  const convertVehicleType = (additionalData: LegacyAdditionalData) =>
    additionalData.vehicleTypes
      ? additionalData.vehicleTypes
      : additionalData.vehicleType // Legacy support
      ? [createVehicleTypeId(additionalData.vehicleType)]
      : DEFAULT_ENABLED_VEHICLE_TYPE_IDS;

  const convertLayers = (additionalData: LegacyAdditionalData) => {
    const { layers } = additionalData;
    if (!layers?.layers) return layers;

    Object.values(LayerNames).forEach((layerName) => {
      if (!layers.layers[layerName])
        layers.layers[layerName] = {
          visible: false,
          locked: false,
        };
    });
    return layers;
  };

  const convert = (additionalData: LegacyAdditionalData) => ({
    ...additionalData,
    loadTypes: convertLoadTypes(additionalData),
    vehicleTypes: convertVehicleType(additionalData),
    layers: convertLayers(additionalData),
  });

  return {
    convert,
  };
};
