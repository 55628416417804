import { atom, atomFamily, selectorFamily } from 'recoil';
import { DistantConnection } from '@modules/common/types/connections';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

export const distcon = atomFamily<DistantConnection, string>({
  key: 'distcon',
  default: selectorFamily({
    key: 'distcon/default',
    get: (id) => () => ({
      id,
      from: null,
      to: null,
      fromPosition: {
        x: 0,
        y: 0,
      },
      toPosition: {
        x: 0,
        y: 0,
      },
      bubblePosition: {
        x: 0,
        y: 0,
      },
      rot: 0,
      inChecked: true,
      outChecked: true,
      usePivots: false,
    }),
    cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
  }),
});

export const allDistconIds = atom<string[]>({
  key: 'distcon/allDistconIds',
  default: [],
});

export const activeDistantConnectionState = atom<Pick<DistantConnection, 'from' | 'id'>>({
  key: 'distcon/active',
  default: null,
});
