export type Mode = 'integer' | 'positive-integer' | 'negative-integer';

export const getRegex = (mode: Mode) => {
  switch (mode) {
    case 'integer': {
      return /^[+-]?([0-9]|([1-9][0-9]*))$/;
    }

    case 'positive-integer': {
      return /^([0-9]|([1-9][0-9]*))$/;
    }

    case 'negative-integer': {
      return /^-([0-9]|([1-9][0-9]*))$/;
    }

    default: {
      return /^.*$/;
    }
  }
};

export const isNegativeAllowed = (mode: Mode) => mode === 'integer' || mode === 'negative-integer';

export const parseValue = (mode: Mode, value: number) => {
  if (mode === 'negative-integer' && value > 0) {
    return '0';
  }

  if (mode === 'positive-integer' && value < 0) {
    return '0';
  }

  return roundInteger(value);
};

export const roundInteger = (value: number) => Math.round(value).toString();
export const isInteger = (regex: RegExp, value: string) => regex.test(value);
export const parseStringValue = (regex: RegExp, value: string) =>
  isInteger(regex, value) ? value : '0';

// function getDecimalSeparator() {
//   const numberWithDecimalSeparator = 1.1;
//   return Intl.NumberFormat()
//     .formatToParts(numberWithDecimalSeparator)
//     .find((part) => part.type === 'decimal').value;
// }

// const DECIMAL_SEPARATOR = getDecimalSeparator();
// const FLOATING_NUMBER_REGEX = /^[+-]?(([0-9]|([1-9][0-9]*))([.][0-9]*)?|[.][0-9]+)$/;
