import { MathUtils, Vector3 } from 'three';

import { AngledHighwayShape } from '@/modules/angledHighways/types';
import { ControlPoint } from '@/modules/common/types/shapes';
import { WallShape } from '@/store/recoil/shape';
import { Color, Element } from '@thive/canvas';

type Segment = {
  start: Vector3;
  end: Vector3;
};

export const createPointsElements = (shape: WallShape |AngledHighwayShape, color: Color): Element[] =>
  pointsToLineSegments(shape.properties.controlPoints).map((point, index) => {
    const segmentVector = new Vector3().subVectors(point.end, point.start);
    const angleInDegrees = MathUtils.radToDeg(Math.atan2(segmentVector.y, segmentVector.x));

    return {
      type: 'Rect',
      name: `${shape.name}_${index}`,
      id: `${shape.id}_${index}`,
      position: new Vector3().lerpVectors(point.start, point.end, 0.5),
      rotation: new Vector3(0, 0, angleInDegrees),
      size: new Vector3(point.start.distanceTo(point.end), shape.parameters.width),
      fill: color,
      interactivity: {
        selectable: false,
      },
    };
  });

export const pointsToLineSegments = (points: readonly ControlPoint[]) => {
  const lineSegments: Segment[] = [];
  const pointsAmount = points.length;

  for (let i = 0; i < pointsAmount - 1; i++) {
    const start = points[i];
    const end = points[i + 1];
    lineSegments.push({
      start: new Vector3(start.position.x, start.position.y, 1),
      end: new Vector3(end.position.x, end.position.y, 1),
    });
  }

  return lineSegments;
};
