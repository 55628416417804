import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { selector } from 'recoil';

import { simulationAtom } from './simulationAtom';

export const obstructionTimeOutInSecondsSelector = selector<number>({
  key: 'simulations/draft/obstructionTimeOutInSeconds',
  get: ({ get }) => get(simulationAtom)?.obstructionTimeOutInSeconds,
  set: ({ set }, obstructionTimeOutInSeconds: number) =>
    set(simulationAtom, (state) => ({
      ...state,
      obstructionTimeOutInSecondsSelector: obstructionTimeOutInSeconds,
    })),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
