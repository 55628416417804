import { atom } from 'recoil';

import { LoadingType } from '../../types/general';

type Loading = {
  isLoading: boolean;
  loadingType: LoadingType;
  initialLoading: boolean;
};
export const loadingAtom = atom<Loading>({
  key: 'common/loading/atom',
  default: {
    isLoading: false,
    loadingType: LoadingType.OPAQUE,
    initialLoading: true,
  },
});
