import { download } from '@/modules/common/helpers/browser';
import { UnitData } from './types';

export const downloadUnitData = (data: UnitData) => {
  let csv = '';
  data.forEach((connection, fromId) => {
    csv += `${connection.from},${connection.from},0`;
    const tos = Array.from(connection.to);
    for (let i = 0; i < tos.length; ++i) {
      csv += `,${tos[i]}`;
    }
    csv += '\n';
  });
  const filename = 'modifiedUnitData.csv';
  const blob = new Blob([csv], { type: 'csv' });
  download(blob, filename);
};
