import { useEffect, useState } from 'react';

export const useImageRef = (referenceImageFile) => {
  const [imgRef, setImgRef] = useState<CanvasImageSource | undefined>(undefined);

  useEffect(() => {
    if (referenceImageFile !== null) {
      const imageObj = new Image();
      imageObj.onload = () => {
        setImgRef(imageObj);
      };
      imageObj.src = referenceImageFile;
    } else {
      setImgRef(undefined);
    }
  }, [referenceImageFile]);

  return {
    imgRef,
  };
};
