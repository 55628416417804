import DotsIcon from '@/assets/icons/DotsIcon';
import { useNotificationStore } from '../store/useNotificationStore';
import { Popover, Stack, Typography, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function NotificationsHeader() {
  const { clearNotifications } = useNotificationStore();
  const [optionsAnchorEl, setOptionsAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOptionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOptionsAnchorEl(event.currentTarget);
  };

  const handleOptionClose = () => {
    setOptionsAnchorEl(null);
  };

  const { t } = useTranslation();
  const optionsOpen = Boolean(optionsAnchorEl);
  const optionsPopup = optionsOpen ? 'options-popup' : undefined;

  const handleClearNotifications = () => {
    clearNotifications();
    handleOptionClose();
  };

  return (
    <div>
      <Stack px={2} py={1} direction='row' justifyContent='space-between' alignItems='center'>
        <Typography fontSize={14}>{t('interface:notifications.title')}</Typography>
        <IconButton onClick={handleOptionsClick}>
          <DotsIcon />
        </IconButton>
      </Stack>
      <Popover
        id={optionsPopup}
        open={optionsOpen}
        anchorEl={optionsAnchorEl}
        onClose={handleOptionClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Stack p={2} spacing={1}>
          <Typography
            variant='body1'
            onClick={handleClearNotifications}
            sx={{
              cursor: 'pointer',
              width: '100%',
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'neutral.lightGrey',
              },
              transition: 'background-color 0.3s ease',
            }}
          >
            {t('interface:notifications.clear_notifications')}
          </Typography>
        </Stack>
      </Popover>
    </div>
  );
}
