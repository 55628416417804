import { Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography/Typography';
import { useCallback, useEffect, useRef, useState } from 'react';
import { StyledTooltip } from '../StyledTooltip';

export const OverflowTypography = ({ sx, ...props }: TypographyProps) => {
  const textRef = useRef<HTMLElement>();
  const [isOverflow, setIsOverflow] = useState<boolean>(false);

  const update = useCallback(() => {
    if (props) {
      setIsOverflow(
        textRef.current?.scrollWidth > textRef.current?.clientWidth ||
          textRef.current?.offsetHeight < textRef.current?.scrollHeight,
      );
    } else {
      setIsOverflow(false);
    }
  }, [props]);

  useEffect(() => {
    update();
    window.addEventListener('resize', update);

    return () => {
      window.removeEventListener('resize', update);
    };
  }, [update]);

  return (
    <StyledTooltip title={props.children} disabled={!isOverflow}>
      <Typography
        ref={textRef}
        {...props}
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', ...sx }}
      />
    </StyledTooltip>
  );
};
