import { selector } from 'recoil';
import workspaceAtom, { WorkspaceAtom } from './atom';
import { SizeShort } from '@helpers/types';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../common';

export const viewportSelector = selector<SizeShort>({
  key: 'viewportSelector',
  get: ({ get }) => get<WorkspaceAtom>(workspaceAtom).viewport,
  set: ({ get, set }, newValue) => {
    set(workspaceAtom, { ...get(workspaceAtom), viewport: newValue });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
