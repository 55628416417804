import { Slider } from '@mui/material';
import { styled } from '@mui/system';
import { theme } from '@/modules/common/components/theme';

export const StyledSlider = styled(Slider)({
  height: '1px',
  marginBottom: 0,
  '& .MuiSlider-thumb': {
    width: '9px',
    height: '9px',
    backgroundColor: theme.palette.shades.light,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },

  '& .MuiSlider-rail': {
    color: theme.palette.neutral.light,
    opacity: 1,
  },
  '& .MuiSlider-mark': {
    width: '3px',
    height: '3px',
    backgroundColor: theme.palette.primary.main
  },
  '& .MuiSlider-markLabel': {
    fontSize: '10px',
    color: theme.palette.neutral.dark,
    marginTop: '5px'
  },
});
