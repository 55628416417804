import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { selector } from 'recoil';

import { simulationAtom } from './simulationAtom';

export const checkpointSetsSelector = selector<any[]>({
  key: 'simulations/draft/checkpointSets',
  get: ({ get }) => get(simulationAtom)?.checkpointSets ?? [],
  set: ({ set }, checkpointSets: any[]) => { 
    set(simulationAtom, (state) => ({ ...state, checkpointSets }))
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
