import { useCallback, useState } from 'react';
import { useVisualizationNotification } from './useVisualizationNotifications';
import { useVikingAdaptorApi } from '@/modules/api/hooks/useVikingAdaptorApi';
import { currentTimeSecState } from '@/modules/visualization/store/currentTimeSecState';
import { useSetRecoilState } from 'recoil';

export const useVikingAdaptor = () => {
  const vikingApi = useVikingAdaptorApi();
  const [vikingConnected, setVikingConnected] = useState<boolean>(false);
  const setCurrentTimeSec = useSetRecoilState(currentTimeSecState);
  const { showVikingOpenFileSuccess, showVikingOpenFileNotSupported, showVikingOpenFileFailed } =
    useVisualizationNotification();

  const openFile = useCallback(
    (simulationId: string, sessionKey: string) => {
      vikingApi
        .post('/openFile', {
          fileName: `${simulationId}/output/care-recording.vve`,
          SessionKey: sessionKey,
        })
        .then((res) => {
          showVikingOpenFileSuccess();
          setVikingConnected(true);
          setCurrentTimeSec(0);
        })
        .catch((err) => {
          if (err.response.status == 415) {
            showVikingOpenFileNotSupported(simulationId);
          } else {
            showVikingOpenFileFailed(simulationId);
          }
          setVikingConnected(false);
        });
    },
    [
      setCurrentTimeSec,
      showVikingOpenFileFailed,
      showVikingOpenFileNotSupported,
      showVikingOpenFileSuccess,
      vikingApi,
    ],
  );

  return { openFile, vikingConnected };
};
