import { useMemo } from 'react';
import { Arc, Group, Image, Line } from 'react-konva';
import useImage from 'use-image';

import { theme } from '@/modules/common/components/theme';
import {
  AUTOSCALE_DEFAULT,
  AUTOSCALE_MAX,
  AUTOSCALE_MIN,
  AUTOSCALE_NAME,
} from '@/modules/workspace/helpers/konva';
import { useAutoScale } from '@/modules/workspace/hooks/useAutoScale';
import { KonvaEventObject } from 'konva/lib/Node';

const customAttr = {
  [AUTOSCALE_MAX]: 8,
  [AUTOSCALE_MIN]: 0.5,
  [AUTOSCALE_DEFAULT]: 1,
};

const RADIUS = 10;

export default function HalfCircleButton({ direction, x, y, onClick, checked = false, listening }) {
  const [chevronImage] = useImage('/arrow_left_alt.svg');
  useAutoScale();

  const handleClick = (e: KonvaEventObject<MouseEvent>) => {
    if (e.evt.button != 0) return;
    onClick?.();
  };

  const rotation = useMemo(() => {
    switch (direction) {
      case 'up':
        return -90;
      case 'down':
      default:
        return 90;
    }
  }, [direction]);

  return (
    <Group name={AUTOSCALE_NAME} {...customAttr}>
      <Arc
        innerRadius={0}
        outerRadius={RADIUS}
        fill={checked ? theme.palette.primary.main : theme.palette.neutral.lighter}
        angle={180}
        x={x}
        y={y}
        offsetY={-1.0}
        rotation={rotation}
        onClick={handleClick}
        listening={!!onClick}
      />
      <Line
        points={[x, y, x + RADIUS, y]}
        rotation={rotation}
        strokeWidth={2}
        stroke={theme.palette.shades.light}
        lineCap='butt'
      />
      <Image
        image={chevronImage}
        x={x}
        y={y}
        rotation={rotation + 180}
        offsetX={5}
        offsetY={10.5}
        onClick={handleClick}
        listening={listening}
      />
    </Group>
  );
}
