import AngleIcon from '@/assets/icons/AngleIcon';
import { Accordion } from '@/modules/common/components/Accordion';
import { Grid, Typography } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '@common/components/Input';

import { useLayoutStore } from '../../store';

const DimensionsComponent = () => {
  const { t } = useTranslation('interface');
  const delta = useLayoutStore((state) => state.delta);
  const deltaAngle = useLayoutStore((state) => state.deltaAngle);
  const setXOffset = useLayoutStore((state) => state.setXOffset);
  const setYOffset = useLayoutStore((state) => state.setYOffset);

  return (
    <Accordion title={t('properties.dimensions.label')} defaultExpanded sx={{ px: 2 }}>
      <Grid
        container
        spacing={1}
        alignItems='center'
        columns={12}
        sx={{ '&:hover': { cursor: 'default' }, pb: 2 }}
      >
        <Grid item xs={1}>
          <Typography fontSize='10px'>{t('interface:properties.dimensions.x', 'X')}</Typography>
        </Grid>
        <Grid item xs={5} sx={{ paddingRight: 1 }}>
          <Input value={delta.x} onChange={setXOffset} unit='mm' />
        </Grid>
        <Grid item xs={1}>
          <Typography fontSize='10px'>{t('interface:properties.dimensions.y', 'Y')}</Typography>
        </Grid>
        <Grid item xs={5} sx={{ paddingRight: 1 }}>
          <Input value={delta.y} onChange={setYOffset} unit='mm' />
        </Grid>
        <Grid item xs={1} sx={{ pt: '12px' }}>
          <AngleIcon style={{ height: '16px', width: '16px' }} />
        </Grid>
        <Grid item xs={5} sx={{ paddingRight: 1 }}>
          <Input value={deltaAngle} onChange={setYOffset} unit='°' disabled />
        </Grid>
      </Grid>
    </Accordion>
  );
};

export const Dimensions = memo(DimensionsComponent);
