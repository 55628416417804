import { t } from 'i18next';

import { useObjectValidation } from '@/modules/common/hooks/useObjectValidation';
import { useSnackbarStore } from '@/modules/snackbar/store/useSnackbarStore';

const requiredKeys = [
  'id',
  'category',
  'physics',
  'motion',
  'steering',
  'loadHandler',
  'layoutConstraints',
  'cstKollmorgenTemplates',
];

export const useUvtObjectValidation = () => {
  const { showSnackbar } = useSnackbarStore();
  const { validateKeys } = useObjectValidation();

  const isValidUvtJson = (uvt: string): boolean => {
    if (!uvt) {
      return false;
    }

    try {
      const parsedJson = JSON.parse(uvt);
      if (!validateKeys(parsedJson.vehicleTypes[0], requiredKeys)) {
        throw Promise.reject();
      }

      return true;
    } catch (error) {
      console.error(error);
      showSnackbar(t('errors:invalid_data_format'));
      return false;
    }
  };

  return { isValidUvtJson };
};
