import { atom } from 'recoil';
import { DEFAULT_REFERENCE_IMAGE_SETTINGS } from '../constants';
import { ReferenceImageSettings } from '../types';

const dwgAtom = atom<ReferenceImageSettings>({
  key: 'dwgAtom',
  default: DEFAULT_REFERENCE_IMAGE_SETTINGS,
});

export default dwgAtom;
