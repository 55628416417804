import { selector } from 'recoil';
import { ShapeType } from '@modules/common/types/shapes';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import workspaceAtom, { WorkspaceAtom } from './atom';

const previousShapeType = selector<ShapeType>({
  key: 'previousDrawingType',
  get: ({ get }) => get<WorkspaceAtom>(workspaceAtom).previousShapeType,
  set: ({ get, set }, newValue) => {
    set(workspaceAtom, { ...get(workspaceAtom), previousShapeType: newValue });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default previousShapeType;
