import { selector } from 'recoil';
import { allGroupIdsState, shapeGroupState } from '../atom';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@/store/recoil/common';
import { ShapeGroup } from '@/modules/common/types/shapeGroup';
import { setAtomFamilyWithIdsAtom } from '@/store/recoil/common/helper';

export const allGroupsSelector = selector<ShapeGroup[]>({
  key: 'shapegroup/all',
  get: ({ get }) => {
    const out = get(allGroupIdsState).map((groupdId) => get(shapeGroupState(groupdId)));
    return out;
  },
  set: (getSetResetRecoilState, newGroups: ShapeGroup[]) => {
    setAtomFamilyWithIdsAtom(getSetResetRecoilState, shapeGroupState, allGroupIdsState, newGroups);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
