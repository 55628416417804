export const getTransparentBackgroundExtension = () =>
  class TransparentBackgroundExtension extends Autodesk.Viewing.Extension {
    constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: any) {
      super(viewer, options);
    }

    load(): boolean | Promise<boolean> {
      (this.viewer.impl as any).renderer().setClearAlpha(0);
      (this.viewer.impl as any).glrenderer().setClearColor(0xffffff, 0);
      (this.viewer.impl as any).invalidate(true);

      return true;
    }

    unload(): boolean {
      return true;
    }
  };
