import { Position } from '@helpers/types';
import { atom } from 'recoil';
import { CONTEXT, InputContext, KEYCODE, KeyCodeType } from './constants';

export type MouseProperties = {
  position: Position;
};

export type MouseIndex = number | null;

export type InputAtom = {
  mouse: MouseProperties;
  /* Mouse button index saved on mouse click event. */
  mouseKeysDown: MouseIndex;
  objectUnderMouseId: string | null;
  context: InputContext;
  keyboard: KeyCodeType;
  lastKeyboard: KeyCodeType;
  ignoreKeyboardKeys: KeyCodeType[];
};

export const inputAtomDefaults = {
  mouse: {
    position: {
      x: 0,
      y: 0,
    },
  },
  objectUnderMouseId: null,
  mouseKeysDown: -1,
  context: CONTEXT.NONE,
  keyboard: KEYCODE.NONE,
  lastKeyboard: KEYCODE.NONE,
  ignoreKeyboardKeys: [],
};

const inputAtom = atom<InputAtom>({
  key: 'inputAtom',
  default: inputAtomDefaults,
});

export default inputAtom;
