import { useCallback, useEffect, useRef } from 'react';
import { useRecoilCallback } from 'recoil';
import { isCancelError } from '@modules/api/helpers';

import { simulationSelector, simulationsSelector } from '../store/simulations';
import { useSimulationApi, useSimulationCallbacks } from '.';
import { randomizeTimeout } from '../helpers/simulation';
import { Simulation } from '../helpers/types';
import { IN_PROGRESS_STATUSES } from '../helpers/constants';

/**
 * Polls for simulation updates
 */
export const useSimulationsUpdater = () => {
  const { fetchGroup } = useSimulationApi();
  const { updateSimulation } = useSimulationCallbacks();
  const handle = useRef<NodeJS.Timer>();
  const abortController = useRef<AbortController>();

  useEffect(
    () => () => {
      abortController.current?.abort();
    },
    [],
  );

  const update = useRecoilCallback(
    ({ snapshot }) =>
      async (id: string) => {
        let simulation = await snapshot.getPromise(simulationSelector(id));

        // check if state still exists
        if (!simulation) {
          return;
        }
        abortController.current = new AbortController();
        try {
          const response = await fetchGroup(
            simulation.floorPlanId,
            simulation.id,
            abortController.current.signal,
          );

          // check if still needs updating
          simulation = await snapshot.getPromise(simulationSelector(simulation.id));
          if (simulation && needUpdating(simulation)) {
            await updateSimulation(simulation.id, (simulation) => {
              if (!simulation) return;
              response.details.floorPlanVersion = simulation.details.floorPlanVersion;
              return response;
            });
          }
        } catch (e) {
          if (!isCancelError(e)) {
            console.error(e);
          }
        }
      },
    [],
  );

  const updateSimulations = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const simulations = await snapshot.getPromise(simulationsSelector);
        const runningSimulations = simulations.filter(needUpdating);

        runningSimulations.forEach((simulation) =>
          setTimeout(() => update(simulation.id), randomizeTimeout()),
        );
      },
    [],
  );

  const start = useCallback(async () => {
    await updateSimulations();

    handle.current = setInterval(() => updateSimulations(), 45000);
  }, [updateSimulations]);

  const stop = useCallback(() => {
    clearInterval(handle.current);
  }, []);

  return {
    start,
    stop,
  };
};

/**
 * Checks whether the given simulation needs to checked for progress
 */
export const needUpdating = (simulation: Simulation) =>
  IN_PROGRESS_STATUSES.includes(simulation.status);
