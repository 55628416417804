import { StyledTooltip } from '@common/components/StyledTooltip';
import { Divider, Tabs as MuiTabs, Stack, Tab, SxProps } from '@mui/material';
import { navSelector } from '@recoil/nav';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

export type TabProps = {
  label: string;
  trans: string;
  disableTab: boolean;
  id: string;
};

type Props = {
  tabLabels: TabProps[];
  onChange: (id: string) => void;
  sx?: SxProps;
};

export const TabsComponent = ({ tabLabels, onChange, sx }: Props) => {
  const nav = useRecoilValue(navSelector);
  const { t } = useTranslation(['interface']);

  return (
    <>
      <Stack justifyContent='center' sx={sx}>
        <MuiTabs value={nav} onChange={(e, value: string) => onChange(value)} sx={{ pb: 1 }}>
          {tabLabels.length > 0 &&
            tabLabels.map((tab) => (
              <Tab
                value={tab.id}
                key={tab.label}
                disabled={tab.disableTab}
                label={
                  <StyledTooltip offset={15} title={t(`${tab.trans}.tooltip`)} placement='bottom'>
                    <span>
                      <Trans i18nKey={`${tab.trans}.label`} ns='interface'>
                        {tab.label}
                      </Trans>
                    </span>
                  </StyledTooltip>
                }
              />
            ))}
        </MuiTabs>
      </Stack>
      <Divider />
    </>
  );
};
