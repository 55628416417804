import { selector } from 'recoil';
import dwgAtom from './atom';

import { RECOIL_SELECTOR_CACHE_POLICY } from '@/store/recoil/common';

export const referenceName = selector({
  key: 'reference/name',
  get: ({ get }) => {
    const currentValue = get(dwgAtom);
    return currentValue.imageName;
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
