import { ArgbColor, LoadCarrierOption, VehicleOption } from './types';

export const AREA_ALPHA = 150;
export const MAX_REFERENCE_IMAGE_SIZE = 1300000;

export const SOURCE_NAME = 'DigitalTwin-Layout';
export const FLOOR_TAG = 'Floor';
export const REFERENCE_TAG = 'Reference';
export const RACKING_TAG = 'Racking';
export const RACKING_BEAM_COLOR: ArgbColor = {
  Alpha: 255,
  R: 255,
  G: 120,
  B: 0,
};
export const RACKING_POST_COLOR: ArgbColor = {
  Alpha: 255,
  R: 190,
  G: 190,
  B: 190,
};
export const HIGHWAY_COLOR: ArgbColor = { Alpha: 20, R: 0, G: 0, B: 0 };
export const VEHICLE_OPTIONS: VehicleOption[] = [
  {
    name: 'RAE',
    vehicleIds: ['RAE160', 'RAE250'],
    fleetTrackerId: 'rae',
  },
  {
    name: 'SAE',
    vehicleIds: ['SAE160_1200', 'SAE160_1600'],
    fleetTrackerId: 'sae',
  },
  {
    name: 'LAE',
    vehicleIds: ['LAE'],
    fleetTrackerId: 'lae',
  },
  {
    name: 'CDI',
    vehicleIds: ['CDI'],
    fleetTrackerId: 'cdi',
  },
  {
    name: 'Charm',
    vehicleIds: ['Charm'],
    fleetTrackerId: 'charm',
  },
  {
    name: 'CB18',
    vehicleIds: ['CB18'],
    fleetTrackerId: 'cb18',
  },
  {
    name: 'AGF',
    vehicleIds: ['AGF_8AFBR30'],
    fleetTrackerId: 'agf-8afbr30',
  },
  {
    name: 'CD-01',
    vehicleIds: ['CD-01'],
    fleetTrackerId: 'trailer-cd01',
  },
  {
    name: 'TE152',
    vehicleIds: ['TE152'],
    fleetTrackerId: 'truck-simai-te152',
  },
  {
    name: 'RAYMOND9700',
    vehicleIds: ['Raymond9700'],
    fleetTrackerId: 'raymond_9700',
  },
  {
    name: 'ML2',
    vehicleIds: ['ML2'],
    fleetTrackerId: 'ml2',
  },
  {
    name: 'M10',
    vehicleIds: ['M10'],
    fleetTrackerId: 'm10',
  },
  {
    name: '8HBC40A',
    vehicleIds: ['8HBC40A'],
    fleetTrackerId: '8hbc40a',
  },
  {
    name: '8TB50A',
    vehicleIds: ['8TB50A'],
    fleetTrackerId: '8tb50a',
  },
  {
    name: 'OAE120CB_1000',
    vehicleIds: ['OAE120CB_1000'],
    fleetTrackerId: 'oae120cb_1000',
  },
  {
    name: 'OAE120CB_1150',
    vehicleIds: ['OAE120CB_1150'],
    fleetTrackerId: 'oae120cb_1150',
  },
  {
    name: 'OAE120CB_1200',
    vehicleIds: ['OAE120CB_1200'],
    fleetTrackerId: 'oae120cb_1200',
  },
  {
    name: '3TE25',
    vehicleIds: ['3TE25'],
    fleetTrackerId: '3te25',
  },
  {
    name: '3TE25_AV',
    vehicleIds: ['3TE25_AV'],
    fleetTrackerId: '3te25_av',
  },
];
export const LOAD_OPTIONS: LoadCarrierOption[] = [
  {
    name: 'Empty Pallet',
    fleetTrackerId: 'pallet-euro',
  },
  {
    name: 'Empty Pallet 90 degrees',
    fleetTrackerId: 'pallet-euro_90',
  },
  {
    name: 'Pallet with Load',
    fleetTrackerId: 'load-pallet-test',
  },
  {
    name: 'Pallet with Load 90 degrees',
    fleetTrackerId: 'load-pallet-test_90',
  },
  {
    name: 'Load Test',
    fleetTrackerId: 'load-test',
  },
  {
    name: 'Load Test 90 degrees',
    fleetTrackerId: 'load-test_90',
  },
  {
    name: 'Pallet Charm',
    fleetTrackerId: 'pallet-charm',
  },
];
