import { PropertiesLabel } from '@/modules/common/components/PropertiesLabel';
import { LayerNames } from '@/modules/common/types/layers';
import { ShapeType } from '@/modules/common/types/shapes';
import { layersShowSelector } from '@/modules/layers';
import { UserPreferenceName } from '@/modules/userPreferences';
import { useUserPreference, useUpdateUserPreferences } from '@/modules/userPreferences/hooks';
import { SelectInput } from '@common/components/inputs';
import { Button, LinearProgress, Stack, Typography } from '@mui/material';
import { allShapesSelector } from '@recoil/shapes';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { coverAreas, propagations } from '../constants';
import { useWifiSimulationApi } from '../hooks/useWifiSimulationApi';
import { wifiPanelAtom } from '../store/wifiPanelAtom';
import { wifiResultAtom } from '../store/wifiResultAtom';
import {
  CoverArea,
  Propagation,
  WifiSimulationInput,
  WifiSimulationResult,
  getCoverArea,
  getPropagation,
} from '../types';
import { useSnackbarStore } from '@/modules/snackbar/store/useSnackbarStore';

export const WifiProperties = () => {
  const propagation: string = useUserPreference(UserPreferenceName.WIFI_PROPAGATION);
  const coverArea: string = useUserPreference(UserPreferenceName.WIFI_COVER_AREA);
  const { updateUserPreference } = useUpdateUserPreferences();
  const { simulate } = useWifiSimulationApi();
  const [waitingResult, setWaitingResult] = useState<Boolean>(false);
  const { t } = useTranslation('interface');
  const { showSnackbar } = useSnackbarStore();

  const onPropagationChange = useCallback(
    (value: Propagation) => {
      updateUserPreference(UserPreferenceName.WIFI_PROPAGATION, value.name);
    },
    [updateUserPreference],
  );

  const onCoverAreaChange = useCallback(
    (value: CoverArea) => {
      updateUserPreference(UserPreferenceName.WIFI_COVER_AREA, value.name);
    },
    [updateUserPreference],
  );

  const handleRunWifiSim = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        let shapes = await snapshot.getPromise(allShapesSelector);
        shapes = shapes.filter((s) => s.type === ShapeType.OBSTACLE || s.type === ShapeType.WALL);
        const input: WifiSimulationInput = {
          propagation: getPropagation(propagation),
          cover_area: getCoverArea(coverArea),
          shapes,
        };
        setWaitingResult(true);
        try {
          const res = await simulate(input);
          const result = res.data as WifiSimulationResult;
          set(wifiResultAtom, result);
          set(layersShowSelector(LayerNames.WIFI), true);
          showSnackbar(t('wifi_simulation.result.success'));
        } catch (e) {
          showSnackbar(t('wifi_simulation.result.error'));
          set(wifiResultAtom, null);
        }
        set(wifiPanelAtom, false);
      },
    [simulate, waitingResult, t],
  );

  return (
    <Stack direction='column' spacing={2}>
      <Typography variant='body2'>
        This is an experimental feature for analyzing the best placements of wi-fi access points in
        your floorplan. Why do we need that? Because a good placement of access points will provide
        good connections to the AGV. This simulation assumes placement of wi-fi access points on
        some obstacles in the floorplan.
      </Typography>
      <Typography variant='h3'>How to use?</Typography>
      <Typography variant='body2'>
        1. Draw a floorplan that is surrounded by the wall.
        <br />
        2. Create obstacles (Type = Box). Set the correct heights.
        <br />
        3. Open Wi-Fi Simulation menu. Select propagation model and cover area.
        <br />
        4. Click &quot;Run the simulation&quot;
        <br />
        5. It will take up to 20 seconds for simulation to complete and the result of access points{' '}
        <br />
        placement will be visible on the floorplan
        <br />
        6. Analysis result will not be saved but you can run as many time as you want.
      </Typography>

      <Stack direction='column' spacing={2}>
        {!waitingResult && (
          <>
            <PropertiesLabel i18nkey='interface:wifi_simulation.propagation.title' labelRatio={20}>
              <SelectInput
                options={propagations.map((p) => ({ label: p.name, value: p }))}
                onChange={(e) => onPropagationChange(e)}
                value={getPropagation(propagation)}
              />
            </PropertiesLabel>
            <PropertiesLabel i18nkey='interface:wifi_simulation.cover_area.title' labelRatio={20}>
              <SelectInput
                options={coverAreas.map((c) => ({ label: c.name, value: c }))}
                onChange={(e) => onCoverAreaChange(e)}
                value={getCoverArea(coverArea)}
              />
            </PropertiesLabel>
            <Button onClick={handleRunWifiSim}>Run Simulation</Button>
          </>
        )}
        {waitingResult && (
          <>
            <LinearProgress />
            <Typography variant='body2'>
              Simulation is running. It will take about 20 seconds.
            </Typography>
          </>
        )}
      </Stack>
    </Stack>
  );
};
