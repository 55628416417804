import { useZoom } from '@/modules/workspace/hooks/useZoom';
import { unitSelector } from '@/store/recoil/workspace';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Group, Rect, Text } from 'react-konva';
import { useRecoilValue } from 'recoil';
import { measureText } from '../../../../helpers/string';
import { Position } from '../../../../helpers/types';
import { Size } from '../../../common/types/general';
import { SCALE_LABEL_HEIGHT, SCALE_LABEL_X_OFFSET, SCALE_LABEL_Y_OFFSET } from '../../constants';
import { ScaleLabelProps } from '../../types';

const ScaleLabel: React.FC<ScaleLabelProps> = ({ pointA, pointB, value }) => {
  const { zoomifyValue } = useZoom();
  const unit = useRecoilValue(unitSelector);
  const [text, setText] = useState<string>('');
  const [width, setWidth] = useState<number>(0);
  const { t } = useTranslation();

  useEffect(() => {
    const text = `${value} ${t(`interface:settings.units.${unit}`, unit)}`;
    setWidth(measureText(text, zoomifyValue(20)) + 20);
    setText(text);
    return () => {
      setText('');
      setWidth(0);
    };
  }, [t, unit, value, setWidth, setText, zoomifyValue]);

  const boxSize: Size = useMemo(
    () => ({ width, height: zoomifyValue(SCALE_LABEL_HEIGHT) }),
    [width, zoomifyValue],
  );

  const boxPosition: Position = useMemo(() => {
    const horizontal = Math.abs(pointA.x - pointB.x) > Math.abs(pointA.y - pointB.y);
    let x = (pointB.x - pointA.x) / 2 + pointA.x;
    let y = (pointB.y - pointA.y) / 2 + pointA.y - boxSize.height / 2;

    if (horizontal) {
      x -= boxSize.width / 2;
      y += zoomifyValue(SCALE_LABEL_Y_OFFSET);
    } else {
      x += zoomifyValue(SCALE_LABEL_X_OFFSET);
    }

    return { x, y };
  }, [boxSize.height, boxSize.width, pointA.x, pointA.y, pointB.x, pointB.y, zoomifyValue]);

  return (
    <Group>
      <Rect
        x={boxPosition.x}
        y={boxPosition.y}
        width={boxSize.width}
        height={boxSize.height}
        cornerRadius={zoomifyValue(16)}
        fill='#F8E569'
      />
      <Text
        x={boxPosition.x}
        y={boxPosition.y}
        width={boxSize.width}
        height={boxSize.height}
        text={text}
        fontSize={zoomifyValue(16)}
        wrap='none'
        align='center'
        verticalAlign='middle'
      />
    </Group>
  );
};

export default ScaleLabel;
