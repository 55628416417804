import { selectorFamily } from 'recoil';

import { RECOIL_SELECTOR_CACHE_POLICY } from '@/store/recoil/common';
import { vehicleDetailsState } from './vehicleDetailsState';

export const vehicleWidthSelector = selectorFamily<number, string>({
  key: 'vehicle/byId/width',
  get:
    (id: string) =>
    ({ get }) => {
      const details = get(vehicleDetailsState(id));
      if (!details) return null;

      return details.width / 10;
    },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
