import { v4 as uuid } from 'uuid';
import { highwayLaneDirectionToTwinConfig } from './utils';

export const addCheckPointGenerationSettings = (
  vehicleSpec,
  id,
  checkPointIdGenerator,
  gap,
  margin,
  routingPointGroupMinGap,
  routingPointMarginToCrossing,
  laneDirection,
  width,
  height,
  angle,
  cpCutOutReferences = [],
) => {
  vehicleSpec.checkPointGenerationSettings.push({
    name: uuid(),
    checkPointIdGenerator,
    commsIdGeneratorBk: 'PointIdGen',
    idShortenerBk: 'IdShort',
    gapBetweenAdjacentRps: gap,
    marginOuterRp: margin,
    applyHeadRoomConstraints: false,
    margin: routingPointMarginToCrossing,
    minGap: routingPointGroupMinGap, // min distance between routing points in the same lane
    skipFirst: true,
    skipLast: true,
    twinConfig: highwayLaneDirectionToTwinConfig(laneDirection, width, height, angle),
    areaReference: {
      name: id,
    },
    cpCutOutReferences,
  });
};
