import { MutableRefObject, useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { OrderProfileProperties } from '@/modules/orderProfile/components/OrderProfileProperties';

import { PropertiesPopup } from '@/modules/common/components/PropertiesPopup';
import { PropertiesTitle } from '@/modules/common/components/PropertiesTitle';
import { t } from 'i18next';
import { openOrderProfilePanelAtom } from '../store/openOrderProfilePanelAtom';

type Props = {
  anchor: MutableRefObject<HTMLElement>;
};

export function OrderProfilePanel({ anchor }: Props) {
  const [panelOpen, setPanelOpen] = useRecoilState(openOrderProfilePanelAtom);

  const onClose = useCallback(() => {
    setPanelOpen(false);
  }, [setPanelOpen]);

  return (
    <PropertiesPopup
      anchor={anchor}
      open={panelOpen}
      onClose={onClose}
      header={
        <PropertiesTitle
          fontSize={14}
          fontWeight={400}
          value={t('interface:order_profile.add_title')}
        />
      }
      sx={{
        width: '391px',
        height: 'auto',
      }}
    >
      <OrderProfileProperties />
    </PropertiesPopup>
  );
}
