import { Color, Element } from '@thive/canvas';
import { v4 as uuid } from 'uuid';

import { SevenSenseLayout } from '../helpers/types';
import { ImageElement } from '@thive/canvas/lib/common/types';
import { LOCALIZATION_ELEMENT_ID } from '../store/useSevenSenseStore';

export const map = (layout: SevenSenseLayout): Element[] => {
  const elements: Element[] = [];

  layout.walls.forEach((rect, index) => {
    const color = Color.fromHex(rect.color + (255 * rect.opacity).toString(16));

    elements.push({
      type: 'Rect',
      name: `Rect ${index}`,
      id: uuid(),
      size: rect.size.clone(),
      position: rect.position.clone().add(rect.size.clone().divideScalar(2)).setZ(-1),
      fill: color,
      interactivity: {
        selectable: false,
      },
    });
  });

  layout.lines.forEach((rect, index) => {
    elements.push({
      type: 'Line',
      name: `Rect ${index}`,
      id: uuid(),
      pointA: rect.p1.setZ(-1),
      pointB: rect.p2.setZ(-1),
      stroke: Color.BLACK,
      thickness: 10,
      interactivity: {
        selectable: false,
      },
    });
  });

  return elements;
};

export const mapReferenceImage = (
  image: HTMLImageElement,
  width?: number,
  height?: number,
): ImageElement => ({
  type: 'Image',
  id: LOCALIZATION_ELEMENT_ID,
  name: LOCALIZATION_ELEMENT_ID,
  image,
  width,
  height,
  pixelToMmRatio: 1,
  interactivity: {
    rotatable: false,
    selectable: true,
    draggable: true,
  },
});
