import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { Module } from '@modules/common/types/navigation';
import { DEVTOOLS_OPTIONS } from '@/modules/debug/constants/zustand';
import { useFloorPlanStore, useSevenSenseStore } from './index';

type ModuleState = {
  module: Module;
};

type ModuleActions = {
  setModule(module: Module): void;
  reset(): void;
};

const INITIAL_STATE: ModuleState = {
  module: Module.COMMISSIONING_SETUP,
};

export const useModuleStore = create<ModuleState & ModuleActions>()(
  devtools(
    (set, get) => ({
      ...INITIAL_STATE,

      setModule: (module: Module) => {
        const current = get().module;
        useSevenSenseStore.getState().changeModule(module);
        if (current !== module) {
          if (module === Module.COMMISSIONING_GATE) {
            useFloorPlanStore.getState().ungroup();
          } else {
            useFloorPlanStore.getState().group();
          }
        }

        set(
          {
            module,
          },
          undefined,
          {
            type: 'setModule',
          },
        );
      },

      reset: () => {
        set(INITIAL_STATE, undefined, {
          type: 'reset',
        });
      },
    }),
    { store: 'commissioning/moduleStore', ...DEVTOOLS_OPTIONS },
  ),
);
