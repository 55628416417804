import { AreaError } from '@/modules/common/types/shapes';

export const AREA_RENDER_ERROR = {
  [AreaError.AbsentLoadCarrier]: {
    code: AreaError.AbsentLoadCarrier,
    i18nMessageKey: 'errors:area.absent_load_carrier',
  },
  [AreaError.AreaToSmall]: {
    code: AreaError.AreaToSmall,
    i18nMessageKey: 'errors:area.too_small',
  },
  [AreaError.AbsentVehicle]: {
    code: AreaError.AbsentVehicle,
    i18nMessageKey: 'errors:area.absent_vehicle',
  },
  [AreaError.PartialGenerationFailed]: {
    code: AreaError.PartialGenerationFailed,
    i18nMessageKey: 'errors:area.partial_generation_failed',
  },
} as const;
