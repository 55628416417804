import { KEYCODE } from '@/store/recoil/input';

export const ARROW_OFFSETS = {
  [KEYCODE.ARROW_LEFT]: { x: 1, y: 0 },
  [KEYCODE.ARROW_UP]: { x: 0, y: 1 },
  [KEYCODE.ARROW_RIGHT]: { x: -1, y: 0 },
  [KEYCODE.ARROW_DOWN]: { x: 0, y: -1 },
};

export const STEP = 10;

export const NUDGE_MIN = 10;

export const NUDGE_MAX = 10000;

export const DIMENTIONS_BOX_HEIGHT_DEFAULT = 24;
export const NAME_BADGE_HEIGHT_DEFAULT = 24;
export const NAME_BADGE_Y_OFFSET = 8;
