export const requestWithTimeout = <TResult>(
  request: () => Promise<TResult>,
  shouldRetryTest: (a: any) => boolean,
  delay = 1000,
  retryCount = 10,
): Promise<TResult> =>
  new Promise(async (resolve, reject) => {
    let attemptsCounter = 0;

    try {
      const result = await request();
      if (!shouldRetryTest(result)) {
        resolve(result);
        return;
      }
    } catch (e) {
      reject(e);
      return;
    }

    let timeoutId: NodeJS.Timeout;

    const tryAgain = () => {
      attemptsCounter++;

      return setTimeout(async () => {
        try {
          const result = await request();

          if (!shouldRetryTest(result)) {
            clearTimeout(timeoutId);
            return resolve(result);
          }

          if (attemptsCounter >= retryCount) reject();
          timeoutId = tryAgain();
        } catch (e) {
          clearTimeout(timeoutId);
          reject(e);
        }
      }, delay);
    };

    timeoutId = tryAgain();
  });
