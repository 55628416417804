import { SvgIcon } from '@mui/material';

export default function AreaAlignTopIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M22 3H2M19 6V14C19 14.5523 18.5523 15 18 15H15C14.4477 15 14 14.5523 14 14V6C14 5.44772 14.4477 5 15 5H18C18.5523 5 19 5.44772 19 6ZM11 6V20C11 20.5523 10.5523 21 10 21H7C6.44772 21 6 20.5523 6 20V6C6 5.44772 6.44772 5 7 5H10C10.5523 5 11 5.44772 11 6Z" 
        fill='transparent' 
        stroke='currentColor'
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
