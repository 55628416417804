import { Paper } from '@mui/material';
import { Stack, ThemeProvider } from '@mui/system';
import { useCallback, useEffect, useMemo } from 'react';
import { Trans } from 'react-i18next';

import { Toolset as CommissioningToolset } from '@/modules/commissioning';
import { toolbarTheme } from '@/modules/common/components/theme';
import { is3dWorkspace, isCommissioningSubModule } from '@/modules/common/helpers/navigation';
import { usesControlPoints } from '@/modules/common/helpers/shapes';
import { Module } from '@/modules/common/types/navigation';
import { ShapeType } from '@/modules/common/types/shapes';
import {
  VisualizationToolsetHorizontal,
  VisualizationToolsetVertical,
} from '@/modules/visualization';
import { useWorkspaceStore } from '@/modules/workspace/store';
import { navSelector } from '@/store/recoil/nav';
import { toolButtonState } from '@/store/recoil/tool';
import { toolState } from '@/store/recoil/workspace';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { ToolState } from '@modules/common/types/tools';
import { CONTEXT, KEYCODE, contextState, keyboardState } from '@recoil/input';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { WorkspaceOverlayPosition } from '../WorkspaceUi';
import { ToolButton } from './ToolButton';
import { ToolsetMain } from './ToolsetMain';
import { ToolsetViewport } from './ToolsetViewport';
import { UndoRedo } from './UndoRedo';

export const Toolbar3 = () => {
  const nav = useRecoilValue(navSelector);
  const mode = useRecoilValue(modeSelector);
  const setDrawingToolState = useSetRecoilState(toolState);
  const keyboard = useRecoilValue(keyboardState);
  const [tool, setTool] = useRecoilState(toolButtonState);
  const context = useRecoilValue(contextState);
  const activePointsDrawing = useWorkspaceStore((state) => state.activePointsDrawingState);
  const handleCursorToolClick = useCallback(() => {
    setDrawingToolState(ToolState.SELECT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePanToolClick = useCallback(() => {
    setDrawingToolState(ToolState.SELECT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO move to useKeyboardEvents
  useEffect(() => {
    if (context !== CONTEXT.WORKSPACE) {
      return;
    }

    if (mode === WorkspaceMode.READONLY || mode === WorkspaceMode.READONLY_SELECTION) {
      switch (keyboard) {
        case KEYCODE.ESCAPE:
          setTool(null);
          break;
        case KEYCODE.V:
          if (tool !== null) {
            setTool(null);
          }
          break;
        case KEYCODE.H:
          if (tool !== ToolState.PAN) {
            setTool(ToolState.PAN);
          }
          break;
        default:
          break;
      }
      return;
    }

    if (mode === WorkspaceMode.EDITABLE) {
      switch (keyboard) {
        case KEYCODE.ESCAPE:
          if (
            (usesControlPoints(tool) && !activePointsDrawing.id) ||
            (!usesControlPoints(tool) && tool !== null)
          ) {
            setTool(null);
          }
          break;
        case KEYCODE.ENTER:
          if (usesControlPoints(tool)) {
            setTool(null);
          }
          break;
        case KEYCODE.V:
          if (tool !== null) {
            setTool(null);
          }
          break;
        case KEYCODE.C:
          if (nav === Module.LAYOUT && tool !== ShapeType.CHARGING) {
            setTool(ShapeType.CHARGING);
          }
          break;
        case KEYCODE.D:
          if (nav === Module.LAYOUT && tool !== ShapeType.DELIVERY) {
            setTool(ShapeType.DELIVERY);
          }
          break;
        case KEYCODE.P:
          if (nav === Module.LAYOUT && tool !== ShapeType.PARKING) {
            setTool(ShapeType.PARKING);
          }
          break;
        case KEYCODE.I:
          if (nav === Module.LAYOUT && tool !== ShapeType.INTAKE) {
            setTool(ShapeType.INTAKE);
          }
          break;
        case KEYCODE.W:
          if (nav === Module.LAYOUT && tool !== ShapeType.WALL) {
            setTool(ShapeType.WALL);
          }
          break;
        case KEYCODE.O:
          if (nav === Module.LAYOUT && tool !== ShapeType.OBSTACLE) {
            setTool(ShapeType.OBSTACLE);
          }
          break;
        case KEYCODE.S:
          if (nav === Module.LAYOUT && tool !== ShapeType.STORAGE) {
            setTool(ShapeType.STORAGE);
          }
          break;
        case KEYCODE.SHIFT_H:
          if (nav === Module.LAYOUT && tool !== ShapeType.HIGHWAY) {
            setTool(ShapeType.HIGHWAY);
          }
          break;
        case KEYCODE.U:
          if (nav === Module.LAYOUT && tool !== ShapeType.HIGHWAY_ANGLED) {
            setTool(ShapeType.HIGHWAY_ANGLED);
          }
          break;
        case KEYCODE.H:
          if (tool !== ToolState.PAN) {
            setTool(ToolState.PAN);
          }
          break;
        case KEYCODE.F:
          if (tool !== ToolState.FLOW) {
            setTool(ToolState.FLOW);
          }
          break;
        case KEYCODE.SHIFT_C:
          if (tool !== ToolState.DISTCON) {
            setTool(ToolState.DISTCON);
          }
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyboard]);

  const is3DWorkspace = useMemo(() => is3dWorkspace(nav), [nav]);
  const isCommissioning = useMemo(() => isCommissioningSubModule(nav), [nav]);

  return (
    <ThemeProvider theme={toolbarTheme}>
      <WorkspaceOverlayPosition
        alignVertical='center'
        alignHorizontal='left'
        additionalStyles={{
          display: 'flex',
          gap: '16px',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        {!is3DWorkspace && !isCommissioning && (
          <>
            <Paper
              elevation={1}
              sx={{
                borderRadius: '4px',
              }}
            >
              <Stack>
                <ToolButton
                  type='cursor'
                  disabled={
                    mode !== WorkspaceMode.EDITABLE && mode !== WorkspaceMode.READONLY_SELECTION
                  }
                  onSelect={handleCursorToolClick}
                  tooltipLabel={
                    <Trans
                      i18nKey='toolbar.select_button.tooltip'
                      ns='interface'
                      components={{
                        s: <span />,
                      }}
                    >
                      Select <span>V</span>
                    </Trans>
                  }
                  icon='cursor'
                />

                <ToolButton
                  type={ToolState.PAN}
                  onSelect={handlePanToolClick}
                  tooltipLabel={
                    <Trans
                      i18nKey='toolbar.pan_button.tooltip'
                      ns='interface'
                      components={{
                        s: <span />,
                      }}
                    >
                      Pan <span>Space</span>
                    </Trans>
                  }
                  icon={ToolState.PAN}
                />
              </Stack>
              <ToolsetMain nav={nav} mode={mode} />
            </Paper>
            <UndoRedo disabled={mode !== WorkspaceMode.EDITABLE} />
          </>
        )}
        {is3DWorkspace && <VisualizationToolsetVertical />}
        {isCommissioning && <CommissioningToolset />}
      </WorkspaceOverlayPosition>
      <WorkspaceOverlayPosition
        alignVertical='bottom'
        alignHorizontal='right'
        additionalStyles={{
          display: 'flex',
          gap: '16px',
        }}
      >
        {is3DWorkspace ? (
          nav === Module.VIEWER ? (
            <VisualizationToolsetHorizontal />
          ) : null
        ) : (
          <ToolsetViewport />
        )}
      </WorkspaceOverlayPosition>
    </ThemeProvider>
  );
};
