import { useCallback } from 'react';
import { EventNames, useEventBus } from '../../../hooks';

export const useZoom = () => {
  const { emit } = useEventBus();

  const zoomFit = useCallback(
    () =>
      emit(EventNames.VisualizationWebGLSend, {
        target: 'MainCamera',
        action: 'ZoomFit',
      }),
    [emit],
  );

  const zoomIn = useCallback(
    () =>
      emit(EventNames.VisualizationWebGLSend, {
        target: 'MainCamera',
        action: 'ZoomIn',
      }),
    [emit],
  );

  const zoomOut = useCallback(
    () =>
      emit(EventNames.VisualizationWebGLSend, {
        target: 'MainCamera',
        action: 'ZoomOut',
      }),
    [emit],
  );

  return {
    zoomFit,
    zoomIn,
    zoomOut,
  };
};
