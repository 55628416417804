import { useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import objectUnderMouseIdSelector from '../../../store/recoil/input/objectUnderMouseIdSelector';

export function useShapeHover(id: string, attachHoverState = false) {
  const setObjectUnderMouseId = useSetRecoilState(objectUnderMouseIdSelector);
  const [isHovered, setIsHovered] = useState(false);

  const onMouseEnter = useCallback(() => {
    if (attachHoverState) setIsHovered(true);
    setObjectUnderMouseId(id);
  }, [attachHoverState, id, setObjectUnderMouseId]);

  const onMouseLeave = useCallback(() => {
    if (attachHoverState) setIsHovered(false);
    setObjectUnderMouseId(null);
  }, [attachHoverState, setObjectUnderMouseId]);

  if (attachHoverState)
    return {
      isHovered,
      onMouseEnter,
      onMouseLeave,
    };

  return {
    onMouseEnter,
    onMouseLeave,
  };
}
