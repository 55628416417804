import { selector } from 'recoil';
import { Connection } from '@modules/common/types/connections';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { unique } from '@modules/common/helpers/array';

import { sortConnections } from '../../common/helpers';
import { allConnectionIds, connectionState } from './atom';

export const allConnectionsSelector = selector({
  key: 'connections/allConnections',
  get: ({ get }) => get(allConnectionIds).map((pair) => get(connectionState(pair))),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const removeConnectionsSelector = selector<string[]>({
  key: 'connections/connections/remove',
  get: () => null,
  set: ({ set, reset }, ids: string[]) => {
    ids.forEach((item) => reset(connectionState(item)));
    set(allConnectionIds, (state) => state.filter((item) => !ids.includes(item)));
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

type UpdateConnectionsSelectorValue = {
  connections: Connection[];
  connectionIdsToRemove?: Set<string>;
};

export const updateConnectionsSelector = selector<UpdateConnectionsSelectorValue>({
  key: 'connections/connections/update',
  get: () => null,
  set: ({ set, reset }, value: UpdateConnectionsSelectorValue) => {
    value.connections.forEach((connection) => set(connectionState(connection.id), connection));

    if (value.connectionIdsToRemove) {
      value.connectionIdsToRemove.forEach((id) => reset(connectionState(id)));
    }

    set(allConnectionIds, (state) => {
      const newState = unique([...state, ...value.connections.map((item) => item.id)]);
      return value.connectionIdsToRemove
        ? newState.filter((item) => !value.connectionIdsToRemove.has(item))
        : newState;
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const saveLoadConnectionSelector = selector<Connection[]>({
  key: 'saveLoadConnection',
  get: ({ get }) =>
    sortConnections(get(allConnectionIds).map((item) => get(connectionState(item)))),
  set: ({ set }, conn: Connection[]) => {
    const ids = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const con of conn) {
      ids.push(con.id);
      set(connectionState(con.id), con);
    }
    set(allConnectionIds, ids);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
