import { selector } from 'recoil';
import { ShapeType } from '@modules/common/types/shapes';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import shapeAtom from '../shape/atom';
import allShapesSelector from './allShapesSelector';

// a setter selector function that enables the provided shapeTypes and disables the non-provided ones
export const enableShapesOfTypesExclusivelySelector = selector({
  key: 'enableShapesOfTypesExclusivelySelector',
  get: () => null,
  set: ({ get, set }, typesToEnableExclusively: ShapeType[]) => {
    const allShapes = get(allShapesSelector);

    allShapes.forEach((shape) => {
      const shouldBeDisabled = !typesToEnableExclusively.includes(shape.type);
      if (shape.disabled !== shouldBeDisabled) {
        set(shapeAtom(shape.id), (state) => ({
          ...state,
          disabled: shouldBeDisabled,
        }));
      }
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

// a setter selector function that disables the provided shapeTypes and enables the non-provided ones
export const disableShapesOfTypesExclusivelySelector = selector({
  key: 'disableShapesOfTypesExclusivelySelector',
  get: () => null,
  set: ({ get, set }, typesToDisableExclusively: ShapeType[]) => {
    const allShapes = get(allShapesSelector);

    allShapes.forEach((shape) => {
      const shouldBeDisabled = typesToDisableExclusively.includes(shape.type);
      if (shape.disabled !== shouldBeDisabled) {
        set(shapeAtom(shape.id), (state) => ({
          ...state,
          disabled: shouldBeDisabled,
        }));
      }
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
