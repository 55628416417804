import { Accordion } from '@common/components/Accordion';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LayoutPointUpload } from './LayoutPointUpload';
import { LoadDemoLayoutButton } from './LoadDemoLayoutButton';
import { UnitDataUpload } from './UnitDataUpload';

export default function Upload() {
  const { t } = useTranslation(['interface']);

  return (
    <Accordion
      title={t('interface:3te25.upload.title')}
      titleSx={{ textTransform: 'capitalize' }}
      defaultExpanded
    >
      <Stack direction='row' alignItems='center'>
        <Stack direction='column' spacing={1}>
          <LayoutPointUpload />
          <UnitDataUpload />
        </Stack>
        <Typography mr={2}>OR</Typography>
        <LoadDemoLayoutButton />
      </Stack>
    </Accordion>
  );
}
