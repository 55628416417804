import { useEffect, useState } from 'react';
import { EventNames, useEventBus } from '../../../hooks';

export const useTool = () => {
  const [tool, setTool] = useState('select');
  const { emit } = useEventBus();

  useEffect(() => {
    switch (tool) {
      case 'select': {
        emit(EventNames.VisualizationWebGLSend, {
          target: 'Tracker',
          action: 'LeftMouseSelect',
        });
        break;
      }
      case 'orbit': {
        emit(EventNames.VisualizationWebGLSend, {
          target: 'Tracker',
          action: 'LeftMouseOrbit',
        });
        break;
      }
      case 'pan': {
        emit(EventNames.VisualizationWebGLSend, {
          target: 'Tracker',
          action: 'LeftMousePan',
        });
        break;
      }
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tool]);

  return { tool, setTool };
};
