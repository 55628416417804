import { SVGProps, memo } from 'react';

function SvgComponent(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns='http://www.w3.org/2000/svg' {...props}>
 <mask id="mask0_521_6023" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
<rect width="24" height="24" fill="currentColor"/>
</mask>
<g mask="url(#mask0_521_6023)">
<path d="M7 20V4H13C14.3667 4 15.5417 4.49167 16.525 5.475C17.5083 6.45833 18 7.63333 18 9C18 10.3667 17.5083 11.5417 16.525 12.525C15.5417 13.5083 14.3667 14 13 14H9V20H7ZM9 12H13.05C13.8667 12 14.571 11.7043 15.163 11.113C15.7543 10.521 16.05 9.81667 16.05 9C16.05 8.18333 15.7543 7.479 15.163 6.887C14.571 6.29567 13.8667 6 13.05 6H9V12Z" fill="currentColor"/>
</g>
    </svg>
  );
}

const Memo = memo(SvgComponent);
export default Memo;


