import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../../store/recoil/common';
import dwgAtom from './atom';

const referenceFile = selector({
  key: 'reference/file',
  get: ({ get }) => get(dwgAtom).file,
  set: ({ get, set }, newValue) => {
    set(dwgAtom, { ...get(dwgAtom), file: newValue });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default referenceFile;
