/**
 * Creates a duration object from the given seconds
 */
export const durationFromSeconds = (value: number) => {
  const seconds = value % 60;
  const minutes = Math.floor(value / 60) % 60;
  const hours = Math.floor((value % 86400) / 3600);
  const days = Math.floor(value / 86400);

  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

/**
 * Creates a duration object from the given hours
 */
export const durationFromHours = (value: number) => durationFromSeconds(value * 3600);

/**
 * Converts the given duration to seconds
 */
export const durationToSeconds = (duration: Duration): number =>
  duration.days * 86400 + duration.hours * 3600 + duration.minutes * 60 + duration.seconds;

/**
 * Converts the given duration to seconds
 */
export const durationToHours = (duration: Duration): number => duration.days * 24 + duration.hours;
export const zeroDuration = (): Duration => ({
  minutes: 0,
  hours: 0,
  days: 0,
  seconds: 0,
});

/**
 * Formats the give duration in a xx:xx:xx format
 * @param duration
 */
export const formatDuration = (duration: Duration) =>
  `${pad(durationToHours(duration))}:${pad(duration.minutes)}:${pad(duration.seconds)}`;
const pad = (value: number) => value.toString().padStart(2, '0');

export type Duration = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

/**
 * Calculate how long it has passed since the specify DateTime
 */

export const timeAgo = (date: Date, language = 'en') => {
  const time = new Date(date).getTime();
  const now = new Date().getTime();
  const diff = Math.floor((now - time) / 1000);
  const isNegative = diff < 0;
  const duration = durationFromSeconds(Math.abs(diff));
  const relativeTime = new Intl.RelativeTimeFormat(language, { style: 'short' });
  if (duration.days !== 0)
    return relativeTime.format(isNegative ? duration.days : -duration.days, 'day');
  if (duration.hours !== 0)
    return relativeTime.format(isNegative ? duration.hours : -duration.hours, 'hour');
  if (duration.minutes !== 0)
    return relativeTime.format(isNegative ? duration.minutes : -duration.minutes, 'minute');

  return duration.seconds === 0
    ? 'just now'
    : relativeTime.format(isNegative ? duration.seconds : -duration.seconds, 'second');
};

export const isAfter = (date: Date) => new Date() > date;

export const isToday = (date: Date) => date.toDateString() === new Date().toDateString();
