import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../../../../store/recoil/common';
import { prevalidationState } from '../state';

export const disconnectedFlowStopIdsSelector = selector({
  key: 'prevalidation/disconnectedFlowStopIds',
  get: ({ get }) => get(prevalidationState).disconnectedFlowStopIds,
  set: ({ set }, newIds: string[]) =>
    set(prevalidationState, (current) => ({ ...current, disconnectedFlowStopIds: newIds })),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
