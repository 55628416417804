import { useCallback } from 'react';

import { useNavitechStore } from '../../store';
import { LocalizationMapType } from '../../helpers/types';
import { useSaveData } from '../../hooks';
import { FileMultiList } from './FileMultiList';

export const NavitechUpload = () => {
  const { setFile, fileName, deleteFile } = useNavitechStore();
  const { deleteLocalizationMap, saveLocalizationMap } = useSaveData();

  const onFileSelected = useCallback(
    async (file: File) => {
      await setFile(file);
      await saveLocalizationMap(LocalizationMapType.Navitech, file);
    },
    [setFile, saveLocalizationMap],
  );

  const onDeleteClick = useCallback(async () => {
    deleteFile();
    await deleteLocalizationMap(LocalizationMapType.Navitech);
  }, [deleteFile, deleteLocalizationMap]);

  return (
    <FileMultiList
      accept='.xml'
      fileName={fileName}
      titleKey='interface:commissioning.navitech.label'
      onDeleteClick={onDeleteClick}
      onFileSelected={onFileSelected}
    />
  );
};
