import { createPointsElements } from '@/modules/canvas/mappers/common';
import { AngledHighwayShape } from '@modules/angledHighways/types';
import { ShapeType } from '@modules/common/types/shapes';
import { GeneratedLocation } from '@modules/floorplanService';
import { HighwayShape } from '@recoil/shape';
import { CircleElement, Color, Element, RectElement } from '@thive/canvas';
import { Vector3 } from 'three';
import { CHECKPOINT_COLORS, COLORS, GATE_COLORS, STROKE_COLOR } from '../../commissioning/mappers/areas/colors';

export const createHighwayElements = (
  shape: HighwayShape | AngledHighwayShape,
  artifacts: GeneratedLocation[],
) => {
  const elements: Element[] = [];

  if (shape.type === ShapeType.HIGHWAY_ANGLED) {
    elements.push(...createPointsElements(shape, Color.fromHex(COLORS[ShapeType.HIGHWAY])));
  } else {
    elements.push(createSimpleHighwayElement(shape));
  }

  artifacts.forEach((value) => {
    if (value.type === 'CHECK_POINT') {
      elements.push(createCheckPointElement(value, shape.id));
    }
    if (value.type === 'ROUTING_POINT') {
      elements.push(createRoutingPointElement(value, shape.id));
    }
  });

  return elements;
};

export const createSimpleHighwayElement = (shape: HighwayShape): Element => ({
  type: 'Rect',
  name: shape.name,
  id: shape.id,
  position: new Vector3(shape.properties.x, shape.properties.y),
  rotation: new Vector3(0, 0, shape.properties.r),
  size: new Vector3(shape.properties.width, shape.properties.height),
  stroke: Color.fromHex(STROKE_COLOR),
  strokeWidth: 2,
  fill: Color.fromHex(COLORS[ShapeType.HIGHWAY]),
  interactivity: {
    selectable: false,
  },
});

const createRoutingPointElement = (location: GeneratedLocation, parentId: string): RectElement => ({
  id: location.locationName,
  type: 'Rect',
  name: location.locationName,
  position: new Vector3(location.rectangle.centerX, location.rectangle.centerY, 1),
  parentId,
  rotation: new Vector3(0, 0, location.rectangle.angleInDegrees + 90),
  size: new Vector3(location.rectangle.width, location.rectangle.length),
  fill: Color.fromHex(GATE_COLORS[ShapeType.HIGHWAY]),
  interactivity: {
    selectable: false,
  },
});

const createCheckPointElement = (location: GeneratedLocation, parentId: string): CircleElement => ({
  id: `${location.locationName}_cp`,
  name: `${location.locationName}_cp`,
  type: 'Circle',
  parentId,
  center: new Vector3(location.x, location.y, 3),
  radius: 750 / 2,
  fill: Color.fromHex(CHECKPOINT_COLORS[ShapeType.HIGHWAY]),
  interactivity: {
    selectable: false,
  },
});
