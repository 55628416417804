import { LoadCarrierType } from '@/modules/common/types/floorPlan';
import { AreaLoadCarrierOrientation } from '@/modules/common/types/shapes';

export const numberOfColumns = (
  length: number,
  gap: number,
  margin: number,
  objectWidth: number,
): number => {
  if (length <= objectWidth) return 0
  if (length <= objectWidth * 2 + gap + margin * 2) return 1
  return Math.floor((length - 2 * margin + gap) / (objectWidth + gap));
}

export const numberOfRows = (length: number, loadLength: number, gap: number): number =>
  Math.floor((length + gap) / (loadLength + gap));

export const calcOrientedLoadCarriersBoundingBox = (
  loadCarriers: LoadCarrierType[],
  loadCarrierOrientation: AreaLoadCarrierOrientation,
) => {
  const boxWidth = Math.max(
    0,
    ...loadCarriers.map((item) =>
      loadCarrierOrientation === AreaLoadCarrierOrientation.SHORT_SIDE ? item.width : item.length,
    ),
  );
  const boxLength = Math.max(
    0,
    ...loadCarriers.map((item) =>
      loadCarrierOrientation === AreaLoadCarrierOrientation.SHORT_SIDE ? item.length : item.width,
    ),
  );

  return {
    width: boxWidth,
    length: boxLength,
  };
};
