import { useRecoilCallback } from 'recoil';
import { openModalSelector } from '@modules/common/store/modal';

export const useModal = () => {
  const openModal = useRecoilCallback(
    ({ set }) =>
      (types: string[]) => {
        set(openModalSelector, types);
      },
    [],
  );

  return {
    openModal,
  };
};
