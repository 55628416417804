import { SvgIcon } from '@mui/material';

export default function FitToScreenIcon(props) {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_521_6054'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='24'
        height='24'
      >
        <rect width='24' height='24' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_521_6054)'>
        <path
          d='M4 20V15H5V18.3L8.6 14.7L9.3 15.4L5.7 19H9V20H4ZM15 20V19H18.3L14.7 15.4L15.4 14.7L19 18.3V15H20V20H15ZM8.6 9.3L5 5.7V9H4V4H9V5H5.7L9.3 8.6L8.6 9.3ZM15.4 9.3L14.7 8.6L18.3 5H15V4H20V9H19V5.7L15.4 9.3Z'
          fill='currentColor'
        />
      </g>
    </SvgIcon>
  );
}
