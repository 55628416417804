import { Theme } from '@modules/common/types/general';
import { UserPreferenceName } from '@modules/userPreferences';
import { useUpdateUserPreferences, useUserPreference } from '@/modules/userPreferences/hooks';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { Stack, Switch } from '@mui/material';
import { useCallback } from 'react';

export const DarkModeSwitch = () => {
  const { updateUserPreference } = useUpdateUserPreferences();
  const theme = useUserPreference(UserPreferenceName.GENERAL_THEME);

  const onModelChange = useCallback(
    () =>
      updateUserPreference(
        UserPreferenceName.GENERAL_THEME,
        theme === Theme.DARK ? Theme.LIGHT : Theme.DARK,
      ),
    [updateUserPreference, theme],
  );

  return (
    <Stack direction='row' spacing={1} alignItems='center'>
      <LightModeIcon />
      <Switch onChange={onModelChange} checked={theme === Theme.DARK} />
      <DarkModeIcon />
    </Stack>
  );
};
