import { ArrowElement, Color, Element, GroupElement, LineElement } from '@thive/canvas';
import { Vector2, Vector3 } from 'three';

import { theme } from '@/modules/common/components/theme';
import { convertV2ToV3, getVectorRotatedAroundPoint } from '@/modules/common/helpers/math';
import { CurveElement } from '@thive/canvas/lib/common/types';

export const createBubbleElement = (
  id: string,
  center: Vector2,
  angle: number,
  disabled = false,
): Element[] => {
  const elements: Element[] = []
  const radius = 300 

  const divider: LineElement = {
    type: 'Line',
    name: `${id}-divider`,
    id: `${id}-divider`,
    pointA: convertV2ToV3(getVectorRotatedAroundPoint(new Vector2(0, radius).add(center), center, angle), 4),
    pointB: convertV2ToV3(getVectorRotatedAroundPoint(new Vector2(0, -radius).add(center), center, angle), 4),
    thickness: 40,
    stroke: Color.fromHex(theme.palette.shades.light),
    interactivity: {
      selectable: false,
    },
  }
  elements.push(divider)

  for (let i = 0; i < 2; i++) {
    const rotation = angle + 90 + 180 * i
   
    const curve: CurveElement = {
      type: 'Curve',
      name: `${id}-curve${i}`,
      id: `${id}-curve${i}`,
      points: [
        convertV2ToV3(getVectorRotatedAroundPoint(new Vector2(-radius/2, 0), new Vector2 (0, 0), rotation)),
        convertV2ToV3(getVectorRotatedAroundPoint(new Vector2(-radius/2, 200), new Vector2 (0, 0), rotation)),
        convertV2ToV3(getVectorRotatedAroundPoint(new Vector2(radius/2, 200), new Vector2 (0, 0), rotation)),
        convertV2ToV3(getVectorRotatedAroundPoint(new Vector2(radius/2, 0), new Vector2 (0, 0), rotation)),
      ],
      position: new Vector3(center.x, center.y, 4),
      stroke: Color.fromHex(theme.palette.primary.main),
      strokeWidth: radius,
      interactivity: {
        selectable: !disabled, 
      },
    }
    elements.push(curve)
  }

  for (let i = -1; i < 2; i+=2) {
    const arrow: ArrowElement = {
      type: 'Arrow',
      name: `${id}-arrow${i}`,
      id: `${id}-arrow${i}`,
      pointA: convertV2ToV3(getVectorRotatedAroundPoint(new Vector2(radius/2 * i, radius/2 * -i).add(center), center, angle), 4),
      pointB: convertV2ToV3(getVectorRotatedAroundPoint(new Vector2(radius/2 * i, radius/2 * i).add(center), center, angle), 4), 
      thickness: 40,
      stroke: Color.fromHex(theme.palette.shades.light),
      interactivity: {
        selectable: false,
      },
    }
    elements.push(arrow)
  }

  const group: GroupElement = {
    id,
    name: id,
    type: 'Group',
    childrenIds: elements.map((item) => item.id),
    interactivity: {
      selectable: false,
    },
  }
  
  return [...elements, group];
};
