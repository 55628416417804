import { selector } from 'recoil';
import { LayerModule } from '@modules/common/types/layers';
import { layersAtom } from '@modules/layers';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

export const moduleStateSelector = selector<LayerModule>({
  key: 'layersShowSelector',
  get: ({ get }) => ({
    layers: {
      ...get(layersAtom),
    },
  }),
  set: ({ set }, value: LayerModule) => {
    set(layersAtom, value.layers);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
