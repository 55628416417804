import { IconButton } from '@mui/material';
import { useCallback, useRef } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { CloseIcon } from '@/assets/icons';
import { NumberInput } from '@common/components/inputs';
import { areaPriority } from '@components/PropertiesPanel/store/area';
import { CONTEXT, contextState } from '@recoil/input';
import { useTranslation } from 'react-i18next';
import { useFloorPlanState } from '../../../modules/floorplan/hooks/useFloorPlanState';
import { selectedShapesState } from '@recoil/shapes/selected';
import { ShapeType } from '@modules/common/types/shapes';
import { UserPreferenceName } from '@modules/userPreferences';
import { useUpdateUserPreferences } from '@/modules/userPreferences/hooks';
import { WorkspaceMode } from '@modules/common/types/general';
import { modeSelector } from '@modules/common/store/workspace';
import { useArtefacts } from '@/modules/artefacts';

function AreaPriority() {
  const { saveFloorPlan } = useFloorPlanState();
  const [priority, setPriority] = useRecoilState(areaPriority);
  const setContext = useSetRecoilState(contextState);
  const { updateUserPreference } = useUpdateUserPreferences();
  const mode = useRecoilValue(modeSelector);
  const { updateDebounced: updateArtefacts } = useArtefacts();

  const onPriorityChange = useRecoilCallback(
    ({ snapshot }) =>
      async (value: number) => {
        setPriority(value);
        saveFloorPlan();

        const selectedShapes = await snapshot.getPromise(selectedShapesState);
        updateArtefacts(selectedShapes.map((item) => item.id));

        switch (selectedShapes[0].type) {
          case ShapeType.CHARGING:
          case ShapeType.CHARGING_POSITION:
            updateUserPreference(UserPreferenceName.CHARGING_PRIORITY, value);
            break;
          case ShapeType.PARKING:
          case ShapeType.PARKING_POSITION:
            updateUserPreference(UserPreferenceName.PARKING_PRIORITY, value);
            break;
          default:
            break;
        }
      },
    [setPriority, saveFloorPlan, updateUserPreference, updateArtefacts],
  );

  const onChange = useCallback(
    (newValue) => {
      if (newValue < 0) return;
      onPriorityChange(newValue);
    },
    [onPriorityChange],
  );

  const onClear = useCallback(() => {
    onPriorityChange(1); // Reset priority to default value
    saveFloorPlan();
  }, [onPriorityChange, saveFloorPlan]);

  const onFocus = useCallback(() => {
    setContext(CONTEXT.PROPERTYPANEL);
  }, [setContext]);

  return (
    <>
      <NumberInput
        value={priority}
        sx={{ width: '100%' }}
        disabled={mode !== WorkspaceMode.EDITABLE}
        onChange={onChange}
        onFocus={onFocus}
      />
      <IconButton
        onClick={onClear}
        sx={{
          position: 'absolute',
          right: 0,
          mt: '1px',
          mr: '18px',
          height: '34px',
          p: 0,
          pt: 0.5,
          display: priority !== 1 ? 'inline' : 'none',
        }}
      >
        <CloseIcon />
      </IconButton>
    </>
  );
}

export default AreaPriority;
