import { TemplateType } from '@/modules/common/types/templating';
import { ShapeType } from '@modules/common/types/shapes';

type ShapeIds = string[];

export enum GroupComposition {
  SINGLE_TYPE = 'single-type',
  MIXED = 'mixed',
}

export type SingleTypeGroup = {
  id: string;
  name: string;
  composition: GroupComposition.SINGLE_TYPE;
  type: ShapeType;
  children: ShapeIds;
  interactivityMode: ShapeGroupInteractivityMode;
};

type MixedGroupBase<T extends TemplateType> = {
  id: string;
  name: string;
  composition: GroupComposition.MIXED;
  type: T;
  children: ShapeIds;
  interactivityMode: ShapeGroupInteractivityMode;
};

export type LassieGroup = MixedGroupBase<TemplateType.LASSIE> & {
  generationParams: LassieGenerationParams;
};

type LassieGenerationParams = {
  name: string,
  x: number,
  y: number,
  angle: number,
  numAisles: number;
  numBays: number;
  numShelves: number;
}

export type TemplateGroupGenerationParams = {
  [TemplateType.LASSIE]: LassieGenerationParams;
};

export type ShapeGroup = SingleTypeGroup | LassieGroup;

export type legacyFlowGroup = {
  id: string;
  name: string;
  type: ShapeType;
  children: ShapeIds;
};

export enum ShapeGroupInteractivityMode {
  LOCKED_SELECTABLE = 'lockedSelectable',
  UNRESTRICTED = 'unrestricted',
}

export const groupIsSingleTypeGroup = (group: ShapeGroup): group is SingleTypeGroup =>
  group.composition === 'single-type';
