import { Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { enabledLoadCarrierTypesSelector } from '@/store/recoil/loadCarrierTypes';
import { selectionContainsLoadCarrierSupport } from '@/store/recoil/shapes/selected';
import { ShapeType } from '@modules/common/types/shapes';
import { supportedVehiclesNameSelector } from '@/modules/vehicles';
import { areaType, areaVehicleIds } from '../../store/area';
import ChargingContent from './ChargingContent';
import LoadContent from './LoadContent';
import ParkingContent from './ParkingContent';

function InfoCard() {
  const shapeType = useRecoilValue(areaType);
  const containsLoadCarrierSupport = useRecoilValue(selectionContainsLoadCarrierSupport);
  const enabledLoadCarriers = useRecoilValue(enabledLoadCarrierTypesSelector);
  const supportedVehicleIds = useRecoilValue(areaVehicleIds);
  const vehicleNames = useRecoilValue(supportedVehiclesNameSelector(supportedVehicleIds));

  const title = useMemo(() => {
    if (containsLoadCarrierSupport) {
      return enabledLoadCarriers[0].name;
    }
    if (shapeType === ShapeType.CHARGING || shapeType === ShapeType.PARKING) {
      return vehicleNames.join('\n');
    }

    return '';
  }, [containsLoadCarrierSupport, enabledLoadCarriers, shapeType, vehicleNames]);

  return (
    <Stack
      spacing={0}
      sx={{ px: 2, mb: 1 }}>
      <Stack direction='column'
        justifyContent='space-between'
        alignItems='stretch'
        spacing={0}
        sx={{
          backgroundColor: 'others.lighter',
          p: 2,
          width: '100%',
          height: '100%',
          '&:hover': { cursor: 'default' },
        }}
        className='infoCard'>
        <Typography
          align='left'
          sx={{ whiteSpace: 'pre-line', fontSize: '14px', lineHeight: '16px' }}
        >
          {title}
        </Typography>

        {containsLoadCarrierSupport && <LoadContent />}
        {shapeType === ShapeType.CHARGING && <ChargingContent />}
        {shapeType === ShapeType.PARKING && <ParkingContent />}
      </Stack>

    </Stack>
  );
}

export default InfoCard;
