import { atom } from 'recoil';

const modalAtom = atom({
  key: 'ModalAtom',
  default: {
    queue: [],
  },
});

export default modalAtom;
