import { useCallback } from 'react';
import { useCanvasStore } from '../store';

/**
 * Returns actions to control the canvas's camera
 */
export const useCanvasCamera = () => {
  const instance = useCanvasStore((state) => state.instance);

  const zoomFit = useCallback(() => instance.zoomToFit(), [instance]);
  const zoomIn = useCallback(() => instance.zoomIn(), [instance]);
  const zoomOut = useCallback(() => instance.zoomOut(), [instance]);
  const zoomReset = useCallback(() => instance.setZoom(100), [instance]);

  return { zoomFit, zoomOut, zoomIn, zoomReset };
};
