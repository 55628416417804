import { SvgIcon } from '@mui/material';

export default function LaneLeftRightIcon(props) {
  return (
    <SvgIcon fill='none' fontSize='large' {...props}>
      <path d='M12.3277 17.6154L11.62 16.9077L16.2142 12.3077L11.62 7.7077L12.3277 7L17.6354 12.3077L12.3277 17.6154ZM18.4277 17.6154L17.72 16.9077L22.3142 12.3077L17.72 7.7077L18.4277 7L23.7354 12.3077L18.4277 17.6154Z' />
      <path d='M6.16769 17.6154L0.859993 12.3077L6.16769 7L6.87539 7.7077L2.28117 12.3077L6.87539 16.9077L6.16769 17.6154ZM12.2677 17.6154L6.95999 12.3077L12.2677 7L12.9754 7.7077L8.38117 12.3077L12.9754 16.9077L12.2677 17.6154Z' />
    </SvgIcon>
  );
}
