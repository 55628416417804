import { useProjectApi } from '@/modules/api/hooks';
import { useNavigation } from '@/modules/common/hooks';
import { Box, Button, Grid, Paper, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TOP_BAR_HEIGHT_PX } from '@modules/common/components/TopBar';
import { DashboardTopBar } from '@common/components/DashboardTopBar';

type Project = {
  id: string;
  name: string;
  created: Date;
  lastUpdated: Date;
};

export function ProjectsDashboard() {
  const { t } = useTranslation(['interface']);
  const [projects, setProjects] = useState<Project[]>([]);
  const { goToProject } = useNavigation();
  const { fetchAll } = useProjectApi();

  const getAllProjects = useCallback(async () => {
    try {
      setProjects(await fetchAll());
    } catch (error) {
      console.log(error);
    }
  }, [fetchAll]);

  useEffect(() => {
    getAllProjects();
  }, [getAllProjects]);

  return (
    <>
      <DashboardTopBar />

      <Box
        sx={{
          mt: TOP_BAR_HEIGHT_PX,
          ml: '0',
          p: 4,
          textAlign: 'left',
          zIndex: 1,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {t('interface:home.your_projects', 'Your Projects')}
        </Box>

        <Grid sx={{ mt: '20px' }} container spacing={2}>
          {projects.length > 0
            ? projects.map((project) => {
                const { name, ...rest } = project;

                return (
                  <Grid item key={`${project.lastUpdated}-${project.name}`} xs={12} md={6} lg={4}>
                    <Paper
                      sx={{
                        p: 2,
                      }}
                    >
                      <Typography variant='h5' component='h4' sx={{ mb: 2 }}>
                        {name}
                      </Typography>
                      {Object.entries(rest).map(([key, value]) => {
                        value = value instanceof Date ? value.toString() : value;
                        return (
                          <Stack direction='row' key={`${key}-${value}`}>
                            <Typography
                              sx={{
                                width: '140px',
                                color: 'neutral.main',
                              }}
                            >
                              {key}:{' '}
                            </Typography>
                            <Typography
                              sx={{
                                flex: 1,
                                pb: 1,
                              }}
                            >
                              {key === 'description'
                                ? `${(value as string).substring(0, 90).trim()}...`
                                : value}
                            </Typography>
                          </Stack>
                        );
                      })}
                      <Box sx={{ textAlign: 'right', mt: '20px' }}>
                        <Button onClick={() => goToProject(project.id)}>Open</Button>
                      </Box>
                    </Paper>
                  </Grid>
                );
              })
            : t('interface:home.no_projects', 'There are no projects to display.')}
        </Grid>
      </Box>
    </>
  );
}
