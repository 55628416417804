import { Position } from '@helpers/types';

type Model = {
  getBoundingBox(): THREE.Box3;
};
type Navigation = {
  getScreenViewport(): ClientRect;
  getCamera(): THREE.Camera;
  getPosition(): THREE.Vector3;
  getTarget(): THREE.Vector3;
  setView(position: THREE.Vector3, target: THREE.Vector3): void;
  setPosition(pos: THREE.Vector3): void;
};

export const isViewportPositionOverModel = (
  screenPosition: Position,
  navigation: Navigation,
  model: Model,
) => {
  if (!navigation || !model) return false;
  const { height, width } = navigation.getScreenViewport();
  const screenVector = viewportPositionToScreenPosition(
    screenPosition.x,
    screenPosition.y,
    width,
    height,
  );
  projectVector3FromCameraNDCIntoWorldSpace(navigation.getCamera(), screenVector);
  return model.getBoundingBox().containsPoint(screenVector);
};

export const viewportPositionToScreenPosition = (
  x: number,
  y: number,
  width: number,
  height: number,
) => new THREE.Vector3((x / width) * 2 - 1, -(y / height) * 2 + 1, 0);

export const screenPositionToViewportPosition = (
  x: number,
  y: number,
  width: number,
  height: number,
) => ({
  x: ((x + 1) / 2) * width,
  y: ((y - 1) / -2) * height,
});

export const setCameraPositionFromScreenPosition = (
  bb: THREE.Box3,
  position: Position,
  offset: Position,
  navigation: Navigation,
  zoomScale: number,
) => {
  const { height, width } = navigation.getScreenViewport();
  const projectedVector = viewportPositionToScreenPosition(
    position.x + offset.x * zoomScale,
    position.y + offset.y * zoomScale,
    width,
    height,
  );

  projectVector3FromCameraNDCIntoWorldSpace(navigation.getCamera(), projectedVector);

  projectedVector.sub(new THREE.Vector3(bb.min.x, bb.max.y, 0));
  let currentPosition = navigation.getPosition().sub(projectedVector);

  navigation.setView(
    currentPosition.clone().setZ(navigation.getPosition().z),
    currentPosition.clone().setZ(navigation.getTarget().z),
  );
};

export const setCameraZoomFromScale = (scale: number, navigation: Navigation) => {
  const pos = navigation.getPosition().clone();
  pos.setZ(pos.z / scale);
  navigation.setPosition(pos);
};

export const offsetCamera = (offset: Position, prevOffset: Position, navigation: Navigation) => {
  const newPosition = navigation
    .getPosition()
    .clone()
    .sub(new THREE.Vector3((offset.x - prevOffset.x) / 100, (offset.y - prevOffset.y) / -100));

  navigation.setView(
    newPosition.clone().setZ(navigation.getPosition().z),
    newPosition.clone().setZ(navigation.getTarget().z),
  );
};

export const getModelViewportSize = (bb: THREE.Box3, navigation: Navigation) => {
  const minScreenPosition = bb.min.clone().project(navigation.getCamera());
  const maxScreenPosition = bb.max.clone().project(navigation.getCamera());
  const { height, width } = navigation.getScreenViewport();
  const minViewportPosition = screenPositionToViewportPosition(
    minScreenPosition.x,
    minScreenPosition.y,
    width,
    height,
  );
  const maxViewportPosition = screenPositionToViewportPosition(
    maxScreenPosition.x,
    maxScreenPosition.y,
    width,
    height,
  );
  return {
    width: maxViewportPosition.x - minViewportPosition.x,
    height: minViewportPosition.y - maxViewportPosition.y,
  };
};

export const calculateBoundingBox = (viewer: Autodesk.Viewing.Viewer3D) =>
  new Promise<THREE.Box3>((resolve, reject) => {
    viewer.getObjectTree(
      (root) => {
        const dbIds = Object.keys(root.nodeAccess.dbIdToIndex).map(Number);
        const objectBounds = new THREE.Box3();

        dbIds.forEach((dbId) => {
          root.enumNodeFragments(
            dbId,
            (fragId) => {
              const fragBounds = new THREE.Box3();
              viewer.model.getFragmentList().getWorldBounds(fragId, fragBounds);
              objectBounds.union(fragBounds);
            },
            true,
          );
        });

        resolve(objectBounds);
      },
      (errorCode: number, errorMessage: string) => {
        reject(new Error(`Getting object tree failed. Reason: ${errorMessage}`));
      },
    );
  });

const projectVector3FromCameraNDCIntoWorldSpace = (
  camera: THREE.Camera,
  vector3: THREE.Vector3,
) => {
  // make camera's internal matrices up-to-date
  camera.updateMatrixWorld(true);
  vector3.unproject(camera);
};
