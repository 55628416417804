import { selectorFamily } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import shapeAtom from './atom';

export const shapeNameSelector = selectorFamily<string, string>({
  key: 'shape/name',
  get:
    (id) =>
    ({ get }) => {
      const shape = get(shapeAtom(id));
      if (!shape.name) return null;

      return shape.name;
    },
  set:
    (id) =>
    ({ set }, newValue: string) => {
      set(shapeAtom(id), (current) => ({
        ...current,
        name: newValue,
      }));
    },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
