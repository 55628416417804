import Box from '@mui/material/Box';

import { DashboardTopBar } from '@common/components/DashboardTopBar';
import { TOP_BAR_HEIGHT_PX } from '@common/components/TopBar';

import FloorplanCreator from './FloorplanCreator';
import FloorplanDuplicator from './FloorplanDuplicator';
import { FloorplanLayout } from './FloorplanLayout';
import { FloorplanContextMenu } from './contextMenu/FloorplanContextMenu';
import { useFloorplanAdmin } from './hooks';
import FloorplanDeleter from './FloorplanDeleter';
import { Snackbar } from '@modules/snackbar/components/Snackbar';

const boxStyle = {
  pt: TOP_BAR_HEIGHT_PX,
  height: '100vh',
  overflowY: 'scroll',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  zIndex: 1,
};

export function FloorplanAdmin() {
  const { projectIsLoading, floorPlanGroupsAreLoading } = useFloorplanAdmin();

  return (
    <Box position='relative'>
      <DashboardTopBar showLoading={projectIsLoading || floorPlanGroupsAreLoading} />

      <Box sx={boxStyle}>
        <FloorplanLayout />
        <FloorplanCreator />
        <FloorplanDeleter />
        <FloorplanDuplicator />
        <Snackbar justifyContent='center' rightOffset='24px' bottomOffset='36px' />
      </Box>

      <FloorplanContextMenu />
    </Box>
  );
}
