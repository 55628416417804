import { SvgIcon } from '@mui/material';

function RedoIcon({ className = '', ...rest }) {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      style={{ fill: 'currentColor', stroke: 'none' }}
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      {...rest}
    >
     <path d="M3.60001 18.5V15C3.60001 13.75 4.03767 12.6873 4.91301 11.812C5.78767 10.9373 6.85001 10.5 8.10001 10.5H18.5L14.4 6.39995L15.1 5.69995L20.4 11L15.1 16.3L14.4 15.6L18.5 11.5H8.10001C7.11667 11.5 6.28767 11.8416 5.61301 12.525C4.93767 13.2083 4.60001 14.0333 4.60001 15V18.5H3.60001Z" />
    </SvgIcon>
  );
}
export default RedoIcon;
