import { atom } from 'recoil';

import { SimulationPanel } from '../../helpers/types';

export const moduleAtom = atom({
  key: 'simulation/module/atom',
  default: {
    panel: SimulationPanel.LIST,
    isLoading: true,
    isFirstLoaded: false,
    errorMessage: null,
    floorPlanId: null,
  },
});
