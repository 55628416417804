import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import dwgAtom from './atom';
import { Position } from '@/helpers/types';

export type ReferenceImageScale = {
  dist: number;
  set: boolean;
  free: boolean;
  pointA: Position | null;
  pointB: Position | null;
  setPointA: Position | null;
  setPointB: Position | null;
  setDist: number;
};
const referenceScale = selector<Partial<ReferenceImageScale>>({
  key: 'reference/scale',
  get: ({ get }) => {
    const currentValue = get(dwgAtom);

    return {
      dist: currentValue.dist,
      set: currentValue.set,
      free: currentValue.free,
      pointA: currentValue.pointA,
      pointB: currentValue.pointB,
      setPointA: currentValue.setPointA,
      setPointB: currentValue.setPointB,
      setDist: currentValue.setDist,
    };
  },
  set: ({ get, set }, newValue) => {
    set(dwgAtom, { ...get(dwgAtom), ...newValue });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default referenceScale;
