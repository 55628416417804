import { useEffect } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { useLoadUserPreferences } from '@/modules/userPreferences/hooks';

export const useApp = () => {
  const isAuthenticated = useIsAuthenticated();
  const { load: loadUserPreferences } = useLoadUserPreferences();

  useEffect(() => {
    if (isAuthenticated) {
      loadUserPreferences();
    }
  }, [loadUserPreferences, isAuthenticated]);
};
