import { MathUtils } from 'three';

const easeInExpo = (x: number): number => (x === 0 ? 0 : 2 ** (10 * x - 10));

/**
 * Calculates size to change with zoom level change.
 * TODO move to canvas
 */
export const calculateZoomInRange = (
  zoom: number,
  minZoom: number,
  maxZoom: number,
  baseSize: number,
  targetSize: number,
) => {
  const zoomDiff = maxZoom - minZoom;
  const sizeDiff = targetSize - baseSize;
  const multiplier = (zoomDiff - (MathUtils.clamp(zoom, minZoom, maxZoom) - minZoom)) / zoomDiff;

  return baseSize + sizeDiff * easeInExpo(multiplier);
};
