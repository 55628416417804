import { Vector2d } from 'konva/lib/types';
import { DTShape } from '../common/types/shapes';
import { coverAreas, propagations } from './constants';

// Input

export type Propagation = {
  name: string;
  coefficient: number;
  constant: number;
  ap_height: number;
  height_limit: number;
};

export const getPropagation = (name: string): Propagation =>
  propagations.find((p) => p.name === name) ?? propagations[0];

export type CoverArea = {
  name: string;
  strength_enable: number;
  strength_sta_wall: number;
  strength_sta_normal: number;
};

export const getCoverArea = (name: string): CoverArea =>
  coverAreas.find((c) => c.name === name) ?? coverAreas[0];

export type WifiSimulationInput = {
  propagation: Propagation;
  cover_area: CoverArea;
  shapes: DTShape[];
};

// Output

export type Size = {
  width: number,
  height: number
}

export type Box = {
  top_left: Vector2d,
  bottom_right: Vector2d
}

export type AccessPoint = {
  id: string,
  coverage: Box,
  location: Vector2d
}

export type Coverage = {
  number_of_acccess_points: number
  target_coverage_rate: number
  total_area: number
}

export type WifiConfiguration = {
  access_points: AccessPoint[],
  coverage: Coverage
}

export type WifiSimulationResult = {
  configurations: WifiConfiguration[]
}
