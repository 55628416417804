import * as pdfjsLib from 'pdfjs-dist';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { LayerNames } from '@/modules/common/types/layers';
import { floorPlanIdSelector, projectIdSelector, useFloorPlanState } from '@/modules/floorplan';
import { layersShowSelector } from '@modules/layers';
import { useApi } from '@modules/referenceImage/AutodeskViewer/hooks';
import { getFileExtension } from '@modules/referenceImage/helpers';
import { useDwgJustGotUploaded, useReferenceImage } from '@modules/referenceImage/hooks';
import { referenceImageNameAtom } from '@modules/referenceImage/store/nameAton';
import { referenceImageStatusAtom } from '@modules/referenceImage/store/statusAtom';
import { useZoomButton } from '@modules/workspace/components/ZoomButton/useZoomButton';
import { modeSelector } from '../../../common/store/workspace';
import { WorkspaceMode } from '../../../common/types/general';
import { Status } from '../../types';
import { FileUploadButton } from '@common/components/FileUploadButton';
import { convertFileToDataURL } from '@modules/common/helpers/browser';
import { useSnackbarStore } from '@/modules/snackbar/store/useSnackbarStore';

const SEVENSENSE_WATERMARK_ID = 'sevensense_watermark';

export const referenceFileTpes = ['image', 'dwg', 'pdf'];

export function UploadUi() {
  const { t } = useTranslation(['common', 'errors', 'interface']);
  const setReferenceLayer = useSetRecoilState(layersShowSelector(LayerNames.REFERENCE));
  const { showSnackbar } = useSnackbarStore();
  const mode = useRecoilValue(modeSelector);
  const { setReferenceImage, setReferenceAPS } = useReferenceImage();
  const { saveFloorPlan } = useFloorPlanState();
  const { uploadFile } = useApi();
  const [status, setStatus] = useRecoilState(referenceImageStatusAtom);
  const setReferenceImageName = useSetRecoilState(referenceImageNameAtom);
  const { zoomFitReference } = useZoomButton();
  const { setDwgJustGotUploaded } = useDwgJustGotUploaded();
  const projectId = useRecoilValue(projectIdSelector);
  const floorPlanId = useRecoilValue(floorPlanIdSelector);
  const disabled = mode !== WorkspaceMode.EDITABLE;

  const newReferenceImage = useCallback(
    async (
      fileName: string,
      base64string: string,
      scaledDimensions?: { width: number; height: number },
    ) => {
      const image = new Image();

      image.onload = async () => {
        setReferenceLayer(true);
        await setReferenceImage(
          fileName,
          base64string,
          image,
          projectId,
          floorPlanId,
          scaledDimensions,
        );
        setStatus(Status.DONE);
        zoomFitReference();
        saveFloorPlan();
      };

      image.src = base64string;
    },
    [
      setReferenceLayer,
      setReferenceImage,
      projectId,
      floorPlanId,
      setStatus,
      zoomFitReference,
      saveFloorPlan,
    ],
  );

  const handleFileSelect = useCallback(
    async (file: File) => {
      setReferenceImageName(file.name);
      setStatus(Status.LOADING);

      const fileType = file?.type || getFileExtension(file.name);

      if (fileType === undefined) {
        showSnackbar(
          t('errors:reference.incompatible_file', 'Incompatible file')
        );
        setStatus(Status.NONE);
        return;
      }

      if (referenceFileTpes.every((t) => !fileType.includes(t))) {
        showSnackbar(
          t('errors:reference.not_an_image', 'File type must be an image')
        );
        setStatus(Status.NONE);
        return;
      }

      if (fileType.includes('dwg')) {
        showSnackbar(
          t(
            'interface:reference.popups.DWG_preparing', 
            `Preparing the DWG file. Depending on the file's size and its complexity it might take from few seconds to few minutes.`
          )
        );

        try {
          const urn = await uploadFile(file);
          setReferenceLayer(true);
          setReferenceAPS(file.name, urn, projectId, floorPlanId);
          showSnackbar(
            t(
              'interface:reference.popups.DWG_conversion_done', 
              `DWG file succesfully converted to PNG file.`
            )
          );
          setStatus(Status.DONE);
          setDwgJustGotUploaded(true);
        } catch (e) {
          t(
            'interface:reference.popups.DWG_conversion_failed', 
            `DWG conversion failed`
          )
          setStatus(Status.NONE);
        }

        return;
      }

      const isPDF = fileType.includes('pdf');
      const isSVG = fileType.includes('svg');

      if (isPDF) {
        showSnackbar(
          t(
            'interface:reference.popups.PDF_preparing',
            `Preparing the PDF file. Depending on the file's size and its complexity it might take from few seconds to few minutes.`
          )
        );
      }

      pdfjsLib.GlobalWorkerOptions.workerSrc = await import('pdfjs-dist/build/pdf.worker.entry');

      const dataUrl = await convertFileToDataURL(file);

      if (isPDF) {
        pdfjsLib.getDocument(dataUrl).promise.then((pdf) => {
          pdf.getPage(1).then(
            (page) => {
              const scale = 1;
              const viewport = page.getViewport({ scale });
              const canvas = document.createElement('canvas');
              const canvasContext = canvas.getContext('2d');
              canvas.height = viewport.height;
              canvas.width = viewport.width;

              const renderContext = {
                canvasContext,
                viewport,
              };

              page.render(renderContext).promise.then(() => {
                newReferenceImage(file.name, canvas.toDataURL());
                setTimeout(async () => {
                  showSnackbar(
                    t(
                      'interface:reference.popups.PDF_conversion_done',
                      `PDF file successfully converted to PNG file.`
                    )
                  );
                }, 1000);
              });
            },
            (error) => {
              console.error('error loading PDF: ', error);
              showSnackbar(
                t(
                  'interface:reference.popups.PDF_conversion_failed',
                  `PDF conversion failed.`
                )
              );
            },
          );
        });
      } else if (isSVG) {
        const text = await file.text();
        const document = new DOMParser().parseFromString(text, 'image/svg+xml');
        const isSevenSenseMap = document.getElementById(SEVENSENSE_WATERMARK_ID);

        if (!isSevenSenseMap) {
          await newReferenceImage(file.name, dataUrl);
          return;
        }

        const svg = Array.from(document.children).at(0);
        const [, , width, height] = svg.getAttribute('viewBox').split(' ');
        const scaledDimensions = { width: Number(width) * 1000, height: Number(height) * 1000 };
        await newReferenceImage(file.name, dataUrl, scaledDimensions);
      } else {
        newReferenceImage(file.name, dataUrl);
      }
    },
    [
      floorPlanId,
      newReferenceImage,
      projectId,
      setReferenceAPS,
      setReferenceImageName,
      setReferenceLayer,
      setStatus,
      showSnackbar,
      t,
      uploadFile,
      setDwgJustGotUploaded,
    ],
  );

  if (status === Status.LOADING) {
    return null;
  }

  return (
    <FileUploadButton
      onUploaded={handleFileSelect}
      accept={'image/*,.dwg, .pdf'}
      label={t('interface:reference.upload_modal.label')}
      disabled={disabled}
    />
  );
}
