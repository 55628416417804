import { atom } from 'recoil';

export type Project = {
  id: string;
  name: string;
};

export const projectState = atom<Project>({
  key: 'project',
  default: {
    id: null,
    name: null,
  },
});
