import { useParams } from 'react-router-dom';
import { Divider, Popover, Stack, Badge, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import BellIcon from '@/assets/icons/BellIcon';
import { useNotificationStore } from '../store/useNotificationStore';
import Notification from '@/modules/Notifications/components/Notification';
import NotificationsHeader from '@/modules/Notifications/components/NotificationsHeader';
import { useTranslation } from 'react-i18next';
import { ToolbarIconButton } from '@/components/Toolbar3/ToolbarButton';
import { theme } from '@/modules/common/components/theme';

export default function NotificationPopup() {
  const { notifications, markMessagesAsRead, hasUnreadNotifications } = useNotificationStore();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { floorPlanId } = useParams();

  // rename store to match floorplan id
  useEffect(() => {
    useNotificationStore?.persist.setOptions({
      name: `notifications-${floorPlanId}`,
    });
    useNotificationStore?.persist.rehydrate();
    // remove the default index
    localStorage.removeItem('notifications-store');
  }, [floorPlanId]);

  const openNotificationPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    markMessagesAsRead();
  };

  const open = Boolean(anchorEl);
  const popup = open ? 'popup' : undefined;
  const notificationList = useMemo(() => [...notifications.values()], [notifications]);
  const { t } = useTranslation();

  return (
    <>
      <ToolbarIconButton
        type={(open ? 'Selected' : 'Deselected') as any}
        onClick={openNotificationPopup}
      >
        <Badge color='primary' variant='dot' invisible={!hasUnreadNotifications}>
          <BellIcon size={24} aria-describedby={popup} />
        </Badge>
      </ToolbarIconButton>
      <Popover
        id={popup}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{ ml: 3, mt: 1 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <NotificationsHeader />
        <Divider />
        <Stack width='400px' divider={<Divider />}>
          {notificationList.length > 0 ? (
            notificationList.map((notification) => (
              <Notification key={notification.id} notification={notification} />
            ))
          ) : (
            <Stack p={2} justifyContent='center' minHeight={100}>
              <Typography textAlign='center'>
                {t('interface:notifications.no_notifications')}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Popover>
    </>
  );
}
