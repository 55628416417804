import {
  useRemoveDistantConnection,
  useUndoRedoDistantConnections,
} from '@modules/connections/distant';
import { useFloorPlanState } from '@modules/floorplan';
import { PreValidationAspect, useRunPreValidation } from '@modules/floorplanValidation/clientSide';
import { useContextMenu } from '@modules/workspace/hooks';
import { MenuItem } from '@mui/material';
import { HistoryManager } from '@recoil/history';
import { showDeleteOptionSelector } from '@recoil/workspace/contextMenu';
import { relatedComponentIdsState } from '@recoil/workspace/contextMenu/state/relatedComponentIdsState';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

export const DeleteOption = () => {
  const show = useRecoilValue(showDeleteOptionSelector);

  if (!show) return null;

  return <DeleteOptionComponent />;
};

const DeleteOptionComponent = () => {
  const { t } = useTranslation();
  const { removeDistantConnections } = useRemoveDistantConnection();
  const { runPreValidation } = useRunPreValidation();
  const { getDistantConnectionDeleteState, restoreDistantConnectionsState } =
    useUndoRedoDistantConnections();
  const { saveFloorPlan } = useFloorPlanState();
  const componentIds = useRecoilValue(relatedComponentIdsState);
  const { hide } = useContextMenu();

  const onClick = useCallback(async () => {
    hide();
    const { newState, oldState } = await getDistantConnectionDeleteState([componentIds[0]]);
    HistoryManager.track(`Connection deleted`, newState, oldState, restoreDistantConnectionsState);
    removeDistantConnections([componentIds[0]]);
    saveFloorPlan();
    runPreValidation([PreValidationAspect.INCORRECTLY_CONNECTED_SHAPES]);
  }, [
    hide,
    getDistantConnectionDeleteState,
    componentIds,
    restoreDistantConnectionsState,
    removeDistantConnections,
    saveFloorPlan,
    runPreValidation,
  ]);

  return (
    <MenuItem onClick={onClick} component='button'>
      {t('interface:context_menu.workspace.delete', 'Delete')}
    </MenuItem>
  );
};
