import { useRecoilCallback, useRecoilTransaction_UNSTABLE } from 'recoil';
import { Vector3 } from 'three';

import {
  shapesAngleState,
  shapesHeightState,
  shapesPositionState,
  shapesWidthState,
} from '@/components/PropertiesPanel/store/dimension';
import { clampAngle180 } from '@/modules/common/helpers/math';
import { isPointsShape } from '@/modules/common/types/guards';
import { positionAfterRotation } from '@/modules/workspace/helpers/shape';
import { DTShape } from '@/store/recoil/shape';
import shapeAtom from '@/store/recoil/shape/atom';

export const useOnShapeAngleChange = () => {

  const onShapeAngleChange = useRecoilCallback(
    ({ set, snapshot }) =>
      async (newValue: number) => {
        const { x, y } = await snapshot.getPromise(shapesPositionState);
        const width = await snapshot.getPromise(shapesWidthState);
        const height = await snapshot.getPromise(shapesHeightState);
        const angle = await snapshot.getPromise(shapesAngleState);

        const newPosition = positionAfterRotation(
          new Vector3(x, y),
          width,
          height,
          angle,
          newValue,
        );
        set(shapesPositionState, newPosition);
        set(shapesAngleState, newValue);
      },
    [],
  );

  const onUnmountAngleChange = useRecoilTransaction_UNSTABLE(({ set }) => (ids: string[]) => {
    ids.forEach((id) => {
      set(shapeAtom(id), (current: DTShape): DTShape => {
        // TODO: points property support
        if (isPointsShape(current)) return current;

        const currentAngle = current.properties.r;
        const clampedAngle = clampAngle180(currentAngle);

        if (currentAngle === clampedAngle) return current;

        return {
          ...current,
          properties: {
            ...current.properties,
            r: clampedAngle,
          },
        };
      });
    });
  });

  return {
    onShapeAngleChange,
    onUnmountAngleChange,
  };
};
