import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../../store/recoil/common';
import dwgAtom from './atom';

const dwgDimensions = selector({
  key: 'reference/dimensions',
  get: ({ get }) => {
    const { x, y, width, height } = get(dwgAtom);

    return {
      x,
      y,
      width,
      height,
    };
  },
  set: ({ get, set }, newValue) => {
    set(dwgAtom, { ...get(dwgAtom), ...newValue });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default dwgDimensions;
