import {
  GeneratedFloorPlanArtefacts,
  GeneratedLoadPosition,
  GeneratedLocation,
} from '@modules/floorplanService';
import { DTShape } from '@modules/common/types/shapes';
import { getGateIdFromLocation, getShapeIdFromLocation, getShapeNameFromLoadPositon } from './id';

export type GateArtifacts = {
  id: string;
  shapeId: string;
  station: GeneratedLocation;
  point: GeneratedLocation;
  loads: GeneratedLoadPosition[];
};

export const groupByGate = (artifacts: GeneratedFloorPlanArtefacts) => {
  const tmpDict = new Map<string, GateArtifacts>();

  artifacts.loadPositions.forEach((artifact) => {
    const gateId = artifact.reachableLocations[0]?.locationName ?? artifact.loadPositionName;

    if (!tmpDict.get(gateId)) {
      tmpDict.set(gateId, createEmptyGateArtifacts(gateId));
    }

    tmpDict.get(gateId).loads.push(artifact);
  });

  artifacts.locations.forEach((artifact) => {
    if (!artifact.rectangle) {
      return;
    }

    const gateId = getGateIdFromLocation(artifact);

    if (!tmpDict.get(gateId)) {
      tmpDict.set(gateId, createEmptyGateArtifacts(gateId));
    }

    tmpDict.get(gateId).station = artifact;
    tmpDict.get(gateId).point = artifact;
    tmpDict.get(gateId).shapeId = getShapeIdFromLocation(artifact);
  });

  const dict = new Map<string, GateArtifacts>();

  tmpDict.forEach((value, key) => {
    if (value.station) {
      dict.set(value.station.locationName, value);
    }
  });

  return dict;
};

export const groupByShape = (shapes: DTShape[], artifacts: GeneratedFloorPlanArtefacts) => {
  const dict = new Map<string, GeneratedFloorPlanArtefacts>();
  const shapeNamesDict = new Map<string, string>();
  shapes.forEach((item) => shapeNamesDict.set(item.name, item.id));

  artifacts.loadPositions.forEach((artifact) => {
    const shapeId = shapeNamesDict.get(getShapeNameFromLoadPositon(artifact));

    if (!dict.get(shapeId)) {
      dict.set(shapeId, createEmptyGeneratedArtefacts());
    }

    dict.get(shapeId).loadPositions.push(artifact);
  });

  artifacts.locations.forEach((artifact) => {
    const shapeId = getShapeIdFromLocation(artifact);

    if (!dict.get(shapeId)) {
      dict.set(shapeId, createEmptyGeneratedArtefacts());
    }

    dict.get(shapeId).locations.push(artifact);
  });

  return dict;
};

const createEmptyGeneratedArtefacts = (): GeneratedFloorPlanArtefacts => ({
  loadPositions: [],
  locations: [],
});

const createEmptyGateArtifacts = (id: string): GateArtifacts => ({
  id,
  station: null,
  point: null,
  shapeId: null,
  loads: [],
});
