import EventEmitter from 'events';
import { useCallback } from 'react';

const eventEmitter = new EventEmitter();

export enum EventNames {
  VisualizationWebGLSend = 'Visualization:WegGLEvent:Send',
  VisualizationWebGLReceive = 'Visualization:WegGLEvent:Receive',
  VisualizationLoadingFinished = 'Visualization:AppEvent:LoadingFinished',
}

export const useEventBus = () => {
  const on = useCallback(
    (event: string, fn: (...args: any[]) => void) => eventEmitter.on(event, fn),
    [],
  );
  const once = useCallback(
    (event: string, fn: (...args: any[]) => void) => eventEmitter.once(event, fn),
    [],
  );
  const off = useCallback(
    (event: string, fn: (...args: any[]) => void) => eventEmitter.off(event, fn),
    [],
  );
  const emit = useCallback((event: string, payload?: any) => (eventEmitter.emit(event, payload)), []);

  return {
    on,
    once,
    off,
    emit,
  };
};
