import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { allGroupsSelector } from '@/modules/shapeGroups';
import areasAtom from './atom';

export const areaIdsNotGroupedSelector = selector<string[]>({
  key: 'area/ids/nonGrouped',
  get: ({ get }) => {
    const ids = new Set(get(areasAtom));
    const groups = get(allGroupsSelector);
    groups.forEach((g) => {
      g.children.forEach((shapeId) => ids.delete(shapeId));
    });
    return Array.from(ids);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
