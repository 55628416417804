import { atom, selector } from 'recoil';
import { Size } from '@modules/common/types/general';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

export const sizeAtom = atom<Size>({
  key: 'workspace/size',
  default: {
    // 25 x 25 km
    width: 25000000,
    height: 25000000,
  },
});

export const sizeSelector = selector<Size>({
  key: 'workspace/sizeSelector',
  get: ({ get }) => get(sizeAtom),
  set: ({ set }, newValue: Size) => set(sizeAtom, newValue),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
