import { useRecoilCallback } from 'recoil';

import { WorkspaceMode } from '../types/general';
import { modeSelector } from '../store/workspace';

export const useWorkspaceMode = () => {
  const setMode = useRecoilCallback(
    ({ set }) =>
      (mode: WorkspaceMode) =>
        set(modeSelector, mode),
    [],
  );

  return {
    setMode,
  };
};
