import { selector } from 'recoil';
import { floorplanIdBeingDuplicatedState } from './floorplanIdBeingDuplicatedState';
import { floorplansState } from './floorplansState';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../../../store/recoil/common';

export const floorplanBeingDuplicatedSelector = selector({
  key: 'project/floorplans/duplicating/floorplan',
  get: ({ get }) => {
    const id = get(floorplanIdBeingDuplicatedState);
    return get(floorplansState).find((item) => item.id === id);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
