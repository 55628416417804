import { SVGProps, memo } from 'react';

function SvgComponent(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#a)'>
        <path
          d='m23.502 23.5-4.34-4.339M2.5.502h-1a1 1 0 0 0-1 1v1m18-2h1a1 1 0 0 1 1 1v1M5.501.5h3.5m3 0h3.5M2.5 20.502h-1a1 1 0 0 1-1-1v-1M5.501 20.5h3.5m3 0h2.5m-14-15V9m0 3v3.5m20-10V9m0 3v2m-3.975 6.773a.5.5 0 0 0 .827.2l3.806-3.806a.5.5 0 0 0-.2-.827l-5.708-1.9a.5.5 0 0 0-.633.633l1.908 5.7Z'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path d='M0 0h24v24H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

const Memo = memo(SvgComponent);
export default Memo;
