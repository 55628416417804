import { useAxios } from '@modules/api/hooks';
import { useConfig } from '@modules/common/hooks';
import { useCallback, useMemo } from 'react';

import { AuthInfo } from '../types';

export const useAuth = () => {
  const { aps } = useConfig();
  const scopes = useMemo(() => [aps.authApiScope], [aps.authApiScope]);
  const { get } = useAxios({
    baseURL: aps.authApi,
    scopes,
  });

  const fetchAuthInfo = useCallback(async (): Promise<AuthInfo> => {
    if (!aps.enabled) {
      throw new Error('APS is disabled');
    }

    const response = await get('token');

    const { access_token, expires_in } = await response.data;

    return {
      accessToken: access_token,
      expiresIn: expires_in,
    };
  }, [aps.enabled, get]);

  const getToken = () => fetchAuthInfo();

  return {
    getToken,
  };
};
