import { Bubble } from '@/modules/common/components/Bubble';
import { distcon } from '@modules/connections/distant/store';
import { HistoryManager } from '@recoil/history';
import { useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { modeSelector } from '../../../common/store/workspace';
import { WorkspaceMode } from '../../../common/types/general';

type Props = {
  id: string;
  x: number;
  y: number;
  rot: number;
  saveFloorPlan: () => void;
};

export const DistantConnectionBubble = ({ id, x, y, rot, saveFloorPlan }: Props) => {
  const [connection, setConnection] = useRecoilState(distcon(id));
  const mode = useRecoilValue(modeSelector);
  const listening = mode === WorkspaceMode.EDITABLE;

  const onInClick = useCallback(() => {
    const oldState = connection;
    const newState = {
      ...connection,
      inChecked: !connection.inChecked,
    };

    HistoryManager.track(`Connection updated`, newState, oldState, (state) => setConnection(state));
    setConnection(newState);
    saveFloorPlan();
  }, [connection, saveFloorPlan, setConnection]);

  const onOutClick = useCallback(() => {
    const oldState = connection;
    const newState = {
      ...connection,
      outChecked: !connection.outChecked,
    };

    HistoryManager.track(`Connection updated`, newState, oldState, (state) => setConnection(state));
    setConnection(newState);
    saveFloorPlan();
  }, [connection, saveFloorPlan, setConnection]);

  const { inChecked, outChecked } = connection;

  return (
    <Bubble
      x={x}
      y={y}
      rotation={rot}
      inChecked={inChecked}
      outChecked={outChecked}
      onInClick={onInClick}
      onOutClick={onOutClick}
      id={id}
      listening={listening}
    />
  );
};
