import { referenceSelected } from '@/modules/referenceImage/store';
import { selectedShapesIdsState } from '@recoil/shapes/selected';
import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../common';
import navAtom from './atom';

const navSelector = selector({
  key: 'navSelector',
  get: ({ get }) => get(navAtom),
  set: ({ set }, newValue) => {
    set(navAtom, newValue);
    set(selectedShapesIdsState, []);
    set(referenceSelected, false);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default navSelector;
