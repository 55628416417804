import { Vector2 } from 'three';

import { OrientedBoundingBox } from '@/helpers/types';
import { LineSegment, WideLineSegment } from '@/modules/common/types/general';
import { getVectorRotatedAroundPoint } from '@/modules/common/helpers/math';

const offsetLine = (p1: Vector2, p2: Vector2, dist: number): LineSegment => {
  const delta = new Vector2(p2.x - p1.x, p2.y - p1.y);
  const dir = delta.clone().normalize();
  const perp = new Vector2(-dir.y, dir.x);
  const n1 = new Vector2(p1.x + perp.x * dist, p1.y + perp.y * dist);
  const n2 = new Vector2(n1.x + delta.x, n1.y + delta.y);
  return { start: n1, end: n2 };
};

export const lineToPolygon = (line: WideLineSegment): Vector2[] => {
  const lineA = offsetLine(line.points.start, line.points.end, line.width / 2);
  const lineB = offsetLine(line.points.start, line.points.end, -line.width / 2);

  return [lineA.start, lineA.end, lineB.end, lineB.start, lineA.start];
};

export const boundingBoxToPolygon = (boundingBox: OrientedBoundingBox): Vector2[] => {
  const centerOfRotation = new Vector2(boundingBox.x, boundingBox.y);

  return [
    getVectorRotatedAroundPoint(new Vector2(boundingBox.x, boundingBox.y), centerOfRotation, -boundingBox.r),
    getVectorRotatedAroundPoint(new Vector2(boundingBox.x + boundingBox.width, boundingBox.y), centerOfRotation, -boundingBox.r),
    getVectorRotatedAroundPoint(new Vector2(boundingBox.x + boundingBox.width, boundingBox.y + boundingBox.height), centerOfRotation, -boundingBox.r),
    getVectorRotatedAroundPoint(new Vector2(boundingBox.x, boundingBox.y + boundingBox.height), centerOfRotation, -boundingBox.r)
  ]
};

export const makeOffsetPoly = (points: Vector2[], offset: number, outer_ccw = 1) => {
  const output = [];
  const num_points = points.length - 1;

  for (let curr = 0; curr < num_points; curr++) {
    const prev = (curr + num_points - 1) % num_points;
    const next = (curr + 1) % num_points;

    const vn = new Vector2(points[next].x - points[curr].x, points[next].y - points[curr].y);
    const vnn = vn.normalize();
    const nnnX = vnn.y;
    const nnnY = -vnn.x;

    const vp = new Vector2(points[curr].x - points[prev].x, points[curr].y - points[prev].y);
    const vpn = vp.normalize();
    const npnX = vpn.y * outer_ccw;
    const npnY = -vpn.x * outer_ccw;

    const bis = new Vector2((nnnX + npnX) * outer_ccw, (nnnY + npnY) * outer_ccw);
    const bisn = bis.normalize();
    const bislen = offset / Math.sqrt((1 + nnnX * npnX + nnnY * npnY) / 2);

    output.push(new Vector2(points[curr].x + bislen * bisn.x, points[curr].y + bislen * bisn.y));
  }

  output.push(output[0]);
  return output;
};

export function doPolygonsIntersect(polygonA: Vector2[], polygonB: Vector2[]) {
  var polygons = [polygonA, polygonB];
  var minA;
  var maxA;
  var projected;
  var i;
  var i1;
  var j;
  var minB;
  var maxB;

  for (i = 0; i < polygons.length; i++) {
    // for each polygon, look at each edge of the polygon, and determine if it separates
    // the two shapes
    let polygon = polygons[i];
    for (i1 = 0; i1 < polygon.length; i1++) {
      // grab 2 vertices to create an edge
      let i2 = (i1 + 1) % polygon.length;
      let p1 = polygon[i1];
      let p2 = polygon[i2];

      // find the line perpendicular to this edge
      let normal = { x: p2.y - p1.y, y: p1.x - p2.x };

      minA = undefined;
      maxA = undefined;
      // for each vertex in the first shape, project it onto the line perpendicular to the edge
      // and keep track of the min and max of these values
      for (j = 0; j < polygonA.length; j++) {
        projected = normal.x * polygonA[j].x + normal.y * polygonA[j].y;
        if (minA === undefined || projected < minA) {
          minA = projected;
        }
        if (maxA === undefined || projected > maxA) {
          maxA = projected;
        }
      }

      // for each vertex in the second shape, project it onto the line perpendicular to the edge
      // and keep track of the min and max of these values
      minB = undefined;
      maxB = undefined;
      for (j = 0; j < polygonB.length; j++) {
        projected = normal.x * polygonB[j].x + normal.y * polygonB[j].y;
        if (minB === undefined || projected < minB) {
          minB = projected;
        }
        if (maxB === undefined || projected > maxB) {
          maxB = projected;
        }
      }

      // if there is no overlap between the projects, the edge we are looking at separates the two
      // polygons, and we know there is no overlap
      if (maxA < minB || maxB < minA) {
        return false;
      }
    }
  }

  return true;
}

export const getCenter = (points: Vector2[]): Vector2 => {
  let minX: number;
  let maxX: number;
  let minY: number;
  let maxY: number;
  for (let i = 0; i < points.length; i++)
  {
    minX = (points[i].x < minX || minX == null) ? points[i].x : minX;
    maxX = (points[i].x > maxX || maxX == null) ? points[i].x : maxX;
    minY = (points[i].y < minY || minY == null) ? points[i].y : minY;
    maxY = (points[i].y > maxY || maxY == null) ? points[i].y : maxY;
  }
  return new Vector2((minX + maxX) / 2, (minY + maxY) / 2);
}
