import { AreaLoadPlacement, ShapeType } from '@modules/common/types/shapes';
import { UserPreferenceName, getUserPreference } from '@modules/userPreferences';

export const loadPlacement = (shapeType: ShapeType): AreaLoadPlacement | null => {
  switch (shapeType) {
    case ShapeType.PROCESS_ONE_EP:
    case ShapeType.PROCESS_ONE_EP_POSITION:
    case ShapeType.PROCESS_TWO_EP:
      return getUserPreference(UserPreferenceName.PROCESS_LOAD_PLACEMENT);
    case ShapeType.HANDOVER:
      return getUserPreference(UserPreferenceName.HANDOVER_LOAD_PLACEMENT);
    default:
      return null;
  }
};
