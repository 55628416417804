import { AreaDirection, AreaEndpointDirection } from '@/modules/common/types/shapes';
import { areaStackingMode, areaTypeToFPAreaType } from './utils';
import { Rectangle } from '@/helpers/types';

export const addVehicleArea = (
  vehicleSpec,
  id,
  shapeType,
  rectangle: Rectangle,
  stackingMode = null,
) => {
  vehicleSpec.areas.push({
    name: id,
    type: areaTypeToFPAreaType(shapeType),
    stackingMode,
    narrowAisleWidth: null,
    rectangle,
    entries: [],
  });
};
