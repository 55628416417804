import { CoverArea, Propagation } from './types';

export const propagations: Propagation[] = [
  {
    name: 'Factory 2G - High Density [Raspberry Pi]',
    coefficient: 14.847,
    constant: -31.701,
    ap_height: 30.0,
    height_limit: 400.0,
  },
  {
    name: 'Factory 2G - Medium Density [raspberryPi',
    coefficient: 14.057,
    constant: -33.347,
    ap_height: 30.0,
    height_limit: 400.0,
  },
  {
    name: 'Factory 2G - Low Density [Raspberry Pi]',
    coefficient: 11.612,
    constant: -34.215,
    ap_height: 30.0,
    height_limit: 400.0,
  },
  {
    name: 'Factory 5G - High Density [Raspberry Pi]',
    coefficient: 15.712,
    constant: -40.807,
    ap_height: 30.0,
    height_limit: 400.0,
  },
  {
    name: 'Factory 5G - Medium Density [Raspberry Pi]',
    coefficient: 15.162,
    constant: -38.816,
    ap_height: 30.0,
    height_limit: 400.0,
  },
  {
    name: 'Factory 5G - Low Density [Raspberry Pi]',
    coefficient: 14.271,
    constant: -37.874,
    ap_height: 30.0,
    height_limit: 400.0,
  },
  {
    name: 'Factory 2G - Low Density [Cisco]',
    coefficient: 12.321,
    constant: -34.844,
    ap_height: 30.0,
    height_limit: 400.0,
  },
  {
    name: 'Factory 5G - Low Density [Cisco]',
    coefficient: 9.9444,
    constant: -42.828,
    ap_height: 30.0,
    height_limit: 400.0,
  },
  {
    name: 'Factory 2G - Low Density [Aruba]',
    coefficient: 11.923,
    constant: -29.766,
    ap_height: 30.0,
    height_limit: 400.0,
  },
  {
    name: 'Factory 5G - Low Density [Aruba]',
    coefficient: 8.3285,
    constant: -38.322,
    ap_height: 30.0,
    height_limit: 400.0,
  },
  {
    name: 'Warehouse 2G - Low Density [Raspberry Pi]',
    coefficient: 21.871,
    constant: -22.769,
    ap_height: 30.0,
    height_limit: 400.0,
  },
  {
    name: 'Warehouse 5G - High Density [Raspberry Pi]',
    coefficient: 12.698,
    constant: -44.075,
    ap_height: 30.0,
    height_limit: 400.0,
  },
  {
    name: 'Warehouse 5G - Low Density [Raspberry Pi]',
    coefficient: 11.324,
    constant: -42.59,
    ap_height: 30.0,
    height_limit: 400.0,
  },
  {
    name: 'Arcade 2G [Raspberry Pi]',
    coefficient: 16.325,
    constant: -34.067,
    ap_height: 30.0,
    height_limit: 400.0,
  },
  {
    name: 'Arcade 5G [Raspberry Pi]',
    coefficient: 9.5547,
    constant: -46.347,
    ap_height: 30.0,
    height_limit: 400.0,
  },
];

export const coverAreas: CoverArea[] = [
  {
    name: 'Default Area',
    strength_enable: -64.0,
    strength_sta_wall: -57.0,
    strength_sta_normal: -54.0,
  },
  {
    name: 'Arcade 2G',
    strength_enable: -64.0,
    strength_sta_wall: -54.0,
    strength_sta_normal: -54.0,
  },
  {
    name: 'Arcade 5G',
    strength_enable: -64.0,
    strength_sta_wall: -56.0,
    strength_sta_normal: -56.0,
  },
  {
    name: 'Factory 2G',
    strength_enable: -64.0,
    strength_sta_wall: -54.0,
    strength_sta_normal: -54.0,
  },
  {
    name: 'Factory 5G',
    strength_enable: -64.0,
    strength_sta_wall: -56.0,
    strength_sta_normal: -56.0,
  },
  {
    name: 'Warehouse 2G',
    strength_enable: -64.0,
    strength_sta_wall: -52.0,
    strength_sta_normal: -52.0,
  },
  {
    name: 'Warehouse 5G',
    strength_enable: -64.0,
    strength_sta_wall: -55.0,
    strength_sta_normal: -55.0,
  },
];
