import { useEffect, useMemo } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';

import { Module } from '@/modules/common/types/navigation';
import { useNavigation } from '@modules/common/hooks';
import {
  isFloorplanValidSelector,
  preValidationShowSelector,
  preValidationValidSelector,
} from '@modules/floorplanValidation/clientSide';
import { useRequiredReferenceScaling } from '@modules/setup/hooks/useRequiredReferenceScaling';
import { toolButtonState } from '@recoil/tool';
import { TabProps, TabsComponent } from './Tabs';

export const TabsMain = () => {
  const { goToModule } = useNavigation();
  const [simulationEnabled, setSimulationEnabled] = useRecoilState(preValidationValidSelector);
  const [showValidationPanel, setShowValidationPanel] = useRecoilState(preValidationShowSelector);
  const isFloorplanValid = useRecoilValue(isFloorplanValidSelector);
  const { referenceExistsAndIsNotScaled, setIsDialogOpen: setRequiredScalingDialogOpen } =
    useRequiredReferenceScaling();
  const tabLabels: TabProps[] = useMemo(() => getTabLabels(simulationEnabled), [simulationEnabled]);

  const onChange = useRecoilCallback(
    ({ set }) =>
      async (nextModule: Module) => {
        set(toolButtonState, null);

        if (referenceExistsAndIsNotScaled) {
          goToModule(Module.SETUP);
          setRequiredScalingDialogOpen(true);
          return;
        }

        if (nextModule === Module.SIMULATION && !showValidationPanel) {
          setShowValidationPanel(!isFloorplanValid);
          setSimulationEnabled(isFloorplanValid);
          goToModule(Module.LAYOUT);
          if (!isFloorplanValid) return;
        }

        goToModule(nextModule);
      },
    [
      referenceExistsAndIsNotScaled,
      showValidationPanel,
      goToModule,
      setRequiredScalingDialogOpen,
      setShowValidationPanel,
      isFloorplanValid,
      setSimulationEnabled,
    ],
  );

  useEffect(() => {
    const set = async () => {
      setShowValidationPanel(!isFloorplanValid);
      setSimulationEnabled(isFloorplanValid);
    };
    if (showValidationPanel) set();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showValidationPanel, isFloorplanValid, setShowValidationPanel, setSimulationEnabled]);

  return (
    <TabsComponent tabLabels={tabLabels} onChange={onChange} sx={{ padding: '16px 16px 0' }} />
  );
};

const getTabLabels = (simulationEnabled: boolean) => [
  {
    label: 'Setup',
    trans: 'tabs.setup',
    disableTab: false,
    id: Module.SETUP,
  },
  {
    label: 'Layout',
    trans: 'tabs.layout',
    disableTab: false,
    id: Module.LAYOUT,
  },
  {
    label: 'Simulation',
    trans: 'tabs.simulation',
    disableTab: !simulationEnabled,
    id: Module.SIMULATION,
  },
];
