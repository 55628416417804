import { selector } from 'recoil';
import { stageSelector } from './stageSelector';
import { Position, StageProps } from '@helpers/types';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../common';

export const panSelector = selector<Position>({
  key: 'panSelector',
  get: ({ get }) => {
    const ws = get<StageProps>(stageSelector);

    return {
      x: ws.x,
      y: ws.y,
    };
  },
  set: ({ get, set }, position: Position) => {
    set(stageSelector, {
      ...get(stageSelector),
      ...position,
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
