import { isGroupable } from '@/modules/common/helpers/shapes';
import { DTShape, ShapeType } from '@/modules/common/types/shapes';
import { useFloorPlanState } from '@/modules/floorplan';
import { SelectShapeOperation, useSelectedShape } from '@/modules/workspace/hooks/useSelectedShape';
import { Theme } from '@modules/common/types/general';
import { TemplateType } from '@/modules/common/types/templating';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Divider, MenuItem } from '@mui/material';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { formatShapeType } from '../../../store/recoil/floorplan/helper';
import { selectedShapesState } from '../../../store/recoil/shapes/selected';
import { showGroupOptionSelector } from '../../../store/recoil/workspace/contextMenu/selectors/showGroupOptionSelector';
import { PreValidationAspect, useRunPreValidation } from '../../floorplanValidation/clientSide';
import { UserPreferenceName } from '@/modules/userPreferences';
import { useUserPreference } from '@/modules/userPreferences/hooks';
import { useContextMenu } from '../../workspace/hooks';
import { selectedGroupIdsState } from '../atom';
import { selectedGroupsSelector } from '../selectors';
import { useShapeGroup } from '../hooks/useShapeGroup';

export const GroupOptionMenu = () => {
  const show = useRecoilValue(showGroupOptionSelector);
  if (!show) return null;
  return <GroupOptionMenuComponent />;
};

type GroupOptions = Map<ShapeType, DTShape[]>;

const GroupOptionMenuComponent = memo(() => {
  const { t } = useTranslation('interface');
  const { hide } = useContextMenu();
  const [groupOptions, setGroupOptions] = useState<GroupOptions>(new Map());
  const [showUngroup, setShowUngroup] = useState<boolean>(false);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState<boolean>(false);
  const { saveFloorPlan } = useFloorPlanState();
  const { runPreValidation } = useRunPreValidation();
  const { createSingleTypeGroupFromSelectedShapes, deleteGroups } = useShapeGroup();
  const { deselect, select } = useSelectedShape();
  const theme = useUserPreference(UserPreferenceName.GENERAL_THEME);

  const menuAnchor = useRef(null);

  const openSubmenu = useCallback(() => {
    setIsSubmenuOpen(true);
  }, [setIsSubmenuOpen]);

  const closeSubMenu = useCallback(() => {
    setIsSubmenuOpen(false);
  }, [setIsSubmenuOpen]);

  const checkSelectionContents = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const shapes = await snapshot.getPromise(selectedShapesState);
        const selectedGroups = await snapshot.getPromise(selectedGroupsSelector);
        const groupedShapeIds = new Set(selectedGroups.flatMap((group) => group.children));
        const templateInSelection = selectedGroups.some(item => item.type === TemplateType.LASSIE);

        // this shape doesn't belong to group, showing options to groups
        const groupOptions: GroupOptions = new Map();
        shapes.forEach((shape) => {
          if (groupedShapeIds.has(shape.id)) {
            return;
          }

          const shapeType = formatShapeType(shape.type) as ShapeType;

          if (groupOptions.has(shapeType)) {
            groupOptions.set(shapeType, [shape, ...groupOptions.get(shapeType)]);
          } else {
            groupOptions.set(shapeType, [shape]);
          }
        });

        setShowUngroup(selectedGroups.length > 0 && !templateInSelection);
        setGroupOptions(groupOptions);
      },
    [],
  );

  useEffect(() => {
    checkSelectionContents();
  }, [checkSelectionContents]);

  const handleGroup = useCallback(
    async (key) => {
      if (isGroupable(key)) {
        // TODO:verify if working correctly
        const shapeGroup = await createSingleTypeGroupFromSelectedShapes(key);
        if (!shapeGroup) return;

        deselect();
        await select(SelectShapeOperation.OVERWRITE, shapeGroup.children);
        await saveFloorPlan();
        runPreValidation([
          PreValidationAspect.REQUIRED_ELEMENTS,
          PreValidationAspect.INCORRECTLY_CONNECTED_SHAPES,
          PreValidationAspect.DISCONNECTED_FLOW_STOPS,
          PreValidationAspect.CONNECTION_LACKING_ROADS,
        ]);
      }
      hide();
    },
    [
      createSingleTypeGroupFromSelectedShapes,
      hide,
      saveFloorPlan,
      select,
      deselect,
      runPreValidation,
    ],
  );

  const ungroupSelectedGroups = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const selectedGroupIds = await snapshot.getPromise(selectedGroupIdsState);
        await deleteGroups(selectedGroupIds);
        saveFloorPlan();
        runPreValidation([
          PreValidationAspect.CONNECTION_LACKING_ROADS,
          PreValidationAspect.REQUIRED_ELEMENTS,
          PreValidationAspect.INCORRECTLY_CONNECTED_SHAPES,
        ]);
        hide();
      },
    [deleteGroups, hide, saveFloorPlan, runPreValidation],
  );

  const clickHandler = useCallback(
    (e) => {
      e.stopPropagation();
      openSubmenu();
    },
    [openSubmenu],
  );

  const onMouseEnter = useCallback((e) => {
    setIsSubmenuOpen(true);
  }, []);

  const onMouseLeave = useCallback((e) => {
    setIsSubmenuOpen(false);
  }, []);

  const handleGroupMemo = useCallback(
    (key) => () => {
      handleGroup(key);
    },
    [handleGroup],
  );

  return (
    <MenuItem
      ref={menuAnchor}
      onClick={clickHandler}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      component='button'
      sx={{
        display: 'flex',
        gap: '14px',
        justifyContent: 'space-between',
      }}
    >
      {t('interface:context_menu.workspace.grouping.group')}
      <ArrowRightIcon sx={{ marginLeft: '70px' }} />
      <Popper
        open={isSubmenuOpen}
        anchorEl={menuAnchor.current}
        placement='right-start'
        transition
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 4],
            },
          },
        ]}
        sx={{
          borderRadius: '4px',
          minWidth: 208,
          zIndex: 99999,
        }}
        className={theme === Theme.DARK ? 'submenuBgDark dark' : 'submenuBg'}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={{ appear: 500, enter: 100, exit: 200 }}>
            <Paper
              onMouseLeave={closeSubMenu}
              elevation={1}
              sx={{
                borderRadius: '4px',
                padding: '6px 0px',
                ...(theme === Theme.DARK ? { border: '1px solid white' } : {}),
              }}
            >
              {Array.from(groupOptions).map(([key, value]) => (
                <MenuItem
                  key={key}
                  sx={{
                    display: 'flex',
                    gap: '14px',
                    justifyContent: 'space-between',
                  }}
                  disabled={value.length < 2 || !isGroupable(key)}
                  onClick={handleGroupMemo(key)}
                >
                  {t(`interface:context_menu.workspace.grouping.${key}`, {
                    amount: value.length,
                  })}
                </MenuItem>
              ))}
              {groupOptions.size > 0 && <Divider />}
              <MenuItem
                sx={{
                  display: 'flex',
                  gap: '14px',
                  justifyContent: 'space-between',
                }}
                disabled={!showUngroup}
                onClick={ungroupSelectedGroups}
              >
                {t('interface:context_menu.workspace.grouping.ungroup')}
              </MenuItem>
            </Paper>
          </Fade>
        )}
      </Popper>
    </MenuItem>
  );
});
