import { getFloorPlanServiceData } from '@/modules/commissioning';
import { FloorplanServiceOption } from '@modules/floorplanService/enum';
import { GenerateFloorPlanExportSettings } from '@modules/floorplanService/types';

export const createExportSettings = (
  option: FloorplanServiceOption,
): GenerateFloorPlanExportSettings => {
  const { floorPlanAdjustment, reflectors } = getFloorPlanServiceData();

  switch (option) {
    case FloorplanServiceOption.Splines:
      return {
        splines: 'EXPORT_WITH_SPLINES',
        unifiedLayout: 'EXPORT_WITH_SPLINES',
        collision: false,
        floorPlanAdjustment,
      };
    case FloorplanServiceOption.All:
      return {
        splines: 'EXPORT_WITH_SPLINES',
        unifiedLayout: 'EXPORT_WITH_SPLINES',
        collision: true,
        floorPlanAdjustment,
      };
    case FloorplanServiceOption.Validate:
      return {
        splines: 'EXPORT',
        unifiedLayout: 'NO_EXPORT',
        collision: false,
        floorPlanAdjustment,
      };
    case FloorplanServiceOption.TStackExport:
      return {
        splines: 'NO_EXPORT',
        unifiedLayout: 'NO_EXPORT',
        collision: false,
        tStackExport: true,
        reflectors,
        floorPlanAdjustment,
      };
    case FloorplanServiceOption.Partial:
      return {
        splines: 'NO_EXPORT',
        unifiedLayout: 'NO_EXPORT',
        collision: false,
        tStackExport: false,
        includeFloorPlanArtefactsInOutput: true,
        reflectors: [],
        floorPlanAdjustment,
      };
    case FloorplanServiceOption.Artefacts:
      return {
        splines: 'NO_EXPORT',
        unifiedLayout: 'NO_EXPORT',
        collision: false,
        tStackExport: false,
        includeFloorPlanArtefactsInOutput: true,
        reflectors: [],
        floorPlanAdjustment,
      };
    case FloorplanServiceOption.Maestro:
      return {
        splines: 'EXPORT_WITH_SPLINES',
        unifiedLayout: 'EXPORT',
        collision: false,
        maestroExport: true,
        floorPlanAdjustment,
      };
    default:
      console.log('Bad option for calling FloorplanService');
      return null;
  }
};
