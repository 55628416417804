import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PropertiesTitle } from '@common/components/PropertiesTitle';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { CONTEXT, contextState } from '@recoil/input';
import { useCallback } from 'react';

import { SIMULATION_EDIT_ERROR } from '../../../helpers/constants';
import { SimulationStatus } from '../../../helpers/types';
import { nameSelector, versionSelector } from '../../../store/draft';
import { useSimulationDraftCallbacks } from '../../../hooks';
import { SimulationStatusChip } from '../../SimulationStatusChip';
import { Version } from '../../Version';
import { CloseButton } from '../../CloseButton';

export function Header() {
  const { t } = useTranslation('interface');
  const { updateDraftSimulation, closeSimulationEditPanel } = useSimulationDraftCallbacks();
  const setContext = useSetRecoilState(contextState);
  const name = useRecoilValue(nameSelector);
  const version = useRecoilValue(versionSelector);

  const onNameChange = useCallback(
    (value) => {
      updateDraftSimulation((simulation) => ({ ...simulation, name: value }));
    },
    [updateDraftSimulation],
  );

  const onNameBlur = useCallback(() => setContext(CONTEXT.WORKSPACE), [setContext]);
  const onNameFocus = useCallback(() => setContext(CONTEXT.PROPERTYPANEL), [setContext]);
  const nameError = !name?.length && SIMULATION_EDIT_ERROR.NAME_TOO_SHORT;
  const onCloseClick = useCallback(() => closeSimulationEditPanel(), [closeSimulationEditPanel]);

  return (
    <Stack sx={{ px: 2 }}>
      <Stack direction='row' alignItems='center' justifyContent='space-between' gap={1}>
        <SimulationStatusChip status={SimulationStatus.DRAFT} />
        <CloseButton onCloseClick={onCloseClick} />
      </Stack>
      <PropertiesTitle
        sx={{ mt: 1 }}
        value={name}
        editable
        onChange={onNameChange}
        onFocus={onNameFocus}
        onBlur={onNameBlur}
        error={!!nameError}
        helperText={nameError ? t(nameError.i18nMessageKey) : ''}
      />
      <Version sx={{ mt: 1 }}>{version}</Version>
    </Stack>
  );
}
