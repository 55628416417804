import { Element } from '@thive/canvas';

import { createAreaElements } from '@/modules/canvas/mappers/area';
import { createHighwayElements } from '@/modules/canvas/mappers/highway';
import { createDirectionElement, createShapeElement } from '@/modules/canvas/mappers/shape';
import { createWallElement } from '@/modules/canvas/mappers/wall';
import { GeneratedFloorPlanArtefacts } from '@/modules/floorplanService';
import { convertToBottomLeftCoordinateSystem, groupByShape } from '@modules/artefacts';
import { DTShape, ShapeType } from '@modules/common/types/shapes';

export const map = (
  salesShapes: readonly DTShape[],
  artifacts: GeneratedFloorPlanArtefacts,
): Element[] => {
  const shapes: Element[] = mapShapesToCanvasElement(salesShapes, artifacts);
  return shapes;
};

const mapShapesToCanvasElement = (
  salesShapes: readonly DTShape[],
  artifacts: GeneratedFloorPlanArtefacts,
): Element[] => {
  const elements: Element[] = [];
  const mappedShapes = convertToBottomLeftCoordinateSystem(salesShapes);
  const gateDict = groupByShape(mappedShapes, artifacts);
  
  mappedShapes.forEach((shape) => {
    if (
      shape.type === ShapeType.INTAKE ||
      shape.type === ShapeType.DELIVERY ||
      shape.type === ShapeType.STORAGE ||
      shape.type === ShapeType.CHARGING ||
      shape.type === ShapeType.PROCESS_ONE_EP
    ) {
      elements.push(...createAreaElements(shape, gateDict.get(shape.id), true, false))
      elements.push(...createDirectionElement(shape));
      // elements.push(...createNameLabelElement(shape));
    }

    if (shape.type === ShapeType.HIGHWAY || shape.type === ShapeType.HIGHWAY_ANGLED) {
      elements.push(...createHighwayElements(shape, gateDict.get(shape.id).locations));
      // elements.push(...createNameLabelElement(shape));
    }

    if (shape.type === ShapeType.OBSTACLE) {
      elements.push(createShapeElement(shape, true));
    }

    if (shape.type === ShapeType.WALL) {
      elements.push(...createWallElement(shape));
    }
  });

  return elements;
};
