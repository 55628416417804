import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { layerTheme } from '@/modules/common/components/theme';
import { LayerNames } from '@/modules/common/types/layers';
import { ShapeType } from '@/modules/common/types/shapes';
import { LayerItem } from '@/modules/layers/components';
import { UnhideEverythingButton } from '@/modules/layers/components/UnhideEverythingButton';
import { Names } from '@/modules/layers/components/names/Names';
import { everythingIsVisible } from '@/modules/layers/store/layersSelector';
import { allProcessTwoEndPointSelector } from '@/modules/processTwoEndPoint/store';
import { allGroupsSelector } from '@/modules/shapeGroups';
import { Accordion } from '@common/components/Accordion';
import { allAngledHighwaysSelector } from '@modules/angledHighways';
import { layoutFlowsSelector } from '@modules/flows/store/layout';
import { Stack, ThemeProvider } from '@mui/material';
import { allAreasSelector } from '@recoil/shapes/area';
import { allHighwaysSelector } from '@recoil/shapes/highway';
import { allObstaclesSelector } from '@recoil/shapes/obstacle';
import { allWallsSelector } from '@recoil/shapes/wall';

const LayerPropertiesComponent = () => {
  const { t } = useTranslation('interface');
  const showUnhideButton = !useRecoilValue(everythingIsVisible);

  return (
    <Accordion
      title={t('interface:layers.toggle', 'Layers')}
      afterIcon={showUnhideButton ? <UnhideEverythingButton /> : null}
      unmountOnExit
      sx={{ fontSize: '14px', paddingRight: 2 }}
      titleSx={{ paddingLeft: 2 }}
    >
      <LayerPropertiesContent />
    </Accordion>
  );
};

const LayerPropertiesContent = () => {
  const { t } = useTranslation('interface');
  const areas = useRecoilValue(allAreasSelector);
  const processTwoEps = useRecoilValue(allProcessTwoEndPointSelector);
  const highways = useRecoilValue(allHighwaysSelector);
  const angledHighways = useRecoilValue(allAngledHighwaysSelector);
  const obstacles = useRecoilValue(allObstaclesSelector);
  const walls = useRecoilValue(allWallsSelector);
  const flows = useRecoilValue(layoutFlowsSelector);
  const groups = useRecoilValue(allGroupsSelector);

  return (
    <ThemeProvider theme={layerTheme}>
      <Stack direction='column' spacing={0} sx={{ marginRight: -2 }}>
        <LayerItem name={LayerNames.CONNECTIONS} label={t('layers.connections')} level={0} />
        <Names
          name={LayerNames.NAMES}
          label={t('layers.names')}
          groups={[
            {
              name: LayerNames.AREA_NAMES,
              label: t('layers.areas'),
              elements: [...areas, ...processTwoEps, ...groups],
            },
            {
              name: LayerNames.HIGHWAY_NAMES,
              label: t('layers.roads'),
              elements: [...highways, ...angledHighways],
            },
            {
              name: LayerNames.OBSTACLE_NAMES,
              label: t('layers.obstacles'),
              elements: obstacles,
            },
          ]}
        />
        <LayerItem name={LayerNames.FLOWS} label={t('layers.flows')} level={0} elements={flows} />
        <LayerItem
          name={LayerNames.INTERIOR}
          label={t('layers.interior', 'Interior')}
          level={0}
          groups={[
            {
              name: LayerNames.WALLS,
              label: t('layers.walls', 'Walls'),
              elements: walls,
            },
            {
              name: LayerNames.OBSTACLES,
              label: t('layers.obstacles', 'Obstacles'),
              elements: obstacles,
            },
          ]}
        />
        <LayerItem
          name={LayerNames.ROADS}
          label={t('layers.roads', 'Roads')}
          level={0}
          groups={[
            {
              name: LayerNames.HIGHWAYS,
              label: t('layers.highways', 'Highways'),
              elements: highways,
            },
            {
              name: LayerNames.ANGLED_HIGHWAYS,
              label: t('layers.angled_highways', 'Angled Highways'),
              elements: angledHighways,
            },
          ]}
        />
        <LayerItem
          name={LayerNames.AREAS}
          label={t('layers.areas')}
          level={0}
          groups={[
            {
              name: LayerNames.INTAKE,
              label: t('layers.intake', 'Intake'),
              elements: areas.filter((area) => area.type === ShapeType.INTAKE),
            },
            {
              name: LayerNames.DELIVERY,
              label: t('layers.delivery', 'Delivery'),
              elements: areas.filter((area) => area.type === ShapeType.DELIVERY),
            },
            {
              name: LayerNames.STORAGE,
              label: t('layers.storage', 'Storage'),
              elements: areas.filter((area) => area.type === ShapeType.STORAGE),
            },
            {
              name: LayerNames.CHARGING,
              label: t('layers.charging', 'Charging'),
              elements: areas.filter((area) => area.type === ShapeType.CHARGING),
            },
            {
              name: LayerNames.PARKING,
              label: t('layers.parking', 'Parking'),
              elements: areas.filter((area) => area.type === ShapeType.PARKING),
            },
            {
              name: LayerNames.PROCESS,
              label: t('layers.process', 'Process'),
              elements: [
                ...areas.filter((area) => area.type === ShapeType.PROCESS_ONE_EP),
                ...processTwoEps,
              ],
            },
          ]}
        />
        <LayerItem name={LayerNames.REFERENCE} label={t('layers.reference')} level={0} />
        <LayerItem name={LayerNames.WIFI} label={t('layers.wifi')} level={0} />
      </Stack>
    </ThemeProvider>
  );
};

export const LayerProperties = memo(LayerPropertiesComponent);
