import { SvgIcon } from '@mui/material';

export default function MinusIcon(props) {
  return (
    <SvgIcon width="24" height="25" viewBox="0 0 24 25" style={{fill: 'currentColor', stroke: 'none'}} {...props}>
      <path d="M5.5 12.5v-1h13v1Z" fill="currentColor"/>
    </SvgIcon>
  );
}

