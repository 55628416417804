import { SvgIcon } from '@mui/material';
import { memo } from 'react';

function SvgComponent({ className = '', ...rest }) {
  return (
    <SvgIcon viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' className={className} {...rest}>
      <rect y='0.5' width='24' height='24' rx='4' fill='none' stroke='none' />
      <path d='M5.5 12.5V11.5H18.5V12.5H5.5Z' fill='currentColor' />
    </SvgIcon>
  );
}

const RemoveIcon = memo(SvgComponent);
export default RemoveIcon;
