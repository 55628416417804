import { createPointsElements } from '@/modules/canvas/mappers/common';
import { ShapeType } from '@modules/common/types/shapes';
import { WallShape } from '@recoil/shape';
import { Color, Element } from '@thive/canvas';
import { COLORS } from '../../commissioning/mappers/areas/colors';

export const createWallElement = (
  shape: WallShape,
) => {
  const elements: Element[] = [];
  elements.push(...createPointsElements(shape, Color.fromHex(COLORS[ShapeType.WALL])))
  return elements;
};
