import { useCallback } from 'react';
import { useRecoilCallback } from 'recoil';
import { useFloorPlanGroupApi, useProjectApi } from '@modules/api/hooks';
import floorplanInfoAtom from '@recoil/floorplan';
import { WorkspaceMode } from '@modules/common/types/general';
import { useWorkspaceMode } from '@modules/common/hooks';
import { useLoadReferenceImage } from '@modules/referenceImage';

import { useLoadProject } from './useLoadProject';
import { useLoadGroup } from './useLoadGroup';
import { useFloorPlanService } from './useFloorPlanService';
import { useFloorPlanState } from './useFloorPlanState';
import { useVehicleDependencyManager } from '@/modules/vehicles';

export const useLoadFloorPlan = () => {
  const { fetch: fetchProject } = useProjectApi();
  const { fetch: fetchGroup } = useFloorPlanGroupApi();
  const { fetchVersion, fetchLatestVersion } = useFloorPlanService();
  const { loadFloorPlan } = useFloorPlanState();
  const { setMode } = useWorkspaceMode();
  const { load: loadProject } = useLoadProject();
  const { load: loadGroup } = useLoadGroup();
  const { load: loadReferenceImage } = useLoadReferenceImage();
  const { ensureVehicleAssetAvailability, ensureVehicleDetailsAvailability } =
    useVehicleDependencyManager();
  /**
   * Loads the entire floor plan together with group and project information
   */
  const load = useCallback(
    async (projectId: string, floorPlanId: string, groupId: string) => {
      const [project, group] = await Promise.all([
        await fetchProject(projectId),
        await fetchGroup(projectId, groupId),
      ]);

      const floorPlan =
        group.floorPlanId === floorPlanId
          ? await fetchLatestVersion(projectId, groupId)
          : await fetchVersion(projectId, groupId, floorPlanId);

      loadProject(project);
      loadGroup(group);

      const { vehicleTypes, vehicleAssets } = floorPlan.additionalData;
      const assets = await ensureVehicleAssetAvailability(vehicleTypes, vehicleAssets);
      await ensureVehicleDetailsAvailability(vehicleTypes, assets);
      floorPlan.additionalData.vehicleAssets = assets;

      setMode(
        group.floorPlanId === floorPlanId
          ? WorkspaceMode.EDITABLE
          : WorkspaceMode.READONLY_SELECTION,
      );
      await loadReferenceImage(projectId, floorPlanId);
      await loadFloorPlan(floorPlan, floorPlanId);
    },
    [
      fetchGroup,
      loadReferenceImage,
      setMode,
      fetchProject,
      fetchLatestVersion,
      fetchVersion,
      loadFloorPlan,
      loadGroup,
      loadProject,
      ensureVehicleAssetAvailability,
      ensureVehicleDetailsAvailability,
    ],
  );

  const clean = useRecoilCallback(
    ({ set }) =>
      () => {
        set(floorplanInfoAtom, null);
      },
    [],
  );

  return {
    load,
    clean,
  };
};
