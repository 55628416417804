import { loginRequest } from '@/modules/authentication/authConfig';
import { SilentRequest } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import { useMemo } from 'react';
import { wrap } from '@modules/common/helpers/array';

import { acquireToken } from '../helpers/msal';

export type UseAxiosOptions = {
  baseURL: string;
  scopes: string | string[];
};

export const useAxios = ({ baseURL, scopes = [] }: UseAxiosOptions, config = {}) => {
  const { instance: msalInstance, accounts } = useMsal();

  return useMemo(() => {
    const instance = axios.create({
      baseURL,
      ...config,
    });

    instance.interceptors.request.use(async (config) => {
      const request: SilentRequest = {
        ...loginRequest,
        scopes: [...loginRequest.scopes, ...wrap(scopes)],
        account: accounts[0],
      };

      config.headers.Authorization = `Bearer ${await acquireToken(msalInstance, request)}`;

      return config;
    });

    return instance;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msalInstance, accounts, baseURL, scopes]);
};
