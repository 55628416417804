import { selectorFamily } from 'recoil';
import { DTShape, DTShapeProperties } from '@modules/common/types/shapes';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import shapeAtom from './atom';

const shapeProperty = selectorFamily<DTShapeProperties, string>({
  key: 'shapeProperty',
  get:
    (id) =>
    ({ get }) => {
      const shape = get(shapeAtom(id));
      if (!shape) return null;
      return shape.properties;
    },
  set:
    (id) =>
    ({ set }, newValue) => {
      set(shapeAtom(id), (current: DTShape) => ({
        ...current,
        properties: newValue,
      }));
    },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default shapeProperty;
