import { useCallback } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { allGroupsSelector, useShapeGroup } from '@/modules/shapeGroups';
import { DEBUG_ACTION_SIZE, DEBUG_DESCRIPTION_SIZE } from './DebugPanel';
import { TemplateType } from '@/modules/common/types/templating';

export const UngroupLassieTemplates = () => {
  const allGroups = useRecoilValue(allGroupsSelector);
  const { deleteGroups } = useShapeGroup();

  const ungroupLassieTemplates = useCallback(() => {
    deleteGroups(
      allGroups.filter((item) => item.type === TemplateType.LASSIE).map((item) => item.id),
    );
  }, [deleteGroups, allGroups]);

  return (
    <>
      <Grid item xs={DEBUG_DESCRIPTION_SIZE}>
        <Typography>Detach lassie templates</Typography>
      </Grid>
      <Grid item xs={DEBUG_ACTION_SIZE}>
        <Button onClick={ungroupLassieTemplates}>Click</Button>
      </Grid>
    </>
  );
};
