import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { DEVTOOLS_OPTIONS } from '@/modules/debug/constants/zustand';

type DebugStore = {
  open: boolean;
  autodeskDebug: boolean;
  groupMemberSelectionForceEnabled: boolean;
};

type DebugActions = {
  setOpen(open: boolean): void;
  toggleAutodeskDebug(): void;
  toggleGroupMemberSelectionEnabled(): void;
};

const INITIAL_STATE: DebugStore = {
  open: false,
  autodeskDebug: false,
  groupMemberSelectionForceEnabled: false,
};

export const useDebugStore = create<DebugStore & DebugActions>()(
  devtools(
    (set, get) => ({
      ...INITIAL_STATE,

      setOpen(open: boolean) {
        set(
          {
            open,
          },
          undefined,
          {
            type: 'setOpen',
          },
        );
      },

      toggleAutodeskDebug() {
        set(
          {
            autodeskDebug: !get().autodeskDebug,
          },
          undefined,
          {
            type: 'toggleAutodeskDebug',
          },
        );
      },

      toggleGroupMemberSelectionEnabled() {
        set(
          {
            groupMemberSelectionForceEnabled: !get().groupMemberSelectionForceEnabled,
          },
          undefined,
          {
            type: 'toggleGroupMemberSelectionEnabled',
          },
        );
      },
    }),
    { store: 'debugStore', ...DEVTOOLS_OPTIONS },
  ),
);
