import { useTranslation } from 'react-i18next';

import { Simulation } from '../helpers/types';
import { DividedRow } from './DividedRow';
import { VehicleCount } from './VehicleCount';

type Props = {
  simulation: Simulation;
};

export const VersionVehicleRow = ({ simulation }: Props) => {
  const { t } = useTranslation('interface');

  return (
    <DividedRow
      left={t('simulation.run_card.version', { version: simulation.details.floorPlanVersion })}
      right={<VehicleCount simulation={simulation} />}
    />
  );
};
