import { Accordion } from '@/modules/common/components/Accordion';
import { Warning } from '../../../../assets/icons';
import ValidationItem from './ValidationItem';

function ValidationGroup({ label, objects }) {
  return (
    <Accordion
      title={`${label} (${objects.length})`}
      titleSx={{ fontWeight: 500, lineHeight: '14px', fontSize: '12px' }}
      sx={{ backgroundColor: 'warning.light', pl: 2, pr: 1 }}
      beforeIcon={<Warning style={{ mx: 2 }} />}
      className='validationMsg'
    >
      {objects.map((value) => (
        <ValidationItem key={value.name} label={value.name} id={value.id} />
      ))}
    </Accordion>
  );
}

export default ValidationGroup;
