import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import floorplanAtom from '@recoil/floorplan';

/**
 * Are there any changes in the latest version
 */
export const hasUnsavedChangesSelector = selector<boolean>({
  key: 'floorplan/hasUnsavedChanges',
  get: ({ get }) => get(floorplanAtom).hasUnsavedChanges,
  set: ({ set }, hasUnsavedChanges: boolean) =>
    set(floorplanAtom, (state) => ({ ...state, hasUnsavedChanges })),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
