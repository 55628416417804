import { ToolButton } from '@/components/Toolbar3/ToolButton';
import { Paper } from '@mui/material';
import { Stack } from '@mui/system';
import { Trans } from 'react-i18next';

import { UndoRedo } from './UndoRedo';

export const Toolset = () => (
  <>
    <Paper
      elevation={1}
      sx={{
        borderRadius: '4px',
      }}
    >
      <Stack>
        <ToolButton
          type='cursor'
          tooltipLabel={
            <Trans
              i18nKey='toolbar.select_button.tooltip'
              ns='interface'
              components={{
                s: <span />,
              }}
            >
              Select <span>V</span>
            </Trans>
          }
          icon='cursor'
        />
      </Stack>
    </Paper>

    <UndoRedo disabled={false} />
  </>
);
