import { DefaultValue, selector } from 'recoil';
import { allVehiclesState } from './allVehiclesState';
import { UnifiedVehicleDetails } from '../types';
import { vehicleDetailsState } from './vehicleDetailsState';

export const knownVehicleDetailsSelector = selector<UnifiedVehicleDetails[] | null>({
  key: 'vehicles/details/known',
  get: ({ get }) => {
    const ids = get(allVehiclesState).map((v) => v.id);

    return ids.map((id) => get(vehicleDetailsState(id))).filter((item) => item);
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      console.error('Unable to set value, value is not an instance of UnifiedVehicleDetails type');
    } else {
      value.forEach((vehicle) => {
        set(vehicleDetailsState(vehicle.id), vehicle);
      });
    }
  },
});
