import { PanelDisplayMode, panelModeState } from '@components/PropertiesPanel/store/panel';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Stack } from '@mui/system';
import { CONTEXT, contextState } from '@recoil/input';
import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTheme } from '@mui/material/styles';
import { PropertiesPanelContent } from './PropertiesPanelContent';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';

export const RIGHT_PANEL_WIDTH = 320;

export function PropertiesPanel() {
  const setContext = useSetRecoilState(contextState);
  const panelMode = useRecoilValue(panelModeState);
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (panelMode === PanelDisplayMode.NONE) {
      setContext(CONTEXT.WORKSPACE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelMode]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      {smallScreen ? (
        <Stack direction='row' sx={{ zIndex: '100' }} onClick={handleDrawerToggle}>
          <SwipeableDrawer
            variant='temporary'
            open={mobileOpen}
            onClose={handleDrawerToggle}
            onOpen={handleDrawerToggle}
            swipeAreaWidth={100}
            disableSwipeToOpen={false}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            anchor='right'
            sx={{
              width: RIGHT_PANEL_WIDTH,
              '& .MuiDrawer-paper': {
                width: RIGHT_PANEL_WIDTH,
                mt: '48px',
                height: 'calc(100% - 48px)',
                overflow: 'visible',
              },
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                borderTopLeftRadius: 4,
                borderBottomLeftRadius: 4,
                visibility: 'visible',
                right: 0,
                left: -25,
                backgroundColor: 'shades.light',
                height: 100,
                display: 'flex',
                paddingLeft: '12px',
                width: 26,
                borderWidth: '1px 0 1px 1px',
                borderStyle: 'solid',
                borderColor: 'neutral.grey',
                boxShadow: '0px 2px 8px 3px #0000000A',
                zIndex: 2000,
              }}
            >
              <IconButton aria-label='open drawer' edge='start'>
                <ArrowBackIosIcon />
              </IconButton>
            </Box>
            <PropertiesPanelContent />
          </SwipeableDrawer>
        </Stack>
      ) : (
        <Drawer
          variant='permanent'
          anchor='right'
          open
          sx={{
            width: RIGHT_PANEL_WIDTH,
            '& .MuiDrawer-paper': {
              width: RIGHT_PANEL_WIDTH,
              mt: '48px',
              height: 'calc(100% - 48px)',
              overflow: 'hidden',
            },
          }}
        >
          <PropertiesPanelContent />
        </Drawer>
      )}
    </>
  );
}
