import { SvgIcon } from '@mui/material';

export default function AreaAlignMidIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M6 12H2M14 12H11M22 12H19M19 8V16C19 16.5523 18.5523 17 18 17H15C14.4477 17 14 16.5523 14 16V8C14 7.44772 14.4477 7 15 7H18C18.5523 7 19 7.44772 19 8ZM11 5V19C11 19.5523 10.5523 20 10 20H7C6.44772 20 6 19.5523 6 19V5C6 4.44772 6.44772 4 7 4H10C10.5523 4 11 4.44772 11 5Z" 
        fill='transparent' 
        stroke='currentColor'
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
