import { Dispatch, SetStateAction } from 'react';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment, Stack, Typography } from '@mui/material';

import { theme } from '@common/components/theme';
import { TextInput } from '@/modules/common/components/inputs';
import { capitalizeFirstLetter } from '@/helpers/string';
import { CONTEXT, contextState } from '@/store/recoil/input';
import CloseIcon from '@assets/icons/CloseIcon';
import { FilterMenu } from './FilterMenu';

type FilterMenuOptions = {
  label: string;
  value: string;
}[];

type Props = {
  searchQuery: string;
  setSearchQuery: Dispatch<SetStateAction<string>>;
  searchHitsAmount: number;
  vendorOptions: FilterMenuOptions;
  selectedVendors: string[];
  setSelectedVendors: Dispatch<SetStateAction<string[]>>;
  applicationOptions: FilterMenuOptions;
  selectedApplications: string[];
  setSelectedApplications: Dispatch<SetStateAction<string[]>>;
  modelOptions: FilterMenuOptions;
  selectedModels: string[];
  setSelectedModels: Dispatch<SetStateAction<string[]>>;
};

export const MetaBar = ({
  searchQuery,
  setSearchQuery,
  searchHitsAmount,
  vendorOptions,
  selectedVendors,
  setSelectedVendors,
  applicationOptions,
  selectedApplications,
  setSelectedApplications,
  modelOptions,
  selectedModels,
  setSelectedModels,
}: Props) => {
  const { t } = useTranslation(['interface']);
  const setContext = useSetRecoilState(contextState);

  return (
    <Stack
      direction='row'
      padding='32px 40px 8px 40px'
      sx={{
        [theme.breakpoints.down('md')]: {
          gap: '8px',
          flexDirection: 'column-reverse',
        },

        [theme.breakpoints.up('md')]: {
          gap: '24px',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
      }}
    >
      <Stack paddingY='5px' gap='5px 24px' direction='row' flexWrap='wrap'>
        <FilterMenu
          btnText={`${t('setup.floorPlan.vehicle_types_select_dialog.filters.vendor.btn_label')}${
            selectedVendors.length > 0 ? ` (${selectedVendors.length})` : ''
          }`}
          options={vendorOptions}
          selectedValues={selectedVendors}
          setSelectedValues={setSelectedVendors}
        />
        {applicationOptions.length === 0 ? null : (
          <FilterMenu
            btnText={getBtnText(
              t('setup.floorPlan.vehicle_types_select_dialog.filters.application.btn_label'),
              selectedApplications,
              t('setup.floorPlan.vehicle_types_select_dialog.filters.application.options', {
                returnObjects: true,
              }),
            )}
            options={applicationOptions}
            selectedValues={selectedApplications}
            setSelectedValues={setSelectedApplications}
          />
        )}
        <FilterMenu
          btnText={`
            ${t('setup.floorPlan.vehicle_types_select_dialog.filters.model.btn_label')}
             ${selectedModels.length > 0 ? ` (${selectedModels.length})` : ''}
          `}
          options={modelOptions}
          selectedValues={selectedModels}
          setSelectedValues={setSelectedModels}
        />
      </Stack>
      <Stack
        gap='36px'
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            alignItems: 'flex-start',
            gap: '16px',
          },

          [theme.breakpoints.up('sm')]: {
            gap: '36px',
          },

          [theme.breakpoints.up('md')]: {
            justifyContent: 'space-between',
            alignSelf: 'flex-start',
          },
        }}
      >
        <Typography
          fontSize='12px'
          fontWeight='500'
          sx={{ textWrap: 'nowrap' }}
          textTransform='uppercase'
        >
          {t('setup.floorPlan.vehicle_types_select_dialog.results_amount', {
            amount: searchHitsAmount,
          })}
        </Typography>
        <TextInput
          placeholder={t('setup.floorPlan.vehicle_types_select_dialog.search', 'Search')}
          value={searchQuery}
          onChange={setSearchQuery}
          onBlur={() => setContext(CONTEXT.WORKSPACE)}
          onFocus={() => setContext(CONTEXT.PROPERTYPANEL)}
          sx={{
            '& .MuiInputBase-root': {
              paddingRight: '2px',
            },
            '& .MuiInputBase-root .MuiInputAdornment-root.MuiInputAdornment-positionEnd': {
              visibility: 'hidden',
            },
            '& .MuiInputBase-root.Mui-focused .MuiInputAdornment-root.MuiInputAdornment-positionEnd':
              {
                visibility: 'visible',
              },
            '& .MuiInputBase-root:hover .MuiInputAdornment-root.MuiInputAdornment-positionEnd': {
              visibility: 'visible',
            },
          }}
          inputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon sx={{ mb: 2 }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  onClick={() => setSearchQuery('')}
                  sx={{ padding: '4px', zIndex: searchQuery.length > 0 ? 'initial' : '-1' }}
                >
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </Stack>
  );
};

const getBtnText = (
  defaultText: string,
  selectedValues: string[],
  availableTranslations?: object,
) => {
  if (selectedValues.length === 0) return defaultText;

  const firstSelectedValue = selectedValues[0];
  const translation = (availableTranslations && availableTranslations[firstSelectedValue]) || null;

  if (selectedValues.length === 1) return translation || capitalizeFirstLetter(firstSelectedValue);

  const countContent = `(+${selectedValues.length - 1})`;
  const valueContent = translation || capitalizeFirstLetter(firstSelectedValue);

  return `${valueContent} ${countContent}`;
};
