import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import { SimulationPanel } from '../../helpers/types';
import { currentSimulationIdState } from '../draft';
import { currentGroupIdSelector } from '../group';

export const panelSelector = selector({
  key: 'simulations/module/panelSelector',
  get: ({ get }) => {
    if (get(currentSimulationIdState)) return SimulationPanel.EDIT;
    if (get(currentGroupIdSelector)) return SimulationPanel.GROUP;
    return SimulationPanel.LIST;
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
