import { DTShape } from '@modules/common/types/shapes';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { selector } from 'recoil';

import { ProcessTwoEPShape } from '@/modules/processTwoEndPoint';
import { allProcessTwoEndPointSelector } from '@/modules/processTwoEndPoint/store';
import { allAngledHighwaysSelector } from '@modules/angledHighways';
import { AngledHighwayShape } from '@modules/angledHighways/types';
import {
  isAngledHighwayShape,
  isHighwayShape,
  isObstacleShape,
  isPositionShape,
  isProcessAreaTwoEp,
  isWallShape,
} from '@modules/common/types/guards';
import { AreaShape, HighwayShape, ObstacleShape, PositionShape, WallShape } from '../shape';
import { allAreasSelector } from './area';
import { allHighwaysSelector } from './highway';
import { allObstaclesSelector } from './obstacle';
import { allPositionsState } from './positions';
import { allWallsSelector } from './wall';

const allShapesSelector = selector<DTShape[]>({
  key: 'allShapesSelector',
  get: ({ get }) => {
    const output: DTShape[] = [];
    get(allAreasSelector).forEach((x) => output.push(x));
    get(allHighwaysSelector).forEach((x) => output.push(x));
    get(allAngledHighwaysSelector).forEach((x) => output.push(x));
    get(allProcessTwoEndPointSelector).forEach((x) => output.push(x));
    get(allWallsSelector).forEach((x) => output.push(x));
    get(allObstaclesSelector).forEach((x) => output.push(x));
    get(allPositionsState).forEach((x) => output.push(x));
    return output;
  },
  set: ({ set }, newShapes: DTShape[]) => {
    const areas: AreaShape[] = [];
    const highways: HighwayShape[] = [];
    const angledHighways: AngledHighwayShape[] = [];
    const processTwoEpAreas: ProcessTwoEPShape[] = [];
    const walls: WallShape[] = [];
    const obstacles: ObstacleShape[] = [];
    const positions: PositionShape[] = [];
    newShapes.forEach((x) => {
      if (isWallShape(x)) walls.push(x);
      else if (isObstacleShape(x)) obstacles.push(x);
      else if (isPositionShape(x)) positions.push(x);
      else if (isHighwayShape(x)) highways.push(x);
      else if (isAngledHighwayShape(x)) angledHighways.push(x);
      else if (isProcessAreaTwoEp(x)) processTwoEpAreas.push(x);
      else areas.push(x);
    });
    set(allAreasSelector, areas);
    set(allHighwaysSelector, highways);
    set(allAngledHighwaysSelector, angledHighways);
    set(allProcessTwoEndPointSelector, processTwoEpAreas);
    set(allWallsSelector, walls);
    set(allPositionsState, positions);
    set(allObstaclesSelector, obstacles);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default allShapesSelector;
