import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import shapeAtom from '../../shape/atom';
import { highwayIdsAtom } from './atom';
import { HighwayShape } from '../../shape';
import { setAtomFamilyWithIdsAtom } from '../../common/helper';

export const allHighwaysSelector = selector({
  key: 'allHighwaysSelector',
  get: ({ get }) => get(highwayIdsAtom).map((id) => get(shapeAtom(id))) as HighwayShape[],
  set: (getSetResetRecoilState, newHighways: HighwayShape[]) => {
    setAtomFamilyWithIdsAtom(getSetResetRecoilState, shapeAtom, highwayIdsAtom, newHighways);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
