export const parseNumberData = (dataType: 'integer' | 'decimal', value: number) => {
  if (Number.isNaN(value)) {
    return 0;
  }

  if (dataType === 'integer') {
    return Math.floor(value);
  }

  return value;
};
