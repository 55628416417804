import { atom, atomFamily } from 'recoil';

import { Simulation } from '../../helpers/types';

/**
 * Id of the currently loaded simulation group
 */
export const simulationGroupIdAtom = atom<string>({
  key: 'simulations/group/idAtom',
  default: null,
});

/**
 * The ids of simulations that belong to the current simulation group
 */
export const simulationsIdsAtom = atom<string[]>({
  key: 'simulations/group/simulations/idsAtom',
  default: [],
});

/**
 * The simulation that belongs to the current simulation group
 */
export const simulationAtom = atomFamily<Simulation, string>({
  key: 'simulations/group/simulations/atom',
  default: null,
});
