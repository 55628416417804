import { Grid, Paper } from '@mui/material';
import { memo } from 'react';

import { UndoRedoSalesBtns } from '@/components/Toolbar3/UndoRedoSalesBtns';

const UndoRedoComponent = ({ disabled }) => (
  <Paper
    elevation={1}
    sx={{
      borderRadius: '4px',
    }}
  >
    <Grid item xs={2} style={{ textAlign: 'center' }}>
      <UndoRedoSalesBtns disabled={disabled} />
    </Grid>
  </Paper>
);

export const UndoRedo = memo(UndoRedoComponent);
