import { SvgIcon } from '@mui/material';

export default function HandoverIcon(props) {
  return (
    <SvgIcon width='24' height='24' {...props} style={{ stroke: 'none', fill: 'none' }}>
      <path
        d='M2.32812 19V18H6.67428V15.5H3.32812V14.5H6.67428V12H4.37812V11H6.67428V8.08843L4.91658 4.23458L5.83197 3.80766L7.7589 7.99998H19.5897L17.7166 4.04228L18.632 3.61536L20.6743 7.99998V19H2.32812ZM11.6743 12.5H15.6743C15.8159 12.5 15.9347 12.452 16.0305 12.356C16.1264 12.2601 16.1743 12.1412 16.1743 11.9993C16.1743 11.8574 16.1264 11.7388 16.0305 11.6433C15.9347 11.5477 15.8159 11.5 15.6743 11.5H11.6743C11.5326 11.5 11.4139 11.548 11.318 11.6439C11.2222 11.7399 11.1743 11.8588 11.1743 12.0007C11.1743 12.1425 11.2222 12.2612 11.318 12.3567C11.4139 12.4522 11.5326 12.5 11.6743 12.5ZM7.67428 18H19.6743V8.99998H7.67428V18Z'
        fill='currentColor'
      />
    </SvgIcon>
  );
}
