import { useRecoilCallback } from 'recoil';

import { getRelatedAndUnrelatedConnectionIds } from '../../common/helpers';
import { allDistantConnectionsSelector, removeDistantConnectionSelector } from '../store';

export const useRemoveDistantConnection = () => {
  const removeDistantConnections = useRecoilCallback(
    ({ set }) =>
      (ids: string[]) =>
        set(removeDistantConnectionSelector, ids),
    [],
  );

  const removeDistantConnectionsByShapes = useRecoilCallback(
    ({ snapshot }) =>
      async (shapeIds: string[]) => {
        const { relatedConnections } = getRelatedAndUnrelatedConnectionIds(
          await snapshot.getPromise(allDistantConnectionsSelector),
          shapeIds,
        );

        removeDistantConnections(relatedConnections.map((item) => item.id));
      },
    [removeDistantConnections],
  );

  return {
    removeDistantConnections,
    removeDistantConnectionsByShapes,
  };
};
