import { useCallback } from 'react';
import { SelectInput } from '@/modules/common/components/inputs';
import { enabledLoadCarrierTypesSelector } from '@/store/recoil/loadCarrierTypes';
import { formControlClasses, inputClasses, selectClasses } from '@mui/material';
import { Stack } from '@mui/system';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useFloorPlanState } from '../../../modules/floorplan/hooks/useFloorPlanState';
import { areaLoadCarriersIds } from '../store/area';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';

const MINIMUM_AMOUNT_SELECTED_OPTIONS = 1;
const MAXIMUM_SUPPORTED_LOAD_TYPES_AMOUNT = 1;

const containerSx = {
  // Wrap value-string to new line, don't truncate.
  [`& .${selectClasses.multiple}`]: {
    whiteSpace: 'normal!important',
  },

  // because value-string is wrapped, correct the height and padding of the options container.
  [`& .${formControlClasses.root} .${inputClasses.root}`]: {
    height: 'auto',
    padding: '4px 12px!important',
  },
};

export const LoadCarrierSelect = () => {
  const enabledLoadCarriers = useRecoilValue(enabledLoadCarrierTypesSelector);
  const [selectedCarriersIds, setSelectedCarriersIds] = useRecoilState(areaLoadCarriersIds);
  const { saveFloorPlan } = useFloorPlanState();
  const mode = useRecoilValue(modeSelector);

  const handleChange = useCallback(
    (newSelected: string[]) => {
      if (
        newSelected.length < MINIMUM_AMOUNT_SELECTED_OPTIONS ||
        // NOTE: multi loadTypes: add a maximum amount (of 1) until multi loadTypes are supported in back-end
        newSelected.length > MAXIMUM_SUPPORTED_LOAD_TYPES_AMOUNT
      ) {
        return;
      }

      setSelectedCarriersIds(newSelected);
      saveFloorPlan();
    },
    [saveFloorPlan, setSelectedCarriersIds],
  );

  return (
    <Stack sx={containerSx}>
      <SelectInput
        disabled={mode !== WorkspaceMode.EDITABLE}
        multiple
        options={enabledLoadCarriers?.map((item) => ({
          label: item.name,
          value: item.id,
        }))}
        onChange={handleChange}
        value={selectedCarriersIds}
      />
    </Stack>
  );
};
