import { Box, Stack, Switch } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { Warning } from '@/assets/icons';
import { allVehiclesState } from '@/modules/vehicles';
import { Accordion } from '@common/components/Accordion';
import { PropertiesLabel } from '@common/components/PropertiesLabel';
import { theme } from '@common/components/theme';
import { useSimulationDraftCallbacks } from '../../../hooks';
import {
  isVehicleRangeSelector,
  vehicleTypesCountSelector,
  vehicleTypesSelector,
} from '../../../store/draft';
import { VehicleType } from './VehicleType';

export const VehicleTypes = () => {
  const { t } = useTranslation();
  const { toggleIsVehicleRange } = useSimulationDraftCallbacks();
  const allVehicles = useRecoilValue(allVehiclesState);
  const vehicleCount = useRecoilValue(vehicleTypesCountSelector);
  const vehicleTypes = useRecoilValue(vehicleTypesSelector);
  const isRange = useRecoilValue(isVehicleRangeSelector);

  const hasMissingVehicles = useMemo(
    () =>
      vehicleTypes.some((item1) => allVehicles.find((item2) => item1.name === item2.name) == null),
    [vehicleTypes, allVehicles],
  );

  const onAccordionToggle = useCallback((expanded) => !expanded, []);

  if (!vehicleTypes?.length) {
    return null;
  }

  return (
    <Accordion
      title={t('interface:simulation.simulation_edit.vehicle_types.header', {
        number: vehicleCount,
      })}
      afterIcon={
        hasMissingVehicles && (
          <Warning
            style={{
              marginRight: '12px',
              fill: theme.palette.error.main,
            }}
          />
        )
      }
      onChange={onAccordionToggle}
      sx={{ px: 2 }}
    >
      <Stack height='100%' gap={1}>
        {vehicleTypes.length === 1 && (
          <PropertiesLabel
            i18nkey='interface:simulation.simulation_edit.vehicle_types.use_range'
            labelRatio={50}
          >
            <Box sx={{ textAlign: 'right' }}>
              <Switch value={isRange} onChange={toggleIsVehicleRange} />
            </Box>
          </PropertiesLabel>
        )}
        <Stack
          sx={{
            flex: '1 1 auto',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
          gap={1}
        >
          {vehicleTypes.map(({ name, range }) => (
            <VehicleType key={name} name={name} range={range} />
          ))}
        </Stack>
      </Stack>
    </Accordion>
  );
};
