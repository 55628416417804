import { BoundingBox, Position, StageProps } from '@helpers/types';
import { Vector2 } from 'three';

export const calculateTranslateVector = (from: Position, to: Position): Vector2 =>
  new Vector2(from.x - to.x, from.y - to.y);

export const checkForBoundingBoxCollision = (b1: BoundingBox, b2: BoundingBox): boolean =>
  b1.x < b2.x + b2.width &&
  b1.x + b1.width > b2.x &&
  b1.y < b2.y + b2.height &&
  b1.height + b1.y > b2.y;

export const equalVector = (p1: Position, p2: Position): boolean => p1.x === p2.x && p1.y === p2.y;
export const calculateDistance = (p1: Position, p2: Position): number => {
  const a = p1.x - p2.x;
  const b = p1.y - p2.y;
  return Math.sqrt(a * a + b * b);
};

type ScaleOptions = {
  origin?: Position;
};

export const scale = (
  boundingBox: BoundingBox,
  scale: number,
  { origin }: ScaleOptions = {},
): BoundingBox => {
  const xOrigin = origin?.x ?? 0;
  const yOrigin = origin?.y ?? 0;

  return {
    x: xOrigin + (boundingBox.x - xOrigin) * scale,
    y: yOrigin + (boundingBox.y - yOrigin) * scale,
    width: boundingBox.width * scale,
    height: boundingBox.height * scale,
  };
};

export const merge = (target, source) => {
  // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
  Object.keys(source).forEach((key) => {
    if (source[key] instanceof Object) Object.assign(source[key], merge(target[key], source[key]));
  });

  // Join `target` and modified `source`
  Object.assign(target || {}, source);
  return target;
};
export const isUuid = (input) => {
  const isString = typeof input === 'string' || input instanceof String;

  return (
    isString &&
    input.match(
      // Using this regex b/c the uuid library has a bug inside so it doesn't generate proper UUID v4s
      // A proper uuid check (8-4-4-12) is /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi,
      /^[0-9a-f]{1,8}\b-[0-9a-f]{1,4}\b-[0-9a-f]{1,4}\b-[0-9a-f]{1,4}\b-[0-9a-f]{1,12}$/g,
    )
  );
};

export const convertToRelativePosition = (position: Position, stage: StageProps): Position => ({
  x: (position.x - stage.x) / stage.scale,
  y: (position.y - stage.y) / stage.scale,
});

export const convertToRelativeBoundingBox = (
  boundingBox: BoundingBox,
  stage: StageProps,
): BoundingBox => ({
  ...convertToRelativePosition({ x: boundingBox.x, y: boundingBox.y }, stage),
  width: boundingBox.width / stage.scale,
  height: boundingBox.height / stage.scale,
});

export const clamp = (value: number, min: number, max: number): number =>
  Math.min(Math.max(value, min), max);

export const pointsToUniquePoints = (points: Vector2[]): Vector2[] => {
  const pointsAmount = points.length;
  const uniquePoints: Vector2[] = [];

  let unique: boolean;
  points.forEach((item, index) => {
    unique = true;

    for (let i = index + 1; i < pointsAmount; i++) {
      if (item.equals(points[i])) {
        unique = false;
        break;
      }
    }

    if (unique) uniquePoints.push(item);
  });

  return uniquePoints;
};
