import { SelectInput } from '@common/components/inputs';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { AreaParkingDirection } from '@modules/common/types/shapes';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useFloorPlanState } from '../../../modules/floorplan/hooks/useFloorPlanState';
import { areasParkingDirection } from '../store/area';

const ParkingDirectionComponent = () => {
  const { saveFloorPlan } = useFloorPlanState();
  const { t } = useTranslation('interface');
  const parkingDirection = useRecoilValue(areasParkingDirection);
  const mode = useRecoilValue(modeSelector);

  const optionLabels = t('properties.area.parking_direction_options', {
    returnObjects: true,
  });

  const onParkingDirectionChange = useRecoilCallback(
    ({ set }) =>
      async (value: AreaParkingDirection) => {
        set(areasParkingDirection, value);
        saveFloorPlan();
      },
    [saveFloorPlan],
  );

  return (
    <SelectInput
      options={[
        {
          label: optionLabels[AreaParkingDirection.BACKWARD],
          value: AreaParkingDirection.BACKWARD,
        },
        {
          label: optionLabels[AreaParkingDirection.FORWARD],
          value: AreaParkingDirection.FORWARD,
        },
      ]}
      onChange={onParkingDirectionChange}
      value={
        [AreaParkingDirection.BACKWARD, AreaParkingDirection.FORWARD].indexOf(
          parkingDirection as AreaParkingDirection,
        ) > -1
          ? parkingDirection
          : AreaParkingDirection.BACKWARD
      }
      disabled={mode !== WorkspaceMode.EDITABLE}
    />
  );
};

const ParkingDirection = memo(ParkingDirectionComponent);
export default ParkingDirection;
