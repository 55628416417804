import { SVGProps, memo } from 'react';

function SvgComponent({ className = '', ...rest }: SVGProps<SVGSVGElement>) {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ fill: 'currentColor' }}
      className={className}
      {...rest}>
      <path d="M2.61537 16.0002C2.16794 16.0002 1.78685 15.8428 1.4721 15.5281C1.15737 15.2134 1 14.8323 1 14.3848V2.00021H0V1.00021H4V0.230957H10V1.00021H14V2.00021H13V14.3848C13 14.8451 12.8458 15.2294 12.5375 15.5377C12.2292 15.846 11.8449 16.0002 11.3846 16.0002H2.61537ZM12 2.00021H2V14.3848C2 14.5643 2.05769 14.7118 2.17308 14.8271C2.28846 14.9425 2.43589 15.0002 2.61537 15.0002H11.3846C11.5385 15.0002 11.6795 14.9361 11.8077 14.8079C11.9359 14.6797 12 14.5387 12 14.3848V2.00021ZM4.80768 13.0002H5.8077V4.00021H4.80768V13.0002ZM8.1923 13.0002H9.19232V4.00021H8.1923V13.0002Z" />
    </svg>
  );
}

const DeleteIcon = memo(SvgComponent);
export default DeleteIcon;
