import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { DEVTOOLS_OPTIONS } from '@/modules/debug/constants/zustand';

export type HistoryState = {
  hasUndo: boolean;
  hasRedo: boolean;
};

type HistoryActions = {
  reset(): void;
};

const INITIAL_STATE: HistoryState = {
  hasUndo: false,
  hasRedo: false,
};

export const useHistoryStore = create<HistoryState & HistoryActions>()(
  devtools(
    (set) => ({
      ...INITIAL_STATE,

      reset: () => {
        set(INITIAL_STATE, undefined, {
          type: 'reset',
        });
      },
    }),
    { store: 'commissioning/historyStore', ...DEVTOOLS_OPTIONS },
  ),
);
