import { BoundingBox } from '@/helpers/types';
import { boundingBoxToFpsBoundingBox } from '@/modules/floorplanService/helpers/mapping/utils';
import { FLOOR_TAG, SOURCE_NAME } from '../consts';
import { Type, Variant } from '../enum';
import { VisualizationObject } from '../types';

const FLOOR_HEIGHT = -50.0;

export function createFloor(box: BoundingBox): VisualizationObject {
  const fpBox = boundingBoxToFpsBoundingBox(box, box);
  const GRAY = 255;
  return {
    Name: 'Floor',
    Source: SOURCE_NAME,
    Type: Type.Primitive,
    Variant: Variant.Plane,
    Position: { X: fpBox.minX, Y: fpBox.minY, Z: FLOOR_HEIGHT },
    Scale: { X: box.width, Y: box.height, Z: 1 },
    Color: { Alpha: 255, R: GRAY, G: GRAY, B: GRAY },
    Tags: [FLOOR_TAG],
  };
}
