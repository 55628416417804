import { useCallback } from 'react';

import { CommissioningLayout, Gate, LocalizationMapType } from '../helpers/types';
import { useGateStore, useLayoutStore } from '../store';
import { useApi } from './useApi';
import { useSnackbarStore } from '@/modules/snackbar/store/useSnackbarStore';
import { useTranslation } from 'react-i18next';

export const useSaveData = () => {
  const { create, deleteLocalization, update, uploadLocalizationMapFile } = useApi();
  const { showSnackbar } = useSnackbarStore();
  const { t } = useTranslation();

  /* 
    TODO: Fix whatever is happening here...  
  */

  const getPayload = useCallback((): CommissioningLayout => {
    const { delta, deltaAngle } = useLayoutStore.getState();
    const { adjustments } = useGateStore.getState();

    const gates: Gate[] = Array.from(adjustments.entries()).map(([id, position]) => ({
      id,
      delta: position,
      deltaAngle: 0,
    }));

    return {
      layoutDelta: {
        delta,
        deltaAngle,
      },
      gates,
      splines: [],
      localizationMaps: [],
    };
  }, []);

  const save = useCallback(async () => {
    const payload = getPayload();
    try {
      await update(payload);
    } catch (error) {
      if (error.response?.status === 404) {
        await create(payload);
        return;
      }

      showSnackbar(t('errors:save.failed', 'Failed to save'));
    }
  }, [create, getPayload, update, showSnackbar, t]);

  const saveLocalizationMap = useCallback(
    async (type: LocalizationMapType, file: File) => {
      try {
        await uploadLocalizationMapFile(type, file);
      } catch (error) {
        if (error.response?.status === 404) {
          await create(getPayload());
          await uploadLocalizationMapFile(type, file);
          return;
        }

        showSnackbar(t('errors:save.failed', 'Failed to save'));
      }
    },
    [create, getPayload, showSnackbar, t, uploadLocalizationMapFile],
  );

  const deleteLocalizationMap = useCallback(
    async (type: LocalizationMapType) => {
      try {
        await deleteLocalization(type);
      } catch (error) {
        showSnackbar(t('errors:delete.failed', 'Failed to delete'));
      }
    },
    [deleteLocalization, showSnackbar, t],
  );
  return { deleteLocalizationMap, save, saveLocalizationMap };
};
