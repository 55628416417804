import { Divider, MenuItem, Stack, ThemeProvider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Replay, NotesOutlined, EditOutlined } from '@mui/icons-material';
import { DeleteIcon, ForkliftIcon } from '@/assets/icons';
import { moreMenuTheme } from '@common/components/theme';

import { Simulation, SimulationStatus } from '../../../helpers/types';
import { Menu } from '../../Menu';
import { DashboardMenuItem } from '../../DashboardMenuItem';

type Props = {
  simulation: Simulation;
  onEditClick: () => void;
  onRerunClick: () => void;
  onDetailsClick: () => void;
  onDeleteClick: () => void;
};

export const TopButtons = ({
  simulation,
  onEditClick,
  onRerunClick,
  onDetailsClick,
  onDeleteClick,
}: Props) => {
  const { t } = useTranslation('interface');

  return (
    <Stack
      sx={{
        alignItems: 'center',
        flexDirection: 'row',
        height: '100%',
        justifyContent: 'flex-end',
        gap: 1,
      }}
    >
      <ThemeProvider theme={moreMenuTheme}>
        <Menu>
          <MenuItem disabled>
            <ForkliftIcon />
            {t('simulation.run_card.commissioning.label')}
          </MenuItem>
          <Divider sx={{ my: 0.5 }} />
          <DashboardMenuItem simulation={simulation} />
          <Divider />
          <MenuItem
            onClick={onDetailsClick}
            disabled={simulation.status === SimulationStatus.DELETING}
          >
            <NotesOutlined />
            {t('simulation.run_card.details_button.label')}
          </MenuItem>
          <MenuItem
            onClick={onRerunClick}
            disabled={
              simulation.status !== SimulationStatus.COMPLETED &&
              simulation.status !== SimulationStatus.PARTIALLY_COMPLETED
            }
          >
            <Replay />
            {t('simulation.run_card.rerun_button.label')}
          </MenuItem>
          <MenuItem
            onClick={onEditClick}
            disabled={simulation.status === SimulationStatus.DELETING}
          >
            <EditOutlined />
            {t('simulation.run_card.edit_button.label')}
          </MenuItem>
          <MenuItem
            onClick={onDeleteClick}
            disabled={simulation.status == SimulationStatus.DELETING}
          >
            <DeleteIcon />
            {t('simulation.run_card.delete_button.tooltip')}
          </MenuItem>
        </Menu>
      </ThemeProvider>
    </Stack>
  );
};
