import { Connection } from '@modules/common/types/connections';
import {
  Connection as ConnectionPersisted,
  Connection as FloorPlanConnection,
} from '@modules/common/types/floorPlan';
import { DTShape } from '@modules/common/types/shapes';
import { sortConnections } from '../../common/helpers';
import { findConnectionPositionsBetween2Shapes } from './connections';

/**
 * Prepares connections state to be loaded into the app.
 */
export const prepareToLoad = (
  connections: ConnectionPersisted[],
  shapes: DTShape[],
): Connection[] => {
  const shapeMap = new Map<string, DTShape>();
  shapes.forEach((item) => shapeMap.set(item.id, item));

  const newConnections: Connection[] = [];

  // eslint-disable-next-line no-restricted-syntax
  for (let connection of connections) {
    const {from, to} = connection
    const fromSplit = from.split('.')
    const fromShapeDT = shapeMap.get(fromSplit[0]);
    const fromSegment = fromSplit.length > 1 ? fromSplit[1] : null
    const toSplit = to.split('.')
    const toShapeDT = shapeMap.get(toSplit[0]);
    const toSegment = toSplit.length > 1 ? toSplit[1] : null    
    
    if (!fromShapeDT?.properties || !toShapeDT?.properties) continue;

    const positions = findConnectionPositionsBetween2Shapes(fromShapeDT, toShapeDT, +fromSegment, +toSegment);
    if (!positions) continue;
      positions.forEach((position) => {
        newConnections.push({
          ...connection,
          ...position,
        });
      });
  }

  return newConnections;
};

/**
 * Prepares connections state to be saved
 */
export const prepareToSave = (connections: Connection[]): FloorPlanConnection[] => {
  const connectionsToSave = connections.map((item) => ({
    from: item.from,
    to: item.to,
    inChecked: item.inChecked,
    outChecked: item.outChecked,
    usePivots: item.usePivots,
    id: item.id,
  }));

  return sortConnections(connectionsToSave);
};
