import navAtom from '@recoil/nav';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilCallback, useSetRecoilState } from 'recoil';

import floorplanInfoAtom from '@/store/recoil/floorplan/atom';
import { Module } from '../types/navigation';
import { useConfig } from './useConfig';

export const useNavigation = () => {
  const navigate = useNavigate();
  const setNav = useSetRecoilState(navAtom);
  const { portal } = useConfig();
  const { projectId, floorPlanId: initialFloorPlanId, groupId } = useParams();

  const goToBuilder = useCallback(
    (groupId: string, floorPlanId: string) => navigate(`builder/${groupId}/version/${floorPlanId}`),
    [navigate],
  );

  const updateUrl = useCallback((projectId: string, groupId: string, floorPlanId: string) => {
    replaceURLPath(`/${projectId}/builder/${groupId}/version/${floorPlanId}`);
  }, []);

  const goToProject = useCallback((id: string) => navigate(`${id}`), [navigate]);

  const goToPortal = useCallback(
    (redirect = false) => {
      if (portal.url) {
        let { url } = portal;

        if (projectId) {
          url += `/projects/${projectId}/`;
        }

        if (redirect) {
          window.location.href = url;
        }
        window.window.open(url);
      } else {
        throw new Error('No portal url was found in configuration');
      }
    },
    [projectId, portal],
  );

  const goToModule = useRecoilCallback(
    ({ snapshot }) =>
      async (module: Module) => {
        const currentNav = await snapshot.getPromise(navAtom);

        if (module !== currentNav && currentNav === Module.VISUALISATION) {
          const info = await snapshot.getPromise(floorplanInfoAtom);
          navigate(`/${projectId}/builder/${groupId}/version/${initialFloorPlanId}`);
          if (info?.id) {
            replaceURLPath(`/${projectId}/builder/${groupId}/version/${info.id}`);
          }
        }

        setNav(module);
      },
    [groupId, initialFloorPlanId, navigate, projectId, setNav],
  );

  const goToTracker = useRecoilCallback(
    ({ snapshot }) =>
      async (id: string, floorPlanVersionId?: string) => {
        const floorplanInfo = await snapshot.getPromise(floorplanInfoAtom);

        let base = `/${projectId}/builder/${groupId}/version/${initialFloorPlanId}/tracker/${id}`;
        if (floorPlanVersionId) {
          base += `/floorPlan/${floorPlanVersionId}`;
        }

        navigate(base);

        if (floorplanInfo?.id && floorplanInfo.id !== initialFloorPlanId) {
          let updatedUrl = `/${projectId}/builder/${groupId}/version/${floorplanInfo.id}/tracker/${id}`;

          if (floorPlanVersionId) {
            updatedUrl += `/floorPlan/${floorPlanVersionId}`;
          }

          replaceURLPath(updatedUrl);
        }
      },
    [navigate, groupId, projectId, initialFloorPlanId],
  );

  return {
    goToBuilder,
    goToModule,
    goToPortal,
    goToProject,
    goToTracker,
    updateUrl,
  };
};

/**
 * Update the url path natively. Avoids a react route component unmount/remount when route params change.
 * Use carefully.
 */
const replaceURLPath = (path: string) => {
  window.history.replaceState(null, '', path);
};
