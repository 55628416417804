import { useRecoilCallback } from 'recoil';
import { allShapesSelector } from '../../../../store/recoil/shapes';
import { enabledVehiclesSelector } from '@/modules/vehicles';
import { layoutFlowsSelector } from '../../../flows/store/layout';
import { allGroupsSelector } from '../../../shapeGroups';
import { getMissingRequiredElements } from '../helpers';
import { prevalidationState } from '../store';

export const useRequiredElementsValidator = () => {
  const validateMissingRequiredElements = useRecoilCallback(
    ({ snapshot, set }) =>
      async (syncResultsWithStore = true) => {
        const allShapes = await snapshot.getPromise(allShapesSelector);
        const vehicles = await snapshot.getPromise(enabledVehiclesSelector);
        const flows = await snapshot.getPromise(layoutFlowsSelector);
        const groups = await snapshot.getPromise(allGroupsSelector);
        const missingRequiredElements = getMissingRequiredElements(
          allShapes,
          vehicles,
          flows,
          groups,
        );

        if (syncResultsWithStore) {
          set(prevalidationState, (current) => {
            if (
              current.missingRequiredElements.length === 0 &&
              missingRequiredElements.length === 0
            ) {
              return current;
            }

            return {
              ...current,
              missingRequiredElements,
            };
          });
        }

        return missingRequiredElements;
      },
    [],
  );

  return {
    validateMissingRequiredElements,
  };
};
