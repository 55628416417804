import { TextInput } from '@/modules/common/components/inputs';
import { PropertiesLabel } from '@modules/common/components/PropertiesLabel';
import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { Vector2 } from 'three';
import { UnitShapeId } from '../../helpers/types';
import { use3TE25LayoutStore } from '../../store';

type Props = {
  unitId: UnitShapeId;
};

export default function UnitDetail({ unitId }: Props) {
  const [points, setPoints] = useState<Vector2[]>([]);

  useEffect(() => {
    const { layoutPoints } = use3TE25LayoutStore.getState();
    if (!layoutPoints.has(parseInt(unitId))) return;

    const points = layoutPoints.get(parseInt(unitId));
    setPoints(points.positions);
  }, [unitId]);

  return (
    <Stack direction='column' spacing={1}>
      <PropertiesLabel i18nkey='interface:3te25.unit.unit_id'>
        <TextInput value={unitId.toString() ?? 'unknown'} disabled />
      </PropertiesLabel>
      <PropertiesLabel i18nkey='interface:3te25.unit.point_count'>
        <TextInput value={points.length.toString()} disabled />
      </PropertiesLabel>
    </Stack>
  );
}
