import {
  FleetTrackerEvent,
  FleetTrackerLoadingEvent,
  useFleetTracker,
} from '@thive/fleet-tracker.web-gl.react';
import { useCallback, useEffect, useState } from 'react';
import { EventNames, useEventBus } from '../../../hooks';
import { useRecoilState } from 'recoil';
import { fleetTrackerSessionKey } from '@/modules/visualization/store/fleetTrackerSessionKey';

export const useVisualization = () => {
  // hook
  const { ref, sendMessage, requestUnload } = useFleetTracker();
  const { emit, on, off } = useEventBus();

  // visualization state
  const [sessionKey, setSessionKey] = useRecoilState(fleetTrackerSessionKey);
  const [assetLoaded, setAssetLoaded] = useState(false);

  const onSendMessage = ({ target, action, payload }: any) => {
    sendMessage(target, action, payload);
  };

  const onReceiveMessage = useCallback(
    (data: any) => {
      if (data.action === 'onWsConnect') {
        let key: string = data.payload.SessionKey;
        setSessionKey(key);
      } else if (data.action === 'onAssetCatalogLoadFinish') {
        setAssetLoaded(true);
      }
    },
    [setAssetLoaded, setSessionKey],
  );

  useEffect(() => {
    on(EventNames.VisualizationWebGLSend, onSendMessage);
    on(EventNames.VisualizationWebGLReceive, onReceiveMessage);

    return () => {
      off(EventNames.VisualizationWebGLSend, onSendMessage);
      off(EventNames.VisualizationWebGLReceive, onReceiveMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoading = useCallback(
    (e: FleetTrackerLoadingEvent) => {
      if (e.isLoaded) {
        emit(EventNames.VisualizationLoadingFinished, {});
      }
    },
    [emit],
  );

  const onEvent = useCallback(
    (e: FleetTrackerEvent) => {
      emit(EventNames.VisualizationWebGLReceive, {
        action: e.action,
        payload: e.payload,
      });
    },
    [emit],
  );

  return {
    ref,
    requestUnload,
    onLoading,
    onEvent,
    sessionKey,
    assetLoaded,
  };
};
