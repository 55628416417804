import { NumberInput } from '@/modules/common/components/inputs';
import { useSimulationDraftCallbacks } from '@/modules/simulation/hooks';
import { obstructionTimeOutInSecondsSelector } from '../../store/draft/obstructionTimeOutInSecondsSelector';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { PropertiesLabel } from '@/modules/common/components/PropertiesLabel';
import { useUpdateUserPreferences } from '@/modules/userPreferences/hooks';
import { UserPreferenceName } from '@/modules/userPreferences';

export const ObstructionTimeInSeconds = () => {
  const { t } = useTranslation('interface');
  const obstructionTimeOutInSeconds = useRecoilValue(obstructionTimeOutInSecondsSelector);
  const { updateDraftSimulation } = useSimulationDraftCallbacks();
  const { updateUserPreference } = useUpdateUserPreferences();

  const onChange = useCallback(
    (e) => {
      updateUserPreference(UserPreferenceName.SIMULATION_OBSTRUCTION_TIMEOUT, e);
      updateDraftSimulation((state) => ({
        ...state,
        obstructionTimeOutInSeconds: e,
      }));
    },
    [updateDraftSimulation, updateUserPreference],
  );

  return (
    <PropertiesLabel
      labelRatio={50}
      i18nkey='interface:simulation.experimental_settings.obstructionTimeOut.label'
    >
      <NumberInput
        value={obstructionTimeOutInSeconds}
        onChange={onChange}
        unit={t('simulation.experimental_settings.obstructionTimeOut.unit')}
        errorType='floating'
        dataType='integer'
        sx={{ float: 'right', height: '32px' }}
        minValue={0}
      />
    </PropertiesLabel>
  );
};
