import { selector } from 'recoil';
import { DTShape } from '@modules/common/types/shapes';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import shapeAtom from '../../shape/atom';
import selectedShapesIdsState from './selectedShapesIdsState';

const selectedShapesState = selector<DTShape[]>({
  key: 'selectedShapes',
  get: ({ get }) => get(selectedShapesIdsState).map((id) => get(shapeAtom(id))),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default selectedShapesState;
