import { findParameterValue } from '@modules/common/helpers/shapes';
import { shapeParameter } from '@recoil/shape';
import { selectedShapesState } from '@recoil/shapes/selected';
import { selector } from 'recoil';
import { isAngledHighwayShape, isHighwayShape } from '../../../modules/common/types/guards';
import { LaneDirection } from '../../../modules/common/types/shapes';
import { getHighwayDirection } from '../../../modules/workspace/helpers/shape';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../../store/recoil/common';
import { unitConverterSelector } from '../../../store/recoil/workspace';
import { ANGLED_HIGHWAY_MIN_WIDTH } from '@/modules/common/constants/shapes';

export const highwayDirectionSelector = selector({
  key: 'propertiesPanelHighwaysDirection',
  get: ({ get }) => {
    const shape = get(selectedShapesState)[0];

    if (!isHighwayShape(shape)) return;

    return getHighwayDirection(shape.properties.width, shape.properties.height);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const highwayLaneDirection = selector({
  key: 'propertiesPanelHighwaysLaneDirection',
  get: ({ get }) => findParameterValue(get(selectedShapesState), 'laneDirection'),
  set: ({ get, set }, value: LaneDirection) => {
    get(selectedShapesState).forEach((shape) => {
      if (!isHighwayShape(shape) && !isAngledHighwayShape(shape)) return;

      set(shapeParameter(shape.id), {
        ...shape.parameters,
        laneDirection: value,
      });
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const highwayWidthSelector = selector<number>({
  key: 'propertiesPanelHighwaysWidth',
  get: ({ get }) => findParameterValue(get(selectedShapesState), 'width'),
  set: ({ get, set }, value) => {
    const valueInMillimeter = get(unitConverterSelector(value as number));
    get(selectedShapesState).forEach((shape) => {
      if (!isAngledHighwayShape(shape)) return;
      if (
        !valueInMillimeter ||
        Number.isNaN(valueInMillimeter) ||
        valueInMillimeter < ANGLED_HIGHWAY_MIN_WIDTH
      ) {
        const newParams = {
          ...shape.parameters,
          width: ANGLED_HIGHWAY_MIN_WIDTH,
        };
        set(shapeParameter(shape.id), newParams);
      } else {
        const newParams = {
          ...shape.parameters,
          width: valueInMillimeter,
        };
        set(shapeParameter(shape.id), newParams);
      }
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const roadMinGapSelector = selector<number>({
  key: 'propertiesPanelRoadGap',
  get: ({ get }) => findParameterValue(get(selectedShapesState), 'gap'),
  set: ({ get, set }, value) => {
    const valueInMillimeter = get(unitConverterSelector(value as number));
    get(selectedShapesState).forEach((shape) => {
      if (!(isHighwayShape(shape) || isAngledHighwayShape(shape))) return;

      if (!valueInMillimeter || Number.isNaN(valueInMillimeter) || valueInMillimeter < 0) {
        set(shapeParameter(shape.id), {
          ...shape.parameters,
          gap: 0,
        });
      } else {
        set(shapeParameter(shape.id), {
          ...shape.parameters,
          gap: valueInMillimeter,
        });
      }
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const roadMinMarginSelector = selector({
  key: 'propertiesPanelRoadMargin',
  get: ({ get }) => findParameterValue(get(selectedShapesState), 'margin'),
  set: ({ get, set }, value) => {
    const valueInMillimeter = get(unitConverterSelector(value as number));
    get(selectedShapesState).forEach((shape) => {
      if (!(isHighwayShape(shape) || isAngledHighwayShape(shape))) return;

      if (!valueInMillimeter || Number.isNaN(valueInMillimeter) || valueInMillimeter < 0) {
        set(shapeParameter(shape.id), {
          ...shape.parameters,
          margin: 0,
        });
      } else {
        set(shapeParameter(shape.id), {
          ...shape.parameters,
          margin: valueInMillimeter,
        });
      }
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const roadMarginToCrossingSelector = selector({
  key: 'propertiesPanelRoadMarginToCrossing',
  get: ({ get }) => findParameterValue(get(selectedShapesState), 'routingPointMarginToCrossing'),
  set: ({ get, set }, value) => {
    const valueInMillimeter = get(unitConverterSelector(value as number));
    get(selectedShapesState).forEach((shape) => {
      if (!(isHighwayShape(shape) || isAngledHighwayShape(shape))) return;

      if (!valueInMillimeter || Number.isNaN(valueInMillimeter) || valueInMillimeter < 0) {
        set(shapeParameter(shape.id), {
          ...shape.parameters,
          routingPointMarginToCrossing: 0,
        });
      } else {
        set(shapeParameter(shape.id), {
          ...shape.parameters,
          routingPointMarginToCrossing: valueInMillimeter,
        });
      }
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const roadMinRoutingPointGroupGapSelector = selector({
  key: 'propertiesPanelRoadMinRoutingPointGroupGap',
  get: ({ get }) => findParameterValue(get(selectedShapesState), 'routingPointGroupMinGap'),
  set: ({ get, set }, value) => {
    const valueInMillimeter = get(unitConverterSelector(value as number));
    get(selectedShapesState).forEach((shape) => {
      if (!(isHighwayShape(shape) || isAngledHighwayShape(shape))) return;

      if (!valueInMillimeter || Number.isNaN(valueInMillimeter) || valueInMillimeter < 0) {
        set(shapeParameter(shape.id), {
          ...shape.parameters,
          routingPointGroupMinGap: 0,
        });
      } else {
        set(shapeParameter(shape.id), {
          ...shape.parameters,
          routingPointGroupMinGap: valueInMillimeter,
        });
      }
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const roadVehicleLimitSelector = selector<number>({
  key: 'propertiesPanelRoadLimit',
  get: ({ get }) => findParameterValue(get(selectedShapesState), 'vehicleLimit'),
  set: ({ get, set }, value) => {

    get(selectedShapesState).forEach((shape) => {
      if (!(isHighwayShape(shape) || isAngledHighwayShape(shape))) return;
      if (Number.isNaN(value) || typeof value !== 'number' || value < 0) {
        set(shapeParameter(shape.id), {
          ...shape.parameters,
          vehicleLimit: -1, // set to all vehicles
        });
      } else {
        set(shapeParameter(shape.id), {
          ...shape.parameters,
          vehicleLimit: value,
        });
      }
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
