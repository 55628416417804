import { SvgIcon } from '@mui/material';
import { memo } from 'react';

function SvgComponent({ className = '', ...rest }) {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      {...rest}
    >
      {/* TODO: abstract fill */}
      <rect x='8' y='5' width='1' height='14' fill='#222222' />
      <rect x='15' y='5' width='1' height='14' fill='#222222' />
      <circle cx='8.5' cy='14.5' r='2.1' fill='white' stroke='#222222' strokeWidth='0.8' />
      {/* TODO: alter svg so circles are transparent and show underlying hover color */}
      <circle cx='15.5' cy='9.5' r='2.1' fill='white' stroke='#222222' strokeWidth='0.8' />
    </SvgIcon>
  );
}

export const PreferenceIcon = memo(SvgComponent);
