import { useRecoilValue } from 'recoil';
import { Box } from '@mui/material';
import { useCallback, useState } from 'react';

import { simulationSelector } from '../../../store/simulations';
import { CardHeader } from './CardHeader';
import { CardContent } from './CardContent';
import { useSimulationCallbacks } from '@/modules/simulation/hooks';

type Props = {
  id: string;
};

const containerSx = {
  border: '2px solid transparent',
  borderBottom: '1px solid transparent',
  borderBottomColor: 'neutral.lighter',
  padding: '12px 0',
};

export function ListPanelCard({ id }: Props) {
  const [isHover, setIsHover] = useState<boolean>(false);
  const simulation = useRecoilValue(simulationSelector(id));
  const { updateSimulation } = useSimulationCallbacks();

  const handleSelect = useCallback(
    () => {
      updateSimulation(simulation.id, (state) => {
        state.isSelected = !state.isSelected;
      })
    },

    [updateSimulation, simulation],
  );

  const isSelectedSx = simulation.isSelected
    ? {
      borderWidth: '2px',
      borderColor: 'primary.main',
      paddingBottom: '12px',
    }
    : {};

  return (
    <Box
      sx={{ ...containerSx, ...isSelectedSx }}
      onMouseEnter={() => setIsHover(true)}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      component='article'
      className={simulation.isSelected ? 'selectedBox' : 'simulationBox'}
    >
      <CardHeader simulation={simulation} isHover={isHover} onClick={handleSelect}/>
      <CardContent simulation={simulation} isHover={isHover} onClick={handleSelect}/>
    </Box>
  );
}
