import { ListItemText, SxProps } from '@mui/material';
import { memo } from 'react';

type LabelProps = {
  labelText: string;
  sx?: SxProps;
};

const LabelComponent = ({ labelText, sx }: LabelProps) => (
  <ListItemText
    primary={labelText}
    primaryTypographyProps={{
      style: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    }}
    sx={{ ...sx, fontSize: 24 }}
  />
);

export const Label = memo(LabelComponent);
