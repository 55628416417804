import { useSimulationDraftCallbacks } from '@/modules/simulation/hooks';
import { Accordion } from '@common/components/Accordion';
import { PropertiesLabel } from '@common/components/PropertiesLabel';
import { NumberInput } from '@common/components/inputs';
import { durationToHours } from '@modules/common/helpers/date';
import { Stack } from '@mui/material';
import { CONTEXT, contextState } from '@recoil/input';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { durationSelector } from '../../../store/draft';
import { SIMULATION_DURATION_MAX } from '@/modules/simulation/helpers/constants';

export function TimeForm() {
  const { t } = useTranslation('interface');
  const setContext = useSetRecoilState(contextState);
  const duration = useRecoilValue(durationSelector);
  const { updateDuration } = useSimulationDraftCallbacks();

  const onFocus = useCallback(() => setContext(CONTEXT.PROPERTYPANEL), [setContext]);
  const onBlur = useCallback(() => setContext(CONTEXT.WORKSPACE), [setContext]);

  const onDurationChange = useCallback(
    (hour: number) => {
      try {
        if (hour <= SIMULATION_DURATION_MAX) {
          updateDuration(hour);
        } else {
          updateDuration(SIMULATION_DURATION_MAX);
          throw new Error(
            t('interface:simulation.simulation_edit.time_form.max_duration', {
              hours: SIMULATION_DURATION_MAX,
            }),
          );
        }
      } catch (e) {
        return { error: e.message };
      }
    },
    [updateDuration, t],
  );

  return (
    <Accordion
      title={t('simulation.simulation_edit.time_form.header')}
      defaultExpanded
      sx={{ px: 2 }}
    >
      <Stack gap={1}>
        <PropertiesLabel i18nkey='interface:simulation.simulation_edit.time_form.duration_input.label'>
          <NumberInput
            ariaLabel={t('simulation.simulation_edit.time_form.duration_input.label')}
            name='duration'
            value={duration ? durationToHours(duration) : 0}
            onChange={onDurationChange}
            unit={t('simulation.simulation_edit.time_form.duration_input.unit')}
            sx={{ width: '100%' }}
            errorType='floating'
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </PropertiesLabel>
      </Stack>
    </Accordion>
  );
}
