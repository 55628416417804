import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { usesControlPoints } from '@/modules/common/helpers/shapes';
import { keyboardState, KEYCODE } from '@/store/recoil/input';
import { toolButtonState } from '@recoil/tool';
import { useStopPointsDrawing } from './useStopPointsDrawing';

/**
 * cleans up points drawing when tool switches to anyting else than points drawing
 * @returns
 */
export const useStopDrawingOnToolSwitch = () => {
  const { stopPointsDrawing } = useStopPointsDrawing();
  const toolState = useRecoilValue(toolButtonState);
  const pressedKey = useRecoilValue(keyboardState);

  useEffect(() => {
    if (!usesControlPoints(toolState) || (pressedKey === KEYCODE.ESCAPE)) {
      stopPointsDrawing();
    } 
  }, [pressedKey, toolState, stopPointsDrawing]);

  return null;
};
