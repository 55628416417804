import { ForwardedRef, forwardRef } from 'react';
import { Box, SxProps } from '@mui/material';

import { OnViewerLoadedEventHandler, ViewerRef } from '../../AutodeskViewer/types';
import { useViewerInit } from '../../AutodeskViewer/hooks';
import { useViewerWorkspaceSync } from './useViewerWorkspaceSync';

type Props = {
  scale: number;
  urn: string;
  x: number;
  offsetX: number;
  y: number;
  offsetY: number;
  sx: SxProps;
  width: number;
  height: number;

  gui?: boolean;
  onViewerLoaded?: OnViewerLoadedEventHandler;
};

export const AutodeskViewer = forwardRef(
  (
    { urn, x, offsetX, y, offsetY, scale, width, height, sx, gui = false, onViewerLoaded }: Props,
    ref: ForwardedRef<ViewerRef>,
  ) => {
    const { viewer, viewerEl, isLoaded, boundingBox } = useViewerInit(
      urn,
      x,
      y,
      offsetX,
      offsetY,
      scale,
      width,
      height,
      ref,
      gui,
      onViewerLoaded,
    );
    useViewerWorkspaceSync(viewer, x, y, offsetX, offsetY, scale, boundingBox, isLoaded);

    return (
      <Box
        sx={{
          position: 'absolute',
          overflow: 'hidden',
          left: '0',
          right: '0',
          top: '0',
          bottom: '0',
          zIndex: '2',
          ...sx,
        }}
      >
        {!isLoaded && (
          <Box
            position='absolute'
            left='0'
            right='0'
            top='0'
            bottom='0'
            sx={{ backgroundColor: 'others.lighter' }}
            zIndex='2'
          />
        )}
        <Box sx={{ height: '100%', width: '100%', overflow: 'hidden' }} ref={viewerEl} />
      </Box>
    );
  },
);
