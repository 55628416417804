import { useRecoilCallback } from 'recoil';
import { isIntersect } from '../../common/helpers/array';
import { useGetShapeIdsRelatedToFlowId } from './useGetShapeIdsRelatedToFlowId';
import { layoutAllFlowIds } from '../store/layout';

export const useGetRelatedFlowIdsOfShapeIds = () => {
  const getRelatedShapeIds = useGetShapeIdsRelatedToFlowId();

  const getRelatedFlowIds = useRecoilCallback(
    ({ snapshot }) =>
      async (shapeIds: string[]): Promise<string[]> => {
        const allFlowIds = await snapshot.getPromise(layoutAllFlowIds);
        const relatedFlowIds = [];
        const promises = allFlowIds.map((id) => getRelatedShapeIds(id));
        const shapeIdsInFlow = await Promise.all(promises);
        allFlowIds.forEach((flowId, index) => {
          if (isIntersect(shapeIds, shapeIdsInFlow[index])) {
            relatedFlowIds.push(flowId);
          }
        });
        return relatedFlowIds;
      },
  );

  return getRelatedFlowIds;
};
