import { SvgIcon } from '@mui/material';

const VisualizationIcon = ({ className = '', size = 24, color = 'currentColor', ...rest }) => (
  <SvgIcon
    viewBox='0 0 24 24'
    sx={{ width: size, height: size, fontSize: `${size}px`, fill: color }}
    className={className}
    {...rest}
  >
    <path
      d='M11.1923 19.1462L6.3077 16.3096C6.05192 16.1626 5.85336 15.9653 5.71203 15.718C5.57068 15.4706 5.5 15.1999 5.5 14.9058V9.23845C5.5 8.94432 5.57068 8.67358 5.71203 8.42623C5.85336 8.17889 6.05192 7.98169 6.3077 7.83463L11.1923 4.99808C11.4491 4.85321 11.7193 4.78077 12.0031 4.78077C12.287 4.78077 12.5551 4.85321 12.8077 4.99808L17.6923 7.83463C17.9481 7.98169 18.1466 8.17889 18.288 8.42623C18.4293 8.67358 18.5 8.94432 18.5 9.23845V14.9058C18.5 15.1999 18.4293 15.4706 18.288 15.718C18.1466 15.9653 17.9481 16.1626 17.6923 16.3096L12.8077 19.1462C12.5509 19.291 12.2807 19.3635 11.9969 19.3635C11.713 19.3635 11.4449 19.291 11.1923 19.1462ZM11.5 18.1731V12.3423L6.5 9.50385V14.9115C6.5 15.0141 6.52564 15.1103 6.57692 15.2C6.62821 15.2898 6.70513 15.3667 6.8077 15.4308L11.5 18.1731ZM12.5 18.1731L17.1923 15.4308C17.2949 15.3667 17.3718 15.2898 17.4231 15.2C17.4744 15.1103 17.5 15.0141 17.5 14.9115V9.50385L12.5 12.3423V18.1731ZM2 6V4.39053C2 3.71991 2.23183 3.15383 2.6955 2.6923C3.15918 2.23077 3.72222 2 4.38462 2H6V3H4.38462C3.99231 3 3.66346 3.13269 3.39808 3.39808C3.13269 3.66346 3 3.99231 3 4.38463V6H2ZM4.39053 22C3.71991 22 3.15383 21.7682 2.6923 21.3045C2.23077 20.8408 2 20.2778 2 19.6154V18H3V19.6154C3 20.0077 3.13269 20.3365 3.39808 20.6019C3.66346 20.8673 3.99231 21 4.38462 21H6V22H4.39053ZM18 21.8077V20.8077H19.6154C20.0077 20.8077 20.3365 20.675 20.6019 20.4096C20.8673 20.1442 21 19.8154 21 19.4231V17.8077H22V19.4172C22 20.0878 21.7682 20.6539 21.3045 21.1154C20.8408 21.5769 20.2778 21.8077 19.6154 21.8077H18ZM21 6V4.38463C21 3.99231 20.8673 3.66346 20.6019 3.39808C20.3365 3.13269 20.0077 3 19.6154 3H18V2H19.6095C20.2801 2 20.8462 2.23183 21.3077 2.6955C21.7692 3.15918 22 3.72223 22 4.38463V6H21ZM12 11.4769L17 8.6L12.3077 5.89615C12.2051 5.83205 12.1026 5.8 12 5.8C11.8974 5.8 11.7949 5.83205 11.6923 5.89615L7 8.6L12 11.4769Z'
      fill={color}
    />
  </SvgIcon>
);

export default VisualizationIcon;
