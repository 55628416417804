import { ShapeType, AreaDistribution } from '@modules/common/types/shapes';
import { UserPreferenceName, getUserPreference } from '@modules/userPreferences';

export const distribution = (shapeType: ShapeType): AreaDistribution => {
  switch (shapeType) {
    case ShapeType.INTAKE:
    case ShapeType.INTAKE_POSITION:
      return getUserPreference(UserPreferenceName.INTAKE_DISTRIBUTION);
    case ShapeType.STORAGE:
    case ShapeType.STORAGE_POSITION:
      return getUserPreference(UserPreferenceName.STORAGE_DISTRIBUTION);
    case ShapeType.DELIVERY:
    case ShapeType.DELIVERY_POSITION:
      return getUserPreference(UserPreferenceName.DELIVERY_DISTRIBUTION);
    case ShapeType.PROCESS_ONE_EP:
    case ShapeType.PROCESS_ONE_EP_POSITION:
      return getUserPreference(UserPreferenceName.PROCESS_DISTRIBUTION);
    case ShapeType.CHARGING:
    case ShapeType.CHARGING_POSITION:
      return getUserPreference(UserPreferenceName.CHARGING_DISTRIBUTION);
    case ShapeType.PARKING:
    case ShapeType.PARKING_POSITION:
      return getUserPreference(UserPreferenceName.PARKING_DISTRIBUTION);
    default:
      return AreaDistribution.EXTRA_SPACE_OVER_MARGIN;
  }
};
