import { MathUtils, Vector3 as TVector3 } from 'three';

import { BoundingBox } from '@/helpers/types';
import { DEFAULT_RACK } from '@/modules/common/constants/storage';
import { numberOfColumns } from '@/modules/common/helpers/loadCarrier';
import {
  calcBayAmountCapacity,
  calcBeamLength,
  calcMaxRackRowLength,
  calcTotalRackWidth,
  getRackShelfHeights,
} from '@/modules/common/helpers/rack';
import { RACKING_BEAM_COLOR, RACKING_POST_COLOR } from '@/modules/visualization/consts';
import { Type, Variant } from '@/modules/visualization/enum';
import { RackingData, Vector3 } from '@/modules/visualization/types';
import { isVertical } from '@/modules/workspace/helpers/shape';
import { AreaAlignment, AreaDirection, AreaDistribution } from '@modules/common/types/shapes';

export function createRack(
  box: BoundingBox,
  position: Vector3,
  alignment: AreaAlignment,
  margin: number,
  areaDirection: AreaDirection,
  angle: number,
  framesDeep: number,
  rackLoadsPerShelf: number,
  beamHeight: number,
  loadCarriersBoundingBox: { width: number; length: number },
  levelHeight: number,
  rackLevels: number,
  clearanceInBetween: number,
  clearanceSide: number,
  postWidth: number,
  firstShelfHeight: number,
) {
  let type = Type.Racking;
  let variant = Variant.None;
  const isHorizontal = box.width > box.height;
  const availableRackRowLength = calcMaxRackRowLength(
    isVertical(areaDirection),
    box.width,
    box.height,
    margin,
  );
  const beamLength = calcBeamLength(
    loadCarriersBoundingBox.length,
    rackLoadsPerShelf,
    clearanceInBetween,
    clearanceSide,
  );
  const baysAmount = calcBayAmountCapacity(
    beamLength,
    availableRackRowLength,
    postWidth,
  );
  const totalRackingWidth = calcTotalRackWidth(baysAmount, beamLength, postWidth);
  const totalRackingDepth = calcTotalRackFramesDepth(
    loadCarriersBoundingBox.width,
    framesDeep,
    DEFAULT_RACK.POST_DEPTH + DEFAULT_RACK.OVERHANG_FRONT + DEFAULT_RACK.OVERHANG_BACK,
  );
  const levelHeights = getRackShelfHeights(firstShelfHeight, levelHeight, rackLevels, beamHeight);
  if (firstShelfHeight === 0) {
    levelHeights.shift();
  }

  const postPositions = rackColumnPositions(
    totalRackingWidth,
    isHorizontal,
    baysAmount,
    beamLength,
    postWidth,
  );

  const rackingData: RackingData = {
    LevelHeights: levelHeights,
    ColumnPositions: postPositions,
    Depth: totalRackingDepth,
    BeamColor: RACKING_BEAM_COLOR,
    PostColor: RACKING_POST_COLOR,
    PostSize: postWidth,
    // TODO: use user provided value. depends on fleet tracker API udpate and differentiation between height and depth
    BeamSize: beamHeight,
  };

  let rotation = { X: 0, Y: 0, Z: angle };
  rotation.Z -= isHorizontal ? 90 : 0;

  const offset = alignPosition(alignment, box, isHorizontal, totalRackingDepth, totalRackingWidth);
  offset.applyAxisAngle(new TVector3(0, 0, 1), MathUtils.degToRad(angle));
  position.X += offset.x;
  position.Y += offset.y;

  return {
    Type: type,
    Variant: variant,
    Position: position,
    Rotation: rotation,
    Data: rackingData,
  };
}

export function createElevationRack(
  box: BoundingBox,
  position: Vector3,
  alignment: AreaAlignment,
  distribution: AreaDistribution,
  direction: AreaDirection,
  gap: number,
  margin: number,
  angle: number,
  loadLength: number,
  rackDepth: number,
  rackLoadsPerShelf: number,
  levelHeights: number[],
) {
  let data: any = null;
  let type = Type.Racking;
  let variant = Variant.None;
  const verticalDirection = isVertical(direction)

  const numberOfLoads = numberOfColumns(
    verticalDirection ? box.width : box.height,
    gap,
    margin,
    loadLength,
  );
  let rackWidth = (loadLength + gap) * numberOfLoads;

  if (distribution === AreaDistribution.EXTRA_SPACE_OVER_GAP) {
    rackWidth = verticalDirection ? box.width : box.height;
  }

  const rackingData: RackingData = {
    LevelHeights: levelHeights,
    ColumnPositions: elevationRackColumnPositions(
      rackLoadsPerShelf,
      rackWidth,
      numberOfLoads,
      verticalDirection,
    ),
    Depth: rackDepth,
    BeamColor: RACKING_BEAM_COLOR,
    PostColor: RACKING_POST_COLOR,
    PostSize: DEFAULT_RACK.POST_DEPTH,
    BeamSize: DEFAULT_RACK.BEAM_HEIGHT,
  };
  data = rackingData;

  let rotation = { X: 0, Y: 0, Z: angle };
  rotation.Z -= verticalDirection ? 90 : 0;

  const offset = alignPosition(alignment, box, verticalDirection, rackDepth, rackWidth);
  offset.applyAxisAngle(new TVector3(0, 0, 1), MathUtils.degToRad(angle));
  position.X += offset.x;
  position.Y += offset.y;

  return {
    Type: type,
    Variant: variant,
    Position: position,
    Rotation: rotation,
    Data: data,
  };
}

export function alignPosition(
  alignment: AreaAlignment,
  box: BoundingBox,
  isHorizontal: boolean,
  depth: number,
  rackWidth: number,
) {
  const startOffset = ((isHorizontal ? box.width : box.height) - rackWidth) / 2;

  if (isHorizontal) {
    if (alignment === AreaAlignment.TOP) {
      return new TVector3(startOffset, box.height);
    }
    if (alignment === AreaAlignment.CENTER) {
      return new TVector3(startOffset, box.height / 2 + depth / 2);
    }
    return new TVector3(startOffset, depth);
  }

  if (alignment === AreaAlignment.TOP) {
    return new TVector3(0, startOffset);
  }

  if (alignment === AreaAlignment.CENTER) {
    return new TVector3(box.width / 2 - depth / 2, startOffset);
  }
  return new TVector3(box.width - depth, startOffset);
}

function rackColumnPositions(
  rackWidth: number,
  isHorizontal: boolean,
  baysAmount: number,
  beamLength: number,
  postWidth: number,
): number[] {
  const columnPositionValues = [postWidth / 2];
  for (let i = 0; i < baysAmount; i++) {
    columnPositionValues.push((beamLength + postWidth) * (i + 1) + postWidth / 2);
  }

  if (!isHorizontal) {
    return columnPositionValues.map((element) => rackWidth - element).reverse();
  }

  return columnPositionValues;
}

function elevationRackColumnPositions(
  loadsPerShelf: number,
  rackWidth: number,
  numberOfLoads: number,
  isHorizontal: boolean,
): number[] {
  const bayWidth = (loadsPerShelf * rackWidth) / numberOfLoads;
  const columnPositionValues = [0];
  const bays = Math.floor(numberOfLoads / loadsPerShelf);
  for (let i = 0; i < bays; i++) {
    columnPositionValues.push(bayWidth * (i + 1));
  }
  columnPositionValues.push(rackWidth);

  if (!isHorizontal) {
    return columnPositionValues.map((element) => rackWidth - element).reverse();
  }

  return columnPositionValues;
}

/**
 * @description calculates the total depth of the racking, excluding any front overhang from the first row and back overhang from the last row
 */
const calcTotalRackFramesDepth = (
  frameDepth: number,
  framesDeepAmount: number,
  spaceBetweenFrames: number,
): number => {
  const totalDepth =
    frameDepth * framesDeepAmount + (framesDeepAmount - 1 || 0) * spaceBetweenFrames;

  return totalDepth;
};
