import { SxProps, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  children: ReactNode;
  sx?: SxProps;
};

export const Version = ({ children, sx }: Props) => {
  const { t } = useTranslation('interface');

  return (
    <Typography sx={{ fontSize: '10px', ...sx }}>
      {t('simulation.run_card.version', { version: children })}
    </Typography>
  );
};
