import { AngleIcon } from '@/assets/icons';
import { unitSelector, unitValueSelector } from '@/store/recoil/workspace';
import { NumberInput } from '@common/components/inputs';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { useCallback } from 'react';
import { Input } from './Input';

type Props = {
  x: number;
  y: number;
  width: number;
  height: number;
  angle?: number;
  disableWidth?: boolean;
  disableHeight?: boolean;
  disableAngle?: boolean;
  disabled?: boolean;
  onXChange?: (value: number) => void;
  onYChange?: (value: number) => void;
  onWidthChange?: (value: number) => void;
  onHeightChange?: (value: number) => void;
  onAngleChange?: (value: number) => void;
  onAngleBlur?: (value: number) => void;
  onBlur?: () => void;
  onFocus?: () => void;
};

const createTextSx = (disabled = false) => ({
  opacity: disabled ? 0.5 : 1,
});

export function Dimensions({
  x,
  y,
  width,
  disableWidth = false,
  height,
  disableHeight = false,
  angle,
  disableAngle = false,
  disabled = false,
  onXChange,
  onYChange,
  onWidthChange,
  onHeightChange,
  onAngleChange,
  onBlur,
  onAngleBlur,
  onFocus,
}: Props) {
  const unit = useRecoilValue(unitSelector);
  const { t } = useTranslation();

  const onAngleBlurInner = useCallback(
    (value: number) => {
      onAngleBlur?.(value);
      onBlur?.();
    },
    [onAngleBlur, onBlur],
  );

  return (
    <Grid
      container
      alignItems='center'
      justifyContent='space-between'
      rowGap={1}
      columns={36}
      sx={{ '&:hover': { cursor: 'default' } }}
    >
      <Grid item xs={3}>
        <Typography fontSize='10px' sx={createTextSx(disabled)}>
          {t('interface:properties.dimensions.x', 'X')}
        </Typography>
      </Grid>
      <Grid item xs={14}>
        <NumberInput
          value={useRecoilValue(unitValueSelector(x))}
          unit={t(`interface:settings.units.${unit}`, unit)}
          errorType='floating'
          disabled={disabled}
          onChange={onXChange}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      </Grid>
      <Grid item xs={2}>
        {/* Intentionally Empty */}
      </Grid>
      <Grid item xs={3} justifyContent='center'>
        <Typography fontSize='10px' sx={createTextSx(disabled)}>
          {t('interface:properties.dimensions.y', 'Y')}
        </Typography>
      </Grid>
      <Grid item xs={14}>
        <NumberInput
          value={useRecoilValue(unitValueSelector(y))}
          unit={t(`interface:settings.units.${unit}`, unit)}
          disabled={disabled}
          errorType='floating'
          onChange={onYChange}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      </Grid>
      <Grid item xs={3}>
        <Typography fontSize='10px' sx={createTextSx(disabled || disableWidth)}>
          {t('interface:properties.dimensions.width', 'W')}
        </Typography>
      </Grid>
      <Grid item xs={14}>
        <NumberInput
          value={useRecoilValue(unitValueSelector(width))}
          unit={t(`interface:settings.units.${unit}`, unit)}
          errorType='floating'
          disabled={disabled || disableWidth}
          onChange={onWidthChange}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      </Grid>
      <Grid item xs={2}>
        {/* Intentionally Empty */}
      </Grid>
      <Grid item xs={3}>
        <Typography fontSize='10px' sx={createTextSx(disabled || disableHeight)}>
          {t('interface:properties.dimensions.height', 'H')}
        </Typography>
      </Grid>
      <Grid item xs={14}>
        <NumberInput
          value={useRecoilValue(unitValueSelector(height))}
          unit={t(`interface:settings.units.${unit}`, unit)}
          errorType='floating'
          disabled={disabled || disableHeight}
          onChange={onHeightChange}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      </Grid>
      <Grid item xs={3} sx={createTextSx(disabled || disableAngle)} columnGap={2}>
        <AngleIcon style={{ height: '16px', width: '16px' }} />
      </Grid>
      <Grid item xs={14} sx={{ marginRight: 'auto' }} columnGap={1}>
        <Input
          value={angle}
          unit='°'
          disabled={disabled || disableAngle}
          errorType='floating'
          onChange={onAngleChange}
          onBlur={onAngleBlurInner}
          onFocus={onFocus}
        />
      </Grid>
    </Grid>
  );
}
