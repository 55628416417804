import { boundingBoxToLine } from '@/modules/common/helpers/boundingBox';
import {
  isAngledHighwayShape,
  isAreaShape,
  isHighwayShape,
  isPositionShape,
  isProcessAreaTwoEp,
  isWallShape,
} from '@modules/common/types/guards';
import { AreaParkingDirection, DTShape } from '@modules/common/types/shapes';
import { AddAreaMappingFromAreaToHighway } from './addAreaMappingFromAreaToHighway';
import { addAreaMappingFromHighwayToOtherShapes } from './addAreaMappingFromHighwayToOtherShapes';
import { decodeShapeId, encodeIdWithVehicleId, getSegmentIndex } from './idEncoder';
import { AreaMapperTypeEnum, ConnectionDirection, Mapping } from './types';
import {
  allowUTurnBetween2Roads,
  areHighwaysAfterEachOther,
  areHighwaysNextToEachOther,
  getAngledHighwaySegment,
  isGetawayConnection,
} from './utils';
import { StorageType } from '@/modules/common/types/storage';
import { AngledHighwayShape } from '@/modules/angledHighways/types';
import { HighwayShape } from '@/store/recoil/shape';

export const ConnectionMapping = (
  vehicleSpec,
  highwayId,
  highwayConnections,
  highwayLine,
  laneDirection,
  connections,
  road: HighwayShape | AngledHighwayShape,
  shapes: DTShape[],
) => {
  const mappings = []

  highwayConnections.forEach((connection) => {
    const otherShape = shapes.filter((shape) => decodeShapeId(shape.id) === decodeShapeId(connection.targetName));
    const targetId = encodeIdWithVehicleId(connection.targetName, vehicleSpec.databaseId)

    if (vehicleSpec.areas.some((area) => area.name === targetId) && !isWallShape(otherShape[0])) {
      if (isProcessAreaTwoEp(otherShape[0])) {
        const parkingDirection = connection.targetName.split('.')[1] === '0' 
          ? otherShape[0].parameters.deliveryParameters.parkingDirection 
          : otherShape[0].parameters.intakeParameters.parkingDirection 

        if (
          connection.direction === ConnectionDirection.BothWays ||
          connection.direction === ConnectionDirection.FromTarget
        ) {
          mappings.push({
            discType: AreaMapperTypeEnum.MAP_CHECK_POINTS_TO_END_POINTS,
            areaReferencesToMap: [{ name: targetId }],
            epBackwardParking: parkingDirection === AreaParkingDirection.BACKWARD,
            usePivots: connection.usePivots,
          });
        }
        if (
          connection.direction === ConnectionDirection.BothWays ||
          connection.direction === ConnectionDirection.FromSource
        ) {
          AddAreaMappingFromAreaToHighway(
            vehicleSpec.gateMappingSettings.areaMappings,
            targetId,
            highwayId,
            false,
            connection.usePivots,
          );
        }
      }

      const otherHighwayLine = isAngledHighwayShape(otherShape[0])
        ? getAngledHighwaySegment(otherShape[0], getSegmentIndex(connection.targetName).toString())
        : !isProcessAreaTwoEp(otherShape[0]) ? boundingBoxToLine(otherShape[0].properties) : null;

      if (isAreaShape(otherShape[0]) || isPositionShape(otherShape[0])) {
        if (
          connection.direction === ConnectionDirection.BothWays ||
          connection.direction === ConnectionDirection.FromTarget
        ) {
          if (isAreaShape(otherShape[0]) && otherShape[0].parameters.storageType === StorageType.TWOSIDEDRACK) {
            mappings.push({
              discType: AreaMapperTypeEnum.MAP_CHECK_POINTS_TO_NARROW_AISLE_END_POINTS,
              areaReferencesToMap: [{ name: targetId }],
              usePivots: connection.usePivots,
            });
          } else if (isAreaShape(otherShape[0])) {
            mappings.push({
              discType: AreaMapperTypeEnum.MAP_CHECK_POINTS_TO_END_POINTS,
              areaReferencesToMap: [{ name: targetId }],
              epBackwardParking: otherShape[0].parameters.parkingDirection === AreaParkingDirection.BACKWARD,
              usePivots: connection.usePivots,
            });
          }
        }
        if (
          (isAreaShape(otherShape[0]) && otherShape[0].parameters.storageType !== StorageType.TWOSIDEDRACK) &&
          (connection.direction === ConnectionDirection.BothWays ||
          connection.direction === ConnectionDirection.FromSource)
        ) {
          AddAreaMappingFromAreaToHighway(
            vehicleSpec.gateMappingSettings.areaMappings,
            targetId,
            highwayId,
            isGetawayConnection(
              otherShape[0].parameters.direction,
              highwayLine,
              otherShape[0].id,
              connections,
            ),
            connection.usePivots,
          );
        }
      } else if (
        (isHighwayShape(otherShape[0]) || isAngledHighwayShape(otherShape[0])) &&
        areHighwaysAfterEachOther(highwayLine, otherHighwayLine.points, connection.rot)
      ) {
        if (
          connection.direction === ConnectionDirection.BothWays ||
          connection.direction === ConnectionDirection.FromTarget
        ) {
          mappings.push({
            discType: AreaMapperTypeEnum.MAP_TWO_NEAREST_CHECK_POINTS,
            areaReferencesToMap: [{ name: targetId }],
            epBackwardParking: true,
            usePivots: connection.usePivots,
          });
        }
      } else if (
        (isHighwayShape(otherShape[0]) || isAngledHighwayShape(otherShape[0])) &&
        areHighwaysNextToEachOther(highwayLine, otherHighwayLine.points, connection.rot)
      ) {
        if (
          connection.direction === ConnectionDirection.BothWays ||
          connection.direction === ConnectionDirection.FromTarget
        ) {
          mappings.push({
            discType: AreaMapperTypeEnum.MAP_ALL_NEAREST_CHECK_POINTS,
            areaReferencesToMap: [{ name: targetId }],
            isUTurn: allowUTurnBetween2Roads(
              {
                line: highwayLine,
                laneDir: laneDirection,
                isHorizontal: isHighwayShape(road)
                  ? road.properties.width > road.properties.height
                  : undefined,
              },
              {
                line: otherHighwayLine.points,
                laneDir: otherShape[0].parameters.laneDirection,
                isHorizontal: isHighwayShape(road)
                  ? road.properties.width > road.properties.height
                  : undefined,
              },
            ),
            usePivots: connection.usePivots,
          });
        }
      }
    }
  });

  const groupedMappings = Object.values(mappings.reduce<Record<string, Mapping>>((r, e) => {
    const key = `${e.discType}|${e.epBackwardParking}|${e.isUTurn}|${e.usePivots}`;
    if (!r[key]) { 
      r[key] = e
    }
    else {
      r[key].areaReferencesToMap.push(e.areaReferencesToMap[0]);
    }
    return r;
  }, {}))
  
  groupedMappings.forEach(group => {
    addAreaMappingFromHighwayToOtherShapes(
      vehicleSpec.gateMappingSettings.areaMappings,
      highwayId,
      (group.discType === AreaMapperTypeEnum.MAP_ALL_NEAREST_CHECK_POINTS) ? group : null,
      null,
      (group.discType === AreaMapperTypeEnum.MAP_CHECK_POINTS_TO_END_POINTS) ? group : null,
      (group.discType === AreaMapperTypeEnum.MAP_TWO_NEAREST_CHECK_POINTS) ? group : null,
      null,
      (group.discType === AreaMapperTypeEnum.MAP_CHECK_POINTS_TO_NARROW_AISLE_END_POINTS) ? group : null,
    );
  });
};
