import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import { IN_PROGRESS_STATUSES } from '../../helpers/constants';
import { simulationsSelector } from './simulationsSelector';

export const hasRunningSimulationsSelector = selector<boolean>({
  key: 'simulations/simulations/hasRunning',
  get: ({ get }) =>
    get(simulationsSelector).some((item) => IN_PROGRESS_STATUSES.includes(item.status)),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
