import { Vector2d } from 'konva/lib/types';
import { MathUtils, Vector2, Vector3 } from 'three';

import { BoundingBox } from '@/helpers/types';
import { convertPointToBottomLeftPosition } from '@/modules/artefacts/helpers/convert';
import { getMiddlePoint } from '@/modules/common/helpers/math';
import { CANVAS_TO_SHAPE_SCALE, SHAPE_TO_CANVAS_SCALE } from '@/modules/workspace/helpers/konva';
import {
  getHighwaySegmentContainingPoint,
  getHighwaySegmentIdContainingPoint,
  getSegment as getHighwaySegment,
} from '@modules/angledHighways/helpers';
import { getBoxAttachPointRelativeTo, getShapeAttachPointArea } from '@modules/common/helpers/shapes';
import { DistantConnection } from '@modules/common/types/connections';
import { isAngledHighwayShape, isHighwayShape, isProcessAreaTwoEp } from '@modules/common/types/guards';
import { DTShape } from '@modules/common/types/shapes';
import { combineConnectionId, getSegmentId } from '@modules/connections/common/connectionId';
import { AreaShape, PositionShape, ShapeProperties } from '@recoil/shape';
import { getClosestEndPoint, getClosestEndSegment, getClosestEndSegmentByIndex, getProcessEPIndexContainingPoint, getSegment as getProcessSegment } from '@/modules/processTwoEndPoint/helpers';
import { getSegmentAttachPoint, getSegmentAttachPointRelativeTo } from '@/modules/shapes/controlPointsShapes/helpers';
import { WideLineSegment } from '@/modules/common/types/general';

export type ConnectionPosition = {
  rot: number;
  from: string;
  to: string;
  bubblePosition: Vector2d;
  fromPosition: Vector2d;
  toPosition: Vector2d;
};

export const updateConnectionPositioning = (
  existingConnection: DistantConnection,
  fromShape: DTShape,
  toShape: DTShape,
): DistantConnection => {
  if (fromShape.id === toShape.id) return null;

  const { from, to } = getConnectionAttachPoints(existingConnection, fromShape, toShape);

  return {
    ...existingConnection,
    fromPosition: from,
    toPosition: to,
    bubblePosition: calculateMidPoint(from, to),
    rot: calculateAngle(from, to),
  };
};

export const getConnectionAttachPoints = (
  existingConnection: DistantConnection,
  fromShape: DTShape,
  toShape: DTShape,
) => {
  let fromPoint = getShapeAttachPointArea(fromShape as AreaShape | PositionShape);
  let toPoint = getShapeAttachPointArea(toShape as AreaShape | PositionShape);

  if (isProcessAreaTwoEp(fromShape)) {
    const segmentId = getSegmentId(existingConnection.from);
    if (segmentId !== undefined) {
      const segment = getClosestEndSegmentByIndex(fromShape, segmentId);
      if (segment) {
        fromPoint = getProcessAttachPoint(segment, segmentId)
      }
    }
  }
  
  if (isProcessAreaTwoEp(toShape)) {
    const segmentId = getSegmentId(existingConnection.to);
    if (segmentId !== undefined) {
      const segment = getProcessSegment(toShape, segmentId);
      if (segment) {
        toPoint = getProcessAttachPoint(segment, segmentId)
      }
    }
  }

  if (isAngledHighwayShape(fromShape)) {
    const segmentId = getSegmentId(existingConnection.from);
    if (segmentId !== undefined) {
      const segment = getHighwaySegment(fromShape, segmentId);
      if (segment) {
        fromPoint = getAngledHighwayAttachPoint(segment, toPoint)
      }
    }
  }

  if (isAngledHighwayShape(toShape)) {
    const segmentId = getSegmentId(existingConnection.to);
    if (segmentId !== undefined) {
      const segment = getHighwaySegment(toShape, segmentId);
      if (segment) {
        toPoint = getAngledHighwayAttachPoint(segment, fromPoint)
      }
    }
  }

  if (isHighwayShape(fromShape)) {
    const newFromPoint = getHighwayAttachPoint(fromShape.properties, toPoint)
    if (newFromPoint) fromPoint = newFromPoint;
  }

  if (isHighwayShape(toShape)) {
    const newToPoint = getHighwayAttachPoint(toShape.properties, fromPoint)
    if (newToPoint) toPoint = newToPoint;
  }

  return {
    from: fromPoint,
    to: toPoint,
  };
};

export const createConnectionPosition = (
  fromShape: DTShape,
  toShape: DTShape,
  mousePosition: Vector2d,
  fromConnectionId: string,
): ConnectionPosition => {
  if (fromShape.id === toShape.id) return null;

  const { from, to, fromSegment, toSegment } = getAttachPointsAndSegmentsBetweenShapes(
    fromShape,
    toShape,
    mousePosition,
    fromConnectionId,
  );

  return {
    from: combineConnectionId(fromShape.id, fromSegment),
    to: combineConnectionId(toShape.id, toSegment),
    fromPosition: from,
    toPosition: to,
    bubblePosition: calculateMidPoint(from, to),
    rot: calculateAngle(from, to),
  };
};

export const getAttachPointsAndSegmentsBetweenShapes = (
  fromShape: DTShape,
  toShape: DTShape,
  mousePosition: Vector2d,
  fromConnectionId: string,
) => {
  let fromPoint = getShapeAttachPointArea(fromShape as AreaShape | PositionShape);
  let toPoint = getShapeAttachPointArea(toShape as AreaShape | PositionShape);
  let fromSegmentId: number | undefined;
  let toSegmentId: number;

  if (isProcessAreaTwoEp(fromShape)) {
    fromSegmentId = getSegmentId(fromConnectionId);
    if (fromSegmentId !== undefined) {
      const segment = getClosestEndSegmentByIndex(fromShape, fromSegmentId);
      if (segment) {
        fromPoint = getProcessAttachPoint(segment, fromSegmentId)
      }
    }
  }

  if (isProcessAreaTwoEp(toShape)) {
    toSegmentId = getProcessEPIndexContainingPoint(toShape,
      new Vector2(mousePosition.x, mousePosition.y).multiplyScalar(CANVAS_TO_SHAPE_SCALE)
    ) 
    if (toSegmentId !== undefined) {
      const segment = getClosestEndSegment(toShape, new Vector2(mousePosition.x, mousePosition.y).multiplyScalar(CANVAS_TO_SHAPE_SCALE));
      if (segment) {
        toPoint = getProcessAttachPoint(segment, toSegmentId)
      }
    }
  }

  if (isAngledHighwayShape(fromShape)) {
    fromSegmentId = getSegmentId(fromConnectionId);
    if (fromSegmentId !== undefined) {
      const segment = getHighwaySegment(fromShape, fromSegmentId);
      if (segment) {
        toPoint = getAngledHighwayAttachPoint(segment, toPoint)
      }
    }
  }

  if (isAngledHighwayShape(toShape)) {
    toSegmentId = getHighwaySegmentIdContainingPoint(
      toShape,
      new Vector2(mousePosition.x, mousePosition.y).multiplyScalar(CANVAS_TO_SHAPE_SCALE),
    )
    if (toSegmentId !== undefined) {
      const segment = getHighwaySegment(toShape, toSegmentId);
      if (segment) {
        toPoint = getAngledHighwayAttachPoint(segment, fromPoint)
      }
    }
  }

  if (isHighwayShape(fromShape)) {
    const newFromPoint = getHighwayAttachPoint(fromShape.properties, toPoint)
    if (newFromPoint) fromPoint = newFromPoint;
  }

  if (isHighwayShape(toShape)) {
    const newToPoint = getHighwayAttachPoint(toShape.properties, fromPoint)
    if (newToPoint) toPoint = newToPoint;
  }

  return {
    from: fromPoint,
    fromSegment: fromSegmentId,
    to: toPoint,
    toSegment: toSegmentId,
  };
};

const calculateMidPoint = (fromPosition: Vector2d, toPosition: Vector2d): Vector2d => {
  const pointA = new Vector2(fromPosition.x, fromPosition.y);
  const pointB = new Vector2(toPosition.x, toPosition.y);
  let dir = pointB.clone().sub(pointA);
  const len = dir.length();
  dir = dir.normalize().multiplyScalar(len * 0.5);
  return pointA.clone().add(dir);
};

const calculateAngle = (fromPosition: Vector2d, toPosition: Vector2d): number => {
  const pointA = new Vector3(fromPosition.x, fromPosition.y, 0);
  const pointB = new Vector3(toPosition.x, toPosition.y, 0);
  const dir = pointB.clone().sub(pointA).normalize();
  const angle = MathUtils.radToDeg(dir.angleTo(new Vector3(1, 0, 0)));
  return (dir.y < 0 ? 360 - angle : angle) + 90;
};

export const getSourceAttachPoint = (shape: DTShape, point: Vector2d) => {
  if (isAngledHighwayShape(shape)) {
    return getSegmentAttachPoint(getHighwaySegmentContainingPoint(shape, point));
  }
  if (isProcessAreaTwoEp (shape)) {
    return getClosestEndPoint(shape, new Vector2(point.x, point.y).multiplyScalar(CANVAS_TO_SHAPE_SCALE))
  }

  return getShapeAttachPointArea(shape as AreaShape | PositionShape);
};

export const convertToBottomLeftCoordinateSystem = (connections: readonly DistantConnection[], boundingBox: BoundingBox) => connections.map((item) => toBottomLeftOrigin(item, boundingBox));

const toBottomLeftOrigin = (connection: DistantConnection, workspaceBoundingBox: BoundingBox): DistantConnection => {
  const from = convertPointToBottomLeftPosition(
    new Vector2(Math.round(connection.fromPosition.x * 10), Math.round(connection.fromPosition.y * 10)), workspaceBoundingBox);
  const to = convertPointToBottomLeftPosition(
    new Vector2(Math.round(connection.toPosition.x * 10), Math.round(connection.toPosition.y * 10)), workspaceBoundingBox);
  const bubble = convertPointToBottomLeftPosition(
    new Vector2(Math.round(connection.bubblePosition.x * 10), Math.round(connection.bubblePosition.y * 10)), workspaceBoundingBox);
  return {
    ...connection,
    fromPosition: new Vector2(from.x, from.y),
    toPosition: new Vector2(to.x, to.y),
    bubblePosition: new Vector2(bubble.x, bubble.y)
  }
};

const getHighwayAttachPoint = (properties: ShapeProperties, otherPoint: Vector2d): Vector2 | null => {
  const newPoint = getBoxAttachPointRelativeTo(
    properties,
    new Vector2(otherPoint.x, otherPoint.y),
  );

  return newPoint;
}

const getAngledHighwayAttachPoint = (segment: WideLineSegment, otherPoint: Vector2d): Vector2 => {
  const newPoint = getSegmentAttachPointRelativeTo(
    segment,
    new Vector2(otherPoint.x, otherPoint.y),
  );

  if (newPoint) {
    return newPoint;
  } 
  return getMiddlePoint(segment.points.start, segment.points.end).multiplyScalar(
    SHAPE_TO_CANVAS_SCALE,
  );
}

const getProcessAttachPoint = (segment: WideLineSegment, segmentId: number): Vector2 => {
  if (segmentId === 0)
    return new Vector2(segment.points.start.x, segment.points.start.y).multiplyScalar(SHAPE_TO_CANVAS_SCALE);

  return new Vector2(segment.points.end.x, segment.points.end.y).multiplyScalar(SHAPE_TO_CANVAS_SCALE);
}
