import { atom } from 'recoil';
import { WorkspaceMode } from '../../types/general';

type Workspace = {
  mode: WorkspaceMode;
};

export const workspaceAtom = atom<Workspace>({
  key: 'common/workspace/atom',
  default: {
    mode: WorkspaceMode.EDITABLE,
  },
});
