import { contextMenuTheme } from '@/modules/common/components/theme';
import { GroupOptionMenu } from '@/modules/shapeGroups';
import { DeleteControlPointOption } from './DeleteControlPointOption';
import { Theme } from '@modules/common/types/general';
import { UserPreferenceName } from '@modules/userPreferences';
import { useUserPreference } from '@/modules/userPreferences/hooks';
import { useContextMenu } from '@modules/workspace/hooks';
import { Menu } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { hasOptionsSelector, positionState } from '@recoil/workspace/contextMenu';
import { useCallback, useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { DeleteOption } from './DeleteOption';
import { PasteOption } from './PasteOption';
import { ToggleFlowsOption } from './ToggleFlowsOption';
import { PivotOption } from './PivotOption';

const CLOSE_DELAY_MS = 700;

const ContextMenuComponent = () => {
  const { position, hide } = useContextMenu();
  const hasOptions = useRecoilValue(hasOptionsSelector);
  const closeTimerRef = useRef(null);
  const themeMode = useUserPreference(UserPreferenceName.GENERAL_THEME);

  const onMouseLeaveHandler = useCallback(() => {
    closeTimerRef.current = setTimeout(hide, CLOSE_DELAY_MS);
  }, [hide]);

  const onMouseEnterHandler = useCallback(() => {
    if (closeTimerRef?.current) clearTimeout(closeTimerRef.current);
  }, []);

  useEffect(
    () => () => {
      if (closeTimerRef?.current) clearTimeout(closeTimerRef.current);
    },
    [],
  );

  if (!hasOptions) return null;

  return (
    <ThemeProvider theme={contextMenuTheme}>
      <Menu
        open
        onClose={hide}
        anchorReference='anchorPosition'
        anchorPosition={{ top: position.y, left: position.x }}
        disableAutoFocusItem
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        PaperProps={{
          sx: {
            borderRadius: '4px',
          },
          elevation: 1,
          onMouseEnter: onMouseEnterHandler,
          onMouseLeave: onMouseLeaveHandler,
        }}
        MenuListProps={{
          'aria-labelledby': 'context-menu',
          sx: {
            minWidth: '210px',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
        onBackdropClick={hide}
        BackdropProps={{
          style: {
            background: 'transparent',
          },
          onContextMenu: (e) => {
            e.preventDefault();
            hide();
          },
        }}
        className={themeMode === Theme.DARK ? 'dark' : ''}
      >
        <ToggleFlowsOption />
        <PasteOption />
        <DeleteOption />
        <GroupOptionMenu />
        <DeleteControlPointOption />
        <PivotOption />
      </Menu>
    </ThemeProvider>
  );
};

export const ContextMenu = () => {
  const position = useRecoilValue(positionState);

  if (!position) return null;

  return <ContextMenuComponent />;
};
