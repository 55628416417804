import { Accordion } from '@/modules/common/components/Accordion';
import { Module } from '@/modules/common/types/navigation';
import { openFlowPanelAtom } from '@/modules/flows/common/store/openFlowPanelAtom';
import { excludedSimulationFlowsSelector } from '@/modules/flows/store/simulation';
import { simulationFlowsSelector } from '@/modules/flows/store/simulation/selector';
import { FlowScope } from '@/modules/flows/types';
import { OrderProfileButton, OrderProfilePanel, openOrderProfilePanelAtom } from '@/modules/orderProfile';
import { navSelector } from '@/store/recoil/nav';
import { InfoText } from '@common/components/InfoText';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { Add } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Flow } from '../../../components';
import { useSimulationFlows } from '../../hooks';
import { ExcludedFlow } from './ExcludedFlow';

export function FlowsPanel() {
  const { t } = useTranslation('interface');
  const simulationFlows = useRecoilValue(simulationFlowsSelector);
  const excludedSimulationFlows = useRecoilValue(excludedSimulationFlowsSelector);
  const containerRef = useRef<HTMLDivElement>();
  const [nav, setNav] = useRecoilState(navSelector);
  const { selectFlowTool } = useSimulationFlows();
  const mode = useRecoilValue(modeSelector);
  const [open, setOpen] = useRecoilState(openFlowPanelAtom);
  const panelRef = useRef<HTMLDivElement>();
  const setOrderProfilePanelOpen = useSetRecoilState(openOrderProfilePanelAtom);

  const handleAddFlowClick = useCallback(() => {
    setNav(Module.LAYOUT);
    selectFlowTool();
  }, [selectFlowTool, setNav]);

  useEffect(() => {
    if (nav !== Module.SIMULATION) setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nav]);

  useEffect(() => () => setOrderProfilePanelOpen(false), [setOrderProfilePanelOpen]);

  return (
    <>
      <OrderProfilePanel anchor={panelRef} />
      <Accordion
        ref={panelRef}
        title={t('simulation.simulation_edit.flows.header', {
          includedCount: simulationFlows.length,
          totalCount: simulationFlows.length + excludedSimulationFlows.length,
        })}
        expanded={open}
        onChange={(expanded: boolean) => setOpen(expanded)}
        afterIcon={<OrderProfileButton />}
        sx={{ px: 2 }}
      >
        <Stack height='100%' ref={containerRef}>
          <Stack
            sx={{
              flex: '1 1 auto',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
            gap={1}
          >
            {/* flows from current draftSimulation */}
            {!!simulationFlows.length && (
              <Stack>
                {simulationFlows.map((flow) => (
                  <Flow
                    flowScope={FlowScope.SIMULATION}
                    tooltipTitle={t('simulation.simulation_edit.flows.remove.tooltip')}
                    key={`sim_${flow.id}`}
                    id={flow.id}
                  />
                ))}
              </Stack>
            )}

            {/* feedback when there are no flows in currentDraftSimulation altho at least 1 layoutFlow exists */}
            {!simulationFlows?.length && !!excludedSimulationFlows?.length && (
              <InfoText
                header={t('simulation.simulation_edit.flows.no_included_flows.title')}
                subHeader={t('simulation.simulation_edit.flows.no_included_flows.subtitle')}
                sx={{ width: '90%', margin: '2rem auto', height: 'auto' }}
              />
            )}

            {/* list layoutFlows excluded from the simulation draft */}
            {!!excludedSimulationFlows?.length && (
              <Stack>
                <Typography marginBottom={2}>
                  {t('simulation.simulation_edit.flows.excluded_flows.header', {
                    count: excludedSimulationFlows.length,
                  })}
                </Typography>
                {excludedSimulationFlows.map((flow) => (
                  <ExcludedFlow key={flow.id} id={flow.id} />
                ))}
              </Stack>
            )}

            {/* if no layoutFlows and no currentSimulationFlows */}
            {!simulationFlows?.length && !excludedSimulationFlows?.length && (
              <InfoText
                header={t('simulation.simulation_edit.flows.no_global_flows.title')}
                subHeader={t('simulation.simulation_edit.flows.no_global_flows.subtitle')}
                sx={{ width: '90%', margin: '0 auto' }}
              />
            )}
          </Stack>
          {!simulationFlows?.length && !excludedSimulationFlows?.length && (
            <Button
              startIcon={<Add />}
              onClick={handleAddFlowClick}
              sx={{ mt: 2 }}
              disabled={mode !== WorkspaceMode.EDITABLE}
            >
              {t('simulation.simulation_edit.flows.add_button.label')}
            </Button>
          )}
        </Stack>
      </Accordion>
    </>
  );
}
