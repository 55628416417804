import { selector } from 'recoil';
import { wallIdsAtom } from './wallIdsAtom';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../common';

export const wallIdsSelector = selector<string[]>({
  key: 'wallIdsSelector',
  get: ({ get }) => get(wallIdsAtom),
  set: ({ set }, newValue) => {
    set(wallIdsAtom, newValue);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
