import { useCallback } from 'react';
import { useDebouncedCallback } from '@modules/common/hooks';

import { UserPreferences } from '../types';
import { useUserPreferencesStore } from '../store';
import { useUserPreferencesApi } from './useUserPreferencesApi';
import { UserPreferenceName } from '../constants';

export const useUpdateUserPreferences = () => {
  const { update } = useUserPreferencesApi();
  const updateDebounced = useDebouncedCallback(update, 500);

  const updateUserPreference = useCallback(
    <T extends UserPreferenceName>(name: T, value: UserPreferences[T], debounce = true) => {
      useUserPreferencesStore.getState().setPreference(name, value);
      if (debounce) updateDebounced(name, value);
      else update(name, value);
    },
    [updateDebounced, update],
  );

  return {
    updateUserPreference,
  };
};
