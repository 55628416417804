import { atom, atomFamily } from 'recoil';

import { FloorPlanGroup } from '../../helpers/types';

export const floorPlanGroupIdsAtom = atom<string[]>({
  key: 'floorPlanAdmin/floorPlanGroups/idsAtom',
  default: [],
});

export const floorPlanGroupAtom = atomFamily<FloorPlanGroup, string>({
  key: 'floorPlanAdmin/floorPlanGroups/atom',
  default: null,
});
