import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { scaleSelector } from '../../../store/recoil/workspace';

export function useZoom() {
  const currentZoom = useRecoilValue(scaleSelector);

  const zoomifyValue = useCallback(
    (input: number): number => input * (1 / currentZoom),
    [currentZoom],
  );

  return { zoomifyValue, currentZoom };
}
