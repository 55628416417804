import { useRecoilCallback } from 'recoil';
import { isReferencePresentSelector, referenceDimensions } from '../store';

export const useGetReferenceProperties = () => {
  const getReferenceProperties = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const referenceExists = await snapshot.getPromise(isReferencePresentSelector);
        if (!referenceExists) {
          return null;
        }

        return await snapshot.getPromise(referenceDimensions);
      },
    [],
  );

  return { getReferenceProperties };
};
