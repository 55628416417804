import { memo } from 'react';
import { useRecoilValue } from 'recoil';

import { ProcessTwoEndPointProxy } from '@/modules/processTwoEndPoint/components/ProcessTwoEndPointProxy';
import { ProcessTwoEndPointRenderer } from '@/modules/processTwoEndPoint/components/ProcessTwoEndPointRenderer';
import { ProcessTwoEPShape } from '@/modules/processTwoEndPoint/types';
import { SHAPE_TO_CANVAS_SCALE } from '@modules/workspace/helpers/konva';
import { shapeTypeShowSelector } from '@/modules/layers';
import shapeAtom from '@recoil/shape/atom';

type ProcessTwoEndPointProps = {
  id: string;
};

const ProcessTwoEndPointComponent = ({ id }: ProcessTwoEndPointProps) => {
  const processTwoEp = useRecoilValue(shapeAtom(id)) as ProcessTwoEPShape;
  const layerShown = useRecoilValue(shapeTypeShowSelector(processTwoEp?.type));

  if (!processTwoEp.id || !layerShown) return null;

  return (
    <>
      <ProcessTwoEndPointRenderer
        id={id}
        points={processTwoEp.properties.controlPoints}
        width={processTwoEp.parameters.width * SHAPE_TO_CANVAS_SCALE}
        intakeSupportedVehicleIds={processTwoEp.parameters.intakeParameters.supportedVehicleIds}
        deliverySupportedVehicleIds={processTwoEp.parameters.deliveryParameters.supportedVehicleIds}
      />
      <ProcessTwoEndPointProxy
        id={id}
        points={processTwoEp.properties.controlPoints}
        width={processTwoEp.parameters.width}
      />
    </>
  );
};

export const ProcessTwoEndPoint = memo(ProcessTwoEndPointComponent);
