import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import { VehicleType } from '../../helpers/types';
import { simulationAtom } from './simulationAtom';

export const vehicleTypesSelector = selector<VehicleType[]>({
  key: 'simulations/draft/vehicleTypes',
  get: ({ get }) => get(simulationAtom).vehicleTypes,
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
