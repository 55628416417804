import { RemoveCircleIcon } from '@/assets/icons';
import { Divider, IconButton, Modal, Paper, Typography } from '@mui/material';
import { ReactNode, useCallback } from 'react';

const settingsStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: 0,
  bgcolor: 'background.paper',
  p: 4,
  minWidth: 700,
  color: 'neutral.dark',
  border: '1px solid',
  borderColor: 'shades.light',
  borderRadius: '4px',
};

const closeButtonStyle = {
  position: 'absolute',
  top: '5px',
  right: '5px',
};

type Props = {
  children: ReactNode;
  onClose: () => void;
  open: boolean;
  title?: string;
};

export const FloatingPanel = ({ children, onClose, open, title }: Props) => {
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    open && (
      <Modal open={open} onClose={handleClose}>
        <Paper elevation={3} sx={settingsStyle}>
          <IconButton onClick={() => onClose()} sx={closeButtonStyle}>
            <RemoveCircleIcon />
          </IconButton>
          {title && (
            <>
              <Typography variant='h3' mb={2}>
                {title}
              </Typography>
              <Divider sx={{ marginBottom: 2 }} />
            </>
          )}
          {children}
        </Paper>
      </Modal>
    )
  );
};
