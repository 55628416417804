import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { selector } from 'recoil';
import { OutputFiles, Warning } from '../types';
import { fpsAtom } from './atom';

export const fpsFilesSelector = selector<OutputFiles>({
  key: 'floorplanService/files',
  get: ({ get }) => get(fpsAtom).files,
  set:
  ({ get, set }, newValue) => {
    set(fpsAtom, {
      ...get(fpsAtom),
      files: newValue,
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
})

export const fpsStatusSelector = selector<string>({
  key: 'floorplanService/status',
  get: ({ get }) => get(fpsAtom).status,
  set:
  ({ get, set }, newValue) => {
    set(fpsAtom, {
      ...get(fpsAtom),
      status: newValue,
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
})

export const fpsWarningsSelector = selector<Warning[]>({
  key: 'floorplanService/warnings',
  get: ({ get }) => get(fpsAtom).warnings,
  set:
  ({ get, set }, newValue) => {
    set(fpsAtom, {
      ...get(fpsAtom),
      warnings: newValue
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
})
