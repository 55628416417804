import { v4 as uuid } from 'uuid';

import {
  DEFAULT_RACK,
} from '@/modules/common/constants/storage';
import { calcOrientedLoadCarriersBoundingBox } from '@/modules/common/helpers/loadCarrier';
import { getRackShelfHeights } from '@/modules/common/helpers/rack';
import { LoadCarrierType } from '@/modules/common/types/floorPlan';
import { RackSpec } from '@/modules/floorplanService/helpers/mapping/types';
import {
  getRackNameFromShapeId,
} from '@/modules/floorplanService/helpers/mapping/utils';
import { AreaShape } from '@/store/recoil/shape';
import { RackProperty } from '@/store/recoil/shape/types/area';

export const createRackSpec = (
  rackSpecs: RackSpec[],
  shape: AreaShape,
  loadTypes: LoadCarrierType[],
) => {
  const overhangFront = DEFAULT_RACK.OVERHANG_FRONT;
  const overhangBack = DEFAULT_RACK.OVERHANG_BACK;

  const { supportedLoadCarriersIds, loadCarrierOrientation } = shape.parameters;
  const supportedLoadCarrierTypes = loadTypes.filter(({ id }) =>
    supportedLoadCarriersIds?.includes(id),
  );
  const loadCarriersBoundingBox = calcOrientedLoadCarriersBoundingBox(
    supportedLoadCarrierTypes,
    loadCarrierOrientation,
  );

  const {
    framesDeep,
    rackLevels,
    rackLoadsPerShelf,
    loadHeight,
    clearanceSide,
    clearanceTop,
    clearanceInBetween,
    beamHeight,
    postWidth,
    firstShelfHeight,
  } = shape.parameters.storageProperty as RackProperty;

  const beamLength =
    loadCarriersBoundingBox.length * rackLoadsPerShelf +
    clearanceInBetween * (rackLoadsPerShelf - 1) +
    clearanceSide * 2;

  const shelfHeights = getRackShelfHeights(firstShelfHeight, loadHeight + clearanceTop, rackLevels, beamHeight).map(
    (shelfHeight, i) => ({
      sequence: i,
      height: shelfHeight,
    }),
  );

  rackSpecs.push({
    rackSpecId: uuid(),
    name: getRackNameFromShapeId(shape.id),
    frameLength: beamLength + 2 * postWidth, // postWidth is interpreted by FPS as post size. This will add automatic margin between shelves in x and y direction. 
    frameDepth: loadCarriersBoundingBox.width * framesDeep - overhangFront - overhangBack,
    frameHeight: shelfHeights[shelfHeights.length - 1].height + 0.5 * loadHeight,
    beamLength,
    beamHeight,
    clearanceSide,
    clearanceInBetween,
    clearanceTop,
    overhangFront,
    overhangBack,
    topShelfHeight: loadHeight,
    numLoadPositionsPerShelf: rackLoadsPerShelf,
    shelfHeights,
    depth: framesDeep,
    loadPositions: [],
  });
};
