import * as React from 'react';

function ArrowSmallDown({ className = '', ...rest }) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
      style={{ fill: 'currentColor' }}
      className={className}
      {...rest}
    >
      <g mask='url(#mask0_315_5633)' transform='rotate(90,12.4,11.999975)'>
        <path d='M 10.5,15.8 V 8.19995 L 14.3,12 Z' />
      </g>
    </svg>
  );
}

const ArrowSmallDownIcon = React.memo(ArrowSmallDown);
export default ArrowSmallDownIcon;
