import * as React from 'react';

function FileAdd({ className = '', ...rest }) {
  return (
    <svg
      width='14px'
      height='18px'
      viewBox='0 0 14 18'
      fill='none'
      className={`fill-current ${className}`}
      {...rest}
    >
<path fillRule='evenodd'
      clipRule='evenodd'
      d="M6.5 14.775H7.5V10.075L9.6 12.175L10.3 11.45L7 8.15L3.7 11.45L4.4 12.175L6.5 10.075V14.775ZM1.625 18C1.15833 18 0.771 17.846 0.463 17.538C0.154333 17.2293 0 16.8417 0 16.375V1.625C0 1.15833 0.154333 0.770667 0.463 0.462C0.771 0.154 1.15833 0 1.625 0H9.5L14 4.5V16.375C14 16.8417 13.846 17.2293 13.538 17.538C13.2293 17.846 12.8417 18 12.375 18H1.625ZM9 5V1H1.625C1.45833 1 1.31267 1.06267 1.188 1.188C1.06267 1.31267 1 1.45833 1 1.625V16.375C1 16.5417 1.06267 16.6873 1.188 16.812C1.31267 16.9373 1.45833 17 1.625 17H12.375C12.5417 17 12.6873 16.9373 12.812 16.812C12.9373 16.6873 13 16.5417 13 16.375V5H9Z"
      fill='currentColor'
      />

    </svg>
  );
}

const FileAddIcon = React.memo(FileAdd);
export default FileAddIcon;
