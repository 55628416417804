import { Stack } from '@mui/system';
import { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { useArtefacts } from '@/modules/artefacts';
import { selectedShapesIdsState } from '@/store/recoil/shapes/selected';
import { unitSelector, unitValueSelector } from '@/store/recoil/workspace';
import { NumberInput } from '@common/components/inputs';
import { areaRackProperties, areasRackShelfDepth } from '@components/PropertiesPanel/store/area';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { CONTEXT, contextState } from '@recoil/input';
import { PropertiesLabel } from '@modules/common/components/PropertiesLabel';
import { MIN_RACK } from '@modules/common/constants/storage';
import { useFloorPlanState } from '@modules/floorplan/hooks/useFloorPlanState';

function GenericRackProperties() {
  const { saveFloorPlan } = useFloorPlanState();
  const { updateDebounced: updateArtefacts } = useArtefacts();
  const [rackProperties, setRackProperties] = useRecoilState(areaRackProperties);
  const unit = useRecoilValue(unitSelector);
  const { t } = useTranslation();
  const setContext = useSetRecoilState(contextState);
  const mode = useRecoilValue(modeSelector);
  const panelRef = useRef<HTMLDivElement>();
  const shelfDepth = useRecoilValue(areasRackShelfDepth);
  const selectedShapeIds = useRecoilValue(selectedShapesIdsState);

  const onChangeLoadsPerShelf = useCallback(
    (value) => {
      if (value < MIN_RACK.LOADS_PER_SHELF) {
        return {
          error: t('common:minimum_value', `Minimum ${MIN_RACK.LOADS_PER_SHELF}`, {
            minValue: MIN_RACK.LOADS_PER_SHELF,
          }),
        };
      }
      setRackProperties({ rackLoadsPerShelf: value });
      saveFloorPlan();
      updateArtefacts(selectedShapeIds);
    },
    [setRackProperties, saveFloorPlan, t, updateArtefacts, selectedShapeIds],
  );

  const onChangeLoadHeight = useCallback(
    (value) => {
      if (value < MIN_RACK.LOAD_HEIGHT) {
        return {
          error: t('common:minimum_value', `Minimum ${MIN_RACK.LOAD_HEIGHT}`, {
            minValue: MIN_RACK.LOAD_HEIGHT,
          }),
        };
      }
      setRackProperties({ loadHeight: value });
      saveFloorPlan();
      updateArtefacts(selectedShapeIds);
    },
    [setRackProperties, saveFloorPlan, t, updateArtefacts, selectedShapeIds],
  );

  const onChangeFramesDeep = useCallback(
    (newVal: number) => {
      if (newVal < MIN_RACK.FRAMES_DEEP) {
        return {
          error: t('common:minimum_value', `Minimum ${MIN_RACK.FRAMES_DEEP}`, {
            minValue: MIN_RACK.FRAMES_DEEP,
          }),
        };
      }
      setRackProperties({ framesDeep: newVal });
      saveFloorPlan();
      updateArtefacts(selectedShapeIds);
    },
    [setRackProperties, saveFloorPlan, t, updateArtefacts, selectedShapeIds],
  );

  const onChangeRackLevels = useCallback(
    (newVal: number) => {
      if (newVal < MIN_RACK.LEVELS) {
        return {
          error: t('common:minimum_value', `Minimum ${MIN_RACK.LEVELS}`, {
            minValue: MIN_RACK.LEVELS,
          }),
        };
      }
      setRackProperties({ rackLevels: newVal });
      saveFloorPlan();
      updateArtefacts(selectedShapeIds);
    },
    [setRackProperties, saveFloorPlan, t, updateArtefacts, selectedShapeIds],
  );

  const onFocus = useCallback(() => setContext(CONTEXT.PROPERTYPANEL), [setContext]);
  const onBlur = useCallback(() => setContext(CONTEXT.WORKSPACE), [setContext]);

  const unitOfMeasurement = useMemo(() => t(`interface:settings.units.${unit}`, unit), [t, unit]);

  return (
    <Stack direction='column' spacing={1}>
      <PropertiesLabel i18nkey='interface:properties.area.rack.levels'>
        <NumberInput
          value={rackProperties.rackLevels}
          sx={{ width: '100%' }}
          disabled={mode !== WorkspaceMode.EDITABLE}
          onChange={onChangeRackLevels}
          onFocus={onFocus}
          onBlur={onBlur}
          errorType='floating'
          dataType='integer'
        />
      </PropertiesLabel>
      <PropertiesLabel i18nkey='interface:properties.area.rack.height_per_level'>
        <NumberInput
          value={useRecoilValue(unitValueSelector(rackProperties.loadHeight))}
          unit={unitOfMeasurement}
          sx={{ width: '100%' }}
          disabled={mode !== WorkspaceMode.EDITABLE}
          onChange={onChangeLoadHeight}
          onFocus={onFocus}
          onBlur={onBlur}
          errorType='floating'
          dataType='integer'
        />
      </PropertiesLabel>
      <PropertiesLabel i18nkey='interface:properties.area.rack.levels_deep'>
        <NumberInput
          value={rackProperties.framesDeep}
          sx={{ width: '100%' }}
          disabled={mode !== WorkspaceMode.EDITABLE}
          onChange={onChangeFramesDeep}
          onFocus={onFocus}
          onBlur={onBlur}
          errorType='floating'
          dataType='integer'
        />
      </PropertiesLabel>
      <PropertiesLabel i18nkey='interface:properties.area.rack.depth_per_level'>
        <NumberInput
          value={shelfDepth}
          sx={{ width: '100%' }}
          disabled
          onChange={onChangeLoadsPerShelf}
          onFocus={onFocus}
          onBlur={onBlur}
          errorType='floating'
          dataType='integer'
        />
      </PropertiesLabel>
      <PropertiesLabel i18nkey='interface:properties.area.rack.loads_per_shelf'>
        <NumberInput
          value={rackProperties.rackLoadsPerShelf}
          sx={{ width: '100%' }}
          disabled={mode !== WorkspaceMode.EDITABLE}
          onChange={onChangeLoadsPerShelf}
          onFocus={onFocus}
          onBlur={onBlur}
          errorType='floating'
          dataType='integer'
        />
      </PropertiesLabel>
    </Stack>
  );
}

export default GenericRackProperties;
