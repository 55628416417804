import { SvgIcon } from '@mui/material';

function GridIcon(props) {
  return (
    <SvgIcon
      width='16'
      height='16'
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      stroke='none'
      {...props}
    >
      <circle cx='5' cy='5' r='1.6' fill={props.color || 'currentColor'} />
      <circle cx='11' cy='5' r='1.6' fill={props.color || 'currentColor'} />
      <circle cx='5' cy='11' r='1.6' fill={props.color || 'currentColor'} />
      <circle cx='11' cy='11' r='1.6' fill={props.color || 'currentColor'} />
    </SvgIcon>
  );
}

export default GridIcon;
