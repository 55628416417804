import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@/store/recoil/common';

import { moduleAtom } from './module';

export const isFirstLoadedSelector = selector({
  key: 'simulation/module/isFirstLoaded',
  get: ({ get }) => get(moduleAtom).isFirstLoaded,
  set: ({ set }, isFirstLoaded: boolean) =>
    set(moduleAtom, (state) => ({
      ...state,
      isFirstLoaded,
    })),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
