// eslint-disable-next-line max-classes-per-file
import { SOURCE_NAME } from './consts';
import { DeleteObject, VisualizationObject } from './types';

export interface FleetTrackerContract {
  MessageType: string;
}
export class ObjectUpdateContract implements FleetTrackerContract {
  MessageType = 'ObjectUpdate';
  Source = SOURCE_NAME;
  Objects: VisualizationObject[];

  constructor(vos: VisualizationObject[]) {
    this.Objects = vos;
  }
}

export class ObjectDeleteContract implements FleetTrackerContract {
  MessageType = 'ObjectDelete';
  Source = SOURCE_NAME;
  Objects: DeleteObject[];

  constructor(objects: DeleteObject[]) {
    this.Objects = objects;
  }
}
