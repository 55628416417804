import { OrderProfileIcon } from '@/assets/icons';
import { StyledTooltip } from '@/helpers/styles';
import { IconButton } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { openOrderProfilePanelAtom } from '../store/openOrderProfilePanelAtom';

export const OrderProfileButton = () => {
  const setPanelOpen = useSetRecoilState(openOrderProfilePanelAtom);

  const handleClick = useCallback(
    (event) => {
      event.stopPropagation();
      setPanelOpen(true);
    },
    [setPanelOpen],
  );

  const { t } = useTranslation('interface');

  return (
    <StyledTooltip
      style={{ marginLeft: 'auto' }}
      title={t('interface:order_profile.title')}
      placement='bottom'
    >
      <IconButton sx={{ padding: 0, marginRight: 1 }} onClick={handleClick}>
        <OrderProfileIcon />
      </IconButton>
    </StyledTooltip>
  );
};
