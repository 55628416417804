import { selectorFamily } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../common';
import allShapesSelector from './allShapesSelector';

// TODO: see if we can jst access the underlying atom directly instead of depending on the allShapesSelector
const shapesSelector = selectorFamily({
  key: 'shapesSelector',
  get:
    (ids: string[]) =>
    ({ get }) =>
      get(allShapesSelector).filter((shape) => ids.includes(shape.id)),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default shapesSelector;
