import { angledHighwayIdsState } from '@modules/angledHighways/store/state';
import { AngledHighwayShape } from '@modules/angledHighways/types';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { setAtomFamilyWithIdsAtom } from '@recoil/common/helper';
import shapeAtom from '@recoil/shape/atom';
import { selector } from 'recoil';

export const allAngledHighwaysSelector = selector({
  key: 'allAngledHighwaysSelector',
  get: ({ get }) => get(angledHighwayIdsState).map((id) => get(shapeAtom(id))) as AngledHighwayShape[],
  set: (getSetResetRecoilState, newAngledHighways: AngledHighwayShape[]) => {
    setAtomFamilyWithIdsAtom(
      getSetResetRecoilState,
      shapeAtom,
      angledHighwayIdsState,
      newAngledHighways,
    );
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
