import { Accordion } from '@/modules/common/components/Accordion';
import { Module } from '@/modules/common/types/navigation';
import { useLayoutFlowsCallbacks } from '@/modules/flows/layout/hooks';
import { allFlowIdsAmountSelector } from '@/modules/flows/store/layout';
import {
  OrderProfileButton,
  OrderProfilePanel,
  openOrderProfilePanelAtom,
} from '@/modules/orderProfile';
import navAtom from '@/store/recoil/nav/atom';
import { InfoText } from '@common/components/InfoText';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { Button, Stack } from '@mui/material';
import { memo, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { openFlowPanelAtom } from '../../common/store/openFlowPanelAtom';
import { ActiveFlow } from './ActiveFlow';
import { ExistingFlows } from './ExistingFlows';

const FlowsPanelComponent = () => {
  const { t } = useTranslation('interface');
  const layoutFlowsAmount = useRecoilValue(allFlowIdsAmountSelector);
  const { selectFlowTool } = useLayoutFlowsCallbacks();
  const mode = useRecoilValue(modeSelector);
  const onAddNewFlow = useCallback(() => selectFlowTool(), [selectFlowTool]);
  const panelRef = useRef<HTMLDivElement>();
  const [open, setOpen] = useRecoilState(openFlowPanelAtom);
  const nav = useRecoilValue(navAtom);
  const setOrderProfilePanelOpen = useSetRecoilState(openOrderProfilePanelAtom);

  useEffect(() => {
    if (nav !== Module.SIMULATION) setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nav]);

  useEffect(() => () => setOrderProfilePanelOpen(false), [setOrderProfilePanelOpen]);

  return (
    <>
      <OrderProfilePanel anchor={panelRef} />
      <Accordion
        ref={panelRef}
        title={t('properties.flows.header', { count: layoutFlowsAmount })}
        afterIcon={<OrderProfileButton />}
        key='layout'
        expanded={open}
        onChange={(expanded: boolean) => setOpen(expanded)}
        sx={{ paddingTop: 1, px: 2 }}
      >
        <Stack>
          <Stack
            flex='1 1 auto'
            sx={{
              overflowY: 'scroll',
              scrollbarWidth: 'none',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            <ExistingFlows />
            <ActiveFlow />

            {!layoutFlowsAmount && (
              <InfoText
                header={t('properties.flows.no_flows.title')}
                subHeader={t('properties.flows.no_flows.subtitle')}
                sx={{ width: '90%', margin: '0 auto' }}
              />
            )}
          </Stack>
          <Button onClick={onAddNewFlow} sx={{ mt: 2 }} disabled={mode !== WorkspaceMode.EDITABLE}>
            {t('properties.flows.add_button.label')}
          </Button>
        </Stack>
      </Accordion>
    </>
  );
};

export const FlowsPanel = memo(FlowsPanelComponent);
