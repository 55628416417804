import { getRecoilPromise } from 'recoil-nexus';
import { v4 as uuid } from 'uuid';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { map } from '@/modules/commissioning/mappers/areas/areaMapper';
import { allShapesSelector } from '@/store/recoil/shapes';
import { useCanvasStore } from '@modules/canvas';
import {
  GeneratedFloorPlanArtefacts,
  GeneratedLoadPosition,
  GeneratedLocation,
} from '@modules/floorplanService';
import { LayoutDelta } from '../helpers/types';
import { DEVTOOLS_OPTIONS } from '@/modules/debug/constants/zustand';

type FloorPlanState = {
  groupId: string;
  loadPositions: GeneratedLoadPosition[];
  locations: GeneratedLocation[];
  shapeIds: string[];
};

type FloorPlanActions = {
  initialize(artifacts: GeneratedFloorPlanArtefacts, layoutDelta?: LayoutDelta): Promise<void>;
  group(): void;
  reset(): void;
  ungroup(): void;
};

const INITIAL_STATE: FloorPlanState = {
  groupId: uuid(),
  locations: [],
  loadPositions: [],
  shapeIds: [],
};

export const useFloorPlanStore = create<FloorPlanState & FloorPlanActions>()(
  devtools(
    (set, get) => ({
      ...INITIAL_STATE,

      async initialize(artifacts) {
        const canvas = useCanvasStore.getState()?.instance;
        if (!canvas) return;

        const shapes = await getRecoilPromise(allShapesSelector);
        const elements = map(shapes, artifacts, get().groupId);
        canvas.updateElements(elements);

        set(
          {
            shapeIds: elements.map((item) => item.id),
            locations: artifacts.locations,
            loadPositions: artifacts.loadPositions,
          },
          undefined,
          {
            type: 'initialize',
          },
        );
      },

      group() {
        const canvas = useCanvasStore.getState().instance;
        const groupId = canvas.group(get().shapeIds);
        canvas.updateInteractivity(groupId, {
          rotatable: false,
        });

        set(
          {
            groupId,
          },
          undefined,
          {
            type: 'group',
          },
        );
      },

      ungroup() {
        const canvas = useCanvasStore.getState().instance;
        canvas.ungroup(get().groupId);
        // TODO deselect
      },

      reset() {
        set(INITIAL_STATE, undefined, {
          type: 'reset',
        });
      },
    }),
    { store: 'commissioning/floorPlanStore', ...DEVTOOLS_OPTIONS },
  ),
);
