import { useRecoilValue } from 'recoil';
import { navSelector } from '@recoil/nav';
import { useEffect } from 'react';
import { useModuleStore } from '@modules/commissioning/store/useModuleStore';

export const useModule = () => {
  const nav = useRecoilValue(navSelector);

  useEffect(() => {
    useModuleStore.getState().setModule(nav);
  }, [nav]);
};
