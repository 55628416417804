import { Element } from '@thive/canvas';

import { BoundingBox } from '@/helpers/types';
import { convertPointToBottomLeftPosition } from '@/modules/artefacts/helpers/convert';
import { getShapeAttachPointArea } from '@/modules/common/helpers/shapes';
import { FlowAttachPoints, LayoutFlow } from '@/modules/flows/types';
import { createFlowElement } from '@/modules/salesWorkspace/mappers/flow/flow';
import { AreaShape, DTShape } from '@/store/recoil/shape';

export const map = async (
  flows: readonly LayoutFlow[],
  shapes: readonly DTShape[],
  boundingBox: BoundingBox,
): Promise<Element[]> => {
  const elements: Element[] = mapFlowsToCanvasElement(flows, shapes, boundingBox);
  return elements;
};

const mapFlowsToCanvasElement = (
  flows: readonly LayoutFlow[],
  shapes: readonly DTShape[],
  boundingBox: BoundingBox,
): Element[] => {
  const elements: Element[] = [];

  flows.forEach((flow) => {
    const fromShape = shapes.find(shape => shape.id === flow.intakeFlowStop.id)
    const toShape = shapes.find(shape => shape.id === flow.deliveryFlowStop.id)
    
    const points: FlowAttachPoints = {
      from: convertPointToBottomLeftPosition(getShapeAttachPointArea(fromShape as AreaShape, false), boundingBox),
      to: convertPointToBottomLeftPosition(getShapeAttachPointArea(toShape as AreaShape, false), boundingBox),
    };
    
    elements.push(createFlowElement(flow, points))

  });

  return elements;
};
