import { MenuItem } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { PasteIcon } from '@/assets/icons';
import { modeSelector } from '@/modules/common/store/workspace';
import { WorkspaceMode } from '@/modules/common/types/general';
import { useContextMenu, useCopyPaste } from '@modules/workspace/hooks';
import { memoryAtom } from '@recoil/memory';
import { sizeSelector, stageRefSelector } from '@recoil/workspace';

export const PasteOption = () => {
  const { t } = useTranslation();
  const { hide } = useContextMenu();
  const { pasteFromMemory } = useCopyPaste();
  const stageRef = useRecoilValue(stageRefSelector);
  const pointerPos = stageRef.getRelativePointerPosition();
  const workspaceSize = useRecoilValue(sizeSelector);
  const editable = useRecoilValue(modeSelector) === WorkspaceMode.EDITABLE;

  const clickedOutsideStage =
    pointerPos.x < 0 ||
    pointerPos.x > workspaceSize.width / 10 ||
    pointerPos.y < 0 ||
    pointerPos.y > workspaceSize.height / 10;
  const disabled = !useRecoilValue(memoryAtom).shapeIds.length || clickedOutsideStage || !editable;

  const clickHandler = useCallback(() => {
    hide();
    pasteFromMemory({ x: pointerPos.x * 10, y: pointerPos.y * 10 });
  }, [pasteFromMemory, pointerPos.x, pointerPos.y, hide]);

  return (
    <MenuItem
      disabled={disabled}
      onClick={clickHandler}
      component='button'
      sx={{
        display: 'flex',
        gap: '14px',
        justifyContent: 'space-between',
      }}
    >
      {t('interface:context_menu.workspace.paste.paste_here')}
      <PasteIcon />
    </MenuItem>
  );
};
