import { Type, Variant } from '@/modules/visualization/enum';
import { VisualizationObject } from '@/modules/visualization/types';
import { Button, Stack, TextField } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { SOURCE_NAME } from '../../../consts';
import { EventNames, useEventBus } from '../../../hooks';
import { ValueAdjustButton } from './ValueAdjustButton';

export function GltfImport() {
  const [url, setUrl] = useState<string>(
    'https://thivefleettracker.blob.core.windows.net/unityassets/gltf/BoiseLabAisle.glb',
  );
  const [loaded, setLoaded] = useState<boolean>(false);
  const [x, setX] = useState<number>(0);
  const [y, setY] = useState<number>(0);
  const [z, setZ] = useState<number>(0);
  const { emit } = useEventBus();

  useEffect(() => {
    if (loaded) injectGltf();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [x, y, z]);

  const injectGltf = useCallback(() => {
    const payload: VisualizationObject = {
      Name: 'Gltf',
      Source: SOURCE_NAME,
      Type: Type.Gltf,
      Variant: Variant.None,
      Position: {
        X: x,
        Y: y,
        Z: z,
      },
      Data: {
        Url: url,
      },
    };
    emit(EventNames.VisualizationWebGLSend, {
      target: 'Tracker',
      action: 'UpdateVisualizationObjectJson',
      payload,
    });
    setLoaded(true);
  }, [emit, url, x, y, z]);

  return (
    <Stack spacing={2}>
      <TextField value={url} onChange={(e) => setUrl(e.target.value)} placeholder="Add URL"/>
      <Button onClick={injectGltf}>Load Gltf Object</Button>
      {loaded && (
        <Stack spacing={2} gap={1}>
          <ValueAdjustButton
            label='X'
            value={x}
            onValueChange={(v) => setX(v)}
          />
          <ValueAdjustButton
            label='Y'
            value={y}
            onValueChange={(v) => setY(v)}
          />
          <ValueAdjustButton
            label='Z'
            value={z}
            onValueChange={(v) => setZ(v)}
          />
        </Stack>
      )}
    </Stack>
  );
}
