import { useCallback } from 'react';
import { useKollmorgenStore } from '../../store';
import { useSaveData } from '../../hooks';
import { LocalizationMapType } from '../../helpers/types';
import { FileMultiList } from './FileMultiList';

export const KollmorgenUpload = () => {
  const { setFile, fileName, deleteFile } = useKollmorgenStore();
  const { deleteLocalizationMap, saveLocalizationMap } = useSaveData();

  const onFileSelected = useCallback(
    async (file: File) => {
      await setFile(file);
      await saveLocalizationMap(LocalizationMapType.Kollmorgen, file);
    },
    [setFile, saveLocalizationMap],
  );

  const onDeleteClick = useCallback(async () => {
    deleteFile();
    await deleteLocalizationMap(LocalizationMapType.Kollmorgen);
  }, [deleteFile, deleteLocalizationMap]);

  return (
    <FileMultiList
      accept='.txt'
      fileName={fileName}
      titleKey='interface:commissioning.kollmorgen.label'
      onDeleteClick={onDeleteClick}
      onFileSelected={onFileSelected}
    />
  );
};
