import { Divider, IconButton, Paper, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { CloseIcon } from '@/assets/icons';
import { StyledTooltip } from '@common/components/StyledTooltip';
import { WorkspaceOverlayPosition } from '@components/WorkspaceUi';
import { referenceScale } from '@modules/referenceImage/store';

export type Props = {
  type: 'guide' | 'reminder';
  onClose(): void;
};

export function ScaleUi({ type, onClose }: Props) {
  const { t } = useTranslation(['interface']);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState('');
  const [loaded, setLoaded] = useState(false);
  const scale = useRecoilValue(referenceScale);

  useEffect(() => {
    if (type === 'guide') {
      setTitle(t('interface:reference.scale_label'));
      setDescription(t('interface:reference.scale_instructions'));
      setImage('/scale_guide.png');
    } else {
      setTitle(t('interface:reference.scale_reminder_label'));
      setDescription(t('interface:reference.scale_reminder_instructions'));
      setImage('/scale_reminder.png');
    }
  }, [t, type]);

  useEffect(() => {
    if (scale.pointB && !scale.free) {
      onClose();
    }
  }, [onClose, scale.free, scale.pointB]);

  return (
    <WorkspaceOverlayPosition
      alignHorizontal='right'
      alignVertical='center'
      additionalStyles={{
        width: '278px',
      }}
    >
      <Paper
        elevation={1}
        sx={{
          padding: 2,
          justify: 'space-between',
          boxShadow: '0px 0px 8px 3px #00000033',
          '&:hover': { cursor: 'default' },
        }}
        style={loaded ? {} : { display: 'none' }}
      >
        <Stack spacing='10px' divider={<Divider />}>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            sx={{ height: '24px' }}
          >
            <Typography
              variant='h3'
              sx={{
                fontWeight: 600,
              }}
            >
              {title}
            </Typography>
            <StyledTooltip title='Close'>
              <IconButton onClick={onClose} sx={{ p: 0 }}>
                <CloseIcon />
              </IconButton>
            </StyledTooltip>
          </Stack>
          <img src={image} alt='' onLoad={() => setLoaded(true)} />
          <Typography variant='h5'>{description}</Typography>
        </Stack>
      </Paper>
    </WorkspaceOverlayPosition>
  );
}
