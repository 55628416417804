import { Input } from '@common/components/Input';
import { PropertiesLabel } from '@common/components/PropertiesLabel';
import { modeSelector } from '@/modules/common/store/workspace';
import { WorkspaceMode } from '@/modules/common/types/general';
import { contextState, CONTEXT } from '@/store/recoil/input';
import { unitSelector } from '@/store/recoil/workspace';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useFloorPlanState } from '@/modules/floorplan';
import { loadElevation } from '../store/area';
import { useCallback } from 'react';

export const LoadElevation = () => {
  const unit = useRecoilValue(unitSelector);
  const { t } = useTranslation('interface');
  const mode = useRecoilValue(modeSelector);
  const setContext = useSetRecoilState(contextState);
  const { saveFloorPlan } = useFloorPlanState();
  const [elevation, setElevation] = useRecoilState(loadElevation);

  const onChange = useCallback(
    async (value: number) => {
      await setElevation(value);
      await saveFloorPlan();
    },
    [saveFloorPlan, setElevation],
  );

  return (
    <PropertiesLabel i18nkey='interface:properties.area.load_elevation'>
      <Input
        value={elevation}
        unit={t(`interface:settings.units.${unit}`, unit)}
        disabled={mode !== WorkspaceMode.EDITABLE}
        mode='positive-integer'
        onChange={onChange}
        onFocus={() => setContext(CONTEXT.PROPERTYPANEL)}
        onBlur={() => setContext(CONTEXT.WORKSPACE)}
      />
    </PropertiesLabel>
  );
};
