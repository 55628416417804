import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { memo, useCallback } from 'react';

import { unitSelector, unitValueSelector } from '@/store/recoil/workspace';
import { Accordion } from '@common/components/Accordion';
import { Input } from '@common/components/Input';
import { PropertiesLabel } from '@modules/common/components/PropertiesLabel';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { useFloorPlanState } from '@modules/floorplan/hooks/useFloorPlanState';
import { CONTEXT, contextState } from '@recoil/input';
import { useArtefacts } from '@/modules/artefacts';
import { selectedShapesIdsState } from '@/store/recoil/shapes/selected';
import { ProcessEndpointDirection } from './ProcessEndpointDirection';
import { processSideLoadingDeltaX, processSideLoadingDeltaY } from '../../store/process';

export type Props = {
  type: 'deliveryParameters' | 'intakeParameters';
};

const ProcessSideLoadingPropertiesComponent = ({ type }: Props) => {
  const { saveFloorPlan } = useFloorPlanState();
  const unit = useRecoilValue(unitSelector);
  const { t } = useTranslation('interface');
  const setContext = useSetRecoilState(contextState);
  const mode = useRecoilValue(modeSelector);
  const { updateDebounced: updateArtefacts } = useArtefacts();
  const ids = useRecoilValue(selectedShapesIdsState);
  const [deltaX, setDeltaX] = useRecoilState(processSideLoadingDeltaX(type));
  const [deltaY, setDeltaY] = useRecoilState(processSideLoadingDeltaY(type));

  const onDeltaXChange = useCallback(
    (value: number) => {
      if (value != null) {
        setDeltaX(value);
        saveFloorPlan();
        updateArtefacts(ids);
      }
    },
    [setDeltaX, saveFloorPlan, updateArtefacts, ids],
  );

  const onDeltaYChange = useCallback(
    (value: number) => {
      if (value != null) {
        setDeltaY(value);
        saveFloorPlan();
        updateArtefacts(ids);
      }
    },
    [setDeltaY, saveFloorPlan, updateArtefacts, ids],
  );

  return (
    <Accordion
      title={t('interface:properties.area.sideloading.label', 'Side Loading Offset Settings')}
      titleSx={{ textTransform: 'capitalize' }}
      defaultExpanded
    >
      <Stack direction='column' spacing={1}>
        <PropertiesLabel i18nkey='interface:properties.area.sideloading.endpoint_direction.label'>
          <ProcessEndpointDirection type={type} />
        </PropertiesLabel>
        <PropertiesLabel i18nkey='interface:properties.area.sideloading.deltaX'>
          <Input
            value={useRecoilValue(unitValueSelector(deltaX))}
            unit={t(`interface:settings.units.${unit}`, unit)}
            disabled={mode !== WorkspaceMode.EDITABLE}
            onChange={onDeltaXChange}
            onFocus={() => setContext(CONTEXT.PROPERTYPANEL)}
            onBlur={() => setContext(CONTEXT.WORKSPACE)}
          />
        </PropertiesLabel>
        <PropertiesLabel i18nkey='interface:properties.area.sideloading.deltaY'>
          <Input
            value={useRecoilValue(unitValueSelector(deltaY))}
            unit={t(`interface:settings.units.${unit}`, unit)}
            disabled={mode !== WorkspaceMode.EDITABLE}
            onChange={onDeltaYChange}
            onFocus={() => setContext(CONTEXT.PROPERTYPANEL)}
            onBlur={() => setContext(CONTEXT.WORKSPACE)}
          />
        </PropertiesLabel>
      </Stack>
    </Accordion>
  );
}

const ProcessSideLoadingProperties = memo(ProcessSideLoadingPropertiesComponent);
export default ProcessSideLoadingProperties;
