import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../../common';
import { relatedShapesSelector } from './relatedShapesSelector';
import { hasFlowInteractivity } from '@/modules/flows/consts';

export const showRelatedFlowsVisibilityOptionSelector = selector<boolean>({
  key: 'contextMenu/workspace/option/relatedFlowsVisibility/show',
  get: ({ get }) => {
    const showOption = get(relatedShapesSelector).some((shape: any) =>
      hasFlowInteractivity(shape.type),
    );
    return showOption;
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
