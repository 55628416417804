import { selector } from 'recoil';
import { StageProps } from '@helpers/types';

import { stageSelector } from './stageSelector';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../common';

export const scaleSelector = selector<number>({
  key: 'scaleSelector',
  get: ({ get }) => get<StageProps>(stageSelector).scale,
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
