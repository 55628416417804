import { create } from 'zustand';
import { ConnectionShape, UnitShapeId } from '../helpers/types';

type SelectionState = {
  connections: ConnectionShape[];
  unitIds: UnitShapeId[];
};

type SelectionAction = {
  setConnections(connections: ConnectionShape[]): void;
  setUnitIds(unitIds: UnitShapeId[]): void;
  reset(): void;
};

const INITIAL_STATE: SelectionState = {
  connections: [],
  unitIds: [],
};

export const useSelectionStore = create<SelectionState & SelectionAction>((set) => ({
  ...INITIAL_STATE,
  setConnections: (connections) => set({ connections }),
  setUnitIds: (unitIds) => set({ unitIds }),
  reset: () => set({ connections: [], unitIds: [] }),
}));
