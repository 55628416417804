import { TextInput } from '@common/components/inputs';
import { Theme } from '@modules/common/types/general';
import { UserPreferenceName } from '@modules/userPreferences';
import { useUserPreference } from '@modules/userPreferences/hooks';
import { Dialog, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { Stack } from '@mui/system';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { KEYCODE } from '../../store/recoil/input';
import Label from '../common/components/Label';
import { useNavigation } from '../common/hooks';
import { floorplanIdBeingDuplicatedState, isDuplicatorOpenState } from './store';
import { nameSelector } from './store/floorPlanGroup';
import { useFloorPlanGroupApi } from '@modules/api/hooks';
import { useParams } from 'react-router-dom';

export default function FloorplanDuplicator() {
  const { t } = useTranslation(['interface', 'common']);
  const { goToBuilder } = useNavigation();
  const { projectId } = useParams();
  const { duplicate } = useFloorPlanGroupApi();

  const buttonRef = useRef(null);
  const floorPlanGroupName = useRecoilValue(
    nameSelector(useRecoilValue(floorplanIdBeingDuplicatedState)),
  );
  const resetFloorplanIdBeingDuplicated = useResetRecoilState(floorplanIdBeingDuplicatedState);
  const isDuplicatorOpen = useRecoilValue(isDuplicatorOpenState);
  const [textInput, setTextInput] = useState('');
  const setIsDuplicatorOpen = useSetRecoilState(isDuplicatorOpenState);
  const theme = useUserPreference(UserPreferenceName.GENERAL_THEME);

  useEffect(() => {
    if (floorPlanGroupName) setTextInput(`${floorPlanGroupName} - Copy`);
  }, [floorPlanGroupName]);

  const handleClose = useCallback(() => {
    setIsDuplicatorOpen(false);
    resetFloorplanIdBeingDuplicated();
  }, [resetFloorplanIdBeingDuplicated, setIsDuplicatorOpen]);

  const onDuplicateClick = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const selectedFloorPlanGroupId = await snapshot.getPromise(floorplanIdBeingDuplicatedState);

        try {
          const group = await duplicate(projectId, selectedFloorPlanGroupId, textInput);
          goToBuilder(group.id, group.floorPlanId);
        } catch (e) {
          alert(`Error: ${e}`);
        }
      },
    [projectId, duplicate, textInput, goToBuilder],
  );

  const handleTextInputChange = useCallback((value) => {
    setTextInput(value);
  }, []);

  const keyUpHandler = useCallback((keyboardEvent) => {
    if (keyboardEvent.key === KEYCODE.ENTER) {
      if (buttonRef?.current && !buttonRef.current.disabled) {
        buttonRef.current.click();
      }
    }
  }, []);

  useEffect(() => {
    if (isDuplicatorOpen) {
      window.addEventListener('keyup', keyUpHandler);
    } else {
      window.removeEventListener('keyup', keyUpHandler);
    }
  }, [isDuplicatorOpen, keyUpHandler]);

  useEffect(
    () => () => {
      handleClose();
    },
    [handleClose],
  );

  return (
    <Dialog
      onClose={handleClose}
      open={isDuplicatorOpen}
      className={theme === Theme.DARK ? 'dark' : ''}
    >
      <Stack gap={2} p={2}>
        <Typography variant='h6' align='center'>
          {t('interface:home.duplicate_floorplan', 'Duplicate floorplan')}
        </Typography>
        <Label label={t('interface:home.name', 'Name')}>
          <TextInput value={textInput} onChange={handleTextInputChange} autoFocus />
        </Label>
        <Stack gap={1}>
          <Button
            ref={buttonRef}
            variant='secondary'
            onClick={onDuplicateClick}
            disabled={textInput.length <= 0}
          >
            {t('interface:home.duplicate_button', 'Duplicate Floorplan')}
          </Button>
          <Button onClick={handleClose}>{t('common:cancel', 'Cancel')}</Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}
