import { KonvaEventObject } from 'konva/lib/Node';
import { useCallback, useRef } from 'react';
import { Snapshot, useRecoilCallback } from 'recoil';
import { v4 as uuid } from 'uuid';

import { useArtefacts } from '@/modules/artefacts';
import { ControlPoint } from '@/modules/common/types/shapes';
import { useConnections } from '@/modules/connections';
import { useWorkspaceStore } from '@/modules/workspace/store';
import {
  snapToClosestAxis,
} from '@modules/angledHighways/helpers';
import { AngledHighwayShape } from '@modules/angledHighways/types';
import { useAutoSave } from '@modules/floorplan';
import {
  CANVAS_TO_SHAPE_SCALE,
  ElementName,
  getRelativeMousePosition,
} from '@modules/workspace/helpers/konva';
import { clampInsideWorkspace } from '@modules/workspace/helpers/shape';
import { KEYCODE, keyboardState } from '@recoil/input';
import shapeAtom from '@recoil/shape/atom';
import { sizeSelector } from '@recoil/workspace';
import { Vector2 } from 'three';
import { useControlPointCallbacks } from './useControlPointCallbacks';
import { useControlPointHistoryTracking } from './useControlPointHistoryTracking';
import { WallShape } from '@/store/recoil/shape';
import { ProcessTwoEPShape } from '@/modules/processTwoEndPoint';
import { retreiveCPIdFromCPAnchorId, retreiveNeighbourIdsFromPotentialCPAnchorId } from '@/modules/shapes/controlPointsShapes/helpers';
import { PreValidationAspect, useRunPreValidation } from '@/modules/floorplanValidation/clientSide';

// TODO: further extract point shapes related stuff out of angled highways module

export const useSelectMouseHandlerWithPoints = () => {
  const { insertControlPoint, updateControlPointPos } = useControlPointCallbacks();
  const { trackControlPointHistory } = useControlPointHistoryTracking();
  const { save } = useAutoSave();
  const { updateConnections } = useConnections();
  const { update: updateArtefacts } = useArtefacts();
  const { runPreValidation } = useRunPreValidation();

  const insertingControlPointMetaData = useRef<{
    newControlPointId: string;
    originalControlPoints: ControlPoint[];
    shapeId: string;
    width: number;
  } | null>(null);

  const updatingControlPointMetaData = useRef<{
    controlPointId: string;
    originalControlPoints: ControlPoint[];
    shapeId: string;
    width: number;
    hasMoved: boolean;
  } | null>(null);

  const controlPointUpdater = useCallback(
    async (
      e: KonvaEventObject<MouseEvent>,
      snapshot: Snapshot,
      shapeId: string,
      controlPointId: string,
      shapeWidth: number,
    ) => {
      let { x, y } = getRelativeMousePosition(e);
      const mousePosition = new Vector2(
        Math.round(x * CANVAS_TO_SHAPE_SCALE),
        Math.round(y * CANVAS_TO_SHAPE_SCALE),
      );

      let pos = mousePosition;

      // snap position for edge controlpoints to neighbor point
      const pressedKey = await snapshot.getPromise(keyboardState);
      if (pressedKey === KEYCODE.SHIFT) {
        const controlPoints = updatingControlPointMetaData.current.originalControlPoints;
        const controlPoint = controlPoints.find(
          (controlPoint) => controlPoint.id === controlPointId,
        );
        if (controlPoint.prev === null) {
          const referencePoint = controlPoints[1];
          pos = snapToClosestAxis(referencePoint.position, mousePosition);
        } else if (controlPoint.next === null) {
          const referencePoint = controlPoints[controlPoints.length - 2];
          pos = snapToClosestAxis(referencePoint.position, mousePosition);
        }
      }
      const workspaceSize = await snapshot.getPromise(sizeSelector);
      const clampedPos = clampInsideWorkspace(pos, workspaceSize, shapeWidth / 2);
      updateControlPointPos(shapeId, controlPointId, clampedPos);
    },
    [updateControlPointPos],
  );

  const onSelectMouseDownWithPointsShape = useRecoilCallback(
    ({ snapshot, set }) =>
      async (e: KonvaEventObject<MouseEvent>) => {
        const targetId = e.target.id();
        const targetName = e.target.name();

        if (targetName === ElementName.CONTROL_POINT) {
          // mouseDown on established control point

          const controlPointId = retreiveCPIdFromCPAnchorId(targetId);
          // const highwayInEditModeId = await snapshot.getPromise(highwayInEditModeIdState);
          const shapeInEditModeId = useWorkspaceStore.getState().shapeInEditModeIdState;
          const shape = (await snapshot.getPromise(shapeAtom(shapeInEditModeId))) as
            | AngledHighwayShape
            | WallShape
            | ProcessTwoEPShape;

          updatingControlPointMetaData.current = {
            shapeId: shapeInEditModeId,
            controlPointId,
            hasMoved: false,
            originalControlPoints: shape.properties.controlPoints,
            width: shape.parameters.width,
          };

          set(
            shapeAtom(shapeInEditModeId),
            (
              current: AngledHighwayShape | WallShape | ProcessTwoEPShape,
            ): AngledHighwayShape | WallShape | ProcessTwoEPShape => ({
              ...current,
              isDrawing: true,
            }),
          );
        } else if (targetName === ElementName.POTENTIAL_CONTROL_POINT) {
          // mouseDown on potential (unestablished) control point

          let { x, y } = getRelativeMousePosition(e);
          const position = new Vector2(
            Math.round(x * CANVAS_TO_SHAPE_SCALE),
            Math.round(y * CANVAS_TO_SHAPE_SCALE),
          );
          const shapeId = useWorkspaceStore.getState().shapeInEditModeIdState;
          const shape = (await snapshot.getPromise(shapeAtom(shapeId))) as
            | AngledHighwayShape
            | WallShape
            | ProcessTwoEPShape;
          const { prevCPId, nextCPId } = retreiveNeighbourIdsFromPotentialCPAnchorId(targetId);

          insertingControlPointMetaData.current = {
            newControlPointId: uuid(),
            originalControlPoints: shape.properties.controlPoints,
            width: shape.parameters.width,
            shapeId,
          };

          insertControlPoint(
            insertingControlPointMetaData.current.shapeId,
            insertingControlPointMetaData.current.newControlPointId,
            prevCPId,
            nextCPId,
            position,
          );

          set(
            shapeAtom(shapeId),
            (
              current: AngledHighwayShape | WallShape | ProcessTwoEPShape,
            ): AngledHighwayShape | WallShape | ProcessTwoEPShape => ({
              ...current,
              isDrawing: true,
            }),
          );
        }
      },
    [insertControlPoint],
  );

  const onSelectMouseMoveWithPointsShape = useRecoilCallback(
    ({ snapshot }) =>
      (e: KonvaEventObject<MouseEvent>) => {
        if (insertingControlPointMetaData.current?.newControlPointId) {
          controlPointUpdater(
            e,
            snapshot,
            insertingControlPointMetaData.current.shapeId,
            insertingControlPointMetaData.current.newControlPointId,
            insertingControlPointMetaData.current.width,
          );

          return;
        }

        if (updatingControlPointMetaData.current) {
          if (!updatingControlPointMetaData.current.hasMoved) {
            updatingControlPointMetaData.current = {
              ...updatingControlPointMetaData.current,
              hasMoved: true,
            };
          }

          controlPointUpdater(
            e,
            snapshot,
            updatingControlPointMetaData.current.shapeId,
            updatingControlPointMetaData.current.controlPointId,
            updatingControlPointMetaData.current.width,
          );
        }
      },
    [],
  );

  const onSelectMouseUpWithPointsShape = useRecoilCallback(
    ({ snapshot, set }) =>
      async (e: KonvaEventObject<MouseEvent>) => {
        if (insertingControlPointMetaData.current?.newControlPointId) {
          controlPointUpdater(
            e,
            snapshot,
            insertingControlPointMetaData.current.shapeId,
            insertingControlPointMetaData.current.newControlPointId,
            insertingControlPointMetaData.current.width,
          );

          set(
            shapeAtom(insertingControlPointMetaData.current.shapeId),
            (
              current: AngledHighwayShape | WallShape | ProcessTwoEPShape,
            ): AngledHighwayShape | WallShape | ProcessTwoEPShape => ({
              ...current,
              isDrawing: false,
            }),
          );

          const shape = (await snapshot.getPromise(
            shapeAtom(insertingControlPointMetaData.current.shapeId),
          )) as AngledHighwayShape | WallShape | ProcessTwoEPShape;

          trackControlPointHistory(
            {
              shapeId: insertingControlPointMetaData.current.shapeId,
              value: shape.properties.controlPoints,
            },
            {
              shapeId: shape.id,
              value: insertingControlPointMetaData.current.originalControlPoints,
            },
          );

          updateConnections([shape.id]);
          updateArtefacts([shape.id]);
          save();
          insertingControlPointMetaData.current = null;
        } else if (updatingControlPointMetaData.current) {
          if (updatingControlPointMetaData.current.hasMoved) {
            controlPointUpdater(
              e,
              snapshot,
              updatingControlPointMetaData.current.shapeId,
              updatingControlPointMetaData.current.controlPointId,
              updatingControlPointMetaData.current.width,
            );

            set(
              shapeAtom(updatingControlPointMetaData.current.shapeId),
              (
                current: AngledHighwayShape | WallShape | ProcessTwoEPShape,
              ): AngledHighwayShape | WallShape | ProcessTwoEPShape => ({
                ...current,
                isDrawing: false,
              }),
            );

            const shape = (await snapshot.getPromise(
              shapeAtom(updatingControlPointMetaData.current.shapeId),
            )) as AngledHighwayShape | WallShape | ProcessTwoEPShape;

            trackControlPointHistory(
              {
                shapeId: updatingControlPointMetaData.current.shapeId,
                value: shape.properties.controlPoints,
              },
              {
                shapeId: updatingControlPointMetaData.current.shapeId,
                value: updatingControlPointMetaData.current.originalControlPoints,
              },
            );

            updateConnections([shape.id]);
            updateArtefacts([shape.id]);
            runPreValidation([
              PreValidationAspect.REQUIRED_ELEMENTS,
              PreValidationAspect.FLOWLESS_AREAS,
            ]);
          }

          save();
          updatingControlPointMetaData.current = null;
        }
      },
    [controlPointUpdater, trackControlPointHistory, save, updateConnections, updateArtefacts, runPreValidation],
  );

  const onDoubleClickWithPointsShape = useCallback((e: KonvaEventObject<MouseEvent>) => {
    const targetId = e.target.id();
    useWorkspaceStore.getState().setShapeInEditModeIdState(targetId);
  }, []);

  return {
    insertingControlPointMetaData,
    updatingControlPointMetaData,
    onSelectMouseDownWithPointsShape,
    onSelectMouseMoveWithPointsShape,
    onSelectMouseUpWithPointsShape,
    onDoubleClickWithPointsShape,
  };
};
