import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../common';
import inputAtom from './atom';
import { KEYCODE, KeyCodeType } from './constants';

const ignoreKeyboardInputState = selector<KeyCodeType[]>({
  key: 'input/ignorekeyboardState',
  get: ({ get }) => get(inputAtom).ignoreKeyboardKeys,
  set: ({ get, set }, newValue: KeyCodeType[]) => {
    set(inputAtom, { ...get(inputAtom),  keyboard: KEYCODE.NONE,
		lastKeyboard: KEYCODE.NONE, ignoreKeyboardKeys: newValue });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default ignoreKeyboardInputState;
