import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import { REFERENCE_TYPE } from '../types';
import referenceType from './referenceType';

const isReferencePresentSelector = selector<boolean>({
  key: 'isReferencePresent',
  get: ({ get }) => get(referenceType) !== REFERENCE_TYPE.NONE,
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default isReferencePresentSelector;
