import { useAxios } from '@/modules/api/hooks/useAxios';
import { useConfig } from '@/modules/common/hooks';
import { useCallback, useMemo } from 'react';
import { WifiSimulationInput } from '../types';

export const useWifiSimulationApi = () => {
  const { api } = useConfig();
  const scopes = useMemo(() => [api.wifiSimulation.scope], [api.wifiSimulation.scope]);
  const axios = useAxios({
    baseURL: `${api.wifiSimulation.url}`,
    scopes,
  });

  const simulate = useCallback(
    (input: WifiSimulationInput) => axios.post(`/simulation/start`, input),
    [axios],
  );

  return {
    simulate,
  };
};
