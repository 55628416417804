import { selectorFamily } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@/store/recoil/common';
import { vehicleNameSelector } from '../vehicleNameSelector';

export const supportedVehiclesNameSelector = selectorFamily<string[], string[]>({
  key: 'vehicles/supported/names',
  get:
    (supportedVehicleIds: string[]) =>
    ({ get }) =>
      supportedVehicleIds.map((id) => get(vehicleNameSelector(id))),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
