import { useTranslation } from 'react-i18next';
import { ConfirmDialog } from '@common/components/ConfirmDialog';

type Props = {
  open: boolean;
  runName: string;
  onConfirm: () => void;
  onCancel: () => void;
};

export function AbortConfirmDialog({ open, runName, onConfirm, onCancel }: Props) {
  const { t } = useTranslation('interface');

  return (
    <ConfirmDialog
      open={open}
      title={t('simulation.abort_dialog.title', { runName })}
      content={t('simulation.abort_dialog.content')}
      yesLabel={t('simulation.abort_dialog.yes_button.label')}
      noLabel={t('simulation.abort_dialog.no_button.label')}
      onConfirm={onConfirm}
      onDecline={onCancel}
    />
  );
}
