import { loginRequest } from '@/modules/authentication/authConfig';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useCallback, useEffect } from 'react';

export const useLoginPage = () => {
  const { instance } = useMsal();

  const signIn = useCallback(async () => {
    await instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  }, [instance]);

  const silentSignIn = useCallback(async () => {
    const request = {
      ...loginRequest,
    };
    try {
      await instance.ssoSilent(request);
    } catch (err) {
      if (err instanceof InteractionRequiredAuthError) {
        console.log(err);
        await signIn();
      } else {
        console.error(err);
      }
    }
  }, [instance, signIn]);

  useEffect(() => {
    silentSignIn();
  }, [silentSignIn]);

  return {
    signIn,
    silentSignIn,
  };
};
