import { useCallback } from 'react';
import { Stack } from '@mui/material';

import { Simulation, SimulationStatus } from '../../../helpers/types';
import { useSimulationCallbacks } from '../../../hooks';
import { HeaderTitle } from '../../HeaderTitle';
import { CompletedDuration } from '../../CompletedDuration';
import { BottomButtons } from '../../BottomButtons';
import { CreatedCompletedDatesRow } from '../../CreatedCompletedDatesRow';
import { VersionVehicleRow } from '../../VersionVehicleRow';

type Props = {
  simulation: Simulation;
  isHover: boolean;
  onClick: () => void;
};

export const CardContent = ({ simulation, isHover, onClick }: Props) => {
  const { abort, rename } = useSimulationCallbacks();
  const onAbortConfirm = useCallback(async () => await abort(simulation), [simulation, abort]);
  const onChange = useCallback((name: string) => rename(simulation, name), [simulation, rename]);

  return (
    <Stack sx={{ gap: 1 }}>
      <HeaderTitle
        editable={
          simulation.status !== SimulationStatus.DELETING &&
          simulation.status !== SimulationStatus.SAVING
        }
        onChange={onChange}
      >
        {simulation.name}
      </HeaderTitle>
      <Stack sx={{ flexDirection: 'row', gap: 1, width: '100%' }}>
        <Stack sx={{ gap: 1, width: '100%' }} onClick={onClick}>
          <VersionVehicleRow simulation={simulation} />
          <CreatedCompletedDatesRow simulation={simulation} />
          <CompletedDuration simulation={simulation} />
        </Stack>
        {isHover && <BottomButtons simulation={simulation} onAbortConfirm={onAbortConfirm} />}
      </Stack>
    </Stack>
  );
};
