import { Line, Star } from 'react-konva';
import { useRecoilValue } from 'recoil';

import { theme } from '@/modules/common/components/theme';
import { SnapLine } from '@/modules/snapping/types';
import { scaleSelector } from '@/store/recoil/workspace';

const DOWNSIZE_ABOVE = 0.1;
const SNAP_STYLE = {
  LINE: {
    THIN: 0.5,
    THICK: 1,
  },
  CROSS: {
    SMALL: 3,
    LARGE: 5,
  },
};

type SnapRendererProps = {
  lines: SnapLine[];
};

export const SnapRenderer = ({ lines }: SnapRendererProps) => {
  const scale = useRecoilValue(scaleSelector);

  let crossSize = SNAP_STYLE.CROSS.LARGE;
  let lineThickness = SNAP_STYLE.LINE.THICK;

  if (scale > DOWNSIZE_ABOVE) {
    crossSize = SNAP_STYLE.CROSS.SMALL;
    lineThickness = SNAP_STYLE.LINE.THIN;
  }

  return (
    <>
      {lines.map((line) => (
        <>
          <Line
            points={[line.x1, line.y1, line.x2, line.y2]}
            key={`${line.x1}-${line.y1}-${line.x2}-${line.y2}`}
            stroke={theme.palette.others.red}
            strokeWidth={lineThickness}
            lineCap='butt'
            listening={false}
            strokeScaleEnabled={false}
          />
          {line.points.map((item) => (
            <Star
              x={item.x}
              y={item.y}
              key={`${item.x}-${item.y}`}
              numPoints={4}
              innerRadius={crossSize}
              outerRadius={0}
              stroke={theme.palette.others.red}
              listening={false}
              strokeWidth={1}
              strokeScaleEnabled={false}
              scaleX={1 / scale}
              scaleY={1 / scale}
            />
          ))}
        </>
      ))}
    </>
  );
};
