import { Vector2 } from 'three';

import { BoundingBox } from '@/helpers/types';
import { convertMetricUnit } from '@/helpers/units/metric';
import { rotateVector } from '@/modules/common/helpers/math';
import { Unit } from '@/modules/common/types/general';
import { ShapeType } from '@modules/common/types/shapes';
import { SOURCE_NAME } from '../consts';
import { Type, Variant } from '../enum';
import { ArgbColor, Polyline, Vector3, VisualizationObject } from '../types';

export function hasSafetyZone(shapeType: string): boolean {
  return (
    shapeType === ShapeType.INTAKE ||
    shapeType === ShapeType.STORAGE ||
    shapeType === ShapeType.DELIVERY ||
    shapeType === ShapeType.PROCESS_ONE_EP ||
    shapeType === ShapeType.CHARGING ||
    shapeType === ShapeType.OBSTACLE
  );
}

const SAFETY_ZONE_SIZE = 0;
const SAFETY_ZONE_HEIGHT = 10;
const POLYLINE_WIDTH_MM = 100;
export const SAFETY_ZONE_TAG = 'Safety Zone';
export const SAFETY_ZONE_COLOR: ArgbColor = { Alpha: 255, R: 255, G: 192, B: 16 };

export function boundingBoxToSafetyZone(inBoxes: BoundingBox): BoundingBox {
  return {
    width: inBoxes.width + SAFETY_ZONE_SIZE * 2,
    height: inBoxes.height + SAFETY_ZONE_SIZE * 2,
    x: inBoxes.x - SAFETY_ZONE_SIZE,
    y: inBoxes.y - SAFETY_ZONE_SIZE,
  };
}

export function safetyZoneBoxToPolyline(box: BoundingBox): Polyline {
  // v4 ---- v3
  //  |      |
  //  |      |
  // v1 ---- v2
  const gapToClosure = convertMetricUnit(POLYLINE_WIDTH_MM, Unit.MILLIMETERS, Unit.MILLIMETERS);
  const v1: Vector3 = { X: box.x, Y: box.y, Z: SAFETY_ZONE_HEIGHT };
  const v2: Vector3 = { X: box.x + box.width, Y: box.y, Z: SAFETY_ZONE_HEIGHT };
  const v3: Vector3 = { X: box.x + box.width, Y: box.y + box.height, Z: SAFETY_ZONE_HEIGHT };
  const v4: Vector3 = { X: box.x, Y: box.y + box.height, Z: SAFETY_ZONE_HEIGHT };
  const v5: Vector3 = { X: box.x - gapToClosure, Y: box.y - gapToClosure, Z: SAFETY_ZONE_HEIGHT };
  return [v1, v2, v3, v4, v5];
}

export function safetyZoneRectangleToPolyline(center: Vector2, width: number, height: number, angle: number): Polyline {
  // v4 ---- v3
  //  |      |
  //  |      |
  // v1 ---- v2
  const v1: Vector2 = rotateVector(new Vector2(-width/2, -height/2), angle).add(center)
  const v2: Vector2 = rotateVector(new Vector2(width/2, -height/2), angle).add(center)
  const v3: Vector2 = rotateVector(new Vector2(width/2, height/2), angle).add(center)
  const v4: Vector2 = rotateVector(new Vector2(-width/2, height/2), angle).add(center)
  const v5: Vector2 = rotateVector(new Vector2(-width/2, -height/2), angle).add(center)
  const v6: Vector2 = rotateVector(
    new Vector2(-width/2 + convertMetricUnit(POLYLINE_WIDTH_MM, Unit.MILLIMETERS, Unit.METERS), 
    -height/2), 
    angle
  ).add(center) // added to make zone uniform

  return [
    { X: v1.x, Y: v1.y, Z: SAFETY_ZONE_HEIGHT }, 
    { X: v2.x, Y: v2.y, Z: SAFETY_ZONE_HEIGHT }, 
    { X: v3.x, Y: v3.y, Z: SAFETY_ZONE_HEIGHT }, 
    { X: v4.x, Y: v4.y, Z: SAFETY_ZONE_HEIGHT }, 
    { X: v5.x, Y: v5.y, Z: SAFETY_ZONE_HEIGHT }, 
    { X: v6.x, Y: v6.y, Z: SAFETY_ZONE_HEIGHT }, 
  ];
}

export function buildSafetyZonePayload(name: string, polyline: Polyline): VisualizationObject {
  return {
    Type: Type.Path,
    Variant: Variant.Polyline,
    Source: SOURCE_NAME,
    Name: name,
    Color: SAFETY_ZONE_COLOR,
    Tags: [SAFETY_ZONE_TAG],
    Data: {
      ControlPoints: polyline,
      Width: convertMetricUnit(POLYLINE_WIDTH_MM, Unit.MILLIMETERS, Unit.METERS),
    },
  };
}
