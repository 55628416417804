import { useEffect } from 'react';

import { useNavigation } from '@/modules/common/hooks';
import { showDebugProjectView } from '@/modules/debug';
import { ProjectsDashboard } from '@/modules/debug/components';
import { ModalManager } from '@common/components/ModalManager';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';

export function RedirectToPortal() {
  const { goToPortal } = useNavigation();

  useEffect(() => {
    if (!showDebugProjectView) {
      goToPortal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showDebugProjectView && (
        <RecoilRoot>
          <RecoilNexus />
          <ProjectsDashboard />
          <ModalManager />
        </RecoilRoot>
      )}
    </>
  );
}
