import { MathUtils, Vector2, Vector3 } from 'three';
import { DEG2RAD } from 'three/src/math/MathUtils';

export const cross = (a: Vector2, b: Vector2): number => a.x * b.y - a.y * b.x;

export const isFloatingPointEqual = (a: number, b: number, tolerance = 10): boolean => {
  const delta = Math.abs(a - b);
  return delta < tolerance;
};

export const rotateVector = (v: Vector2, angleDegree: number): Vector2 => {
  const angle = angleDegree * DEG2RAD;
  return new Vector2(
    v.x * Math.cos(angle) - v.y * Math.sin(angle),
    v.x * Math.sin(angle) + v.y * Math.cos(angle),
  );
};

export const getVectorRotatedAroundPoint = (
  vectorToRotate: Vector2,
  centerOfRotation: Vector2,
  angleInDegrees: number,
): Vector2 => {
  // TODO:
  // NOTE: reuse rotateAround method from three.js

  const angleInRadians = MathUtils.degToRad(angleInDegrees);
  const c = Math.cos(angleInRadians);
  const s = Math.sin(angleInRadians);

  const x = vectorToRotate.x - centerOfRotation.x;
  const y = vectorToRotate.y - centerOfRotation.y;

  const newX = x * c - y * s + centerOfRotation.x;
  const newY = x * s + y * c + centerOfRotation.y;

  return vectorToRotate.clone().setX(newX).setY(newY);
};

export const getMiddlePoint = (p1: Vector2, p2: Vector2): Vector2 =>
  new Vector2().lerpVectors(p1, p2, 0.5);

export const mod = (n: number, m: number) => ((n % m) + m) % m;

/**
 * Clamps the angle within a 0 - 180 and -179 - 0 range
 */
export const clampAngle180 = (angle: number) => {
  const modAngle = mod(angle, 360);
  return modAngle > 180 ? modAngle - 360 : modAngle;
};

export const convertV2ToV3 = (
  vector: Vector2,
  z = 0
): Vector3 => new Vector3(vector.x,vector.y, z);
