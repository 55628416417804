import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import { versionAtom, versionIdsAtom } from './versionsAtom';
import { Version } from '../../helpers/types';

export const numberSelectedVersionsSelector = selector<Version[]>({
    key: 'versioning/versions/numberSelected',
    get: ({ get }) => get(versionIdsAtom).map((item) => get(versionAtom(item))).filter((item) => item.isSelected),
    cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT
});
