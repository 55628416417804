import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UploadUi } from '../../../components/ReferenceUi/UploadUi';

export const UploadReferenceButton = () => {
  const { t } = useTranslation(['interface']);

  return (
    <Stack alignItems='stretch' width='100%' gap={1}>
      <UploadUi />
      <Typography variant='caption' sx={{ display: 'block' }}>
        {t(
          'interface:reference.upload_model.formats_text',
          'Allowed formats: JPG, PNG, PDF or DWG',
        )}
      </Typography>
    </Stack>
  );
};
