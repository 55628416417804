import { referenceMode, referenceType } from '@modules/referenceImage/store';
import { Mode, REFERENCE_TYPE } from '@modules/referenceImage/types';
import { useRequiredReferenceScaling } from '@modules/setup/hooks/useRequiredReferenceScaling';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ScaleUi } from './ScaleUi';

export const ReferenceUi = () => {
  const [interfaceState, setInterfaceState] = useState<Mode>();
  const type = useRecoilValue(referenceType);
  const modeState = useRecoilValue(referenceMode);
  const [guideOpen, setGuideOpen] = useState(true);
  const { onClose, isDialogOpen } = useRequiredReferenceScaling();

  const onCloseGuide = useCallback(() => {
    setGuideOpen(false);
  }, []);

  useEffect(() => {
    setInterfaceState(type === REFERENCE_TYPE.NONE ? Mode.UPLOAD : modeState);
    setGuideOpen(true);
  }, [type, modeState]);

  const getInterface = (interfaceState: any) => {
    if (isDialogOpen) {
      return <ScaleUi type='reminder' onClose={onClose} />;
    }
    if (interfaceState === Mode.SCALE && guideOpen) {
      return <ScaleUi type='guide' onClose={onCloseGuide} />;
    }

    return null;
  };

  return getInterface(interfaceState);
};
