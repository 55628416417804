import { Stack, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';

import { referenceStatus, referenceType } from '../../../store';
import { REFERENCE_TYPE, Status } from '../../../types';
import { FileStatus } from './FileStatus';
import { UploadReferenceButton } from './UploadReferenceButton';

export function FilePanel() {
  const { t } = useTranslation(['interface']);
  const status = useRecoilValue(referenceStatus);
  const file = useRecoilValue(referenceType);

  return (
    <Stack spacing={1} sx={{ width: '100%', px: 2, pt: 2 }}>
      <Typography
        sx={{
          fontSize: '14px',
          height: '32px',
        }}
      >
        {t('setup.reference.properties_title', 'Reference')}
      </Typography>
      {file !== REFERENCE_TYPE.NONE || status !== Status.NONE ? (
        <FileStatus />
      ) : (
        <UploadReferenceButton />
      )}
    </Stack>
  );
}
