import { Accordion } from '@common/components/Accordion';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelectionStore } from '../../store/useSelectionStore';
import UnitDetail from './UnitDetail';

export default function UnitProperty() {
  const { t } = useTranslation(['interface']);
  const { unitIds } = useSelectionStore();

  return (
    <Accordion
      title={t('interface:3te25.unit.title')}
      titleSx={{ textTransform: 'capitalize' }}
      defaultExpanded
    >
      <Stack direction='column' spacing={1}>
        {unitIds && unitIds.map((unitId) => <UnitDetail unitId={unitId} key={unitId} />)}
        {(!unitIds || unitIds.length === 0) && <Typography>No Unit is selected</Typography>}
      </Stack>
    </Accordion>
  );
}
