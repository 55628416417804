import { GetRecoilValue, RecoilState, ResetRecoilState, SetRecoilState } from 'recoil';

interface StateWithId {
  id: string;
}

type GetSetResetRecoilState = {
  get: GetRecoilValue;
  set: SetRecoilState;
  reset: ResetRecoilState;
};

export const setAtomFamilyWithIdsAtom = <T extends StateWithId>(
  getSetResetRecoilState: GetSetResetRecoilState,
  stateAtomFamily: (string) => RecoilState<any>,
  idsAtom: RecoilState<string[]>,
  newObjects: T[],
) => {
  const { get, set, reset } = getSetResetRecoilState;

  const newIds = new Set(newObjects.map((x) => x.id));
  const oldIds = get(idsAtom);
  const diff: string[] = [];
  const remain: string[] = [];
  oldIds.forEach((id) => (newIds.has(id) ? remain.push(id) : diff.push(id)));
  diff.forEach((id) => reset(stateAtomFamily(id)));

  // update atoms from atom family
  newObjects.forEach((value) => {
    set(stateAtomFamily(value.id), value);
  });

  // update ids collection
  const combineIds = Array.from(new Set([...remain, ...newIds]));
  set(idsAtom, combineIds);
};
