import { ReactNode } from 'react';
import { Box, Stack, Typography } from '@mui/material';

type Props = {
  label: string;
  children: ReactNode;
};

function Label({ label, children }: Props) {
  return (
    <Stack direction='row' alignItems='flex-start' gap='1em'>
      <Typography sx={{ flex: '1 1 30%', mt: '0.8em' }} fontSize='10px'>
        {label}
      </Typography>
      <Box sx={{ flex: '1 1 70%' }}>{children}</Box>
    </Stack>
  );
}

export default Label;
