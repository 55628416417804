import { useDialog } from '@/modules/common/hooks';
import { Stack } from '@mui/material';
import { useCallback } from 'react';
import { Simulation, SimulationStatus, SimulationType } from '../../../helpers/types';
import { useSimulationCallbacks } from '../../../hooks';
import { DeleteConfirmDialog } from '../../ConfirmDialog/DeleteConfirmDialog';
import { Status } from '../../Status';
import { TopButtons } from '../../TopButtons';
import { TopButtons as GroupTopButtons } from './TopButtons';

type Props = {
  simulation: Simulation;
  isHover: boolean;
  onClick: () => void;
};

export const CardHeader = ({ simulation, isHover, onClick }: Props) => {
  const { name } = simulation;
  const { open: deleteDialogOpen, setOpen: toggleDeleteDialog } = useDialog();
  const { openSimulationEditPanel, openSimulationGroupPanel, rerun, remove, openFleetTracker } =
    useSimulationCallbacks();

  const onDeleteConfirm = useCallback(async () => await remove(simulation), [simulation, remove]);

  const onEditClick = useCallback(
    async () => await openSimulationEditPanel(simulation),
    [simulation, openSimulationEditPanel],
  );
  const onRerunClick = useCallback(async () => await rerun(simulation), [simulation, rerun]);

  const onViewClick = useCallback(
    async () => await openFleetTracker(simulation),
    [simulation, openFleetTracker],
  );

  const onDetailsClick = useCallback(
    async () => openSimulationGroupPanel(simulation.id),
    [simulation, openSimulationGroupPanel],
  );

  const onDeleteClick = useCallback(() => toggleDeleteDialog(true), [toggleDeleteDialog]);

  return (
    <Stack
      sx={{
        alignItems: 'center',
        flexDirection: 'row',
        gap: 1,
        mb: 1,
        justifyContent: 'space-between',
      }}
    >
      <Stack
        sx={{ flexBasis: '100%', alignItems: 'center', flexDirection: 'row', height: '100%' }}
        onClick={onClick}
      >
        <Status simulation={simulation} />
      </Stack>
      <Stack
        sx={{
          alignItems: 'center',
          justifyContent: 'flex-end',
          flexDirection: 'row',
          gap: 2,
          height: '32px',
          flexBasis: '50px',
        }}
      >
        {isHover && simulation.status !== SimulationStatus.SAVING && (
          <>
            {simulation.type === SimulationType.GROUP ? (
              <GroupTopButtons
                simulation={simulation}
                onRerunClick={onRerunClick}
                onEditClick={onEditClick}
                onDetailsClick={onDetailsClick}
                onDeleteClick={onDeleteClick}
              />
            ) : (
              <TopButtons
                simulation={simulation}
                onViewClick={onViewClick}
                onRerunClick={onRerunClick}
                onEditClick={onEditClick}
                onDeleteClick={onDeleteClick}
              />
            )}
          </>
        )}
      </Stack>
      <DeleteConfirmDialog
        open={deleteDialogOpen}
        runName={name}
        onConfirm={() => {
          onDeleteConfirm();
          toggleDeleteDialog(false);
        }}
        onCancel={() => toggleDeleteDialog(false)}
      />
    </Stack>
  );
};
