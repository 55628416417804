import { Unit } from '../../../modules/common/types/general';
import { defaultUnitDecimalPlacesMap } from './constants';

export const convertMetricUnit = (
  value: number,
  sourceUnit: Unit,
  targetUnit: Unit,
  decimalPlaces: number = defaultUnitDecimalPlacesMap[targetUnit] || 0,
  limitPrecision = true,
): number => {
  let valueInMm: number;
  let convertedValue: number;

  // convert to mm
  switch (sourceUnit) {
    case Unit.METERS:
      valueInMm = value * 1000;
      break;
    case Unit.CENTIMETERS:
      valueInMm = value * 10;
      break;
    case Unit.MILLIMETERS:
    default:
      valueInMm = value;
      break;
  }

  // convert mm to target unit
  switch (targetUnit) {
    case Unit.METERS:
      convertedValue = valueInMm / 1000;
      break;
    case Unit.CENTIMETERS:
      convertedValue = valueInMm / 10;
      break;
    case Unit.MILLIMETERS:
    default:
      convertedValue = valueInMm;
      break;
  }

  return limitPrecision ? Number(convertedValue.toFixed(decimalPlaces)) : convertedValue;
};
