import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../common';
import inputAtom, { MouseProperties } from './atom';

const mouseInputState = selector<MouseProperties>({
  key: 'input/mouseState',
  get: ({ get }) => get(inputAtom).mouse,
  set: ({ get, set }, newValue) => {
    set(inputAtom, { ...get(inputAtom), mouse: newValue });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default mouseInputState;
