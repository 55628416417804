import { Stack } from '@mui/material';
import { HeaderTitle } from '../../HeaderTitle';
import { VehicleCount } from '../../VehicleCount';
import { Simulation } from '../../../helpers/types';

type Props = {
  simulation: Simulation;
};

export const CardContent = ({ simulation }: Props) => (
  <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
    <Stack sx={{ gap: 1 }}>
      <HeaderTitle>{simulation.name}</HeaderTitle>
      <VehicleCount simulation={simulation} />
    </Stack>
  </Stack>
);
