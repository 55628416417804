import { useCallback } from 'react';

import { LayerNames } from '@/modules/common/types/layers';
import { UserPreferenceName } from '@modules/userPreferences';
import { useUpdateUserPreferences } from '@modules/userPreferences/hooks';

export const useUpdateLayerUserPreference = () => {
  const { updateUserPreference } = useUpdateUserPreferences();

  const updateVisibility = useCallback(
    async (layer: LayerNames, visible: boolean) => {
      switch (layer) {
        case LayerNames.ROADS:
          return await updateUserPreference(UserPreferenceName.LAYER_ROADS_HIDDEN, !visible);
        case LayerNames.HIGHWAYS:
          return await updateUserPreference(UserPreferenceName.LAYER_HIGHWAYS_HIDDEN, !visible);
        case LayerNames.ANGLED_HIGHWAYS:
          return await updateUserPreference(UserPreferenceName.LAYER_ANGLED_HIGHWAYS_HIDDEN, !visible);
        case LayerNames.AREAS:
          return await updateUserPreference(UserPreferenceName.LAYER_AREAS_HIDDEN, !visible);
        case LayerNames.INTAKE:
          return await updateUserPreference(UserPreferenceName.LAYER_INTAKE_HIDDEN, !visible);
        case LayerNames.DELIVERY:
          return await updateUserPreference(UserPreferenceName.LAYER_DELIVERY_HIDDEN, !visible);
        case LayerNames.STORAGE:
          return await updateUserPreference(UserPreferenceName.LAYER_STORAGE_HIDDEN, !visible);
        case LayerNames.CHARGING:
          return await updateUserPreference(UserPreferenceName.LAYER_CHARGING_HIDDEN, !visible);
        case LayerNames.PARKING:
          return await updateUserPreference(UserPreferenceName.LAYER_PARKING_HIDDEN, !visible);
        case LayerNames.PROCESS:
          return await updateUserPreference(UserPreferenceName.LAYER_PROCESS_HIDDEN, !visible);
        case LayerNames.INTERIOR:
          return await updateUserPreference(UserPreferenceName.LAYER_INTERIOR_HIDDEN, !visible);
        case LayerNames.WALLS:
          return await updateUserPreference(UserPreferenceName.LAYER_WALLS_HIDDEN, !visible);
        case LayerNames.OBSTACLES:
          return await updateUserPreference(UserPreferenceName.LAYER_OBSTACLES_HIDDEN, !visible);
        case LayerNames.REFERENCE:
          return await updateUserPreference(UserPreferenceName.LAYER_REFERENCES_HIDDEN, !visible);
        case LayerNames.FLOWS:
          return await updateUserPreference(UserPreferenceName.LAYER_FLOWS_HIDDEN, !visible);
        case LayerNames.NAMES:
          return await updateUserPreference(UserPreferenceName.LAYER_NAMES_HIDDEN, !visible);
        case LayerNames.CONNECTIONS:
          return await updateUserPreference(UserPreferenceName.LAYER_CONNECTIONS_HIDDEN, !visible);
        default:
      }
    },
    [updateUserPreference],
  );

  return {
    updateVisibility,
  };
};
