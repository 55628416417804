import { memo, useState, useCallback } from 'react';
import { Typography, Stack, Badge } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { CloseIcon, DownloadIcon } from '@/assets/icons';
import NotificationIcon from '@/modules/Notifications/components/NotificationIcon';
import {
  NotificationType,
  type ErrorNotification,
  type Notification as NotificationInterface,
} from '@/modules/Notifications/helpers/types';
import { useNotificationStore } from '@/modules/Notifications/store/useNotificationStore';
import ArrowIcon from '@/assets/icons/ArrowIcon';
import { useFloorPlanFile } from '@/modules/floorplan';

interface NotificationProps {
  notification: NotificationInterface | ErrorNotification;
}

function Notification({ notification }: NotificationProps) {
  const { removeNotification } = useNotificationStore();
  const isErrorNotification = notification.type === NotificationType.ERROR;
  const title =
    notification.title || notification.type.charAt(0).toUpperCase() + notification.type.slice(1);

  const { downloadFloorplan } = useFloorPlanFile();
  const [isHovered, setIsHovered] = useState(false);
  const { t } = useTranslation(['errors']);
  const handleRemove = useCallback(
    () => removeNotification(notification.id),
    [removeNotification, notification.id],
  );

  const notificationTime = format(new Date(notification.timestamp), 'HH:mm');

  return (
    <Stack
      className='notification'
      direction='row'
      spacing={2}
      sx={{
        width: '100%',
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'neutral.grey',
        },
        transition: 'background-color 0.1s ease',
        p: 2,
      }}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Stack sx={{ mt: -0.5 }} alignItems='center'>
        <NotificationIcon type={notification.type} />
      </Stack>
      <Stack sx={{ flex: 1, gap: 1 }}>
        <Typography
          sx={{
            fontWeight: 600,
            color: 'neutral.darker',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {title ?? notification.type}
        </Typography>
        <Typography lineHeight='16px' fontSize={10} sx={{ wordBreak: 'break-word' }}>
          {notification.description}
        </Typography>
        {isErrorNotification && (
          <>
            <Stack
              direction='row'
              sx={{
                cursor: 'pointer',
                gap: '4px',
                borderBottom: '1px solid #222',
                lineHeight: '16px',
                display: 'inline-flex',
                width: 'fit-content',
              }}
              onClick={() => {
                downloadFloorplan();
              }}
            >
              <Typography fontSize={10}>{t('notifications.download_floorplan')}</Typography>
              <DownloadIcon size={16} />
            </Stack>
            <Stack
              direction='row'
              sx={{
                cursor: 'pointer',
                gap: '4px',
                borderBottom: '1px solid #222',
                lineHeight: '16px',
                display: 'inline-flex',
                width: 'fit-content',
              }}
              onClick={() =>
                window.open('https://t-hive.freshdesk.com/support/tickets/new', '_blank')
              }
            >
              <Typography fontSize={10}>{t('notifications.contact_support')}</Typography>
              <ArrowIcon size={16} sx={{ transform: 'rotate(90deg)' }} />
            </Stack>
          </>
        )}
      </Stack>
      <Stack width='16%'>
        <Stack direction='row' justifyContent='flex-end' alignItems='center'>
          {isHovered ? (
            <Stack sx={{ cursor: 'pointer', mt: '-4px' }}>
              <CloseIcon onClick={handleRemove} />
            </Stack>
          ) : (
            <>
              <Typography fontSize={10} color='neutral.main' pt='0px'>
                {notificationTime}
              </Typography>
              {!notification.read && (
                <Badge color='primary' variant='dot' sx={{ pl: 1.5, mr: 1 }} />
              )}
            </>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default memo(Notification);
