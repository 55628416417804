import { useRecoilCallback } from 'recoil';

import { AngledHighwayShape } from '@modules/angledHighways/types';
import { useConnections } from '@modules/connections';
import { PropertyPath, PropertyTrackerMap } from '@modules/workspace/types/dragging';
import { HistoryManager } from '@recoil/history';
import { shapePropertyCanvas } from '@recoil/shape';
import shapeAtom from '@recoil/shape/atom';
import { useArtefacts } from '@/modules/artefacts';

export const useDragAndResizeHistory = () => {
  const { updateConnections } = useConnections();
  const { update: updateArtefacts } = useArtefacts();

  const trackDragAndResizeHistory = useRecoilCallback(
    ({ set }) =>
      (mode: 'moved' | 'resized', currState: PropertyTrackerMap, prevState: PropertyTrackerMap) => {
        HistoryManager.track(
          `shape ${mode}`,
          currState,
          prevState,
          (historyState: PropertyTrackerMap) => {
            historyState.forEach((data, shapeId) => {
              if (data.path === PropertyPath.PROPERTIES_CONTROLPOINTS) {
                set(shapeAtom(shapeId), (current: AngledHighwayShape) => ({
                  ...current,
                  properties: {
                    ...current.properties,
                    controlPoints: data.value,
                  },
                }));
              } else if (data.path === PropertyPath.PROPERTIES) {
                set(shapePropertyCanvas(shapeId), (prop) => ({
                  ...prop,
                  ...data.boundingBox,
                  r: data.angle,
                }));
              }
            });

            const shapeIds = Array.from(historyState.keys());
            updateConnections(shapeIds);
            updateArtefacts(shapeIds);
          },
        );
      },
    [updateConnections, updateArtefacts],
  );

  return { trackDragAndResizeHistory };
};
