import { memo } from 'react';
import { useRecoilValue } from 'recoil';

import { WallProxy } from '@/components/Workspace/WallProxy';
import { SHAPE_TO_CANVAS_SCALE } from '@/modules/workspace/helpers/konva';
import { shapeTypeShowSelector } from '@/modules/layers';
import shapeAtom from '@/store/recoil/shape/atom';
import { WallShape } from '@recoil/shape';
import { WallRender } from './WallRender';

export type WallProps = {
  id: string;
};

const WallComponent = ({ id }: WallProps) => {
  const wall = useRecoilValue(shapeAtom(id)) as WallShape;
  const layerShown = useRecoilValue(shapeTypeShowSelector(wall?.type));

  if (!wall.id || !layerShown) return null;

  return (
    <>
      <WallRender
        points={wall.properties.controlPoints}
        width={wall.parameters.width * SHAPE_TO_CANVAS_SCALE}
      />
      <WallProxy id={id} points={wall.properties.controlPoints} width={wall.parameters.width} />
    </>
  );
};

export const Wall = memo(WallComponent);
