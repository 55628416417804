import { useSimulationDraftCallbacks } from '@/modules/simulation/hooks';
import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { isValidToRunSelector } from '../../../store/draft';
import { useCallback } from 'react';
import { useTrackFloorplanMetric } from '@/modules/insight/hooks/useTrackFloorplanMetric';

export const Buttons = () => {
  const { prepareAndRun } = useSimulationDraftCallbacks();
  const { t } = useTranslation('interface');
  const isValidToRun = useRecoilValue(isValidToRunSelector);
  const trackFloorplanMetric = useTrackFloorplanMetric();

  const runSimulation = useCallback(() => {
    const startTime = performance.now();
    prepareAndRun().then(() => {
      trackFloorplanMetric('RUN_SIMULATION', startTime);
    });
  }, [prepareAndRun, trackFloorplanMetric]);

  return (
    <Stack sx={{ px: 2, mt: 2 }}>
      <Button
        sx={{ height: 40 }}
        variant='outlined'
        disabled={!isValidToRun}
        onClick={runSimulation}
      >
        {t('simulation.simulation_edit.header.run_button.label')}
      </Button>
    </Stack>
  );
};
