import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import { Flow } from '../../helpers/types';
import { simulationAtom } from './simulationAtom';

export const flowsSelector = selector<Flow[]>({
  key: 'simulations/draft/flows',
  get: ({ get }) => get(simulationAtom)?.flows ?? [],
  set: ({ set }, flows: Flow[]) => set(simulationAtom, (state) => ({ ...state, flows })),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
