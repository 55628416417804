import { Accordion } from '@/modules/common/components/Accordion';
import { Stack } from '@mui/material';
import { KollmorgenUpload } from './KollmorgenUpload';
import { SevenSenseUpload } from './SevenSenseUpload';
import { NavitechUpload } from './NavitechUpload';

export const Uploads = () => (
  <Accordion titleKey='interface:commissioning.localization_layer' defaultExpanded sx={{ px: 2 }}>
    <Stack sx={{ gap: 1, pb: 1 }}>
      <KollmorgenUpload />
      <SevenSenseUpload />
      <NavitechUpload />
    </Stack>
  </Accordion>
);
