import { selector } from 'recoil';
import floorplanAtom from '@recoil/floorplan/atom';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

/**
 * Version of the currently loaded floor plan
 */
export const versionSelector = selector<number>({
  key: 'floorplan/version',
  get: ({ get }) => get(floorplanAtom).version,
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
