import { AngledHighwayShape } from '@/modules/angledHighways/types';
import { addVehicleArea } from '@/modules/floorplanService/helpers/mapping/addVehicleArea';
import { addAreaMappingFromHighwayToOtherShapes } from './addAreaMappingFromHighwayToOtherShapes';
import { addCheckPointGenerationSettings } from './addCheckPointGenerationSettings';
import { addDefaultCheckPointIdGenerator } from './addIdGenerators';
import { decodeShapeId, encodeIdWithVehicleId } from './idEncoder';
import { AreaMapperTypeEnum } from './types';
import { angledHighwayLaneDirection, lineToFpsRectangle } from './utils';
import { IdGenerator } from '@/modules/floorplanService';
import { WideLineSegment } from '@/modules/common/types/general';

export const createAngledHighway = (
  vehicleSpec,
  checkPointIdGenerators,
  shape: AngledHighwayShape,
  workspaceBoundingBox,
  customIdGeneratorMap: Map<string, IdGenerator>
) => {
  const { properties, parameters } = shape;
  const numberOfSegments = properties.controlPoints.length - 1;
  for (let i = 0; i < numberOfSegments; i++) {
    const shapeId = encodeIdWithVehicleId(shape.id, vehicleSpec.databaseId, i);
    const name = encodeIdWithVehicleId(shape.name, vehicleSpec.databaseId, i);
    const checkPointIdGeneratorName =
      shape.parameters.checkPointIdGeneratorName || `${name}-CpIdGen`;
    const customCheckPointIdGenerator = customIdGeneratorMap.get(checkPointIdGeneratorName);
    const line: WideLineSegment = {
      points: {
        start: properties.controlPoints[i].position,
        end: properties.controlPoints[i + 1].position,
      },
      width: parameters.width,
    };
    const rectangle = lineToFpsRectangle(line, workspaceBoundingBox);

    const cutOutsInBounds = [];
    if (i === 0 && numberOfSegments > 1) {
      cutOutsInBounds.push({ name: encodeIdWithVehicleId(shape.id, vehicleSpec.databaseId, i + 1)});
    } else if (i === numberOfSegments - 1 && numberOfSegments > 1) {
      cutOutsInBounds.push({ name: encodeIdWithVehicleId(shape.id, vehicleSpec.databaseId, i - 1)});
    } else if (numberOfSegments > 2) {
      cutOutsInBounds.push({ name: encodeIdWithVehicleId(shape.id, vehicleSpec.databaseId, i + 1)});
      cutOutsInBounds.push({ name: encodeIdWithVehicleId(shape.id, vehicleSpec.databaseId, i - 1)});
    }

    addVehicleArea(vehicleSpec, shapeId, shape.type, rectangle);
    addCheckPointGenerationSettings(
      vehicleSpec,
      shapeId,
      checkPointIdGeneratorName,
      shape.parameters.gap,
      shape.parameters.margin,
      shape.parameters.routingPointGroupMinGap,
      shape.parameters.routingPointMarginToCrossing,
      angledHighwayLaneDirection(shape.parameters.laneDirection, rectangle.angleInDegrees),
      0, // TODO
      0, // TODO
      cutOutsInBounds,
    );
    if (customCheckPointIdGenerator) {
      checkPointIdGenerators.push(customCheckPointIdGenerator);
    } else {
      addDefaultCheckPointIdGenerator(checkPointIdGenerators, checkPointIdGeneratorName, name);
    }
  }
};

export const interlinkAngledHighway = (vehicleSpec, id) => {
  const areasInAngledHighway = vehicleSpec.areas.filter(
    (area) => decodeShapeId(area.name) === decodeShapeId(id),
  );

  for (let i = 0; i < areasInAngledHighway.length - 1; i++) {
    addAreaMappingFromHighwayToOtherShapes(
      vehicleSpec.gateMappingSettings.areaMappings,
      areasInAngledHighway[i].name,
      null,
      {
        discType: AreaMapperTypeEnum.MAP_CHECK_POINTS_AT_CROSSING,
        areaReferencesToMap: [{ name: areasInAngledHighway[i + 1].name }],
      },
      null,
      null,
      null,
      null,
    );
    addAreaMappingFromHighwayToOtherShapes(
      vehicleSpec.gateMappingSettings.areaMappings,
      areasInAngledHighway[i + 1].name,
      null,
      {
        discType: AreaMapperTypeEnum.MAP_CHECK_POINTS_AT_CROSSING,
        areaReferencesToMap: [{ name: areasInAngledHighway[i].name }],
      },
      null,
      null,
      null,
      null,
    );
  }
};
