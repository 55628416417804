import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@/store/recoil/common';

import { moduleAtom } from './moduleAtom';

export const isLoadingSelector = selector({
  key: 'versioning/module/isLoading',
  get: ({ get }) => get(moduleAtom).isLoading,
  set: ({ set }, isLoading: boolean) =>
    set(moduleAtom, (state) => ({
      ...state,
      isLoading,
    })),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
