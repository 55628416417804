import { SVGProps, memo } from 'react';

function SvgComponent({ className = '', ...rest }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      style={{ stroke: 'currentColor' }}
      className={className}
      {...rest}
    >
      <path
        d='M18 9 12.354 14.646a.5.5 0 0 1-.708 0L6 9'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

const ChevronDownIcon = memo(SvgComponent);
export default ChevronDownIcon;
