import { useAxios, useAxiosWithErrorNotifications } from '@/modules/api/hooks';
import { LoadCarrierType } from '@modules/common/types/floorPlan';
import REST from '../types';
import { useRecoilCallback } from 'recoil';
import { availableLoadCarrierTypesState } from '@recoil/loadCarrierTypes';
import { createLoadCarrierId } from '@modules/loadCarriers';
import { useMemo } from 'react';
import { useConfig } from '@/modules/common/hooks';

export const useLoadCarrierService = () => {
  const { api } = useConfig();
  const scopes = useMemo(() => [api.pimService.scope], [api.pimService.scope]);

  const axios = useAxiosWithErrorNotifications(
    { baseURL: api.pimService.url, scopes },
    {
      errorCode: (response) => response.data.code,
      errorCondition: (response) => response.status !== 200,
      serviceName: 'loadCarrierService, PIM API',
    },
  );

  const getAllLoadCarriers = async (): Promise<LoadCarrierType[]> => {
    const response = await axios.get('/v1/loadCarriers');
    return response.data.map((v) => convertRestLoadCarrierToModel(v));
  };

  const getLoadCarrier = async (databaseId: number): Promise<LoadCarrierType | null> => {
    const response = await axios.get(`/v1/loadCarriers/${databaseId}`);
    if (response.status !== 200) {
      console.log(
        `Error fetching load carrier with id: ${databaseId}, status: ${response.statusText}`,
      );
      return null;
    }
    return convertRestLoadCarrierToModel(response.data);
  };

  const getLoadTypeById = useRecoilCallback(
    ({ snapshot }) =>
      async (id: string) => {
        const loadCarrierTypes = await snapshot.getPromise(availableLoadCarrierTypesState);
        return loadCarrierTypes.find((item) => item.id === id);
      },
    [],
  );
  const convertRestLoadCarrierToModel = (v: REST.LoadCarrier): LoadCarrierType => ({
    height: v.height,
    width: v.width,
    databaseId: v.id,
    id: createLoadCarrierId(v.name),
    length: v.length,
    name: v.name,
    safeWorkingLoad: v.safeWorkLoad,
    weight: v.weight,
    image: {
      height: v.image.height,
      width: v.image.width,
      url: v.image.url,
    },
  });

  return { getAllLoadCarriers, getLoadCarrier, getLoadTypeById };
};
