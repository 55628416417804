import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../common';
import inputAtom from './atom';
import { KeyCodeType } from './constants';

const keyboardInputState = selector<KeyCodeType>({
  key: 'input/keyboardState',
  get: ({ get }) => get(inputAtom).keyboard,
  set: ({ get, set }, newValue: KeyCodeType) => {
	const currentModel = get(inputAtom);
	if (currentModel.ignoreKeyboardKeys.includes(newValue)) return;

    set(inputAtom, { ...get(inputAtom), keyboard: newValue });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default keyboardInputState;
