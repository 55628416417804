import { useRecoilCallback } from 'recoil';
import { allShapesSelector } from '../../../../store/recoil/shapes';
import { layoutFlowsSelector } from '../../../flows/store/layout';
import { allGroupsSelector } from '../../../shapeGroups';
import { getFlowlessAreas } from '../helpers';
import { prevalidationState } from '../store';

export const useFlowlessAreasValidator = () => {
  const validateFlowlessAreas = useRecoilCallback(
    ({ snapshot, set }) =>
      async (syncResultsWithStore = true) => {
        const allShapes = await snapshot.getPromise(allShapesSelector);
        const flows = await snapshot.getPromise(layoutFlowsSelector);
        const shapeGroups = await snapshot.getPromise(allGroupsSelector);

        const flowlessAreas = getFlowlessAreas(
          allShapes,
          shapeGroups,
          flows,
        );

        if (syncResultsWithStore) {
          set(prevalidationState, (current) => {
            if (
              current.flowlessAreas.length === 0 &&
              flowlessAreas.length === 0
            ) {
              return current;
            }

            return {
              ...current,
              flowlessAreas,
            };
          });
        }

        return flowlessAreas;
      },
    [],
  );

  return {
    validateFlowlessAreas,
  };
};
