import { useCallback } from 'react';
import { UndoButton } from '@/components/Toolbar3/UndoButton';
import { RedoButton } from '@/components/Toolbar3/RedoButton';
import { Paper, Grid } from '@mui/material';

import { useHistoryStore } from '../store';
import { HistoryManager } from '../utils';

export const UndoRedo = ({ disabled }) => {
  const { hasUndo, hasRedo } = useHistoryStore((state) => state);

  const undo = useCallback(() => {
    HistoryManager.undo();
  }, []);

  const redo = useCallback(() => {
    HistoryManager.redo();
  }, []);

  return (
    <Paper
      elevation={1}
      sx={{
        borderRadius: '4px',
      }}
    >
      <Grid item xs={2} style={{ textAlign: 'center' }}>
        <UndoButton disabled={disabled || !hasUndo} onClick={undo} />
        <RedoButton disabled={disabled || !hasRedo} onClick={redo} />{' '}
      </Grid>
    </Paper>
  );
};
