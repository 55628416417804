import { InsightMetrics } from '../types';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useContext } from 'react';
import { useRecoilCallback } from 'recoil';
import { allFlowIdsAmountSelector } from '@/modules/flows/store/layout';
import { allShapesSelector } from '@/store/recoil/shapes';
import { DTShape } from '@/store/recoil/shape';

const useTrackFloorplanMetric = () => {
  const appInsights = useContext(AppInsightsContext);

  return useRecoilCallback(
    ({ snapshot }) =>
      async (eventName: InsightMetrics, startTime: number, endTime?: number) => {
        const shapes = await snapshot.getPromise(allShapesSelector);
        const flows = await snapshot.getPromise(allFlowIdsAmountSelector);

        const { stations, pointsOfInterest } = calculateStationsAndPointsOfInterest(shapes);
        appInsights.trackEvent({
          name: eventName,
          measurements: {
            duration: (endTime || performance.now()) - startTime,
            numShapes: shapes.length,
            numFlows: flows,
            numStations: stations,
            numPointsOfInterest: pointsOfInterest,
          },
        });
      },
    [appInsights],
  );
};

const calculateStationsAndPointsOfInterest = (shapes: DTShape[]) => {
  let pointsOfInterest = 0;
  let stations = 0;

  shapes.forEach((shape) => {
    shape?.gates?.forEach((gate) => {
      stations += gate.loads.length;
      pointsOfInterest += gate.station.type === 'ROUTING_POINT' ? 1 : 0;
    });
  });

  return { stations, pointsOfInterest };
};

export { useTrackFloorplanMetric };