import { selectorFamily } from 'recoil';
import { selectedGroupIdsState } from '../atom';

export const groupIdIsInSelectionSelector = selectorFamily({
  key: 'shapeGroup/byId/isSelected',
  get:
    (id: string) =>
    ({ get }) =>
      get(selectedGroupIdsState).includes(id),
});
