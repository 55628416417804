import { useFloorPlanService } from '@modules/floorplan';
import { useRecoilCallback } from 'recoil';

import { DEFAULT_REFERENCE_IMAGE_SETTINGS } from '@modules/referenceImage/constants';
import dwgState from '@modules/referenceImage/store';
import { useImageSettings } from './useImageSettings';

export const useLoadReferenceImage = () => {
  const { fetchReferenceDetails, fetchReferenceImage: fetchReferenceImageAPI } =
    useFloorPlanService();
  const { createSettings } = useImageSettings();

  const load = useRecoilCallback(
    ({ set }) =>
      async (projectId: string, floorplanId: string) => {
        set(dwgState, DEFAULT_REFERENCE_IMAGE_SETTINGS);

        const referenceImageDetails = await fetchReferenceDetails(projectId, floorplanId);
        const referenceUrl = referenceImageDetails?.timeLimitedReferenceImageUrl;
        let base64string;

        if (referenceUrl) {
          base64string = await fetchReferenceImageAPI(referenceUrl);
        }

        const imageSettings = createSettings(
          floorplanId,
          base64string,
          referenceImageDetails.width,
          referenceImageDetails.height,
        );

        const referenceSettings = {
          ...imageSettings,
          ...referenceImageDetails,
          pointA: null,
          pointB: null,
          selected: false,
        };
        set(dwgState, (state) => ({
          ...referenceSettings,
          apsUrn: state.apsUrn,
        }));
      },
    [fetchReferenceDetails, createSettings, fetchReferenceImageAPI],
  );

  const clean = useRecoilCallback(
    ({ set }) =>
      () => {
        set(dwgState, null);
      },
    [],
  );

  return {
    load,
    clean,
  };
};
