import { SVGProps, memo } from 'react';

function SvgComponent({ className = '', ...rest }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 -960 960 960'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      style={{ fill: 'currentColor' }}
      className={className}
      {...rest}
    >
      <path d='M256-227.692 227.692-256l224-224-224-224L256-732.308l224 224 224-224L732.308-704l-224 224 224 224L704-227.692l-224-224-224 224Z' />
    </svg>
  );
}

const CloseIcon = memo(SvgComponent);
export default CloseIcon;
