import { DTShape, ShapeType } from '@modules/common/types/shapes';
import { atomFamily } from 'recoil';

export const DEFAULT_SHAPE: any = {
  id: undefined,
  name: undefined,
  type: ShapeType.NONE,
  disabled: false,
  isDrawing: true,
  isTransforming: false,
  properties: {},
  parameters: {},
};

const shapeAtom = atomFamily<DTShape, string>({
  key: 'shape',
  default: DEFAULT_SHAPE,
});

export default shapeAtom;
