import { useRecoilCallback } from 'recoil';
import { FloorPlanGroup } from '@modules/api/hooks/useFloorPlanGroupApi';

import { groupSelector } from '../store/group';

export const useLoadGroup = () => {
  const load = useRecoilCallback(
    ({ set }) =>
      (group: FloorPlanGroup) => {
        set(groupSelector, {
          name: group.name,
          id: group.id,
          latestFloorPlanId: group.floorPlanId,
        });
      },
    [],
  );

  return {
    load,
  };
};
