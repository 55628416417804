import { Stack, Typography } from '@mui/material';
import { t } from 'i18next';

function MessageBox() {
  return (
    <Stack
      direction='column'
      justifyContent='space-between'
      alignItems='stretch'
      spacing={2}
      sx={{
        backgroundColor: 'others.lighter',
        p: 2,
        mb: 1,
        '&:hover': { cursor: 'default' },
      }}
      className='infoCard'
    >
      <Typography align='left' sx={{ fontWeight: 500, fontSize: '12px', lineHeight: '150%' }}>
        {t('interface:prevalidation.disabled_simulation_title', 'You can’t start a simulation.')}
      </Typography>
      <Typography align='left' sx={{ fontWeight: 300, fontSize: '12px', lineHeight: '150%' }}>
        {t(
          'interface:prevalidation.disabled_simulation_message',
          'Fix the missing flows and Disconnected areas before starting a simulation.',
        )}
      </Typography>
    </Stack>
  );
}

export default MessageBox;
