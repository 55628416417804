import { Divider, Stack } from '@mui/material';

import { LoadCarrierTypes } from './LoadCarrierTypes';
import { VehicleTypes } from './VehicleTypes';

export function FloorPlanProperties() {
  return (
    <Stack spacing={2} divider={<Divider />} data-testid='FloorPlanProperties'>
      <VehicleTypes />
      <LoadCarrierTypes />
    </Stack>
  );
}
