import { SvgIcon } from '@mui/material';

const PencilIcon = ({ className = '', size = 24, color = 'currentColor', ...rest }) => (
  <SvgIcon
    viewBox='0 0 24 24'
    sx={{ width: size, height: size, fontSize: `${size}px`, fill: color }}
    className={className}
    {...rest}
  >
    <path
      d='M5.30764 19.0002H6.38072L16.2749 9.10594L15.2019 8.03287L5.30764 17.9271V19.0002ZM18.4153 8.38672L15.9153 5.89824L17.123 4.69054C17.3269 4.48669 17.5701 4.38477 17.8528 4.38477C18.1355 4.38477 18.3788 4.48669 18.5826 4.69054L19.6173 5.72517C19.8211 5.92902 19.9249 6.17036 19.9288 6.44919C19.9326 6.72804 19.8326 6.96939 19.6288 7.17324L18.4153 8.38672ZM17.6961 9.11172L6.80764 20.0002H4.30762V17.5002L15.1961 6.61169L17.6961 9.11172ZM15.7288 8.55979L15.2019 8.03287L16.2749 9.10594L15.7288 8.55979Z'
      fill={color}
    />
  </SvgIcon>
);

export default PencilIcon;
