import { Color, Element, LineElement } from '@thive/canvas';
import { Vector2, Vector3 } from 'three';

import { theme } from '@/modules/common/components/theme';
import { DistantConnection } from '@/modules/common/types/connections';
import { createBubbleElement } from '@/modules/salesWorkspace/mappers/connection/bubble';

export const createDistantConnectionElement = (
  connection: DistantConnection
): Element[] => {
  const elements: Element[] = []

  const line: LineElement = {
    type: 'Line',
    name: `${connection.id}-line`,
    id: `${connection.id}-line`,
    pointA: new Vector3(connection.fromPosition.x, connection.fromPosition.y, 1),
    pointB: new Vector3(connection.toPosition.x, connection.toPosition.y, 1),
    thickness: 40,
    stroke: Color.fromHex(theme.palette.primary.main),
    interactivity: {
      selectable: false,
    },
  }
  elements.push(line)

  elements.push(...createBubbleElement(connection.id, new Vector2(connection.bubblePosition.x, connection.bubblePosition.y) , -connection.rot))

  return elements;
};
