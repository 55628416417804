import { useNavigation } from '@/modules/common/hooks';
import { AppMode } from '@/modules/common/types/general';
import { Module } from '@/modules/common/types/navigation';
import { DebugMenu } from '@/modules/debug/components/DebugMenu';
import { showDebugMenu } from '@/modules/debug/helpers';
import { wifiPanelAtom } from '@/modules/wifisimulation/store/wifiPanelAtom';
import settingsAtom from '@/store/recoil/settings/atom';
import BuildIcon from '@mui/icons-material/Build';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import WifiIcon from '@mui/icons-material/Wifi';
import {
  ClickAwayListener,
  Divider,
  Grow,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { useUserInfo } from '../../modules/authentication/hooks/useUserInfo';
import VersionList from './VersionList';

const buttonStyle = {
  width: '2rem',
  height: '2rem',
  borderRadius: '9999px',
  overflow: 'hidden',
  padding: 0,
  cursor: 'pointer',
} as const;

type Props = {
  appMode: AppMode;
};

export function UserMenu({ appMode }: Props) {
  const { accountInfo, getName, getAvatarImage, signOut } = useUserInfo();
  const [avatar, setAvatar] = useState('');
  const [name, setName] = useState('');
  const [initials, setInitials] = useState('');
  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const setSettings = useSetRecoilState(settingsAtom);
  const setWifiSimPanel = useSetRecoilState(wifiPanelAtom);
  const anchorRef = useRef(null);
  const { t } = useTranslation(['common', 'interface']);
  const { goToModule } = useNavigation();

  useEffect(() => {
    if (!accountInfo) return;

    const fullName = getName();
    if (fullName) {
      setName(fullName);
      setInitials(
        fullName
          .split(' ')
          .map((e) => e.substring(0, 1))
          .join(''),
      );
    }
    getAvatarImage().then((imageUrl) => {
      setAvatar(imageUrl);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountInfo]);

  const handleProfileDropDownClose = useCallback(
    (event: Event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
        return;
      }

      setProfileDropdownOpen(false);
      setSettings(false);
    },
    [setSettings],
  );

  const handleSignOut = useCallback(() => {
    setProfileDropdownOpen(false);
    setSettings(false);
    signOut();
  }, [setSettings, signOut]);

  const handleOpenSettings = useCallback(() => {
    setProfileDropdownOpen(false);
    setSettings(true);
  }, [setSettings]);

  const handleOpenWifiSimulation = useCallback(() => {
    setProfileDropdownOpen(false);
    setWifiSimPanel(true);
  }, [setWifiSimPanel]);

  const open3te25 = useCallback(() => {
    goToModule(Module.COMMISSIONING_3TE25);
    setProfileDropdownOpen(false);
  }, [goToModule, setProfileDropdownOpen]);

  return (
    <>
      {accountInfo && (
        <button
          ref={anchorRef}
          onClick={() => {
            setProfileDropdownOpen(!profileDropdownOpen);
          }}
          style={buttonStyle}
        >
          {avatar && (
            <img
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              src={avatar}
              alt={name}
            />
          )}
          {!avatar && (
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderWidth: '1px',
                borderColor: 'neutral.main',
                '&:hover': { cursor: 'pointer' },
              }}
            >
              {initials}
            </Typography>
          )}
        </button>
      )}
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={profileDropdownOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement='bottom-end'
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-end' ? 'right top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleProfileDropDownClose}>
                <MenuList id='split-button-menu' autoFocusItem sx={{ maxWidth: 250 }}>
                  <MenuItem disabled>{getName()}</MenuItem>
                  <Divider />
                  {appMode === AppMode.DigitalTwin && [
                    <MenuItem onClick={handleOpenWifiSimulation} key='wifiSimulation'>
                      <ListItemIcon>
                        <WifiIcon fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>{t('interface:wifi_simulation.title')}</ListItemText>
                    </MenuItem>,
                    <MenuItem onClick={open3te25} key='3te35'>
                      <ListItemIcon>
                        <BuildIcon fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>{t('interface:3te25.menu')}</ListItemText>
                    </MenuItem>,
                    <MenuItem onClick={handleOpenSettings} key='settings'>
                      <ListItemIcon>
                        <SettingsIcon fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>{t('common:settings', 'settings')}</ListItemText>
                    </MenuItem>,
                  ]}
                  {showDebugMenu && <DebugMenu />}
                  <MenuItem onClick={handleSignOut}>
                    <ListItemIcon>
                      <LogoutIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>{t('common:logout', 'Log out')}</ListItemText>
                  </MenuItem>
                  <Divider />
                  <MenuItem disabled>
                    <VersionList />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
