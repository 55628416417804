import { ShapeType } from '@modules/common/types/shapes';
import { UserPreferenceName, getUserPreference } from '@modules/userPreferences';

export const operationTime = (shapeType: ShapeType): number | null => {
  switch (shapeType) {
    case ShapeType.PROCESS_ONE_EP:
    case ShapeType.PROCESS_ONE_EP_POSITION:
    case ShapeType.PROCESS_TWO_EP:
      return getUserPreference(UserPreferenceName.PROCESS_OPERATION_TIME);
    case ShapeType.HANDOVER:
      return getUserPreference(UserPreferenceName.HANDOVER_OPERATION_TIME);
    default:
      return null;
  }
};
