import { SimulationStatus } from '../../helpers/types';

export const Colors = {
  [SimulationStatus.ABORTED]: 'neutral.main',
  [SimulationStatus.ABORTING]: 'neutral.main',
  [SimulationStatus.COMPLETED]: 'success.main',
  [SimulationStatus.DELETING]: 'warning.main',
  [SimulationStatus.DRAFT]: 'neutral.main',
  [SimulationStatus.FAILED]: 'error.main',
  [SimulationStatus.PREPARING]: 'neutral.main',
  [SimulationStatus.RUNNING]: 'status.running',
  [SimulationStatus.SCHEDULED]: 'info.main',
  [SimulationStatus.SAVING]: 'info.main',
  [SimulationStatus.PARTIALLY_COMPLETED]: 'warning.main',
} as Readonly<Record<SimulationStatus, string>>;
