import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@/store/recoil/common';
import { enabledVehicleIdsState } from './atom';
import { vehicleWidthSelector } from '../vehicleWidthSelector';

export const enabledVehiclesWidthSelector = selector<number>({
  key: 'vehicles/enabled/width',
  get: ({ get }) => {
    const widths = get(enabledVehicleIdsState).map((id) => get(vehicleWidthSelector(id)));
    return Math.max(...widths);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
