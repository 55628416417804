import { sizeSelector } from '@recoil/workspace';
import { useRecoilCallback } from 'recoil';

import { useCallback } from 'react';
import { referenceDimensions } from '../../store';

export const useWorkspaceClamp = () => {
  const clamp = useRecoilCallback(
    ({ snapshot }) =>
      async (x: number, y: number) => {
        const { width: referenceWidth, height: referenceHeight } = await snapshot.getPromise(
          referenceDimensions,
        );
        const { width: workspaceWidth, height: workspaceHeight } = await snapshot.getPromise(
          sizeSelector,
        );

        if (x < 0) {
          x = 0;
        }

        if (y < 0) {
          y = 0;
        }

        if (x + referenceWidth > workspaceWidth) {
          x = workspaceWidth - referenceWidth;
        }

        if (y + referenceHeight > workspaceHeight) {
          y = workspaceHeight - referenceHeight;
        }

        return {
          x,
          y,
        };
      },
    [],
  );

  const validateX = useCallback((value: number) => {
    // TODO check workspace width

    const valid = value < 0;

    return {
      valid,
      error: valid ? 'Value must be positive' : undefined,
    };
  }, []);

  const validateY = useCallback((value: number) => {
    // TODO check workspace height

    const valid = value < 0;

    return {
      valid,
      error: valid ? 'Value must be positive' : undefined,
    };
  }, []);

  return { clamp, validateX, validateY };
};
