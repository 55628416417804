import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../../store/recoil/common';
import dwgAtom from './atom';

const referenceMode = selector({
  key: 'reference/mode',
  get: ({ get }) => {
    const currentValue = get(dwgAtom);
    return currentValue.mode;
  },
  set: ({ get, set }, newValue) => {
    set(dwgAtom, { ...get(dwgAtom), mode: newValue });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default referenceMode;
