import { Order } from "@/modules/floorplanAdmin/sortingMenu/constants";

export type Comparator<T> = (v1: T, v2: T) => number;

export const stringComparator: Comparator<string> = (v1: string, v2: string) => (v1 < v2 ? -1 : 1);
export const dateStringComparator: Comparator<string> = (v1: string, v2: string) =>
  new Date(v1).getTime() - new Date(v2).getTime();

export const fieldSortFunction = <T>(
  field: string,
  order: Order,
  typeComparator: Comparator<T>,
): Comparator<T> => {
  if (order === Order.ASC) return (v1, v2) => typeComparator(v1[field], v2[field]);
  return (v1, v2) => -1 * typeComparator(v1[field], v2[field]);
};
