import { createVehicleTypeId } from './vehicle';
import { REST, UnifiedVehicle } from '../types';

export const transformRestVehiclesToVehicles = (
  restVehicles: REST.UnifiedVehicleType[],
): UnifiedVehicle[] =>
  restVehicles.map((v) => ({
    id: createVehicleTypeId(v.name),
    databaseId: v.id,
    name: v.name,
    vendor: v.vendor,
    model: v.model,
    hue: v.hue,
    image: {
      url: v.image.url,
    },
    tags: v.tags,
    uvt: {
      path: v.uvt?.path || null,
    },
    kmMdb: {
      path: v.kmMdb?.path || null,
    },
  }));
