import { useRecoilState, useRecoilCallback } from 'recoil';
import {
  positionState,
  relatedControlPointIdState,
  relatedShapeIdsState,
  relatedComponentIdsState,
} from '@recoil/workspace/contextMenu';

export const useContextMenu = () => {
  const [position, setPosition] = useRecoilState(positionState);

  const hide = useRecoilCallback(
    ({ reset }) =>
      () => {
        reset(positionState);
        reset(relatedShapeIdsState);
        reset(relatedComponentIdsState);
        reset(relatedControlPointIdState);
      },
    [],
  );

  return {
    position,
    setPosition,
    hide,
  };
};
