import { useUpdateUserPreferences, useUserPreference } from '@/modules/userPreferences/hooks';
import { NumberInput } from '@common/components/inputs';
import { UserPreferenceName } from '@modules/userPreferences';
import { useCallback } from 'react';

type FlowProps = {
  onFocus: () => void;
  onBlur: () => void;
};

export const FlowsOpacity = ({ onFocus, onBlur }: FlowProps) => {
  const { updateUserPreference } = useUpdateUserPreferences();
  const flowOpacity = useUserPreference(UserPreferenceName.LAYER_FLOWS_OPACITY);

  const onModelChange = useCallback(
    (value) => {
      updateUserPreference(UserPreferenceName.LAYER_FLOWS_OPACITY, value);
    },
    [updateUserPreference],
  );

  return (
    <NumberInput
      value={flowOpacity}
      sx={{ flex: '0 0 25%', marginRight: 1 }}
      dataType='integer'
      unit='%'
      onChange={onModelChange}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};
