import { Box, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  left: ReactNode;
  right: ReactNode;
};

export const DividedRow = ({ left, right }: Props) => (
  <Stack sx={{ alignItems: 'baseline', flexDirection: 'row', gap: 1 }}>
    <Typography sx={{ fontSize: '10px' }}>{left}</Typography>
    <Box sx={{ color: 'neutral.light', fontSize: '10px' }}>|</Box>
    <Typography variant='caption' sx={{ fontSize: '10px' }}>
      {right}
    </Typography>
  </Stack>
);
