import { selector } from 'recoil';
import { allVehicleAssetsState } from './allVehicleAssetsState';

// TODO: remove this useless proxy selector, and access underlying state directly
export const allVehicleAssetsSelector = selector({
  key: 'vehiclesAssets/all',
  get: ({ get }) => get(allVehicleAssetsState),
  set: ({ set }, newVehicleAssets) => {
    set(allVehicleAssetsState, newVehicleAssets);
  },
});
