import { findParameterValue } from '@/modules/common/helpers/shapes';
import { ObstacleType } from '@/modules/common/types/shapes';
import { shapeParameter } from '@recoil/shape';
import { selectedShapesState } from '@recoil/shapes/selected';
import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../../store/recoil/common';
import { isObstacleShape } from '../../../modules/common/types/guards';

export const obstacleTypeState = selector<ObstacleType>({
  key: 'propertyPanelObstacleTypeState',
  get: ({ get }) => findParameterValue(get(selectedShapesState), 'obstacleType'),
  set: ({ get, set }, type: ObstacleType) => {
    get(selectedShapesState).forEach((shape) => {
      if (!isObstacleShape(shape)) return;

      set(shapeParameter(shape.id), {
        ...shape.parameters,
        obstacleType: type,
      });
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const obstacleHeightState = selector<number>({
  key: 'propertyPanelObstacleHeightState',
  get: ({ get }) => findParameterValue(get(selectedShapesState), 'height'),
  set: ({ get, set }, value: number) => {
    get(selectedShapesState).forEach((shape) => {
      if (!isObstacleShape(shape)) return;

      set(shapeParameter(shape.id), {
        ...shape.parameters,
        height: value,
      });
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
