export const loadAutodeskScripts = () => Promise.all([loadScript(), loadStyle()]);

const loadScript = () =>
  new Promise<void>((resolve, reject) => {
    const id = 'autodeskScript';
    if (document.querySelector(`#${id}`)) {
      resolve();
      return;
    }
    const element = document.createElement('script');
    element.id = id;
    element.type = 'text/javascript';
    element.src =
      'https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/viewer3D.min.js';

    element.onload = () => {
      resolve();
    };
    element.onerror = () => {
      console.error('Unable to download Autodesk Viewer script');
      reject();
    };
    document.head.appendChild(element);
  });

const loadStyle = () =>
  new Promise<void>((resolve, reject) => {
    const id = 'autodeskStyle';
    if (document.querySelector(`#${id}`)) {
      resolve();
      return;
    }
    const element = document.createElement('link');
    element.href =
      'https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/style.min.css';
    element.type = 'text/css';
    element.rel = 'stylesheet';
    element.id = id;

    element.onload = () => {
      resolve();
    };
    element.onerror = () => {
      console.error('Unable to download Autodesk Viewer script');
      reject();
    };
    document.head.appendChild(element);
  });
