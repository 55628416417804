import { SvgIcon } from '@mui/material';
import { memo } from 'react';

function SvgComponent() {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'>
      <path d='M140-130.769 110.769-160l272.308-272.308 160 160 279.385-320 28.307 26.77-306.154 353.231-161.538-161.539L140-130.769Zm0-240L110.769-400l272.308-272.308 160 160 279.385-320 28.307 26.77-306.154 353.231-161.538-161.539L140-370.769Z' />
    </SvgIcon>
  );
}

const StackedLineChart = memo(SvgComponent);
export default StackedLineChart;
