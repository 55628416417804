import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../common';
import inputAtom from './atom';
import { InputContext } from './constants';

const contextInputState = selector<InputContext>({
  key: 'input/contextState',
  get: ({ get }) => get(inputAtom).context,
  set: ({ get, set }, newValue) => {
    set(inputAtom, { ...get(inputAtom), context: newValue });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default contextInputState;
