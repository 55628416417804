import { theme } from '@common/components/theme';
import { SVGProps, memo } from 'react';

function SvgComponent({ className = '', ...rest }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      style={{ stroke: 'none', fill: theme.palette.primary.main }}
      className={className}
      {...rest}
    >
      <path d='M10.6 15.5077L16.9962 9.11155L16.2885 8.40385L10.6 14.0923L7.75 11.2423L7.0423 11.95L10.6 15.5077ZM5.61538 20C5.15513 20 4.77083 19.8458 4.4625 19.5375C4.15417 19.2292 4 18.8449 4 18.3846V5.61537C4 5.15512 4.15417 4.77083 4.4625 4.4625C4.77083 4.15417 5.15513 4 5.61538 4H18.3846C18.8449 4 19.2292 4.15417 19.5375 4.4625C19.8458 4.77083 20 5.15512 20 5.61537V18.3846C20 18.8449 19.8458 19.2292 19.5375 19.5375C19.2292 19.8458 18.8449 20 18.3846 20H5.61538Z' />
    </svg>
  );
}

const CheckboxCheckedIcon = memo(SvgComponent);
export default CheckboxCheckedIcon;
