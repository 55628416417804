import { availableLoadCarrierTypesState } from "@/store/recoil/loadCarrierTypes/availableLoadCarrierTypes";
import { useLoadCarrierService } from "./useLoadCarrierService";
import { useRecoilState, useSetRecoilState } from 'recoil';
import { enabledLoadCarrierTypesIdsState, enabledLoadCarrierTypesSelector } from "@/store/recoil/loadCarrierTypes";

export const useLoadLoadCarriers = () => {
    const { getAllLoadCarriers } = useLoadCarrierService();
    const [availableLoadCarriers, setAvailableLoadCarriers] = useRecoilState(availableLoadCarrierTypesState);
    const setEnabledLoadCarrierTypes = useSetRecoilState(enabledLoadCarrierTypesSelector);
    const [enabledLoadCarrierTypesIds, setEnabledLoadCarrierTypesIds] = useRecoilState(enabledLoadCarrierTypesIdsState);

    const fetchLoadCarriers = async () => {
        if (availableLoadCarriers.length <= 0) {
          const allLoadCarriers = await getAllLoadCarriers();
          setAvailableLoadCarriers(allLoadCarriers);
          const enabledLoadCarriers = allLoadCarriers.filter(l => enabledLoadCarrierTypesIds.find(i => i === l.id));
          if (enabledLoadCarriers.length < enabledLoadCarrierTypesIds.length) {
            setEnabledLoadCarrierTypesIds(enabledLoadCarriers.map(l => l.id));
          }
          setEnabledLoadCarrierTypes(enabledLoadCarriers);
        }
      };
    

    return { fetchLoadCarriers };
}