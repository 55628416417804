import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import commonLocaleEn from './assets/locales/en/common.json';
import interfaceLocaleEn from './assets/locales/en/interface.json';
import errorsLocaleEn from './assets/locales/en/errors.json';

import commonLocaleJa from './assets/locales/ja/common.json';
import interfaceLocaleJa from './assets/locales/ja/interface.json';
import errorsLocaleJa from './assets/locales/ja/errors.json';

import commonLocaleNl from './assets/locales/nl/common.json';
import interfaceLocaleNl from './assets/locales/nl/interface.json';
import errorsLocaleNl from './assets/locales/nl/errors.json';

// Include here b/c of issues with lazy loading (backend, see below)
const languages = {
  en: {
    common: commonLocaleEn,
    interface: interfaceLocaleEn,
    errors: errorsLocaleEn,
  },
  ja: {
    common: commonLocaleJa,
    interface: interfaceLocaleJa,
    errors: errorsLocaleJa,
  },
  nl: {
    common: commonLocaleNl,
    interface: interfaceLocaleNl,
    errors: errorsLocaleNl,
  },
};

i18n
  // .use(ICU) // Specialised formatting, not required yet

  // Leaving backend out for now b/c lazy loading causes all kinds of errors
  // related to useTranslation as it triggers a suspense unless set to sync instead
  // of async
  // .use(Backend) // (loading of external translation files)
  .use(LanguageDetector)
  .use(initReactI18next) // if not using I18nextProvider
  .init({
    ns: ['common', 'interface'], // which namespaces to load
    load: 'languageOnly', // only language, not region
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: 'en',
    supportedLngs: ['en', 'ja', 'nl'],
    detection: {
      order: ['navigator'],
      caches: [],
    },
    // backend: {
    // 	// for all available options read the backend's repository readme file
    // 	loadPath: "/locales/{{lng}}/{{ns}}.json",
    // },

    resources: languages,

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    // react i18next special options (optional)
    react: {
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
    },
  });

export default i18n;
