import { memo } from 'react';

function SvgComponent({ className = '', ...rest }) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
      fill='none'
      style={{ stroke: 'currentColor' }}
      className={className}
      {...rest}
    >
      <path
        d='M16.241 7.758L7.75602 16.243M16.241 16.243L7.75602 7.758M22.999 12C22.999 18.0751 18.0742 23 11.999 23C5.92389 23 0.999023 18.0751 0.999023 12C0.999023 5.92487 5.92389 1 11.999 1C18.0742 1 22.999 5.92487 22.999 12Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

const RemoveCircle = memo(SvgComponent);
export default RemoveCircle;
