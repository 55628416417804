import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../common';
import { obstacleIdsAtom } from './obstacleIdsAtom';

export const deleteObstacleIdsSelector = selector<string[]>({
  key: 'obstacle/ids/delete',
  get: () => null,
  set: ({ get, set }, deleteIds: string[]) => {
    const ids = new Set(get(obstacleIdsAtom));
    deleteIds.forEach((id) => ids.delete(id));
    set(obstacleIdsAtom, Array.from(ids));
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
