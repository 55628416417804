import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../common';
import shapeAtom from './atom';

export const shapesProperty = selector({
  key: 'shapesProperty',
  get: () => null,
  set: ({ get, set }, newValues: any[]) => {
      newValues.forEach((value) => {
        set(shapeAtom(value.id), {
          ...get(shapeAtom(value.id)),
          properties: value.props,
        }); 
      })
    },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
