import DeleteIcon from '@/assets/icons/DeleteIcon';
import IndeterminateCheckBoxOutlined from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { IconButton, Stack, Typography } from '@mui/material';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useState } from 'react';
import { numberSelectedVersionsSelector, versionIdsSelector, versionSelector } from '../store/versions';

const iconAction = {
    color: 'neutral.dark',
    borderRadius: 0,
    '&:active, &:hover, &.active': {
        color: 'primary.main',
    },
};

function SelectedItemsInfo({ sx }) {
    const selectedVersions = useRecoilValue(numberSelectedVersionsSelector);
    const [selectButtonState, setSelectButtonState] = useState(false);

    const selectAllVersions = useRecoilCallback(
        ({ set, snapshot }) =>
            async () => {
                setSelectButtonState(!selectButtonState);
                const ids = await snapshot.getPromise(versionIdsSelector);
                ids.forEach((item) => {
                    set(versionSelector(item), (state) => ({ ...state, isSelected: !selectButtonState }));
                });
            },
        [selectButtonState],
    );

    return (
        <>
            {selectedVersions.length > 0
                &&
                <Stack direction='row' gap={1} justifyContent='space-between' sx={sx}>
                    <Stack direction='row' alignItems='center'>
                        <IconButton onClick={selectAllVersions} sx={iconAction}>
                            <IndeterminateCheckBoxOutlined />
                        </IconButton>
                        <Typography align='left' variant='h4'>
                            {selectedVersions.length} selected
                        </Typography>
                    </Stack>
                    <IconButton sx={iconAction} disabled>
                        <DeleteIcon />
                    </IconButton>
                </Stack>
            }
        </>
    );
}

export default SelectedItemsInfo;