import { MutableRefObject, useCallback } from 'react';

export const useEnterBlur = (ref: MutableRefObject<HTMLInputElement>) => {
  const onKeyUp = useCallback(
    (e) => {
      if (e.key === 'Enter') ref.current.blur();
    },
    [ref],
  );

  return {
    onKeyUp,
  };
};
