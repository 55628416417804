import { selector } from 'recoil';

import { ShapeGroup } from '@/modules/common/types/shapeGroup';
import { selectedGroupsSelector } from '@/modules/shapeGroups';
import { isArea, isPosition } from '@modules/common/helpers/shapes';
import { DTShape, ShapeType } from '@modules/common/types/shapes';
import { TemplateType } from '@/modules/common/types/templating';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { selectedShapesState } from '@recoil/shapes/selected';

const hasOnlyAreas = (shapes: DTShape[]) => shapes.every((shape) => isArea(shape.type));
const hasOnlyHighways = (shapes: DTShape[]) =>
  shapes.every((shape) => shape.type === ShapeType.HIGHWAY);
const hasOnlyAngledHighways = (shapes: DTShape[]) =>
  shapes.every((shape) => shape.type === ShapeType.HIGHWAY_ANGLED);
const hasOnlyProcessTwoEp = (shapes: DTShape[]) =>
  shapes.every((shape) => shape.type === ShapeType.PROCESS_TWO_EP);
const hasOnlyWalls = (shapes: DTShape[]) => shapes.every((shape) => shape.type === ShapeType.WALL);
const hasOnlyObstacles = (shapes: DTShape[]) =>
  shapes.every((shape) => shape.type === ShapeType.OBSTACLE);
const hasOnlyPositions = (shapes: DTShape[]) => shapes.every((shape) => isPosition(shape.type));
export const hasSomePositions = (shapes: DTShape[]) =>
  shapes.some((shape) => isPosition(shape.type));
const hasOnlySingleLassieGroup = (shapes: DTShape[], groups: ShapeGroup[]) => {
  if (groups.length !== 1) return false;

  const group = groups[0];
  if (
    group.type === TemplateType.LASSIE &&
    group.children.length === shapes.length &&
    shapes.every((shape) => group.children.includes(shape.id))
  )
    return true;

  return false;
};

export enum PanelDisplayMode {
  AREA = 0,
  HIGHWAYS = 1,
  WALLS = 2,
  OBSTACLES = 3,
  ELEMENTS = 4,
  NONE = 5,
  POSITIONS = 6,
  ANGLED_HIGHWAYS = 7,
  PROCESS_TWO_EP = 8,
  TEMPLATE_LASSIE = 9,
}

export const panelModeState = selector<PanelDisplayMode>({
  key: 'propertiesPanelMode',
  get: ({ get }) => {
    const shapes = get(selectedShapesState);

    if (!shapes.length) {
      return PanelDisplayMode.NONE;
    }

    if (hasOnlyAreas(shapes)) {
      return PanelDisplayMode.AREA;
    }

    if (hasOnlyHighways(shapes)) {
      return PanelDisplayMode.HIGHWAYS;
    }

    if (hasOnlyWalls(shapes)) {
      return PanelDisplayMode.WALLS;
    }

    if (hasOnlyObstacles(shapes)) {
      return PanelDisplayMode.OBSTACLES;
    }

    if (hasOnlyPositions(shapes)) {
      return PanelDisplayMode.POSITIONS;
    }

    if (hasOnlyAngledHighways(shapes)) {
      return PanelDisplayMode.ANGLED_HIGHWAYS;
    }

    if (hasOnlyProcessTwoEp(shapes)) {
      return PanelDisplayMode.PROCESS_TWO_EP;
    }

    if (hasOnlySingleLassieGroup(shapes, get(selectedGroupsSelector))) {
      return PanelDisplayMode.TEMPLATE_LASSIE;
    }

    return PanelDisplayMode.ELEMENTS;
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
