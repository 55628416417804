import { selector } from 'recoil';
import floorplanAtom from '@recoil/floorplan/atom';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import { hasUnsavedChangesSelector } from './hasUnsavedChangesSelector';
import { isLatestSelector } from './isLatestSelector';

/**
 * Display version of the currently loaded floor plan
 */
export const displayVersionSelector = selector<number>({
  key: 'floorplan/displayVersion',
  get: ({ get }) => {
    const { version } = get(floorplanAtom);
    return get(isLatestSelector) ? (get(hasUnsavedChangesSelector) ? version : version - 1) : version;
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
