import { SvgIcon } from '@mui/material';

export default function WallMenuIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d='m 20.5,4 h -17 c -0.552285,0 -1,0.447715 -1,1 v 14 c 0,0.5523 0.447715,1 1,1 h 17 c 0.5523,0 1,-0.4477 1,-1 V 5 c 0,-0.552285 -0.4477,-1 -1,-1 z'      
        strokeLinecap='butt'
        strokeLinejoin='butt'
      />
      <path d='m 2.5,8 h 19'  strokeLinecap='butt' strokeLinejoin='butt' />
      <path d='m 2.5,12 h 19'  strokeLinecap='butt' strokeLinejoin='butt' />
      <path d='m 2.5,16 h 19'  strokeLinecap='butt' strokeLinejoin='butt' />
      <path d='M 12,4 V 8'  strokeLinecap='butt' strokeLinejoin='butt' />
      <path d='m 12,12 v 4'  strokeLinecap='butt' strokeLinejoin='butt' />
      <path d='m 8.5,16 v 4'  strokeLinecap='butt' strokeLinejoin='butt' />
      <path d='m 15.5,16 v 4'  strokeLinecap='butt' strokeLinejoin='butt' />
      <path d='m 8.5,8 v 4'  strokeLinecap='butt' strokeLinejoin='butt' />
      <path d='m 15.5,8 v 4'  strokeLinecap='butt' strokeLinejoin='butt' />
      <path d='M 17.5,4 V 8'  strokeLinecap='butt' strokeLinejoin='butt' />
      <path d='M 6.5,4 V 8'  strokeLinecap='butt' strokeLinejoin='butt' />
      <path d='m 17.5,12 v 4'  strokeLinecap='round' strokeLinejoin='round' />
      <path d='m 6.5,12 v 4'  strokeLinecap='round' strokeLinejoin='round' />
    </SvgIcon>
  );
}
