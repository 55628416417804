import { ControlPoint } from '@/modules/common/types/shapes';
import { BoundingBox } from '@helpers/types';

export enum PropertyPath {
  PROPERTIES = 'properties',
  PROPERTIES_CONTROLPOINTS = 'properties.controlPoints',
}

export type PropertyTrackerMapEntry =
  | {
      path: PropertyPath.PROPERTIES;
      boundingBox: BoundingBox;
      angle: number;
    }
  | {
      path: PropertyPath.PROPERTIES_CONTROLPOINTS;
      value: ControlPoint[];
    };

export type PropertyTrackerMap = Map<string, PropertyTrackerMapEntry>;
