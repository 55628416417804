import { useConfig } from '@/modules/common/hooks';
import { Module } from '@/modules/common/types/navigation';
import { groupIdSelector, projectIdSelector } from '@/modules/floorplan';
import { LoadingPanel } from '@common/components/LoadingPanel';
import { Box, Stack } from '@mui/material';
import { FleetTracker } from '@thive/fleet-tracker.web-gl.react';
import { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import navAtom from '../../../../store/recoil/nav/atom';
import { useDigitalTwinFleetTrackerAdaptor } from '../../hooks/useDigitalTwinFleetTrackerAdaptor';
import { useFleetTrackerWebglApi } from '../../hooks/useFleetTrackerWebglApi';
import { useLoading } from '../../hooks/useLoading';
import { PlaybackControl } from '../PlaybackControl';
import { VisualizationToolsetHorizontal } from '../Toolset';
import { useVikingAdaptor } from './hooks/useVikingAdaptor';
import { useVisualization } from './hooks/useVisualization';

type Props = {
  onUnloaded: () => void;
};

export type VisualizationRef = {
  requestUnload: () => void;
};

export const Visualization = forwardRef(
  ({ onUnloaded }: Props, ref: ForwardedRef<VisualizationRef>) => {
    const { simulationId, floorPlanVersionId } = useParams();
    const { isLoaded: isFleetTrackerLoaded } = useLoading();
    const { fleetTracker } = useConfig();
    const {
      ref: fleetTrackerRef,
      requestUnload,
      onEvent,
      onLoading,
      assetLoaded,
      sessionKey,
    } = useVisualization();
    const { openFile, vikingConnected } = useVikingAdaptor();
    const { registerAdapter, drawFloorPlan, drawFromLocalState } =
      useDigitalTwinFleetTrackerAdaptor();
    const { sendPlay } = useFleetTrackerWebglApi();
    const [drawn, setDrawn] = useState(false);
    const nav = useRecoilValue(navAtom);

    // visualization mode
    useEffect(() => {
      if (nav !== Module.VISUALISATION || !simulationId || !sessionKey || !assetLoaded) return;

      if (!vikingConnected) {
        openFile(simulationId, sessionKey);
      } else {
        drawVisualizationMode();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionKey, assetLoaded, vikingConnected]);

    // layout mode
    useEffect(() => {
      if (nav !== Module.VIEWER || !assetLoaded || drawn) return;
      registerAdapter();
      drawFromLocalState();
      sendPlay();
      setDrawn(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetLoaded]);

    useImperativeHandle(
      ref,
      () => ({
        requestUnload: () => requestUnload(),
      }),
      [requestUnload],
    );

    const drawVisualizationMode = useRecoilCallback(
      ({ snapshot }) =>
        async () => {
          const projectId = await snapshot.getPromise(projectIdSelector);
          const groupId = await snapshot.getPromise(groupIdSelector);
          drawFloorPlan(projectId, groupId, floorPlanVersionId, false);
        },
      [drawFloorPlan],
    );

    return (
      <Box height='100%' position='relative' overflow='hidden'>
        <Box left='0' right='0' top='0' bottom='0' position='absolute'>
          {!isFleetTrackerLoaded && <LoadingPanel />}
          <FleetTracker
            ref={fleetTrackerRef}
            onLoading={onLoading}
            onUnloaded={onUnloaded}
            webglUrl={fleetTracker.webglUrl}
            gatewayUrl={fleetTracker.gatewayUrl}
            assetUrl={fleetTracker.assetUrl}
            onEvent={onEvent}
            showVersionInfo
          />
          {nav === Module.VISUALISATION && (
            <Stack
              left='0'
              right='0'
              bottom='0'
              spacing={2}
              position='absolute'
              direction='row'
              justifyContent='flex-end'
              zIndex='2'
              sx={{
                width: '100%',
                padding: '1rem',
              }}
            >
              <PlaybackControl vikingConnected={vikingConnected} />
              <VisualizationToolsetHorizontal />
            </Stack>
          )}
        </Box>
      </Box>
    );
  },
);
