import { useEffect } from 'react';
import { RIGHT_PANEL_WIDTH } from '@components/PropertiesPanel';
import { BUILDER_BAR_HEIGHT } from '@components/NavigationBar/BuilderBar';
import { useSetRecoilState } from 'recoil';
import { viewportSelector } from '@recoil/workspace';
import { useTheme } from '@mui/material/styles';

export const useCanvasViewportSetup = () => {
  const setViewport = useSetRecoilState(viewportSelector);
  const theme = useTheme();

  useEffect(() => {
    const onWindowResize = () => {
      if (window.innerWidth > theme.breakpoints.values.sm) {
        setViewport({
          w: window.innerWidth - RIGHT_PANEL_WIDTH,
          h: window.innerHeight - BUILDER_BAR_HEIGHT,
        });
      } else {
        setViewport({
          w: window.innerWidth,
          h: window.innerHeight,
        });
      }
    };
    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
