import { useCallback, useEffect, useRef } from 'react';
import { useRecoilCallback } from 'recoil';
import { isCancelError } from '@modules/api/helpers';

import { currentGroupSelector } from '../store/group';
import { useSimulationApi, useSimulationGroupCallbacks } from '.';
import { randomizeTimeout } from '../helpers/simulation';
import { Simulation } from '../helpers/types';
import { IN_PROGRESS_STATUSES } from '../helpers/constants';

/**
 * Polls for simulation updates
 */
export const useSimulationGroupUpdater = () => {
  const { fetchGroupDetails } = useSimulationApi();
  const { updateSimulation, updateGroup } = useSimulationGroupCallbacks();
  const handle = useRef<NodeJS.Timer>();
  const abortController = useRef<AbortController>();

  useEffect(
    () => () => {
      abortController.current?.abort();
    },
    [],
  );

  const update = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        let group = await snapshot.getPromise(currentGroupSelector);

        // check if state still exists
        if (!group) {
          return;
        }
        abortController.current = new AbortController();
        try {
          const response = await fetchGroupDetails(
            group.floorPlanId,
            group.id,
            abortController.current.signal,
          );

          // check if still needs updating
          group = await snapshot.getPromise(currentGroupSelector);
          if (group && needUpdating(group)) {
            await updateGroup((simulation) => {
              if (!simulation) return;
              response.group.details.floorPlanVersion = simulation.details.floorPlanVersion;
              return response.group;
            });

            response.simulations.forEach((simulationResponse) =>
              updateSimulation(simulationResponse.id, (simulation) => {
                if (!simulation) return;
                simulationResponse.details.floorPlanVersion = simulation.details.floorPlanVersion;
                return simulationResponse;
              }),
            );
          }
        } catch (e) {
          if (!isCancelError(e)) {
            console.error(e);
          }
        }
      },
    [],
  );

  const updateSimulations = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const group = await snapshot.getPromise(currentGroupSelector);
        if (needUpdating(group)) {
          setTimeout(() => update(), randomizeTimeout());
        }
      },
    [],
  );

  const start = useCallback(async () => {
    await updateSimulations();

    handle.current = setInterval(() => updateSimulations(), 45000);
  }, [updateSimulations]);

  const stop = useCallback(() => {
    clearInterval(handle.current);
  }, []);

  return {
    start,
    stop,
  };
};

/**
 * Checks whether the given simulation needs to checked for progress
 */
export const needUpdating = (group: Simulation) => IN_PROGRESS_STATUSES.includes(group.status);
