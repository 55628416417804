import { Vector3 } from 'three';

export enum ReflectorType {
  Flat = 1,
  Round = 2,
  FlatCentered = 5,
}

export type Reflector = {
  type: ReflectorType;
  id: string;
  position: Vector3;
  angle: number;
};

export type LayoutDelta = {
  delta: Vector3;
  deltaAngle: number;
};

export type Gate = {
  id: string;
  delta: Vector3;
  deltaAngle: number;
};

export type Spline = {
  id: string;
};

export type CommissioningLayout = {
  layoutDelta: LayoutDelta;
  gates: Gate[];
  splines: Spline[];
  localizationMaps: LocalizationMap[];
};

export type FloorPlanAdjustment = {
  transformationDeltaX: number;
  transformationDeltaY: number;
  transformationAngle: number;
};

type ArtefactAdjustment = {
  deltaX: number;
  deltaY: number;
  angleInDegrees: number;
};

export type LoadPositionAdjustment = ArtefactAdjustment & {
  loadPositionName: string;
};

export type LocationAdjustment = ArtefactAdjustment & {
  locationName: string;
};

export type SevenSenseRectangle = {
  position: Vector3;
  size: Vector3;
  color: string;
  opacity: number;
};

export type SevenSenseLine = {
  p1: Vector3;
  p2: Vector3;
  color: string;
};

export type SevenSenseLayout = {
  walls: SevenSenseRectangle[];
  lines: SevenSenseLine[];
  width?: number;
  height?: number;
};

export enum LocalizationMapType {
  Kollmorgen = 0,
  SevenSense = 1,
  Navitech = 2,
}

export type LocalizationMap = {
  fileName: string;
  type: LocalizationMapType;
  timeLimitedUrl: string;
};
