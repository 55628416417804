import { useRecoilCallback } from 'recoil';
import { Unit } from '@modules/common/types/general';
import { unitSelector } from '@recoil/workspace';

export const useUnit = () => {
  const toBaseUnit = useRecoilCallback(
    ({ snapshot }) =>
      async (value: number) => {
        const unit = await snapshot.getPromise(unitSelector);

        if (unit === Unit.CENTIMETERS) {
          return value * 10;
        }

        if (unit === Unit.METERS) {
          return value * 1000;
        }

        return value;
      },
    [],
  );

  return {
    toBaseUnit,
  };
};
