import { SVGProps, memo } from 'react';

function SvgComponent({ className = '', ...rest }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      style={{ fill: 'currentColor' }}
      className={className}
      {...rest}
    >
      <path d='M4.61538 17C4.15513 17 3.77083 16.8458 3.4625 16.5375C3.15417 16.2292 3 15.8449 3 15.3846V8.61537C3 8.15512 3.15417 7.77083 3.4625 7.4625C3.77083 7.15417 4.15513 7 4.61538 7H19.3846C19.8449 7 20.2292 7.15417 20.5375 7.4625C20.8458 7.77083 21 8.15512 21 8.61537V15.3846C21 15.8449 20.8458 16.2292 20.5375 16.5375C20.2292 16.8458 19.8449 17 19.3846 17H4.61538ZM4.61538 16H19.3846C19.5385 16 19.6795 15.9359 19.8077 15.8077C19.9359 15.6795 20 15.5385 20 15.3846V8.61537C20 8.46154 19.9359 8.32052 19.8077 8.1923C19.6795 8.0641 19.5385 8 19.3846 8H16.5V11.2308H15.5V8H12.5V11.2308H11.5V8H8.5V11.2308H7.5V8H4.61538C4.46154 8 4.32052 8.0641 4.1923 8.1923C4.0641 8.32052 4 8.46154 4 8.61537V15.3846C4 15.5385 4.0641 15.6795 4.1923 15.8077C4.32052 15.9359 4.46154 16 4.61538 16Z' />
    </svg>
  );
}

const StraightenIcon = memo(SvgComponent);
export default StraightenIcon;
