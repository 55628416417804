import { useTranslation } from 'react-i18next';
import { StatusChip } from '@common/components/StatusChip';

import { SimulationStatus } from '../../helpers/types';
import { Colors } from './colors';

type Props = {
  status: SimulationStatus;
  message?: string;
};

export function SimulationStatusChip({ status, message }: Props) {
  const { t } = useTranslation('interface');

  return (
    <StatusChip
      status={t(`simulation.status_chip.status_${status}`)}
      color={Colors[status]}
      message={message}
    />
  );
}
