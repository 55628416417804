import { useErrorMessageApi } from '@/modules/Notifications/hooks/useErrorMessageApi';
import { useCallback } from 'react';
import { useNotificationStore } from '../store/useNotificationStore';
import { formatMessage } from '../helpers';

export const useErrorNotification = () => {
  const { getErrorDetails } = useErrorMessageApi();

  const addErrorNotification = useCallback(
    async (code: string, args?: string[]) => {
      const newErrorNotification = await getErrorDetails(code);
      if (args) {
        newErrorNotification.description = formatMessage(newErrorNotification.description, args);
      }
      useNotificationStore.getState().addErrorNotification(newErrorNotification);
    },
    [getErrorDetails],
  );

  return {
    addErrorNotification,
  };
};
