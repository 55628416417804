import { selectionContainsLoadCarrierSupport } from '@/store/recoil/shapes/selected';
import { Accordion } from '@common/components/Accordion';
import { PropertiesLabel } from '@common/components/PropertiesLabel';
import { Stack } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { areaSectionTitle, areaType } from '../store/area';
import AreaDirection from './AreaDirection';
import { LoadCarrierSelect } from './LoadCarrierSelect';
import { LoadCarrierSide } from './LoadCarrierSide';
import { VehicleSelect } from './VehicleSelect';

const PositionPropertyComponent = () => {
  const sectionTitle = useRecoilValue(areaSectionTitle);
  const { t } = useTranslation('interface');

  return (
    <Accordion
      title={t(sectionTitle, sectionTitle)}
      titleSx={{ textTransform: 'capitalize' }}
      defaultExpanded
      unmountOnExit
      sx={{ px: 2 }}
    >
      <PositionPropertyContent />
    </Accordion>
  );
};

const PositionPropertyContent = () => {
  const shapeType = useRecoilValue(areaType);
  const containsLoadCarrierSupport = useRecoilValue(selectionContainsLoadCarrierSupport);

  return (
    <Stack direction='column' spacing={1}>
      <PropertiesLabel i18nkey='interface:properties.area.vehicle'>
        <VehicleSelect />
      </PropertiesLabel>

      {containsLoadCarrierSupport &&
        // for now, only show when 1 shape is selected
        shapeType !== null && (
          <PropertiesLabel i18nkey='interface:properties.area.load_carrier_select.label'>
            <LoadCarrierSelect />
          </PropertiesLabel>
        )}

      <PropertiesLabel i18nkey='interface:properties.area.direction'>
        <AreaDirection />
      </PropertiesLabel>

      {containsLoadCarrierSupport && (
        <PropertiesLabel i18nkey='interface:properties.area.load_carrier_orientation.label'>
          <LoadCarrierSide />
        </PropertiesLabel>
      )}
    </Stack>
  );
};

const PositionProperty = memo(PositionPropertyComponent);
export default PositionProperty;
