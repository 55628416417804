import { NumberInput } from '@common/components/inputs';
import { PropertiesLabel } from '@modules/common/components/PropertiesLabel';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { useReferenceImage } from '@modules/referenceImage/hooks';
import { referenceOpacity } from '@modules/referenceImage/store';
import { UserPreferenceName } from '@modules/userPreferences';
import { useUpdateUserPreferences } from '@/modules/userPreferences/hooks';
import { useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

export const Opacity = () => {
  const { saveReferenceSettings } = useReferenceImage();
  const [opacity, setOpacity] = useRecoilState(referenceOpacity);
  const { updateUserPreference } = useUpdateUserPreferences();
  const workspaceMode = useRecoilValue(modeSelector);
  const disabled = workspaceMode !== WorkspaceMode.EDITABLE;

  const onOpacityChange = useCallback(
    (value: number) => {
      setOpacity(value);
      saveReferenceSettings();
      updateUserPreference(UserPreferenceName.REFERENCE_OPACITY, value);
    },
    [updateUserPreference, saveReferenceSettings, setOpacity],
  );

  if (!opacity && opacity !== 0) return null;

  return (
    <PropertiesLabel
      i18nkey='interface:reference.properties.opacity'
      defaultLabel='Opacity'
      labelRatio={50}
    >
      <NumberInput
        unit='%'
        name='Opacity'
        value={opacity}
        disabled={disabled}
        onChange={(newValue: number) => onOpacityChange(newValue)}
      />
    </PropertiesLabel>
  );
};
