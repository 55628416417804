import { useRecoilCallback } from 'recoil';
import { useMemo } from 'react';

import { useAxiosWithErrorNotifications, useFileApi } from '@/modules/api/hooks';
import { knownVehicleDetailsSelector } from '../store';
import { UnifiedVehicle } from '../types';
import { transformRestVehiclesToVehicles } from '../helpers/mappers';
import { FileReferenceResponse, ValidAssetType } from '@/modules/vehicles/types/internal';
import {
  UVT_ASSET_FILE_CONTAINER_DEFAULT,
  KM_MDB_ASSET_FILE_CONTAINER_DEFAULT,
  FLOORPLAN_ASSET_FILE_CONTAINER,
} from '@/modules/vehicles/constants';
import { useConfig } from '@/modules/common/hooks';

export const useVehicleService = () => {
  const { api } = useConfig();
  const { getFile } = useFileApi();
  const scopes = useMemo(() => [api.pimService.scope], [api.pimService.scope]);
  const axios = useAxiosWithErrorNotifications(
    { baseURL: api.pimService.url, scopes },
    {
      errorCode: (response) => response.data.code,
      errorCondition: (response) => response.status !== 200,
      serviceName: 'vehicleService, PIM API',
    },
  );

  const getAllUnifiedVehicleTypes = async (): Promise<UnifiedVehicle[]> => {
    try{
      const response = await axios.get('/v1/unifiedVehicleTypes');
      return transformRestVehiclesToVehicles(response.data);
    } catch {
      // handled in useAxiosWithErrorNotifications
    }
  };

  const fetchAndUploadAssetToFileApi = async (
    vehicleVariantName: string,
    assetType?: ValidAssetType,
  ): Promise<FileReferenceResponse | null> => {
    try {
      const url = `/v1/unifiedVehicleType/file?vehicleId=${vehicleVariantName}${
        assetType
          ? `&category=${
              assetType === 'uvt'
                ? UVT_ASSET_FILE_CONTAINER_DEFAULT
                : KM_MDB_ASSET_FILE_CONTAINER_DEFAULT
            }`
          : ''
      }`;

      const response = await axios.get(url);

      return response.data;
    } catch {
      // handled in useAxiosWithErrorNotifications
    }
  };

  const getVehicleJSONAsset = async (reference: string): Promise<any | null> => {
    try {
      const response = await getFile(reference, FLOORPLAN_ASSET_FILE_CONTAINER, 'json');

      if (response.status !== 200) {
        console.log(`Error fetching unified vehicle type asset: ${response.statusText}`);
        return null;
      }

      return await response.data;
    } catch (e) {
      console.log('Error fetching unified vehicle type asset: ', e);
      return null;
    }
  };

  const getVehicleDetailsById = useRecoilCallback(
    ({ snapshot }) =>
      async (id: string) => {
        const allDetails = await snapshot.getPromise(knownVehicleDetailsSelector);
        return allDetails.find((item) => item.id === id);
      },
    [],
  );

  return {
    getAllUnifiedVehicleTypes,
    getVehicleJSONAsset,
    getVehicleDetailsById,
    fetchAndUploadAssetToFileApi,
  };
};
