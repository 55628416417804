import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { RetryInfoText } from '@common/components/RetryInfoText';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { useNavigation, usePrevious } from '@modules/common/hooks';
import { InfoText } from '@common/components/InfoText';
import { displayVersionSelector } from '@modules/floorplan/store/floorPlan';
import { isLoadingSelector } from '../store/module';
import { versionIdsSelector, visibleVersionsCountSelector } from '../store/versions';
import { useVersioningCallbacks } from '../hooks';
import { VersionCard } from './VersionCard';
import { Module } from '@/modules/common/types/navigation';
import SelectedVersionsInfo from './SelectedVersionsInfo';
import { PropertyPanelContainer } from '@common/components/PropertyPanelContainer';
import { LoadingContainer } from '@/modules/common/components/LoadingContainer';

const boxSx = {
  display: 'flex',
  justifyContent: 'center',
  mx: 2,
  flex: '1 1 auto',
};

export const PropertyPanel = () => {
  const isLoading = useRecoilValue(isLoadingSelector);
  const [isError, setIsError] = useState(false);
  const { t } = useTranslation();
  const { loadAll } = useVersioningCallbacks();
  const versionIds = useRecoilValue(versionIdsSelector);
  const version = useRecoilValue(displayVersionSelector);
  const previousVersion = usePrevious(version);
  const visibleVersionsCount = useRecoilValue(visibleVersionsCountSelector);
  const { goToModule } = useNavigation();

  const load = useCallback(
    (skipLoader = false) => {
      setIsError(false);

      return loadAll(skipLoader).catch(() => setIsError(true));
    },
    [loadAll],
  );

  const onRetry = useCallback(() => load(), [load]);

  useEffect(() => {
    load(previousVersion && version !== previousVersion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version]);

  return (
    <LoadingContainer isLoading={isLoading}>
      <PropertyPanelContainer
        labelKey='interface:tabs.versioning.label'
        onCloseClick={() => goToModule(Module.LAYOUT)}
      >
        {isError && (
          <Stack sx={boxSx}>
            <RetryInfoText header={t('errors:versioning.list.fetch')} onRetry={onRetry} />
          </Stack>
        )}

        {!isError && visibleVersionsCount === 0 && (
          <Stack sx={boxSx}>
            <InfoText
              header={t('interface:versioning.version_list.no_versions.title')}
              subHeader={t('interface:versioning.version_list.no_versions.subtitle')}
            />
          </Stack>
        )}

        {!isError && visibleVersionsCount > 0 && (
          <>
            <SelectedVersionsInfo sx={{ margin: '15px 0 20px', padding: '0 20px 0 10px' }} />
            {versionIds.map((id) => (
              <VersionCard key={id} id={id} />
            ))}
          </>
        )}
      </PropertyPanelContainer>
    </LoadingContainer>
  );
};
