import { selector } from 'recoil';
import { Position } from '@helpers/types';

import dwgAtom from './atom';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../../store/recoil/common';

const referencePan = selector<Position>({
  key: 'reference/pan',
  get: ({ get }) => get(dwgAtom).pan,
  set: ({ get, set }, newValue) => {
    set(dwgAtom, { ...get(dwgAtom), pan: newValue });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default referencePan;
