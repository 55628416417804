import { t } from 'i18next';
import { useObjectValidation } from '@/modules/common/hooks/useObjectValidation';
import { useSnackbarStore } from '@/modules/snackbar/store/useSnackbarStore';

const requiredKeys = [
  'Name',
  'ObstructionTimeOutInSeconds',
  'OrderDistributionStrategy',
  'ManualIntervention',
  'TrafficManagementDisabled',
  'FailOnNoRouteFound',
  'CheckpointSets',
  'DecisionCheckPoints',
  'DestinationTrafficOnlyCheckpoints',
  'ForbiddenCombos',
  'OppositeDrivingDirectionPaths',
  'ParkingAndChargingLocations',
];

export const useFmsConfigObjectValidation = () => {
  const { showSnackbar } = useSnackbarStore();
  const { validateKeys } = useObjectValidation();

  const isValidFmsConfigJson = (fmsConfig: string): boolean => {
    if (!fmsConfig) {
      return false;
    }

    try {
      const parsedJson = JSON.parse(fmsConfig);
      if (!validateKeys(parsedJson, requiredKeys)) {
        throw Promise.reject();
      }

      return true;
    } catch (error) {
      console.error(error);
      showSnackbar(t('errors:invalid_data_format'));
      return false;
    }
  };

  return { isValidFmsConfigJson };
};
