import { BoundingBox } from '@/helpers/types';
import { calcOrientedLoadCarriersBoundingBox } from '@/modules/common/helpers/loadCarrier';
import { LoadCarrierType } from '@/modules/common/types/floorPlan';
import { ObstacleType, ShapeType } from '@/modules/common/types/shapes';
import { StorageType } from '@/modules/common/types/storage';
import { getShapeColor, hexToRgb } from '@/modules/workspace/helpers/colors';
import { centerPoint } from '@/modules/workspace/helpers/shape';
import {
  AreaShape,
  HighwayShape,
  ObstacleShape,
  PositionShape
} from '@/store/recoil/shape';
import { RackProperty } from '@/store/recoil/shape/types/area';
import { HIGHWAY_COLOR, RACKING_TAG, SOURCE_NAME } from '../consts';
import { Type, Variant } from '../enum';
import { ArgbColor, Vector3, VisualizationObject } from '../types';
import { getMaterialTexture, getObstacleVariant, mapPositionTypeToAreaType } from './floorPlan';
import { createElevationRack, createRack } from './rack';

const SHAPE_HEIGHT = 0.0;

export function createRectangleShape(
  shape: AreaShape | PositionShape | ObstacleShape | HighwayShape,
  box: BoundingBox,
  loadCarriers: LoadCarrierType[],
): VisualizationObject {
  let type = Type.Primitive;
  let variant = Variant.Rectangle;
  let color: ArgbColor = null;
  let overrideColor = false;
  let position: Vector3 = { X: box.x, Y: box.y, Z: SHAPE_HEIGHT };
  let scale: Vector3 = null;
  let rotation = { X: 0, Y: 0, Z: shape.properties.r };
  let data: any = null;
  let extraTags: string[] = [];

  switch (shape.type) {
    case ShapeType.OBSTACLE: {
      variant = getObstacleVariant(shape.parameters.obstacleType);
      if (shape.parameters.obstacleType === ObstacleType.Pillar || variant === Variant.Pillar) {
        type = Type.Model;
        const center = centerPoint(box, -shape.properties.r);
        position = { X: center.x, Y: center.y, Z: 0 };
      } else {
        overrideColor = true;
        scale = { X: box.width, Y: box.height, Z: shape.parameters.height };
        if (shape.parameters.obstacleType === ObstacleType.Warning_Zone) {
          data = {
            autoTile: true,
            Variant: getMaterialTexture(shape.parameters.obstacleType),
            ScaleX: 2,
            ScaleY: 2,
          };
        }
      }
      break;
    }

    case ShapeType.STORAGE_POSITION:
    case ShapeType.INTAKE_POSITION:
    case ShapeType.DELIVERY_POSITION:
    case ShapeType.PROCESS_ONE_EP_POSITION: {
      scale = { X: box.width, Y: box.height, Z: 1 };
      overrideColor = true;
      break;
    }

    case ShapeType.STORAGE:
    case ShapeType.INTAKE:
    case ShapeType.DELIVERY:
    case ShapeType.PROCESS_ONE_EP: {
      if (shape.parameters.storageType !== StorageType.RACK) {
        if (shape.parameters.loadElevation !== 0) {
          const { Type, Variant, Position, Rotation, Data } = createElevationRack(
            box,
            position,
            shape.parameters.alignment,
            shape.parameters.distribution,
            shape.parameters.direction,
            shape.parameters.gap,
            shape.parameters.margin,
            rotation.Z,
            loadCarriers[0].length,
            loadCarriers[0].width,
            1,
            [shape.parameters.loadElevation],
          );
          type = Type;
          variant = Variant;
          position = Position;
          rotation = Rotation;
          data = Data;
          extraTags.push(RACKING_TAG);
          break;
        }

        scale = { X: box.width, Y: box.height, Z: 1 };
        overrideColor = true;
        break;
      }

      const storageProperty = shape.parameters.storageProperty as RackProperty;
      const { supportedLoadCarriersIds, loadCarrierOrientation } = shape.parameters;
      const supportedLoadCarrierTypes = loadCarriers.filter(({ id }) =>
        supportedLoadCarriersIds?.includes(id),
      );
      const loadCarriersBoundingBox = calcOrientedLoadCarriersBoundingBox(
        supportedLoadCarrierTypes,
        loadCarrierOrientation,
      );
      const { Type, Variant, Position, Rotation, Data } = createRack(
        box,
        position,
        shape.parameters.alignment,
        shape.parameters.margin,
        shape.parameters.direction,
        rotation.Z,
        storageProperty.framesDeep,
        storageProperty.rackLoadsPerShelf,
        storageProperty.beamHeight,
        loadCarriersBoundingBox,
        storageProperty.loadHeight + storageProperty.clearanceTop,
        storageProperty.rackLevels,
        storageProperty.clearanceInBetween,
        storageProperty.clearanceSide,
        storageProperty.postWidth,
        storageProperty.firstShelfHeight,
      );
      type = Type;
      variant = Variant;
      position = Position;
      rotation = Rotation;
      data = Data;
      extraTags.push(RACKING_TAG);
      break;
    }
    case ShapeType.HIGHWAY: {
      type = Type.Primitive;
      variant = Variant.Plane;
      scale = { X: box.width, Y: box.height, Z: 1 };
      color = HIGHWAY_COLOR;
      break;
    }
    default:
      scale = { X: box.width, Y: box.height, Z: 1 };
      overrideColor = true;
      break;
  }

  if (overrideColor) {
    const hexColor = getShapeColor(shape.type, true);
    const stdColor = hexToRgb(hexColor);
    color = { Alpha: stdColor.a, R: stdColor.r, G: stdColor.g, B: stdColor.b };
  }

  return {
    Type: type,
    Variant: variant,
    Position: position,
    Scale: scale,
    Rotation: rotation,
    Source: SOURCE_NAME,
    Name: shape.name,
    Color: color,
    Tags: [mapPositionTypeToAreaType(shape.type), ...extraTags], // e.g. tag "intake_position" as "intake"
    Data: data,
  };
}
