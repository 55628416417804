import { activeFlowState } from '@modules/flows/store/layout';
import { memo } from 'react';
import { useRecoilValue } from 'recoil';
import { FlowDirection } from '../../components';
import { FlowScope } from '../../types';
import { IconButton, Stack } from '@mui/material';
import { PropertiesTitle } from '@/modules/common/components/PropertiesTitle';
import { RemoveIcon } from '@/assets/icons';
import { PropertiesLabel } from '@/modules/common/components/PropertiesLabel';
import { LoadPerHourInput } from '@/modules/flows/components/LoadPerHourInput';
import VehicleLimit from '@/components/PropertiesPanel/layout/VehicleLimit';
import { FlowGraph } from '@/modules/flows/components/FlowGraph';

const ActiveFlowComponent = () => {
  const activeFlow = useRecoilValue(activeFlowState);

  if (!activeFlow) return null;

  const { name, sourceName, targetName, totalNumLoads, loadProfile, vehicleLimit } = activeFlow;

  return (
    <Stack spacing={1} sx={{ flexDirection: 'column', mb: 2 }}>
      <Stack direction='row' width='100%' alignItems='center'>
        <PropertiesTitle
          editable={false}
          fontSize={14}
          value={name}
          sx={{ flex: '1 1 auto' }}
          onChange={() => {}}
        />
        <IconButton onClick={() => {}} sx={{ marginY: 'auto', p: 0, flex: '0 0 auto' }} disabled>
          <RemoveIcon />
        </IconButton>
      </Stack>
      <FlowDirection sourceName={sourceName} targetName={targetName} />
      {!loadProfile || loadProfile.length === 0 ? (
        <PropertiesLabel
          labelRatio={70}
          i18nkey='interface:properties.flows.flow_info.total_loads.label'
        >
          <LoadPerHourInput
            id=''
            flowScope={FlowScope.LAYOUT}
            totalNumLoads={totalNumLoads}
            disabled
          />
        </PropertiesLabel>
      ) : (
        <FlowGraph loadProfile={loadProfile} flowScope={FlowScope.LAYOUT} />
      )}
      <PropertiesLabel labelRatio={70} skipFocus i18nkey='interface:properties.highway.vehicles'>
        <VehicleLimit vehicleLimit={vehicleLimit} onChange={() => {}} disabled />
      </PropertiesLabel>
    </Stack>
  );
};

export const ActiveFlow = memo(ActiveFlowComponent);
