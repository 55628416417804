import { useRecoilCallback } from 'recoil';
import { Project } from '@modules/api/hooks/useProjectApi';

import { projectState } from '../store/project';

export const useLoadProject = () => {
  const load = useRecoilCallback(
    ({ set }) =>
      (project: Project) => {
        set(projectState, {
          id: project.id,
          name: project.name,
        });
      },
    [],
  );

  return {
    load,
  };
};
