import { useRecoilCallback } from 'recoil';

import { layoutAllFlowIds } from '../store/layout';
import { useGetFlowLinkRelatedToFlowId } from './useGetFlowLinkRelatedToFlowId';
import { FlowNode, FlowStopType } from '../types';

export const useGetAllFlowNodesRelatedToFlowId = () => {
  const getFlowLinkRelatedToFlowId = useGetFlowLinkRelatedToFlowId();

  const filterFlowNodes = (currentShapes: string[], newShapes: string[], directionToNode: 'forward' | 'backward'): FlowNode[] => 
    newShapes.filter((shape) => !currentShapes.includes(shape)).map((x) =>  
      ({
        id: x, 
        directionToNode
      })
    )

  const getAllFlowNodesRelatedToFlowId = useRecoilCallback(({ snapshot }) => async (shapeId: string, startDirection?: '' | 'forward' | 'backward'): Promise<FlowNode[]> => {
    const allFlowIds = await snapshot.getPromise(layoutAllFlowIds);
    const promises = allFlowIds.map((id) => getFlowLinkRelatedToFlowId(id));
    const flowLinks = await Promise.all(promises);
    const flowNodes: FlowNode[] = [{id: shapeId, directionToNode: startDirection }]

    for (let i = 0; i < flowNodes.length; i++) {
      const currentId = flowNodes[i].id
      const directionToCurrentShape = flowNodes[i].directionToNode
      const flowLinksWithCurrentId = flowLinks.filter((flowLink) => flowLink.from.includes(currentId) || flowLink.to.includes(currentId))
      
      flowLinksWithCurrentId.forEach(flowLinkWithCurrentId => {
        if (flowLinkWithCurrentId.from.includes(currentId)) {
          if (
            flowLinkWithCurrentId.fromType !== FlowStopType.PROCESS || (
            flowLinkWithCurrentId.fromType === FlowStopType.PROCESS && 
            directionToCurrentShape === 'backward')
          ) {
            flowNodes.push(...filterFlowNodes(flowNodes.map(node => node.id), flowLinkWithCurrentId.to, 'forward'))
          } 
        } else if (flowLinkWithCurrentId.to.includes(currentId)) {
          if ( 
            flowLinkWithCurrentId.toType !== FlowStopType.PROCESS || ( 
            flowLinkWithCurrentId.toType === FlowStopType.PROCESS &&
            directionToCurrentShape === 'forward') 
          ) {
            flowNodes.push(...filterFlowNodes(flowNodes.map(node => node.id), flowLinkWithCurrentId.from, 'backward'))
          }
        }
      });
    }

    return flowNodes    
  });

  return getAllFlowNodesRelatedToFlowId;
};
