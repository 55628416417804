import { Canvas } from '@/modules/canvas';
import { useLayoutDraw } from '../hooks';
import { useInteraction } from '../hooks/useInteraction';

export const Workspace = () => {
  useInteraction();
  useLayoutDraw();

  return <Canvas />;
};
