import { SvgIcon } from '@mui/material';

const CompactViewIcon = ({ className = '', size = 24, color = 'currentColor', ...rest }) => (
  <SvgIcon
    viewBox='0 0 20 15'
    sx={{ width: size, height: size, fontSize: `${size}px`, fill: color }}
    className={className}
    {...rest}
  >
    <path
      d='M0.961426 14.4039V0.596191H19.0384V14.4039H0.961426ZM1.96145 4.86542H5.23068V1.59619H1.96145V4.86542ZM6.23068 4.86542H9.4999V1.59619H6.23068V4.86542ZM10.4999 4.86542H13.7691V1.59619H10.4999V4.86542ZM14.7691 4.86542H18.0384V1.59619H14.7691V4.86542ZM14.7691 9.13467H18.0384V5.86542H14.7691V9.13467ZM10.4999 9.13467H13.7691V5.86542H10.4999V9.13467ZM6.23068 9.13467H9.4999V5.86542H6.23068V9.13467ZM5.23068 5.86542H1.96145V9.13467H5.23068V5.86542ZM14.7691 13.4039H18.0384V10.1347H14.7691V13.4039ZM10.4999 13.4039H13.7691V10.1347H10.4999V13.4039ZM6.23068 13.4039H9.4999V10.1347H6.23068V13.4039ZM1.96145 13.4039H5.23068V10.1347H1.96145V13.4039Z'
      fill={color}
    />
  </SvgIcon>
);

export default CompactViewIcon;
