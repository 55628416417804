import './styling';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as ResizeObserverModule from 'resize-observer-polyfill';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';

import { App } from './App';
import './i18n';

import { msalConfig } from '@/modules/authentication/authConfig';
import { getConfig } from '@/modules/common/helpers/config';

const instance = new PublicClientApplication(msalConfig);

// Initialize App Insights
const reactPlugin = new ReactPlugin();
const { azure } = getConfig();
const appInsights = new ApplicationInsights({
  config: {
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
    instrumentationKey: azure.insightsInstrumentationKey,
    connectionString: azure.insightsConnectionString,
  },
});
appInsights.loadAppInsights();

(global as any).ResizeObserver = ResizeObserverModule.default;

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <MsalProvider instance={instance}>
        <AppInsightsContext.Provider value={reactPlugin}>
          <App />
        </AppInsightsContext.Provider>
      </MsalProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
