import { useFloorPlanGroupApi } from '@/modules/api/hooks';
import { Button, Grid, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { DEBUG_ACTION_SIZE, DEBUG_DESCRIPTION_SIZE } from './DebugPanel';

export const OpenAllFloorplanButton = () => {
  const { projectId } = useParams();
  const { fetchAll } = useFloorPlanGroupApi();

  const loadAll = useCallback(async () => {
    const groups = await fetchAll(projectId);
    groups.forEach((group) => {
      window.open(`/${projectId}/builder/${group.id}/version/${group.floorPlanId}`);
    });
  }, [fetchAll, projectId]);

  return (
    <>
      <Grid item xs={DEBUG_DESCRIPTION_SIZE}>
        <Typography>Open all floorplans in new tab</Typography>
      </Grid>
      <Grid item xs={DEBUG_ACTION_SIZE}>
        <Button onClick={loadAll}>Click</Button>
      </Grid>
    </>
  );
};
