import { SvgIcon } from '@mui/material';
import { memo } from 'react';

function SvgComponent() {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M6.46152 13C6.18652 13 5.9511 12.9021 5.75527 12.7063C5.55943 12.5104 5.46152 12.275 5.46152 12C5.46152 11.725 5.55943 11.4896 5.75527 11.2938C5.9511 11.0979 6.18652 11 6.46152 11C6.73653 11 6.97196 11.0979 7.16779 11.2938C7.36363 11.4896 7.46154 11.725 7.46154 12C7.46154 12.275 7.36363 12.5104 7.16779 12.7063C6.97196 12.9021 6.73653 13 6.46152 13ZM12 13C11.725 13 11.4896 12.9021 11.2937 12.7063C11.0979 12.5104 11 12.275 11 12C11 11.725 11.0979 11.4896 11.2937 11.2938C11.4896 11.0979 11.725 11 12 11C12.275 11 12.5104 11.0979 12.7062 11.2938C12.9021 11.4896 13 11.725 13 12C13 12.275 12.9021 12.5104 12.7062 12.7063C12.5104 12.9021 12.275 13 12 13ZM17.5385 13C17.2635 13 17.028 12.9021 16.8322 12.7063C16.6364 12.5104 16.5384 12.275 16.5384 12C16.5384 11.725 16.6364 11.4896 16.8322 11.2938C17.028 11.0979 17.2635 11 17.5385 11C17.8135 11 18.0489 11.0979 18.2447 11.2938C18.4406 11.4896 18.5385 11.725 18.5385 12C18.5385 12.275 18.4406 12.5104 18.2447 12.7063C18.0489 12.9021 17.8135 13 17.5385 13Z' />
    </SvgIcon>
  );
}

const MoreHorizontalIcon = memo(SvgComponent);
export default MoreHorizontalIcon;
