import { isProcessAreaOneEp, isProcessAreaTwoEp } from '@/modules/common/types/guards';
import { getProcessIdsWithUnbalancedInboundOutboundFlows } from "@/modules/floorplanValidation/clientSide/helpers";
import { layoutFlowsSelector } from "@/modules/flows/store/layout";
import { RECOIL_SELECTOR_CACHE_POLICY } from "@/store/recoil/common";
import { allShapesSelector } from "@/store/recoil/shapes";
import { selector } from "recoil";

export const unbalancedProcessFlowsSelector = selector<string[]>({
  key: 'prevalidation/unbalancedProcessFlows',
  get: ({ get }) => {
    const allShapes = get(allShapesSelector);
    const processAreas = allShapes.filter(isProcessAreaOneEp);
    const processTwoEndPointShapes = allShapes.filter(isProcessAreaTwoEp);
    const layoutFlows = get(layoutFlowsSelector);
    return getProcessIdsWithUnbalancedInboundOutboundFlows(processAreas, processTwoEndPointShapes, layoutFlows);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
