import { useCanvasStore } from '@/modules/canvas';
import { useCallback, useEffect, useState } from 'react';
import { drawConnections } from '../draw/drawConnections';
import { drawLayoutPoints } from '../draw/drawLayoutPoints';
import { use3TE25LayoutStore } from '../store';

export const useLayoutDraw = () => {
  const { layoutPoints, unitData } = use3TE25LayoutStore();
  const [drawn, setDrawn] = useState(false);

  const drawLayout = useCallback(() => {
    const canvas = useCanvasStore.getState()?.instance;
    if (!canvas) return;

    const { unitData, layoutPoints, setConnectionShapes, setUnitShapes } =
      use3TE25LayoutStore.getState();
    const connections = drawConnections(unitData, layoutPoints);
    const units = drawLayoutPoints(layoutPoints);
    const objects = [...units.elements, ...connections.elements];
    canvas.updateElements(objects);
    setConnectionShapes(connections.shapes);
    setUnitShapes(units.shapes);
  }, []);

  useEffect(() => {
    if (drawn || !layoutPoints || !unitData) return;

    console.log('draw');
    drawLayout();
    setDrawn(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layoutPoints, unitData]);
};
