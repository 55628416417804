import { selector } from 'recoil';

import { RECOIL_SELECTOR_CACHE_POLICY } from '@/store/recoil/common';
import { simulationAtom } from './simulationAtom';

export const failOnNoRouteFoundSelector = selector<boolean>({
  key: 'simulations/draft/failOnNoRouteFound',
  get: ({ get }) => !!get(simulationAtom)?.failOnNoRouteFound,
  set: ({ set }, newValue: boolean) =>
    set(simulationAtom, (state) => ({
      ...state,
      failOnNoRouteFound: newValue,
    })),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
