import { Divider, Stack } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { areaVehicleCount } from '../../store/area';
import { ContentRow } from './ContentRow';

function ChargingContent() {
  const vehicleCount = useRecoilValue(areaVehicleCount);

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='stretch'
      sx={{ mt: 2, height: '100%' }}
    >
      <Stack
        direction='column'
        alignItems='start'
        justifyContent='space-between'
        sx={{ width: '50%' }}
        spacing={1}
      >
        <ContentRow label='Total' value={vehicleCount} unit='' />
      </Stack>

      <Divider sx={{ ml: 2, mr: 2 }} orientation='vertical' flexItem />

      <Stack
        direction='column'
        alignItems='start'
        justifyContent='space-between'
        sx={{ width: '50%' }}
        spacing={1}
      >
        <ContentRow label='Charge time' value={10} unit='%' />
      </Stack>
    </Stack>
  );
}

export default ChargingContent;
