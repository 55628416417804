import { useCallback } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { areaOperationTime } from '@/components/PropertiesPanel/store/area';
import { Input } from '@/modules/common/components/Input';
import { MIN_OPERATION_TIME } from '@/modules/common/constants/process';
import { ShapeType } from '@/modules/common/types/shapes';
import { useAutoSave } from '@/modules/floorplan';
import { UserPreferenceName } from '@/modules/userPreferences';
import { useUpdateUserPreferences } from '@/modules/userPreferences/hooks';
import { selectedShapesState } from '@/store/recoil/shapes/selected';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { CONTEXT, contextState } from '@recoil/input';

function OperationTime() {
  const setContext = useSetRecoilState(contextState);
  const mode = useRecoilValue(modeSelector);
  const { save } = useAutoSave();
  const [operationTime, setOperationTime] = useRecoilState(areaOperationTime);
  const { updateUserPreference } = useUpdateUserPreferences();

  const onFocus = useCallback(() => setContext(CONTEXT.PROPERTYPANEL), [setContext]);
  const onBlur = useCallback(() => setContext(CONTEXT.WORKSPACE), [setContext]);

  const onChangeOperationTime = useRecoilCallback(
    ({ snapshot }) =>
      async (newVal: number) => {
        if (newVal < MIN_OPERATION_TIME) return;
        setOperationTime(newVal);
        save();

        const selectedShapes = await snapshot.getPromise(selectedShapesState);

        switch (selectedShapes[0].type) {
          case ShapeType.PROCESS_ONE_EP:
          case ShapeType.PROCESS_ONE_EP_POSITION:
            updateUserPreference(UserPreferenceName.PROCESS_OPERATION_TIME, newVal);
            break;
          case ShapeType.HANDOVER:
            updateUserPreference(UserPreferenceName.HANDOVER_OPERATION_TIME, newVal);
            break;
          default:
        }
      },
    [setOperationTime, save],
  );

  return (
    <Input
      value={operationTime}
      unit='sec'
      disabled={mode !== WorkspaceMode.EDITABLE}
      onChange={onChangeOperationTime}
      onFocus={onFocus}
      onBlur={onBlur}
      errorType='floating'
      mode='integer'
    />
  );
}

export default OperationTime;
