import { SvgIcon } from '@mui/material';

export default function AreaAlignBotIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d='M22 21H2M14 18V10C14 9.44771 14.4477 9 15 9H18C18.5523 9 19 9.44771 19 10V18C19 18.5523 18.5523 19 18 19H15C14.4477 19 14 18.5523 14 18ZM6 18V4C6 3.44771 6.44772 3 7 3H10C10.5523 3 11 3.44771 11 4V18C11 18.5523 10.5523 19 10 19H7C6.44772 19 6 18.5523 6 18Z'
        fill='transparent'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  );
}
