import { PropertiesLabel } from '@/modules/common/components/PropertiesLabel';
import { FileUploadButton } from '@common/components/FileUploadButton';
import { useTranslation } from 'react-i18next';
import { use3TE25LayoutStore } from '../../store';

export function LayoutPointUpload() {
  const { t } = useTranslation(['interface']);
  const { setLayoutPoint } = use3TE25LayoutStore();

  return (
    <PropertiesLabel i18nkey='interface:3te25.upload.layout_point'>
      <FileUploadButton
        onUploaded={setLayoutPoint}
        accept='.csv'
        label={t('interface:3te25.upload.upload')}
      />
    </PropertiesLabel>
  );
}
