import { useFloorPlanState } from '@modules/floorplan/hooks/useFloorPlanState';
import { stageSelector } from '@recoil/workspace';
import { useRef } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useZoomButton } from '../../../workspace/components/ZoomButton/useZoomButton';

import { ViewerLoadedEventArgs, ViewerRef } from '../../AutodeskViewer/types';
import { useWorkspaceResize } from '../../common/hooks';
import { VIEWER_DIMENSION_CONVERSION_RATIO } from '../../constants';
import {
  referenceApsUrn,
  referenceDimensions,
  referenceOpacity,
  referenceOriginalSize,
  referencePan,
} from '../../store';
import { useDwgJustGotUploaded } from '../../hooks';

export const useViewerWrapper = () => {
  const pan = useRecoilValue(referencePan);
  const stageProps = useRecoilValue(stageSelector);
  const urn = useRecoilValue(referenceApsUrn);
  const dimensions = useRecoilValue(referenceDimensions);
  const opacity = useRecoilValue(referenceOpacity);
  const viewerRef = useRef<ViewerRef>();

  const { scaleWorkspaceSize } = useWorkspaceResize();
  const { saveFloorPlan } = useFloorPlanState();
  const { dwgJustGotUploaded, resetNewDwgUploadIndicator } = useDwgJustGotUploaded();
  const { zoomFitReference } = useZoomButton();

  const onViewerLoaded = useRecoilCallback(
    ({ set, snapshot }) =>
      async (e: ViewerLoadedEventArgs) => {
        const { height, width } = e.viewportBoundingBox;
        const dimensions = await snapshot.getPromise(referenceDimensions);
        const newDimensions = { width, height };

        if (dimensions.width === 0 && dimensions.height === 0) {
          set(referenceDimensions, (state) => ({
            ...state,
            width: width * VIEWER_DIMENSION_CONVERSION_RATIO,
            height: height * VIEWER_DIMENSION_CONVERSION_RATIO,
          }));

          await scaleWorkspaceSize(newDimensions);
          await saveFloorPlan();
        }

        set(referenceOriginalSize, { ...newDimensions });

        if (dwgJustGotUploaded) {
          zoomFitReference();
          resetNewDwgUploadIndicator();
        }
      },
    [
      saveFloorPlan,
      scaleWorkspaceSize,
      zoomFitReference,
      dwgJustGotUploaded,
      resetNewDwgUploadIndicator,
    ],
  );

  return {
    urn,
    x: pan.x || stageProps.x,
    y: pan.y || stageProps.y,
    offsetX: dimensions.x / 10,
    offsetY: dimensions.y / 10,
    scale: stageProps.scale,
    width: dimensions.width / VIEWER_DIMENSION_CONVERSION_RATIO,
    height: dimensions.height / VIEWER_DIMENSION_CONVERSION_RATIO,
    viewerRef,
    opacity,
    onViewerLoaded,
  };
};
