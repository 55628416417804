import { useEffect, useRef, useState } from 'react'

type UseLoading = {
  isLoading: boolean

  delay?: number
  minDuration?: number
}

export const useLoading = ({ delay = 0, isLoading, minDuration = 200 }: UseLoading) => {
  const [isVisible, setIsVisible] = useState(delay ? false : isLoading)
  const startTime = useRef<Date | null>()

  useEffect(() => {
    let timeoutHandle: NodeJS.Timeout

    if (isLoading) {
      startTime.current = new Date()
      if (delay) {
        timeoutHandle = setTimeout(() => {
          setIsVisible(true)
        }, delay)
      }
      else {
        setIsVisible(true)
      }
    } else {
      if (!startTime.current) {
        return
      }

      const timeToWait =  Math.max(minDuration - (new Date().getTime() - startTime.current.getTime()), 0)
      startTime.current = null

      if (timeToWait) {
        timeoutHandle = setTimeout(() => {
          setIsVisible(false)
        }, timeToWait)
      } else {
        setIsVisible(false)
      }
    }

    return () => {
      clearTimeout(timeoutHandle)
    }

  }, [delay, isLoading, minDuration])

  return {
    isLoading: isVisible,
  }
}
