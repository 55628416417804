import { TextInput } from '@/modules/common/components/inputs';
import { PropertiesLabel } from '@modules/common/components/PropertiesLabel';
import { Stack, Typography } from '@mui/material';
import { ConnectionShape } from '../../helpers/types';

type Props = {
  connection: ConnectionShape;
};

export default function ConnectionDetail({ connection }: Props) {
  return (
    <Stack direction='column' spacing={1}>
      <Typography>{connection?.id ?? 'unknown'}</Typography>
      <PropertiesLabel i18nkey='interface:3te25.connection.from'>
        <TextInput value={connection?.from.toString() ?? 'unknown'} disabled />
      </PropertiesLabel>
      <PropertiesLabel i18nkey='interface:3te25.connection.to'>
        <TextInput value={connection?.to.toString() ?? 'unknown'} disabled />
      </PropertiesLabel>
    </Stack>
  );
}
