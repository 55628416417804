export enum Module {
  SETUP = 'setup',
  LAYOUT = 'layout',
  VISUALISATION = 'visualization',
  SIMULATION = 'simulation',
  VIEWER = 'viewer',
  VERSIONING = 'versioning',
  SALES_CANVAS = 'sales_canvas',
  COMMISSIONING_3TE25 = 'commissioning_3TE25',
  COMMISSIONING_SETUP = 'commissioning_setup',
  COMMISSIONING_GATE = 'commissioning_gate',
  COMMISSIONING_SPLINE = 'commissioning_spline',
}
