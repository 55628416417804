import { Accordion } from '@common/components/Accordion';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelectionStore } from '../../store/useSelectionStore';
import ConnectionDetail from './ConnectionDetail';

export default function ConnectionProperty() {
  const { t } = useTranslation(['interface']);
  const { connections } = useSelectionStore();

  return (
    <Accordion
      title={t('interface:3te25.connection.title')}
      titleSx={{ textTransform: 'capitalize' }}
      defaultExpanded
    >
      <Stack direction='column' spacing={1}>
        {connections &&
          connections.map((connection) => (
            <ConnectionDetail connection={connection} key={connection.id} />
          ))}
        {(!connections || connections.length === 0) && (
          <Typography>No connection is selected</Typography>
        )}
      </Stack>
    </Accordion>
  );
}
