import { Element, GroupElement } from '@thive/canvas';

export const createGroupElement = (shapes: Element[], groupId: string): GroupElement => ({
  id: groupId,
  name: 'Group',
  type: 'Group',
  childrenIds: shapes.map((item) => item.id),
  interactivity: {
    rotatable: false,
  },
});
