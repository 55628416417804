import { Box, SxProps, Theme } from '@mui/material';
import { ReactNode } from 'react';
import { LoadingPanel, useLoading, Props as PanelProps } from '../LoadingPanel';

type Props = {
  children?: ReactNode;
  delay?: number;
  isLoading?: boolean;
  keepChildren?: boolean;
  minDuration?: number;
  progress?: number;
  sx?: SxProps<Theme>;
  panelProps?: PanelProps;
  zIndex?: number;
};

export function LoadingContainer({
  children,
  delay,
  isLoading = false,
  keepChildren,
  minDuration,
  progress,
  sx,
  zIndex = 2,
  panelProps,
}: Props) {
  const loadingPanelSx: SxProps<Theme> = {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    position: 'absolute',
    zIndex,
    ...panelProps?.sx,
  };

  const { isLoading: isVisible } = useLoading({ delay, isLoading, minDuration });

  return (
    <Box position='relative' sx={{ height: '100%', ...sx }}>
      {keepChildren ? (
        <>
          {isVisible && <LoadingPanel progress={progress} sx={loadingPanelSx} />}
          {children}
        </>
      ) : isVisible ? (
        <LoadingPanel progress={progress} sx={loadingPanelSx} />
      ) : (
        children
      )}
    </Box>
  );
}
