import { IconButton } from '@mui/material';

import { StyledTooltip } from '@/helpers/styles';
import { Redo } from '../../assets/icons';

export const RedoButton = ({ onClick, disabled }) => {
  const btn = (
    <IconButton onClick={onClick} disabled={disabled}>
      <Redo />
    </IconButton>
  );

  if (disabled) return btn;

  return (
    <StyledTooltip title='Redo' placement='right'>
      {btn}
    </StyledTooltip>
  );
};
