import { useConfig } from '@modules/common/hooks';
import { useCallback } from 'react';

import { toSafeBase64 } from '../helpers/api';
import { useAuth } from './useAuth';

export const useApi = () => {
  const { aps } = useConfig();
  const { getToken } = useAuth();

  const fetchUploadUrl = async (
    api: string,
    authToken: string,
    objectKey: string,
    bucketKey: string,
    bucketLifespan: number,
  ) => {
    const url = new URL(`${api}/buckets/${bucketKey}/objects/${objectKey}/signeds3upload`);

    url.searchParams.append('minutesExpiration', bucketLifespan.toString());
    url.searchParams.append('ossbucketKey', bucketKey);
    url.searchParams.append('ossSourceFileObjectKey', objectKey);
    url.searchParams.append('access', 'full');
    url.searchParams.append('policyKey', 'transient');

    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });

    const { uploadKey, urls } = await response.json();

    return {
      uploadKey,
      url: urls[0],
    };
  };

  const finalizeUpload = useCallback(
    async (
      api: string,
      authToken: string,
      objectKey: string,
      bucketKey: string,
      uploadKey: number,
    ) => {
      const url = new URL(`${api}/buckets/${bucketKey}/objects/${objectKey}/signeds3upload`);

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          ossbucketKey: bucketKey,
          ossSourceFileObjectKey: objectKey,
          access: 'full',
          uploadKey,
        }),
      });

      const data = await response.json();

      return data.objectId;
    },
    [],
  );

  const startTranslationJob = useCallback(async (api: string, authToken: string, urn: string) => {
    const response = await fetch(`${api}/designdata/job`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
        'x-ads-force': 'true',
      },
      body: JSON.stringify({
        input: {
          urn,
        },
        output: {
          destination: {
            region: 'emea',
          },
          formats: [
            {
              type: 'svf2',
              views: ['2d'],
            },
          ],
        },
      }),
    });

    return (await response.json()).urn;
  }, []);

  const checkTranslationStatus = useCallback(
    async (api: string, authToken: string, urn: string) => {
      const response = await fetch(`${api}/designdata/${urn}/manifest`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      return (await response.json()).progress;
    },
    [],
  );

  const pollTranslationStatus = useCallback(
    async (
      api: string,
      authToken: string,
      urn: string,
      statusCallback?: (progress: string) => void,
    ) => {
      const progress = await checkTranslationStatus(api, authToken, urn);

      if (progress !== 'complete') {
        statusCallback?.(progress);

        return new Promise<void>((resolve) => {
          setTimeout(async () => {
            await pollTranslationStatus(api, authToken, urn, statusCallback);
            resolve();
          }, 10000);
        });
      }
    },
    [checkTranslationStatus],
  );

  const uploadFile = useCallback(
    async (file: File) => {
      if (!aps.enabled) {
        throw new Error('APS is disabled');
      }

      const { accessToken } = await getToken();

      try {
        const { url, uploadKey } = await fetchUploadUrl(
          aps.ossApi,
          accessToken,
          file.name,
          aps.bucketKey,
          10,
        );
        await fetch(url, {
          method: 'PUT',
          body: file,
        });
        const objectId = await finalizeUpload(
          aps.ossApi,
          accessToken,
          file.name,
          aps.bucketKey,
          uploadKey,
        );
        const safeUrn = toSafeBase64(objectId);
        await startTranslationJob(aps.modelDerivativeApi, accessToken, safeUrn);
        await pollTranslationStatus(aps.modelDerivativeApi, accessToken, safeUrn);
        return safeUrn;
      } catch (e) {
        throw new Error('Uploading file failed', { cause: e });
      }
    },
    [
      aps.bucketKey,
      aps.enabled,
      aps.modelDerivativeApi,
      aps.ossApi,
      finalizeUpload,
      getToken,
      pollTranslationStatus,
      startTranslationJob,
    ],
  );

  return {
    uploadFile,
  };
};
