import { theme } from '@/modules/common/components/theme';
import { useMemo } from 'react';
import { Line } from 'react-konva';
import { useRecoilValue } from 'recoil';

import { distcon } from '@modules/connections/distant/store';
import { DistantConnectionBubble } from './DistconBubble';

type Props = {
  id: string;
  saveFloorPlan: () => void;
};

export const DistantConnectionLine = ({ id, saveFloorPlan }: Props) => {
  const { rot, toPosition, fromPosition, bubblePosition, to } = useRecoilValue(distcon(id));
  const points = useMemo(
    () => [fromPosition.x, fromPosition.y, toPosition.x, toPosition.y],
    [toPosition, fromPosition],
  );

  const isComplete = !!to;

  if (!isComplete) {
    return null;
  }

  return (
    <>
      <Line
        points={points}
        listening
        strokeWidth={2}
        strokeScaleEnabled={false}
        stroke={theme.palette.primary.main}
      />
      <DistantConnectionBubble
        id={id}
        x={bubblePosition.x}
        y={bubblePosition.y}
        rot={rot}
        saveFloorPlan={saveFloorPlan}
      />
    </>
  );
};
