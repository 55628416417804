import { Vector2 } from 'three';
import { Vector2d } from 'konva/lib/types';

import { ProcessTwoEPShape } from '@/modules/processTwoEndPoint';
import { WideLineSegment } from '@/modules/common/types/general';
import { CANVAS_TO_SHAPE_SCALE, SHAPE_TO_CANVAS_SCALE } from '../workspace/helpers/konva';

export const getClosestEndSegment = (
  shape: ProcessTwoEPShape,
  point: Vector2
): WideLineSegment | null => {
  const segments = getSegments(shape) ?? null;

  if (!segments) return null;

  const startPosition = segments[0].points.start
  const endPosition = segments[segments.length - 1].points.end
  const distanceToStart = point.clone().distanceTo(startPosition)
  const distanceToEnd = point.clone().distanceTo(endPosition)
  return distanceToStart < distanceToEnd ? segments[0] : segments[segments.length - 1]
} 

export const getClosestEndSegmentByIndex = (
  shape: ProcessTwoEPShape,
  segmentId: number
): WideLineSegment | null => {
  const endSegments = getSegments(shape) ?? null;

  if (!endSegments) return null;

  if (endSegments.length === 1) return endSegments[0]

  return endSegments[segmentId]
} 

export const getClosestEndIndex = (
  shape: ProcessTwoEPShape,
  point: Vector2
): number => {
  const segments = getSegments(shape) ?? null;

  if (!segments) return null;

  const startPosition = segments[0].points.start
  const endPosition = segments[segments.length - 1].points.end
  const distanceToStart = point.clone().distanceTo(startPosition)
  const distanceToEnd = point.clone().distanceTo(endPosition)
  return distanceToStart < distanceToEnd ? 0 : 1
} 

export const getClosestEndPoint = (
  shape: ProcessTwoEPShape,
  point: Vector2
): Vector2 => {
  const segments = getSegments(shape) ?? null;

  if (!segments) return null;

  const startPosition = segments[0].points.start
  const endPosition = segments[segments.length - 1].points.end
  const distanceToStart = point.clone().distanceTo(startPosition)
  const distanceToEnd = point.clone().distanceTo(endPosition)
  return distanceToStart < distanceToEnd 
    ? startPosition.clone().multiplyScalar(SHAPE_TO_CANVAS_SCALE) 
    : endPosition.clone().multiplyScalar(SHAPE_TO_CANVAS_SCALE)
} 

export const getSegment = (
  shape: ProcessTwoEPShape,
  segmentId: number,
): WideLineSegment | null => {
  const segments = getSegments(shape)
  if (segments.length > 1) return segments[segmentId] ?? null;

  return segments[0] ?? null;
}

export const getSegments = (shape: ProcessTwoEPShape): WideLineSegment[] => {
  const lines: WideLineSegment[] = [];

  const{
    properties,
    parameters,
  } = shape

  lines.push({
    points: {
      start: properties.controlPoints[0].position,
      end: properties.controlPoints[1].position,
    },
    width: parameters.width,
  });
  
  if (properties.controlPoints.length > 2) {
    lines.push({
      points: {
        start: properties.controlPoints[properties.controlPoints.length - 2].position,
        end: properties.controlPoints[properties.controlPoints.length - 1].position,
      },
      width: parameters.width,
    });
  }

  return lines;
  
}

export const getProcessEPContainingPoint = (
  shape: ProcessTwoEPShape,
  point: Vector2d,
): WideLineSegment | null => 
   getClosestEndSegment(shape, new Vector2(point.x, point.y).multiplyScalar(CANVAS_TO_SHAPE_SCALE))

export const getProcessEPIndexContainingPoint = (
  shape: ProcessTwoEPShape, 
  point: Vector2
): number => 
  getClosestEndIndex(shape, point);
