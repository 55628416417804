import { InfoText } from '@common/components/InfoText';
import { theme } from '@common/components/theme';
import { useTranslation } from 'react-i18next';
import { Link, Stack } from '@mui/material';
import Warning from '@assets/icons/Warning';

type Props = {
  header: string;
  subHeader?: string;
  onRetry: () => void;
};

export const RetryInfoText = ({ header, subHeader, onRetry }: Props) => {
  const { t } = useTranslation('common');

  return (
    <Stack gap={1} sx={{ textAlign: 'center', alignItems: 'center' }}>
      <Warning
        style={{
          flex: '0 0 auto',
          fill: theme.palette.error.main,
          margin: '0 auto',
        }}
      />
      <InfoText header={header} subHeader={subHeader} />
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link component='button' onClick={onRetry}>
        {t('retry?')}
      </Link>
    </Stack>
  );
};
