import Label from '@common/components/Label';
import { Box, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  i18nkey: string;
  children: ReactNode;
  alignLabel?: 'top' | 'center';
  defaultLabel?: string;
  skipFocus?: boolean;
  labelRatio?: number;
};

export function PropertiesLabel({
  alignLabel = 'center',
  i18nkey,
  defaultLabel = '',
  children,
  skipFocus = false,
  labelRatio = 30,
}: Props) {
  const { t } = useTranslation();

  if (skipFocus) {
    return (
      <Stack
        sx={{
          flexDirection: 'row',
          alignItems: alignLabel === 'top' ? 'flex-start' : 'center',
        }}
      >
        {renderLabel(alignLabel, t(i18nkey, defaultLabel), labelRatio)}
        {renderContent(children, labelRatio)}
      </Stack>
    );
  }

  return (
    <Label
      sx={{
        display: 'flex',
        alignItems: alignLabel === 'top' ? 'flex-start' : 'center',
      }}
      label={renderLabel(alignLabel, t(i18nkey, defaultLabel), labelRatio)}
    >
      {renderContent(children, labelRatio)}
    </Label>
  );
}

const renderLabel = (alignLabel: 'top' | 'center', label: string, labelRatio: number) => (
  <Typography
    sx={{
      flex: `1 1 ${labelRatio}%`,
      mr: 1,
      fontSize: '10px',
      mt: alignLabel === 'top' ? '10px' : 0,
    }}
  >
    {label}
  </Typography>
);

const renderContent = (children: ReactNode, labelRatio: number) => (
  <Box sx={{ flex: `1 1 ${100 - labelRatio}%` }}>{children}</Box>
);
