import { useEffect, useState } from 'react';
import { EventNames, useEventBus } from './useEventBus';

export const useLoading = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { on, off } = useEventBus();

  useEffect(() => {
    const onEvent = () => setIsLoaded(true);

    on(EventNames.VisualizationLoadingFinished, onEvent);

    return () => {
      off(EventNames.VisualizationLoadingFinished, onEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isLoaded };
};
