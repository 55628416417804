import { t } from 'i18next';

import { useObjectValidation } from '@/modules/common/hooks/useObjectValidation';
import { useSnackbarStore } from '@/modules/snackbar/store/useSnackbarStore';

const requiredKeys = [
  'id',
  'hasUnsavedChanges',
  'project',
  'groupId',
  'created',
  'lastUpdated',
  'additionalData',
  'version',
  'lastUpdatedBy',
];

export const useFloorplanObjectValidation = () => {
  const { showSnackbar } = useSnackbarStore();
  const { validateKeys } = useObjectValidation();

  const isValidFloorplan = (floorplan: object) => {
    if (!floorplan) {
      return false;
    }

    if (!validateKeys(floorplan, requiredKeys)) {
      showSnackbar(t('errors:invalid_data_format'));

      return false;
    }

    return true;
  };

  return { isValidFloorplan };
};
