import { Vector2 } from "three";

export type LabelProperties = {
  position: Vector2;
  angle: number;
};

export enum LabelAlignment {
  TOP = 'top',
  BOTTOM = 'bottom',
}