import { Divider, Stack } from '@mui/material';

import { Dimensions } from './Dimensions';
import { Uploads } from './Uploads';

export const Layout = () => (
  <Stack divider={<Divider />}>
    <Uploads />
    <Dimensions />
  </Stack>
);
