import { MIN_ZOOM_SCALE, MAX_ZOOM_SCALE } from '../../../store/recoil/workspace';

type ClampZoomScaleArgs = {
  scale: number;
  minScale?: number;
  maxScale?: number;
  clampMinScale: boolean;
  clampMaxScale: boolean;
};

export const clampZoomScale = (args: ClampZoomScaleArgs) => {
  const {
    scale,
    minScale = MIN_ZOOM_SCALE,
    maxScale = MAX_ZOOM_SCALE,
    clampMinScale,
    clampMaxScale,
  } = args;
  let newScale = scale;

  if (clampMinScale && scale < minScale) newScale = minScale;
  if (clampMaxScale && newScale > maxScale) newScale = maxScale;

  return newScale;
};
