import { BoundingBox, Position } from '@helpers/types';
import { isPointsShape } from '@modules/common/types/guards';
import { DTShape, ShapeType } from '@modules/common/types/shapes';
import { Size } from '@modules/wifisimulation/types';
import { PropertyPath, PropertyTrackerMap } from '@modules/workspace/types/dragging';
import cloneDeep from 'clone-deep';
import { CANVAS_TO_SHAPE_SCALE, SHAPE_TO_CANVAS_SCALE } from './konva';

export const getDragVectorPoint = (
  type: ShapeType,
  shapeOrNode: any,
  context: 'store' | 'konva',
): Position => {
  if (context === 'konva') {
    switch (type) {
      case ShapeType.HIGHWAY_ANGLED: {
        const { x, y } = shapeOrNode.getPosition();
        return {
          x: x * CANVAS_TO_SHAPE_SCALE,
          y: y * CANVAS_TO_SHAPE_SCALE,
        };
      }
      default:
        return {
          x: shapeOrNode.x() * CANVAS_TO_SHAPE_SCALE,
          y: shapeOrNode.y() * CANVAS_TO_SHAPE_SCALE,
        };
    }
  }

  switch (type) {
    case ShapeType.HIGHWAY_ANGLED: {
      return { x: 0, y: 0 };
    }
    default:
      return {
        x: shapeOrNode.properties.x,
        y: shapeOrNode.properties.y,
      };
  }
};

export const makePropertyTrackerMap = (shapes: DTShape[]) => {
  const tempMap: PropertyTrackerMap = new Map();

  shapes.forEach((item) => {
    if (isPointsShape(item)) {
      tempMap.set(item.id, {
        path: PropertyPath.PROPERTIES_CONTROLPOINTS,
        value: item.properties.controlPoints.map((item) => cloneDeep(item)),
      });
    } else {
      let { x, y, width, height, r } = item.properties;

      // storing bb in CM. TODO => change to uniform unit (mm)
      tempMap.set(item.id, {
        path: PropertyPath.PROPERTIES,
        boundingBox: {
          x: x * SHAPE_TO_CANVAS_SCALE,
          y: y * SHAPE_TO_CANVAS_SCALE,
          width: width * SHAPE_TO_CANVAS_SCALE,
          height: height * SHAPE_TO_CANVAS_SCALE,
        },
        angle: r,
      });
    }
  });

  return tempMap;
};

export const clampBoundingBoxToOuterBox = (
  innerBoundingBox: BoundingBox,
  outerBoxSize: Size,
): BoundingBox => {
  const newBox = {
    ...innerBoundingBox,
  };

  const maxX = outerBoxSize.width - innerBoundingBox.width;
  const maxY = outerBoxSize.height - innerBoundingBox.height;

  if (innerBoundingBox.x < 0) newBox.x = 0;
  if (innerBoundingBox.y < 0) newBox.y = 0;

  if (innerBoundingBox.x > maxX) {
    newBox.x = maxX;
  }
  if (innerBoundingBox.y > maxY) {
    newBox.y = maxY;
  }

  return newBox;
};
