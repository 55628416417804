import { atom } from 'recoil';

export type HistoryAtomState = {
  hasUndo: boolean;
  hasRedo: boolean;
};

const historyAtom = atom<HistoryAtomState>({
  key: 'history',
  default: {
    hasUndo: false,
    hasRedo: false,
  },
});

export default historyAtom;
