import { useCallback, memo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Bubble } from '@common/components/Bubble';
import { connectionState } from '../store';
import { HistoryManager } from '@recoil/history';
import { WorkspaceMode } from '@modules/common/types/general';
import { modeSelector } from '@modules/common/store/workspace';

type Props = {
  id: string;
  saveFloorPlan: () => void;
};

const ConnectionBubbleComponent = ({ id, saveFloorPlan }: Props) => {
  const [connection, setConnection] = useRecoilState(connectionState(id));

  const onOutClicked = useCallback(() => {
    const oldState = connection;
    const newState = {
      ...connection,
      outChecked: !connection.outChecked,
    };

    HistoryManager.track(`Connection updated`, newState, oldState, (state) => setConnection(state));
    setConnection(newState);
    saveFloorPlan();
  }, [setConnection, saveFloorPlan, connection]);

  const onInClicked = useCallback(() => {
    const oldState = connection;
    const newState = {
      ...connection,
      inChecked: !connection.inChecked,
    };

    HistoryManager.track(`Connection updated`, newState, oldState, (state) => setConnection(state));
    setConnection(newState);
    saveFloorPlan();
  }, [setConnection, saveFloorPlan, connection]);

  const mode = useRecoilValue(modeSelector);
  const listening = mode === WorkspaceMode.EDITABLE;

  return (
    <Bubble
      x={connection.position.x / 10}
      y={connection.position.y / 10}
      rotation={connection.rot}
      inChecked={connection.inChecked}
      outChecked={connection.outChecked}
      onInClick={onInClicked}
      onOutClick={onOutClicked}
      listening={listening}
      id={id}
    />
  );
};

export const ConnectionBubble = memo(ConnectionBubbleComponent);
