import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import { projectState } from './projectState';

/**
 * Id of the project the currently loaded floor plan belongs to
 */
export const idSelector = selector({
  key: 'project/id',
  get: ({ get }) => get(projectState).id,
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
