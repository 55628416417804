// Check if reference image can be found in cache
import { selector } from 'recoil';
import dwgAtom from './atom';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../../store/recoil/common'

const dwgCache = selector({
  key: 'dwg/cache',
  get: ({ get }) => {
    const { cacheRef } = get(dwgAtom);
    if (cacheRef !== '') return localStorage.getItem(`ref-${localStorage.getItem(cacheRef)}`);
    return false;
  },
  set: ({ set }, cacheRef) => {
    const cachedDwg = localStorage.getItem(`ref-${cacheRef}`);
    if (cachedDwg) {
      const dwgObject = JSON.parse(cachedDwg);
      if (!dwgObject.hasOwnProperty('opacity')) {
        // NOTE: here for backwards compatibility. Remove on launch
        dwgObject.opacity = 100;
      }
      set(dwgAtom, dwgObject);
    }
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default dwgCache;
