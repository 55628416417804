import { selector } from 'recoil';

import { ROW_GAP } from '@/components/Workspace/Area/consts';
import { DEFAULT_RACK, DEFAULT_SIDE_LOADING } from '@/modules/common/constants/storage';
import { primitiveArraysHaveEqualContents } from '@/modules/common/helpers/array';
import {
  calcOrientedLoadCarriersBoundingBox,
  numberOfColumns,
  numberOfRows,
} from '@/modules/common/helpers/loadCarrier';
import {
  calcBayAmountCapacity,
  calcBeamLength,
  calcMaxRackRowLength,
} from '@/modules/common/helpers/rack';
import { isVertical } from '@/modules/workspace/helpers/shape';
import { AREA_OVERFLOW, unitConverterSelector } from '@/store/recoil/workspace';
import {
  findParameterValue,
  supportsLoadCarriers,
  supportsVehicleTypes,
  usesLoadPlacement,
  usesOperationTime,
} from '@modules/common/helpers/shapes';
import {
  AreaAlignment,
  AreaDirection,
  AreaDistribution,
  AreaEndpointDirection,
  AreaLoadCarrierOrientation,
  AreaLoadPlacement,
  AreaParkingDirection,
  LaneDirection,
  ShapeType,
} from '@modules/common/types/shapes';
import { StorageType } from '@modules/common/types/storage';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { selectedShapesState } from '@recoil/shapes/selected';
import {
  isAreaShape,
  isPositionShape,
  isProcessAreaTwoEp,
} from '../../../modules/common/types/guards';
import {
  enabledLoadCarrierTypesSelector,
  orientedLoadCarriersBoundingBox,
} from '../../../store/recoil/loadCarrierTypes';
import {
  AreaShapeParameters,
  PositionShapeParameters,
  shapeParameter,
} from '../../../store/recoil/shape';
import {
  RackProperty,
  SideLoadingProperty,
  StorageProperty,
} from '../../../store/recoil/shape/types/area';
import {
  supportedVehiclesLengthSelector,
  supportedVehiclesWidthSelector,
} from '@/modules/vehicles';
import { VehiclePosition } from '@/modules/floorplanService/helpers/mapping/types';

export const areaSectionTitle = selector<string>({
  key: 'propertiesPanelAreaSectionTitle',
  get: ({ get }) => {
    const shapes = get(selectedShapesState);

    return shapes.length === 1
      ? `properties.area.title.${shapes[0].type}`
      : `properties.area.title.generic`;
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const areaType = selector<ShapeType>({
  key: 'propertiesPanelAreaType',
  get: ({ get }) => {
    const shapes = get(selectedShapesState);

    return shapes.length === 1 ? shapes[0].type : null;
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const areasAlignment = selector<string>({
  key: 'propertiesPanelAreaAlignment',
  get: ({ get }) => findParameterValue(get(selectedShapesState), 'alignment'),
  set: ({ get, set }, value: AreaAlignment) => {
    get(selectedShapesState).forEach((shape) => {
      if (isAreaShape(shape) || isPositionShape(shape)) {
        set(shapeParameter(shape.id), {
          ...shape.parameters,
          alignment: value,
        });
      }
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const areasDistribution = selector<string>({
  key: 'propertiesPanelAreaDistribution',
  get: ({ get }) => findParameterValue(get(selectedShapesState), 'distribution'),
  set: ({ get, set }, value: AreaDistribution) => {
    get(selectedShapesState).forEach((shape) => {
      if (!isAreaShape(shape)) return;

      const { id, parameters } = shape;
      let { positionOverflow } = parameters;

      if (
        parameters.positionOverflow === AREA_OVERFLOW.ALLOW &&
        [AreaDistribution.SPACE_AROUND, AreaDistribution.SPACE_EVENLY].includes(value)
      ) {
        positionOverflow = AREA_OVERFLOW.CONTAIN;
      }

      const newParameters: AreaShapeParameters = {
        ...parameters,
        positionOverflow,
        distribution: value,
      };

      set(shapeParameter(id), newParameters);
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const areasGap = selector<number>({
  key: 'propertiesPanelAreaGap',
  get: ({ get }) => findParameterValue(get(selectedShapesState), 'gap'),
  set: ({ get, set }, value) => {
    get(selectedShapesState).forEach((shape) => {
      if (!isAreaShape(shape)) return;

      const { id, parameters } = shape;
      const valueInMillimeter = get(unitConverterSelector(value as number));
      if (!valueInMillimeter || Number.isNaN(valueInMillimeter) || valueInMillimeter < 0) {
        set(shapeParameter(id), {
          ...parameters,
          gap: 0,
        });
      } else {
        set(shapeParameter(id), {
          ...parameters,
          gap: valueInMillimeter,
        });
      }
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const areaMargin = selector({
  key: 'propertiesPanelAreasMargin',
  get: ({ get }) => findParameterValue(get(selectedShapesState), 'margin'),
  set: ({ get, set }, value) => {
    get(selectedShapesState).forEach((shape) => {
      if (!isAreaShape(shape)) return;

      const { id, parameters } = shape;
      const valueInMillimeter = get(unitConverterSelector(value));
      if (!valueInMillimeter || Number.isNaN(valueInMillimeter) || valueInMillimeter < 0) {
        set(shapeParameter(id), {
          ...parameters,
          margin: 0,
        });
      } else {
        set(shapeParameter(id), {
          ...parameters,
          margin: valueInMillimeter,
        });
      }
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const areaPriority = selector({
  key: 'propertiesPanelAreasPriority',
  get: ({ get }) => {
    const priority: number = findParameterValue(get(selectedShapesState), 'priority');
    return priority;
  },
  set: ({ get, set }, value: number) => {
    get(selectedShapesState).forEach((shape) => {
      if (!isAreaShape(shape)) return;
      if (Number.isNaN(value)) return;     
      const { id, parameters } = shape;

      const newParameters: AreaShapeParameters = {
        ...parameters,
        priority: value,
      };

      set(shapeParameter(id), {
        ...newParameters,
      });
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const areaStorageType = selector<StorageType>({
  key: 'propertiesPanelareaStorageType',
  get: ({ get }) => findParameterValue(get(selectedShapesState), 'storageType'),
  set: ({ get, set }, value: StorageType) => {
    get(selectedShapesState).forEach((shape) => {
      if (!isAreaShape(shape)) return;

      const { id, parameters } = shape;
      let newProperty: StorageProperty | null = null;
      if (value === StorageType.SIDELOADING) {
        newProperty = {
          endpointDirection: DEFAULT_SIDE_LOADING.END_POINT_DIRECTION,
          sideLoadingDeltaX: DEFAULT_SIDE_LOADING.DELTA_X,
          sideLoadingDeltaY: DEFAULT_SIDE_LOADING.DELTA_Y,
        };
      } else if (value === StorageType.RACK || value === StorageType.TWOSIDEDRACK) {
        newProperty = {
          framesDeep: DEFAULT_RACK.FRAMES_DEEP,
          rackLevels: DEFAULT_RACK.LEVELS,
          rackLoadsPerShelf: DEFAULT_RACK.LOADS_PER_SHELF,
          loadHeight: DEFAULT_RACK.LOAD_HEIGHT,
          clearanceSide: DEFAULT_RACK.CLEARANCE_SIDE,
          clearanceTop: DEFAULT_RACK.CLEARANCE_TOP,
          clearanceInBetween: DEFAULT_RACK.CLEARANCE_IN_BETWEEN,
          beamHeight: DEFAULT_RACK.BEAM_HEIGHT,
          postWidth: DEFAULT_RACK.POST_WIDTH,
          firstShelfHeight: DEFAULT_RACK.FIRST_SHELF_HEIGHT,
          aisleWidth: DEFAULT_RACK.AISLE_WIDTH,
          vehiclePositionInRack: VehiclePosition.PERPENDICULAR,
          offsetDrivingInRack: 0,
          laneDirectionInAisle: LaneDirection.LEFT_RIGHT,
        };
      }

      const newParameters: AreaShapeParameters = {
        ...parameters,
        storageProperty: newProperty,
        storageType: value,
      };

      set(shapeParameter(id), newParameters);
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const areaPalletCountInfo = selector<{ columns: number; rows: number; total: number }>({
  key: 'propertiesPanelAreaPalletCountInfo',
  get: ({ get }) => {
    const shape = get(selectedShapesState).at(0);

    if (!shape || !isAreaShape(shape)) return;

    if (shape.parameters.storageType === 'rack') {
      const enabledLoadCarriers = get(enabledLoadCarrierTypesSelector);
      const {
        supportedLoadCarriersIds,
        loadCarrierOrientation,
        storageProperty,
        direction,
        margin,
      } = shape.parameters;
      const { rackLoadsPerShelf, clearanceInBetween, clearanceSide, rackLevels, framesDeep, postWidth } =
        storageProperty as RackProperty;

      const supportedLoadCarrierTypes = enabledLoadCarriers.filter(({ id }) =>
        supportedLoadCarriersIds?.includes(id),
      );
      const loadCarriersBoundingBox = calcOrientedLoadCarriersBoundingBox(
        supportedLoadCarrierTypes,
        loadCarrierOrientation,
      );
      const beamLength = calcBeamLength(
        loadCarriersBoundingBox.length,
        rackLoadsPerShelf,
        clearanceInBetween,
        clearanceSide,
      );
      const availableRowLength = calcMaxRackRowLength(
        isVertical(direction),
        shape.properties.width,
        shape.properties.height,
        margin,
      );

      const baysAmount = calcBayAmountCapacity(
        beamLength,
        availableRowLength,
        postWidth,
      );

      return {
        columns: baysAmount * rackLoadsPerShelf,
        rows: framesDeep * rackLevels,
        total: baysAmount * rackLoadsPerShelf * framesDeep * rackLevels,
      };
    }

    const { gap, margin, storageType } = shape.parameters;
    const { height, width } = shape.properties;
    const direction = findParameterValue([shape], 'direction');
    const vehicleLength =
      get(supportedVehiclesLengthSelector(shape.parameters.supportedVehicleIds || [])) * 10;
    const loadCarriersBoundingBox = get(orientedLoadCarriersBoundingBox(shape.id));

    const vertical = isVertical(direction);
    const l = vertical ? width : height;
    const w = vertical ? height : width;
    const loadCarrierBoxLengthInDirection = loadCarriersBoundingBox.width * 10;
    const loadCarrierBoxWidthInDirection = loadCarriersBoundingBox.length * 10;

    const columns = Math.max(0, numberOfColumns(l, gap, margin, loadCarrierBoxWidthInDirection));
    const lengthForLoad = w - vehicleLength + loadCarrierBoxLengthInDirection;
    const rows =
      storageType === StorageType.BLOCKSTACKING
        ? Math.max(0, numberOfRows(lengthForLoad, loadCarrierBoxLengthInDirection, ROW_GAP))
        : 1;
    const total = columns * rows;

    return {
      columns,
      rows,
      total,
    };
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const areaVehicleCount = selector<number>({
  key: 'propertiesPanelAreaVehicleCount',
  get: ({ get }) => {
    const shape = get(selectedShapesState).at(0);

    if (!shape || !isAreaShape(shape)) return;

    const { gap, margin } = shape.parameters;
    const { height, width } = shape.properties;
    const direction = findParameterValue([shape], 'direction');
    const vehicleWidth = get(supportedVehiclesWidthSelector(shape.parameters.supportedVehicleIds));
    const length = isVertical(direction) ? width : height;
    return Math.max(0, numberOfColumns(length, gap, margin, vehicleWidth * 10));
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

// NOTE: this selector its setter supports multiple shapes, BUT its getter only supports the supportedLoadCarriersIds for ONE shape.
export const areaLoadCarriersIds = selector<string[]>({
  key: 'propertiesPanelLoadCarrierIdsSelect',
  get: ({ get }) => {
    // work with a safe fallback value for now
    // NOTE: since there is currently a "dead-zone" in time, when we start drawing while there is already a shape selected,
    // that shape will get deselected and no shapes will be "selected" until drawing is finished
    // this suspense between old selected shape - no selected shapes - new selected shape, gives issues with components not getting the props they expect.
    // TODO: check alternative propertyPanel/mouseEvents implementation
    const SAFE_FALLBACK_VALUE = [];

    const shape = get(selectedShapesState).at(0);
    if (!shape || !supportsLoadCarriers(shape.type)) {
      return SAFE_FALLBACK_VALUE;
    }

    const shapeParameters = get(shapeParameter(shape.id)) as
      | AreaShapeParameters
      | PositionShapeParameters;

    if (shapeParameters.supportedLoadCarriersIds === undefined) return SAFE_FALLBACK_VALUE;

    return shapeParameters.supportedLoadCarriersIds;
  },
  set: ({ get, set }, newValue: string[]) => {
    const selectedShapes = get(selectedShapesState);

    selectedShapes.forEach((shape) => {
      if (!isAreaShape(shape) && !isPositionShape(shape)) return;

      if (
        supportsLoadCarriers(shape.type) &&
        shape.parameters.supportedLoadCarriersIds !== undefined
      ) {
        set(shapeParameter(shape.id), {
          ...shape.parameters,
          supportedLoadCarriersIds: newValue,
        });
      }
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const areaVehicleIds = selector<string[]>({
  key: 'propertiesPanelVehicleIdsSelect',
  get: ({ get }) => {
    // work with a safe fallback value for now
    // NOTE: since there is currently a "dead-zone" in time, when we start drawing while there is already a shape selected,
    // that shape will get deselected and no shapes will be "selected" until drawing is finished
    // this suspense between old selected shape - no selected shapes - new selected shape, gives issues with components not getting the props they expect.
    // TODO: check alternative propertyPanel/mouseEvents implementation
    const SAFE_FALLBACK_VALUE = [];

    const shape = get(selectedShapesState).at(0);
    if (!shape) {
      return SAFE_FALLBACK_VALUE;
    }

    const shapeParameters = get(shapeParameter(shape.id)) as
      | AreaShapeParameters
      | PositionShapeParameters;

    if (shapeParameters.supportedVehicleIds === undefined) return SAFE_FALLBACK_VALUE;

    return shapeParameters.supportedVehicleIds;
  },
  set: ({ get, set }, newValue: string[]) => {
    const selectedShapes = get(selectedShapesState);

    selectedShapes.forEach((shape) => {
      if (!isAreaShape(shape) && !isPositionShape(shape)) return;

      const { id, type, parameters } = shape;

      if (supportsVehicleTypes(type) && parameters.supportedVehicleIds !== undefined) {
        set(shapeParameter(id), {
          ...parameters,
          supportedVehicleIds: newValue,
        });
      }
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

// NOTE: this selector its setter supports multiple shapes, BUT its getter only supports the supportedLoadCarriersIds for ONE shape.
export const areaLoadPlacement = selector<AreaLoadPlacement>({
  key: 'propertiesPanelAreaLoadPlacement',
  get: ({ get }) => {
    const shape = get(selectedShapesState).at(0);

    const shapeParameters = get(shapeParameter(shape.id)) as
      | AreaShapeParameters
      | PositionShapeParameters;

    return shapeParameters.loadPlacement;
  },
  set: ({ get, set }, newValue: AreaLoadPlacement) => {
    const selectedShapes = get(selectedShapesState);

    selectedShapes.forEach((shape) => {
      if (!isAreaShape(shape) && !isPositionShape(shape)) return;

      if (usesLoadPlacement(shape.type) && shape.parameters.loadPlacement !== undefined) {
        set(shapeParameter(shape.id), {
          ...shape.parameters,
          loadPlacement: newValue,
        });
      }
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const areaLoadCarrierOrientation = selector<AreaLoadCarrierOrientation>({
  key: 'propertiesPanelLoadCarrierOrientation',
  get: ({ get }) => {
    const loadCarrierSupportingShapes = get(selectedShapesState).filter((item) =>
      supportsLoadCarriers(item.type),
    );

    return findParameterValue(loadCarrierSupportingShapes, 'loadCarrierOrientation');
  },
  set: ({ get, set }, value: AreaLoadCarrierOrientation) => {
    get(selectedShapesState).forEach((shape) => {
      if (!isAreaShape(shape) && !isPositionShape(shape)) return;

      const { id, type, parameters } = shape;

      if (supportsLoadCarriers(type) && parameters.loadCarrierOrientation !== undefined) {
        set(shapeParameter(id), {
          ...parameters,
          loadCarrierOrientation: value,
        });
      }
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const areaStorageProperty = selector<StorageProperty>({
  key: 'propertiesPanelStorageProperty',
  get: ({ get }) => findParameterValue(get(selectedShapesState), 'storageProperty'),
  set: ({ get, set }, newProperty: StorageProperty) => {
    get(selectedShapesState).forEach((shape) => {
      if (!isAreaShape(shape)) return;

      if (shape.parameters.storageProperty !== undefined) {
        const newParameters = {
          ...shape.parameters,
          storageProperty: newProperty,
        };
        set(shapeParameter(shape.id), newParameters);
      }
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const areaDirection = selector<AreaDirection>({
  key: 'propertiesPanelAreaDirection',
  get: ({ get }) => findParameterValue(get(selectedShapesState), 'direction'),
  set: ({ get, set }, value: AreaDirection) => {
    get(selectedShapesState).forEach((shape) => {
      if (!isAreaShape(shape) && !isPositionShape(shape)) return;

      const newParameters = {
        ...shape.parameters,
        direction: value,
      };
      set(shapeParameter(shape.id), newParameters);
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const areaRackProperties = selector<Partial<RackProperty>>({
  key: 'propertiesPanelAreaRack',
  get: ({ get }) => get(areaStorageProperty) as RackProperty,
  set: ({ get, set }, updatedProperties: RackProperty) => {
    const property = get(areaStorageProperty) as RackProperty;
    set(areaStorageProperty, {
      ...property,
      ...updatedProperties,
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const areasRackShelfDepth = selector<number>({
  key: 'propertiesPanelAreaRack/depthPerLevel',
  get: ({ get }) => {
    const selectedShapes = get(selectedShapesState);
    const firstShape = selectedShapes[0];

    if (!isAreaShape(firstShape)) return 0;

    const loadCarriers = get(enabledLoadCarrierTypesSelector);
    const loadCarrierOrientation = findParameterValue(selectedShapes, 'loadCarrierOrientation');

    if (!loadCarrierOrientation) return 0;
    const firstShapeSupportedLoadCarrierIds = firstShape.parameters.supportedLoadCarriersIds;
    const identicalLoadCarrierSupport = selectedShapes.every(
      (item) =>
        isAreaShape(item) &&
        primitiveArraysHaveEqualContents(
          firstShapeSupportedLoadCarrierIds,
          item.parameters.supportedLoadCarriersIds,
        ),
    );

    if (!identicalLoadCarrierSupport) return 0;

    const supportedLoadCarriers = loadCarriers.filter(({ id }) =>
      firstShapeSupportedLoadCarrierIds?.includes(id),
    );
    const loadCarriersBoundingBox = calcOrientedLoadCarriersBoundingBox(
      supportedLoadCarriers,
      loadCarrierOrientation,
    );
    const depthPerLevel = loadCarriersBoundingBox.width;
    return depthPerLevel;
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const areaEndpointDirection = selector<AreaEndpointDirection>({
  key: 'propertiesPanelAreaEndpointDirection',
  get: ({ get }) => {
    const property = get(areaStorageProperty) as SideLoadingProperty;
    return property.endpointDirection;
  },
  set: ({ get, set }, value) => {
    const property = get(areaStorageProperty) as SideLoadingProperty;

    set(areaStorageProperty, {
      ...property,
      endpointDirection: value as AreaEndpointDirection,
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const areaSideLoadingDeltaX = selector<number>({
  key: 'propertiesPanelAreaSideLoadingDeltaX',
  get: ({ get }) => {
    const property = get(areaStorageProperty) as SideLoadingProperty;
    return property.sideLoadingDeltaX;
  },
  set: ({ get, set }, value) => {
    const valueInMillimeter = get(unitConverterSelector(value as number));
    const property = get(areaStorageProperty) as SideLoadingProperty;

    set(areaStorageProperty, {
      ...property,
      sideLoadingDeltaX: valueInMillimeter,
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const areaSideLoadingDeltaY = selector<number>({
  key: 'propertiesPanelAreaSideLoadingDeltaY',
  get: ({ get }) => {
    const property = get(areaStorageProperty) as SideLoadingProperty;
    return property.sideLoadingDeltaY;
  },
  set: ({ get, set }, value) => {
    const valueInMillimeter = get(unitConverterSelector(value as number));
    const property = get(areaStorageProperty) as SideLoadingProperty;

    set(areaStorageProperty, {
      ...property,
      sideLoadingDeltaY: valueInMillimeter,
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const areasParkingDirection = selector<AreaParkingDirection>({
  key: 'propertiesPanelAreaParkingDirection',
  get: ({ get }) => findParameterValue(get(selectedShapesState), 'parkingDirection'),
  set: ({ get, set }, value: AreaParkingDirection) => {
    get(selectedShapesState).forEach(({ id, parameters }) => {
      const newParameters = {
        ...parameters,
        parkingDirection: value,
      };
      set(shapeParameter(id), newParameters);
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const loadElevation = selector({
  key: 'propertiesPanelLoadElevation',
  get: ({ get }) => {
    const loadCarrierSupportingShapes = get(selectedShapesState).filter((item) =>
      supportsLoadCarriers(item.type),
    );

    return findParameterValue(loadCarrierSupportingShapes, 'loadElevation');
  },
  set: ({ get, set }, value) => {
    get(selectedShapesState).forEach((shape) => {
      if (!isAreaShape(shape) || !supportsLoadCarriers(shape.type)) return;

      const { id, parameters } = shape;
      const valueInMillimeter = get(unitConverterSelector(value));
      if (!valueInMillimeter || Number.isNaN(valueInMillimeter) || valueInMillimeter < 0) {
        set(shapeParameter(id), {
          ...parameters,
          loadElevation: 0,
        });
      } else {
        set(shapeParameter(id), {
          ...parameters,
          loadElevation: valueInMillimeter,
        });
      }
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const areaOperationTime = selector({
  key: 'propertiesPanelAreasOperationTime',
  get: ({ get }) => findParameterValue(get(selectedShapesState), 'operationTime'),
  set: ({ get, set }, value) => {
    get(selectedShapesState).forEach((shape) => {
      if ((!isAreaShape(shape) && !isProcessAreaTwoEp(shape)) || !usesOperationTime(shape.type))
        return;

      const { id, parameters } = shape;
      if (Number.isNaN(value) || value < 0) return;

      set(shapeParameter(id), {
        ...parameters,
        operationTime: value,
      });
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
