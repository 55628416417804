import { useRecoilCallback } from 'recoil';

import { shapeGroupState } from '../../shapeGroups';
import { FlowLink, FlowStopType } from '../types';
import { layoutFlowSelector } from '../store/layout';

export const useGetFlowLinkRelatedToFlowId = () => {
  const getFlowLinkRelatedToFlowId = useRecoilCallback(({ snapshot }) => async (flowId: string): Promise<FlowLink> => {
    const flow = await snapshot.getPromise(layoutFlowSelector(flowId));
    const from: string[] = [];
    const to: string[] = [];
    const fromType: FlowStopType = flow.intakeFlowStop.type
    const toType: FlowStopType = flow.deliveryFlowStop.type
    
    if (flow.intakeFlowStop) {
      if (flow.intakeFlowStop.type === FlowStopType.AREA) {
        from.push(flow.intakeFlowStop.id);
      } else if (flow.intakeFlowStop.type === FlowStopType.PROCESS) {
        from.push(flow.intakeFlowStop.id);
      } else {
        const shapeGroup = await snapshot.getPromise(shapeGroupState(flow.intakeFlowStop.id));
        from.push(...shapeGroup.children);
      }
    }
    if (flow.deliveryFlowStop) {
      if (flow.deliveryFlowStop.type === FlowStopType.AREA) {
        to.push(flow.deliveryFlowStop.id);
      } else if (flow.deliveryFlowStop.type === FlowStopType.PROCESS) {
        to.push(flow.deliveryFlowStop.id);
      } else {
        const shapeGroup = await snapshot.getPromise(shapeGroupState(flow.deliveryFlowStop.id));
        to.push(...shapeGroup.children);
      }
    }

    return {
      from,
      to,
      fromType,
      toType
    }
  });

  return getFlowLinkRelatedToFlowId
    
};
