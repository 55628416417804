import { StyledTooltip } from '@helpers/styles';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

type Props = {
  onCloseClick: () => void;
};

export const CloseButton = ({ onCloseClick }: Props) => {
  const { t } = useTranslation('interface');

  return (
    <StyledTooltip title={t('simulation.simulation_edit.header.close_button.tooltip')}>
      <IconButton sx={{ mr: -1 }} onClick={onCloseClick}>
        <Close />
      </IconButton>
    </StyledTooltip>
  );
};
