import { memo, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { useCanvasCamera, useCanvasMousePosition } from '@/modules/canvas';
import { Module } from '@/modules/common/types/navigation';
import { useZoomButton } from '@/modules/workspace/components/ZoomButton/useZoomButton';
import { navSelector } from '@/store/recoil/nav';
import { ZoomButton } from '../../modules/workspace/components';
import { VisualizeButton } from '../../modules/workspace/components/VisualizeButton';
import { isCommissioningSubModule } from '@/modules/common/helpers/navigation';
import { useCanvasZoom } from '@modules/canvas/hooks/useCanvasZoom';
import { MousePosition } from '@modules/workspace/components/MousePosition';

export const ToolsetViewport = memo(() => {
  const { zoomIn, zoomOut, zoomFit, zoomReset, zoom } = useZoomButton();
  const {
    zoomFit: newCanvasZoomFit,
    zoomOut: newCanvasZoomOut,
    zoomIn: newCanvasZoomIn,
    zoomReset: newCanvasZoomReset,
  } = useCanvasCamera();
  const newCanvasZoom = useCanvasZoom();
  const { x, y } = useCanvasMousePosition();
  const nav = useRecoilValue(navSelector);

  const isCommissioning = useMemo(
    () => isCommissioningSubModule(nav) || nav === Module.COMMISSIONING_3TE25,
    [nav],
  );

  return (
    <>
      {!isCommissioning && (
        <>
          <VisualizeButton />
          <ZoomButton
            zoomIn={zoomIn}
            zoomOut={zoomOut}
            zoomFit={zoomFit}
            zoomReset={zoomReset}
            zoom={zoom}
          />
        </>
      )}
      {isCommissioning && (
        <>
          <MousePosition x={x} y={y} />
          <ZoomButton
            zoom={newCanvasZoom / 100}
            zoomFit={newCanvasZoomFit}
            zoomOut={newCanvasZoomOut}
            zoomIn={newCanvasZoomIn}
            zoomReset={newCanvasZoomReset}
          />
        </>
      )}
    </>
  );
});
