import { ArrowElement, Color } from '@thive/canvas';
import { Vector3 } from 'three';

import { FlowAttachPoints, LayoutFlow } from '@/modules/flows/types';
import { FLOW_LINE_COLOR } from '@modules/flows/helpers';

export const createFlowElement = (
  flow: LayoutFlow,
  points: FlowAttachPoints,
  disabled = false,
): ArrowElement => ({
  type: 'Arrow',
  name: flow.name,
  id: flow.id,
  pointA: new Vector3(points.from.x, points.from.y, 0),
  pointB: new Vector3(points.to.x, points.to.y, 0),
  thickness: 100,
  stroke: Color.fromHex(FLOW_LINE_COLOR),
  interactivity: {
    selectable: !disabled,
  },
});
