import { addVehicleArea } from '@/modules/floorplanService/helpers/mapping/addVehicleArea';
import { boundingBoxToFpsRectangle } from '@/modules/floorplanService/helpers/mapping/utils';
import { addCheckPointGenerationSettings } from './addCheckPointGenerationSettings';
import { addDefaultCheckPointIdGenerator } from './addIdGenerators';
import { encodeIdWithVehicleId } from './idEncoder';
import { IdGenerator } from '@/modules/floorplanService';

export const createHighway = (
  vehicleSpec,
  checkPointIdGenerators,
  shapeName,
  id,
  type,
  laneDirection,
  margin,
  gap,
  routingPointGroupMinGap,
  routingPointMarginToCrossing,
  customCheckPointIdGeneratorName,
  properties,
  workspaceBoundingBox,
  customIdGeneratorMap: Map<string, IdGenerator>,
) => {
  const shapeId = encodeIdWithVehicleId(id, vehicleSpec.databaseId);
  const name = encodeIdWithVehicleId(shapeName, vehicleSpec.databaseId);
  const checkPointIdGeneratorName = customCheckPointIdGeneratorName || `${name}-CpIdGen`;
  const customCheckPointIdGenerator = customIdGeneratorMap.get(checkPointIdGeneratorName);

  const rectangle = boundingBoxToFpsRectangle(properties, properties.r, workspaceBoundingBox);
  addVehicleArea(vehicleSpec, shapeId, type, rectangle);

  addCheckPointGenerationSettings(
    vehicleSpec,
    shapeId,
    checkPointIdGeneratorName,
    gap,
    margin,
    routingPointGroupMinGap,
    routingPointMarginToCrossing,
    laneDirection,
    rectangle.length,
    rectangle.width,
    rectangle.angleInDegrees,
  );

  if (customCheckPointIdGenerator) {
    checkPointIdGenerators.push(customCheckPointIdGenerator);
  } else {
    addDefaultCheckPointIdGenerator(checkPointIdGenerators, checkPointIdGeneratorName, name);
  }
};
