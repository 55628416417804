import { openFile } from '@/modules/common/helpers/browser';
import { MultiList, MultiListItem } from '@common/components/MultiList';
import { useCallback } from 'react';

type Props = {
  accept: string;
  fileName: string;
  titleKey: string;
  onFileSelected: (file: File) => void;
  onDeleteClick: () => void;
};

export const FileMultiList = ({
  accept,
  fileName,
  titleKey,
  onDeleteClick,
  onFileSelected,
}: Props) => {
  const onAddClick = useCallback(async () => {
    const files = await openFile(accept);
    if (files?.length > 0) {
      onFileSelected(files[0]);
    }
  }, [accept, onFileSelected]);

  return (
    <>
      <MultiList titleKey={titleKey} onAddClick={onAddClick}>
        {fileName && <MultiListItem title={fileName} onDeleteClick={onDeleteClick} />}
      </MultiList>
    </>
  );
};
