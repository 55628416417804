import { Box, SxProps } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  children?: ReactNode;
  sx?: SxProps;
};

const TOP_BAR_HEIGHT = 48;
const BORDER_SIZE = 1;

export const TOP_BAR_HEIGHT_PX = `${TOP_BAR_HEIGHT + BORDER_SIZE}px`;

export const TopBar = ({ children, sx }: Props) => (
  <Box
    sx={{
      backgroundColor: 'shades.light',
      borderBottom: `${BORDER_SIZE}px solid`,
      borderBottomColor: 'neutral.lighter',
      display: 'flex',
      height: `${TOP_BAR_HEIGHT}px`,
      left: 0,
      position: 'fixed',
      pr: 3,
      right: 0,
      top: 0,
      ...sx,
    }}
  >
    {children}
  </Box>
);
