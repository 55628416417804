import Konva from 'konva';
import { useEffect, useRef } from 'react';

export const LOADING_ANIMATION_MAX_OPACITY = 1;
export const LOADING_ANIMATION_MIN_OPACITY = 0.1;

export const useLoadingAnimation = (isLoading: boolean) => {
  const loadingRefs = useRef<Map<string, Konva.Rect>>(new Map());

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    let direction = 1;
    let value = LOADING_ANIMATION_MIN_OPACITY;

    const anim = new Konva.Animation((frame) => {
      const diff = frame.timeDiff / 1000;
      value += diff * direction;

      if (value >= LOADING_ANIMATION_MAX_OPACITY) {
        direction = -1;
      } else if (value <= LOADING_ANIMATION_MIN_OPACITY) {
        direction = 1;
      }

      Array.from(loadingRefs.current.values()).forEach((item) => item.opacity(value));
    });

    anim.start();

    return () => {
      anim.stop();
    };
  }, [isLoading]);

  return { loadingRefs };
};
