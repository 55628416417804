import { ObjectValues, Position } from '@helpers/types';
import { DEFAULT_REFERENCE_IMAGE_SETTINGS } from './constants';

export const REFERENCE_TYPE = {
  NONE: 'none',
  APS: 'aps',
  IMAGE: 'image',
} as const;
export type ReferenceType = ObjectValues<typeof REFERENCE_TYPE>;

export enum Mode {
  SCALE = 'scale',
  CROP = 'crop',
  MOVE = 'move',
  UPLOAD = 'upload',
}

export enum Status {
  LOADING = 'Loading',
  DONE = 'Done',
  NONE = 'None',
}

export type ReferenceImageSettings = typeof DEFAULT_REFERENCE_IMAGE_SETTINGS;

export type ScaleLabelProps = {
  pointA: Position;
  pointB: Position;
  value: number;
};
