import {
  InteractionRequiredAuthError,
  IPublicClientApplication,
  SilentRequest,
} from '@azure/msal-browser';

export const acquireToken = async (instance: IPublicClientApplication, request: SilentRequest) => {
  try {
    const response = await instance.acquireTokenSilent(request);

    return response.accessToken;
  } catch (e) {
    if (e instanceof InteractionRequiredAuthError) {
      return (await instance.acquireTokenPopup(request)).accessToken;
    }
  }
};
