import { LoadingContainer } from '@common/components/LoadingContainer';
import React from 'react';

export function Lazy({ children }) {
  return (
    <React.Suspense
      fallback={
        <LoadingContainer
          isLoading
          minDuration={1000}
          delay={0}
          sx={{ width: '100vw', height: '100vh' }}
        />
      }
    >
      {children}
    </React.Suspense>
  );
}
