import { existingFlowsIdsSelector } from '@modules/flows/store/layout';
import { invisibleElementsSelector } from '@modules/layers';
import { memo } from 'react';
import { useRecoilValue } from 'recoil';
import { FlowLine } from '../../components';
import { FlowScope } from '../../types';

const ExistingFlowsRendererComponent = () => {
  const existingFlows = useRecoilValue(existingFlowsIdsSelector);
  const invisibleObjects = useRecoilValue(invisibleElementsSelector);

  if (!existingFlows?.length) return null;

  return (
    <>
      {existingFlows
        .filter((item) => !invisibleObjects.has(item))
        .map((id: string) => (
          <FlowLine flowId={id} flowScope={FlowScope.LAYOUT} key={id} />
        ))}
    </>
  );
};

export const ExistingFlowsRenderer = memo(ExistingFlowsRendererComponent);
