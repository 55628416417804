import { PropertiesLabel } from '@common/components/PropertiesLabel';
import { NumberInput } from '@common/components/inputs';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { useSimulationDraftCallbacks } from '@modules/simulation';
import { Stack } from '@mui/material';
import { CONTEXT, contextState } from '@recoil/input';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { vehicleTypesSelector } from '../../../store/draft';

type Props = {
  vehicleName: string;
};

export const VehicleTypeLoadTimes = ({ vehicleName }: Props) => {
  const { t } = useTranslation();
  const setContext = useSetRecoilState(contextState);
  const disabled = useRecoilValue(modeSelector) !== WorkspaceMode.EDITABLE;
  const vehicleTypes = useRecoilValue(vehicleTypesSelector).find(
    (item) => item.name === vehicleName,
  );
  const { updateVehicleLoadTime, updateVehicleUnloadTime } = useSimulationDraftCallbacks();

  const onLoadValueChange = useCallback(
    (value: number) => {
      if (value >= 0) {
        updateVehicleLoadTime(vehicleName, value);
      } else {
        return { error: t('common:minimum_value', { minValue: 0 }) };
      }
    },
    [vehicleName, updateVehicleLoadTime, t],
  );

  const onUnloadValueChange = useCallback(
    (value: number) => {
      if (value >= 0) {
        updateVehicleUnloadTime(vehicleName, value);
      } else {
        return { error: t('common:minimum_value', { minValue: 0 }) };
      }
    },
    [vehicleName, updateVehicleUnloadTime, t],
  );

  return (
    <Stack gap={1}>
      <PropertiesLabel
        i18nkey='interface:simulation.simulation_edit.vehicle_types.load_time'
        labelRatio={50}
      >
        <NumberInput
          value={vehicleTypes.loadTime}
          disabled={disabled}
          onChange={onLoadValueChange}
          onFocus={() => setContext(CONTEXT.PROPERTYPANEL)}
          onBlur={() => setContext(CONTEXT.WORKSPACE)}
          ariaLabel={t('interface:simulation.simulation_edit.vehicle_types.load_time')}
          errorType='floating'
          dataType='integer'
          unit='sec'
        />
      </PropertiesLabel>
      <PropertiesLabel
        i18nkey='interface:simulation.simulation_edit.vehicle_types.unload_time'
        labelRatio={50}
      >
        <NumberInput
          value={vehicleTypes.unloadTime}
          disabled={disabled}
          onChange={onUnloadValueChange}
          onFocus={() => setContext(CONTEXT.PROPERTYPANEL)}
          onBlur={() => setContext(CONTEXT.WORKSPACE)}
          ariaLabel={t('interface:simulation.simulation_edit.vehicle_types.unload_time')}
          errorType='floating'
          dataType='integer'
          unit='sec'
        />
      </PropertiesLabel>
    </Stack>
  );
};
