import { DownloadIcon } from '@/assets/icons';
import { Module } from '@/modules/common/types/navigation';
import { FlowScope } from '@/modules/flows';
import navAtom from '@/store/recoil/nav/atom';
import { Button, Stack, Typography } from '@mui/material';
import { ChangeEvent, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useOrderProfileExcel } from '../hooks/useOrderProfileExcel';
import { OrderProfileFile } from './OrderProfileFile';
import { SUPPORTED_FILE_TYPES } from '@/modules/common/helpers/excel';
import { useSnackbarStore } from '@/modules/snackbar/store/useSnackbarStore';

export function OrderProfileProperties() {
  const { t } = useTranslation('interface');
  const fileInput = useRef(null);
  const { createExcel, downloadExcel, uploadExcel } = useOrderProfileExcel();
  const nav = useRecoilValue(navAtom);
  const flowScope = nav === Module.LAYOUT ? FlowScope.LAYOUT : FlowScope.SIMULATION;
  const { showSnackbar } = useSnackbarStore();

  const onFileSelect = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files[0];
      const buffer = await file.arrayBuffer();
      const result = await uploadExcel(buffer, file.name, flowScope);
      showSnackbar(t(result.message));
    },
    [flowScope, showSnackbar, t, uploadExcel],
  );

  const downloadOrderProfile = useCallback(async () => {
    const blob = await createExcel(flowScope);
    downloadExcel(blob);
  }, [createExcel, downloadExcel, flowScope]);

  return (
    <Stack spacing='10px'>
      <Typography variant='body2' sx={{ fontSize: '10px' }}>
        {t('interface:order_profile.add_description')}
      </Typography>
      <OrderProfileFile flowScope={flowScope} />
      <Button
        sx={{
          height: 40,
          textTransform: 'none',
          border: 0,
          '&:hover': { color: 'primary.main', backgroundColor: 'transparent' },
        }}
        onClick={downloadOrderProfile}
        endIcon={<DownloadIcon />}
      >
        {t('interface:order_profile.template')}
      </Button>
      <Button sx={{ height: 40 }} onClick={() => fileInput.current.click()}>
        {t('interface:order_profile.upload')}
      </Button>
      <input
        type='file'
        accept={SUPPORTED_FILE_TYPES}
        ref={fileInput}
        onChange={onFileSelect}
        hidden
      />
    </Stack>
  );
}
