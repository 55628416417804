import { selector } from 'recoil';

import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { UnifiedVehicle } from '../../types';
import { enabledVehicleState, enabledVehicleIdsState } from './atom';

export const enabledVehiclesSelector = selector<UnifiedVehicle[]>({
  key: 'vehicles/enabled',
  get: ({ get }) => get(enabledVehicleIdsState).map((item) => get(enabledVehicleState(item))),
  set: ({ set }, newVehicles: UnifiedVehicle[]) => {
    const newVehicleIds = [];

    newVehicles.forEach((item) => {
      set(enabledVehicleState(item.id), item);
      newVehicleIds.push(item.id);
    });

    set(enabledVehicleIdsState, newVehicleIds);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
