import { selector } from 'recoil';
import { v4 as uuid } from 'uuid';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import modalAtom from './atom';

export const openModalSelector = selector({
  key: 'modal/open',
  get: ({ get }) => {
    const state = get(modalAtom);
    return state.queue;
  },
  set: ({ get, set }, newValue) => {
    const oldQueue = get(modalAtom).queue;
    // first element from array because... typescript
    const queue = [...oldQueue, { id: uuid(), type: newValue[0] }];

    set(modalAtom, {
      queue,
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
