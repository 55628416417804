import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { connectionLackingRoadIdsSelector, disconnectedAreaIdsSelector, flowlessAreasSelector } from '..';
import { shapesSelector } from '../../../../store/recoil/shapes';
import { Accordion } from '../../../common/components/Accordion';
import SummaryGroup from './SummaryGroup';

export const ValidationSummary = () => {
  const { t } = useTranslation('interface');
  const flowlessAreas = useRecoilValue(flowlessAreasSelector);
  const disconnectedAreaIds = useRecoilValue(disconnectedAreaIdsSelector);
  const disconnectedAreas = useRecoilValue(shapesSelector(disconnectedAreaIds));
  const connectionLackingRoadIds = useRecoilValue(connectionLackingRoadIdsSelector);
  const connectionLackingRoads = useRecoilValue(shapesSelector(connectionLackingRoadIds));

  return (
    <Stack sx={{ px: 2, pt: 1 }}>
      {(disconnectedAreaIds.length > 0 || flowlessAreas.length > 0) && (
        <Accordion
          title={t(`prevalidation.missing_connections_or_flows_title`, 'Information')}
          sx={{ backgroundColor: 'others.lighter', mb: 2 }}
          titleSx={{ ml: 2, fontWeight: 500, lineHeight: '18px' }}
          fontSize='12px'
        >
          <Stack
            direction='column'
            justifyContent='space-between'
            alignItems='stretch'
            spacing={2}
            sx={{
              backgroundColor: 'others.lighter',
              p: 2,
              paddingTop: '7px',
            }}
            className='infoCard'
          >
            {disconnectedAreas.length > 0 && (
              <SummaryGroup
                label={t(`prevalidation.warning_labels.disconnected_areas`, 'Disconnected areas')}
                objects={disconnectedAreas.map((area) => ({
                  name: area.name,
                  id: area.id,
                }))}
              />
            )}
            {flowlessAreas.length > 0 && (
              <SummaryGroup
                label={t(`prevalidation.warning_labels.areas_without_flows`, 'Areas without flows')}
                objects={flowlessAreas.map((area) => ({
                  name: area.name,
                  id: area.id,
                }))}
              />
            )}
            {connectionLackingRoads.length > 0 && (
              <SummaryGroup
                label={t(`prevalidation.warning_labels.disconnected_roads`, 'Disconnected roads')}
                objects={connectionLackingRoads.map((road) => ({
                  name: road.name,
                  id: road.id,
                }))}
              />
            )}
          </Stack>
        </Accordion>
      )}
    </Stack>
  );
};
