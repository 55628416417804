import { useRecoilCallback } from 'recoil';
import { orderProfileAtom } from '../store/orderProfileAtom';

export const useOrderProfile = () => {
  const prepareForSimulation = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const orderprofile = await snapshot.getPromise(orderProfileAtom);
        if (!orderprofile) return;

        set(orderProfileAtom, {
          uploadedFileName: orderprofile.uploadedFileName,
          overwriteFileName: orderprofile.uploadedFileName,
        });
      },
    [],
  );

  const resetOverwriteFilename = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const orderprofile = await snapshot.getPromise(orderProfileAtom);
        if (!orderprofile) return;

        set(orderProfileAtom, (val) => ({
          uploadedFileName: val.uploadedFileName,
        }));
      },
    [],
  );

  return { resetOverwriteFilename, prepareForSimulation };
};
