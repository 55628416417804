import { Divider, Stack } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { LayerProperties } from '@/modules/layers/components/LayerProperties';
import { useSimulationCallbacks, useSimulationsUpdater } from '../../hooks';
import { simulationIdsSelector } from '../../store/simulations';
import { SimulationList } from '../SimulationList';
import { Header } from './components/Header';
import { ListPanelCard } from './components/ListPanelCard';

export function ListPanel() {
  const { loadAll } = useSimulationCallbacks();
  const simulationIds = useRecoilValue(simulationIdsSelector);
  const { start, stop } = useSimulationsUpdater();

  const load = useCallback(async () => {
    await loadAll();
    return start();
  }, [loadAll, start]);

  useEffect(() => {
    load();

    return () => {
      stop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Stack height='100%' data-testid='ListPanel' divider={<Divider />} sx={{ pt: 1 }}>
        <Header />
        <SimulationList
          ids={simulationIds}
          hasRunningSimulations={false}
          renderCard={(id) => <ListPanelCard key={id} id={id} />}
        />
        <LayerProperties />
        <>{/* Intentionally Empty */}</>
      </Stack>
    </>
  );
}
