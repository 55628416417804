import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { selector } from 'recoil';

import { relatedComponentIdsState } from '../state';
import { connectionState } from '@/modules/connections/connections';
import { distcon } from '@/modules/connections/distant';

export const relatedComponentsSelector = selector({
  key: 'contextMenu/workspace/context/components', 
  get: ({ get }) => {
    const componentIds = get(relatedComponentIdsState);
    const components = [];
    componentIds.forEach((id) => {
      const connection = get(connectionState(id))
      const component = connection.id ? connection : get(distcon(id))
      components.push(component);
    });

    return components;
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
