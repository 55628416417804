import { selector } from 'recoil';
import { ShapeType } from '@modules/common/types/shapes';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import workspaceAtom, { WorkspaceAtom } from './atom';

const shapeTypeSelector = selector<ShapeType>({
  key: 'drawingType',
  get: ({ get }) => get<WorkspaceAtom>(workspaceAtom).shapeType,
  set: ({ get, set }, newValue) => {
    set(workspaceAtom, {
      ...get(workspaceAtom),
      previousShapeType: get<WorkspaceAtom>(workspaceAtom).shapeType,
    });
    set(workspaceAtom, { ...get(workspaceAtom), shapeType: newValue });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default shapeTypeSelector;
