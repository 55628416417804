import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilValue, useResetRecoilState } from 'recoil';

import { VisibilityOff } from '@/assets/icons';
import { StyledTooltip } from '@/helpers/styles';
import { modeSelector } from '@/modules/common/store/workspace';
import { WorkspaceMode } from '@/modules/common/types/general';
import { LayerNames } from '@/modules/common/types/layers';
import { useAutoSave } from '@/modules/floorplan';
import { useUpdateLayerUserPreference } from '@/modules/layers/hooks';
import {
  invisibleElementsAtom,
  invisibleNameLabelsAtom,
  layersAtom,
  layersShowSelector,
} from '@/modules/layers/store';

export const UnhideEverythingButton = () => {
  const resetInvisibleElementsAtom = useResetRecoilState(invisibleElementsAtom);
  const resetInvisibleNameLabelsAtom = useResetRecoilState(invisibleNameLabelsAtom);
  const resetLayersAtom = useResetRecoilState(layersAtom);
  const { updateVisibility } = useUpdateLayerUserPreference();
  const mode = useRecoilValue(modeSelector);
  const { save } = useAutoSave();

  const handleClick = useRecoilCallback(
    ({ set, snapshot }) =>
      (event: any) => {
        event.stopPropagation();
        resetInvisibleElementsAtom();
        resetInvisibleNameLabelsAtom();
        Object.values(LayerNames).forEach(async (value) => {
          if (!(await snapshot.getPromise(layersShowSelector(value)))) {
            set(layersShowSelector(value), true);
            updateVisibility(value, true);
          }
        });

        if (mode == WorkspaceMode.EDITABLE) {
          save();
        }
      },
    [
      resetInvisibleElementsAtom,
      resetInvisibleNameLabelsAtom,
      resetLayersAtom,
      mode,
      updateVisibility,
      save,
    ],
  );

  const { t } = useTranslation('interface');

  return (
    <StyledTooltip
      style={{ marginLeft: 'auto' }}
      title={t('interface:layers.show_everything', 'Show Everything')}
      placement='bottom'
    >
      <IconButton sx={{ padding: 0, marginRight: 1 }} onClick={handleClick}>
        <VisibilityOff />
      </IconButton>
    </StyledTooltip>
  );
};
