import { Divider, MenuItem } from '@mui/material';
import { Replay } from '@mui/icons-material';
import { EditPenIcon } from '@/assets/icons';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { Simulation, SimulationStatus } from '../../../helpers/types';
import { useSimulationCallbacks } from '../../../hooks';
import { DashboardMenuItem } from '../../DashboardMenuItem';
import { Menu } from '../../Menu';

type Props = {
  simulation: Simulation;
};

export const HeaderMenu = ({ simulation }: Props) => {
  const { rerun, closeSimulationGroupPanel, openSimulationEditPanel } = useSimulationCallbacks();
  const { t } = useTranslation('interface');

  const onEditClick = useCallback(async () => {
    await closeSimulationGroupPanel();
    await openSimulationEditPanel(simulation);
  }, [openSimulationEditPanel, simulation, closeSimulationGroupPanel]);

  const onRerunClick = useCallback(async () => {
    await rerun(simulation);
    await closeSimulationGroupPanel();
  }, [rerun, closeSimulationGroupPanel, simulation]);

  return (
    <Menu>
      <DashboardMenuItem simulation={simulation} />
      <Divider />
      <MenuItem
        onClick={onRerunClick}
        disabled={
          simulation.status !== SimulationStatus.COMPLETED &&
          simulation.status !== SimulationStatus.PARTIALLY_COMPLETED
        }
      >
        <Replay />
        {t('simulation.run_card.rerun_button.label')}
      </MenuItem>
      <MenuItem onClick={onEditClick} disabled={simulation.status === SimulationStatus.DELETING}>
        <EditPenIcon />
        {t('simulation.run_card.edit_button.label')}
      </MenuItem>
    </Menu>
  );
};
