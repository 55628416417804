import { selectorFamily } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../../../../store/recoil/common';
import { incorrectlyConnectedShapeToRoadIdsSelector } from './incorrectlyConnectedShapeToRoadIdsSelector';
import { disconnectedAreaIdsSelector } from './disconnectedAreaIdsSelector';

export const isNotOrIncorrectlyConnectedAreaSelector = selectorFamily({
  key: 'shape/byId/isNotOrIncorrectlyConnectedArea',
  get:
    (shapeId: string) =>
    ({ get }) =>
      get(disconnectedAreaIdsSelector).includes(shapeId) ||
      get(incorrectlyConnectedShapeToRoadIdsSelector).includes(shapeId),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
