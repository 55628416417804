import { useCallback, useRef } from 'react';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';

import { floorPlanIdSelector, projectIdSelector, useFloorPlanService } from '@/modules/floorplan';
import { useFloorPlanState } from '@modules/floorplan/hooks/useFloorPlanState';
import { DEFAULT_REFERENCE_IMAGE_SETTINGS } from '@modules/referenceImage/constants';
import dwgState from '@modules/referenceImage/store';
import { referenceImageNameAtom } from '@modules/referenceImage/store/nameAton';
import { referenceImageStatusAtom } from '@modules/referenceImage/store/statusAtom';
import { ReferenceImageSettings, Status } from '../types';
import { useImageSettings } from './useImageSettings';

export type UseReferenceImage = {
  setReferenceImage(
    fileName: string,
    base64string: string,
    image: HTMLImageElement,
    projectId: string,
    floorplanId: string,
    scaledDimensions?: { width: number; height: number },
  ): Promise<void>;
  setReferenceAPS(
    fileName: string,
    urn: string,
    projectId: string,
    floorplanId: string,
  ): Promise<void>;
  deleteReference(projectId: string, floorplanId: string): Promise<void>;
  saveReferenceSettings(): Promise<void>;
};

const DEBOUNCE_DELAY = 500;

export function useReferenceImage(): UseReferenceImage {
  const setReferenceImageSettings = useSetRecoilState(dwgState);
  const setReferenceImageName = useSetRecoilState(referenceImageNameAtom);
  const setStatus = useSetRecoilState(referenceImageStatusAtom);
  const {
    uploadReferenceImage,
    updateReferenceDetails,
    deleteReference: deleteReferenceAPI,
  } = useFloorPlanService();
  const debounceHandle = useRef<NodeJS.Timeout>();
  const { saveFloorPlan } = useFloorPlanState();
  const projectId = useRecoilValue(projectIdSelector);
  const floorPlanId = useRecoilValue(floorPlanIdSelector);
  const { createSettings, createAPSSetting } = useImageSettings();

  const saveImageOnServer = useCallback(
    async (
      fileName: string,
      base64string: string,
      projectId: string,
      floorplanId: string,
    ): Promise<void> => {
      const file = new File([new Blob([base64string], { type: 'image/png' })], fileName);
      await uploadReferenceImage(projectId, floorplanId, file);
    },
    [uploadReferenceImage],
  );

  const saveSettingsOnServer = useCallback(
    async (
      settings: ReferenceImageSettings,
      projectId: string,
      floorplanId: string,
    ): Promise<void> => {
      const { cacheRef, file, apsUrn, ...serverProps } = settings;
      await updateReferenceDetails(projectId, floorplanId, serverProps);
    },
    [updateReferenceDetails],
  );

  const getSettings = useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        await snapshot.getPromise(dwgState),
    [],
  );

  const setReferenceImage = useCallback(
    async (
      fileName: string,
      base64string: string,
      image: HTMLImageElement,
      projectId: string,
      floorplanId: string,
      scaledDimensions?: { width: number; height: number },
    ): Promise<void> => {
      try {
        const newReferenceImageSettings = createSettings(
          floorplanId,
          base64string,
          image.width,
          image.height,
          scaledDimensions?.width,
          scaledDimensions?.height,
        );
        await saveImageOnServer(fileName, base64string, projectId, floorplanId);
        await saveSettingsOnServer(newReferenceImageSettings, projectId, floorplanId);
        setReferenceImageSettings(newReferenceImageSettings);
        setReferenceImageName(fileName);
      } catch (e) {
        console.log('Could not save reference image.', e);
      }
    },
    [
      createSettings,
      saveImageOnServer,
      saveSettingsOnServer,
      setReferenceImageName,
      setReferenceImageSettings,
    ],
  );

  const setReferenceAPS = useCallback(
    async (
      fileName: string,
      urn: string,
      projectId: string,
      floorplanId: string,
    ): Promise<void> => {
      try {
        const newReferenceImageSettings = createAPSSetting(urn, floorplanId);
        await saveSettingsOnServer(newReferenceImageSettings, projectId, floorplanId);
        setReferenceImageSettings(newReferenceImageSettings);
        saveFloorPlan();
        setReferenceImageName(fileName);
      } catch (e) {
        console.log('Could not save reference image.', e);
      }
    },
    [
      createAPSSetting,
      saveFloorPlan,
      saveSettingsOnServer,
      setReferenceImageName,
      setReferenceImageSettings,
    ],
  );

  const deleteReference = useCallback(
    async (projectId: string, floorplanId: string): Promise<void> => {
      try {
        setReferenceImageSettings(DEFAULT_REFERENCE_IMAGE_SETTINGS);
        await deleteReferenceAPI(projectId, floorplanId);
        setStatus(Status.NONE);
      } catch (e) {
        console.log('Could not delete reference image.', e);
      }
    },
    [deleteReferenceAPI, setReferenceImageSettings, setStatus],
  );

  const saveReferenceSettings = useCallback(async () => {
    if (debounceHandle.current) clearTimeout(debounceHandle.current);

    debounceHandle.current = setTimeout(async () => {
      const settings = await getSettings();
      await saveSettingsOnServer(
        {
          ...settings,
          pointA: settings.setPointA,
          pointB: settings.setPointB,
          dist: settings.setDist,
        },
        projectId,
        floorPlanId,
      );
    }, DEBOUNCE_DELAY);
  }, [floorPlanId, getSettings, projectId, saveSettingsOnServer]);

  return {
    setReferenceImage,
    setReferenceAPS,
    deleteReference,
    saveReferenceSettings,
  };
}
