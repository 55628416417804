import {
  FLAT_REFLECTOR_SIZE,
  ROUND_REFLECTOR_SIZE,
} from '@modules/commissioning/helpers/constants';
import { CircleElement, Color, Element, LinePathElement } from '@thive/canvas';
import { Vector3 } from 'three';
import { Reflector, ReflectorType } from '../helpers/types';

const REFLECTOR_COLOR = '#5B9B4F';

export const map = (reflectors: readonly Reflector[]): Element[] =>
  reflectors.flatMap((reflector) =>
    reflector.type === ReflectorType.Round
      ? createRoundReflector(reflector)
      : createFlatReflector(reflector),
  );

const createFlatReflector = (reflector: Reflector): LinePathElement => {
  const size = FLAT_REFLECTOR_SIZE;

  return {
    type: 'LinePath',
    id: reflector.id,
    name: `Reflector ${reflector.id}`,
    position: reflector.position.clone(),
    rotation: new Vector3(0, 0, reflector.angle),
    size: new Vector3(size, size),
    points: [
      new Vector3(0, 0, 0),
      new Vector3(0, 0.5, 0),
      new Vector3(-0.5, 0.5, 0),
      new Vector3(-0.5, -0.5, 0),
      new Vector3(0, -0.5, 0),
      new Vector3(0, 0.15, 0),
      new Vector3(0.25, 0.15, 0),
      new Vector3(0.25, 0.3, 0),
      new Vector3(0.5, 0, 0),
      new Vector3(0.25, -0.3, 0),
      new Vector3(0.25, -0.15, 0),
      new Vector3(0, -0.15, 0),
    ],
    stroke: Color.fromHex(REFLECTOR_COLOR),
    interactivity: {
      selectable: false,
    },
  };
};

const createRoundReflector = (reflector: Reflector): Element[] => {
  const radius = ROUND_REFLECTOR_SIZE;
  const p = Math.cos(Math.PI / 4) * radius;
  const center = reflector.position.clone();

  return [
    {
      type: 'Circle',
      id: reflector.id,
      name: `Reflector ${reflector.id}`,
      center: reflector.position.clone(),
      radius,
      stroke: Color.fromHex(REFLECTOR_COLOR),
      strokeWidth: 2,
      interactivity: {
        selectable: false,
      },
    } as CircleElement,
    // {
    //   type: 'Line',
    //   id: `${reflector.id}_l1`, // TODO no id
    //   name: '', // TODO no name
    //   parentId: reflector.id,
    //   pointA: new Vector3(center.x - p, center.y - p, 0),
    //   pointB: new Vector3(center.x + p, center.y + p, 0),
    //   stroke: Color.fromHex(REFLECTOR_COLOR),
    //   thickness: 2,
    // } as LineElement,
    // {
    //   type: 'Line',
    //   id: `${reflector.id}_l2`, // TODO no id
    //   name: '', // TODO no name
    //   parentId: reflector.id,
    //   pointA: new Vector3(center.x - p, center.y + p, 0),
    //   pointB: new Vector3(center.x + p, center.y - p, 0),
    //   stroke: Color.fromHex(REFLECTOR_COLOR),
    //   thickness: 2,
    // } as LineElement,
  ];
};
