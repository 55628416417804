import { useCanvasStore } from '@/modules/canvas';
import { ArrowElement, Color } from '@thive/canvas';
import { useCallback } from 'react';
import { CONNECTION_COLOR, CONNECTION_DISABLE_ARROW_COLOR } from '../draw/drawConnections';
import { ShapeId } from '../helpers/types';
import { use3TE25LayoutStore } from '../store';

export const useConnectionUpdate = () => {
  const deleteConnections = useCallback((ids: ShapeId[]) => {
    // render
    const canvas = useCanvasStore.getState().instance;
    const elements = ids.map((id) => canvas.getElement(id) as ArrowElement);
    elements.forEach((element) => {
      element.stroke = Color.fromHex(CONNECTION_DISABLE_ARROW_COLOR);
    });
    canvas.updateElements(elements);

    // update state
    const layout = use3TE25LayoutStore.getState();
    layout.deleteConnections(ids);
  }, []);

  const deleteAllConnections = useCallback(() => {
    const layout = use3TE25LayoutStore.getState();
    const ids = [];
    layout.connectionShapes.forEach((shape, shapeIds) => ids.push(shapeIds));
    deleteConnections(ids);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const keepConnections = useCallback((ids: ShapeId[]) => {
    // render
    const canvas = useCanvasStore.getState().instance;
    const elements = ids.map((id) => canvas.getElement(id) as ArrowElement);
    elements.forEach((element) => {
      element.stroke = Color.fromHex(CONNECTION_COLOR);
    });
    canvas.updateElements(elements);

    // update state
    const layout = use3TE25LayoutStore.getState();
    layout.keepConnections(ids);
  }, []);

  const keepAllConnections = useCallback(() => {
    const layout = use3TE25LayoutStore.getState();
    const ids = [];
    layout.connectionShapes.forEach((shape, shapeIds) => ids.push(shapeIds));
    keepConnections(ids);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { deleteConnections, keepConnections, deleteAllConnections, keepAllConnections };
};
