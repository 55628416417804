import { SvgIcon } from '@mui/material';
import { memo } from 'react';

function SvgComponent() {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M5.3077 19H6.38078L16.275 9.10582L15.2019 8.03274L5.3077 17.927V19ZM18.4154 8.38659L15.9154 5.89812L17.1231 4.69042C17.3269 4.48657 17.5702 4.38464 17.8529 4.38464C18.1356 4.38464 18.3789 4.48657 18.5827 4.69042L19.6173 5.72504C19.8212 5.92889 19.925 6.17024 19.9289 6.44907C19.9327 6.72792 19.8327 6.96927 19.6289 7.17312L18.4154 8.38659ZM17.6962 9.11159L6.8077 20H4.30768V17.5L15.1962 6.61157L17.6962 9.11159ZM15.7289 8.55967L15.2019 8.03274L16.275 9.10582L15.7289 8.55967Z' />
    </SvgIcon>
  );
}

const EditPenIcon = memo(SvgComponent);
export default EditPenIcon;
