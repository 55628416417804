import { ToolState } from '@modules/common/types/tools';
import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../common';
import workspaceAtom, { WorkspaceAtom } from './atom';

const previousToolState = selector<ToolState>({
  key: 'previousToolState',
  get: ({ get }) => get<WorkspaceAtom>(workspaceAtom).previousToolState,
  set: ({ get, set }, newValue) => {
    set(workspaceAtom, { ...get(workspaceAtom), previousToolState: newValue });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default previousToolState;
