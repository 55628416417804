import { useCallback } from "react";
import { useFloorPlanState } from "./useFloorPlanState";

export const useAutoSave = () => {
    const { saveFloorPlan } = useFloorPlanState()
    
    /**
     * Saves current floor plan state in the current version
     */
    const save = useCallback(() => saveFloorPlan(), [saveFloorPlan]);
    
    return {
        save
    }
}