import type { Duration } from '@/modules/common/helpers/date';

export enum SimulationPanel {
  LIST = 0,
  EDIT = 1,
  GROUP = 2,
}

export enum SimulationStatus {
  DRAFT = 0,
  SCHEDULED = 2,
  RUNNING = 3,
  COMPLETED = 4,
  ABORTED = 5,
  FAILED = 6,
  ABORTING = 7,
  DELETING = 8,
  PREPARING = 9,
  PARTIALLY_COMPLETED = 10,
  SAVING = 11,
}

export enum SimulationType {
  SINGLE = 0,
  GROUP = 1,
}

export type SimulationError = {
  code: string;
  type: SimulationErrorType;
  args: string[];
};

export enum SimulationErrorType {
  GENERAL = 'GENERAL',
  RUNTIME = 'RUNTIME',
  CONFIGURATION = 'CONFIGURATION',
}

export type Simulation = {
  created: Date;
  details: SimulationDetails;
  error?: string;
  errors: SimulationError[];
  floorPlanId: string;
  generatedFloorPlanId?: string;
  id: string;
  isSelected: boolean;
  name: string;
  results?: SimulationResults;
  simulationRunId?: string;
  status: SimulationStatus;
  trafficManagementDisabled: boolean;
  failOnNoRouteFound: boolean;
  manualIntervention: boolean;
  obstructionTimeOutInSeconds?: number;
  orderDistributionStrategy?: OrderDistributionStrategy;
  type: SimulationType;
};

export type SimulationDetails = {
  vehicleTypes: SimulationDetailsVehicleType[];
  floorPlanVersion?: number;
};

export type SimulationDetailsVehicleType = {
  name: string;
  range: number[];
};

export type SimulationResults = {
  currentTime: Duration;
  endTime: Duration;
  dashboard?: {
    url: string;
    urlExpirationDate: Date;
  };
};

export type SimulationConfiguration = {
  duration: Duration;
  transportWindow: Duration;
  vehicleTypes: VehicleType[];
  flows: Flow[];
  dependentFlows: DependentFlow[];
  checkpointSets: CheckpointSet[];
  chargingParkingPriority: ParkingAndChargingLocation[];
  trafficManagementDisabled: boolean;
  failOnNoRouteFound: boolean;
  manualIntervention: boolean;
  obstructionTimeOutInSeconds?: number;
  orderDistributionStrategy?: OrderDistributionStrategy;
};

export type SimulationDraft = {
  name: string;
  duration: Duration;
  transportWindow: Duration; // should always be equal to duration
  vehicleTypes: VehicleType[];
  flows: Flow[];
  dependentFlows: DependentFlow[];
  floorPlanId: string;
  version: number;
  definition?: any;
  dynamicSplines?: boolean;
  generatedFloorPlanId?: string;
  trafficManagementDisabled: boolean;
  failOnNoRouteFound: boolean;
  manualIntervention: boolean;
  checkpointSets: CheckpointSet[];
  chargingParkingPriority: ParkingAndChargingLocation[];
  fmsConfig?: FmsConfig;
  obstructionTimeOutInSeconds?: number;
  orderDistributionStrategy?: OrderDistributionStrategy;
};

export type VehicleType = {
  name: string;
  range: number[];
  loadTime: number;
  unloadTime: number;
  hue: number;
};

export type OrdersPerHour = {
  hour: number; // starting from 1, 2, 3, ...
  loadsCount: number;
};

export type Flow = {
  id: string;
  name: string;
  intakeName: string;
  deliveryName: string;
  ordersPerHours: OrdersPerHour[];
  loadsCount?: number; // should be removed (obsolete) when new Simulation.Api support orderprofile
  vehicleLimit: number;
};

export type DependentFlow = {
  name: string;
  flowFromName: string;
  flowToName: string;
  processingTime: number;
};

export interface ParkingAndChargingLocation {
  name: string;
  priority: number;
}

export interface Match {
  priority: number;
  vehicleFlowName: string;
}

export interface VehicleFlowLookup {
  name: string;
  fromLoadPositionGroupId: string;
  toLoadPositionGroupId: string;
  loadTypeId: string;
  matches: Match[];
}

export interface LoadType {
  sequence: number;
  loadTypeId: string;
}

export interface VehicleFlow {
  name: string;
  capacity: number;
  subPoolName: string;
  fromLoadPositionGroupId: string;
  toLoadPositionGroupId: string;
  vehicleTypeId: string;
  loadTypes: LoadType[];
}

export interface SubPool {
  name: string;
  capacity: number;
  vehicleTypeId: string;
}

export interface VehiclePool {
  name: string;
  capacity: number;
  subPools: SubPool[];
}

export interface ForbiddenComboRoutePoint {
  sequence: number;
  routingPointId: string;
  routingPoints: ForbiddenComboRoutePoint[];
}

export interface ForbiddenCombo {
  sequence: number;
  routingPointId: string;
  routingPoints: ForbiddenComboRoutePoint[];
}

export interface DestinationTrafficOnlyCheckpointDestination {
  sequence: number;
  endPointId: string;
}

export interface DestinationTrafficOnlyCheckpoint {
  sequence: number;
  name: string;
  checkpointId: string;
  destinations: DestinationTrafficOnlyCheckpointDestination[];
}

export interface DecisionCheckPoint {
  sequence: number;
  name: string;
  checkpointId: string;
  loadPositionGroupId: string;
}

export interface OppositeDrivingDirectionPathCheckpoint {
  sequence: number;
  checkpointId: string;
}

export interface OppositeDrivingDirectionPath {
  sequence: number;
  name: string;
  checkpoints: OppositeDrivingDirectionPathCheckpoint[];
}

export type CheckpointSet = {
  maxVehiclesInSet: number;
  highways: string[];
  name?: string;
  checkpoints?: any[]; // pretty sure this is not used but it's in the floorplan...
};

export type OrderDistributionStrategy = 'TIME' | 'DISTANCE';

export interface FmsConfig {
  name: string;
  obstructionTimeOutInSeconds: number;
  orderDistributionStrategy: OrderDistributionStrategy;
  manualIntervention: boolean;
  trafficManagementDisabled: boolean;
  failOnNoRouteFound: boolean;
  checkpointSets: CheckpointSet[];
  parkingAndChargingLocations: ParkingAndChargingLocation[];
  decisionCheckPoints: DecisionCheckPoint[];
  destinationTrafficOnlyCheckpoints: DestinationTrafficOnlyCheckpoint[];
  forbiddenCombos: ForbiddenCombo[];
  oppositeDrivingDirectionPaths: OppositeDrivingDirectionPath[];
  vehicleFlowLookups: VehicleFlowLookup[];
  vehicleFlows: VehicleFlow[];
  vehiclePools: VehiclePool[];
}