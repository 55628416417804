import { useArtefacts } from '@/modules/artefacts';
import { enabledLoadCarrierTypesIdsState } from '@/store/recoil/loadCarrierTypes';
import { supportsLoadCarriers } from '@modules/common/helpers/shapes';
import { useAutoSave } from '@modules/floorplan';
import { useRecoilCallback } from 'recoil';
import { allShapesSelector } from '../../../store/recoil/shapes';
import { isAreaShape, isPositionShape, isProcessAreaTwoEp } from '../../common/types/guards';

export const useLoadCarrierTypes = () => {
  const { save } = useAutoSave();
  const { update: updateArtefacts } = useArtefacts();

  const deleteLoadCarrierTypesIds = useRecoilCallback(
    ({ set, snapshot }) =>
      async (deleteIds: string[]) => {
        const enabledLoadTypesIds: string[] = await snapshot.getPromise(
          enabledLoadCarrierTypesIdsState,
        );
        const newEnabledLoadTypesIds = enabledLoadTypesIds.filter((id) => !deleteIds.includes(id));
        set(enabledLoadCarrierTypesIdsState, newEnabledLoadTypesIds);

        const shapes = await snapshot.getPromise(allShapesSelector);
        const newShapes = shapes.map((shape) => {
          if (
            !(isAreaShape(shape) || isPositionShape(shape)) || isProcessAreaTwoEp(shape) || 
            !supportsLoadCarriers(shape.type)
          ) {
            return shape;
          }

          const currentLoadTypes: string[] = shape.parameters.supportedLoadCarriersIds;
          const supportedLoadTypes = currentLoadTypes.filter((item) =>
            newEnabledLoadTypesIds.includes(item),
          );

          return {
            ...shape,
            parameters: {
              ...shape.parameters,
              supportedLoadCarriersIds: supportedLoadTypes.length
                ? supportedLoadTypes
                : [newEnabledLoadTypesIds.at(0)],
            },
          };
        });

        set(allShapesSelector, newShapes);
        await save();
      },
    [save],
  );

  const setLoadCarrierIds = useRecoilCallback(
    ({ set, snapshot }) =>
      async (ids: string[]) => {
        const shapes = await snapshot.getPromise(allShapesSelector);
        set(enabledLoadCarrierTypesIdsState, ids);
        const shapesWithNewLoadCarrier = []

        const newShapes = shapes.map((shape) => {
          if (
            !supportsLoadCarriers(shape.type) ||
            !(isAreaShape(shape) || isPositionShape(shape) || isProcessAreaTwoEp(shape))
          ) {
            return shape;
          }

          // Single id for now
          const id = ids.at(0);
          const currentLoadTypes: string[] = shape.parameters.supportedLoadCarriersIds ?? [];
          const shapeAlreadyHasSupportedTypes = currentLoadTypes.some((item) => item === id);
          if (shapeAlreadyHasSupportedTypes) {
            return shape;
          }

          shapesWithNewLoadCarrier.push(shape.id)
          
          return {
            ...shape,
            parameters: {
              ...shape.parameters,
              supportedLoadCarriersIds: [id],
            },
          };
        });
        set(allShapesSelector, newShapes);
        updateArtefacts(shapesWithNewLoadCarrier)
        await save();
      },
    [save, updateArtefacts],
  );

  return {
    deleteLoadCarrierTypesIds,
    setLoadCarrierIds,
  };
};
