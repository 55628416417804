import { Vector2d } from 'konva/lib/types';
import { Vector2, Vector3 } from 'three';
import { DEG2RAD, degToRad } from 'three/src/math/MathUtils';

import { isPointsShape, isProcessAreaTwoEp } from '@/modules/common/types/guards';
import { LayerNames } from '@/modules/common/types/layers';
import { LabelAlignment, LabelProperties } from '@/modules/workspace/types/label';
import { DTShape } from '@/store/recoil/shape';
import { BoundingBox, Position } from '@helpers/types';
import { LineSegment, Size } from '@modules/common/types/general';
import { AreaDirection, HighwayDirection, ShapeType } from '@modules/common/types/shapes';
import { SHAPE_TO_CANVAS_SCALE } from './konva';

const DOT_PRODUCT_PARALLEL_THRESHOLD = 0.9999;

export const isVertical = (direction: string): boolean =>
  direction === AreaDirection.DOWN || direction === AreaDirection.UP;

export const getLayerOfShapeType = (type: ShapeType): LayerNames | undefined => {
  switch (type) {
    case ShapeType.HIGHWAY:
      return LayerNames.HIGHWAYS;
    case ShapeType.HIGHWAY_ANGLED:
      return LayerNames.ANGLED_HIGHWAYS;
    case ShapeType.OBSTACLE:
      return LayerNames.OBSTACLES;
    case ShapeType.WALL:
      return LayerNames.WALLS;
    case ShapeType.INTAKE:
    case ShapeType.INTAKE_POSITION:
      return LayerNames.INTAKE;
    case ShapeType.DELIVERY:
    case ShapeType.DELIVERY_POSITION:
      return LayerNames.DELIVERY;
    case ShapeType.STORAGE:
    case ShapeType.STORAGE_POSITION:
      return LayerNames.STORAGE;
    case ShapeType.PROCESS_ONE_EP:
    case ShapeType.PROCESS_ONE_EP_POSITION:
    case ShapeType.HANDOVER:
    case ShapeType.PROCESS_TWO_EP:
      return LayerNames.PROCESS;
    case ShapeType.CHARGING:
    case ShapeType.CHARGING_POSITION:
      return LayerNames.CHARGING;
    case ShapeType.PARKING:
    case ShapeType.PARKING_POSITION:
      return LayerNames.PARKING;
    case ShapeType.NONE:
      return;
    default: {
      // warn if not all options are covered
      return type;
    }
  }
};

export const getLayerGroupOfShapeType = (type: ShapeType): LayerNames | undefined => {
  switch (type) {
    case ShapeType.HIGHWAY:
    case ShapeType.HIGHWAY_ANGLED:
      return LayerNames.ROADS;
    case ShapeType.OBSTACLE:
    case ShapeType.WALL:
      return LayerNames.INTERIOR;
    case ShapeType.INTAKE:
    case ShapeType.INTAKE_POSITION:
    case ShapeType.DELIVERY:
    case ShapeType.DELIVERY_POSITION:
    case ShapeType.STORAGE:
    case ShapeType.STORAGE_POSITION:
    case ShapeType.PROCESS_ONE_EP:
    case ShapeType.PROCESS_ONE_EP_POSITION:
    case ShapeType.PROCESS_TWO_EP:
    case ShapeType.HANDOVER:
    case ShapeType.CHARGING:
    case ShapeType.CHARGING_POSITION:
    case ShapeType.PARKING:
    case ShapeType.PARKING_POSITION:
      return LayerNames.AREAS;
    case ShapeType.NONE:
      return;
    default: {
      // warn if not all options are covered
      return type;
    }
  }
};

export const isInsideWorkspace = (position: Position, workspaceSize: Size) =>
  !(
    position.x < 0 ||
    position.x > workspaceSize.width * SHAPE_TO_CANVAS_SCALE ||
    position.y < 0 ||
    position.y > workspaceSize.height * SHAPE_TO_CANVAS_SCALE
  );

export const clampInsideWorkspace = (p: Vector2, workspaceSize: Size, margin = 0) => {
  if (p.x - margin < 0) p.x = margin;
  if (p.x + margin > workspaceSize.width) p.x = workspaceSize.width - margin;
  if (p.y - margin < 0) p.y = margin;
  if (p.y + margin > workspaceSize.height) p.y = workspaceSize.height - margin;

  return p;
};

export const moveInsideWorkspace = (
  boundingBox: BoundingBox,
  workspaceSize: Size,
  minSize: number,
) => {
  let newBoundingBox = boundingBox;

  if (newBoundingBox.width < 0) {
    newBoundingBox.width = Math.abs(newBoundingBox.width);
    newBoundingBox.x -= newBoundingBox.width;
  }

  if (newBoundingBox.height < 0) {
    newBoundingBox.height = Math.abs(newBoundingBox.height);
    newBoundingBox.y -= newBoundingBox.height;
  }

  if (newBoundingBox.x < 0) {
    newBoundingBox.width = Math.max(newBoundingBox.width + newBoundingBox.x, minSize);
    newBoundingBox.x = 0;
  }
  if (newBoundingBox.y < 0) {
    newBoundingBox.height = Math.max(newBoundingBox.height + newBoundingBox.y, minSize);
    newBoundingBox.y = 0;
  }
  if (newBoundingBox.x + newBoundingBox.width > workspaceSize.width * SHAPE_TO_CANVAS_SCALE) {
    newBoundingBox.width -=
      newBoundingBox.x + newBoundingBox.width - workspaceSize.width * SHAPE_TO_CANVAS_SCALE;
  }
  if (newBoundingBox.y + newBoundingBox.height > workspaceSize.height * SHAPE_TO_CANVAS_SCALE) {
    newBoundingBox.height -=
      newBoundingBox.y + newBoundingBox.height - workspaceSize.height * SHAPE_TO_CANVAS_SCALE;
  }

  return newBoundingBox;
};

export const getHighwayDirection = (shapeWidth: number, shapeHeight: number): HighwayDirection =>
  shapeWidth < shapeHeight ? HighwayDirection.UP_DOWN : HighwayDirection.LEFT_RIGHT;

export const areLinesParallel = (lineA: LineSegment, lineB: LineSegment): boolean => {
  const vectorA = new Vector2().subVectors(lineA.end, lineA.start).normalize();
  const vectorB = new Vector2().subVectors(lineB.end, lineB.start).normalize();
  return Math.abs(vectorA.dot(vectorB)) > DOT_PRODUCT_PARALLEL_THRESHOLD;
};

export const getAreaAutoDirection = (
  initialMousePosition: Vector2d,
  currentMousePosition,
): AreaDirection => {
  const dx = currentMousePosition.x - initialMousePosition.x;
  const dy = currentMousePosition.y - initialMousePosition.y;
  const width = Math.abs(dx);
  const height = Math.abs(dy);

  if (width > height) {
    if (dy > 0) {
      return AreaDirection.DOWN;
    }
    return AreaDirection.UP;
  }
  if (dx > 0) {
    return AreaDirection.RIGHT;
  }
  return AreaDirection.LEFT;
};

export const angleBetweenVectors = (fromVector: Vector2, toVector: Vector2): number => {
  const vectorA = new Vector3(fromVector.x, fromVector.y, 0);
  const vectorB = new Vector3(toVector.x, toVector.y, 0);
  let angle = vectorA.angleTo(vectorB);
  const orientation = vectorA.x * vectorB.y - vectorA.y * vectorB.x;
  if (orientation > 0) angle = -angle;

  return angle;
};

export const pointAlongVector = (pointA: Vector2, pointB: Vector2, length: number): Vector2 => {
  const distance = pointA.distanceTo(pointB);
  const factor = length / distance;
  return new Vector2().lerpVectors(pointA, pointB, factor);
};

export const vectorsAlongHighway = (points: Vector2[]): Vector2[] => {
  const vectors: Vector2[] = [];

  for (let i = 0; i < points.length; i++) {
    let vectorBefore: Vector2;
    let vectorAfter: Vector2;

    if (i === 0) {
      vectorBefore = new Vector2().subVectors(points[i + 1], points[i]);
      vectorAfter = vectorBefore;
    } else if (i >= points.length - 1) {
      vectorBefore = new Vector2().subVectors(points[i], points[i - 1]);
      vectorAfter = vectorBefore;
    } else {
      vectorBefore = new Vector2().subVectors(points[i], points[i - 1]);
      vectorAfter = new Vector2().subVectors(points[i + 1], points[i]);
    }

    vectors.push(vectorBefore);
    vectors.push(vectorAfter);
  }

  return vectors;
};

export const pointOnShape = (
  shape: DTShape,
  boundingBox: BoundingBox,
  factorX: number,
  factorY: number,
  offsetX = 0,
  offsetY = 0,
): Vector2 => {
  const angle = isPointsShape(shape) || isProcessAreaTwoEp(shape) ? 0 : shape.properties.r;

  const center = centerPoint(boundingBox, angle);
  const offset = new Vector3(
    (factorX * boundingBox.width) / 2 + offsetX,
    (factorY * boundingBox.height) / 2 + offsetY,
    0,
  );
  offset.applyAxisAngle(new Vector3(0, 0, 1), angle * DEG2RAD * -1);

  return new Vector2(center.x + offset.x, center.y + offset.y);
};

export const calculateLabelProperties = (
  shape: DTShape,
  boundingBox: BoundingBox,
  offsetInput: Vector2,
  alignment: LabelAlignment,
): LabelProperties => {
  let angle: number;
  let factorX: number;
  let factorY: number;
  let offsetX: number;
  let offsetY: number;

  let r = isPointsShape(shape) || isProcessAreaTwoEp(shape) ? 0 : -shape.properties.r;
  if (r < 0) r = 360 + r;
  r = ((r % 360) + 360) % 360;

  if (r > 135 && r < 225) {
    angle = r - 180;
    factorX = 0;
    factorY = alignment === LabelAlignment.TOP ? 1 : -1;
    offsetX = -offsetInput.x;
    offsetY = -offsetInput.y;
  } else if (r > 45 && r < 315) {
    const sign = r > 180 ? -1 : 1;
    angle = r - 90 * sign;
    factorX = -sign * (alignment === LabelAlignment.TOP ? 1 : -1);
    factorY = 0;
    offsetX = offsetInput.y * sign;
    offsetY = offsetInput.x * -sign;
  } else {
    angle = r;
    factorX = 0;
    factorY = alignment === LabelAlignment.TOP ? -1 : 1;
    offsetX = offsetInput.x;
    offsetY = offsetInput.y;
  }

  return {
    position: pointOnShape(shape, boundingBox, factorX, factorY, offsetX, offsetY),
    angle,
  };
};

export const centerPoint = (boundingBox: BoundingBox, angle: number): Vector3 => {
  const radAngle = degToRad(-angle);
  const vectorToCenter = new Vector3(
    (boundingBox.width / 2) * Math.cos(radAngle) - (boundingBox.height / 2) * Math.sin(radAngle),
    (boundingBox.height / 2) * Math.cos(radAngle) + (boundingBox.width / 2) * Math.sin(radAngle),
    0,
  );

  return new Vector3(boundingBox.x + vectorToCenter.x, boundingBox.y + vectorToCenter.y, 0);
};

export const positionAfterRotation = (
  position: Vector3,
  width: number,
  height: number,
  angle: number,
  newAngle: number,
): Vector3 => {
  const center = centerPoint({ x: 0, y: 0, width, height }, angle).add(position);
  return new Vector3()
    .subVectors(new Vector3(position.x, position.y, 0), center)
    .applyAxisAngle(new Vector3(0, 0, 1), (newAngle - angle) * DEG2RAD * -1)
    .add(center);
};

export const latestShape = (shapes: DTShape[]) => {
    if (shapes.length === 0) return null;
    return shapes[shapes.length - 1].id;
  };
