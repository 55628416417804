import { ArrowDownIcon, ArrowLeftIcon, ArrowRightIcon, ArrowUpIcon } from '@/assets/icons';
import { useFloorPlanState } from '@/modules/floorplan/hooks/useFloorPlanState';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { HistoryManager } from '@recoil/history';
import { selectedShapesIdsState } from '@recoil/shapes/selected';
import { useRecoilValue } from 'recoil';

import { useArea } from '@components/PropertiesPanel/hooks';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { AreaDirection as AreaDirectionEnum } from '@modules/common/types/shapes';
import { useCallback } from 'react';
import {
  PreValidationAspect,
  useRunPreValidation,
} from '../../../modules/floorplanValidation/clientSide';
import { useArtefacts } from '@/modules/artefacts';

function AreaDirection() {
  const { saveFloorPlan } = useFloorPlanState();
  const { runPreValidation } = useRunPreValidation();
  const { direction, setDirection } = useArea();
  const ids = useRecoilValue(selectedShapesIdsState);
  const mode = useRecoilValue(modeSelector);
  const { updateDebounced: updateArtefacts } = useArtefacts();

  const onChange = useCallback(
    (_, value) => {
      if (value != null) {
        setDirection(value, ids);

        HistoryManager.track(
          `direction changed`,
          value,
          direction,
          (directionIn: AreaDirectionEnum): Promise<void> => setDirection(directionIn, ids),
        );

        saveFloorPlan();
        runPreValidation([
          PreValidationAspect.REQUIRED_ELEMENTS,
          PreValidationAspect.FLOWLESS_AREAS,
        ]);
        updateArtefacts(ids);
      }
    },
    [setDirection, ids, direction, saveFloorPlan, runPreValidation, updateArtefacts],
  );

  return (
    <ToggleButtonGroup
      value={direction}
      exclusive
      onChange={onChange}
      disabled={mode !== WorkspaceMode.EDITABLE}
    >
      <ToggleButton value={AreaDirectionEnum.DOWN} aria-label='Down'>
        <ArrowDownIcon />
      </ToggleButton>
      <ToggleButton value={AreaDirectionEnum.UP} aria-label='Up'>
        <ArrowUpIcon />
      </ToggleButton>
      <ToggleButton value={AreaDirectionEnum.LEFT} aria-label='Left'>
        <ArrowLeftIcon />
      </ToggleButton>
      <ToggleButton value={AreaDirectionEnum.RIGHT} aria-label='Right'>
        <ArrowRightIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export default AreaDirection;
