import AdbIcon from '@mui/icons-material/Adb';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';

import { useDebugStore } from '../store/useDebugStore';

export const DebugMenu = () => {
  const setOpen = useDebugStore((state) => state.setOpen);

  return (
    <MenuItem onClick={() => setOpen(true)}>
      <ListItemIcon>
        <AdbIcon fontSize='small' />
      </ListItemIcon>
      <ListItemText>Debug Menu</ListItemText>
    </MenuItem>
  );
};
