import { IconButton, Stack, ThemeProvider, Typography } from '@mui/material';
import {
  enabledLoadCarrierTypesIdsState,
  enabledLoadCarrierTypesSelector,
} from '@recoil/loadCarrierTypes';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { AddIcon } from '@/assets/icons';
import { setupPropertyPanelTheme } from '@/modules/common/components/theme';
import { StyledTooltip } from '@modules/common/components/StyledTooltip';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { FloorPlanPropertyItem } from '@modules/setup/components/common/FloorPlanPropertyItem';
import { useLoadCarrierTypes } from '@modules/setup/hooks';
import { LoadCarrierTypeSelectDialog } from './LoadCarrierTypeSelectDialog';

export const LoadCarrierTypes: React.FC = () => {
  const { t } = useTranslation(['interface']);
  const [open, setOpen] = useState(false);
  const mode = useRecoilValue(modeSelector);
  const enabledLoadCarrierTypesIds = useRecoilValue(enabledLoadCarrierTypesIdsState);
  const enabledLoadCarrierTypes = useRecoilValue(enabledLoadCarrierTypesSelector);
  const { deleteLoadCarrierTypesIds, setLoadCarrierIds } = useLoadCarrierTypes();

  const onSubmit = useCallback(
    async (ids: string[]) => {
      setOpen(false);
      await setLoadCarrierIds(ids);
    },
    [setLoadCarrierIds],
  );

  const onDeleteClick = useCallback(
    async (ids: string[]) => {
      await deleteLoadCarrierTypesIds(ids);
    },
    [deleteLoadCarrierTypesIds],
  );

  return (
    <>
      <ThemeProvider theme={setupPropertyPanelTheme}>
        <Stack spacing={1} sx={{ px: 2 }}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Typography
              align='center'
              sx={{
                fontSize: '14px',
                fontWeight: 400,
              }}
            >
              {t('setup.floorPlan.load_carrier_types.title', 'Load carrier types', {
                count: enabledLoadCarrierTypesIds.length,
              })}
            </Typography>
            <StyledTooltip title={t('setup.floorPlan.load_carrier_types.add_button.title')}>
              <IconButton
                disabled={mode !== WorkspaceMode.EDITABLE}
                onClick={() => setOpen(true)}
                sx={{ padding: 0, border: 'none' }}
              >
                <AddIcon data-testid='add-icon' />
              </IconButton>
            </StyledTooltip>
          </Stack>
          <Stack gap={1}>
            {enabledLoadCarrierTypes.map((item) => (
              <FloorPlanPropertyItem
                key={item.id}
                name={item.name}
                imageUrl={item.image.url}
                deleteBtnTooltipText={t('setup.floorPlan.load_carrier_types.delete_button.title')}
                deletable={enabledLoadCarrierTypes.length > 1}
                onDeleteClick={async () => onDeleteClick([item.id])}
              />
            ))}
          </Stack>
        </Stack>
      </ThemeProvider>
      <LoadCarrierTypeSelectDialog
        open={open}
        enabledLoadCarriersIds={enabledLoadCarrierTypesIds}
        onSubmit={onSubmit}
        onClose={() => setOpen(false)}
      />
    </>
  );
};
