import { GeneratedFloorPlanArtefacts } from '@modules/floorplanService';
import { getRecoil, setRecoil } from 'recoil-nexus';
import { create } from 'zustand';

import { convertToSalesCanvasCoordinateSystem } from '@/modules/artefacts/helpers/convert';
import { filterRackLoadPositions } from '@/modules/artefacts/helpers/rack';
import { isRoad, supportsVehicleTypes } from '@/modules/common/helpers/shapes';
import { AreaError } from '@/modules/common/types/shapes';
import { DTShape } from '@/store/recoil/shape';
import shapeAtom from '@/store/recoil/shape/atom';
import { shapesSelector } from '@/store/recoil/shapes';
import { groupByGate, groupByShape } from '../helpers/group';
import { devtools } from 'zustand/middleware';
import { DEVTOOLS_OPTIONS } from '@/modules/debug/constants/zustand';

type GateStore = {};

type GateActions = {
  updateError(shapes: DTShape[]): unknown;
  update(shapes: DTShape[], artefacts: GeneratedFloorPlanArtefacts): void;
  getVehicleShapes(shapeIds: string[]): Set<DTShape>
  getObstacles(shapeIds: string[]): Set<DTShape>
};

const INITIAL_STATE: GateStore = {};

export const useGateStore = create<GateStore & GateActions>()(
  devtools(
    (set, get) => ({
      ...INITIAL_STATE,

      updateError(shapes) {
        shapes.forEach(({ id }) => {
          setRecoil(shapeAtom(id), (state) => ({
            ...state,
            gates: [],
            error: AreaError.PartialGenerationFailed,
          }));
        });
      },

      update(shapes, artefacts) {
        artefacts = convertToSalesCanvasCoordinateSystem(shapes, artefacts);
        const shapeGrouped = groupByShape(shapes, artefacts);

        shapes.forEach((shape) => {
          const { id } = shape;
          const gates = shapeGrouped.get(id);

          if (!gates) {
            setRecoil(shapeAtom(id), (state) => ({
              ...state,
              gates: [],
              error: AreaError.AreaToSmall,
            }));

            return;
          }

          const gateGrouped = filterRackLoadPositions([shape], groupByGate(gates));

          setRecoil(shapeAtom(id), (state) => ({
            ...state,
            gates: Array.from(gateGrouped.values()),
            error: null,
          }));
        });
      },

      getVehicleShapes(shapeIds: string[]) {
        return new Set(
          getRecoil(shapesSelector(shapeIds)).filter(
            (item) => supportsVehicleTypes(item.type) || isRoad(item.type),
          ),
        );
      },

      getObstacles(shapeIds: string[]) {
        return new Set(
          getRecoil(shapesSelector(shapeIds)).filter(
            (item) => !supportsVehicleTypes(item.type) && !isRoad(item.type),
          ),
        );
      },
    }),
    { store: 'gateStore', ...DEVTOOLS_OPTIONS },
  ),
);
