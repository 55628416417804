import { PlaybackMenu } from '@thive/fleet-tracker.ui.react';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { EventNames, useEventBus } from '../hooks';
import { useFleetTrackerWebglApi } from '../hooks/useFleetTrackerWebglApi';
import { currentTimeSecState } from '../store/currentTimeSecState';

type Props = {
  vikingConnected: boolean;
};

export const PlaybackControl = ({ vikingConnected }: Props) => {
  const [playing, setPlaying] = useState(false);
  const [speed, setSpeed] = useState(1);
  const [startTime, setStartTime] = useState<Date>(new Date(0));
  const [currentTimeSec, setCurrentTimeSec] = useRecoilState(currentTimeSecState);
  const [duration, setDuration] = useState<number>(0);
  const { on, off, emit } = useEventBus();
  const { sendPlay, sendPause } = useFleetTrackerWebglApi();

  useEffect(() => {
    if (playing) {
      sendPlay();
    } else {
      sendPause();
    }
  }, [playing, sendPause, sendPlay]);

  useEffect(() => {
    function onReceiveMessage(data: any) {
      if (data.action === 'onCurrentTimeUpdate') {
        const currentTime: Date = new Date(data.payload.Time);
        const secs: number = secondSinceEpoch(currentTime) - secondSinceEpoch(startTime);

        // Stop visualization if time exceeds duration
        if (secs > duration) {
          setPlaying(false);
          setCurrentTimeSec(duration);
        } else {
          setCurrentTimeSec(secs);
        }
      } else if (data.action === 'onPlaybackInitialize') {
        const startTime: Date = new Date(data.payload.StartTime);
        const endTime: Date = new Date(data.payload.EndTime);
        const duration: number = secondSinceEpoch(endTime) - secondSinceEpoch(startTime);
        setDuration(duration);
        setStartTime(startTime);
        setTimeout(zoomFit, 5000);
        sendPlay();
      } else if (data.action === 'onPlaybackReady') {
        setPlaying(true);
      }
    }

    on(EventNames.VisualizationWebGLReceive, onReceiveMessage);

    return () => {
      off(EventNames.VisualizationWebGLReceive, onReceiveMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [speed, duration]);

  const zoomFit = useCallback(() => {
    emit(EventNames.VisualizationWebGLSend, {
      target: 'MainCamera',
      action: 'ZoomFit',
    });
  }, [emit]);

  const handleTimeChange = useCallback(() => {}, []);

  const handleTimeChangeCommitted = useCallback(
    (value: number) => {
      const newTimeMs: number = startTime.getTime() + value * 1000;
      const newTime = new Date(newTimeMs);
      const payload: any = {
        Time: newTime.toISOString(),
      };
      console.log(`Jumping to ${newTime} (${newTimeMs} ms) ${duration}`);
      emit(EventNames.VisualizationWebGLSend, {
        target: 'Tracker',
        action: 'JumpToTimeJson',
        payload,
      });
      setPlaying(false);
      setCurrentTimeSec(value);
    },
    [duration, emit, setCurrentTimeSec, startTime],
  );

  const handleSpeedChange = useCallback(
    (value: number) => {
      if (value === speed) return;
      setSpeed(value);
      emit(EventNames.VisualizationWebGLSend, {
        target: 'Tracker',
        action: 'SetPlaybackSpeed',
        payload: {
          speed: value,
        },
      });
    },
    [emit, speed],
  );

  const secondSinceEpoch = useCallback(
    (date: Date): number => Math.round(date.getTime() / 1000),
    [],
  );

  return vikingConnected ? (
    <PlaybackMenu
      playing={playing}
      speedOptions={[1, 2, 5, 10, 25, 50]}
      speed={speed}
      currentTime={currentTimeSec}
      duration={duration}
      onPlayingChange={() => setPlaying(!playing)}
      onTimeChange={handleTimeChange}
      onTimeChangeCommitted={handleTimeChangeCommitted}
      onSpeedChange={handleSpeedChange}
      alwaysShow
    />
  ) : null;
};
