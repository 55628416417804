import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import { simulationAtom } from './simulationAtom';

/**
 * Floor plan version the currently edited simulation will be run against
 */
export const versionSelector = selector<number>({
  key: 'simulations/draft/version',
  get: ({ get }) => get(simulationAtom).version,
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
