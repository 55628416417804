import { Box, IconButton, SxProps } from '@mui/material';
import { StyledTooltip } from '@common/components/StyledTooltip';
import { MoreHorizontalIcon } from '@/assets/icons';
import { StyledMenu } from '@common/components/StyledMenu';
import { Theme } from '@modules/common/types/general';
import { MouseEvent, ReactNode, useCallback, useState } from 'react';
import { UserPreferenceName } from '@modules/userPreferences';
import { useUserPreference } from '@modules/userPreferences/hooks';
import { useTranslation } from 'react-i18next';

type Props = {
  children: ReactNode;
  sx?: SxProps;
};

export function Menu({ sx, children }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation('interface');
  const open = Boolean(anchorEl);
  const theme = useUserPreference(UserPreferenceName.GENERAL_THEME);

  const onMoreClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <Box sx={sx}>
      <StyledTooltip title={t('simulation.run_card.more_button.label')}>
        <IconButton onClick={onMoreClick} sx={{ p: 0 }}>
          <MoreHorizontalIcon />
        </IconButton>
      </StyledTooltip>
      <StyledMenu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClick={handleClose}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        className={theme === Theme.DARK ? 'dark' : ''}
      >
        {children}
      </StyledMenu>
    </Box>
  );
}
