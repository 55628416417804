import { SvgIcon } from '@mui/material';
import { memo } from 'react';

const ErrorIcon = ({ className = '', size = 24, color = 'currentColor', ...rest }) => (
  <SvgIcon
    viewBox='0 0 24 24'
    sx={{ width: size, height: size, fontSize: `${size}px`, fill: color }}
    className={className}
    {...rest}
  >
    <path
      d='M12 16.4615C12.1744 16.4615 12.3205 16.4026 12.4385 16.2846C12.5564 16.1667 12.6154 16.0205 12.6154 15.8462C12.6154 15.6718 12.5564 15.5257 12.4385 15.4077C12.3205 15.2898 12.1744 15.2308 12 15.2308C11.8256 15.2308 11.6795 15.2898 11.5615 15.4077C11.4436 15.5257 11.3846 15.6718 11.3846 15.8462C11.3846 16.0205 11.4436 16.1667 11.5615 16.2846C11.6795 16.4026 11.8256 16.4615 12 16.4615ZM11.5 13.1538H12.5V7.15385H11.5V13.1538ZM12.0034 21C10.7588 21 9.58872 20.7638 8.4931 20.2915C7.39748 19.8192 6.44444 19.1782 5.63397 18.3685C4.82352 17.5588 4.18192 16.6066 3.70915 15.512C3.23638 14.4174 3 13.2479 3 12.0033C3 10.7588 3.23616 9.58872 3.70848 8.4931C4.18081 7.39748 4.82183 6.44444 5.63153 5.63398C6.44123 4.82353 7.39337 4.18192 8.48795 3.70915C9.58255 3.23638 10.7521 3 11.9966 3C13.2412 3 14.4113 3.23616 15.5069 3.70848C16.6025 4.18081 17.5556 4.82182 18.366 5.63152C19.1765 6.44122 19.8181 7.39337 20.2908 8.48795C20.7636 9.58255 21 10.7521 21 11.9967C21 13.2412 20.7638 14.4113 20.2915 15.5069C19.8192 16.6025 19.1782 17.5556 18.3685 18.366C17.5588 19.1765 16.6066 19.8181 15.512 20.2908C14.4174 20.7636 13.2479 21 12.0034 21ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z'
      fill={color}
    />
  </SvgIcon>
);

export default memo(ErrorIcon);
