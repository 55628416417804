import { MenuItem, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isDeleterOpenState, selectedFloorplanIdsState } from '../store';
import { DeleteIcon } from '@/assets/icons';

export const DeleteOption = () => {
  const selectedFloorplansIds = useRecoilValue(selectedFloorplanIdsState);
  const setDeleterIsOpen = useSetRecoilState(isDeleterOpenState);
  const openDeleter = useCallback(() => {
    setDeleterIsOpen(true);
  }, [setDeleterIsOpen]);

  return (
    <MenuItem
      onClick={openDeleter}
      component='button'
      sx={{
        display: 'flex',
        gap: '14px',
        width: '100%',
        paddingLeft: '21px',
        paddingRight: '21px',
      }}
    >
      <DeleteIcon />
      <Typography>
        {`Delete ${selectedFloorplansIds.size || 1} floorplan${
          selectedFloorplansIds.size > 1 ? 's' : ''
        }`}
      </Typography>
    </MenuItem>
  );
};
