import { AreaEndpointDirection } from '@modules/common/types/shapes';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import { WorkspaceMode } from '@/modules/common/types/general';
import { modeSelector } from '@modules/common/store/workspace';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useCallback } from 'react';
import { useFloorPlanState } from '../../../modules/floorplan/hooks/useFloorPlanState';
import { areaEndpointDirection } from '../store/area';
import { useArtefacts } from '@/modules/artefacts';
import { selectedShapesIdsState } from '@/store/recoil/shapes/selected';

export const EndpointDirection = () => {
  const { t } = useTranslation('interface');
  const { saveFloorPlan } = useFloorPlanState();
  const [endpointDirection, setEndpointDirection] = useRecoilState(areaEndpointDirection);
  const mode = useRecoilValue(modeSelector);
  const { updateDebounced: updateArtefacts } = useArtefacts();
  const ids = useRecoilValue(selectedShapesIdsState);

  const onChange = useCallback(
    (_, value: AreaEndpointDirection) => {
      if (value !== null) {
        setEndpointDirection(value);
        saveFloorPlan();
        updateArtefacts(ids);
      }
    },
    [ids, saveFloorPlan, setEndpointDirection, updateArtefacts],
  );

  return (
    <ToggleButtonGroup
      value={endpointDirection}
      exclusive
      onChange={onChange}
      disabled={mode !== WorkspaceMode.EDITABLE}
    >
      <ToggleButton
        value={AreaEndpointDirection.HORIZONTAL}
        aria-label='Generate load position horizontally'
        sx={{ p: 0, fontSize: '13px' }}
      >
        {t('properties.area.sideloading.endpoint_direction.horizontal')}
      </ToggleButton>
      <ToggleButton
        value={AreaEndpointDirection.VERTICAL}
        aria-label='Generate load position vertically'
        sx={{ p: 0, fontSize: '13px' }}
      >
        {t('properties.area.sideloading.endpoint_direction.vertical')}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
