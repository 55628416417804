import { atom, atomFamily, selectorFamily } from 'recoil';

import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { HistoryManager } from '@/store/recoil/history';
import { DEFAULT_ENABLED_VEHICLE_TYPE_IDS } from '../../constants';
import { allVehiclesState } from '../allVehiclesState';
import { UnifiedVehicle } from '../../types';

export const enabledVehicleState = atomFamily<UnifiedVehicle, string>({
  key: 'vehicle/enabled',
  default: selectorFamily({
    key: 'vehicle/enabled/default',
    get:
      (id: string) =>
      ({ get }) =>
        get(allVehiclesState).find((item) => item.id === id),
    cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
  }),
});

export const enabledVehicleIdsState = atom<string[]>({
  key: 'vehicles/enabled/ids',
  default: DEFAULT_ENABLED_VEHICLE_TYPE_IDS,
  effects: [
    ({ onSet, setSelf }) => {
      onSet((newValue: string[], oldValue: string[]) => {
        const newLengthDiff = newValue.length - oldValue.length;

        if (
          newLengthDiff > 0 ||
          newLengthDiff < 0 ||
          (newLengthDiff === 0 &&
            // if length is same, check if the array contents are also the same
            !newValue.every((newValueElement, i) => newValueElement === oldValue[i]))
        ) {
          HistoryManager.track('Vehicle changed', newValue, oldValue, setSelf);
        }
      });
    },
  ],
});
