import { useCallback } from 'react';

import { useUserPreferencesApi } from './useUserPreferencesApi';
import { useUserPreferencesStore } from '../store';

export const useLoadUserPreferences = () => {
  const { fetch } = useUserPreferencesApi();

  const load = useCallback(async () => {
    const userPreferences = await fetch();
    useUserPreferencesStore.getState().initialize(userPreferences);
  }, [fetch]);

  return {
    load,
  };
};
