import { useTranslation } from 'react-i18next';
import { Grid, Typography, TextField } from '@mui/material';

export const OperationCode = () => {
    const { t } = useTranslation('interface');

    return (
        <Grid
            container
            spacing={1}
            alignItems='center'
            columns={12}
            sx={{ '&:hover': { cursor: 'default' }, my: 1, px: 2 }}
        >
            <Grid item xs={6}>
                <Typography fontSize='10px'>{t('interface:commissioning.gate.operation_code', 'Operation code')}</Typography>
            </Grid>
            <Grid item xs={6} sx={{ paddingRight: 1 }}>
                <TextField
                    name='Operation code'
                    variant='filled'
                    value='$C3'
                    disabled={false}
                    onChange={() => { }}
                    InputProps={{
                        disableUnderline: true
                    }}
                />
            </Grid>
        </Grid>
    )
}
