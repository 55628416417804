import { VehicleAssets } from '@/modules/vehicles/types';

export const createVehicleTypeId = (name: string) => `${name.replaceAll(' ', '_')}`;

export const buildVehicleAsset = (
  vehicleId: string,
  uvtFileReference?: string,
  kmMdbFileReference?: string,
): VehicleAssets => ({
  vehicleVariantId: vehicleId,
  uvtReference: uvtFileReference ?? null,
  uvtModificationDate: 0,
  uvtReferenceOriginal: true,
  kmMdbReference: kmMdbFileReference ?? null,
  kmMdbModificationDate: 0,
  kmMdbReferenceOriginal: true,
});
