import { Crossing } from '@modules/common/types/connections';
import {
  allCrossingSelector,
  updateCrossingsSelector,
} from '@modules/connections/crossings/store';
import { useRecoilCallback } from 'recoil';

export type UndoRedoCrossingsState = {
  crossings: Crossing[];
};

export const useUndoRedoCrossings = () => {
  const getCrossingsDeleteState = useRecoilCallback(
    ({ snapshot }) =>
      async (
        ids: string[],
      ): Promise<{
        newState: UndoRedoCrossingsState;
        oldState: UndoRedoCrossingsState;
      }> => {
        const crossings = await snapshot.getPromise(allCrossingSelector);
        const newCrossings = crossings.filter((item) => !ids.includes(item.id));

        return {
          newState: { crossings: newCrossings},
          oldState: { crossings },
        };
      },
    [],
  );

  const restoreCrossingsState = useRecoilCallback(
    ({ snapshot, set }) =>
      async ({ crossings }: UndoRedoCrossingsState) => {
        const allCrossings = await snapshot.getPromise(allCrossingSelector);
        const existingIds = allCrossings.map((item) => item.id);
        const newCrossingIds = crossings.map((item) => item.id);
        const idsToRemove = existingIds.filter((item) => !newCrossingIds.includes(item));

        set(updateCrossingsSelector, {
          crossings,
          crossingIdsToRemove: new Set(idsToRemove),
        });
      },
    [],
  );

  return {
    getCrossingsDeleteState,
    restoreCrossingsState,
  };
};
