import { PropertyPanel as ReferenceImagePropertyPanel } from '@modules/referenceImage';
import { Divider, Stack } from '@mui/material';
import { FloorPlanProperties } from './FloorPlanProperties';

export function PropertyPanel() {
  const propertiesPanelStyle = {
    boxShadow: 'none',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column' as const,
  };

  return (
    <Stack gap={2} style={propertiesPanelStyle} className='overflowHidden'>
      <ReferenceImagePropertyPanel />
      <Divider />
      <FloorPlanProperties />
      <Divider />
    </Stack>
  );
}
