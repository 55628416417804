interface IHistoryManager {
  track: <TrackingData>(
    label: string,
    newValue: TrackingData,
    oldValue: TrackingData,
    setSelfCallback: (historyState: TrackingData) => void,
  ) => void;
  undo(): void;
  redo(): void;
  startTracking(): void;
  stopTracking(): void;
  clearHistory(): void;
}

type HistoryManagerState = {
  hasUndo: boolean;
  hasRedo: boolean;
};

export class HistoryManagerClass implements IHistoryManager {
  private history = {
    past: [],
    future: [],
  };
  private depth: number;
  private trackingEnabled = false;
  private syncToStore: (state: HistoryManagerState) => void;

  constructor(depth: number, syncToStoreCallback: (state: HistoryManagerState) => void) {
    this.depth = depth;
    this.syncToStore = syncToStoreCallback;
  }

  startTracking() {
    this.trackingEnabled = true;
  }

  stopTracking() {
    this.trackingEnabled = false;
  }

  clearHistory(): void {
    this.history = {
      past: [],
      future: [],
    };
  }

  track<TrackingData>(
    label: string,
    newValue: TrackingData,
    oldValue: TrackingData,
    setSelfCallback: (historyState: TrackingData) => void,
  ): void {
    if (!this.trackingEnabled) return;

    const historyEntry = {
      label,
      undo: () => {
        setSelfCallback(oldValue);
      },
      redo: () => {
        setSelfCallback(newValue);
      },
    };
    this.saveHistoryEntry(historyEntry);
  }

  undo(): void {
    const lastState = this.history.past.pop();
    if (lastState) {
      lastState.undo();
      this.history.future.push(lastState);
    }

    this.updateHistoryState();
  }

  redo(): void {
    const lastState = this.history.future.pop();
    if (lastState) {
      lastState.redo();
      this.history.past.push(lastState);
    }

    this.updateHistoryState();
  }

  private saveHistoryEntry(entry: any): void {
    // Reached max undo, remove first in row
    if (this.history.past.length === this.depth) this.history.past.shift();

    this.history.past.push(entry);
    // reset future
    this.history.future = [];

    this.updateHistoryState();
  }

  private hasUndo(): boolean {
    return this.history.past.length > 0;
  }

  private hasRedo(): boolean {
    return this.history.future.length > 0;
  }

  private getHistoryState(): HistoryManagerState {
    return {
      hasUndo: this.hasUndo(),
      hasRedo: this.hasRedo(),
    };
  }

  private updateHistoryState(): void {
    this.syncToStore(this.getHistoryState());
  }
}
