import { ShapeType } from '@modules/common/types/shapes';

export const STROKE_COLOR = '#222';
export const COLORS = {
  [ShapeType.INTAKE]: '#B6DFFD',
  [ShapeType.DELIVERY]: '#FAE9BB',
  [ShapeType.STORAGE]: '#DBEACD',
  [ShapeType.CHARGING]: '#D6D6D9',
  [ShapeType.PROCESS_ONE_EP]: '#F3D8CE',
  [ShapeType.HIGHWAY]: '#F0E0FB',
  [ShapeType.HIGHWAY_ANGLED]: '#F0E0FB',
  [ShapeType.OBSTACLE]: '#D6D6D9',
  [ShapeType.WALL]: '#D6D6D9',
};

export const GATE_COLORS = {
  [ShapeType.INTAKE]: '#A3C8E3',
  [ShapeType.DELIVERY]: '#E0D1A8',
  [ShapeType.STORAGE]: '#C4D2B8',
  [ShapeType.CHARGING]: '#B0B0B2',
  [ShapeType.PROCESS_ONE_EP]: '#DAC3B8',
  [ShapeType.HIGHWAY]: '#DACCE5',
  [ShapeType.HIGHWAY_ANGLED]: '#DACCE5',
};

export const LOAD_COLORS = {
  [ShapeType.INTAKE]: '#62B5EF',
  [ShapeType.DELIVERY]: '#EDCB68',
  [ShapeType.STORAGE]: '#AECC8D',
  [ShapeType.PROCESS_ONE_EP]: '#DEA98E',
};

export const CHECKPOINT_COLORS = {
  [ShapeType.INTAKE]: '#0A1E89',
  [ShapeType.DELIVERY]: '#B34624',
  [ShapeType.STORAGE]: '#627C46',
  [ShapeType.CHARGING]: '#75F94C',
  [ShapeType.PROCESS_ONE_EP]: '#C74B89',
  [ShapeType.HIGHWAY]: '#8F1DDE',
  [ShapeType.HIGHWAY_ANGLED]: '#8F1DDE',
};
