import { Canvas } from '@modules/canvas';
import { useEvents, useKeyboardEvents, useLoadData, useModule, useStore } from '../hooks';

export const Workspace = () => {
  useLoadData();
  useEvents();
  useKeyboardEvents();
  useModule();
  useStore();
  return <Canvas />;
};
