import { useEvents, useKeyboardEvents } from '@/modules/commissioning/hooks';
import { useLoadData } from '@/modules/salesWorkspace/hooks';
import { useMouseEvents } from '@/modules/salesWorkspace/hooks/mouseHandler/useMouseEvents';
import { useActivePointsDrawing } from '@/modules/salesWorkspace/hooks/points/useActivePointsDrawing';
import { useTool } from '@/modules/salesWorkspace/hooks/useTool';
import { Canvas } from '@modules/canvas';

export const Workspace = () => {
  useLoadData();
  useEvents();
  useKeyboardEvents();
  useMouseEvents();
  useTool();
  useActivePointsDrawing();
  return <Canvas />;
};
