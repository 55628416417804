import { SvgIcon } from '@mui/material';

const ReplayIcon = ({ className = '', ...rest }) => (
  <SvgIcon
    width='24'
    height='24'
    viewBox='0 0 24 24'
    style={{ fill: 'currentColor', stroke: 'none' }}
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    {...rest}
  >
    <path
      d='M12 5V1L7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8'
      fill='#2537F3'
    />
  </SvgIcon>
);
export default ReplayIcon;
