import { useFloorPlanService } from '@/modules/floorplan';
import { useLoadCarrierService } from '@/modules/loadCarriers';
import { referenceState, referenceType } from '@/modules/referenceImage';
import { useVehicleService, enabledVehiclesLengthSelector, enabledVehiclesSelector, enabledVehiclesDetailsSelector } from '@/modules/vehicles';
import { wifiResultAtom } from '@/modules/wifisimulation/store/wifiResultAtom';
import { allShapesSelector } from '@/store/recoil/shapes';
import { DTShape, ShapeType } from '@modules/common/types/shapes';
import { enabledLoadCarrierTypesSelector } from '@recoil/loadCarrierTypes';
import { useCallback } from 'react';
import { useRecoilCallback } from 'recoil';
import { ObjectUpdateContract } from '../class';
import { SOURCE_NAME } from '../consts';
import { createAccessPoint } from '../helpers/createAccessPoint';
import { SAFETY_ZONE_TAG } from '../helpers/safetyZones';
import {
  calculateFleetTrackerWorkspaceBox,
  createVisualizationObjects,
} from '../helpers/visualizationObject';
import { AdapterSnapshotContract, VisualizationObject, VisualizationTag } from '../types';
import { useFleetTrackerWebglApi } from './useFleetTrackerWebglApi';
import { enabledVehicleState } from '@/modules/vehicles/store/enabled/atom';

const MAX_OBJECT_IN_CHUNK = 20;
const CHUNK_DELAY_MS = 500;

export function useDigitalTwinFleetTrackerAdaptor() {
  const { fetchVersion } = useFloorPlanService();
  const { getVehicleDetailsById } = useVehicleService();
  const { getLoadTypeById } = useLoadCarrierService();
  const { sendAdaptorSnapshotContract, sendMessage } = useFleetTrackerWebglApi();

  const sendVisualizationObject = useCallback(
    (objects: VisualizationObject[]) => {
      const chunk = [];
      for (let i = 0; i < objects.length; i += MAX_OBJECT_IN_CHUNK) {
        chunk.push(objects.slice(i, i + MAX_OBJECT_IN_CHUNK));
      }

      for (let i = 0; i < chunk.length; i += 1) {
        setTimeout(() => {
          const message = new ObjectUpdateContract(chunk[i]);
          sendMessage(message);
        }, CHUNK_DELAY_MS * i);
      }
    },
    [sendMessage],
  );

  const drawFloorPlan = useRecoilCallback(
    ({ snapshot }) =>
      async (projectId: string, groupId: string, versionId: string, pad: boolean) => {
        const floorPlan = await fetchVersion(projectId, groupId, versionId);
        const vehicles = await Promise.all(
          floorPlan.additionalData.vehicleTypes.map((item) => getVehicleDetailsById(item)),
        );
        const loadCarriers = await Promise.all(
          floorPlan.additionalData.loadTypes.map((item) => getLoadTypeById(item)),
        );
        const state = await snapshot.getPromise(referenceState);
        const type = await snapshot.getPromise(referenceType);
        const padding = pad ? Math.max(...vehicles.map((item) => item.length)) : 0;
        sendVisualizationObject(
          createVisualizationObjects(
            floorPlan.additionalData.shapes as DTShape[],
            loadCarriers,
            vehicles,
            padding,
            { ...state, type },
          ),
        );
      },
    [fetchVersion, getLoadTypeById, getVehicleDetailsById, sendVisualizationObject],
  );

  const drawFromLocalState = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const shapes = await snapshot.getPromise(allShapesSelector);
        const loadCarriers = await snapshot.getPromise(enabledLoadCarrierTypesSelector);
        const vehicles = await snapshot.getPromise(enabledVehiclesDetailsSelector)
        const state = await snapshot.getPromise(referenceState);
        const type = await snapshot.getPromise(referenceType);

        sendVisualizationObject(
          createVisualizationObjects(shapes, loadCarriers, vehicles, 0, { ...state, type }),
        );
        drawWifiSimulationResult();
      },
    [createVisualizationObjects, sendVisualizationObject],
  );

  const drawWifiSimulationResult = useRecoilCallback(({ snapshot }) => async () => {
    const result = await snapshot.getPromise(wifiResultAtom);
    if (!result || result.configurations?.length === 0) return;

    const shapes = await snapshot.getPromise(allShapesSelector);
    const vehicleLength = await snapshot.getPromise(enabledVehiclesLengthSelector);
    const workspaceBox = calculateFleetTrackerWorkspaceBox(shapes, vehicleLength);

    result.configurations[0].access_points.forEach((ap) => {
      const vo = createAccessPoint(ap, workspaceBox);
      sendVisualizationObject([vo]);
    });
  });

  function registerAdapter() {
    let shapeTypes = Object.values(ShapeType);
    shapeTypes = [...shapeTypes];
    shapeTypes = shapeTypes.filter((value) => !value.includes('position') && value !== 'none');
    let areaTags: VisualizationTag[] = [];
    shapeTypes.forEach((s) => {
      areaTags.push({
        Name: s,
        Children: [],
      });
    });

    const safetyZoneTag: VisualizationTag = { Name: SAFETY_ZONE_TAG, Children: [] };
    const payload: AdapterSnapshotContract = {
      MessageType: 'AdapterSnapshot',
      AvailableDataSource: {
        Name: SOURCE_NAME,
        Tags: [...areaTags, safetyZoneTag],
      },
    };

    sendAdaptorSnapshotContract(payload);
  }

  return { registerAdapter, drawFloorPlan, drawFromLocalState };
}
