import { AdditionalData, LoadCarrierType } from '@modules/common/types/floorPlan';
import { DEFAULT_ENABLED_LOAD_TYPE_IDS } from '@modules/common/constants/loadTypes';

/**
 * Prepares load type state to be loaded into the app
 */
export const prepareToLoad = (
  additionalData: AdditionalData,
  allVehicles: LoadCarrierType[],
): LoadCarrierType[] => {
  const loadTypes = allVehicles.filter((l) =>
    additionalData.loadTypes.find((item) => item === l.id),
  );

  return loadTypes.length > 0
    ? loadTypes
    : allVehicles.filter((item) => DEFAULT_ENABLED_LOAD_TYPE_IDS.includes(item.id));
};

/**
 * Prepares load type state to be saved
 */
export const prepareToSave = (enabledVehicles: LoadCarrierType[]): string[] =>
  enabledVehicles.map((item) => item.id);
