import { SvgIcon } from '@mui/material';
import { memo } from 'react';

function SvgComponent(props) {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      style={{ stroke: 'none', fill: 'none' }}
      {...props}
    >
      <mask
        id='mask0_521_6025'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='24'
        height='24'
      >
        <rect width='24' height='24' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_521_6025)'>
        <path
          d='M5.7 19.125C4.96666 19.125 4.346 18.871 3.838 18.363C3.32933 17.8543 3.075 17.2333 3.075 16.5H1.7V6.625C1.7 6.15833 1.85433 5.771 2.163 5.463C2.471 5.15433 2.85 5 3.3 5H16.55V8.625H18.85L22.3 13.275V16.5H20.7C20.7 17.2333 20.446 17.8543 19.938 18.363C19.4293 18.871 18.8083 19.125 18.075 19.125C17.3417 19.125 16.721 18.871 16.213 18.363C15.7043 17.8543 15.45 17.2333 15.45 16.5H8.3C8.3 17.2333 8.046 17.8543 7.538 18.363C7.02933 18.871 6.41666 19.125 5.7 19.125ZM5.7 18.125C6.15 18.125 6.52933 17.9667 6.838 17.65C7.146 17.3333 7.3 16.95 7.3 16.5C7.3 16.05 7.146 15.6667 6.838 15.35C6.52933 15.0333 6.15 14.875 5.7 14.875C5.25 14.875 4.86666 15.0333 4.55 15.35C4.23333 15.6667 4.075 16.05 4.075 16.5C4.075 16.95 4.23333 17.3333 4.55 17.65C4.86666 17.9667 5.25 18.125 5.7 18.125ZM2.7 15.5H3.35C3.48333 15.0667 3.771 14.6877 4.213 14.363C4.65433 14.0377 5.15 13.875 5.7 13.875C6.21666 13.875 6.7 14.0333 7.15 14.35C7.6 14.6667 7.9 15.05 8.05 15.5H15.55V6H3.3C3.15 6 3.01266 6.06267 2.888 6.188C2.76266 6.31267 2.7 6.45833 2.7 6.625V15.5ZM18.075 18.125C18.525 18.125 18.9083 17.9667 19.225 17.65C19.5417 17.3333 19.7 16.95 19.7 16.5C19.7 16.05 19.5417 15.6667 19.225 15.35C18.9083 15.0333 18.525 14.875 18.075 14.875C17.625 14.875 17.2417 15.0333 16.925 15.35C16.6083 15.6667 16.45 16.05 16.45 16.5C16.45 16.95 16.6083 17.3333 16.925 17.65C17.2417 17.9667 17.625 18.125 18.075 18.125ZM16.55 13.5H21.25L18.3 9.625H16.55V13.5Z'
          fill='currentColor'
        />
      </g>
    </SvgIcon>
  );
}

const Memo = memo(SvgComponent);
export default Memo;
