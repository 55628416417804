import { Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/system';
import { theme } from '@/modules/common/components/theme';

/*
  @deprecated Use StyledTooltip from common
 */
export const StyledTooltip = styled(({ className, offset = 0, ...props }) => (
  // Add arrow property to show arrow again
  <Tooltip {...props} classes={{ popper: className }} />
))((props) => ({
  [`& .${tooltipClasses.arrow}::before`]: {
    backgroundColor: theme.palette.primary.light,
    border: '1px solid',
    borderColor: theme.palette.primary.light
  },

  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: 0,
    fontWeight: 400,
    color: theme.palette.neutral.darker,
    fontSize: 10,
    padding: '4px 8px',
  },
  [`& .${tooltipClasses.tooltip} span`]: {
    fontWeight: 400,
    color: theme.palette.primary.main,
  },
  [`& .${tooltipClasses.tooltipPlacementBottom}`]: {
    top: props.offset,
  },
  [`& .${tooltipClasses.tooltipPlacementRight}`]: {
    marginLeft: '2px !important',
  },
}));
