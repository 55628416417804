import { Element } from '@thive/canvas';

import { BoundingBox } from '@/helpers/types';
import { Connection, DistantConnection } from '@/modules/common/types/connections';
import { convertToBottomLeftCoordinateSystem as convertConnection } from '@/modules/connections/common/helpers';
import { convertToBottomLeftCoordinateSystem as convertDistantConnection } from '@/modules/connections/distant/helpers/connections';
import { createConnectionElement } from '@/modules/salesWorkspace/mappers/connection/connection';
import { createDistantConnectionElement } from '@/modules/salesWorkspace/mappers/connection/distantConnection';

export const map = (
  connections: readonly Connection[],
  distantConnections: readonly DistantConnection[],
  boundingBox: BoundingBox,
): Element[] => {
  const elements: Element[] = [];
  
  const mappedConnections = convertConnection(connections, boundingBox);
  mappedConnections.forEach((connection) => {
    elements.push(...createConnectionElement(connection))
  });
  const mappedDistantConnections = convertDistantConnection(distantConnections, boundingBox);
  mappedDistantConnections.forEach((connection) => {
    elements.push(...createDistantConnectionElement(connection))
  });

  return elements;
};
