import { Divider, Stack } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { LoadCarrierSelect } from '@/components/PropertiesPanel/layout/LoadCarrierSelect';
import OperationTime from '@/components/PropertiesPanel/layout/process/OperationTime';
import { ProcessLoadCarrierSide } from '@/components/PropertiesPanel/layout/process/ProcessLoadCarrierSide';
import ProcessParkingDirection from '@/components/PropertiesPanel/layout/process/ProcessParkingDirection';
import { ProcessVehicleSelect } from '@/components/PropertiesPanel/layout/process/ProcessVehicleSelect';
import ProcessWidth from '@/components/PropertiesPanel/layout/process/ProcessWidth';
import { processStorageType } from '@/components/PropertiesPanel/store/process';
import { PropertiesTitle } from '@/modules/common/components/PropertiesTitle';
import { Accordion } from '@common/components/Accordion';
import { PropertiesLabel } from '@modules/common/components/PropertiesLabel';
import ProcessStorageType from './ProcessStorageType';
import ProcessDirection from './ProcessDirection';
import { StorageType } from '@/modules/common/types/storage';
import { ProcessLoadElevation } from './ProcessLoadElevation';
import ProcessSideLoadingProperties from './ProcessSideLoadingProperties';

export default function ProcessTwoEndPointPropery() {
  const intakeStorageType = useRecoilValue(
    processStorageType('intakeParameters'),
  );
  const deliveryStorageType = useRecoilValue(
    processStorageType('deliveryParameters'),
  );

  return (
    <Accordion
      title='Process'
      titleSx={{ textTransform: 'capitalize' }}
      sx={{ px: 2 }}
      defaultExpanded
    >
      <Stack direction='column' spacing={1}>
        <PropertiesLabel i18nkey='common:width'>
          <ProcessWidth />
        </PropertiesLabel>

        <PropertiesLabel i18nkey='interface:properties.area.operation_time'>
          <OperationTime />
        </PropertiesLabel>

        <PropertiesLabel i18nkey='interface:properties.area.load_carrier_select.label'>
          <LoadCarrierSelect />
        </PropertiesLabel>

        <Divider variant='middle' />
        <PropertiesTitle fontSize={12} fontWeight={400} value='Delivery' />
        <PropertiesLabel i18nkey='interface:properties.area.supported_vehicle.label'>
          <ProcessVehicleSelect type='deliveryParameters' />
        </PropertiesLabel>
        <PropertiesLabel i18nkey='interface:properties.area.load_carrier_orientation.label'>
          <ProcessLoadCarrierSide type='deliveryParameters' />
        </PropertiesLabel>
        <PropertiesLabel i18nkey='interface:properties.area.storage_type_label'>
          <ProcessStorageType type='deliveryParameters' />
        </PropertiesLabel>

        { deliveryStorageType === StorageType.SINGLE && 
          <ProcessLoadElevation type='deliveryParameters' />
        }

        { deliveryStorageType === StorageType.SIDELOADING && (<>
          <PropertiesLabel i18nkey='interface:properties.area.direction'>
            <ProcessDirection type='deliveryParameters' />
          </PropertiesLabel>
          <PropertiesLabel i18nkey='interface:properties.area.parking_direction'>
            <ProcessParkingDirection type='deliveryParameters' />
          </PropertiesLabel>
          <ProcessSideLoadingProperties type='deliveryParameters' />
        </>) }

        <Divider variant='middle' />
        <PropertiesTitle fontSize={12} fontWeight={400} value='Intake' />
        <PropertiesLabel i18nkey='interface:properties.area.supported_vehicle.label'>
          <ProcessVehicleSelect type='intakeParameters' />
        </PropertiesLabel>
        <PropertiesLabel i18nkey='interface:properties.area.load_carrier_orientation.label'>
          <ProcessLoadCarrierSide type='intakeParameters' />
        </PropertiesLabel>
        <PropertiesLabel i18nkey='interface:properties.area.storage_type_label'>
          <ProcessStorageType type='intakeParameters' />
        </PropertiesLabel>

        { intakeStorageType === StorageType.SINGLE && 
          <ProcessLoadElevation type='intakeParameters' />
        }

        { intakeStorageType === StorageType.SIDELOADING && (<>
          <PropertiesLabel i18nkey='interface:properties.area.direction'>
            <ProcessDirection type='intakeParameters' />
          </PropertiesLabel>
          <PropertiesLabel i18nkey='interface:properties.area.parking_direction'>
            <ProcessParkingDirection type='intakeParameters' />
          </PropertiesLabel>
          <ProcessSideLoadingProperties type='intakeParameters' />
        </>) }
      </Stack>
    </Accordion>
  );
}
