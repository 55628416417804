import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import { Simulation } from '../../helpers/types';
import { simulationIdsAtom, simulationAtom } from './simulationsAtom';

export const simulationsSelector = selector<Simulation[]>({
  key: 'simulations/simulations/all',
  get: ({ get }) => get(simulationIdsAtom).map((item) => get(simulationAtom(item))),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
