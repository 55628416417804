import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import ListIcon from '@mui/icons-material/List';
import SearchIcon from '@mui/icons-material/Search';

import { DeleteIcon, GridViewIcon, PlusIcon } from '@/assets/icons';
import { StyledTooltip } from '@common/components/StyledTooltip';
import { TextInput } from '@common/components/inputs';
import { FloorplanGrid } from './FloorplanGrid';
import { FloorplanList } from './FloorplanList';
import { SortingMenu } from './sortingMenu/SortingMenu';
import {
  isCreatorOpenState,
  isDeleterOpenState,
  searchState,
  selectedFloorplanIdsState,
} from './store';
import { floorPlanGroupsSelector } from './store/floorPlanGroup';
import {
  DEFAULT_SORT_ORDER_OPTIONS,
  FloorplanViewMode,
  SortingCriteria,
  gridViewEnabledSortingCriteria,
} from '@/modules/floorplanAdmin/sortingMenu/constants';
import { UserPreferenceName, getUserPreference } from '@/modules/userPreferences';
import { useUserPreference, useUpdateUserPreferences } from '@/modules/userPreferences/hooks';
import { floorPlanGroupIdsAtom } from '@/modules/floorplanAdmin/store/floorPlanGroup/floorPlanGroupAtom';
import { FloorPlanGroup } from '@/modules/floorplanAdmin/helpers/types';
import { getSortedFloorPlanGroups } from '@/modules/floorplanAdmin/helpers/floorplans';
import { queryItems } from '@/modules/common/helpers/search';

export const FloorplanLayout = () => {
  const { t } = useTranslation();
  const setDeleterIsOpen = useSetRecoilState(isDeleterOpenState);
  const setCreatorIsOpen = useSetRecoilState(isCreatorOpenState);
  const [selectedFloorplanIds, setSelectedFloorplans] = useRecoilState(selectedFloorplanIdsState);
  const [search, setSearch] = useRecoilState(searchState);
  const floorplanView = useUserPreference(UserPreferenceName.FLOORPLAN_ADMIN_VIEW_MODE);
  const sortingCriteria = useUserPreference(UserPreferenceName.FLOORPLAN_ADMIN_SORT_BY);
  const sortOrder = useUserPreference(UserPreferenceName.FLOORPLAN_ADMIN_SORT_ORDER);
  const { updateUserPreference } = useUpdateUserPreferences();
  const allFloorPlanIds = useRecoilValue(floorPlanGroupIdsAtom);
  const allFloorPlans = useRecoilValue(floorPlanGroupsSelector(allFloorPlanIds));
  const [sortedFloorPlanGroups, setSortedFloorPlanGroups] = useState<FloorPlanGroup[]>([]);
  const [floorPlanGroupsHits, setFloorPlanGroupsHits] = useState<FloorPlanGroup[]>([]);
  const floorPlanGroupsHitsIds = floorPlanGroupsHits.map((item) => item.id);

  useEffect(() => {
    setSortedFloorPlanGroups(getSortedFloorPlanGroups(allFloorPlans, sortingCriteria, sortOrder));
  }, [allFloorPlans, sortingCriteria, sortOrder]);

  useEffect(() => {
    setFloorPlanGroupsHits(queryItems(search, sortedFloorPlanGroups, (item) => item.name));
  }, [sortedFloorPlanGroups, search]);

  const switchToGridView = useCallback(() => {
    const currentSortCriteria = getUserPreference(UserPreferenceName.FLOORPLAN_ADMIN_SORT_BY);
    if (!gridViewEnabledSortingCriteria.includes(currentSortCriteria)) {
      // NOTE: patch unsupported sorting criteria in grid view
      const overrideCriteria = SortingCriteria.NAME;
      updateUserPreference(UserPreferenceName.FLOORPLAN_ADMIN_SORT_BY, overrideCriteria, false);
      updateUserPreference(
        UserPreferenceName.FLOORPLAN_ADMIN_SORT_ORDER,
        DEFAULT_SORT_ORDER_OPTIONS[overrideCriteria],
        false,
      );
    }

    updateUserPreference(UserPreferenceName.FLOORPLAN_ADMIN_VIEW_MODE, FloorplanViewMode.GRID);
  }, [updateUserPreference]);

  const switchToListView = useCallback(() => {
    updateUserPreference(UserPreferenceName.FLOORPLAN_ADMIN_VIEW_MODE, FloorplanViewMode.LIST);
  }, [updateUserPreference]);

  const selectAllFloorplans = useCallback(() => {
    if (selectedFloorplanIds.size != floorPlanGroupsHitsIds.length)
      setSelectedFloorplans(new Set([...floorPlanGroupsHitsIds]));
    else setSelectedFloorplans(new Set());
  }, [floorPlanGroupsHitsIds, setSelectedFloorplans, selectedFloorplanIds]);

  const openDeleter = useCallback(() => {
    setDeleterIsOpen(true);
  }, [setDeleterIsOpen]);

  const openCreator = useCallback(() => {
    setCreatorIsOpen(true);
  }, [setCreatorIsOpen]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flexGrow: 1,
      }}
    >
      <Container maxWidth='xl'>
        <Stack direction='row' justifyContent='space-between' alignItems='center' mt={5} mb={3}>
          {selectedFloorplanIds.size > 0 ? (
            <Stack direction='row' gap={1} alignItems='center'>
              <StyledTooltip
                placement='bottom'
                title={
                  <Trans
                    i18nKey='select_all_floorplans.tooltip'
                    ns='interface'
                    components={{
                      s: <span />,
                    }}
                  >
                    Select all floorplans
                  </Trans>
                }
              >
                <IconButton onClick={selectAllFloorplans}>
                  <IndeterminateCheckBoxOutlinedIcon />
                </IconButton>
              </StyledTooltip>
              <Typography align='left' variant='h4'>
                {selectedFloorplanIds.size} selected
              </Typography>
              <IconButton onClick={openDeleter}>
                <DeleteIcon />
              </IconButton>
            </Stack>
          ) : (
            <Typography align='left' variant='subtitle2'>
              {t('interface:home.your_floorplans', 'My floorplans')}
            </Typography>
          )}
          <TextInput
            placeholder={t('interface:home.search_floorplans', 'Search floorplans')}
            value={search}
            onChange={setSearch}
            inputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon sx={{ mb: 2 }} />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Stack direction='row' justifyContent='space-between' alignItems='center' mb={4}>
          <Box>
            {floorplanView === 'grid' ? (
              <SortingMenu />
            ) : (
              <Stack>
                <Button onClick={openCreator} variant='outlined' sx={{ py: 1 }}>
                  <Stack direction='row' gap={1} alignItems='center'>
                    {t('interface:home.new_floorplan', 'New Floorplan')}
                    <PlusIcon sx={{ width: 25, height: 25 }} />
                  </Stack>
                </Button>
              </Stack>
            )}
          </Box>
          <Stack direction='row' gap={1}>
            <IconButton
              onClick={switchToGridView}
              className={floorplanView === 'grid' ? 'active' : ''}
            >
              <GridViewIcon />
            </IconButton>
            <IconButton
              onClick={switchToListView}
              className={floorplanView === 'grid' ? '' : 'active'}
            >
              <ListIcon />
            </IconButton>
          </Stack>
        </Stack>

        {floorplanView === 'grid' ? (
          <FloorplanGrid floorPlanGroupIds={floorPlanGroupsHitsIds} />
        ) : (
          <FloorplanList floorPlanGroupIds={floorPlanGroupsHitsIds} />
        )}
      </Container>
    </Box>
  );
};
