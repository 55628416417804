import { Stack, SxProps, Typography } from '@mui/material';

type Props = {
  header: string;
  subHeader?: string;
  sx?: SxProps;
};

export function InfoText({ header, subHeader, sx }: Props) {
  return (
    <Stack alignItems='center' gap={2} sx={sx}>
      <Typography fontSize={14} fontWeight='600'>
        {header}
      </Typography>
      {subHeader && (
        <Typography fontSize={14} textAlign='center' color='neutral.dark'>
          {subHeader}
        </Typography>
      )}
    </Stack>
  );
}
