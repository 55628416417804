import { DeleteIcon, DuplicateIcon, DuplicatePlusIcon } from '@/assets/icons';
import { PropertiesTitle } from '@common/components/PropertiesTitle';
import { Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Trans } from 'react-i18next';
import { StyledTooltip } from './StyledTooltip';
import { OnChangeHandler } from './inputs';
import { theme } from './theme';

type Props = {
  name: string;
  onDuplicateClick?: () => void;
  onDuplicateMultiClick?: () => void;
  onDeleteClick: () => void;

  disabled?: boolean;
  editable?: boolean;
  onBlur?: () => void;
  onNameChange?: OnChangeHandler<string>;
  onFocus?: () => void;
};

const iconBtnStyle = {
  border: 0,
  p: '2px',
  '&:hover': {
    color: theme.palette.neutral.darker,
  },
  '&:active:hover': {
    backgroundColor: theme.palette.shades.light,
    color: theme.palette.neutral.darker,
    borderColor: 'transparent',
  },
}

export function PropertiesHeader({
  name,
  disabled,
  editable,
  onBlur,
  onDuplicateClick,
  onDuplicateMultiClick,
  onDeleteClick,
  onFocus,
  onNameChange,
}: Props) {
  return (
    <Stack alignItems='center' direction='row' justifyContent='flex-start' gap={1} sx={{ pb: 1, pt: 2, px: 2 }}>
      <PropertiesTitle
        value={name}
        editable={!disabled && editable}
        onBlur={onBlur}
        onChange={onNameChange}
        onFocus={onFocus}
        multiline
        maxRowsPassive={2}
        maxRowsActive={2}
        sx={{ flex: '1 1 auto' }}
      />

      {onDuplicateMultiClick && (
        <StyledTooltip
          placement='bottom'
          disabled={disabled}
          title={
            <Trans
              i18nKey='duplicate_tooltip'
              ns='common'
              components={{
                s: <span />,
              }}
            >
              Grid copy
            </Trans>
          }
        >
          <IconButton
            sx={{ iconBtnStyle, ml: 1 }}
            disabled={disabled}
            onClick={onDuplicateMultiClick}
            className='btn-icon'
          >
            <DuplicatePlusIcon />
          </IconButton>
        </StyledTooltip>
      )}
      {onDuplicateClick && (
        <StyledTooltip
          placement='bottom'
          disabled={disabled}
          title={
            <Trans
              i18nKey='duplicate_tooltip'
              ns='common'
              components={{
                s: <span />,
              }}
            >
              Duplicate
            </Trans>
          }
        >
          <IconButton
            onClick={onDuplicateClick}
            className='btn-icon'
            disabled={disabled}
            sx={{ iconBtnStyle }}
          >
            <DuplicateIcon />
          </IconButton>
        </StyledTooltip>
      )}
      <StyledTooltip
        placement='bottom'
        disabled={disabled}
        title={
          <Trans
            i18nKey='delete_tooltip'
            ns='common'
            components={{
              s: <span />,
            }}
          >
            Delete <span>Backspace</span>
          </Trans>
        }
      >
        <IconButton
          onClick={onDeleteClick}
          className='btn-icon'
          disabled={disabled}
          sx={{ iconBtnStyle }}
          disableRipple
        >
          <DeleteIcon />
        </IconButton>
      </StyledTooltip>
    </Stack>
  );
}
