import { Size } from '@modules/common/types/general';
import { referenceDimensions } from '@modules/referenceImage';
import { sizeSelector } from '@recoil/workspace';
import { useRecoilCallback } from 'recoil';

export const useWorkspaceResize = () => {
  const scaleWorkspaceSize = useRecoilCallback(
    ({ snapshot, set }) =>
      async (dimensions: Size) => {
        const workspaceSize = await snapshot.getPromise(sizeSelector);
        const { x, y } = await snapshot.getPromise(referenceDimensions);

        if (workspaceSize.width < x + dimensions.width) {
          set(sizeSelector, (state) => ({
            ...state,
            width: x + dimensions.width,
          }));
        }

        if (workspaceSize.height < y + dimensions.height) {
          set(sizeSelector, (state) => ({
            ...state,
            height: y + dimensions.height,
          }));
        }
      },
    [],
  );

  return {
    scaleWorkspaceSize,
  };
};
