/* eslint-disable import/no-named-as-default */
import { selectorFamily } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../common';
import { unitSelector } from './unitSelector';
import { Unit } from '@modules/common/types/general';

export const unitConverterSelector = selectorFamily({
  key: 'unitConverterSelector',
  get:
    (value: number) =>
    ({ get }) => {
      const unit = get(unitSelector);
      if (unit === Unit.CENTIMETERS) {
        return value * 10;
      }
      if (unit === Unit.METERS) {
        return value * 1000;
      }

      return value;
    },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
