import { AreaAlignBotIcon, AreaAlignMidIcon, AreaAlignTopIcon } from '@/assets/icons';
import { IconButton, Stack } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlign } from '../hooks/useAlign';
import { AlignMode } from '../types/align';
import { toolbarTheme } from '@/modules/common/components/theme';
import { useRecoilValue } from 'recoil';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { StyledTooltip } from '@/modules/common/components/StyledTooltip';

const buttonStyle = {
  height: '32px',
  width: '32px',
};

const AlignPanelComponent = () => {
  const { t } = useTranslation('interface');
  const { align } = useAlign();
  const mode = useRecoilValue(modeSelector);
  const disabled = mode !== WorkspaceMode.EDITABLE;

  return (
    <ThemeProvider theme={toolbarTheme}>
      <Stack
        direction='row'
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <StyledTooltip
          offset={0}
          title={t('alignment.align_left')}
          placement='bottom'
          disabled={disabled}
        >
          <IconButton
            sx={buttonStyle}
            disabled={disabled}
            className='align-left-icon-btn'
            onClick={() => align(AlignMode.LEFT)}
          >
            <AreaAlignTopIcon sx={{ transform: 'rotate(-90deg)' }} />
          </IconButton>
        </StyledTooltip>
        <StyledTooltip
          offset={0}
          title={t('alignment.align_center')}
          placement='bottom'
          disabled={disabled}
        >
          <IconButton
            sx={buttonStyle}
            disabled={disabled}
            className='align-center-icon-btn'
            onClick={() => align(AlignMode.CENTER)}
          >
            <AreaAlignMidIcon sx={{ p: 0, transform: 'rotate(-90deg)' }} />
          </IconButton>
        </StyledTooltip>
        <StyledTooltip
          offset={0}
          title={t('alignment.align_right')}
          placement='bottom'
          disabled={disabled}
        >
          <IconButton
            sx={buttonStyle}
            disabled={disabled}
            className='align-right-icon-btn'
            onClick={() => align(AlignMode.RIGHT)}
          >
            <AreaAlignBotIcon sx={{ p: 0, transform: 'rotate(-90deg)' }} />
          </IconButton>
        </StyledTooltip>
        <StyledTooltip
          offset={0}
          title={t('alignment.align_top')}
          placement='bottom'
          disabled={disabled}
        >
          <IconButton
            sx={buttonStyle}
            disabled={disabled}
            className='align-top-icon-btn'
            onClick={() => align(AlignMode.TOP)}
          >
            <AreaAlignTopIcon />
          </IconButton>
        </StyledTooltip>
        <StyledTooltip
          offset={0}
          title={t('alignment.align_middle')}
          placement='bottom'
          disabled={disabled}
        >
          <IconButton
            sx={buttonStyle}
            disabled={disabled}
            className='align-middle-icon-btn'
            onClick={() => align(AlignMode.MIDDLE)}
          >
            <AreaAlignMidIcon />
          </IconButton>
        </StyledTooltip>
        <StyledTooltip
          offset={0}
          title={t('alignment.align_bottom')}
          placement='bottom'
          disabled={disabled}
        >
          <IconButton
            sx={buttonStyle}
            disabled={disabled}
            className='align-bottom-icon-btn'
            onClick={() => align(AlignMode.BOTTOM)}
          >
            <AreaAlignBotIcon />
          </IconButton>
        </StyledTooltip>
      </Stack>
    </ThemeProvider>
  );
};

export const AlignPanel = memo(AlignPanelComponent);
