import { selector } from 'recoil';
import { selectedShapesState } from '@recoil/shapes/selected';
import { supportsLoadCarriers } from '@modules/common/helpers/shapes';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

const selectionContainsLoadCarrierSupport = selector({
  key: 'selectionContainsLoadCarrierSupport',
  get: ({ get }) => get(selectedShapesState).some((shape) => supportsLoadCarriers(shape.type)),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default selectionContainsLoadCarrierSupport;
