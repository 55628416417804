import * as React from 'react';

function Lock({ className = '', ...rest }) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      style={{ fill: 'currentColor' }}
      className={className}
      {...rest}
    >
      <path d='M6.625 21C6.175 21 5.79167 20.8417 5.475 20.525C5.15833 20.2083 5 19.825 5 19.375V10.625C5 10.175 5.15833 9.79167 5.475 9.475C5.79167 9.15833 6.175 9 6.625 9H8V7C8 5.88333 8.38767 4.93733 9.163 4.162C9.93767 3.38733 10.8833 3 12 3C13.1167 3 14.0627 3.38733 14.838 4.162C15.6127 4.93733 16 5.88333 16 7V9H17.375C17.825 9 18.2083 9.15833 18.525 9.475C18.8417 9.79167 19 10.175 19 10.625V19.375C19 19.825 18.8417 20.2083 18.525 20.525C18.2083 20.8417 17.825 21 17.375 21H6.625ZM6.625 20H17.375C17.5583 20 17.7083 19.9417 17.825 19.825C17.9417 19.7083 18 19.5583 18 19.375V10.625C18 10.4417 17.9417 10.2917 17.825 10.175C17.7083 10.0583 17.5583 10 17.375 10H6.625C6.44167 10 6.29167 10.0583 6.175 10.175C6.05833 10.2917 6 10.4417 6 10.625V19.375C6 19.5583 6.05833 19.7083 6.175 19.825C6.29167 19.9417 6.44167 20 6.625 20ZM12 16.5C12.4167 16.5 12.7707 16.354 13.062 16.062C13.354 15.7707 13.5 15.4167 13.5 15C13.5 14.5833 13.354 14.2293 13.062 13.938C12.7707 13.646 12.4167 13.5 12 13.5C11.5833 13.5 11.2293 13.646 10.938 13.938C10.646 14.2293 10.5 14.5833 10.5 15C10.5 15.4167 10.646 15.7707 10.938 16.062C11.2293 16.354 11.5833 16.5 12 16.5ZM9 9H15V7C15 6.16667 14.7083 5.45833 14.125 4.875C13.5417 4.29167 12.8333 4 12 4C11.1667 4 10.4583 4.29167 9.875 4.875C9.29167 5.45833 9 6.16667 9 7V9Z' />
    </svg>
  );
}

const LockIcon = React.memo(Lock);
export default LockIcon;
