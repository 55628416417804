import { ClickAwayListener, Stack, Tooltip as MuiTooltip, Typography } from '@mui/material';
import { TooltipProps } from '@mui/material/Tooltip/Tooltip';
import { Box } from '@mui/system';
import { ReactElement, useCallback, useRef } from 'react';

import { getSx, getIcon } from './helpers';
import { Type } from './types';

export type Props = {
  children: ReactElement;
  title: string;
  type: Type;

  onClickAway?: (event: MouseEvent | TouchEvent) => void;
  width?: number;
} & TooltipProps;

export function InfoTooltip({
  children,
  title,
  type,
  width = 400,
  onClickAway = () => null,
  ...rest
}: Props) {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const onInnerClickAway = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (
        event.target !== tooltipRef.current &&
        !tooltipRef.current?.contains(event.target as HTMLElement)
      ) {
        onClickAway?.(event);
      }
    },
    [onClickAway],
  );

  const onClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  return (
    <ClickAwayListener onClickAway={onInnerClickAway}>
      <MuiTooltip
        title={
          <Stack alignItems='center' direction='row' padding={2} gap={2} onClick={onClick}>
            <Box flex='0 0 auto'>{getIcon(type)}</Box>
            <Typography fontSize={10}>{title}</Typography>
          </Stack>
        }
        arrow
        componentsProps={{
          popper: { sx: getSx(type, width) },
          tooltip: {
            ref: tooltipRef,
          },
        }}
        {...rest}
      >
        {children}
      </MuiTooltip>
    </ClickAwayListener>
  );
}
