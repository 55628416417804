import { FloorPlanGroup } from '@/modules/floorplanAdmin/helpers/types';
import {
  SortingCriteria,
  Order,
  sortingCriteriaToModelFieldMap,
} from '@/modules/floorplanAdmin/sortingMenu/constants';
import {
  fieldSortFunction,
  dateStringComparator,
  stringComparator,
} from '@/modules/floorplanAdmin/sortingMenu/helpers';

export const getSortedFloorPlanGroups = (
  floorPlanGroups: FloorPlanGroup[],
  criteria: SortingCriteria,
  order: Order,
): FloorPlanGroup[] => {
  const field = sortingCriteriaToModelFieldMap[criteria];
  let sorter: any;
  if (field === 'created' || field === 'lastUpdated')
    sorter = fieldSortFunction(field, order, dateStringComparator);
  else sorter = fieldSortFunction(field, order, stringComparator);

  return [...floorPlanGroups].sort(sorter);
};
