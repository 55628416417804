import { Vector2 } from 'three';
import { DEG2RAD } from 'three/src/math/MathUtils';

import { groupByShape } from '@/modules/artefacts/helpers/group';
import { calculateOrientedBoundingBox } from '@/modules/common/helpers/boundingBox';
import { getVectorRotatedAroundPoint } from '@/modules/common/helpers/math';
import { DTShape, ShapeProperties } from '@/modules/common/types/shapes';
import { GeneratedFloorPlanArtefacts } from '@/modules/floorplanService';
import { BoundingBox, Position } from '@helpers/types';
import { calculateShapesBoundingBox } from '@modules/common/helpers/shapes';
import { isAreaShape, isPointsShape } from '@modules/common/types/guards';

/**
 * Converts artefacts from FPS coordinate system (bottom left) to Sales canvas coordinate system (top left)
 */
export const convertToSalesCanvasCoordinateSystem = (
  shapes: DTShape[],
  artefacts: GeneratedFloorPlanArtefacts,
) => {
  const convertedShapes = convertToBottomLeftCoordinateSystem(shapes);
  const gateDict = groupByShape(convertedShapes, artefacts);

  convertedShapes.forEach((shape) => {
    if (isAreaShape(shape) && shape.properties.r !== null) {
      const boundingBox = calculateOrientedBoundingBox([shape.properties]); // calculateOrientedBoundingBoxExtent(shape.properties);

      let offsetX = -boundingBox.x + boundingBox.width / 2;
      let offsetY = -boundingBox.y + boundingBox.height / 2;
      const deg = shape.properties.r;
      const angle = deg * DEG2RAD;

      if (deg < 0 && deg > -90) {
        offsetX += -Math.cos(Math.PI / 2 + angle) * shape.properties.height;
      } else if (deg > 0 && deg < 90) {
        offsetY += Math.cos(Math.PI / 2 + angle) * shape.properties.width;
      } else if ((deg <= -90 && deg >= -180) || deg === 180) {
        offsetX +=
          Math.cos(Math.PI / 2 + angle) * -shape.properties.height +
          Math.cos(Math.PI + angle) * -shape.properties.width;
        offsetY += Math.cos(angle) * shape.properties.height;
      } else if (deg >= 90 && deg <= 180) {
        offsetX += Math.cos(Math.PI - angle) * -shape.properties.width;
        offsetY +=
          Math.cos(Math.PI / 2 - angle) * -shape.properties.width +
          Math.cos(Math.PI - angle) * -shape.properties.height;
      }

      const gate = gateDict.get(shape.id);

      if (!gate) {
        return;
      }

      gate.locations.forEach((item) => {
        if (item.rectangle) {
          item.rectangle.centerX += offsetX;
          item.rectangle.centerY += offsetY;
        }
      });

      gate.loadPositions.forEach((item) => {
        item.rectangle.centerX += offsetX;
        item.rectangle.centerY += offsetY;
      });
    }
  });

  return artefacts;
};

export const convertToBottomLeftCoordinateSystem = (shapes: readonly DTShape[]) => {
  const bb = calculateShapesBoundingBox(shapes);
  return shapes.map((item) => toBottomLeftOrigin(item, bb));
};

const toBottomLeftOrigin = (shape: DTShape, workspaceBoundingBox: BoundingBox): DTShape => {
  if (isPointsShape(shape)) {
    return {
      ...shape,
      properties: {
        ...shape.properties,
        controlPoints: shape.properties.controlPoints.map((item) => {
          const pos = convertPointToBottomLeftPosition(item.position, workspaceBoundingBox);

          return {
            ...item,
            position: new Vector2(pos.x, pos.y),
          };
        }),
      },
    };
  }

  const pos = convertPropertiesToBottomLeftPosition(shape.properties, workspaceBoundingBox);

  return {
    ...shape,
    properties: {
      ...shape.properties,
      ...pos,
    },
  };
};

export const convertPointToBottomLeftPosition = (
  point: Position,
  workspaceBoundingBox: BoundingBox,
): Position => {
  const offsetFromLeftBottomBB = workspaceBoundingBox.y + workspaceBoundingBox.height - point.y;
  const newY = workspaceBoundingBox.y + offsetFromLeftBottomBB;

  return {
    x: point.x - workspaceBoundingBox.x,
    y: newY - workspaceBoundingBox.y,
  };
};

const convertPropertiesToBottomLeftPosition = (
  properties: ShapeProperties,
  workspaceBoundingBox: BoundingBox,
): Position => {
  let { x, y, height, width, r } = properties;

  const rotatedOrigin = new Vector2(x, y);
  const orientedBBCenter = getVectorRotatedAroundPoint(
    new Vector2(x + width / 2, y + height / 2),
    rotatedOrigin,
    -r,
  );
  const unrotatedOrigin = getVectorRotatedAroundPoint(rotatedOrigin, orientedBBCenter, r);
  x = unrotatedOrigin.x;
  y = unrotatedOrigin.y;

  y -= workspaceBoundingBox.y * 2;
  y = Math.floor(workspaceBoundingBox.height - y - height);

  x += width / 2;
  y += height / 2;

  x -= workspaceBoundingBox.x;
  y -= workspaceBoundingBox.y;

  return {
    x,
    y,
  };
};
