import { atom } from 'recoil';

export type Group = {
  id: string;
  name: string;
  latestFloorPlanId: string;
};

export const groupAtom = atom<Group>({
  key: 'group/atom',
  default: {
    id: null,
    name: null,
    latestFloorPlanId: null,
  },
});
