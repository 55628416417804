import { useRecoilCallback } from 'recoil';
import { shapesSelector } from '@recoil/shapes';
import { DistantConnection } from '@modules/common/types/connections';
import { v4 as uuid } from 'uuid';
import { filterRelatedConnections } from '@modules/connections/common/helpers';

import { CopyIdMapping } from '../../common/types';
import { allDistconIds, allDistantConnectionsSelector, distcon } from '../store';
import { updateConnectionPositioning } from '../helpers/connections';
import { combineConnectionId, getSegmentId, getShapeId } from '../../common/connectionId';

export const useCopyDistantConnection = () => {
  const copyDistantConnection = useRecoilCallback(
    ({ set, snapshot }) =>
      async (idMapping: CopyIdMapping) => {
        const oldShapeIds = idMapping.map((item) => item.oldShapeId);
        const newShapeIds = idMapping.map((item) => item.newShapeId);
        const [allConnections, newShapes] = await Promise.all([
          snapshot.getPromise(allDistantConnectionsSelector),
          snapshot.getPromise(shapesSelector(newShapeIds)),
        ]);

        const newConnections: DistantConnection[] = filterRelatedConnections(
          allConnections,
          oldShapeIds,
        )
          .map((connection) => {
            const fromMapping = idMapping.find(
              (item) => item.oldShapeId === getShapeId(connection.from),
            );
            const toMapping = idMapping.find(
              (item) => item.oldShapeId === getShapeId(connection.to),
            );

            if (!fromMapping || !toMapping) {
              return null;
            }

            const fromShape = newShapes.find((item) => item.id === fromMapping.newShapeId);
            const toShape = newShapes.find((item) => item.id === toMapping.newShapeId);

            return {
              ...updateConnectionPositioning(connection, fromShape, toShape),
              from: combineConnectionId(fromShape.id, getSegmentId(connection.from)),
              to: combineConnectionId(toShape.id, getSegmentId(connection.to)),
              id: uuid(),
            };
          })
          .filter((item) => item);

        set(allDistconIds, (state) => [...state, ...newConnections.map((item) => item.id)]);
        newConnections.forEach((item) => set(distcon(item.id), item));
      },
    [],
  );

  return {
    copyDistantConnection,
  };
};
