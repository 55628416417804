import { atom, selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import selectedShapesRotationState from './selectedShapesRotationState';

const selectedShapesIdsStateAtom = atom<string[]>({
  key: 'selectedShapes/ids/atom',
  default: [],
});

const selectedShapesIdsState = selector<string[]>({
  key: 'selectedShapes/ids',
  get: ({ get }) => get(selectedShapesIdsStateAtom),
  set: ({ set }, value) => {
    set(selectedShapesRotationState, 0);
    set(selectedShapesIdsStateAtom, value);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default selectedShapesIdsState;
