import { Stack, Typography } from '@mui/material';

type Props = {
  sourceName: string;
  targetName: string;
};

export function FlowDirection({ sourceName, targetName }: Props) {
  return (
    <Stack
      direction='row'
      alignItems='center'
      gap={1}
      color='neutral.main'
      width='100%'
      height={24}
      marginTop={1}
    >
      <Typography fontSize={10}>
        {sourceName}&nbsp;&mdash;&nbsp;{targetName ?? '?'}
      </Typography>
    </Stack>
  );
}
