import { FullDialog } from '@common/components/FullDialog';
import { Button, Grid, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type Props = {
  open: boolean;
  innerSelectedIds: string[];
  title: string;
  onSubmit: (id: string[]) => void;
  onClose: () => void;
  children: React.ReactNode;
};

export const SetupPropertiesSelectDialog = ({
  open,
  innerSelectedIds,
  title,
  onClose,
  onSubmit,
  children,
}: Props) => {
  const { t } = useTranslation(['common']);

  return (
    <FullDialog
      open={open}
      onClose={onClose}
      title={title}
      doneButton={
        <Button
          variant='secondary'
          onClick={() => onSubmit?.(innerSelectedIds)}
          disabled={innerSelectedIds && innerSelectedIds.length < 1}
          aria-label={t('common:done')}
          sx={{ height: '40px', border: 0 }}
        >
          {t('common:done')}
        </Button>
      }
    >
      {children}
    </FullDialog>
  );
};
