import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';

import { AreaLoadCarrierOrientation } from '@modules/common/types/shapes';
import { useUpdateUserPreferences } from '@modules/userPreferences/hooks';
import { selectedShapesState } from '@recoil/shapes/selected';
import { useArtefacts } from '@/modules/artefacts';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { useFloorPlanState } from '../../../../modules/floorplan/hooks/useFloorPlanState';
import { processLoadCarrierSide } from '../../store/process';

export type Props = {
  type: 'deliveryParameters' | 'intakeParameters';
};

export const ProcessLoadCarrierSide = ({ type }: Props) => {
  const { t } = useTranslation('interface');
  const { saveFloorPlan } = useFloorPlanState();
  const { updateUserPreference } = useUpdateUserPreferences();
  const mode = useRecoilValue(modeSelector);
  const { updateDebounced: updateArtefacts } = useArtefacts();
  const [loadCarrierSide, setLoadCarrierSide] = useRecoilState(
    processLoadCarrierSide(type),
  );

  const onLoadCarrierSideChange = useRecoilCallback(
    ({ snapshot }) =>
      async (_, value: AreaLoadCarrierOrientation) => {
        if (value !== null) {
          setLoadCarrierSide(value);
          saveFloorPlan();

          const selectedShapes = await snapshot.getPromise(selectedShapesState);

          updateArtefacts(selectedShapes.map((item) => item.id));
        }
      },
    [updateUserPreference, saveFloorPlan],
  );

  return (
    <ToggleButtonGroup
      value={loadCarrierSide}
      exclusive
      onChange={onLoadCarrierSideChange}
      disabled={mode !== WorkspaceMode.EDITABLE}
    >
      <ToggleButton
        value={AreaLoadCarrierOrientation.SHORT_SIDE}
        aria-label='Orientate load carrier short side'
        sx={{ p: 0, fontSize: '13px' }}
      >
        {t('properties.area.load_carrier_orientation.short')}
      </ToggleButton>
      <ToggleButton
        value={AreaLoadCarrierOrientation.LONG_SIDE}
        aria-label='Orientate load carrier long side'
        sx={{ p: 0, fontSize: '13px' }}
      >
        {t('properties.area.load_carrier_orientation.long')}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
