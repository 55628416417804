import { selector } from 'recoil';
import { Size } from '@modules/common/types/general';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import dwgAtom from './atom';

const referenceOriginalSize = selector<Size>({
  key: 'reference/OriginalSize',
  get: ({ get }) => {
    const currentValue = get(dwgAtom);

    return {
      width: currentValue.original.width,
      height: currentValue.original.height,
    };
  },
  set: ({ get, set }, newValue) => {
    set(dwgAtom, { ...get(dwgAtom), original: newValue });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default referenceOriginalSize;
