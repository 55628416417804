import { Stack } from '@mui/material';
import { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { SimulationStatus } from '../../../helpers/types';
import { useSimulationCallbacks, useSimulationGroupCallbacks } from '../../../hooks';
import { currentGroupSelector } from '../../../store/group';
import { BottomButtons } from '../../BottomButtons';
import { CloseButton } from '../../CloseButton';
import { CompletedDuration } from '../../CompletedDuration';
import { CreatedCompletedDatesRow } from '../../CreatedCompletedDatesRow';
import { HeaderTitle } from '../../HeaderTitle';
import { Status } from '../../Status';
import { VersionVehicleRow } from '../../VersionVehicleRow';
import { HeaderMenu } from './HeaderMenu';

export function Header() {
  const { abort } = useSimulationGroupCallbacks();
  const { closeSimulationGroupPanel, rename } = useSimulationCallbacks();
  const onCloseClick = useCallback(() => closeSimulationGroupPanel(), [closeSimulationGroupPanel]);
  const simulation = useRecoilValue(currentGroupSelector);
  const [isHover, setIsHover] = useState<boolean>();

  const onAbortConfirm = useCallback(async () => {
    await abort();
  }, [abort]);

  const onChange = useCallback((name: string) => rename(simulation, name), [simulation, rename]);

  return (
    <Stack
      onMouseEnter={() => setIsHover(true)}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      sx={{ pb: 2, px: 2 }}
      gap={1}
    >
      <Stack direction='row' alignItems='center' justifyContent='space-between' gap={1}>
        <Status simulation={simulation} sx={{ marginRight: 'auto' }} />
        {isHover && <HeaderMenu simulation={simulation} />}
        <CloseButton onCloseClick={onCloseClick} />
      </Stack>
      <Stack sx={{ gap: 1 }}>
        <HeaderTitle editable={simulation.status !== SimulationStatus.DELETING} onChange={onChange}>
          {simulation.name}
        </HeaderTitle>
        <Stack sx={{ flexDirection: 'row', gap: 1, width: '100%' }}>
          <Stack sx={{ gap: 1, width: '100%' }}>
            <VersionVehicleRow simulation={simulation} />
            <CreatedCompletedDatesRow simulation={simulation} />
            <CompletedDuration simulation={simulation} />
          </Stack>
          {isHover && <BottomButtons simulation={simulation} onAbortConfirm={onAbortConfirm} />}
        </Stack>
      </Stack>
    </Stack>
  );
}
