import { useConfig } from '@modules/common/hooks';
import { useMemo } from 'react';
import { useAxios } from '@modules/api/hooks/useAxios';

export const useBaseProjectApi = () => {
  const { api } = useConfig();
  const scopes = useMemo(() => [api.project.scope], [api.project.scope]);
  return useAxios({
    baseURL: api.project.url,
    scopes,
  });
};
