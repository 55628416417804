import { useSetRecoilState } from 'recoil';

import { useVehicleService } from './useVehicleService';
import { allVehiclesState } from '../store';

export const useLoadVehicleTypes = () => {
  const { getAllUnifiedVehicleTypes } = useVehicleService();
  const setAllVehicles = useSetRecoilState(allVehiclesState);

  const fetchInitialVehicleData = async () => {
    const allVehicles = await getAllUnifiedVehicleTypes();
    setAllVehicles(allVehicles);
  };

  return { fetchInitialVehicleData };
};
