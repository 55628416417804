import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { selector } from 'recoil';

import { simulationAtom } from './simulationAtom';
import { ParkingAndChargingLocation } from '@/modules/simulation/helpers/types';

export const chargingParkingPrioritySelector = selector<ParkingAndChargingLocation[]>({
  key: 'simulations/draft/chargingParkingPriority',
  get: ({ get }) => get(simulationAtom)?.chargingParkingPriority ?? [],
  set: ({ set }, priorities: ParkingAndChargingLocation[]) => {
    set(simulationAtom, (state) => ({
      ...state,
      chargingParkingPriority: priorities,
    }));
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
