import { SvgIcon } from '@mui/material';
import { memo } from 'react';

const ArrowIcon = ({ className = '', size = 24, color = 'currentColor', ...rest }) => (
  <SvgIcon
    viewBox='0 0 24 24'
    sx={{ width: size, height: size, fontSize: `${size}px`, fill: color }}
    className={className}
    {...rest}
  >
    <path
      d='M11.4999 17.5V8.63272L7.3999 12.7077L6.71143 12L11.9999 6.71155L17.2884 12L16.5999 12.7077L12.4999 8.63272V17.5H11.4999Z'
      fill={color}
    />
  </SvgIcon>
);

export default memo(ArrowIcon);
