import { SvgIcon } from '@mui/material';

function UndoIcon({ className = '', ...rest }) {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      style={{ fill: 'currentColor', stroke: 'none' }}
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      {...rest}
    >
      <path
        d='M19.4 18.5V15C19.4 14.0333 19.0627 13.2083 18.388 12.525C17.7127 11.8416 16.8833 11.5 15.9 11.5H5.5L9.6 15.6L8.9 16.3L3.6 11L8.9 5.69995L9.6 6.39995L5.5 10.5H15.9C17.15 10.5 18.2127 10.9373 19.088 11.812C19.9627 12.6873 20.4 13.75 20.4 15V18.5H19.4Z'
      />
    </SvgIcon>
  );
}

export default UndoIcon;
