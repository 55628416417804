import { SvgIcon } from '@mui/material';
import { memo } from 'react';

const NotificationsIcon = ({ className = '', size = 24, color = 'currentColor', ...rest }) => (
  <SvgIcon
    viewBox='0 0 24 24'
    sx={{ width: size, height: size, fontSize: `${size}px`, fill: color }}
    className={className}
    {...rest}
  >
    <path
      d='M4.5 18.7308V17.7308H6.5V10.2308C6.5 8.89872 6.92308 7.72725 7.76923 6.71635C8.61538 5.70545 9.6923 5.07179 11 4.81537V4.11537C11 3.8269 11.0946 3.58812 11.2837 3.39902C11.4728 3.20992 11.7115 3.11537 12 3.11537C12.2885 3.11537 12.5272 3.20992 12.7163 3.39902C12.9054 3.58812 13 3.8269 13 4.11537V4.81537C14.3077 5.07179 15.3846 5.70545 16.2308 6.71635C17.0769 7.72725 17.5 8.89872 17.5 10.2308V17.7308H19.5V18.7308H4.5ZM12 21.6154C11.5654 21.6154 11.1875 21.4612 10.8663 21.1529C10.5452 20.8445 10.3846 20.4602 10.3846 20H13.6154C13.6154 20.4602 13.4612 20.8445 13.1529 21.1529C12.8445 21.4612 12.4603 21.6154 12 21.6154ZM7.5 17.7308H16.5V10.2308C16.5 8.97692 16.0635 7.91345 15.1904 7.04037C14.3173 6.1673 13.2539 5.73077 12 5.73077C10.7462 5.73077 9.68269 6.1673 8.80963 7.04037C7.93654 7.91345 7.5 8.97692 7.5 10.2308V17.7308Z'
      fill={color}
    />
  </SvgIcon>
);

export default memo(NotificationsIcon);
