import { BoundingBox } from '@/helpers/types';
import { AngledHighwayShape } from '@/modules/angledHighways/types';
import { ControlPoint, ShapeType } from '@/modules/common/types/shapes';
import { ProcessTwoEPShape } from '@/modules/processTwoEndPoint';
import { getShapeColor, hexToRgb } from '@/modules/workspace/helpers/colors';
import { HIGHWAY_COLOR, SOURCE_NAME } from '../consts';
import { Type, Variant } from '../enum';
import { ArgbColor, Vector3, VisualizationObject } from '../types';

const HIGHWAY_HEIGHT = 0.0;

export function createPolylineObject(
  shape: AngledHighwayShape | ProcessTwoEPShape,
  workspaceBox: BoundingBox,
): VisualizationObject {
  let color: ArgbColor = null;
  let z = 0;
  let data: any = null;
  let tags: string[] = [];
  
  switch (shape.type) {
    case ShapeType.HIGHWAY_ANGLED: {
      color = HIGHWAY_COLOR;
      z = HIGHWAY_HEIGHT;
      tags = [ShapeType.HIGHWAY]
      break;
    }
    case ShapeType.PROCESS_TWO_EP: {
      const hexColor = getShapeColor(shape.type, false);
      const stdColor = hexToRgb(hexColor);
      color = { Alpha: stdColor.a, R: stdColor.r, G: stdColor.g, B: stdColor.b };
      tags = [shape.type]
      break;
    }
    default:
      break;
  }

  const controlPoints: Vector3[] = shape.properties.controlPoints.map((cp: ControlPoint) => {
    const pos = cp.position;
    return {
      X: pos.x - workspaceBox.x,
      Y: workspaceBox.height - (pos.y - workspaceBox.y),
      Z: z,
    };
  });

  const vo: VisualizationObject = {
    Name: shape.name,
    Type: Type.Path,
    Variant: Variant.Polyline,
    Position: { X: 0, Y: 0, Z: 0 },
    Scale: { X: 1, Y: 1, Z: 1 },
    Rotation: { X: 0, Y: 0, Z: 0 },
    Source: SOURCE_NAME,
    Color: color,
    Tags: tags,
    Data: {
      ...data,
      ControlPoints: controlPoints,
      Width: shape.parameters.width / 1000 / 2, // divide by 2 is a bug in FT
    },  
  };

  return vo;
}
