import { SvgIcon } from '@mui/material';
import { memo } from 'react';

function SvgComponent(props) {
  return (
    <SvgIcon
      width='16'
      height='22'
      viewBox='0 0 16 22'
      fill='none'
      stroke="none"
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M0 21.1V0.925049H1V3.02505H15V0.925049H16V21.1H15V19H1V21.1H0ZM1 10H3.625V6.22505H8.375V10H15V4.02505H1V10ZM1 18H7.625V14.225H12.375V18H15V11H1V18ZM4.625 10H7.375V7.22505H4.625V10ZM8.625 18H11.375V15.225H8.625V18Z' />
    </SvgIcon>
  );
}

const Memo = memo(SvgComponent);
export default Memo;
