import { Box, Stack } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { areaVehicleCount } from '../../store/area';
import { ContentRow } from './ContentRow';

function ParkingContent() {
  const vehicleCount = useRecoilValue(areaVehicleCount);

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='stretch'
      sx={{ mt: '16px', height: '100%', width: '100%' }}
    >
      <Stack
        direction='column'
        alignItems='start'
        justifyContent='space-between'
        sx={{ width: '50%', paddingRight: '16.2px' }}
        spacing={1}
      >
        <ContentRow label='Total' value={vehicleCount} unit='' />
      </Stack>

      <Box sx={{ width: '50%' }} />
    </Stack>
  );
}

export default ParkingContent;
