import { selectorFamily } from 'recoil';

import { isShapeProperties } from '@modules/common/types/guards';
import { DTShape, ShapeProperties } from '@modules/common/types/shapes';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import shapeAtom from './atom';
import { CANVAS_TO_SHAPE_SCALE, SHAPE_TO_CANVAS_SCALE } from '@/modules/workspace/helpers/konva';

const shapePropertyCanvas = selectorFamily<ShapeProperties, string>({
  key: 'shape/property/canvas',
  get:
    (id) =>
    ({ get }) => {
      const shape = get(shapeAtom(id));
      if (!shape) return null;

      if (!isShapeProperties(shape.properties)) {
        console.error('unexpected type of properties handled shapePropertyCanvas selector');
        return null;
      }

      const { x, y, width, height, r } = shape.properties;
      return {
        x: x * SHAPE_TO_CANVAS_SCALE,
        y: y * SHAPE_TO_CANVAS_SCALE,
        width: width * SHAPE_TO_CANVAS_SCALE,
        height: height * SHAPE_TO_CANVAS_SCALE,
        r,
      };
    },
  set:
    (id) =>
    ({ get, set }, newValue: ShapeProperties) => {
      Object.keys(newValue).forEach((key) => {
        // do not scale rotation
        if (key === 'r') return;

        newValue[key] = Math.round(newValue[key] * CANVAS_TO_SHAPE_SCALE);
      }, []);

      const newShape = {
        ...get(shapeAtom(id)),
        properties: newValue,
      } as DTShape;

      set(shapeAtom(id), newShape);
    },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default shapePropertyCanvas;
