import { useRecoilValue } from "recoil";
import { memo } from "react";
import { existsSelector } from "../../simulation";
import { ExistingFlowsRenderer } from "../layout";
import { SimulationFlowsRenderer } from "../simulation";

const FlowsRendererComponent = () => {
  const simulationDraftExists = useRecoilValue(existsSelector);

  if (simulationDraftExists) {
    return <SimulationFlowsRenderer />;
  }

  return <ExistingFlowsRenderer />;
};

export const FlowsRenderer = memo(FlowsRendererComponent);
