import { selector } from 'recoil';
import { positionsAtom } from './atom';
import shapeAtom from '../../shape/atom';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../common';
import { setAtomFamilyWithIdsAtom } from '../../common/helper';
import { PositionShape } from '../../shape/types/position';

export const allPositionsState = selector<PositionShape[]>({
  key: 'allPositions',
  get: ({ get }) => {
    const output = [];
    get(positionsAtom).forEach((id) => {
      const area = get(shapeAtom(id));
      output.push(area);
    });
    return output;
  },
  set: (getSetResetRecoilState, newPositions: PositionShape[]) => {
    setAtomFamilyWithIdsAtom(getSetResetRecoilState, shapeAtom, positionsAtom, newPositions);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
