import { Position } from '@helpers/types';
import { usePrevious } from '@modules/common/hooks';
import { useEffect, useRef } from 'react';
import {
  offsetCamera,
  setCameraPositionFromScreenPosition,
  setCameraZoomFromScale,
} from '../../AutodeskViewer/helpers/camera';

export const useViewerWorkspaceSync = (
  viewer: Autodesk.Viewing.Viewer3D,
  x: number,
  y: number,
  offsetX: number,
  offsetY: number,
  scale: number,
  boundingBox: THREE.Box3,
  isLoaded: boolean,
) => {
  const prevScale = usePrevious(scale);
  const prevHeight = useRef<number>();
  const prevOffset = useRef<Position>(null);

  useEffect(() => {
    if (!isLoaded || !Number.isFinite(offsetX) || !Number.isFinite(offsetY)) {
      return;
    }

    if (!prevOffset.current) {
      prevOffset.current = {
        x: offsetX,
        y: offsetY,
      };
    }

    offsetCamera({ x: offsetX, y: offsetY }, prevOffset.current, viewer.navigation);

    prevOffset.current = {
      x: offsetX,
      y: offsetY,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, offsetX, offsetY]);

  useEffect(() => {
    if (!viewer || !isLoaded || !prevScale || scale === prevScale) {
      return;
    }

    setCameraZoomFromScale(scale / prevScale, viewer.navigation);
    (viewer.navigation as any).updateCamera();
    setCameraPositionFromScreenPosition(
      boundingBox,
      { x, y },
      {
        x: offsetX,
        y: offsetY,
      },
      viewer.navigation,
      scale,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [x, y, scale, offsetX, offsetY]);

  useEffect(() => {
    if (!viewer || !isLoaded || scale !== prevScale) {
      return;
    }

    setCameraPositionFromScreenPosition(
      boundingBox,
      { x, y },
      {
        x: offsetX,
        y: offsetY,
      },
      viewer.navigation,
      scale,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [x, y, offsetX, offsetY]);

  useEffect(() => {
    if (!viewer || !isLoaded) {
      return;
    }

    if (!prevHeight.current) {
      prevHeight.current = viewer.navigation.getScreenViewport().height;
    }

    const resizeCallback = () => {
      const { height } = viewer.navigation.getScreenViewport();
      setCameraZoomFromScale(prevHeight.current / height, viewer.navigation);
      (viewer.navigation as any).updateCamera();
      setCameraPositionFromScreenPosition(
        boundingBox,
        { x, y },
        {
          x: offsetX,
          y: offsetY,
        },
        viewer.navigation,
        scale,
      );
      prevHeight.current = height;
    };

    viewer.addEventListener(Autodesk.Viewing.VIEWER_RESIZE_EVENT, resizeCallback);

    return () => {
      viewer.removeEventListener(Autodesk.Viewing.VIEWER_RESIZE_EVENT, resizeCallback);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewer, x, y, isLoaded, offsetY, offsetX]);
};
