import { PropertyPanelContainer } from '@/modules/common/components/PropertyPanelContainer';
import { useNavigation } from '@modules/common/hooks';
import { Module } from '@modules/common/types/navigation';

import { TabsCommissioning } from './TabsCommissioning';

export function PropertyPanel() {
  const { goToModule } = useNavigation();

  return (
    <PropertyPanelContainer
      labelKey='interface:tabs.commissioning.label'
      onCloseClick={() => goToModule(Module.LAYOUT)}
      contentScroll={false}
    >
      <TabsCommissioning />
    </PropertyPanelContainer>
  );
}
