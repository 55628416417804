import { LoadingPanel } from '@common/components/LoadingPanel';
import { RetryInfoText } from '@common/components/RetryInfoText';
import { ValidationSummary } from '@modules/floorplanValidation/clientSide';
import { SimulationPanel } from '@modules/simulation/helpers/types';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useSimulationCallbacks } from '../hooks';
import { errorMessageSelector, isLoadingSelector, panelSelector } from '../store/module';
import { EditPanel } from './EditPanel';
import { GroupPanel } from './GroupPanel';
import { ListPanel } from './ListPanel';

const containerSx = {
  overflowY: 'scroll',
  position: 'relative',
  height: 'calc(100% - 80px)',
  scrollbarWidth: 'none',

  '&:hover': { cursor: 'default' },

  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

export function PropertyPanel() {
  const { t } = useTranslation();
  const panel = useRecoilValue(panelSelector);
  const isLoading = useRecoilValue(isLoadingSelector);
  const errorMessage = useRecoilValue(errorMessageSelector);
  const { clearState } = useSimulationCallbacks();

  const retry = useRecoilCallback(
    ({ set }) =>
      async () => {
        set(errorMessageSelector, null);
      },
    [],
  );

  useEffect(
    () => () => {
      clearState();
    },
    // eslint-disable-next-line
    [],
  );

  return (
    <>
      <ValidationSummary />
      {errorMessage && <RetryInfoText header={t(errorMessage)} onRetry={retry} />}
      {!errorMessage && (
        <Box sx={containerSx}>
          {isLoading && (
            <LoadingPanel
              sx={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, zIndex: 2 }}
            />
          )}
          {panel === SimulationPanel.LIST && <ListPanel />}
          {panel === SimulationPanel.EDIT && <EditPanel />}
          {panel === SimulationPanel.GROUP && <GroupPanel />}
        </Box>
      )}
    </>
  );
}
