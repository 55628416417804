import { useRecoilCallback } from 'recoil';
import {
  projectIdSelector,
  groupIdSelector,
  floorPlanIdSelector,
  useVersioning,
  useFloorPlanService,
} from '@/modules/floorplan';
import { useLoading } from '@modules/common/hooks';
import { LoadingType } from '@modules/common/types/general';
import { HistoryManager } from '@recoil/history';

import { isLoadingSelector } from '../store/module';
import { versionIdsSelector, versionSelector } from '../store/versions';

export const useVersioningCallbacks = () => {
  const { fetchAllVersions } = useFloorPlanService();
  const { loadVersion, loadLatestVersion } = useVersioning();
  const { showLoader, hideLoader } = useLoading();

  const loadAll = useRecoilCallback(
    ({ set, snapshot }) =>
      async (skipLoader: boolean) => {
        if (!skipLoader) {
          set(isLoadingSelector, true);
        }

        try {
          const projectId = await snapshot.getPromise(projectIdSelector);
          const groupId = await snapshot.getPromise(groupIdSelector);
          const floorPlanId = await snapshot.getPromise(floorPlanIdSelector);
          const versions = await fetchAllVersions(projectId, groupId);
          const currentVersion = versions.find((item) => item.id === floorPlanId);

          versions.forEach((item, index) => {
            const current = currentVersion ? currentVersion.id === item.id : index === 0;
            set(versionSelector(item.id), {
              id: item.id,
              version: item.version,
              updatedAt: item.lastUpdated,
              notes: item.name,
              latest: index === 0,
              readonly: index !== 0 && current,
              current,
            });
          });
          set(
            versionIdsSelector,
            versions.map((item) => item.id),
          );
        } catch (error) {
          set(versionIdsSelector, []);
          console.error(error);
          throw error;
        } finally {
          set(isLoadingSelector, false);
        }
      },
    [],
  );

  const changeVersion = useRecoilCallback(
    ({ snapshot }) =>
      async (id: string) => {
        showLoader(LoadingType.TRANSPARENT);
        const versionIds = await snapshot.getPromise(versionIdsSelector);
        const latestVersion = await snapshot.getPromise(versionSelector(versionIds[0]));

        if (latestVersion.id === id) {
          await loadLatestVersion();
        } else {
          await loadVersion(id);
        }

        HistoryManager.clearHistory();
        hideLoader();
      },
    [loadVersion, showLoader, hideLoader, loadLatestVersion],
  );

  return {
    loadAll,
    changeVersion,
  };
};
