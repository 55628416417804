import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { selectorFamily } from 'recoil';

import { floorPlanGroupAtom } from './floorPlanGroupAtom';

export const floorPlanGroupsSelector = selectorFamily({
  key: 'floorPlanAdmin/floorPlanGroups/filtered',
  get:
    (ids: string[]) =>
    ({ get }) =>
      ids.map((id) => get(floorPlanGroupAtom(id))),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
