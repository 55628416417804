import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import { SelectInput } from '@common/components/inputs';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { AreaParkingDirection } from '@modules/common/types/shapes';
import { useFloorPlanState } from '../../../../modules/floorplan/hooks/useFloorPlanState';
import { processParkingDirection } from '../../store/process';

export type Props = {
  type: 'deliveryParameters' | 'intakeParameters';
};

const ProcessParkingDirectionComponent = ({ type }: Props) => {
  const { saveFloorPlan } = useFloorPlanState();
  const { t } = useTranslation('interface');
  const mode = useRecoilValue(modeSelector);
  const [parkingDirection, setParkingDirection] = useRecoilState(
    processParkingDirection(type),
  );
  const optionLabels = t('properties.area.parking_direction_options', {
    returnObjects: true,
  });

  const onParkingDirectionChange = useCallback(
    async (value: AreaParkingDirection) => {
      setParkingDirection(value);
      saveFloorPlan();
    },
    [saveFloorPlan, setParkingDirection],
  );

  return (
    <SelectInput
      options={[
        {
          label: optionLabels[AreaParkingDirection.BACKWARD],
          value: AreaParkingDirection.BACKWARD,
        },
        {
          label: optionLabels[AreaParkingDirection.FORWARD],
          value: AreaParkingDirection.FORWARD,
        },
      ]}
      onChange={onParkingDirectionChange}
      value={
        [AreaParkingDirection.BACKWARD, AreaParkingDirection.FORWARD].indexOf(
          parkingDirection as AreaParkingDirection,
        ) > -1
          ? parkingDirection
          : AreaParkingDirection.BACKWARD
      }
      disabled={mode !== WorkspaceMode.EDITABLE}
    />
  );
};

const ProcessParkingDirection = memo(ProcessParkingDirectionComponent);
export default ProcessParkingDirection;
