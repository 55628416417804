import { ArrowDownIcon, ArrowLeftIcon, ArrowRightIcon, ArrowUpIcon } from '@/assets/icons';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { memo, useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { selectedShapesIdsState } from '@recoil/shapes/selected';
import { useFloorPlanState } from '@/modules/floorplan/hooks/useFloorPlanState';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { AreaDirection as AreaDirectionEnum } from '@modules/common/types/shapes';
import {
  PreValidationAspect,
  useRunPreValidation,
} from '../../../../modules/floorplanValidation/clientSide';
import { useArtefacts } from '@/modules/artefacts';
import {
  processDirection,
} from '@/components/PropertiesPanel/store/process';

export type Props = {
  type: 'deliveryParameters' | 'intakeParameters';
};

const ProcessDirectionComponent = ({ type }: Props) => {
  const { saveFloorPlan } = useFloorPlanState();
  const { runPreValidation } = useRunPreValidation();
  const mode = useRecoilValue(modeSelector);
  const ids = useRecoilValue(selectedShapesIdsState);
  const { updateDebounced: updateArtefacts } = useArtefacts();
  const [direction, setDirection] = useRecoilState(
    processDirection(type),
  );

  const onDirectionChange = useCallback(
    (_, value: AreaDirectionEnum) => {
      setDirection(value);
      saveFloorPlan();
      runPreValidation([
        PreValidationAspect.REQUIRED_ELEMENTS,
        PreValidationAspect.FLOWLESS_AREAS,
      ]);
      updateArtefacts(ids);
    },
    [setDirection, saveFloorPlan, runPreValidation, updateArtefacts, ids],
  );

  return (
    <ToggleButtonGroup
      value={direction}
      exclusive
      onChange={onDirectionChange}
      disabled={mode !== WorkspaceMode.EDITABLE}
    >
      <ToggleButton value={AreaDirectionEnum.DOWN} aria-label='Down'>
        <ArrowDownIcon />
      </ToggleButton>
      <ToggleButton value={AreaDirectionEnum.UP} aria-label='Up'>
        <ArrowUpIcon />
      </ToggleButton>
      <ToggleButton value={AreaDirectionEnum.LEFT} aria-label='Left'>
        <ArrowLeftIcon />
      </ToggleButton>
      <ToggleButton value={AreaDirectionEnum.RIGHT} aria-label='Right'>
        <ArrowRightIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

const ProcessDirection = memo(ProcessDirectionComponent);
export default ProcessDirection;
