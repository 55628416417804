import { CheckboxCheckedIcon, CheckboxUncheckedIcon } from '@/assets/icons';
import { LoadCarrierType } from '@/modules/common/types/floorPlan';
import { deSelectedSx, selectedSx } from '@/modules/setup/styles/PropertySelectCard.styles';
import { SpecificationItem } from '@modules/setup/components/common/SpecificationItem';
import { Button, Stack, Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const DEFAULT_WEIGHT_UNIT = 'kg';
const DEFAULT_MEASUREMENT_UNIT = 'mm';

type Props = {
  item: LoadCarrierType;
  selected: boolean;
  onClick: () => void;
};

export const LoadCarrierTypeCard = ({
  item: { id, name, length, width, height, weight, safeWorkingLoad, image },
  selected,
  onClick,
}: Props) => {
  const { t } = useTranslation(['interface']);
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setIsHover(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHover(false);
  }, []);

  const icon = useMemo(() => {
    if (selected) {
      return <CheckboxCheckedIcon className='check' />;
    }
    if (isHover) {
      return <CheckboxUncheckedIcon className='check' />;
    }

    return null;
  }, [isHover, selected]);

  return (
    <Stack>
      <Button
        onClick={onClick}
        sx={selected ? selectedSx : deSelectedSx}
        aria-selected={selected}
        aria-label='load carrier option button'
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Stack width='100%' height='100%'>
          <img
            src={image.url}
            alt={name}
            style={{
              maxWidth: 'calc(197px - 1.6px)',
              maxHeight: 'calc(195px - 1.6px)',
              padding: 8,
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
          />
          <Stack
            className='label-box'
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Typography fontWeight='bold' align='left' fontSize={14}>
              {name}
            </Typography>
            {icon}
          </Stack>
        </Stack>
      </Button>
      <Stack gap={1} mx={1} mt={2}>
        <Typography fontSize={13} color='neutral.main' textTransform='uppercase'>
          {t('setup.floorPlan.load_carrier_types_select_dialog.specifications', 'Specifications')}
        </Typography>
        <SpecificationItem
          i18nKey='interface:setup.floorPlan.property.specifications.length'
          value={length}
          unit={DEFAULT_MEASUREMENT_UNIT}
        />
        <SpecificationItem
          i18nKey='interface:setup.floorPlan.property.specifications.width'
          value={width}
          unit={DEFAULT_MEASUREMENT_UNIT}
        />
        <SpecificationItem
          i18nKey='interface:setup.floorPlan.property.specifications.height'
          value={height}
          unit={DEFAULT_MEASUREMENT_UNIT}
        />
        <SpecificationItem
          i18nKey='interface:setup.floorPlan.property.specifications.weight'
          value={weight}
          unit={DEFAULT_WEIGHT_UNIT}
        />
        <SpecificationItem
          i18nKey='interface:setup.floorPlan.property.specifications.safeWorkingLoad'
          value={safeWorkingLoad}
          unit={DEFAULT_WEIGHT_UNIT}
        />
      </Stack>
    </Stack>
  );
};
