import { ToolState } from '@/modules/common/types/tools';
import { useLayoutFlowsCallbacks } from '@/modules/flows/layout/hooks/useLayoutFlowsCallbacks';
import { toolState } from '@/store/recoil/workspace';
import { activeFlowState } from '@modules/flows/store/layout';
import { Button, Paper, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { WorkspaceOverlayPosition } from '../../../../components/WorkspaceUi';
import { toolButtonState } from '../../../../store/recoil/tool';

const style: any = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: 2,
  borderRadius: '4px',
};

export function FlowUI() {
  const { t } = useTranslation('interface');
  const activeFlow = useRecoilValue(activeFlowState);
  const { endActiveFlow } = useLayoutFlowsCallbacks();
  const setTool = useSetRecoilState(toolState);
  const setToolButton = useSetRecoilState(toolButtonState);

  const cancel = useCallback(() => {
    endActiveFlow();
    setTool(ToolState.SELECT);
    setToolButton(null);
  }, [endActiveFlow, setTool, setToolButton]);

  return (
    <WorkspaceOverlayPosition
      alignVertical='top'
      additionalStyles={{
        width: '100%',
        maxWidth: '42rem',
      }}
    >
      <Paper elevation={1} sx={style}>
        <Typography fontSize={12} sx={{ '&:hover': { cursor: 'default' } }}>
          {activeFlow
            ? t('properties.flows.create_flow.select_end_shape')
            : t('properties.flows.create_flow.select_first_shape')}
        </Typography>
        <Button onClick={cancel}>{t('properties.flows.create_flow.cancel')}</Button>
      </Paper>
    </WorkspaceOverlayPosition>
  );
}
