import { Chip } from '@mui/material';
import { Box } from '@mui/system';

type Props = {
  name: string;
  version: string;
};

export default function PackageVersionChip({ name, version }: Props) {
  return (
    <Chip
      sx={{ marginBottom: 0.5, marginRight: 0.5 }}
      label={
        <Box>
          <Box sx={{ fontWeight: 'bold', display: 'inline', marginRight: 0.5 }}>{name}</Box>
          {version}
        </Box>
      }
      variant='outlined'
      size='small'
    />
  );
}
