import { atom } from 'recoil';

const memoryAtom = atom({
  key: 'memory',
  default: {
    shapeIds: [],
  },
});

export default memoryAtom;
