const OrderProfileIcon = ({ className = '', ...rest }) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    style={{ fill: 'currentColor', stroke: 'none' }}
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    {...rest}
  >
    <path d='M5.61538 20.9999C5.15513 20.9999 4.77083 20.8458 4.4625 20.5374C4.15417 20.2291 4 19.8448 4 19.3846V6.61532C4 6.15507 4.15417 5.77077 4.4625 5.46244C4.77083 5.15411 5.15513 4.99994 5.61538 4.99994H7.3846V2.76917H8.46152V4.99994H15.6154V2.76917H16.6154V4.99994H18.3846C18.8449 4.99994 19.2292 5.15411 19.5375 5.46244C19.8458 5.77077 20 6.15507 20 6.61532V19.3846C20 19.8448 19.8458 20.2291 19.5375 20.5374C19.2292 20.8458 18.8449 20.9999 18.3846 20.9999H5.61538ZM5.61538 19.9999H18.3846C18.5385 19.9999 18.6795 19.9358 18.8077 19.8076C18.9359 19.6794 19 19.5384 19 19.3846V10.6153H5V19.3846C5 19.5384 5.0641 19.6794 5.1923 19.8076C5.32052 19.9358 5.46154 19.9999 5.61538 19.9999ZM5 9.61532H19V6.61532C19 6.46148 18.9359 6.32046 18.8077 6.19224C18.6795 6.06404 18.5385 5.99994 18.3846 5.99994H5.61538C5.46154 5.99994 5.32052 6.06404 5.1923 6.19224C5.0641 6.32046 5 6.46148 5 6.61532V9.61532ZM7.5 13.4999V12.4999H16.5V13.4999H7.5ZM7.5 17.4999V16.4999H13.5V17.4999H7.5Z' />
  </svg>
);

export default OrderProfileIcon;
