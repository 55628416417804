import { existingFlowsIdsSelector } from '@modules/flows/store/layout';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { Flow } from '../../components';
import { FlowScope } from '../../types';
import {
  useRunPreValidation,
  PreValidationAspect,
  PrevalidationDebounceMs,
} from '../../../floorplanValidation/clientSide';

const ExistingFlowsComponent = () => {
  const { t } = useTranslation('interface');
  const existingFlowsIds = useRecoilValue(existingFlowsIdsSelector);
  const { runPreValidation } = useRunPreValidation(PrevalidationDebounceMs.INPUT);

  const onDelete = useCallback(() => {
    runPreValidation([
      PreValidationAspect.INCORRECTLY_CONNECTED_SHAPES,
      PreValidationAspect.DISCONNECTED_AREA_IDS,
      PreValidationAspect.CONNECTION_LACKING_ROADS,
    ]);
  }, [runPreValidation]);

  if (!existingFlowsIds || !existingFlowsIds.length) {
    return null;
  }

  return (
    <>
      {existingFlowsIds.map((id) => (
        <Flow
          key={id}
          flowScope={FlowScope.LAYOUT}
          tooltipTitle={t('properties.flows.delete.tooltip')}
          id={id}
          onDelete={onDelete}
        />
      ))}
    </>
  );
};

export const ExistingFlows = memo(ExistingFlowsComponent);
