import { t } from 'i18next';
import { useSnackbarStore } from '@/modules/snackbar/store/useSnackbarStore';

export const useFileTypeValidator = () => {
  const { showSnackbar } = useSnackbarStore();

  const validateFileType = (file: File, acceptableExtensions: string[]): boolean => {
    const mimeTypeExtension = file.type.split('/').pop()?.toLowerCase();
    const lowerCaseAcceptableExtensions = acceptableExtensions.map((ext) => ext.toLowerCase());

    if (mimeTypeExtension && lowerCaseAcceptableExtensions.includes(mimeTypeExtension)) {
      return true;
    }

    const fileNameExtension = file.name.split('.').pop()?.toLowerCase();

    if (fileNameExtension && lowerCaseAcceptableExtensions.includes(fileNameExtension)) {
      return true;
    }

    const currentType = mimeTypeExtension || fileNameExtension || 'unknown';
    const expectedTypes = acceptableExtensions.join(', ');

    showSnackbar(
      t(
        'errors:settings.incompatible_file_extension',
        `Incompatible file. Current type: ${currentType}, expected: ${expectedTypes}`,
        { currentType, expectedTypes },
      )
    );

    return false;
  };
  return {
    validateFileType,
  };
};
