import { useRef } from 'react';
import { Typography, Stack } from '@mui/material';
import { Accordion } from '@/modules/common/components/Accordion';
import { AngleIcon } from '@/assets/icons';
import { theme } from '@/modules/common/components/theme';

import { useGateStore } from '../../store';

export const StationPosition = () => {
  const panelRef = useRef<HTMLDivElement>();
  const position = useGateStore((state) => state.currentPosition);
  const angle = useGateStore((state) => state.currentAngle);
  const endPointName = useGateStore((state) => state.currentEndpointName);

  if (!position) return null;

  return (
    <Accordion ref={panelRef} title={endPointName} defaultExpanded sx={{ px: 2 }}>
      <Stack direction='row' justifyContent='space-between' mb={2} mt={1}>
        <Stack spacing={1} direction='row'>
          <Typography fontSize='12px' color='neutral.main'>
            X
          </Typography>
          <Typography fontSize='12px'>{position.x}</Typography>
        </Stack>
        <Stack spacing={1} direction='row'>
          <Typography fontSize='12px' color='neutral.main'>
            Y
          </Typography>
          <Typography fontSize='12px'>{position.y}</Typography>
        </Stack>
        <Stack spacing={1} direction='row'>
          <Typography fontSize='12px' color='neutral.main'>
            Z
          </Typography>
          <Typography fontSize='12px'>{position.z}</Typography>
        </Stack>
        <Stack spacing={1} direction='row'>
          <Typography fontSize='12px'>
            <AngleIcon
              style={{
                width: '16px',
                height: '16px',
                color: theme.palette.neutral.main,
              }}
            />
          </Typography>
          <Typography fontSize='12px'>{`${angle}°`}</Typography>
        </Stack>
      </Stack>
    </Accordion>
  );
};
