import { setRecoil } from 'recoil-nexus';
import HistoryAtom, { HistoryAtomState } from './atom';
import { HistoryManagerClass } from '@/modules/common/utils';

const HistoryManager = new HistoryManagerClass(100, (state) => {
  const dataToSync: HistoryAtomState = {
    hasUndo: state.hasUndo,
    hasRedo: state.hasRedo,
  };

  // TODO: Find a better way to get around error "releaseNodesNowOnCurrentTree should only be called at the end of a batch"
  // USE OF TIMEOUTS TO PREVENT ERRORS IS BAD MMMKAY!
  setTimeout(() => setRecoil(HistoryAtom, dataToSync), 0);
});

export default HistoryManager;
