import { Typography } from '@mui/material';
import { isToday } from '@modules/common/helpers/date';

import { Simulation } from '../helpers/types';
import { DividedRow } from './DividedRow';

type Props = {
  simulation: Simulation;
};

export const CreatedCompletedDatesRow = ({ simulation }: Props) => {
  const { created } = simulation;
  const time = created.toLocaleTimeString([...navigator.languages], {
    hour: '2-digit',
    minute: '2-digit',
  });

  if (isToday(simulation.created)) {
    return <Typography sx={{ fontSize: '10px' }}>{time}</Typography>;
  }

  return <DividedRow left={created.toLocaleDateString()} right={time} />;
};
