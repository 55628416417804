import { SVGProps, memo } from 'react';

function SvgComponent({ className = '', ...rest }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      {...rest}
    >
      <path
        d='M12.99 18.9749C13.01 18.8049 13.02 18.6349 13.02 18.4649C13.02 14.3249 9.65002 10.9649 5.51002 10.9649C5.34002 10.9649 5.18002 10.9749 5.02002 10.9949V4.0249H4.02002V19.9749H19.98V18.9749H12.99ZM5.02002 18.9749V11.9849C5.18002 11.9749 5.34002 11.9649 5.51002 11.9649C9.10002 11.9649 12.02 14.8749 12.02 18.4649C12.02 18.6349 12.01 18.8049 12 18.9749H5.02002Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='0.5'
        strokeMiterlimit='10'
      />
    </svg>
  );
}

const ChevronDownIcon = memo(SvgComponent);
export default ChevronDownIcon;
