import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { displayVersionSelector } from '@modules/floorplan/store/floorPlan';

import { useSimulationCallbacks } from '../../../hooks';
import { nextSimulationNameSelector } from '../../../store/simulations';
import { HeaderTitle } from '../../HeaderTitle';
import { Version } from '../../Version';

export const Header = () => {
  const { t } = useTranslation('interface');
  const { createNewDraft } = useSimulationCallbacks();
  const nextName = useRecoilValue(nextSimulationNameSelector);
  const version = useRecoilValue(displayVersionSelector);

  const onAddClick = useCallback(async () => await createNewDraft(), [createNewDraft]);

  return (
    <Stack sx={{ pb: 2, px: 2}} gap={1}>
      <HeaderTitle>{nextName}</HeaderTitle>
      <Version>{version}</Version>
      <Button onClick={onAddClick}>{t('simulation.simulation_list.add_button.label')}</Button>
    </Stack>
  );
};
