import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { useToolStore } from '@/modules/salesWorkspace/store/useToolStore';
import toolStateSelector from '@/store/recoil/workspace/toolState';

export const useTool = () => {
  const tool = useRecoilValue(toolStateSelector);

  useEffect(() => {
    useToolStore.getState().setTool(tool);
  }, [tool]);
};
