import { Connection } from '@modules/common/types/connections';

/**
 * changes the rotation of the connection to conform to the perspective from the shapeId
 */
export const transformConnectionToRelativePerspective = (con: Connection, shapeId: string) =>
  con.from === shapeId ? con : { ...con, rot: invertConnectionRotation(con.rot) };

export const invertConnectionRotation = (rot: number): number => {
  let result = rot > 180 ? rot - 180 : rot + 180;
  if (result === 360) return 0;

  return result;
};
