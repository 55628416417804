import * as React from 'react';

function Warning({ className = '', ...rest }) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      style={{ fill: 'currentColor' }}
      className={className}
      {...rest}
    >
      <path d='M2.72501 20L12 4L21.275 20H2.72501ZM4.45001 19H19.55L12 6L4.45001 19ZM12 17.625C12.1667 17.625 12.3127 17.5627 12.438 17.438C12.5627 17.3127 12.625 17.1667 12.625 17C12.625 16.8333 12.5627 16.6873 12.438 16.562C12.3127 16.4373 12.1667 16.375 12 16.375C11.8333 16.375 11.6873 16.4373 11.562 16.562C11.4373 16.6873 11.375 16.8333 11.375 17C11.375 17.1667 11.4373 17.3127 11.562 17.438C11.6873 17.5627 11.8333 17.625 12 17.625ZM11.5 15.375H12.5V10.375H11.5V15.375Z' />
    </svg>
  );
}

const WarningIcon = React.memo(Warning);
export default WarningIcon;
