import { ReactNode } from 'react';
import { useRecoilValue } from 'recoil';

import { referenceType } from '../store';
import { REFERENCE_TYPE } from '../types';
import { AutodeskViewerWrapper } from './AutodeskViewer/AutodeskViewerWrapper';

type Props = {
  children: ReactNode;
};

export const ReferenceImageWrapper = ({ children }: Props) => {
  const type = useRecoilValue(referenceType);

  if (type !== REFERENCE_TYPE.APS) {
    return <>{children}</>;
  }

  return <AutodeskViewerWrapper>{children}</AutodeskViewerWrapper>;
};
