import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../../../store/recoil/common';
import { flowInfoIdState } from './atom';

export const flowInfoIdSelector = selector<string | null>({
  key: 'flowInfoIdSelector',
  get: ({ get }) => get(flowInfoIdState),
  set: ({ set }, newValue: string | null) => {
    set(flowInfoIdState, newValue);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
