export const getRackShelfHeights = (
  baseLevelHeight: number,
  levelHeight: number,
  levelAmount: number,
  beamHeight: number,
) => {
  const levelHeights: number[] = [];
  for (let i = 0; i < levelAmount; i++) {
    levelHeights.push(baseLevelHeight + (levelHeight + beamHeight) * i);
  }

  return levelHeights;
};

export const calcTotalRackWidth = (baysAmount: number, bayWidth: number, postWidth: number) =>
  bayWidth * baysAmount + postWidth * (baysAmount + 1);

export const calcBayAmountCapacity = (
  beamLength: number,
  availableSpace: number,
  postWidth: number,
) => {
  let baysAmount = 0;
  let spaceTaken = beamLength + 2 * postWidth;
  while (spaceTaken <= availableSpace) {
    spaceTaken += beamLength + postWidth;
    baysAmount++;
  }

  return baysAmount;
};

export const calcBeamLength = (
  loadCarrierBoxWidth: number,
  loadsPerShelfAlongWidth: number,
  clearanceInBetween: number,
  clearanceSide: number,
) =>
  loadCarrierBoxWidth * loadsPerShelfAlongWidth +
  clearanceInBetween * (loadsPerShelfAlongWidth - 1) +
  clearanceSide * 2;

export const calcMaxRackRowLength = (
  isVertical: boolean,
  boxW: number,
  boxH: number,
  margin: number,
) => (isVertical ? boxW : boxH) - margin * 2;
  