import { Box, Typography } from '@mui/material';
import { formatDuration } from '@/modules/common/helpers/date';

import { Simulation } from '../helpers/types';

type Props = {
  simulation: Simulation;
};

export const CompletedDuration = ({ simulation }: Props) => {
  if (!simulation.results?.endTime || !simulation.results?.currentTime) {
    return null;
  }

  return (
    <Typography variant='caption' fontSize={10}>
      <Box component='span' color='neutral.darker'>
        {formatDuration(simulation.results.currentTime)}
      </Box>{' '}
      {`/ ${formatDuration(simulation.results.endTime)}`}
    </Typography>
  );
};
