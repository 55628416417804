import { RemoveIcon } from '@/assets/icons';
import { StyledTooltip } from '@helpers/styles';
import { IconButton, Stack, Typography } from '@mui/material';
import { useRef } from 'react';

type Props = {
  name: string;
  imageUrl: string;
  deleteBtnTooltipText: string;
  onDeleteClick: () => void;
  deletable: boolean;
};

const containerSx = {
  height: '48px',
  alignItems: 'center',
  flexDirection: 'row',
  gap: 3,
  padding: 0,
  border: '1px solid transparent',
};

const containerInteractiveSx = {
  '&:hover': {
    borderColor: 'divider',
  },
};

export const FloorPlanPropertyItem = ({
  name,
  imageUrl,
  onDeleteClick,
  deleteBtnTooltipText,
  deletable,
}: Props) => {
  const removeButtonRef = useRef();

  return (
    <Stack sx={{ ...containerSx, ...(deletable ? containerInteractiveSx : {}) }}>
      <img src={imageUrl} alt={name} width={32} height={32} />
      <Typography fontSize={14} fontWeight={500}>
        {name}
      </Typography>
      {deletable && (
        <StyledTooltip title={deleteBtnTooltipText}>
          <IconButton
            sx={{ marginLeft: 'auto', padding: 0, border: 'none' }}
            onClick={onDeleteClick}
            ref={removeButtonRef}
            aria-label='delete property item'
          >
            <RemoveIcon />
          </IconButton>
        </StyledTooltip>
      )}
    </Stack>
  );
};
