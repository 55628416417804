import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { selector } from 'recoil';

import { simulationAtom } from './simulationAtom';
import { OrderDistributionStrategy } from '@/modules/simulation/helpers/types';

export const orderDistributionStrategySelector = selector<OrderDistributionStrategy>({
  key: 'simulations/draft/orderDistributionStrategySelector',
  get: ({ get }) => get(simulationAtom)?.orderDistributionStrategy,
  set: ({ set }, orderDistributionStrategySelector: OrderDistributionStrategy) =>
    set(simulationAtom, (state) => ({
      ...state,
      orderDistributionStrategySelector,
    })),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
