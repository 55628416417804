import { zoomTheme } from '@common/components/theme';
import { ThemeProvider } from '@mui/styles';
import { Paper, Typography } from '@mui/material';

type Props = {
  x: number;
  y: number;
};

export const MousePosition = ({ x, y }: Props) => (
  <ThemeProvider theme={zoomTheme}>
    <Paper
      elevation={1}
      sx={{
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        p: 1,
        px: 2,
        minWidth: '200px',
      }}
    >
      <Typography fontSize={14} sx={{ whiteSpace: 'nowrap' }}>
        x: {x} y: {y}
      </Typography>
    </Paper>
  </ThemeProvider>
);
