import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import { Simulation } from '../helpers/types';

type Props = {
  simulation: Simulation;
};

/**
 * Displays number or range of vehicles used in simulation
 */
export const VehicleCount = ({ simulation }: Props) => {
  const { t } = useTranslation('interface');

  const message = useMemo(() => {
    const vehicleCount = simulation.details.vehicleTypes[0].range;

    if (vehicleCount.length === 1) {
      const count = simulation.details.vehicleTypes.reduce(
        (acc, item) => acc + item.range.at(0),
        0,
      );
      return t(
        count === 1 ? 'simulation.run_card.vehicle_count' : 'simulation.run_card.vehicles_count',
        { vehicleCount: count },
      );
    }

    return t('simulation.run_card.vehicle_range', {
      from: vehicleCount.at(0),
      to: vehicleCount.at(-1),
    });
  }, [simulation, t]);

  return (
    <Typography variant='caption' sx={{ fontSize: '10px' }}>
      {message}
    </Typography>
  );
};
