import { SvgIcon } from '@mui/material';
import { memo } from 'react';

const DownloadIcon = ({ className = '', size = 24, color = 'currentColor', ...rest }) => (
  <SvgIcon
    viewBox='0 0 24 24'
    sx={{ width: size, height: size, fontSize: `${size}px`, fill: color }}
    className={className}
    {...rest}
  >
    <path
      d='M6.61537 19C6.15512 19 5.77083 18.8458 5.4625 18.5375C5.15417 18.2291 5 17.8448 5 17.3846V15H6V17.3846C6 17.5384 6.0641 17.6794 6.1923 17.8077C6.32052 17.9359 6.46154 18 6.61537 18H17.3846C17.5385 18 17.6795 17.9359 17.8077 17.8077C17.9359 17.6794 18 17.5384 18 17.3846V15H19V17.3846C19 17.8448 18.8458 18.2291 18.5375 18.5375C18.2292 18.8458 17.8449 19 17.3846 19H6.61537ZM12 15.2307L8.46152 11.6923L9.16923 10.973L11.5 13.3038V4.65381H12.5V13.3038L14.8308 10.973L15.5385 11.6923L12 15.2307Z'
      fill={color}
    />
  </SvgIcon>
);

export default memo(DownloadIcon);
