import { calculateShapesBoundingBox } from '@/modules/common/helpers/shapes';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@/store/recoil/common';
import { AreaShape } from '@/store/recoil/shape';
import shapeAtom from '@/store/recoil/shape/atom';
import { shapeGroupState } from '@modules/shapeGroups';
import { selectorFamily } from 'recoil';

// selector that gets the bounding box of a group based on its children shapes their state in recoil
export const shapeGroupBoundingBoxSelector = selectorFamily({
  key: 'shapegroup/boundingbox',
  get:
    (id: string) =>
    ({ get }) => {
      const group = get(shapeGroupState(id));

      if (!group) return undefined;
      const groupedShapes = group.children.map((item) => get(shapeAtom(item)) as AreaShape);
      return calculateShapesBoundingBox(groupedShapes);
    },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const shapeBoundingBoxSelector = selectorFamily({
  key: 'shape/boundingBox',
  get:
    (id: string) =>
    ({ get }) => {
      const shape = get(shapeAtom(id)) as AreaShape;
      return calculateShapesBoundingBox([shape]);
    },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
