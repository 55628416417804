import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@/store/recoil/common';

import { moduleAtom } from './module';

/**
 * Id of the floor plan the next simulation will be run on
 */
export const floorPlanIdSelector = selector({
  key: 'simulation/module/floorPlanId',
  get: ({ get }) => get(moduleAtom).floorPlanId,
  set: ({ set }, floorPlanId: string) =>
    set(moduleAtom, (state) => ({
      ...state,
      floorPlanId,
    })),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
