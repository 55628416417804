import { MutableRefObject, useCallback } from 'react';
import { KEYCODE } from '@recoil/input';

export const useEnterBlur = (ref: MutableRefObject<HTMLInputElement>) => {
  const onKeyUp = useCallback(
    (e) => {
      if (e.key === KEYCODE.ENTER) ref.current.blur();
    },
    [ref],
  );

  return {
    onKeyUp,
  };
};
