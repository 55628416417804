import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../../../../store/recoil/common';
import { prevalidationState } from '../state';

export const incorrectlyConnectedShapeToRoadIdsSelector = selector({
  key: 'prevalidation/incorrectlyConnectedShapeIds',
  get: ({ get }) => get(prevalidationState).incorrectlyConnectedShapeToRoadIds,
  set: ({ set }, newIds: string[]) =>
    set(prevalidationState, (current) => ({ ...current, incorrectlyConnectedShapeIds: newIds })),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
