import { useAxios } from '@/modules/api/hooks/useAxios';
import { useConfig } from '@/modules/common/hooks';
import { useMemo } from 'react';

export const useVikingAdaptorApi = () => {
  const { api } = useConfig();

  const scopes = useMemo(() => [api.viking.scope], [api.viking.scope]);

  return useAxios({
    baseURL: api.viking.url,
    scopes,
  });
};
