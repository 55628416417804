import { FlowDirection } from '@/modules/flows/components';
import { PropertiesLabel } from '@common/components/PropertiesLabel';
import { PropertiesPopup } from '@common/components/PropertiesPopup';
import { PropertiesTitle } from '@common/components/PropertiesTitle';
import { NumberInput } from '@common/components/inputs';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { FLOW_MIN } from '@modules/simulation';
import { Stack } from '@mui/material';
import { CONTEXT, contextState } from '@recoil/input';
import { MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useFlowUpdaters } from '../common/hooks';
import { getScopedFlowSelector } from '../helpers';
import { FlowScope } from '../types';

type Props = {
  anchor: MutableRefObject<HTMLElement>;
  id: string;
  propertiesLabeli18nkey: string;
  onClose?: () => void;
  flowScope: FlowScope;
};

export function FlowInfo({ anchor, id, onClose, propertiesLabeli18nkey, flowScope }: Props) {
  const { t } = useTranslation();
  const flowSelector = getScopedFlowSelector(flowScope);
  const { name, sourceName, targetName, totalNumLoads } = useRecoilValue(flowSelector(id));
  const setContext = useSetRecoilState(contextState);
  const { onFlowLoadsChange, updateFlow } = useFlowUpdaters(id, flowScope);
  const disabled = useRecoilValue(modeSelector) !== WorkspaceMode.EDITABLE;

  return (
    <PropertiesPopup
      anchor={anchor}
      open={!!anchor}
      onClose={onClose}
      header={
        <Stack>
          <PropertiesTitle
            editable={!disabled}
            fontSize={14}
            value={name}
            sx={{ flex: '1 1 auto' }}
            onChange={(newName) => updateFlow({ name: newName })}
            onFocus={() => setContext(CONTEXT.PROPERTYPANEL)}
            onBlur={() => setContext(CONTEXT.WORKSPACE)}
          />
          <FlowDirection sourceName={sourceName} targetName={targetName} />
        </Stack>
      }
    >
      <PropertiesLabel i18nkey={propertiesLabeli18nkey}>
        <NumberInput
          value={totalNumLoads}
          disabled={disabled}
          onChange={onFlowLoadsChange}
          onFocus={() => setContext(CONTEXT.PROPERTYPANEL)}
          onBlur={() => setContext(CONTEXT.WORKSPACE)}
          ariaLabel={t('interface:properties.flows.flow_info.total_loads.label')}
          errorType='floating'
          dataType='integer'
          minValue={FLOW_MIN}
          minValueErrorMsg={t('common:minimum_value', { minValue: FLOW_MIN })}
        />
      </PropertiesLabel>
    </PropertiesPopup>
  );
}
