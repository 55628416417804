import { useCallback } from 'react';
import { FleetTrackerContract } from '../class';
import { AdapterSnapshotContract, VisualizationObject } from '../types';
import { EventNames, useEventBus } from './useEventBus';

export const useFleetTrackerWebglApi = () => {
  const { emit } = useEventBus();

  const sendPlay = useCallback(() => {
    emit(EventNames.VisualizationWebGLSend, {
      target: 'Tracker',
      action: 'PlaybackPlay',
    });
  }, [emit]);

  const sendPause = useCallback(() => {
    emit(EventNames.VisualizationWebGLSend, {
      target: 'Tracker',
      action: 'PlaybackPause',
    });
  }, [emit]);

  const sendAdaptorSnapshotContract = useCallback(
    (snapshot: AdapterSnapshotContract) => {
      emit(EventNames.VisualizationWebGLSend, {
        target: 'Tracker',
        action: 'UpdateAdapterSnapshotJson',
        payload: snapshot,
      });
    },
    [emit],
  );

  const sendVisualizationObjectUpdate = useCallback(
    (vo: VisualizationObject) => {
      emit(EventNames.VisualizationWebGLSend, {
        target: 'Tracker',
        action: 'UpdateVisualizationObjectJson',
        payload: vo,
      });
    },
    [emit],
  );

  const sendMessage = useCallback(
    (contract: FleetTrackerContract) => {
      emit(EventNames.VisualizationWebGLSend, {
        target: 'Tracker',
        action: 'HandleGatewayMessageJson',
        payload: contract,
      });
    },
    [emit],
  );

  return {
    sendPlay,
    sendPause,
    sendAdaptorSnapshotContract,
    sendVisualizationObjectUpdate,
    sendMessage,
  };
};
