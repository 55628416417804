import { Type, Variant } from '@/modules/visualization/enum';
import { PointcloudData, VisualizationObject } from '@/modules/visualization/types';
import { Button, Stack, TextField } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { SOURCE_NAME } from '../../../consts';
import { EventNames, useEventBus } from '../../../hooks';
import { ValueAdjustButton } from './ValueAdjustButton';

export function Pointcloud() {
  const [url, setUrl] = useState<string>(
    'https://thivefleettracker.blob.core.windows.net/unityassets/pointcloud/sensus-200k.ply',
  );
  const [loaded, setLoaded] = useState<boolean>(false);
  const [x, setX] = useState<number>(12880);
  const [y, setY] = useState<number>(6290);
  const [z, setZ] = useState<number>(-440);
  const { emit } = useEventBus();

  useEffect(() => {
    if (loaded) injectPointCloud();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [x, y, z]);

  const injectPointCloud = useCallback(() => {
    const pointcloudData: PointcloudData = {
      Url: url,
    };
    const payload: VisualizationObject = {
      Name: 'Pointcloud',
      Source: SOURCE_NAME,
      Type: Type.Pointcloud,
      Variant: Variant.Ply,
      Position: {
        X: x,
        Y: y,
        Z: z,
      },
      Data: pointcloudData,
    };
    emit(EventNames.VisualizationWebGLSend, {
      target: 'Tracker',
      action: 'UpdateVisualizationObjectJson',
      payload,
    });
    setLoaded(true);
  }, [emit, url, x, y, z]);

  return (
    <Stack spacing={2}>
      <TextField value={url} onChange={(e) => setUrl(e.target.value)} placeholder='Add URL' />
      <Button onClick={injectPointCloud}>Load Pointcloud</Button>
      <Stack spacing={2} gap={1}>
        <ValueAdjustButton label='X' value={x} onValueChange={(v) => setX(v)} />
        <ValueAdjustButton label='Y' value={y} onValueChange={(v) => setY(v)} />
        <ValueAdjustButton label='Z' value={z} onValueChange={(v) => setZ(v)} />
      </Stack>
    </Stack>
  );
}
