import axios from 'axios';
import { useCallback } from 'react';
import { use3TE25LayoutStore } from '../store';

const BASE_URL = '/3te25';
const LAYOUT_POINT_FILE = 'LayoutPoint.csv';
const UNIT_DATA_FILE = 'UnitData.csv';

export const useDemoLayout = () => {
  const uploadFiles = useCallback(async () => {
    console.log('/// uploading debug layout for 3TE25 ///');
    const { setLayoutPoint, setUnitData } = use3TE25LayoutStore.getState();

    // layout point
    const layoutBlob = await axios.get(`${BASE_URL}/${LAYOUT_POINT_FILE}`, {
      responseType: 'blob',
    });
    const layoutFile = new File([layoutBlob.data], 'LayoutPoint.csv');
    setLayoutPoint(layoutFile);

    // unit data
    const unitBlob = await axios.get(`${BASE_URL}/${UNIT_DATA_FILE}`, { responseType: 'blob' });
    const unitFile = new File([unitBlob.data], 'LayoutPoint.csv');
    setUnitData(unitFile);
  }, []);
  return { uploadFiles };
};
