import { DeleteIcon } from '@/assets/icons';
import { StyledTooltip } from '@/helpers/styles';
import { OverflowTypography } from '@/modules/common/components/OverflowTypography';
import { theme } from '@/modules/common/components/theme';
import { FlowScope } from '@/modules/flows';
import { IconButton } from '@mui/material';
import { Stack } from '@mui/system';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { useOrderProfileExcel } from '../hooks/useOrderProfileExcel';
import { orderProfileAtom } from '../store/orderProfileAtom';

const buttonStyle = {
  padding: 0,
  borderRadius: '2px',
  '&:hover': {
    color: theme.palette.primary.main,
  },
  '&:active:hover': {
    color: theme.palette.primary.main,
  },
};

type Props = {
  flowScope: FlowScope;
};

export const OrderProfileFile = ({ flowScope }: Props) => {
  const orderProfile = useRecoilValue(orderProfileAtom);
  const { removeOrderProfile } = useOrderProfileExcel();

  const filename = useMemo(() => {
    if (flowScope === FlowScope.LAYOUT) return orderProfile?.uploadedFileName;
    return orderProfile?.overwriteFileName;
  }, [orderProfile, flowScope]);

  return (
    <>
      {filename && (
        <Stack direction='row' alignItems='center' gap={1} sx={{ width: '100%' }}>
          <OverflowTypography
            sx={{
              display: 'flex',
              fontSize: 12,
              mr: 'auto',
              marginY: '1rem',
            }}
          >
            {filename}
          </OverflowTypography>
          <StyledTooltip title='Delete order profile'>
            <IconButton onClick={() => removeOrderProfile(flowScope)} sx={buttonStyle}>
              <DeleteIcon />
            </IconButton>
          </StyledTooltip>
        </Stack>
      )}
    </>
  );
};
