import { useRecoilValue } from 'recoil';
import { FlowLine } from '../../components';
import { simulationFlowIdsAtom } from '../../store/simulation/atom';
import { FlowScope } from '../../types';

export function SimulationFlowsRenderer() {
  const flowIds = useRecoilValue(simulationFlowIdsAtom);

  if (!flowIds.length) return null;

  return (
    <>
      {!!flowIds?.length &&
        flowIds.map((id) => <FlowLine flowId={id} flowScope={FlowScope.SIMULATION} key={id} />)}
    </>
  );
}
