import { Module } from '@/modules/common/types/navigation';
import { isFloorPlanLoadedSelector } from '@/modules/floorplan';
import navAtom from '@recoil/nav';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

export const useSetupVisualization = () => {
  const { simulationId } = useParams();
  const setNav = useSetRecoilState(navAtom);
  const isFloorplanLoaded = useRecoilValue(isFloorPlanLoadedSelector);

  useEffect(() => {
    if (!simulationId || !isFloorplanLoaded) {
      return;
    }

    setNav(Module.VISUALISATION);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simulationId, isFloorplanLoaded]);
};
