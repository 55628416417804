import { Box, Card, IconButton, Stack, Typography } from '@mui/material';
import { LoadingContainer } from '@common/components/LoadingContainer';
import { memo } from 'react';
import { DuplicateIcon } from '../../assets/icons';
import { useFloorplanGridItem } from './hooks';
import { theme } from '@modules/common/components/theme';

function ThumbnailPlaceholder({ text }) {
  return <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>{text}</div>;
}

type Props = {
  id: string;
};

const FloorplanGridItemComponent = ({ id }: Props) => {
  const {
    name,
    itemHover,
    bindLongPress,
    handleFloorplanClick,
    thumb,
    onMouseOver,
    onMouseLeave,
    onDoubleClick,
    onContextMenuHandler,
    isSelected,
    isBeingDeleted,
    onDuplicate,
  } = useFloorplanGridItem(id);

  return (
    <Box>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignContent: 'space-between' }}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        data-testid='floorplan'
        onClick={handleFloorplanClick}
      >
        <Card
          elevation={0}
          onDoubleClick={onDoubleClick}
          onContextMenu={onContextMenuHandler}
          {...bindLongPress()}
          sx={{
            height: 230,
            borderRadius: 0,
            backgroundColor: theme.palette.others.lighter,
            border: '1px solid',
            borderColor: isSelected ? theme.palette.primary.main : theme.palette.neutral.grey,
            textTransform: 'none',
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '&:hover': {
              backgroundColor: theme.palette.others.lighter,
              borderColor: theme.palette.primary.main,
              cursor: 'pointer',
            },
          }}
        >
          {isBeingDeleted ? (
            <LoadingContainer isLoading minDuration={1000} delay={0} sx={{ height: '100%' }} />
          ) : thumb ? (
            <Box
              sx={{
                padding: '10px 50px',
                minHeight: 0,
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                src={thumb}
                style={{ height: 'auto', maxHeight: '100%', maxWidth: '100%' }}
                alt=''
              />
            </Box>
          ) : (
            <Box sx={{ padding: '10px 50px' }}>
              <ThumbnailPlaceholder text='' />
            </Box>
          )}
          <Stack
            alignItems='center'
            justifyContent='space-between'
            direction='row'
            sx={{
              height: '56px',
              width: '100%',
              padding: '0 16px',
              backgroundColor:
                itemHover || isSelected ? theme.palette.primary.light : theme.palette.shades.light,
              borderTop: '1px solid',
              borderTopColor: itemHover || isSelected ? theme.palette.primary.main : 'transparent',
              flexShrink: 0,
            }}
          >
            <Typography
              variant='h4'
              sx={{
                fontWeight: '500',
                color: itemHover ? theme.palette.primary.main : theme.palette.neutral.darker,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                paddingRight: '10px',
              }}
            >
              {name}
            </Typography>
            {itemHover ? (
              <Stack direction='row' gap={1}>
                <IconButton
                  onClick={onDuplicate}
                  sx={{ padding: 0 }}
                  aria-label='duplicate floorplan'
                >
                  <DuplicateIcon />
                </IconButton>
              </Stack>
            ) : null}
          </Stack>
        </Card>
      </Box>
    </Box>
  );
};

export const FloorplanGridItem = memo(FloorplanGridItemComponent);
