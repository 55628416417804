import { ShapeType } from '@modules/common/types/shapes';
import { UserPreferenceName, getUserPreference } from '@modules/userPreferences';

export const gap = (shapeType: ShapeType): number => {
  switch (shapeType) {
    case ShapeType.STORAGE:
    case ShapeType.STORAGE_POSITION:
      return getUserPreference(UserPreferenceName.STORAGE_GAP);
    case ShapeType.INTAKE:
    case ShapeType.INTAKE_POSITION:
      return getUserPreference(UserPreferenceName.INTAKE_GAP);
    case ShapeType.DELIVERY:
    case ShapeType.DELIVERY_POSITION:
      return getUserPreference(UserPreferenceName.DELIVERY_GAP);
    case ShapeType.PROCESS_ONE_EP:
    case ShapeType.PROCESS_ONE_EP_POSITION:
      return getUserPreference(UserPreferenceName.PROCESS_GAP);
    case ShapeType.CHARGING:
    case ShapeType.CHARGING_POSITION:
      return getUserPreference(UserPreferenceName.CHARGING_GAP);
    case ShapeType.PARKING:
    case ShapeType.PARKING_POSITION:
      return getUserPreference(UserPreferenceName.PARKING_GAP);
    case ShapeType.HIGHWAY:
    case ShapeType.HIGHWAY_ANGLED:
      return getUserPreference(UserPreferenceName.HIGHWAY_GAP);
    default:
      return 1250;
  }
};
