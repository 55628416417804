import { useRecoilCallback } from 'recoil';

import { ControlPoint } from '@/modules/common/types/shapes';
import { useConnections } from '@/modules/connections';
import { useAutoSave } from '@/modules/floorplan';
import { AngledHighwayShape } from '@modules/angledHighways/types';
import { HistoryManager } from '@recoil/history';
import shapeAtom from '@recoil/shape/atom';

type HistoryState = { shapeId: string; value: ControlPoint[] };

export const useControlPointHistoryTracking = () => {
  const { updateConnections } = useConnections();
  const { save } = useAutoSave();

  const trackControlPointHistory = useRecoilCallback(
    ({ set }) =>
      (newState: HistoryState, prevState: HistoryState) => {
        HistoryManager.track(
          `control points edited`,
          newState,
          prevState,
          (historyState: { shapeId: string; value: ControlPoint[] }) => {
            set(
              shapeAtom(historyState.shapeId),
              (current: AngledHighwayShape): AngledHighwayShape => ({
                ...current,
                properties: {
                  ...current.properties,
                  controlPoints: historyState.value,
                },
              }),
            );

            updateConnections([historyState.shapeId]);
            save();
            // TODO: angled highways prevalidation support
          },
        );
      },
    [save, updateConnections],
  );

  return { trackControlPointHistory };
};
