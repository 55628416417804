import { Grid, Typography, Switch } from '@mui/material';
import { DEBUG_ACTION_SIZE, DEBUG_DESCRIPTION_SIZE } from './DebugPanel';
import { useDebugStore } from '@/modules/debug/store';

export const GroupMemberSelectionEnabledToggle = () => {
  const { groupMemberSelectionForceEnabled, toggleGroupMemberSelectionEnabled } = useDebugStore(
    (state) => state,
  );

  return (
    <>
      <Grid item xs={DEBUG_DESCRIPTION_SIZE}>
        <Typography>Enable group member selection</Typography>
      </Grid>
      <Grid item xs={DEBUG_ACTION_SIZE}>
        <Switch
          onChange={toggleGroupMemberSelectionEnabled}
          checked={groupMemberSelectionForceEnabled}
        />
      </Grid>
    </>
  );
};
