import { Group as GroupType } from 'konva/lib/Group';
import { KonvaEventObject, Node } from 'konva/lib/Node';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Group, Line, Rect } from 'react-konva';
import { useRecoilValue } from 'recoil';

import { BoundingBox } from '@/helpers/types';
import { useShapeHover } from '@/modules/common/hooks/useShapeHover';
import { ControlPoint } from '@/modules/common/types/shapes';
import { selectedShapesIdsState } from '@/store/recoil/shapes/selected';
import {
  ElementName,
  PROXY_STROKE_WIDTH,
  SHAPE_TO_CANVAS_SCALE,
} from '@modules/workspace/helpers/konva';
import { draggableShapeSelector } from '@recoil/workspace/draggableShapesSelector';
import { accurateAngledHighwayBoundingBox, getFlatPointsArrayFromControlPoints } from '@/modules/shapes/controlPointsShapes/helpers';

export type WallProxyProps = {
  id: string;
  points: ControlPoint[];
  width: number;
};

/* TRANSPARENT RECT FOR HANDLING EVENTS */
export const WallProxy = ({ id, points, width }: WallProxyProps) => {
  const { onMouseEnter, onMouseLeave } = useShapeHover(id);
  const draggable = useRecoilValue(draggableShapeSelector(id));
  const selectedShapesIds = useRecoilValue(selectedShapesIdsState);
  const [canvasPoints, setCanvasPoints] = useState<number[]>(null);
  const [proxyBox, setProxyBox] = useState<BoundingBox>(null);
  const mousingDown = useRef<boolean>(false);

  useEffect(() => {
    setCanvasPoints(
      getFlatPointsArrayFromControlPoints(points).map((num) => num * SHAPE_TO_CANVAS_SCALE),
    );
  }, [points]);

  useEffect(() => {
    const controlPoints = points.map((p) => p.position);
    const bb = accurateAngledHighwayBoundingBox(controlPoints, width);
    setProxyBox(bb);
  }, [points, width]);

  const onDragStart = useCallback((e: KonvaEventObject<DragEvent>) => {
    const angledHighwayProxyGroup =
      e.target.getType() === 'Group' && e.target.name() === ElementName.POINTS_SHAPE_PROXY_GROUP
        ? e.target
        : e.target.getParent();

    const bbSubstitute = angledHighwayProxyGroup.children.find(
      (item) => item.name() === ElementName.POINTS_SHAPE_PROXY_BOX,
    ) as Node;

    // if drag is initiated by this shape, mark bb substitute node as drag leader
    bbSubstitute.setAttr('isDragLeader', mousingDown.current);

    if (e.target.name() === ElementName.POINTS_SHAPE_PROXY_GROUP) bbSubstitute.fire('dragstart', e);
  }, []);

  const onDragMove = useCallback((e: KonvaEventObject<DragEvent>) => {
    if (e.target.name() !== ElementName.POINTS_SHAPE_PROXY_GROUP) return;

    const bbSubstitute = (e.target as GroupType).children.find(
      (item) => item.name() === ElementName.POINTS_SHAPE_PROXY_BOX,
    );

    bbSubstitute.fire('dragmove', e);
  }, []);

  const isSelected = useMemo(() => selectedShapesIds.includes(id), [id, selectedShapesIds]);

  return (
    <>
      {proxyBox && (
        <Group
          draggable={draggable}
          name={ElementName.POINTS_SHAPE_PROXY_GROUP}
          onDragStart={onDragStart}
          onDragMove={onDragMove}
          onDragEnd={() => {}}
          onMouseDown={() => {
            mousingDown.current = true;
          }}
          onMouseUp={() => {
            mousingDown.current = false;
          }}
        >
          <Rect
            id={id}
            name={ElementName.POINTS_SHAPE_PROXY_BOX}
            x={proxyBox.x * SHAPE_TO_CANVAS_SCALE + PROXY_STROKE_WIDTH / 2}
            y={proxyBox.y * SHAPE_TO_CANVAS_SCALE + PROXY_STROKE_WIDTH / 2}
            width={proxyBox.width * SHAPE_TO_CANVAS_SCALE - PROXY_STROKE_WIDTH}
            height={proxyBox.height * SHAPE_TO_CANVAS_SCALE - PROXY_STROKE_WIDTH}
            listening={isSelected}
            fillEnabled={false}
            stroke='transparent'
            strokeWidth={PROXY_STROKE_WIDTH}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
          <Line
            id={id}
            name={ElementName.POINTS_SHAPE_PROXY_LINE}
            points={canvasPoints}
            stroke='#0000'
            strokeWidth={width * SHAPE_TO_CANVAS_SCALE}
            lineCap='butt'
            lineJoin='round'
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
        </Group>
      )}
    </>
  );
};
