import { selectorFamily } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import { floorPlanGroupAtom } from './floorPlanGroupAtom';

export const nameSelector = selectorFamily<string, string>({
  key: 'floorPlanAdmin/floorPlanGroups/name',
  get:
    (id: string) =>
    ({ get }) =>
      get(floorPlanGroupAtom(id))?.name,

  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
