import { useCallback } from 'react';
import { useBaseProjectApi } from './useBaseProjectApi';

export const useProjectApi = () => {
  const projectApi = useBaseProjectApi();

  const fetch = useCallback(
    async (projectId: string): Promise<Project> =>
      mapToProject((await projectApi.get(projectId)).data),
    [projectApi],
  );

  const fetchAll = useCallback(
    async (): Promise<Project[]> => (await projectApi.get('/')).data.map(mapToProject),
    [projectApi],
  );

  return {
    fetch,
    fetchAll,
  };
};

const mapToProject = (response: any): Project => ({
  id: response.id,
  name: response.name,
  created: new Date(response.created),
  lastUpdated: new Date(response.lastUpdated),
});

export type Project = {
  id: string;
  name: string;
  created: Date;
  lastUpdated: Date;
};
