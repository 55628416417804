import {
  GroupComposition,
  ShapeGroup,
  ShapeGroupInteractivityMode,
  SingleTypeGroup,
  legacyFlowGroup,
} from '@/modules/common/types/shapeGroup';
import { isLegacyFlowGroup } from '@/modules/common/types/guards';

/**
 * Prepares shapeGroups to be loaded into the app
 */
export const prepareToLoad = (shapeGroups: (ShapeGroup | legacyFlowGroup)[]): ShapeGroup[] => {
  let newGroups = shapeGroups;

  return backwardCompatibilityForSingleTypeGroups(newGroups);
};

/**
 * Converts an outdated flow group, now single type group, by ensuring that it has the necessary properties: `composition` and `interactivityMode`.
 *
 * @param shapeGroups - The items of which some are already up-to-date, and others can be outdated.
 * @returns An array of groups, of which all the legacy flow groups are converted to single type groups
 */
const backwardCompatibilityForSingleTypeGroups = (
  shapeGroups: (ShapeGroup | legacyFlowGroup)[],
): ShapeGroup[] =>
  shapeGroups.map((item) => {
    if (isLegacyFlowGroup(item)) {
      const updatedSingleTypeGroup: SingleTypeGroup = {
        ...item,
        composition: GroupComposition.SINGLE_TYPE,
        interactivityMode: ShapeGroupInteractivityMode.UNRESTRICTED,
      };

      return updatedSingleTypeGroup;
    }

    return item;
  });
