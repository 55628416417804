import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import { groupAtom } from './atom';

/**
 * Name of the group the currently loaded floor plan belongs to
 */

export const nameSelector = selector({
  key: 'group/name',
  get: ({ get }) => get(groupAtom).name,
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
