import { useCallback, useRef } from 'react';
import { GroupElement, ElementsTransformEventArgs } from '@thive/canvas';
import { Vector3 } from 'three';

import { useFloorPlanStore, useLayoutStore } from '../store';
import { HistoryManager } from '../utils';
import { useSaveData } from '.';

export const useTransformEventListeners = () => {
  const initialCenter = useRef<Vector3>();
  const { save } = useSaveData();

  const onTransform = useCallback((e: ElementsTransformEventArgs) => {
    const groupElement = e.elements.find(
      (item) => item.id === useFloorPlanStore.getState().groupId,
    ) as GroupElement;

    if (groupElement) {
      useLayoutStore.getState().updateGroupTransformation(groupElement);
    }
  }, []);

  const onTransformStart = useCallback((e: ElementsTransformEventArgs) => {
    const groupElement = e.elements.find(
      (item) => item.id === useFloorPlanStore.getState().groupId,
    ) as GroupElement;

    if (groupElement) {
      initialCenter.current = groupElement.position;
    }
  }, []);

  const onTransformEnd = useCallback(
    (e: ElementsTransformEventArgs) => {
      const groupElement = e.elements.find(
        (item) => item.id === useFloorPlanStore.getState().groupId,
      ) as GroupElement;

      if (groupElement) {
        HistoryManager.track(
          'Floor plan moved',
          groupElement.position,
          initialCenter.current,
          (center: Vector3) => {
            useLayoutStore.getState().setCenterOffset(center);
            save();
          },
        );

        save();
      }

      initialCenter.current = null;
    },
    [save],
  );

  return {
    onTransformStart,
    onTransform,
    onTransformEnd,
  };
};
