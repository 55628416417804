import { selector } from 'recoil';

import { valueCheck } from '@/helpers/input';
import { WALL_MIN_WIDTH } from '@/modules/common/constants/shapes';
import { findParameterValue } from '@/modules/common/helpers/shapes';
import { isWallShape } from '@/modules/common/types/guards';
import { MaterialType } from '@/modules/common/types/shapes';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { shapeParameter } from '@recoil/shape';
import { selectedShapesState } from '@recoil/shapes/selected';
import { unitConverterSelector } from '@recoil/workspace';

const MIN_SHAPE_HEIGHT = 5;
const MAX_SHAPE_HEIGHT = 5000;

export const wallsWidthState = selector<number>({
  key: 'propertyPanelWallsWidthState',
  get: ({ get }) => findParameterValue(get(selectedShapesState), 'width'),
  set: ({ get, set }, value: number) => {
    const valueInMillimeter = get(unitConverterSelector(value as number));
    get(selectedShapesState).forEach((shape) => {
      if (!isWallShape(shape)) return;
      if (
        !valueInMillimeter ||
        Number.isNaN(valueInMillimeter) ||
        valueInMillimeter < WALL_MIN_WIDTH
      ) {
        const newParams = {
          ...shape.parameters,
          width: WALL_MIN_WIDTH,
        };
        set(shapeParameter(shape.id), newParams);
      } else {
        const newParams = {
          ...shape.parameters,
          width: valueInMillimeter,
        };
        set(shapeParameter(shape.id), newParams);
      }
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const wallsHeightState = selector<number>({
  key: 'propertyPanelWallsHeightState',
  get: ({ get }) => findParameterValue(get(selectedShapesState), 'height'),
  set: ({ get, set }, value: number) => {
    value = valueCheck(value);
    const valueInMillimeter = get(unitConverterSelector(value));

    if (valueInMillimeter < MIN_SHAPE_HEIGHT) {
      throw new Error('Value is too small');
    }

    if (valueInMillimeter > MAX_SHAPE_HEIGHT) {
      throw new Error('Value is to big');
    }

    get(selectedShapesState).forEach((shape) => {
      if (!isWallShape(shape)) return;

      const { id, parameters } = shape;

      set(shapeParameter(id), {
        ...parameters,
        height: valueInMillimeter,
      });
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const wallsMaterialState = selector<MaterialType>({
  key: 'propertyPanelWallsMaterialState',
  get: ({ get }) => findParameterValue(get(selectedShapesState), 'material'),
  set: ({ get, set }, material: MaterialType) => {
    get(selectedShapesState).forEach((shape) => {
      if (!isWallShape(shape)) return;

      const { id, parameters } = shape;

      set(shapeParameter(id), {
        ...parameters,
        material,
      });
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
