import { SVGProps, memo } from 'react';

function SvgComponent({ className = '', ...rest }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      style={{ fill: 'currentColor' }}
      className={className}
      {...rest}
    >
      <path
        d='M6.17502 4.17496C6.39381 3.95617 6.69056 3.83325 6.99998 3.83325H19C19.3094 3.83325 19.6061 3.95617 19.8249 4.17496C20.0437 4.39375 20.1666 4.6905 20.1666 4.99992V16.9999C20.1666 17.3093 20.0437 17.6061 19.8249 17.8249C19.6061 18.0437 19.3094 18.1666 19 18.1666H17.5V18.9999C17.5 19.3093 17.3771 19.6061 17.1583 19.8249C16.9395 20.0437 16.6427 20.1666 16.3333 20.1666H4.99998C4.69056 20.1666 4.39381 20.0437 4.17502 19.8249C3.95623 19.6061 3.83331 19.3093 3.83331 18.9999V7.66659C3.83331 7.35717 3.95623 7.06042 4.17502 6.84163C4.39381 6.62284 4.69056 6.49992 4.99998 6.49992H5.83331V4.99992C5.83331 4.6905 5.95623 4.39375 6.17502 4.17496ZM5.83331 7.49992H4.99998C4.95578 7.49992 4.91338 7.51748 4.88213 7.54873C4.85087 7.57999 4.83331 7.62238 4.83331 7.66659V18.9999C4.83331 19.0441 4.85087 19.0865 4.88213 19.1178C4.91338 19.149 4.95578 19.1666 4.99998 19.1666H16.3333C16.3775 19.1666 16.4199 19.149 16.4512 19.1178C16.4824 19.0865 16.5 19.0441 16.5 18.9999V18.1666H6.99998C6.69056 18.1666 6.39381 18.0437 6.17502 17.8249C5.95623 17.6061 5.83331 17.3093 5.83331 16.9999V7.49992ZM6.99998 17.1666C6.95578 17.1666 6.91338 17.149 6.88213 17.1178C6.85087 17.0865 6.83331 17.0441 6.83331 16.9999V4.99992C6.83331 4.95572 6.85087 4.91332 6.88213 4.88207C6.91338 4.85081 6.95578 4.83325 6.99998 4.83325H19C19.0442 4.83325 19.0866 4.85081 19.1178 4.88207C19.1491 4.91332 19.1666 4.95572 19.1666 4.99992V16.9999C19.1666 17.0441 19.1491 17.0865 19.1178 17.1178C19.0866 17.149 19.0442 17.1666 19 17.1666H6.99998Z'
        fillRule='evenodd'
        clipRule='evenodd'
      />
      <path
        d='M13 8C13.2761 8 13.5 8.00007 13.5 8.00007V10.5L16 10.5C16 10.5 16 10.7239 16 11C16 11.2761 16 11.5 16 11.5H13.5L13.5 14C13.5 14 13.2761 14 13 14C12.7239 14 12.5 14 12.5 14L12.5 11.5H10C10 11.5 10 11.2761 10 11C10 10.7239 10 10.5 10 10.5L12.5 10.5V8.00007C12.5 8.00007 12.7239 8 13 8Z'
        fillRule='evenodd'
        clipRule='evenodd'
      />
    </svg>
  );
}

const DuplicatePlusIcon = memo(SvgComponent);
export default DuplicatePlusIcon;
