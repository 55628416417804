import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

import { useAutoSave } from '@/modules/floorplan';
import { getScopedFlowSelector } from '@modules/flows/helpers';
import { FlowScope, LayoutFlow } from '@modules/flows/types';
import { FLOW_MIN } from '@modules/simulation';

export const useFlowUpdaters = (flowId: string, flowScope: FlowScope) => {
  const { t } = useTranslation();
  const { save } = useAutoSave();
  
  const updateFlow = useRecoilCallback(
    ({ set }) =>
      (value: Partial<LayoutFlow>) => {
        const flowSelector = getScopedFlowSelector(flowScope);
        set(flowSelector(flowId), (state) => ({ ...state, ...value }));
        save()
      },
    [flowScope, flowId, save],
  );

  const onFlowLoadsChange = useRecoilCallback(
    ({ snapshot }) =>
      async (newTotalNumLoads: number) => {
        if (newTotalNumLoads < FLOW_MIN) {
          return { error: t('common:minimum_value', { minValue: FLOW_MIN }) };
        }

        updateFlow({ totalNumLoads: newTotalNumLoads });
      },
    [updateFlow, t],
  );

  const onFlowVehicleLimitChange = useRecoilCallback(
    () =>
      async (newVehicleLimit: number) => {
        updateFlow({ vehicleLimit: newVehicleLimit });
      },
    [updateFlow, t],
  );

  return {
    updateFlow,
    onFlowLoadsChange,
    onFlowVehicleLimitChange
  };
};
