import { useCallback, useContext } from 'react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { InsightMetrics } from '../types';

const useTrackTiming = () => {
  const appInsights = useContext(AppInsightsContext);

  return useCallback(
    (eventName: InsightMetrics, startTime: number, endTime?: number) => {
      appInsights.trackEvent({
        name: eventName,
        measurements: {
          duration: (endTime || performance.now()) - startTime,
        },
      });
    },

    [appInsights],
  );
};

export { useTrackTiming };
