import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../../common';
import { showRelatedFlowsVisibilityOptionSelector } from './showRelatedFlowsVisibilityOptionSelector';
import { showPasteOptionSelector } from './showPasteOptionSelector';
import { showGroupOptionSelector } from './showGroupOptionSelector';

export const hasOptionsSelector = selector({
  key: 'contextMenu/hasOptions',
  get: ({ get }) =>
    [
      showPasteOptionSelector,
      showGroupOptionSelector,
      showRelatedFlowsVisibilityOptionSelector,
    ].some((selector) => get(selector)),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
