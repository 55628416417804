import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { useFloorPlanState } from '@/modules/floorplan';
import {
  useRunPreValidation,
  PrevalidationDebounceMs,
} from '@/modules/floorplanValidation/clientSide';
import { HistoryManager } from '@/store/recoil/history';
import historyAtom from '@/store/recoil/history/atom';
import { RedoButton } from '@/components/Toolbar3/RedoButton';
import { UndoButton } from '@/components/Toolbar3/UndoButton';

export const UndoRedoSalesBtns = ({ disabled }) => {
  const historyInfo = useRecoilValue(historyAtom);
  const { saveFloorPlan } = useFloorPlanState();
  const { runPreValidation } = useRunPreValidation(PrevalidationDebounceMs.INPUT);

  const undo = useCallback(() => {
    HistoryManager.undo();
    saveFloorPlan();
    runPreValidation();
  }, [saveFloorPlan, runPreValidation]);

  const redo = useCallback(() => {
    HistoryManager.redo();
    saveFloorPlan();
    runPreValidation();
  }, [saveFloorPlan, runPreValidation]);

  return (
    <>
      <UndoButton disabled={disabled || !historyInfo.hasUndo} onClick={undo} />
      <RedoButton disabled={disabled || !historyInfo.hasRedo} onClick={redo} />
    </>
  );
};
