import { TFunction } from 'i18next';
import { format } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import { DataGrid, GridColDef, GridComparatorFn, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { useNavigation } from '@/modules/common/hooks';
import { timeAgo } from '../common/helpers/date';
import { selectedFloorplanIdsState } from './store';
import { floorPlanGroupSelector, floorPlanGroupsSelector } from './store/floorPlanGroup';
import { Order, SortingCriteria } from '@/modules/floorplanAdmin/sortingMenu/constants';
import { UserPreferenceName } from '@/modules/userPreferences';
import { useUserPreference, useUpdateUserPreferences } from '@/modules/userPreferences/hooks';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  '& .MuiDataGrid-columnHeaders': {
    border: 0,
    color: theme.palette.neutral.main,
    fontSize: '10px',
    fontWeight: '400',
  },
  '& .MuiDataGrid-row': {
    marginBottom: '8px',
  },
  '& .MuiDataGrid-row:hover, .MuiDataGrid-row.Mui-selected': {
    backgroundColor: theme.palette.primary.light,
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-iconButtonContainer .MuiIconButton-root': {
    color: theme.palette.neutral.main,
  },
  '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted):hover .MuiDataGrid-sortIcon':
    {
      opacity: 1,
    },
  '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
    border: 0,
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: 0,
  },
  '& .MuiPaginationItem-root': {
    borderRadius: 0,
  },
  '& .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },
}));

const lastModifyComparator: GridComparatorFn<Date> = (v1, v2) =>
  new Date(v1).getTime() - new Date(v2).getTime();

const buildColumns = (t: TFunction): GridColDef[] => [
  {
    field: SortingCriteria.NAME,
    sortingOrder: ['asc', 'desc'],
    headerName: t('common:sorting.case_name', 'Case Name'),
    cellClassName: 'caseNameCol',
    flex: 1,
    minWidth: 150,
  },
  {
    field: SortingCriteria.LAST_MODIFIED_BY,
    sortingOrder: ['asc', 'desc'],
    headerName: t('common:sorting.option.last_modified_by', 'Modified by'),
    flex: 0.5,
  },
  {
    field: SortingCriteria.LAST_MODIFIED,
    sortingOrder: ['desc', 'asc'],
    headerName: t('common:sorting.option.last_modified', 'Last modified'),
    renderCell: (params: GridRenderCellParams<Date>) => timeAgo(params.value),
    sortComparator: lastModifyComparator,
    flex: 0.5,
    width: 100,
  },
  {
    field: SortingCriteria.CREATED,
    sortingOrder: ['desc', 'asc'],
    headerName: t('common:sorting.option.date_created', 'Created'),
    flex: 0.5,
    width: 100,
    renderCell: (params: GridRenderCellParams<Date>) => format(params.value, 'MMM d, yyyy HH:mm'),
  },
];

type Props = {
  floorPlanGroupIds: string[];
};

export const FloorplanList = ({ floorPlanGroupIds }: Props) => {
  const { t } = useTranslation();
  const floorPlanGroups = useRecoilValue(floorPlanGroupsSelector(floorPlanGroupIds));
  const [selectedFloorplanIds, setSelectedFloorplanIds] = useRecoilState(selectedFloorplanIdsState);
  const { goToBuilder } = useNavigation();
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const sortCriteria = useUserPreference(UserPreferenceName.FLOORPLAN_ADMIN_SORT_BY);
  const sortOrder = useUserPreference(UserPreferenceName.FLOORPLAN_ADMIN_SORT_ORDER);
  const { updateUserPreference } = useUpdateUserPreferences();

  useEffect(() => {
    setColumns(buildColumns(t));
  }, [t]);

  const rows = useMemo(
    () =>
      floorPlanGroups.map((item) => ({
        id: item.id,
        [SortingCriteria.NAME]: item.name,
        [SortingCriteria.LAST_MODIFIED_BY]: item.lastUpdatedBy,
        [SortingCriteria.LAST_MODIFIED]: item.lastUpdated,
        [SortingCriteria.CREATED]: item.created,
      })),
    [floorPlanGroups],
  );

  const handleRowClick = useRecoilCallback(
    ({ snapshot }) =>
      async (row: any) => {
        const group = await snapshot.getPromise(floorPlanGroupSelector(row.id));
        goToBuilder(group.id, group.floorPlanId);
      },
    [goToBuilder],
  );

  const handleRowSelectionModelChange = useCallback(
    (newRowSelectionModel: string[]) => {
      setSelectedFloorplanIds(new Set(newRowSelectionModel));
    },
    [setSelectedFloorplanIds],
  );

  const onChangeSortModel = useCallback(
    (sortModel: GridSortModel) => {
      if (!sortModel.length) {
        console.warn('Sort order was unexpectedly reset to default');
        return;
      }

      const newSortModel = sortModel[0];
      const { field, sort: order } = newSortModel;
      const newOrder = order === 'asc' ? Order.ASC : Order.DESC;

      if (sortCriteria !== field) {
        updateUserPreference(
          UserPreferenceName.FLOORPLAN_ADMIN_SORT_BY,
          field as SortingCriteria,
          false,
        );
      }
      if (sortOrder !== newOrder) {
        updateUserPreference(UserPreferenceName.FLOORPLAN_ADMIN_SORT_ORDER, newOrder);
      }
    },
    [updateUserPreference, sortCriteria, sortOrder],
  );

  return (
    <Box style={{ width: '100%', height: 'calc(100vh - 230px)' }}>
      <StyledDataGrid
        // loading // TODO: add loading state
        rows={rows}
        columns={columns}
        disableColumnMenu
        checkboxSelection
        onRowDoubleClick={handleRowClick}
        onRowSelectionModelChange={handleRowSelectionModelChange}
        rowSelectionModel={Array.from(selectedFloorplanIds)}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 50,
            },
          },
          sorting: {
            sortModel: [{ field: sortCriteria, sort: sortOrder === Order.ASC ? 'asc' : 'desc' }],
          },
        }}
        pageSizeOptions={[50]}
        onSortModelChange={onChangeSortModel}
      />
    </Box>
  );
};
