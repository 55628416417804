import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../../store/recoil/common';
import dwgAtom from './atom';

const referenceSelected = selector<boolean>({
  key: 'reference/selected',
  get: ({ get }) => get(dwgAtom).selected,
  set: ({ get, set }, newValue) => set(dwgAtom, { ...get(dwgAtom), selected: newValue }),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default referenceSelected;
