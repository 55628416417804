import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import { Simulation } from '../../helpers/types';
import { simulationIdsAtom, simulationAtom } from './simulationsAtom';

export const numberSelectedSimulationsSelector = selector<Simulation[]>({
  key: 'simulations/simulations/numberSelected',
  get: ({ get }) =>
    get(simulationIdsAtom)
      .map((item) => get(simulationAtom(item)))
      .filter((item) => item.isSelected),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});