import { memo } from 'react';
import { Stack, Typography } from '@mui/material';

const ContentRowComponent = ({ label, value, unit }) => (
  <Stack
    direction='row'
    alignItems='start'
    justifyContent='space-between'
    sx={{ height: '16px', width: '100%' }}
  >
    <Typography
      className='label'
      align='left'
      sx={{ fontSize: '10px', lineHeight: '160%', fontWeight: '400', color: 'neutral.dark' }}
    >
      {label}
    </Typography>
    <Typography
      className='value'
      align='left'
      sx={{
        fontSize: '10px',
        lineHeight: '160%',
        fontWeight: '400',
        color: 'shades.dark',
      }}
    >
      {value}
      {unit}
    </Typography>
  </Stack>
);

export const ContentRow = memo(ContentRowComponent);
