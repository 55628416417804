import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../common';
import inputAtom, { MouseIndex } from './atom';

const mouseKeysInputState = selector<MouseIndex>({
  key: 'input/mouseKeysState',
  get: ({ get }) => get(inputAtom).mouseKeysDown,
  set: ({ get, set }, newValue) => {
    set(inputAtom, { ...get(inputAtom), mouseKeysDown: newValue });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default mouseKeysInputState;
