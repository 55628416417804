import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { selector, selectorFamily } from 'recoil';
import { AreaShape, PositionShape } from '../../../../../store/recoil/shape';
import { prevalidationState } from '../state';

export const flowlessAreasSelector = selector({
  key: 'prevalidation/flowlessAreas',
  get: ({ get }) => get(prevalidationState).flowlessAreas,
  set: ({ set }, newItems: (AreaShape | PositionShape)[]) =>
    set(prevalidationState, (current) => ({ ...current, flowlessAreas: newItems })),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export const isFlowlessAreaSelector = selectorFamily({
  key: 'shape/byId/isFlowlessArea',
  get:
    (shapeId: string) =>
    ({ get }) =>
      get(flowlessAreasSelector).some((item) => item.id === shapeId),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
