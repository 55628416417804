import { Stack } from '@mui/system';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import MarginHorizontalIcon from '@/assets/icons/MarginHorizontalIcon';
import MarginVerticalIcon from '@/assets/icons/MarginVerticalIcon';
import {
  roadMarginToCrossingSelector,
  roadMinMarginSelector,
} from '@/components/PropertiesPanel/store/highway';
import { convertMetricUnit } from '@/helpers/units/metric';
import { HIGHWAY_MIN_MARGIN, HIGHWAY_ROUTING_POINT_MARGIN_TO_CROSSING_MIN } from '@/modules/common/constants/shapes';
import { modeSelector } from '@/modules/common/store/workspace';
import { Unit, WorkspaceMode } from '@/modules/common/types/general';
import { useAutoSave } from '@/modules/floorplan';
import { UserPreferenceName } from '@/modules/userPreferences';
import { useUpdateUserPreferences } from '@/modules/userPreferences/hooks';
import { unitSelector } from '@/store/recoil/workspace';
import { NumberInput } from '@common/components/inputs';
import { CONTEXT, contextState } from '@recoil/input';

function HighwayMargin() {
  const [minMargin, setMinMargin] = useRecoilState(roadMinMarginSelector);
  const [marginToCrossing, setMarginToCrossing] = useRecoilState(roadMarginToCrossingSelector);
  const unit = useRecoilValue(unitSelector);
  const mode = useRecoilValue(modeSelector);
  const { save } = useAutoSave();
  const { updateUserPreference } = useUpdateUserPreferences();
  const disabled = mode !== WorkspaceMode.EDITABLE;
  const setContext = useSetRecoilState(contextState);
  const { t } = useTranslation(['interface']);

  const onMinMarginChange = useCallback(
    (value: number) => {
      if (value >= convertMetricUnit(HIGHWAY_MIN_MARGIN, Unit.MILLIMETERS, unit)) {
        setMinMargin(value);
        save();
        updateUserPreference(UserPreferenceName.HIGHWAY_MARGIN, value);
      }
    },
    [setMinMargin, unit, save, updateUserPreference],
  );

  const onMarginToCrossingChange = useCallback(
    (value: number) => {
      if (
        value >=
        convertMetricUnit(HIGHWAY_ROUTING_POINT_MARGIN_TO_CROSSING_MIN, Unit.MILLIMETERS, unit)
      ) {
        setMarginToCrossing(value);
        save();
        updateUserPreference(UserPreferenceName.HIGHWAY_MARGIN_TO_CROSSING, value);
      }
    },
    [setMarginToCrossing, unit, save, updateUserPreference],
  );

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      spacing={1}
      sx={{ width: '100%' }}
    >
      <NumberInput
        value={minMargin}
        disabled={disabled}
        prefix={<MarginHorizontalIcon />}
        onFocus={() => setContext(CONTEXT.PROPERTYPANEL)}
        onBlur={() => setContext(CONTEXT.WORKSPACE)}
        onChange={onMinMarginChange}
        tooltip={t('properties.highway.tooltips.margin')}
      />
      <NumberInput
        value={marginToCrossing}
        disabled={disabled}
        prefix={<MarginVerticalIcon />}
        onFocus={() => setContext(CONTEXT.PROPERTYPANEL)}
        onBlur={() => setContext(CONTEXT.WORKSPACE)}
        onChange={onMarginToCrossingChange}
        tooltip={t('properties.highway.tooltips.routing_point_margin_to_crossing')}
      />
    </Stack>
  );
}

export default HighwayMargin;
