import { selectorFamily } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@/store/recoil/common';
import { vehicleWidthSelector } from '../vehicleWidthSelector';

export const supportedVehiclesWidthSelector = selectorFamily<number, string[]>({
  key: 'vehicles/supported/width',
  get:
    (supportedVehicleIds: string[]) =>
    ({ get }) => {
      const widths = supportedVehicleIds.map((id) => get(vehicleWidthSelector(id)));
      return Math.max(...widths);
    },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
