import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Button, Fade, IconButton, Paper, Popper, Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { ToolButton } from '@/components/Toolbar3/ToolButton';
import { ShapeType } from '@/modules/common/types/shapes';
import { ToolState } from '@/modules/common/types/tools';
import { TemplateType } from '@/modules/common/types/templating';
import { keyboardState } from '@/store/recoil/input';
import { shouldToolMenuItemBeHighlightedSelector, toolButtonState } from '@/store/recoil/tool';
import { findDescendantToolKeyCodes } from '@/store/recoil/tool/helpers';
import { ToolItem, ToolMenuItem } from '@/store/recoil/tool/types';
import { toolState } from '@/store/recoil/workspace';
import { Theme } from '@modules/common/types/general';
import { UserPreferenceName } from '@modules/userPreferences';
import { useUserPreference } from '@modules/userPreferences/hooks';
import getIcon from './getIcons';

type Props = {
  items: ToolMenuItem[];
  initialBtnIconName?: string;
  onItemClick?: (tool: ShapeType | TemplateType) => void | undefined;
  menuBtnLabel?: string;
  disabled?: boolean;
};

const ToolMenu = ({
  items,
  onItemClick,
  initialBtnIconName,
  menuBtnLabel,
  disabled = false,
}: Props) => {
  const theme = useUserPreference(UserPreferenceName.GENERAL_THEME);
  const selected = useRecoilValue(shouldToolMenuItemBeHighlightedSelector(items));
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [iconName, setIconName] = useState<string>(initialBtnIconName);
  const toolBtn = useRecoilValue(toolButtonState);
  const tool = useRecoilValue(toolState);
  const keyboard = useRecoilValue(keyboardState);

  const openSubmenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (disabled) {
        return;
      }
      setAnchorEl(event.currentTarget);
      setOpen(true);
    },
    [disabled],
  );

  const closeSubmenu = useCallback(() => {
    if (disabled) {
      return;
    }
    setOpen(false);
  }, [disabled]);

  const onItemClickInner = useCallback(
    (tool: ShapeType | TemplateType, icon?: string) => {
      if (icon) setIconName(icon);
      onItemClick?.(tool);
      setOpen(false);
    },
    [onItemClick],
  );

  useEffect(() => {
    if (open && (findDescendantToolKeyCodes(items).includes(keyboard) || tool === ToolState.PAN))
      setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyboard, open, tool]);

  useEffect(() => {
    if (menuBtnLabel) return;

    const selectedChildToolIcon = items
      .filter((item): item is ToolItem => item.type == 'tool')
      .find((item) => item.id === toolBtn)?.icon;

    if (selectedChildToolIcon) setIconName(selectedChildToolIcon);
  }, [menuBtnLabel, items, toolBtn]);

  return (
    <div onMouseEnter={openSubmenu} onMouseLeave={closeSubmenu}>
      {menuBtnLabel ? (
        <Button
          onClick={openSubmenu}
          startIcon={iconName ? getIcon(iconName) : undefined}
          disabled={disabled !== undefined ? disabled : false}
          type={(selected ? 'Selected' : 'Deselected') as any}
        >
          {/* {t(label)} */}
          <Trans i18nKey={menuBtnLabel} ns='interface' />
          <ArrowRightIcon sx={{ marginLeft: 'auto' }} />
        </Button>
      ) : (
        <IconButton
          disabled={disabled}
          type={(selected ? 'Selected' : 'Deselected') as any}
          disableRipple
        >
          {getIcon(iconName)}
        </IconButton>
      )}
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement='right'
        transition
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 12],
            },
          },
        ]}
        sx={{
          borderRadius: '4px',
          minWidth: 208,
          zIndex: 99999,
        }}
        className={theme === Theme.DARK ? 'submenuBgDark dark' : 'submenuBg'}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={{ appear: 500, enter: 100, exit: 200 }}>
            <Paper elevation={1}>
              <Stack
                sx={{
                  padding: '4px',
                  gap: '6px',
                  borderRadius: '4px',
                  ...(theme === Theme.DARK ? { border: '1px solid white' } : {}),
                }}
              >
                {renderMenuItems(items, onItemClickInner)}
              </Stack>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default ToolMenu;

const renderMenuItems = (
  items: ToolMenuItem[],
  onItemClick: (tool: ShapeType | TemplateType, icon?: string) => void,
) => (
  <>
    {items.map((item) =>
      item.type === 'menu' ? (
        <ToolMenu
          key={item.label}
          menuBtnLabel={item.label}
          items={item.items}
          onItemClick={onItemClick}
          initialBtnIconName={item.icon}
        />
      ) : (
        <ToolButton
          onSelect={onItemClick}
          type={item.id}
          regularBtn
          disabled={item.disabled}
          key={item.id}
          icon={item.icon}
          tooltipLabel={
            <Trans
              i18nKey={item.tooltipLabel}
              ns='interface'
              components={{
                s: <span style={{ marginLeft: 'auto' }} />,
              }}
            >
              Charging Position <span>H</span>
            </Trans>
          }
        />
      ),
    )}
  </>
);
