import { Theme } from '@/modules/common/types/general';
import { UserPreferenceName } from '@/modules/userPreferences';
import { useUserPreference } from '@/modules/userPreferences/hooks';
import { CircularProgress, Stack, Typography } from '@mui/material';

type Props = {
  variant: string;
  content: any;
};

export function Panel({ variant, content }: Props) {
  const theme = useUserPreference(UserPreferenceName.GENERAL_THEME);
  
  return (
    <Stack
      direction='column'
      justifyContent='center'
      alignItems='center'
      spacing={0}
      sx={{
        width: 260,
        minHeight: 50,
        backgroundColor: theme === Theme.DARK ? 'neutral.darker' : 'shades.light',
        border: '1px solid',
        borderColor: theme === Theme.DARK ? 'shades.light' : 'neutral.lighter',
        '&:hover': { cursor: 'default' },
      }}
    >
      {variant === 'text' && (
        <Typography aria-label='text' align='center' sx={{color: theme === Theme.DARK ? 'shades.light' : 'neutral.darker'}}>
          {content}
        </Typography>
      )}
      {variant === 'loadingAnimation' && <CircularProgress />}
    </Stack>
  );
}
