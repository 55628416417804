import {
  ErrorNotification,
  NotificationType,
  SupportedLanguages,
} from '@/modules/Notifications/helpers/types';
import { useConfig } from '@/modules/common/hooks';
import { useAxios } from '@/modules/api/hooks/useAxios';
import { useCallback, useMemo } from 'react';
import i18next from 'i18next';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';

export const useErrorMessageApi = () => {
  const { t } = useTranslation('errors');
  const { api } = useConfig();
  const scopes = useMemo(() => [api.errorMessageService.scope], [api.errorMessageService.scope]);
  const axios = useAxios({
    baseURL: `${api.errorMessageService.url}`,
    scopes,
  });

  const getErrorDetails = useCallback(
    async (code: string): Promise<ErrorNotification> => {
      const id = uuid();
      try {
        if (!code) {
          return Promise.reject();
        }
        const activeLanguage = i18next.language as SupportedLanguages;
        const response = await axios.get(`/retrieve_error_description/${code}/${activeLanguage}`);

        return {
          ...response.data,
          id,
          timestamp: new Date(),
          type: NotificationType.ERROR,
          title: t('notifications.default.title'),
        };
      } catch (error) {
        return {
          id,
          timestamp: new Date(),
          read: false,
          title: t('notifications.default.title'),
          description: t('notifications.default.description'),
          type: NotificationType.ERROR,
        };
      }
    },
    [t, axios],
  );

  return { getErrorDetails };
};
