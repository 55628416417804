import { ObjectValues } from '@/helpers/types';

export const KEYCODE = {
  SPACE: 'Space',
  SHIFT: 'Shift',
  DELETE: 'Delete',
  BACKSPACE: 'Backspace',
  CONTROL_LEFT: 'ControlLeft',
  CONTROL_RIGHT: 'ControlRight',
  CONTROL: 'Control',
  ESCAPE: 'Escape',
  ENTER: 'Enter',
  SAVE: 'Save',
  V: 'KeyV',
  W: 'KeyW',
  H: 'KeyH',
  O: 'KeyO',
  P: 'KeyP',
  S: 'KeyS',
  C: 'KeyC',
  D: 'KeyD',
  I: 'KeyI',
  Z: 'KeyZ',
  Y: 'KeyY',
  L: 'KeyL',
  R: 'KeyR',
  G: 'KeyG',
  U: 'KeyU',
  F: 'KeyF',
  NONE: '',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_UP: 'ArrowUp',
  ARROW_RIGHT: 'ArrowRight',
  ARROW_DOWN: 'ArrowDown',
  COPY: 'Copy',
  PASTE: 'PASTE',
  UNDO: 'Undo',
  REDO: 'Redo',
  SHIFT_W: 'ShiftW',
  SHIFT_H: 'ShiftH',
  SHIFT_P: 'ShiftP',
  SHIFT_R: 'ShiftR',
  SHIFT_C: 'ShiftC',
  CTRL_D: 'CtrlD',
  GROUP: 'Group',
  UNGROUP: 'Ungroup',
} as const;
export type KeyCodeType = ObjectValues<typeof KEYCODE>;

export const ARROWS = [
  KEYCODE.ARROW_LEFT,
  KEYCODE.ARROW_UP,
  KEYCODE.ARROW_RIGHT,
  KEYCODE.ARROW_DOWN,
] as const;
export type Arrows = typeof ARROWS[number];

export const CONTEXT = {
  WORKSPACE: 'Workspace',
  PROPERTYPANEL: 'Propertypanel',
  SETTINGS: 'Settings',
  NONE: '',
} as const;
export type InputContext = ObjectValues<typeof CONTEXT>;
