import { selector } from 'recoil';
import { selectedShapesState } from '@recoil/shapes/selected';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../common';
import { hasFlowInteractivity } from '@/modules/flows/consts';

const selectionContainsFlowInteractivity = selector({
  key: 'flows/layout/selectionContainsFlowReceptivity',
  get: ({ get }) => get(selectedShapesState).some((shape) => hasFlowInteractivity(shape.type)),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default selectionContainsFlowInteractivity;
