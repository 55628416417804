import { memo } from 'react';

function Paste({ className = '', ...rest }) {
  return (
    <svg
      height="24" viewBox="0 96 960 960" width="24"
      style={{ fill: 'currentColor' }}
      className={className}
      {...rest}
    >
      <path d="M224.615 896q-26.846 0-45.731-18.884Q160 858.231 160 831.385v-510.77q0-26.846 18.884-45.731Q197.769 256 224.615 256h185.462q5.615-23.462 24.923-39.423 19.308-15.962 45-15.962 26.154 0 45.346 15.962 19.193 15.961 24.808 39.423h185.231q26.846 0 45.731 18.884Q800 293.769 800 320.615v510.77q0 26.846-18.884 45.731Q762.231 896 735.385 896h-510.77Zm0-40h510.77q9.23 0 16.923-7.692Q760 840.615 760 831.385v-510.77q0-9.23-7.692-16.923Q744.615 296 735.385 296H640v89.231H320V296h-95.385q-9.23 0-16.923 7.692Q200 311.385 200 320.615v510.77q0 9.23 7.692 16.923Q215.385 856 224.615 856ZM480 305.231q13.923 0 23.115-9.193 9.193-9.192 9.193-23.115 0-13.923-9.193-23.115-9.192-9.193-23.115-9.193-13.923 0-23.115 9.193-9.193 9.192-9.193 23.115 0 13.923 9.193 23.115 9.192 9.193 23.115 9.193Z"/>
    </svg>
  );
};

const PasteIcon = memo(Paste);
export default PasteIcon;
