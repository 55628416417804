import { Accordion } from '@common/components/Accordion';
import { Stack, TextField } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { use3TE25LayoutStore } from '../../store';
import { useSelectionStore } from '../../store/useSelectionStore';

export default function SearchUnit() {
  const { t } = useTranslation(['interface']);

  const handleKeydown = useCallback((e) => {
    if (e.keyCode == 13) {
      const unitId = parseInt(e.target.value);
      if (Number.isNaN(unitId)) return;

      const { setUnitIds } = useSelectionStore.getState();
      const { unitShapes } = use3TE25LayoutStore.getState();
      if (!unitShapes.has(unitId.toString())) return;
      setUnitIds([unitId.toString()]);
    }
  }, []);

  return (
    <Accordion
      title={t('interface:3te25.search_unit.title')}
      titleSx={{ textTransform: 'capitalize' }}
      defaultExpanded
    >
      <Stack direction='column' spacing={1}>
        <TextField label={t('interface:3te25.search_unit.unit_id')} onKeyDown={handleKeydown} />
      </Stack>
    </Accordion>
  );
}
