import { atom } from 'recoil';

import { SimulationDraft } from '../../helpers/types';

/**
 * Id of the currently editable simulation
 */
export const simulationIdAtom = atom<string>({
  key: 'simulations/draft/idAtom',
  default: null,
});

/**
 * The currently editable simulation
 */
export const simulationAtom = atom<SimulationDraft>({
  key: 'simulations/draft/atom',
  default: null,
});
