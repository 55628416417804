import { useCallback, useEffect, useRef } from 'react';

export const useDebouncedCallback = <A extends any[]>(
  callback: (...args: A) => void,
  wait: number,
  abortOnUnmount = true,
) => {
  // track args & timeout handle between calls
  const argsRef = useRef<A>();
  const timeout = useRef<ReturnType<typeof setTimeout>>();

  const cleanup = useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  }, []);

  // clean up the timer when our consuming component gets unmounted, except when opted out
  useEffect(
    () => () => {
      if (abortOnUnmount) {
        cleanup();
      }
    },
    [cleanup, abortOnUnmount],
  );

  return useCallback(
    (...args: A) => {
      // capture latest args
      argsRef.current = args;

      // clear debounce timer
      cleanup();

      // start waiting again
      timeout.current = setTimeout(() => {
        if (argsRef.current) {
          callback(...argsRef.current);
        }
      }, wait);
    },
    [callback, wait, cleanup],
  );
};
