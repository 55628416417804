import { selectorFamily } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@/store/recoil/common';
import { vehicleLengthSelector } from '../vehicleLengthSelector';

export const supportedVehiclesLengthSelector = selectorFamily<number, string[]>({
  key: 'vehicles/supported/length',
  get:
    (supportedVehicleIds: string[]) =>
    ({ get }) => {
      const lengths = supportedVehicleIds.map((id) => get(vehicleLengthSelector(id)));
      return Math.max(...lengths);
    },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
