import { theme } from '@/modules/common/components/theme';

const button = {
  width: '100%',
  height: 230,
  backgroundColor: theme.palette.shades.light,
  border: 1,
  padding: 0,
  display: 'flex',
  flexDirection: 'column',

  '&:hover': {
    backgroundColor: theme.palette.shades.light,
    borderColor: theme.palette.primary.main,
  },

  '&:hover .label-box': {
    backgroundColor: theme.palette.primary.light,
    borderColor: theme.palette.primary.main,
  },
};

const labelBox = {
  width: '100%',
  height: '35px',
  paddingRight: 1,
  paddingLeft: 1,
  borderTop: 1,
  backgroundColor: theme.palette.shades.light,
};

export const selectedSx = {
  ...button,
  borderColor: theme.palette.primary.main,

  '.label-box': {
    ...labelBox,
    borderColor: theme.palette.primary.main,
  },

  '.check': {
    color: theme.palette.primary.main,
  },

  '&:hover .check': {
    color: theme.palette.primary.main,
  },
};

export const deSelectedSx = {
  ...button,
  borderColor: theme.palette.neutral.lighter,

  '.label-box': {
    ...labelBox,
    borderColor: theme.palette.neutral.lighter,
  },

  '.check': {
    color: theme.palette.neutral.darkest,
  },

  '&:hover .check': {
    color: theme.palette.neutral.darker,
  },
};
