import { Box3, Vector3 } from 'three';
import { parseXMLDocument } from '@/modules/common/helpers/browser';
import { t } from 'i18next';

import { SevenSenseRectangle, SevenSenseLine, SevenSenseLayout } from '../helpers/types';

export const deserialize = (text: string): SevenSenseLayout => {
  const document = parse(text);
  const svg = Array.from(document.children).at(0);
  const walls: SevenSenseRectangle[] = [];
  const lines: SevenSenseLine[] = [];
  const boundingBox = new Box3();
  const viewBox = svg.getAttribute('viewBox')?.split(' ');

  Array.from(svg.children).forEach((child) => {
    if (child.tagName === 'rect') {
      const rect = parseRect(child);
      const rectBoundingBox = new Box3(
        new Vector3(rect.position.x, rect.position.y - rect.size.y),
        new Vector3(rect.position.x + rect.size.x, rect.position.y),
      );

      boundingBox.union(rectBoundingBox);
      walls.push(rect);
    } else if (child.tagName === 'line') {
      const line = parseLine(child);
      const rectBoundingBox = new Box3(line.p1, line.p2);

      boundingBox.union(rectBoundingBox);
      lines.push(line);
    }
  });

  walls.forEach((wall) => {
    wall.position.y = boundingBox.max.y - wall.position.y - wall.size.y + boundingBox.min.y;
  });

  lines.forEach((line) => {
    line.p1.y = boundingBox.max.y - line.p1.y + boundingBox.min.y;
    line.p2.y = boundingBox.max.y - line.p2.y + boundingBox.min.y;
  });

  return {
    walls,
    lines,
    width: viewBox && viewBox[2] ? parseFloat(viewBox[2]) * 1000 : undefined,
    height: viewBox && viewBox[3] ? parseFloat(viewBox[3]) * 1000 : undefined,
  };
};

export const createImageObject = async (file: File) =>
  new Promise((resolve: (value: HTMLImageElement) => void, reject: (reason: Error) => void) => {
    const image = new Image();
    const reader = new FileReader();
    reader.onload = () => {
      const dataUrl = reader.result;
      image.src = dataUrl as string;
      return resolve(image);
    };
    reader.onerror = () => reject(new Error('Cannot create Image object from file'));
    reader.readAsDataURL(file);
  });

const parse = (text: string) => {
  try {
    return parseXMLDocument(text, 'image/svg+xml');
  } catch (e) {
    throw new Error(t('errors:commissioning.sevensense.unable_to_parse', { cause: e.message }));
  }
};

const parseRect = (rect: Element): SevenSenseRectangle => ({
  position: new Vector3(
    parseNumber(rect.getAttribute('x')),
    parseNumber(rect.getAttribute('y')),
  ).multiplyScalar(1000),
  size: new Vector3(
    parseNumber(rect.getAttribute('width')),
    parseNumber(rect.getAttribute('height')),
  ).multiplyScalar(1000),
  opacity: parseNumber(rect.getAttribute('opacity')),
  color: rect.getAttribute('fill'),
});

const parseLine = (child: Element) => ({
  p1: new Vector3(
    parseNumber(child.getAttribute('x1')),
    parseNumber(child.getAttribute('y1')),
  ).multiplyScalar(1000),
  p2: new Vector3(
    parseNumber(child.getAttribute('x2')),
    parseNumber(child.getAttribute('y2')),
  ).multiplyScalar(1000),
  color: '#000000',
});

const parseNumber = (value: string) => (value === '' ? NaN : Number(value));
