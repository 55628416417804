import { shapesSelector } from '@recoil/shapes';
import { useRecoilCallback } from 'recoil';
import { v4 as uuid } from 'uuid';

import { getShapeId } from '@/modules/connections/common/connectionId';
import { toMap } from '@modules/common/helpers/array';
import { Connection } from '@modules/common/types/connections';
import { filterRelatedConnections } from '@modules/connections/common/helpers';
import { CopyIdMapping } from '@modules/connections/common/types';
import { findConnectionsPositions } from '../helpers/connections';
import { allConnectionIds, allConnectionsSelector, connectionState } from '../store';

export const useCopyConnection = () => {
  const copyConnections = useRecoilCallback(
    ({ set, snapshot }) =>
      async (idMapping: CopyIdMapping) => {
        const oldShapeIds = idMapping.map((item) => item.oldShapeId);
        const newShapeIds = idMapping.map((item) => item.newShapeId);
        const [allConnections, newShapes] = await Promise.all([
          snapshot.getPromise(allConnectionsSelector),
          snapshot.getPromise(shapesSelector(newShapeIds)),
        ]);
        const shapeDict = toMap(newShapes, (item) => item.id);

        const newConnections: Connection[] = filterRelatedConnections(allConnections, oldShapeIds)
          .map((connection) => {
            const fromMapping = idMapping.find((item) => item.oldShapeId === getShapeId(connection.from));
            const toMapping = idMapping.find((item) => item.oldShapeId === getShapeId(connection.to));

            if (!fromMapping || !toMapping) {
              return null;
            }

            const fromShape = shapeDict.get(fromMapping.newShapeId);
            const toShape = shapeDict.get(toMapping.newShapeId);
            const connectionPosition = findConnectionsPositions(fromShape, [toShape]).at(0);

            return {
              ...connection,
              id: uuid(),
              from: connectionPosition.from,
              to: connectionPosition.to,
              position: connectionPosition.position,
            };
          })
          .filter((item) => item !== null);

        newConnections.forEach((item) => set(connectionState(item.id), item));
        set(allConnectionIds, (state) => [...state, ...newConnections.map((item) => item.id)]);
      },
    [],
  );

  return {
    copyConnections,
  };
};
