import { allShapesSelector } from '@/store/recoil/shapes';
import { Connection } from '@modules/common/types/connections';
import {
  isAngledHighwayShape,
  isAreaShape,
  isHighwayShape,
  isPositionShape,
  isProcessAreaTwoEp,
} from '@modules/common/types/guards';
import {
  filterRelatedConnections,
  findRelatedConnection,
} from '@modules/connections/common/helpers';
import { findConnectionsPositions } from '@modules/connections/connections/helpers/connections';
import {
  allConnectionsSelector,
  updateConnectionsSelector,
} from '@modules/connections/connections/store';
import { useRecoilCallback } from 'recoil';
import { v4 as uuid } from 'uuid';
import { useUpdateCrossings } from '../../crossings';

export const useUpdateConnection = () => {
  const { updateCrossings } = useUpdateCrossings();

  // regular connections are generated here
  const updateConnections = useRecoilCallback(
    ({ set, snapshot }) =>
      async (shapeIds: string[]) => {
        const [allConnections, allShapes] = await Promise.all([
          snapshot.getPromise(allConnectionsSelector),
          snapshot.getPromise(allShapesSelector),
        ]);

        const connectionIdsToRemove = new Set<string>();
        const connectionsToAddOrUpdate: Connection[] = [];
        const connectableShapes = allShapes.filter(
          (item) =>
            isAreaShape(item) ||
            isPositionShape(item) ||
            isHighwayShape(item) ||
            isAngledHighwayShape(item) ||
            isProcessAreaTwoEp(item),
        );

        connectableShapes
          .filter((shape) => shapeIds.includes(shape.id))
          .forEach((shape) => {
            const existingShapeConnections = filterRelatedConnections(allConnections, [shape.id]);
            const newAndUpdatedConnections = findConnectionsPositions(shape, connectableShapes).map(
              (connectionPosition) => {
                const existingConnection = findRelatedConnection(
                  existingShapeConnections,
                  connectionPosition.to,
                  connectionPosition.from,
                );

                if (existingConnection) {
                  return {
                    ...existingConnection,
                    position: {
                      x: Math.round(connectionPosition.position.x),
                      y: Math.round(connectionPosition.position.y),
                    },
                    rot: connectionPosition.rot,
                  };
                }

                return {
                  id: uuid(),
                  from: connectionPosition.from,
                  to: connectionPosition.to,
                  inChecked: true,
                  outChecked: true,
                  usePivots: false,
                  position: {
                    x: Math.round(connectionPosition.position.x),
                    y: Math.round(connectionPosition.position.y),
                  },
                  rot: connectionPosition.rot,
                };
              },
            );

            connectionsToAddOrUpdate.push(...newAndUpdatedConnections);

            const connectionsIdsToAddOrUpdate = new Set(
              connectionsToAddOrUpdate.map((item) => item.id),
            );

            existingShapeConnections
              .filter((item) => !connectionsIdsToAddOrUpdate.has(item.id))
              .forEach((item) => connectionIdsToRemove.add(item.id));
          });

        set(updateConnectionsSelector, {
          connections: connectionsToAddOrUpdate,
          connectionIdsToRemove,
        });
      },
    [updateCrossings],
  );

  return {
    updateConnections,
  };
};
