import { useRecoilValue } from 'recoil';
import { memo } from 'react';

import { SnapRenderer } from '@/modules/snapping/components/SnapRenderer';
import { snappingState } from '@/modules/snapping';

const SnappingComponent = () => {
  const state = useRecoilValue(snappingState);

  if (!state?.lines || state.lines.length === 0) return null;

  return <SnapRenderer lines={state.lines} />;
};

export const Snapping = memo(SnappingComponent);
