import { PropertyPanelContainer } from '@/modules/common/components/PropertyPanelContainer';
import { useNavigation } from '@modules/common/hooks';
import { Module } from '@modules/common/types/navigation';
import { Stack } from '@mui/material';
import ConnectionProperty from './submenu/ConnectionProperty';
import LayoutTool from './submenu/LayoutTool';
import SearchUnit from './submenu/SearchUnit';
import UnitProperty from './submenu/UnitProperty';
import Upload from './submenu/Upload';

const stackSx = {
  marginInline: 2,
};

export function PropertyPanel() {
  const { goToModule } = useNavigation();

  return (
    <PropertyPanelContainer
      labelKey='interface:tabs.commissioning.label'
      onCloseClick={() => goToModule(Module.LAYOUT)}
    >
      <Stack sx={stackSx}>
        <Upload />
        <LayoutTool />
        <ConnectionProperty />
        <UnitProperty />
        <SearchUnit />
      </Stack>
    </PropertyPanelContainer>
  );
}
