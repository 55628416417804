import { useCallback } from 'react';
import { CanvasZoomEventArgs } from '@thive/canvas';

import { useKollmorgenStore, useNavitechStore } from '../store';

export const useZoomEventListeners = () => {
  const onCameraZoom = useCallback((e: CanvasZoomEventArgs) => {
    useKollmorgenStore.getState().scale(e.value);
    useNavitechStore.getState().scale(e.value);
  }, []);
  return {
    onCameraZoom,
  };
};
