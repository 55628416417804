import { transformConnectionToRelativePerspective } from '@modules/common/helpers/connections';
import {
  createProcessRelatedFlowsMap,
  isArea,
  supportsVehicleTypes,
} from '@modules/common/helpers/shapes';
import { Connection, Crossing, DistantConnection } from '@modules/common/types/connections';
import { isAreaShape, isPositionShape, isProcessAreaTwoEp } from '@modules/common/types/guards';
import { ShapeGroup } from '@modules/common/types/shapeGroup';
import { AreaDirection, DTShape, ProcessAreaOneEp, ShapeType } from '@modules/common/types/shapes';
import { FlowStop, FlowStopType, LayoutFlow } from '@modules/flows/types';
import { AreaShape, HighwayShape, PositionShape } from '@recoil/shape';
import { UnifiedVehicle } from '@/modules/vehicles/types';
import { RequiredElementType, shapeDirectionToDegreesMap } from './constants';
import {
  MissingRequiredElement,
  RoadConnectionsCrossingsCollection,
  ShapeConsDistConsCollection,
} from './types';
import { decodeShapeId } from '@/modules/floorplanService/helpers/mapping/idEncoder';
import { ProcessTwoEPShape } from '@/modules/processTwoEndPoint';

export const areaDirectionsAllPointToARoadConnection = (
  directions: AreaDirection[],
  connectionsToRoads: Connection[],
) =>
  directions.every((dir) => {
    const dirDegrees = mapShapeDirectionToDegrees(dir);
    return connectionsToRoads.some((con) => con.rot === dirDegrees);
  });

export const areaDirectionIsPerpendicularToRoadConnection = (
  direction: AreaDirection,
  connectionsToRoads: Connection[],
) => {
  const dirDegrees = mapShapeDirectionToDegrees(direction);
  return connectionsToRoads.some((con) => Math.abs(degreeDelta(con.rot, dirDegrees)) === 90);
};

export const getProcessIdsWithUnequalInboundOutboundFlows = (
  processShapes: ProcessAreaOneEp[],
  processTwoEndPointShapes: ProcessTwoEPShape[],
  flows: LayoutFlow[],
) => {
  const processRelatedFlowsMap = createProcessRelatedFlowsMap(processShapes, processTwoEndPointShapes, flows);
  const unequalProcessAreasIds: string[] = [];
  processRelatedFlowsMap.forEach((item) => {
    if (item.inbound.length !== item.outbound.length)
      unequalProcessAreasIds.push(item.processAreaId);
  });

  return unequalProcessAreasIds;
};

export const getProcessIdsWithUnbalancedInboundOutboundFlows = (
  processShapes: ProcessAreaOneEp[],
  processTwoEndPointShapes: ProcessTwoEPShape[],
  flows: LayoutFlow[],
) => {
  const processRelatedFlowsMap = createProcessRelatedFlowsMap(processShapes, processTwoEndPointShapes, flows);
  const unbalancedProcessAreasIds: string[] = [];
  processRelatedFlowsMap.forEach((item) => {
    const sumInbound = item.inbound.map((flow) => flow.totalNumLoads).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const sumOutbound = item.outbound.map((flow) => flow.totalNumLoads).reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    if (sumInbound !== sumOutbound)
      unbalancedProcessAreasIds.push(item.processAreaId);
  });

  return unbalancedProcessAreasIds;
};

const mapShapeDirectionToDegrees = (direction: AreaDirection) =>
  shapeDirectionToDegreesMap[direction];

const degreeDelta = (degree1: number, degree2: number) => ((degree2 - degree1 + 540) % 360) - 180;

export const isDeadEndRoad = (connectionAmount: number, crossingAmount: number) =>
  connectionAmount + crossingAmount < 2;

export const getMissingRequiredElements = (
  allShapes: DTShape[],
  vehicles: UnifiedVehicle[],
  flows: LayoutFlow[],
  groups: ShapeGroup[],
): MissingRequiredElement[] => {
  const missingRequiredElements: MissingRequiredElement[] = [];

  vehicles.forEach((vehicle) => {
    const intakeIds = [];
    const deliveryIds = [];
    const storageIds = [];
    const chargingIds = [];
    // const parkingIds = []; TODO: parkings are not yet supported
    const roadIds = [];
    const vehicleShapes = allShapes.filter(
      (shape) =>
        !supportsVehicleTypes(shape.type) ||
        ((isAreaShape(shape) || isPositionShape(shape)) &&
          !!shape.parameters.supportedVehicleIds?.includes(vehicle.id)) ||
        (isProcessAreaTwoEp(shape) && (
          !!shape.parameters.deliveryParameters.supportedVehicleIds?.includes(vehicle.id) ||
          !!shape.parameters.intakeParameters.supportedVehicleIds?.includes(vehicle.id)))
    );
    const vehicleGroups = groups
      .filter(
        (group) =>
          isArea(group.type) &&
          vehicleShapes
            .map((shape) => shape.id)
            .some((shapeId) => group.children.includes(shapeId)),
      );
    const vehicleFlows = flows.filter(
      (flow) =>
        (flow.intakeFlowStop.type === FlowStopType.AREA &&
          vehicleShapes.some((shape) => shape.id === flow.intakeFlowStop.id)) ||
        (flow.intakeFlowStop.type === FlowStopType.AREA_GROUP &&
          vehicleGroups.some((group) => group.id === flow.intakeFlowStop.id)) ||
        (flow.deliveryFlowStop.type === FlowStopType.PROCESS &&
          vehicleShapes.some((shape) => shape.id === flow.deliveryFlowStop.id)) ||
        (flow.intakeFlowStop.type === FlowStopType.PROCESS &&
          vehicleShapes.some((shape) => shape.id === flow.intakeFlowStop.id))
    );

    // eslint-disable-next-line no-restricted-syntax
    for (const shape of vehicleShapes) {
      switch (shape.type) {
        case ShapeType.INTAKE:
        case ShapeType.INTAKE_POSITION:
          intakeIds.push(shape.id);
          break;
        case ShapeType.DELIVERY_POSITION:
        case ShapeType.DELIVERY:
          deliveryIds.push(shape.id);
          break;
        case ShapeType.STORAGE:
        case ShapeType.STORAGE_POSITION:
          storageIds.push(shape.id);
          break;
        case ShapeType.CHARGING_POSITION:
        case ShapeType.CHARGING:
          chargingIds.push(shape.id);
          break;
        // TODO: parkings are not yet supported
        // case ShapeType.PARKING:
        // case ShapeType.PARKING_POSITION:
        //   chargingIds.push(shape.id);
        //   break;
        case ShapeType.HIGHWAY:
        case ShapeType.HIGHWAY_ANGLED:
          roadIds.push(shape.id);
          break;
        default:
          break;
      }
    }

    if (!vehicleFlows?.length)
      missingRequiredElements.push({ element: RequiredElementType.FLOW, vehicle: vehicle.name });
    if (!intakeIds?.length && !deliveryIds?.length && !storageIds?.length)
      missingRequiredElements.push({ element: RequiredElementType.AREA, vehicle: vehicle.name });
    if (!chargingIds?.length)
      missingRequiredElements.push({
        element: RequiredElementType.CHARGING,
        vehicle: vehicle.name,
      });
    // if (!parkingIds?.length) missingRequiredElements.push(RequiredElementType.PARKING); TODO: parkings are not yet supported
    if (!roadIds?.length)
      missingRequiredElements.push({ element: RequiredElementType.ROAD, vehicle: vehicle.name });
  });

  return missingRequiredElements;
};

export const getFlowlessAreas = (
  allShapes: DTShape[],
  shapeGroups: ShapeGroup[],
  flows: LayoutFlow[],
): (AreaShape | PositionShape)[] => {
  const intakes: (AreaShape | PositionShape)[] = [];
  const deliveries: (AreaShape | PositionShape)[] = [];
  const storages: (AreaShape | PositionShape)[] = [];

  // derive intakes, deliveries and storages
  // eslint-disable-next-line no-restricted-syntax
  for (const shape of allShapes) {
    switch (shape.type) {
      case ShapeType.INTAKE:
      case ShapeType.INTAKE_POSITION:
        intakes.push(shape as AreaShape | PositionShape);
        break;
      case ShapeType.DELIVERY:
      case ShapeType.DELIVERY_POSITION:
        deliveries.push(shape as AreaShape | PositionShape);
        break;
      case ShapeType.STORAGE:
      case ShapeType.STORAGE_POSITION:
        storages.push(shape as AreaShape | PositionShape);
        break;
      default:
        break;
    }
  }

  const flowStopIds = new Set<string>();
  flows.forEach((flow) => {
    if (flow.id != null) {
      flowStopIds.add(flow.deliveryFlowStop.id);
      flowStopIds.add(flow.intakeFlowStop.id);
    }
  });

  const shapeGroupsWithFlows = shapeGroups.filter(({ id }) => flowStopIds.has(id));
  const shapesFromGroupsWithFlows = shapeGroupsWithFlows
    .map((shapeGroup) => shapeGroup.children)
    .flat();

  return [...intakes, ...deliveries, ...storages].filter(
    ({ id }) => !flowStopIds.has(id) && !shapesFromGroupsWithFlows.includes(id),
  );
};

export const getConnectionLackingRoadIds = (
  allRoadIds: string[],
  allConnections: (Connection | DistantConnection)[],
  allCrossings: Crossing[],
): string[] => {
  // allocate a map between all roads and their connections (if any) and crossings (if any)
  const roadIdConnectionsCrossingsMap = new Map<string, RoadConnectionsCrossingsCollection>(
    allRoadIds.map((id) => [id, { connections: [], crossings: [] }]),
  );

  allConnections.forEach((con) => {
    roadIdConnectionsCrossingsMap.get(decodeShapeId(con.from))?.connections.push(con);
    roadIdConnectionsCrossingsMap.get(decodeShapeId(con.to))?.connections.push(con);
  });

  allCrossings.forEach((cros) => {
    roadIdConnectionsCrossingsMap.get(decodeShapeId(cros.from))?.crossings.push(cros);
    roadIdConnectionsCrossingsMap.get(decodeShapeId(cros.to))?.crossings.push(cros);
  });

  const connectionLackingRoadIds = allRoadIds.filter((id) => {
    const { connections, crossings } = roadIdConnectionsCrossingsMap.get(id);
    return isDeadEndRoad(connections.length, crossings.length);
  });

  return connectionLackingRoadIds;
};

export const getDisconnectedAreaIds = (
  allAreaIds: string[],
  allPositionIds: string[],
  allProcessIds: string[],
  allConnections: (Connection | DistantConnection)[],
) => { 
  const connectedShapeIds = new Set<string>();
  allConnections.forEach((item) => {
    connectedShapeIds.add(item.from);
    connectedShapeIds.add(item.to);
  });

  // Two endpoint process areas need to be connected on both ends
  allProcessIds
    .filter((id) => connectedShapeIds.has(`${id}.0`) && connectedShapeIds.has(`${id}.1`))
    .forEach(connectedShapeIds.add, connectedShapeIds)

  return [...allAreaIds, ...allPositionIds, ...allProcessIds].filter((id) => !connectedShapeIds.has(id))
};

export const getDisconnectedFlowStopIds = (
  flows: LayoutFlow[],
  allConnections: Connection[],
  allDistantConnections: DistantConnection[],
  allShapeGroups: ShapeGroup[],
): string[] => {
  const disconnectedFlowStopIds = new Set<string>();
  const flowStopIdMap = new Map<string, FlowStop>();
  const connectedShapesIds = new Set<string>();

  // get a map between each flowstop id and its entity
  flows.forEach((flow) => {
    flowStopIdMap.set(flow.intakeFlowStop.id, flow.intakeFlowStop);
    flowStopIdMap.set(flow.deliveryFlowStop.id, flow.deliveryFlowStop);
  });

  // populate flowStopIdsWithConnectionsSet
  allConnections.forEach((con) => {
    connectedShapesIds.add(con.from).add(con.to);
  });
  allDistantConnections.forEach((distCon) => {
    connectedShapesIds.add(distCon.from).add(distCon.to);
  });

  const groupMap = new Map<string, ShapeGroup>(allShapeGroups.map((group) => [group.id, group]));

  flowStopIdMap.forEach((flowStop: FlowStop) => {
    if (flowStop.type === FlowStopType.AREA) {
      if (!connectedShapesIds.has(flowStop.id)) {
        disconnectedFlowStopIds.add(flowStop.id);
      }
    } else if (flowStop.type === FlowStopType.AREA_GROUP) {
      const shapeGroup = groupMap.get(flowStop.id);

      const childIdConnectionsCrossingsMap = new Map<string, ShapeConsDistConsCollection>(
        shapeGroup.children.map((childId) => [
          childId,
          { connections: [], distantConnections: [] },
        ]),
      );

      // allocate connections to appropriate group child id
      allConnections.forEach((con) => {
        childIdConnectionsCrossingsMap.get(con.from)?.connections.push(con);
        childIdConnectionsCrossingsMap.get(con.to)?.connections.push(con);
      });

      // allocate distant connections to appropriate group child id
      allDistantConnections.forEach((distCon) => {
        childIdConnectionsCrossingsMap.get(distCon.from)?.distantConnections.push(distCon);
        childIdConnectionsCrossingsMap.get(distCon.to)?.distantConnections.push(distCon);
      });

      const disconnectedChildren = [];
      shapeGroup.children.forEach((childId: string) => {
        if (
          !childIdConnectionsCrossingsMap.get(childId).connections.length &&
          !childIdConnectionsCrossingsMap.get(childId).distantConnections.length
        ) {
          disconnectedChildren.push(childId);
        }
      });

      if (disconnectedChildren.length === shapeGroup.children.length)
        disconnectedFlowStopIds.add(shapeGroup.id);
    }
  });

  return [...disconnectedFlowStopIds];
};

export const getIncorrectlyConnectedShapeIds = (
  areasAndPositionsIds: string[],
  areasAndPositions: (AreaShape | PositionShape)[],
  allHighways: HighwayShape[],
  allConnections: Connection[],
): string[] => {
  const incorrectlyConnectedShapeIds = [];
  const highwayIdHighwayMap = new Map<string, HighwayShape>(
    allHighways.map((item) => [item.id, item]),
  );

  const areaIdDirectionMap = new Map<string, AreaDirection>(
    areasAndPositions.map((shape: any) => [shape.id, shape.parameters.direction]),
  );
  const shapeIdsConnectionsMap = new Map<string, Connection[]>(
    areasAndPositionsIds.map((shapeId) => [shapeId, []]),
  );

  // allocate each connection to both its "from"-shape and its "to"-shape
  allConnections.forEach((con) => {
    shapeIdsConnectionsMap.get(con.from)?.push(con);
    shapeIdsConnectionsMap.get(con.to)?.push(con);
  });

  shapeIdsConnectionsMap.forEach((connections, shapeId) => {
    // get ids of the shapes connected to the current shape
    const relatedConnectedShapeIds = connections.map((item) =>
      item.from === shapeId ? item.to : item.from,
    );

    // get roads connected to this shape
    const roadsConnectedToShape = [];
    relatedConnectedShapeIds.forEach((id) => {
      const highway = highwayIdHighwayMap.get(id);
      if (highway) roadsConnectedToShape.push(highway);
    });

    // negate shapes with no road connections
    if (!roadsConnectedToShape.length) {
      return;
    }

    const connectedRoadIds = roadsConnectedToShape.map((road) => road.id);

    // TODO: revise once shapes support multiple directions / entrances for vehicles
    const direction = areaIdDirectionMap.get(shapeId);

    const connectionsWithRoads = connections.filter(
      (connection) =>
        connectedRoadIds.includes(connection.to) || connectedRoadIds.includes(connection.from),
    );

    const connectionsToRoads = connectionsWithRoads.map((con) =>
      transformConnectionToRelativePerspective(con, shapeId),
    );

    // if (
    // !areaDirectionsAllPointToARoadConnection([direction], connectionsToRoads)
    // !areaDirectionIsPerpendicularToRoadConnection(direction, connectionsToRoads)
    // ) {
    //   incorrectlyConnectedShapeIds.push(shapeId);
    // }
  });

  return incorrectlyConnectedShapeIds;
};
