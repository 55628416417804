import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@/store/recoil/common';
import { MissingRequiredElement } from '../../types';
import { prevalidationState } from '../state';

export const missingRequiredElementsSelector = selector({
  key: 'prevalidation/missingRequiredElements',
  get: ({ get }) => get(prevalidationState).missingRequiredElements,
  set: ({ set }, newItems: MissingRequiredElement[]) => {
    set(prevalidationState, (current) => ({ ...current, missingRequiredElements: newItems }));
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
