import { useCallback, useMemo } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import { Mode, REFERENCE_TYPE, referenceType } from '../../referenceImage';
import { referenceMode, isReferenceScaledSelector } from '../../referenceImage/store';
import { showRequiredScalingDialogState } from '../store/requiredReferenceScaling';

export const useRequiredReferenceScaling = () => {
  const isReferenceScaled = useRecoilValue(isReferenceScaledSelector);
  const floorplanReferenceType = useRecoilValue(referenceType);
  const [isDialogOpen, setIsDialogOpen] = useRecoilState(showRequiredScalingDialogState);

  const referenceExistsAndIsNotScaled = useMemo(
    () => floorplanReferenceType !== REFERENCE_TYPE.NONE && !isReferenceScaled,
    [isReferenceScaled, floorplanReferenceType],
  );

  const onCancel = useCallback(() => {
    setIsDialogOpen(false);
  }, [setIsDialogOpen]);

  const onConfirm = useRecoilCallback(
    ({ set }) =>
      async () => {
        set(referenceMode, Mode.SCALE);
        setIsDialogOpen(false);
      },
    [setIsDialogOpen],
  );

  const onClose = useCallback(() => {
    setIsDialogOpen(false);
  }, [setIsDialogOpen]);

  return {
    referenceExistsAndIsNotScaled,
    isDialogOpen,
    setIsDialogOpen,
    onCancel,
    onConfirm,
    onClose,
  };
};
