import { RequiredElementType } from '@/modules/floorplanValidation/clientSide';
import { useRecoilCallback } from 'recoil';
import { missingRequiredElementsSelector } from '../store';

export const useIsFloorplanServiceRequirementsMet = () => {
  const missingAreaElements = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const missingRequiredElements = await snapshot.getPromise(missingRequiredElementsSelector);
        return missingRequiredElements.filter(x => x.element === RequiredElementType.AREA)
      },
    [],
  );

  return {
    missingAreaElements,
  };
};
