import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import { latestFloorPlanIdSelector } from '../group';
import { idSelector } from './idSelector';

/**
 * Checks whether the currently loaded floor plan is the latest version in a group
 */
export const isLatestSelector = selector<boolean>({
  key: 'floorplan/isLatest',
  get: ({ get }) => get(idSelector) === get(latestFloorPlanIdSelector),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
