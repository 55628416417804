import { List, ListItemButton, ListItemText } from '@mui/material';
import { Accordion } from '@/modules/common/components/Accordion';

import { useGateStore } from '../../store';

export const GateList = () => {
  const gates = useGateStore((state) => state.editableGates);

  return (
    <Accordion title='All positions' sx={{ px: 2 }}>
      <List component='div' disablePadding>
        {gates.map((item) => (
          <ListItemButton
            key={item.id}
            alignItems='center'
            onClick={() => useGateStore.getState().selectGate(item.station.locationName)}
          >
            <ListItemText
              primary={item.station.locationName}
              primaryTypographyProps={{
                style: {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
              }}
              sx={{ fontSize: 24, marginLeft: 1 }}
            />
          </ListItemButton>
        ))}
      </List>
    </Accordion>
  );
};
