import { PointerEventArgs } from '@thive/canvas';
import { useCallback, useMemo } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { Vector2 } from 'three';

import { getOrderedControlPoints } from '@/modules/common/helpers/shapes';
import { ShapeType } from '@/modules/common/types/shapes';
import { useConnections } from '@/modules/connections';
import { usePointsDrawing } from '@/modules/workspace/hooks/points';
import { useWorkspaceStore } from '@/modules/workspace/store';
import { drawingIdSelector, sizeSelector } from '@/store/recoil/workspace';
import shapeTypeSelector from '@/store/recoil/workspace/shapeType';
import { snapToClosestAxis } from '@modules/angledHighways/helpers';
import { KEYCODE, keyboardState } from '@recoil/input';

export const usePointsMouseHandler = () => {
  const workspaceSize = useRecoilValue(sizeSelector);
  const drawingId = useRecoilValue(drawingIdSelector);
  const { updateConnections } = useConnections();

  const { updateInterimEndPointPos, initDrawing, persistDrawing, appendControlPoint } =
    usePointsDrawing();

  // do nothing on mouse down
  const onMouseDown = useCallback((e: PointerEventArgs) => {}, []);

  const onMouseMove = useRecoilCallback(
    ({ snapshot }) =>
      async (e: PointerEventArgs) => {
        if (drawingId) {
          let pos = e.position;

          const activeDrawingState = useWorkspaceStore.getState().activePointsDrawingState;
          const { controlPoints } = activeDrawingState;

          const pressedKey = await snapshot.getPromise(keyboardState);
          if (pressedKey === KEYCODE.SHIFT) {
            const { position } = getOrderedControlPoints(controlPoints)[controlPoints.length - 1];
            pos = snapToClosestAxis(position, pos);
          }
          updateInterimEndPointPos(pos);
        }
      },
    [updateInterimEndPointPos, drawingId],
  );

  const onMouseUp = useRecoilCallback(
    ({ snapshot }) =>
      async (e: PointerEventArgs) => {
        let { x, y } = e.position;

        const shapeType = await snapshot.getPromise(shapeTypeSelector);

        if (
          shapeType !== ShapeType.WALL &&
          shapeType !== ShapeType.HIGHWAY_ANGLED &&
          shapeType !== ShapeType.PROCESS_TWO_EP
        )
          return;

        // active drawing state
        const activeDrawingState = useWorkspaceStore.getState().activePointsDrawingState;

        // convert and cap to mm
        const eventMousePos = new Vector2(Math.round(x), Math.round(y));

        let pos = eventMousePos;
        const pressedKey = await snapshot.getPromise(keyboardState);
        if (pressedKey === KEYCODE.SHIFT && drawingId) {
          // active drawing state
          const { controlPoints } = activeDrawingState;
          const { position } = getOrderedControlPoints(controlPoints)[controlPoints.length - 1];
          pos = snapToClosestAxis(position, eventMousePos);
        }

        // active drawing state
        if (!drawingId) {
          initDrawing(eventMousePos, shapeType);
        } else if (activeDrawingState.controlPoints.length === 1) {
          await persistDrawing(pos);
          await updateConnections([activeDrawingState.id]);
        } else {
          await appendControlPoint(pos);
          await updateConnections([activeDrawingState.id]);
        }
      },
    [workspaceSize, updateConnections, drawingId],
  );

  // do nothing on click, double click
  const onClick = useCallback(async () => {}, []);
  const onDoubleClick = useCallback(async () => {}, []);

  const mouseHandler = useMemo(
    () => ({
      onMouseDown,
      onMouseMove,
      onMouseUp,
      onClick,
      onDoubleClick,
    }),
    [onMouseDown, onMouseMove, onMouseUp, onClick, onDoubleClick],
  );

  return mouseHandler;
};
