import { useRecoilCallback } from 'recoil';
import { allDistantConnectionsSelector } from '@modules/connections/distant';
import { allConnectionsSelector } from '@modules/connections/connections';

import { areasAtom } from '../../../../store/recoil/shapes/area';
import { positionsAtom } from '../../../../store/recoil/shapes/positions/atom';
import { getDisconnectedAreaIds } from '../helpers';
import { prevalidationState } from '../store/state';
import { processTwoEndPointIdsState } from '@/modules/processTwoEndPoint/store';

export const useDisconnectedAreaIdsValidator = () => {
  const validateDisconnectedAreaIds = useRecoilCallback(
    ({ snapshot, set }) =>
      async (syncResultsWithStore = true) => {
        const allAreaIds = await snapshot.getPromise(areasAtom);
        const allPositionIds = await snapshot.getPromise(positionsAtom);
        const allProcessIds = await snapshot.getPromise(processTwoEndPointIdsState)
        const allConnections = [
          ...(await snapshot.getPromise(allConnectionsSelector)),
          ...(await snapshot.getPromise(allDistantConnectionsSelector)),
        ];

        const disconnectedAreaIds = getDisconnectedAreaIds(
          allAreaIds,
          allPositionIds,
          allProcessIds,
          allConnections,
        );

        if (syncResultsWithStore) {
          set(prevalidationState, (current) => {
            if (current.disconnectedAreaIds.length === 0 && disconnectedAreaIds.length === 0)
              return current;

            return {
              ...current,
              disconnectedAreaIds,
            };
          });
        }

        return disconnectedAreaIds;
      },
    [],
  );

  return {
    validateDisconnectedAreaIds,
  };
};
