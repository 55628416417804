import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@/store/recoil/common';

import { versionIdsAtom } from './versionsAtom';

/**
 * Number of visible versions
 */
export const visibleVersionsCountSelector = selector<number>({
  key: 'versioning/module/visibleVersionsCount',
  get: ({ get }) => get(versionIdsAtom).length,
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
