import { useCallback, useMemo, useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';

import { CheckboxCheckedIcon, CheckboxUncheckedIcon } from '@/assets/icons';
import { deSelectedSx, selectedSx } from '@/modules/setup/styles/PropertySelectCard.styles';
import { OverflowTypography } from '@/modules/common/components/OverflowTypography';

type Props = {
  name: string;
  imageUrl: string;
  selected: boolean;
  onClick: () => void;
};

export const VehicleCard = ({ name, imageUrl, selected, onClick }: Props) => {
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setIsHover(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHover(false);
  }, []);

  const icon = useMemo(() => {
    if (selected) {
      return <CheckboxCheckedIcon className='check' />;
    }
    if (isHover) {
      return <CheckboxUncheckedIcon className='check' />;
    }

    return <CheckboxCheckedIcon className='check' style={{ visibility: 'hidden' }} />;
  }, [isHover, selected]);

  return (
    <Button
      onClick={onClick}
      sx={selected ? selectedSx : deSelectedSx}
      aria-selected={selected}
      aria-label='vehicle option button'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Stack padding={1} minHeight={0} flexGrow={1}>
        <img
          src={imageUrl}
          alt={name}
          style={{
            height: '100%',
            width: '100%',
            objectFit: 'contain',
          }}
        />
      </Stack>

      <Stack
        className='label-box'
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <OverflowTypography align='left' fontSize={12}>
          {name}
        </OverflowTypography>
        {icon}
      </Stack>
    </Button>
  );
};
