import { SvgIcon } from '@mui/material';

export default function ObstracleIcon(props) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="none" {...props}>
      <path
       fillRule="evenodd" clipRule="evenodd" d="M8.5 1C8.22386 1 8 1.22386 8 1.5V2.79289L9.79289 1H8.5ZM10.9999 0H8.5C7.67157 0 7 0.671574 7 1.5V20.9999V22.5C7 23.3284 7.67157 24 8.5 24H15.5C16.3284 24 17 23.3284 17 22.5V17.7505V12.0005V6.26731C17.0004 6.25577 17.0004 6.24423 17 6.23269V1.5C17 0.671574 16.3284 0 15.5 0H10.9999ZM11.2071 1L8 4.20711V8.29289L15.2929 1H11.2071ZM16 1.70711L8 9.70711V14.0432L16 6.04319V1.70711ZM16 7.4574L8 15.4574V19.7929L16 11.7929V7.4574ZM16 13.2071L8 21.2071V22.5C8 22.7761 8.22386 23 8.5 23H10.5429L16 17.5429V13.2071ZM16 18.9571L11.9571 23H15.5C15.7761 23 16 22.7761 16 22.5V18.9571Z" fill="currentColor"
      />
    </SvgIcon>
  );
}