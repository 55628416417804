import { FloatingPanel } from '@/modules/common/components/FloatingPanel';
import { PropertiesLabel } from '@/modules/common/components/PropertiesLabel';
import settingsAtom from '@/store/recoil/settings/atom';
import { Stack } from '@mui/system';
import { useRecoilState } from 'recoil';
import { DarkModeSwitch } from './DarkModeSwitch';
import { ExperimentSettings } from './ExperimentSettings';
import { NudgeInput } from './NudgeInput';

export const SettingsPanel = () => {
  const [open, setOpen] = useRecoilState(settingsAtom);

  return (
    <FloatingPanel open={open} onClose={() => setOpen(false)} title='Settings'>
      <Stack direction='column' spacing={2}>
        <Stack direction='column' spacing={1} maxWidth={300}>
          <PropertiesLabel i18nkey='interface:settings.nudge'>
            <NudgeInput />
          </PropertiesLabel>
          <PropertiesLabel i18nkey='interface:settings.display'>
            <DarkModeSwitch />
          </PropertiesLabel>
        </Stack>
        <ExperimentSettings />
      </Stack>
    </FloatingPanel>
  );
};
