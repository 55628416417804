import { useEffect } from 'react';
import { HistoryManager } from '../utils';
import {
  useFloorPlanStore,
  useModuleStore,
  useKollmorgenStore,
  useHistoryStore,
  useGateStore,
  useLayoutStore,
  useSevenSenseStore,
  useNavitechStore,
} from '../store';

export const useStore = () => {
  useEffect(
    () => {
      HistoryManager.startTracking();

      return () => {
        HistoryManager.stopTracking();
        useKollmorgenStore.getState().reset();
        useFloorPlanStore.getState().reset();
        useHistoryStore.getState().reset();
        useModuleStore.getState().reset();
        useGateStore.getState().reset();
        useLayoutStore.getState().reset();
        useSevenSenseStore.getState().reset();
        useNavitechStore.getState().reset();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
};
