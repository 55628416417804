import { HistoryState, useHistoryStore } from '@/modules/commissioning/store';
import { HistoryManagerClass } from '@/modules/common/utils';

export const HistoryManager = new HistoryManagerClass(100, (state) => {
  const dataToSync: HistoryState = {
    hasUndo: state.hasUndo,
    hasRedo: state.hasRedo,
  };

  useHistoryStore.setState(dataToSync);
});
