import { useRecoilCallback } from 'recoil';

import { DTShape } from '@/modules/common/types/shapes';
import { UndoRedoState, useUndoRedoConnections } from '@/modules/connections';
import { HistoryManager } from '@/store/recoil/history';
import { allShapesSelector } from '@/store/recoil/shapes';
import { selectedShapesIdsState } from '@/store/recoil/shapes/selected';

type ShapeCreationHistoryState = {
  shapes: DTShape[];
  connections?: UndoRedoState;
};

export const useShapeCreationHistory = () => {
  const { restoreUndoRedoState } = useUndoRedoConnections();

  const trackShapeCreation = useRecoilCallback(
    ({ set }) =>
      async (
        newState: ShapeCreationHistoryState,
        oldState: ShapeCreationHistoryState,
        label: string | undefined = 'Shape created',
      ) => {
        const setSelfCallback = ({ shapes, connections }: ShapeCreationHistoryState) => {
          set(selectedShapesIdsState, (currentSelectedIds) =>
            currentSelectedIds.every((selectedId) => shapes.find((item) => item.id === selectedId))
              ? currentSelectedIds
              : [],
          );

          set(allShapesSelector, shapes);

          if (connections) restoreUndoRedoState(connections);
        };

        HistoryManager.track(label, newState, oldState, setSelfCallback);
      },
    [restoreUndoRedoState],
  );

  return {
    trackShapeCreation,
  };
};
