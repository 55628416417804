import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Vector3 } from 'three';
import { Button, Grid, Stack, Typography } from '@mui/material';

import { AngleIcon } from '@/assets/icons';
import { useGateStore } from '@/modules/commissioning/store';
import { HistoryManager } from '@/modules/commissioning/utils';
import { Accordion } from '@/modules/common/components/Accordion';
import { Input } from '@common/components/Input';
import { useSaveData } from '@/modules/commissioning/hooks';

export const AdjustPosition = () => {
  const { t } = useTranslation('interface');
  const id = useGateStore((state) => state.currentId);
  const adjustment = useGateStore((state) => state.currentAdjustment);
  const adjustmentError = useGateStore((state) => state.currentAdjustmentError);
  const { save } = useSaveData();

  useEffect(() => {
    useGateStore.getState().resetCurrentAdjustment();
  }, [id]);

  const onSubmit = useCallback(() => {
    const { prevAdjustment, updatedAdjustment } = useGateStore
      .getState()
      .applyCurrentAdjustment();
    const { currentId: selectedId } = useGateStore.getState();

    HistoryManager.track(
      'Position adjusted',
      updatedAdjustment,
      prevAdjustment,
      (adjustment: Vector3) => {
        useGateStore.getState().updateAdjustment(selectedId, adjustment);
        save();
      },
    );

    save();
  }, [save]);

  const adjustX = useCallback((value) => useGateStore.getState().adjustX(value), []);
  const adjustY = useCallback((value) => useGateStore.getState().adjustY(value), []);
  const adjustZ = useCallback((value) => useGateStore.getState().adjustZ(value), []);

  return (
    <Accordion
      title={t('interface:commissioning.gate.adjust_position', 'Adjust position')}
      defaultExpanded
      sx={{ px: 2 }}
    >
      <Grid
        container
        spacing={1}
        alignItems='center'
        columns={12}
        sx={{ '&:hover': { cursor: 'default' }, mb: 2 }}
      >
        <Grid item xs={6}>
          <Typography fontSize='10px'>
            {t('interface:commissioning.gate.deltaX', 'Delta X')}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ paddingRight: 1 }}>
          <Input
            ariaLabel={t('interface:commissioning.gate.deltaX', 'Delta X')}
            value={adjustment.x}
            onChange={adjustX}
            unit='mm'
            error={adjustmentError.x && t('errors:commissioning.gate.outside_area', 'Error')}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography fontSize='10px'>
            {t('interface:commissioning.gate.deltaY', 'Delta Y')}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ paddingRight: 1 }}>
          <Input
            ariaLabel={t('interface:commissioning.gate.deltaY', 'Delta Y')}
            value={adjustment.y}
            onChange={adjustY}
            unit='mm'
            error={adjustmentError.y && t('errors:commissioning.gate.outside_area', 'Error')}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography fontSize='10px'>
            {t('interface:commissioning.gate.deltaZ', 'Delta Z')}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ paddingRight: 1 }}>
          <Input
            ariaLabel={t('interface:commissioning.gate.deltaZ', 'Delta Z')}
            value={adjustment.z}
            onChange={adjustZ}
            unit='mm'
            disabled
            error={adjustmentError.z && t('errors:commissioning.gate.outside_area', 'Error')}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography fontSize='10px'>
            {t('interface:commissioning.gate.deltaAngle', 'Delta angle')}
            <AngleIcon
              style={{
                width: '16px',
                height: '16px',
                position: 'relative',
                left: '5px',
                top: '3px',
              }}
            />
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ paddingRight: 1 }}>
          <Input
            ariaLabel={t('interface:commissioning.gate.deltaAngle', 'Delta angle')}
            value={0}
            unit='°'
            disabled
          />
        </Grid>
      </Grid>
      <Stack mb={2}>
        <Button
          onClick={onSubmit}
          disabled={adjustmentError.x || adjustmentError.y || adjustmentError.z}
        >
          {t('interface:commissioning.gate.apply_button')}
        </Button>
      </Stack>
    </Accordion>
  );
};
