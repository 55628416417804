import { SvgIcon } from '@mui/material';

const ReloadIcon = ({ className = '', size = 24, color = 'currentColor', ...rest }) => (
  <SvgIcon
    viewBox='0 0 24 24'
    sx={{ width: size, height: size, fontSize: `${size}px`, fill: color }}
    className={className}
    {...rest}
  >
    <path
      d='M5.23462 15.2039C4.99616 14.7116 4.8141 14.1994 4.68845 13.6674C4.56282 13.1353 4.5 12.5962 4.5 12.0501C4.5 9.95777 5.23012 8.17636 6.69037 6.70586C8.15064 5.23534 9.92052 4.50008 12 4.50008H13.3865L11.2865 2.40008L11.9942 1.69238L15.3019 5.00008L11.9942 8.30778L11.2865 7.60008L13.3865 5.50008H12C10.1923 5.50008 8.65706 6.13566 7.39423 7.40681C6.13141 8.67797 5.5 10.2257 5.5 12.0501C5.5 12.445 5.54038 12.8475 5.62115 13.2578C5.70192 13.668 5.82307 14.0667 5.98462 14.4539L5.23462 15.2039ZM12.0058 22.3078L8.69808 19.0001L12.0058 15.6924L12.7135 16.4001L10.6135 18.5001H12C13.8077 18.5001 15.3429 17.8645 16.6058 16.5934C17.8686 15.3222 18.5 13.7744 18.5 11.9501C18.5 11.5552 18.4596 11.1526 18.3788 10.7424C18.2981 10.3321 18.1769 9.93342 18.0154 9.54623L18.7654 8.79623C19.0038 9.28853 19.1859 9.80072 19.3115 10.3328C19.4372 10.8648 19.5 11.4039 19.5 11.9501C19.5 14.0424 18.7699 15.8238 17.3096 17.2943C15.8494 18.7648 14.0795 19.5001 12 19.5001H10.6135L12.7135 21.6001L12.0058 22.3078Z'
      fill={color}
    />
  </SvgIcon>
);

export default ReloadIcon;
