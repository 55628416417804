import { PropertyPanelContainer } from '@/modules/common/components/PropertyPanelContainer';
import { useNavigation } from '@modules/common/hooks';
import { Module } from '@modules/common/types/navigation';
import { Typography } from '@mui/material';

export function PropertyPanel() {
  const { goToModule } = useNavigation();

  return (
    <PropertyPanelContainer
      labelKey='Property Panel'
      onCloseClick={() => goToModule(Module.LAYOUT)}
      contentScroll={false}
    >
      <Typography sx={{ p: 2, fontSize: 12 }}>Under construction</Typography>
    </PropertyPanelContainer>
  );
}
