import { useCallback, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { useCanvasStore } from '@/modules/canvas';
import { useFloorPlanService as fpsService } from '@/modules/floorplanService';
import { useShapeStore } from '@/modules/salesWorkspace/store/useShapeStore';
import { useSnackbarStore } from '@/modules/snackbar/store/useSnackbarStore';
import { useTranslation } from 'react-i18next';

export const useLoadData = () => {
  const { generateFloorPlanArtefacts } = fpsService();
  const { showSnackbar } = useSnackbarStore();
  const { t } = useTranslation();

  const load = useCallback(async () => {
    try {
      const artifacts = await generateFloorPlanArtefacts();
      await useShapeStore.getState().initialize(artifacts);
      useCanvasStore.getState().instance.zoomToFit();
    } catch (error) {
      showSnackbar(t('errors:home.load_floorplan'));
      console.error(error);
    }
  }, [generateFloorPlanArtefacts, showSnackbar, t]);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
