import { SvgIcon } from '@mui/material';

function FlowIcon({ className = '', ...props }) {
  return (
    <SvgIcon
      width='24'
      height='24'
      {...props}
    >
     <path d="M9 16.5q-1.875 0-3.188-1.312Q4.5 13.875 4.5 12q0-1.875 1.312-3.188Q7.125 7.5 9 7.5q1.75 0 3 1.137 1.25 1.138 1.475 2.863h5.9l-2.1-2.1.7-.725L21.3 12l-3.325 3.3-.7-.725 2.1-2.075h-5.9Q13.25 14.225 12 15.363 10.75 16.5 9 16.5Zm0-1q1.45 0 2.475-1.025Q12.5 13.45 12.5 12q0-1.45-1.025-2.475Q10.45 8.5 9 8.5q-1.45 0-2.475 1.025Q5.5 10.55 5.5 12q0 1.45 1.025 2.475Q7.55 15.5 9 15.5ZM9 12Z" stroke="none" fill="currentColor" />
    </SvgIcon>
  );
}

export default FlowIcon;
