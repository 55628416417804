import { Vector2d } from 'konva/lib/types';

export enum FlowScope {
  LAYOUT = 'layout',
  SIMULATION = 'simulation',
}

export enum FlowStopType {
  AREA_GROUP = 'areaGroup',
  AREA = 'area',
  PROCESS = 'process',
}
export type FlowStop = {
  id: string;
  type: FlowStopType;
};

export type LayoutFlow = {
  id: string;
  name: string;
  totalNumLoads: number;
  loadProfile?: number[];
  intakeFlowStop: FlowStop;
  deliveryFlowStop: FlowStop;
  sourceName?: string;
  targetName?: string;
  vehicleLimit: number;
};

export type FlowAttachShapeIds = {
  fromShapeId: string;
  toShapeId: string;
};

export type FlowAttachPoints = {
  from: Vector2d;
  to: Vector2d;
};

export type FlowLink = {
  from: string[];
  fromType: FlowStopType;
  to: string[];
  toType: FlowStopType;
};

export type FlowNode = {
  id: string;
  directionToNode: '' | 'forward' | 'backward';
};

