import { memo, useEffect, useMemo, useState } from 'react';
import { Image, Line } from 'react-konva';
import { useRecoilValue } from 'recoil';
import { Vector2 } from 'three';
import { RAD2DEG } from 'three/src/math/MathUtils';
import useImage from 'use-image';

import { areaIconAutoScaleAttr } from '@/components/Workspace/Area/consts';
import { theme } from '@/modules/common/components/theme';
import {
  supportedVehiclesLengthSelector,
  supportedVehiclesWidthSelector,
} from '@/modules/vehicles';
import { useColors } from '@/modules/workspace/hooks';
import {
  processDeliveryOrientedLoadCarriersBoundingBoxSelector, 
  processIntakeOrientedLoadCarriersBoundingBoxSelector 
} from '@/store/recoil/loadCarrierTypes';
import { AreaLoadCarrierOrientation, ControlPoint } from '@modules/common/types/shapes';
import { SHAPE_TO_CANVAS_SCALE } from '@modules/workspace/helpers/konva';
import { angleBetweenVectors, pointAlongVector } from '@modules/workspace/helpers/shape';
import { getFlatPointsArrayFromControlPoints } from '@/modules/shapes/controlPointsShapes/helpers';
import { processParameters } from '@/store/recoil/shape/shapeParameter';

export type ProcessTwoEndPointRendererProps = {
  id: string;
  points: ControlPoint[];
  width: number;
  intakeSupportedVehicleIds: string[];
  deliverySupportedVehicleIds: string[];
};

const ProcessTwoEndPointRendererComponent: React.FC<ProcessTwoEndPointRendererProps> = ({
  id,
  points,
  width,
  intakeSupportedVehicleIds,
  deliverySupportedVehicleIds,
}) => {
  const [arrowImage] = useImage('/chevron-double-right.svg');
  const [vehicleImage] = useImage('/arrow-down.svg');
  const { laneColor, loadBoxColor, shapeColor } = useColors(id);
  const params = useRecoilValue(processParameters(id));

  const deliveryVehicleLength = useRecoilValue(
    supportedVehiclesLengthSelector(deliverySupportedVehicleIds || []),
  );
  const deliveryVehicleWidth = useRecoilValue(
    supportedVehiclesWidthSelector(deliverySupportedVehicleIds || []),
  );
  const intakeVehicleLength = useRecoilValue(
    supportedVehiclesLengthSelector(intakeSupportedVehicleIds || []),
  );
  const intakeVehicleWidth = useRecoilValue(
    supportedVehiclesWidthSelector(intakeSupportedVehicleIds || []),
  );

  const deliveryOrientedLoadsBoundingBox = useRecoilValue(processDeliveryOrientedLoadCarriersBoundingBoxSelector(id));
  const deliveryLoadCarriersBoundingBox = useMemo(
    () => deliveryOrientedLoadsBoundingBox,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deliveryOrientedLoadsBoundingBox.length, deliveryOrientedLoadsBoundingBox.width],
  );

  const intakeOrientedLoadsBoundingBox = useRecoilValue(processIntakeOrientedLoadCarriersBoundingBoxSelector(id));
  const intakeLoadCarriersBoundingBox = useMemo(
    () => intakeOrientedLoadsBoundingBox,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intakeOrientedLoadsBoundingBox.length, intakeOrientedLoadsBoundingBox.width],
  );

  // local state
  const [canvasPoints, setCanvasPoints] = useState<number[]>([]);
  const [loads, setLoads] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [ends, setEnds] = useState([]);
  const [arrows, setArrows] = useState([]);
  const [directionImages, setDirectionImages] = useState([]);

  useEffect(() => {
    setCanvasPoints(
      getFlatPointsArrayFromControlPoints(points).map((num) => num * SHAPE_TO_CANVAS_SCALE),
    );
  }, [points]);

  useEffect(() => {
    const outLoads = [];
    const outVehicles = [];
    const outEnds = [];
    const outDirectionImages = [];

    if (canvasPoints.length === 0) return;

    const deliveryLoadLongSide = Math.max(deliveryLoadCarriersBoundingBox.length, deliveryLoadCarriersBoundingBox.width);
    const deliveryLoadShortSide = Math.min(deliveryLoadCarriersBoundingBox.length, deliveryLoadCarriersBoundingBox.width);
    const intakeLoadLongSide = Math.max(intakeLoadCarriersBoundingBox.length, intakeLoadCarriersBoundingBox.width);
    const intakeLoadShortSide = Math.min(intakeLoadCarriersBoundingBox.length, intakeLoadCarriersBoundingBox.width);
    
    const deliveryLoadWidth =
      params.deliveryParameters.loadCarrierOrientation === AreaLoadCarrierOrientation.SHORT_SIDE
      ? deliveryLoadShortSide
      : deliveryLoadLongSide;
    const deliveryLoadLength =
      params.deliveryParameters.loadCarrierOrientation === AreaLoadCarrierOrientation.SHORT_SIDE
      ? deliveryLoadLongSide
      : deliveryLoadShortSide;

    const intakeLoadWidth =
      params.intakeParameters.loadCarrierOrientation === AreaLoadCarrierOrientation.SHORT_SIDE
      ? intakeLoadShortSide
      : intakeLoadLongSide;
    const intakeLoadLength =
      params.intakeParameters.loadCarrierOrientation === AreaLoadCarrierOrientation.SHORT_SIDE
      ? intakeLoadLongSide
      : intakeLoadShortSide;

    const loadLengths = [deliveryLoadLength, intakeLoadLength];
    const loadWidths = [deliveryLoadWidth, intakeLoadWidth];
    
    const vehicleLengths = [deliveryVehicleLength, intakeVehicleLength];
    const vehicleWidths = [deliveryVehicleWidth, intakeVehicleWidth];

    const processEndPoints = [
      new Vector2(canvasPoints[0], canvasPoints[1]),
      new Vector2(canvasPoints[canvasPoints.length - 2], canvasPoints[canvasPoints.length - 1]),
    ];
    const processFirstSegmentPoints = [
      new Vector2(canvasPoints[2], canvasPoints[3]),
      new Vector2(canvasPoints[canvasPoints.length - 4], canvasPoints[canvasPoints.length - 3]),
    ];
    
    for (let i = 0; i < 2; i++) {
      const startLoad = pointAlongVector(
        processEndPoints[i].clone().add(new Vector2(0, 0)),
        processFirstSegmentPoints[i],
        vehicleLengths[i] + 25 - loadLengths[i]
      ) 
      const endLoad = pointAlongVector(
        processEndPoints[i].clone().add(new Vector2(0, 0)),
        processFirstSegmentPoints[i],
        vehicleLengths[i] + 25,
      )
      outLoads.push({
        points: [ startLoad.x, startLoad.y, endLoad.x, endLoad.y ],
        width: loadWidths[i], // loadShortSide,
        color: shapeColor, // loadBoxColor,
        id: `load-${i}`,
      });

      const startVehicle = pointAlongVector(
        processEndPoints[i], 
        processFirstSegmentPoints[i], 
        25
      ) 
      const endVehicle = pointAlongVector(
        processEndPoints[i],
        processFirstSegmentPoints[i],
        vehicleLengths[i] + 25,
      )
      outVehicles.push({
        points: [ startVehicle.x, startVehicle.y, endVehicle.x, endVehicle.y ],
        width: vehicleWidths[i],
        color: laneColor,
        id: `vehicle-${i}`,
      });

      const endPoint = pointAlongVector(
        processEndPoints[i],
        processFirstSegmentPoints[i],
        vehicleLengths[i] + 50,
      ) 

      outEnds.push({
        points: [
          processEndPoints[i].x,
          processEndPoints[i].y,
          endPoint.x, endPoint.y
        ],
        width,
        color: shapeColor,
        id: `end-${i}`,
      });
      const centerPos = pointAlongVector(
        processEndPoints[i],
        processFirstSegmentPoints[i],
        vehicleLengths[i] / 2 + 25,
      );

      const segmentVector = new Vector2().subVectors(
        processEndPoints[i],
        processFirstSegmentPoints[i],
      );
      let angle = angleBetweenVectors(segmentVector, new Vector2(1, 0)) * RAD2DEG - 90;
      outDirectionImages.push({
        x: centerPos.x,
        y: centerPos.y,
        rotation: angle,
        id: `direction-${i}`,
      });
    }

    setLoads(outLoads);
    setVehicles(outVehicles);
    setEnds(outEnds);
    setDirectionImages(outDirectionImages);
  }, [
    canvasPoints, 
    deliveryVehicleLength, 
    deliveryVehicleWidth, 
    intakeVehicleLength, 
    intakeVehicleWidth, 
    laneColor, 
    loadBoxColor, 
    deliveryLoadCarriersBoundingBox.length, 
    deliveryLoadCarriersBoundingBox.width, 
    intakeLoadCarriersBoundingBox.length, 
    intakeLoadCarriersBoundingBox.width, 
    shapeColor, 
    width, 
    params.deliveryParameters.loadCarrierOrientation, 
    params.intakeParameters.loadCarrierOrientation
  ]);

  // building arrows
  useEffect(() => {
    const outArrow = [];

    for (let i = 0; i < canvasPoints.length - 2; i += 2) {
      let pointA = new Vector2(canvasPoints[i], canvasPoints[i + 1])
      let pointB = new Vector2(canvasPoints[i + 2], canvasPoints[i + 3]);

      if (i === 0) {
        pointA = pointAlongVector(pointA, pointB, deliveryVehicleLength);
      } else if (i === canvasPoints.length - 4) {
        pointB = pointAlongVector(pointB, pointA, intakeVehicleLength);
      }

      const segmentVector = new Vector2().subVectors(pointA, pointB);
      let angle = angleBetweenVectors(segmentVector, new Vector2(1, 0)) * RAD2DEG + 180;
      const distance = pointA.distanceTo(pointB);
      const number = Math.floor(distance / 100);

      for (let j = 1; j < number; j++) {
        const factor = j / number;
        const position = pointAlongVector(pointA, pointB, factor * distance);

        const arrow = {
          x: position.x,
          y: position.y,
          rotation: angle,
          id: `arrow-${i}-${j}`,
        };
        outArrow.push(arrow);
      }
    }

    setArrows(outArrow);
  }, [deliveryVehicleLength, intakeVehicleLength, canvasPoints]);

  return (
    <>
      <Line
        key='line'
        points={canvasPoints}
        stroke={theme.palette.neutral.lighter}
        strokeWidth={width}
        lineCap='butt'
        listening={false}
      />

      {/* direction arrow  */}
      {arrows &&
        arrowImage &&
        arrows.map((arrow) => (
          <Image
            image={arrowImage}
            key={arrow.id}
            rotation={arrow.rotation}
            x={arrow.x}
            y={arrow.y}
            offsetX={arrowImage.width / 2}
            offsetY={arrowImage.height / 2}
            listening={false}
            strokeEnabled={false}
          />
        ))}

      {ends &&
        vehicles &&
        loads &&
        [...ends, ...vehicles, ...loads].map((object) => (
          <Line
            key={object.id}
            points={object.points}
            stroke={object.color}
            strokeWidth={object.width}
            lineCap='butt'
            listening={false}
          />
        ))}

      {vehicleImage &&
        directionImages.map((object) => (
          <Image
            key={object.id}
            image={vehicleImage}
            x={object.x}
            y={object.y}
            rotation={object.rotation}
            offsetX={vehicleImage.width / 2}
            offsetY={vehicleImage.height / 2}
            listening={false}
            strokeEnabled={false}
            {...areaIconAutoScaleAttr}
          />
        ))}
    </>
  );
};

export const ProcessTwoEndPointRenderer = memo(ProcessTwoEndPointRendererComponent);
