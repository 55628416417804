export enum FloorplanViewMode {
  GRID = 'grid',
  LIST = 'list',
}

export enum SortingCriteria {
  NAME = 'name',
  LAST_MODIFIED = 'lastModified',
  LAST_MODIFIED_BY = 'lastModifiedBy',
  CREATED = 'created',
}

export enum Order {
  DESC = 'DESC',
  ASC = 'ASC',
}

export const DEFAULT_SORTING_CRITERIA = SortingCriteria.NAME;

export const sortingCriteriaToModelFieldMap = {
  [SortingCriteria.NAME]: 'name',
  [SortingCriteria.CREATED]: 'created',
  [SortingCriteria.LAST_MODIFIED]: 'lastUpdated',
  [SortingCriteria.LAST_MODIFIED_BY]: 'lastUpdatedBy',
} as const;

export const SORTING_OPTIONS = {
  [SortingCriteria.NAME]: {
    id: SortingCriteria.NAME,
    i18nKey: 'common:sorting.option.alphabetical',
    orderOptions: {
      ASC: {
        id: Order.ASC,
        i18nKey: 'common:sorting.order.alphabetical.asc',
      },
      DESC: {
        id: Order.DESC,
        i18nKey: 'common:sorting.order.alphabetical.desc',
      },
    },
  },
  [SortingCriteria.CREATED]: {
    id: SortingCriteria.CREATED,
    i18nKey: 'common:sorting.option.date_created',
    orderOptions: {
      DESC: {
        id: Order.DESC,
        i18nKey: 'common:sorting.order.newest_first',
      },
      ASC: {
        id: Order.ASC,
        i18nKey: 'common:sorting.order.oldest_first',
      },
    },
  },
  [SortingCriteria.LAST_MODIFIED]: {
    id: SortingCriteria.LAST_MODIFIED,
    i18nKey: 'common:sorting.option.last_modified',
    orderOptions: {
      DESC: {
        id: Order.DESC,
        i18nKey: 'common:sorting.order.newest_first',
      },
      ASC: {
        id: Order.ASC,
        i18nKey: 'common:sorting.order.oldest_first',
      },
    },
  },
  [SortingCriteria.LAST_MODIFIED_BY]: {
    id: SortingCriteria.LAST_MODIFIED_BY,
    i18nKey: 'common:sorting.option.last_modified_by',
    orderOptions: {
      ASC: {
        id: Order.ASC,
        i18nKey: 'common:sorting.order.alphabetical.asc',
      },
      DESC: {
        id: Order.DESC,
        i18nKey: 'common:sorting.order.alphabetical.desc',
      },
    },
  },
};

export const DEFAULT_SORT_ORDER_OPTIONS = {
  [SortingCriteria.NAME]: SORTING_OPTIONS[SortingCriteria.NAME].orderOptions.ASC.id,
  [SortingCriteria.CREATED]: SORTING_OPTIONS[SortingCriteria.CREATED].orderOptions.DESC.id,
  [SortingCriteria.LAST_MODIFIED]:
    SORTING_OPTIONS[SortingCriteria.LAST_MODIFIED].orderOptions.DESC.id,
  [SortingCriteria.LAST_MODIFIED_BY]:
    SORTING_OPTIONS[SortingCriteria.LAST_MODIFIED_BY].orderOptions.ASC.id,
};

export const gridViewEnabledSortingCriteria = [
  SortingCriteria.NAME,
  SortingCriteria.LAST_MODIFIED,
  SortingCriteria.CREATED,
];
