import { SvgIcon } from '@mui/material';

export default function HighwayIcon(props) {
  return (
    <SvgIcon {...props} fill='none'>
      <path
        d='M4 24C3.724 24 3.5 23.776 3.5 23.5V0.5C3.5 0.224 3.724 0 4 0C4.276 0 4.5 0.224 4.5 0.5V23.5C4.5 23.776 4.276 24 4 24Z'
        strokeWidth='0.4'
        stroke="currentColor"
      />
      <path
        d='M20 24C19.724 24 19.5 23.776 19.5 23.5V0.5C19.5 0.224 19.724 0 20 0C20.276 0 20.5 0.224 20.5 0.5V23.5C20.5 23.776 20.276 24 20 24Z'
        strokeWidth='0.4'
        stroke="currentColor"
      />
      <path
        d='M12 6C11.724 6 11.5 5.776 11.5 5.5V2.5C11.5 2.224 11.724 2 12 2C12.276 2 12.5 2.224 12.5 2.5V5.5C12.5 5.776 12.276 6 12 6Z'
        strokeWidth='0.4'
        stroke="currentColor"
      />
      <path
        d='M12 14C11.724 14 11.5 13.776 11.5 13.5V10.5C11.5 10.224 11.724 10 12 10C12.276 10 12.5 10.224 12.5 10.5V13.5C12.5 13.776 12.276 14 12 14Z'
        strokeWidth='0.4'
        stroke="currentColor"
      />
      <path
        d='M12 22C11.724 22 11.5 21.776 11.5 21.5V18.5C11.5 18.224 11.724 18 12 18C12.276 18 12.5 18.224 12.5 18.5V21.5C12.5 21.776 12.276 22 12 22Z'
        strokeWidth='0.4'
        stroke="currentColor"
      />
    </SvgIcon>
  );
}
