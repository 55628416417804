import { LayerNames } from '@/modules/common/types/layers';
import { useFlow } from '@/modules/flows/hooks/useFlow';
import { layoutAllFlowIds } from '@/modules/flows/store/layout';
import {
  addInvisibleElementsSelector,
  invisibleElementsAtom,
  layersShowSelector,
  removeInvisibleElementsSelector,
} from '@modules/layers';
import { MenuItem } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import {
  relatedShapeIdsState,
  showRelatedFlowsVisibilityOptionSelector,
} from '../../../../store/recoil/workspace/contextMenu';
import { useContextMenu } from '../../hooks';

export const ToggleFlowsOption = () => {
  const show = useRecoilValue(showRelatedFlowsVisibilityOptionSelector);

  if (!show) return null;

  return <ToggleFlowsOptionComponent />;
};

const ToggleFlowsOptionComponent = () => {
  const { t } = useTranslation();
  const { hide } = useContextMenu();
  const { getRelatedFlowIdsOfShapes } = useFlow();
  const [flowIds, setFlowIds] = useState<string[]>([]);
  const contextRelatedShapeIds = useRecoilValue(relatedShapeIdsState);

  const updateFlowIds = useCallback(
    async (shapeIds: string[]) => {
      if (shapeIds.length === 0) setFlowIds([]);
      const relatedFlowIds = await getRelatedFlowIdsOfShapes(shapeIds);
      setFlowIds(relatedFlowIds);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    updateFlowIds(contextRelatedShapeIds);
  }, [contextRelatedShapeIds, updateFlowIds]);

  const toggleFlowVisibility = useRecoilCallback(
    ({ set, snapshot }) =>
      async (relatedFlowIds: string[]) => {
        if (!relatedFlowIds?.length) return;

        const [invisibleElements, flowLayerIsVisible] = await Promise.all([
          snapshot.getPromise(invisibleElementsAtom),
          snapshot.getPromise(layersShowSelector(LayerNames.FLOWS)),
        ]);

        const allInvisibleElements = new Set(invisibleElements);
        const hiddenRelatedFlowsIds = relatedFlowIds.filter((id) => allInvisibleElements.has(id));

        if (!flowLayerIsVisible) {
          const allLayoutFlowIds = await snapshot.getPromise(layoutAllFlowIds);
          const unrelatedFlowIds = allLayoutFlowIds.filter((id) => !relatedFlowIds.includes(id));
          const unrelatedVisibleFlowIds = unrelatedFlowIds.filter(
            (id) => !allInvisibleElements.has(id),
          );

          set(addInvisibleElementsSelector, unrelatedVisibleFlowIds);
          set(removeInvisibleElementsSelector, hiddenRelatedFlowsIds);
          set(layersShowSelector(LayerNames.FLOWS), true);

          return;
        }

        if (hiddenRelatedFlowsIds.length) {
          set(removeInvisibleElementsSelector, hiddenRelatedFlowsIds);
        } else {
          set(addInvisibleElementsSelector, relatedFlowIds);
        }
      },
    [],
  );

  const onClick = useCallback(() => {
    toggleFlowVisibility(flowIds);
    hide();
  }, [toggleFlowVisibility, flowIds, hide]);

  return (
    <MenuItem disabled={!flowIds.length} onClick={onClick} component='button'>
      {t('interface:context_menu.workspace.show_hide_flows')}
    </MenuItem>
  );
};
