import { memo } from 'react';
import { useRecoilValue } from 'recoil';

import { useShapeHover } from '@modules/common/hooks/useShapeHover';
import { shapeTypeShowSelector } from '@/modules/layers';
import shapeState, { HighwayShape } from '@recoil/shape';
import { ShapeProxy } from '../ShapeProxy';
import { HighwayRender } from './HighwayRender';

type RoadProps = {
  id: string;
};

const RoadComponent = ({ id }: RoadProps) => {
  const shape = useRecoilValue(shapeState(id)) as HighwayShape;
  const { onMouseEnter, onMouseLeave } = useShapeHover(id);
  const layerShown = useRecoilValue(shapeTypeShowSelector(shape?.type));

  if (!shape.id || !layerShown) return null;

  return (
    <>
      <HighwayRender
        shapeId={id}
        x={shape.properties.x / 10}
        y={shape.properties.y / 10}
        width={shape.properties.width / 10}
        height={shape.properties.height / 10}
        r={-shape.properties.r}
        margin={shape.parameters.margin / 10}
        gap={shape.parameters.gap / 10}
        laneDirection={shape.parameters.laneDirection}
        supportedVehicleIds={shape.parameters.supportedVehicleIds}
      />

      {/* Show Shape ID on rectangle. Handy for debugging */}
      {/* <Text {...propertiesCanvas} fontSize={80} listening={false} text={id} /> */}

      <ShapeProxy
        id={id}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        x={shape.properties.x / 10}
        y={shape.properties.y / 10}
        width={shape.properties.width / 10}
        height={shape.properties.height / 10}
        angle={-shape.properties.r}
      />
    </>
  );
};

export const Road = memo(RoadComponent);
