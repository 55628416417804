import { ElementSelectedEventArgs } from '@thive/canvas';

import { Module } from '@/modules/common/types/navigation';
import { useShapeStore } from '@/modules/salesWorkspace/store/useShapeStore';
import navAtom from '@/store/recoil/nav/atom';
import { useRecoilCallback } from 'recoil';
import { useGateStore } from '../store';

export const useSelectEventListeners = () => {
  const onSelected = useRecoilCallback(({ snapshot }) =>
    async (e: ElementSelectedEventArgs) => {
      const nav = await snapshot.getPromise(navAtom)

      if (nav === Module.COMMISSIONING_GATE) {
        useShapeStore.getState().selectShape(null);
        useGateStore.getState().selectGate(e.elements.length > 0 ? e.elements.at(0).id : null);
        return
      }

      useGateStore.getState().selectGate(null);
      useShapeStore.getState().selectShape(e.elements.length > 0 ? e.elements.at(0).id : null);
    }, []);

  return {
    onSelected,
  };

}