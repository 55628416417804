import { v4 as uuid } from 'uuid';
import { Color, Element } from '@thive/canvas';
import { Vector2, Vector3 } from 'three';

export const map = (points: Vector2[]): Element[] => {
  const elements: Element[] = [];
  const color = Color.fromHex('#0288f5');

  points.forEach((point, index) => {
    elements.push({
      type: 'Circle',
      name: `Point ${index}`,
      id: uuid(),
      radius: 10,
      center: new Vector3(point.x, point.y),
      fill: color,
      interactivity: {
        selectable: false,
      },
    });
  });

  return elements;
};
