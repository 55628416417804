import { useCallback } from 'react';
import { NumberInput } from '@common/components/inputs';
import { UserPreferenceName } from '@modules/userPreferences';
import { useUserPreference, useUpdateUserPreferences } from '@/modules/userPreferences/hooks';
import { NUDGE_MAX, NUDGE_MIN } from '@/modules/workspace/constants/workspace';
import { CONTEXT, contextState } from '@/store/recoil/input';
import { useSetRecoilState } from 'recoil';

export const NudgeInput = () => {
  const setContext = useSetRecoilState(contextState);
  const nudgeAmount: number = useUserPreference(UserPreferenceName.NUDGE_AMOUNT);
  const { updateUserPreference } = useUpdateUserPreferences();

  const onNudgeChange = useCallback(
    (value: number) => {
      if (value >= NUDGE_MIN && value <= NUDGE_MAX) {
        updateUserPreference(UserPreferenceName.NUDGE_AMOUNT, value);
      }
    },
    [updateUserPreference],
  );

  return (
    <NumberInput
      sx={{ width: '100%' }}
      value={nudgeAmount}
      onChange={onNudgeChange}
      onFocus={() => setContext(CONTEXT.SETTINGS)}
      onBlur={() => setContext(CONTEXT.WORKSPACE)}
    />
  );
};
