import { useCallback, useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { keyDown, keyUp } from '@/helpers/keyboard';
import { useSaveData } from '@/modules/commissioning';
import { HistoryManager } from '@/modules/commissioning/utils';
import { KEYCODE, contextState, keyboardState } from '@/store/recoil/input';

export function useKeyboardEvents() {
  const [pressedKey, setPressedKey] = useRecoilState(keyboardState);
  const context = useRecoilValue(contextState);
  const contextRef = useRef(context);
  const { save } = useSaveData();

  const keyDownHandler = useCallback(
    (keyboardEvent) => {
      keyDown(keyboardEvent, setPressedKey, contextRef.current);
    },
    [setPressedKey],
  );

  const keyUpHandler = useCallback(
    () => {
      keyUp(setPressedKey);
    },
    [setPressedKey],
  );

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler);
    window.addEventListener('keyup', keyUpHandler);

    return () => {
      window.removeEventListener('keydown', keyDownHandler);
      window.removeEventListener('keyup', keyUpHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (pressedKey) {
      case KEYCODE.UNDO: {
        HistoryManager.undo();
        save();
        break;
      }
      case KEYCODE.REDO: {
        HistoryManager.redo();
        save();
        break;
      }
      default:
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressedKey, save]);
}
