import { useEffect, useState } from 'react';
import { EventNames, useEventBus } from '../../../hooks';

export const useViewMode = () => {
  const [viewMode, setViewMode] = useState('perspective');
  const { emit } = useEventBus();

  useEffect(() => {
    switch (viewMode) {
      case 'top':
        emit(EventNames.VisualizationWebGLSend, {
          target: 'MainCamera',
          action: 'UseTopViewCamera',
        });
        break;
      case 'perspective':
        emit(EventNames.VisualizationWebGLSend, {
          target: 'MainCamera',
          action: 'UsePerspectiveCamera',
        });
        break;
      case 'orthographic':
        emit(EventNames.VisualizationWebGLSend, {
          target: 'MainCamera',
          action: 'UseOrthographicCamera',
        });
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewMode]);

  return { viewMode, setViewMode };
};
