import { AdditionalData } from '@modules/common/types/floorPlan';
import { DEFAULT_ENABLED_VEHICLE_TYPE_IDS } from '../constants';
import { UnifiedVehicle } from '../types';

/**
 * Prepares vehicle type state to be loaded into the app
 */
export const prepareToLoad = (
  additionalData: AdditionalData,
  allVehicles: UnifiedVehicle[],
): UnifiedVehicle[] => {
  const vehicles = allVehicles.filter((vehicle) =>
    additionalData.vehicleTypes.find((enabledVehicles) => enabledVehicles === vehicle.id),
  );

  return vehicles.length > 0
    ? vehicles
    : allVehicles.filter((item) => DEFAULT_ENABLED_VEHICLE_TYPE_IDS.includes(item.id));
};

/**
 * Prepares vehicle type state to be saved
 */
export const prepareToSave = (enabledVehicles: UnifiedVehicle[]): string[] => enabledVehicles.map((item) => item.id);
