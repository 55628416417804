import { SvgIcon } from '@mui/material';

function LinkIcon({ className = '', ...props }) {
  return (
    <SvgIcon width='24' height='24' {...props}>
      <path
        d='M10.6152 16.0767H6.99978C5.87183 16.0767 4.91035 15.6793 4.11535 14.8845C3.32035 14.0897 2.92285 13.1285 2.92285 12.0009C2.92285 10.8732 3.32035 9.91164 4.11535 9.11613C4.91035 8.32061 5.87183 7.92285 6.99978 7.92285H10.6152V8.92285H6.99978C6.15363 8.92285 5.42927 9.22413 4.8267 9.8267C4.22414 10.4293 3.92285 11.1536 3.92285 11.9998C3.92285 12.8459 4.22414 13.5703 4.8267 14.1729C5.42927 14.7754 6.15363 15.0767 6.99978 15.0767H10.6152V16.0767ZM8.49978 12.4998V11.4998H15.4998V12.4998H8.49978ZM13.3844 16.0767V15.0767H16.9998C17.8459 15.0767 18.5703 14.7754 19.1729 14.1729C19.7754 13.5703 20.0767 12.8459 20.0767 11.9998C20.0767 11.1536 19.7754 10.4293 19.1729 9.8267C18.5703 9.22413 17.8459 8.92285 16.9998 8.92285H13.3844V7.92285H16.9998C18.1277 7.92285 19.0892 8.32024 19.8842 9.11503C20.6792 9.90983 21.0767 10.8711 21.0767 11.9987C21.0767 13.1263 20.6792 14.0879 19.8842 14.8834C19.0892 15.6789 18.1277 16.0767 16.9998 16.0767H13.3844Z'
        stroke='none'
        fill='currentColor'
      />
    </SvgIcon>
  );
}

export default LinkIcon;
