import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../common';
import workspaceAtom, { WorkspaceAtom } from './atom';

export const pannableSelector = selector<boolean>({
  key: 'pannableSelector',
  get: ({ get }) => get<WorkspaceAtom>(workspaceAtom).pannable,
  set: ({ get, set }, newValue) => {
    set(workspaceAtom, { ...get(workspaceAtom), pannable: newValue });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
