import { SvgIcon } from '@mui/material';
import { memo } from 'react';

const DotsIcon = ({ className = '', size = 24, color = 'currentColor', ...rest }) => (
  <SvgIcon
    viewBox='0 0 24 25'
    sx={{ width: size, height: size, fontSize: `${size}px`, fill: color }}
    className={className}
    {...rest}
  >
    <path
      d='M6.46155 13.5C6.18655 13.5 5.95113 13.4021 5.7553 13.2063C5.55946 13.0104 5.46155 12.775 5.46155 12.5C5.46155 12.225 5.55946 11.9896 5.7553 11.7938C5.95113 11.5979 6.18655 11.5 6.46155 11.5C6.73656 11.5 6.97199 11.5979 7.16782 11.7938C7.36366 11.9896 7.46157 12.225 7.46157 12.5C7.46157 12.775 7.36366 13.0104 7.16782 13.2063C6.97199 13.4021 6.73656 13.5 6.46155 13.5ZM12 13.5C11.725 13.5 11.4896 13.4021 11.2938 13.2063C11.0979 13.0104 11 12.775 11 12.5C11 12.225 11.0979 11.9896 11.2938 11.7938C11.4896 11.5979 11.725 11.5 12 11.5C12.275 11.5 12.5104 11.5979 12.7063 11.7938C12.9021 11.9896 13 12.225 13 12.5C13 12.775 12.9021 13.0104 12.7063 13.2063C12.5104 13.4021 12.275 13.5 12 13.5ZM17.5385 13.5C17.2635 13.5 17.0281 13.4021 16.8322 13.2063C16.6364 13.0104 16.5385 12.775 16.5385 12.5C16.5385 12.225 16.6364 11.9896 16.8322 11.7938C17.0281 11.5979 17.2635 11.5 17.5385 11.5C17.8135 11.5 18.0489 11.5979 18.2447 11.7938C18.4406 11.9896 18.5385 12.225 18.5385 12.5C18.5385 12.775 18.4406 13.0104 18.2447 13.2063C18.0489 13.4021 17.8135 13.5 17.5385 13.5Z'
      fill={color}
    />
  </SvgIcon>
);

export default memo(DotsIcon);
