import { useFloorPlanState } from '@/modules/floorplan/hooks/useFloorPlanState';
import { Accordion } from '@common/components/Accordion';
import { NumberInput, SelectInput } from '@common/components/inputs';
import { UserPreferenceName } from '@modules/userPreferences';
import { useUpdateUserPreferences } from '@modules/userPreferences/hooks';
import { Stack } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { ObstacleType } from '@/modules/common/types/shapes';
import { CONTEXT, contextState } from '@/store/recoil/input';
import { unitSelector } from '@/store/recoil/workspace';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import Label from '../Label';
import { obstacleHeightState, obstacleTypeState } from '../store/obstacle';

export default function ObstacleProperty() {
  const { saveFloorPlan } = useFloorPlanState();
  const [obstacleType, setObstacleType] = useRecoilState(obstacleTypeState);
  const [obstacleHeight, setObstacleHeight] = useRecoilState(obstacleHeightState);
  const unit = useRecoilValue(unitSelector);
  const { t } = useTranslation(['interface', 'common']);
  const setContext = useSetRecoilState(contextState);
  const { updateUserPreference } = useUpdateUserPreferences();
  const mode = useRecoilValue(modeSelector);

  const onObstacleTypeChange = useCallback(
    (value) => {
      try {
        setObstacleType(value);
        saveFloorPlan();
        updateUserPreference(UserPreferenceName.BUILDING_OBSTACLE_TYPE, value);
      } catch (e) {
        return { error: e.message };
      }
    },
    [setObstacleType, saveFloorPlan, updateUserPreference],
  );

  const onHeightChange = useCallback(
    (value) => {
      try {
        setObstacleHeight(value);
        saveFloorPlan();
        updateUserPreference(UserPreferenceName.BUILDING_OBSTACLE_HEIGHT, value);
      } catch (e) {
        return { error: e.message };
      }
    },
    [setObstacleHeight, saveFloorPlan, updateUserPreference],
  );

  return (
    <Accordion
      title={t('interface:properties.obstacle.obstacle_title', 'Obstacle')}
      defaultExpanded
      sx={{ px: 2 }}
    >
      <Stack direction='column' spacing={1} sx={{ '&:hover': { cursor: 'default' } }}>
        <Label label={t('interface:properties.obstacle.type', 'Obstacle Type')}>
          <SelectInput
            disabled={mode !== WorkspaceMode.EDITABLE}
            options={Object.entries(ObstacleType).map(([k, v]) => ({ label: k, value: v }))}
            onChange={onObstacleTypeChange}
            value={obstacleType}
          />
        </Label>
        {obstacleType === ObstacleType.Box && (
          <Label label={t('interface:properties.wall.height', 'Height')}>
            <NumberInput
              disabled={mode !== WorkspaceMode.EDITABLE}
              value={obstacleHeight}
              errorType='floating'
              unit={t(`interface:settings.units.${unit}`, unit)}
              onFocus={() => setContext(CONTEXT.PROPERTYPANEL)}
              onBlur={() => setContext(CONTEXT.WORKSPACE)}
              onChange={onHeightChange}
            />
          </Label>
        )}
      </Stack>
    </Accordion>
  );
}
