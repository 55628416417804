import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ThreeSixty } from '@mui/icons-material';
import { Stack, Paper } from '@mui/material';
import { StyledTooltip } from '@helpers/styles';
import { useLoading } from '../../hooks/useLoading';
import { useTool } from './hooks';
import getIcon from '@/components/Toolbar3/getIcons';
import { ToolbarIconButton } from '@/components/Toolbar3/ToolbarButton';

const groupBgStyle = {
  borderRadius: '4px',
};

type RenderButtonOptions = {
  title: string;
  disabled?: boolean;
  onClick: () => void;
  selected?: boolean;
  icon: ReactNode;
};

const renderButton = ({ title, disabled, onClick, selected, icon }: RenderButtonOptions) => {
  const button = (
    <ToolbarIconButton
      disabled={disabled}
      onClick={onClick}
      style={{ borderRadius: 5, alignSelf: 'center', stroke: 'none' }}
      type={(selected ? 'Selected' : 'Deselected') as any}
    >
      {icon}
    </ToolbarIconButton>
  );

  return disabled ? (
    button
  ) : (
    <StyledTooltip title={title} placement='right'>
      <div>{button}</div>
    </StyledTooltip>
  );
};

export function VisualizationToolsetVertical() {
  const { isLoaded } = useLoading();
  const { tool, setTool } = useTool();
  const { t } = useTranslation('interface');

  if (isLoaded)
    return (
      <Stack spacing={2}>
        <Paper elevation={1} sx={groupBgStyle}>
          {renderButton({
            icon: getIcon('cursor'),
            title: t('visualization.toolbar.select_button.tooltip'),
            selected: tool === 'select',
            onClick: () => setTool('select'),
          })}
          {renderButton({
            icon: getIcon('pan'),
            title: t('visualization.toolbar.pan_button.tooltip'),
            selected: tool === 'pan',
            onClick: () => setTool('pan'),
          })}
          {renderButton({
            icon: <ThreeSixty />,
            title: t('visualization.toolbar.orbit_button.tooltip'),
            selected: tool === 'orbit',
            onClick: () => setTool('orbit'),
          })}
        </Paper>
      </Stack>
    );
  return <></>;
}
