import { Box, CircularProgress, Stack, SxProps, Theme } from '@mui/material';
import { theme} from '@modules/common/components/theme';

export type Props = {
  progress?: number;
  sx?: SxProps<Theme>;
};

export function LoadingPanel({ progress, sx }: Props) {
  return (
    <Box sx={{ backgroundColor: theme.palette.shades.light, height: '100%', ...sx }}>
      <Stack alignItems='center' justifyContent='center' height='100%'>
        <CircularProgress
          disableShrink
          variant={progress === undefined ? 'indeterminate' : 'determinate'}
          value={progress}
        />
      </Stack>
    </Box>
  );
}
