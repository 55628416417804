import {Stack, TextField, Typography } from '@mui/material';


const textFieldStyle = {
  height: '40px', 
  minHeight: '28px', 
  marginTop: -1, 
  borderBottom: 0,

  '& .MuiInputBase-root': {
    border: 0
  },

  '& .MuiInputBase-input': {
      paddingLeft: 0,
      color: 'neutral.main'
  },

  '& fieldset' : {
    border: 0,

  }
}

export function ValueAdjustButton({ label, value, onValueChange }) {
  return (
    <Stack direction='row' alignItems='baseline' justifyContent="space-between">
      <Typography width={100}>{label}</Typography>
      <Stack direction='row' alignItems='center'>
        <TextField
          onChange={(e) => onValueChange(e.target.value)}
          value={value}
          sx={textFieldStyle}
        />
      </Stack>
    </Stack>
  );
}
