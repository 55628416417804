import { IconButton } from '@mui/material';

import { StyledTooltip } from '@/helpers/styles';
import { Undo } from '../../assets/icons';

export const UndoButton = ({ onClick, disabled }) => {
  const btn = (
    <IconButton onClick={onClick} disabled={disabled}>
      <Undo />
    </IconButton>
  );

  if (disabled) return btn;

  return (
    <StyledTooltip title='Undo' placement='right'>
      {btn}
    </StyledTooltip>
  );
};
