import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Group, Rect } from 'react-konva';
import { useRecoilValue } from 'recoil';
import Konva from 'konva';

import { toCanvasX, toCanvasY } from '@/helpers/draw';
import { numberOfColumns, numberOfRows } from '@/modules/common/helpers/loadCarrier';
import { InvalidShape } from '@/modules/workspace/components/InvalidShape';
import { isVertical } from '@/modules/workspace/helpers/shape';
import { useColors } from '@/modules/workspace/hooks';
import {
  AreaDirection,
  AreaDistribution,
  AreaError,
  ShapeType,
} from '@modules/common/types/shapes';
import { AREA_RENDER_ERROR } from '@modules/workspace/types/errors';
import { orientedLoadCarriersBoundingBox } from '@recoil/loadCarrierTypes';
import { areaParameters } from '@recoil/shape';
import {
  supportedVehiclesLengthSelector,
  supportedVehiclesWidthSelector,
} from '@/modules/vehicles';
import { LOADING_ANIMATION_MIN_OPACITY, useLoadingAnimation } from './useLoadingAnimation';
import { useError } from './useError';

export type StorageRenderProps = {
  shapeId: string;
  type: ShapeType;
  isDrawing: boolean;
  isLoading: boolean;
  x: number;
  y: number;
  width: number;
  height: number;
  r: number;
};

const StorageRenderComponent: React.FC<StorageRenderProps> = ({
  shapeId,
  type,
  isDrawing,
  isLoading,
  x: shapeX,
  y: shapeY,
  width: shapeWidth,
  height: shapeHeight,
  r: shapeR,
}) => {
  // recoil state
  const params = useRecoilValue(areaParameters(shapeId));
  const vehicleWidth = useRecoilValue(
    supportedVehiclesWidthSelector(params.supportedVehicleIds || []),
  );
  const vehicleLength = useRecoilValue(
    supportedVehiclesLengthSelector(params.supportedVehicleIds || []),
  );
  const { laneColor, loadBoxColor, shapeColor } = useColors(shapeId);
  const orientedLoadsBoundingBox = useRecoilValue(orientedLoadCarriersBoundingBox(shapeId));

  // memoized load-carriers-bounding-box to use in dependency arrays. Workaround for recoil currently not supporting memoized selectorFamily return values.
  const loadCarriersBoundingBox = useMemo(
    () => orientedLoadsBoundingBox,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orientedLoadsBoundingBox.length, orientedLoadsBoundingBox.width],
  );

  // local state
  const [vehicleBoxes, setVehicleBoxes] = useState([]);
  const [loadBoxes, setLoadBoxes] = useState([]);
  const { t } = useTranslation(['errors']);

  const currentError = useError(shapeId);

  const renderApproximations = useMemo(
    () => isDrawing || isLoading || currentError === AreaError.PartialGenerationFailed,
    [isDrawing, isLoading, currentError],
  );

  const renderErrorShape = useMemo(
    () => currentError && currentError !== AreaError.PartialGenerationFailed,
    [currentError],
  );

  useEffect(() => {
    if (!renderApproximations) {
      return;
    }

    const outLoad = [];

    // Direction
    let { direction } = params;

    // Gap
    const gap = params.gap / 10;

    // Margin
    const margin = params.margin / 10;
    const marginMajorAxis = margin;
    let marginMinorAxis = 0;

    // Amount
    let amountX = 0;
    let amountY = 0;
    let boxWidth = 0;
    let boxHeight = 0;
    const length = isVertical(direction) ? shapeWidth : shapeHeight;
    const width = isVertical(direction) ? shapeHeight : shapeWidth;
    const amount = numberOfColumns(length, gap, margin, loadCarriersBoundingBox.length);
    if (amount < 1) {
      return;
    }

    const subAreaSize = loadCarriersBoundingBox.width + 5;
    const lengthForLoad = width - vehicleLength + loadCarriersBoundingBox.width;
    const numberOfLoads = numberOfRows(lengthForLoad, loadCarriersBoundingBox.width, 5);
    if (numberOfLoads < 1) {
      return;
    }
    const lengthUsedByLoads = numberOfLoads * loadCarriersBoundingBox.width + numberOfLoads * 5;
    const firstAreaSize = vehicleLength + (lengthForLoad - lengthUsedByLoads);

    if (isVertical(direction)) {
      amountX = amount;
      amountY = numberOfLoads;
      boxWidth = loadCarriersBoundingBox.length;
      boxHeight = loadCarriersBoundingBox.width;
    } else {
      amountY = amount;
      amountX = numberOfLoads;
      boxWidth = loadCarriersBoundingBox.width;
      boxHeight = loadCarriersBoundingBox.length;
    }

    // Distribution
    let offset = 0;
    let extraGap = 0;
    let extraMargin = 0;
    const overflow =
      length - loadCarriersBoundingBox.length * amount - gap * (amount - 1) - margin * 2;
    if (params.distribution === AreaDistribution.EXTRA_SPACE_OVER_MARGIN) {
      extraMargin = overflow / 2;
    } else if (params.distribution === AreaDistribution.EXTRA_SPACE_OVER_GAP) {
      if (amount > 1) {
        extraGap = overflow / (amount - 1);
      } else {
        offset = overflow / 2;
      }
    }

    if (direction === AreaDirection.DOWN || direction === AreaDirection.RIGHT) {
      marginMinorAxis = -(firstAreaSize - loadCarriersBoundingBox.width);
    }

    // Building load boxes
    for (let j = 0; j < amountY; ++j) {
      for (let i = 0; i < amountX; ++i) {
        let x = 0;
        let y = 0;
        const constants = marginMajorAxis + extraMargin + offset;
        const increment = loadCarriersBoundingBox.length + gap + extraGap;

        if (isVertical(direction)) {
          x = i * increment + constants;
          y =
            firstAreaSize -
            loadCarriersBoundingBox.width +
            marginMinorAxis +
            (j * (width - firstAreaSize + subAreaSize)) / amountY;
        } else {
          x =
            firstAreaSize -
            loadCarriersBoundingBox.width +
            marginMinorAxis +
            (i * (width - firstAreaSize + subAreaSize)) / amountX;
          y = j * increment + constants;
        }

        const load = {
          id: `loadBox_${i}_${j}`,
          x,
          y,
          w: boxWidth,
          h: boxHeight,
          xIndex: i,
          yIndex: j,
        };
        outLoad.push(load);
      }
    }
    setLoadBoxes(outLoad);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    renderApproximations,
    loadCarriersBoundingBox,
    type,
    params.supportedLoadCarriersIds,
    params.direction,
    params.gap,
    params.margin,
    vehicleWidth,
    vehicleLength,
    shapeWidth,
    shapeHeight,
  ]);

  useEffect(() => {
    if (!renderApproximations) {
      setVehicleBoxes([]);
      setLoadBoxes([]);
    }
  }, [renderApproximations]);

  useEffect(() => {
    const outVehicle = [];

    loadBoxes.forEach((lb) => {
      if (params.direction === AreaDirection.DOWN && lb.yIndex === 0) {
        const vehicle = {
          id: `vehicleBox-${lb.id}`,
          x: toCanvasX(lb.x + lb.w / 2, vehicleWidth),
          y: 0,
          w: vehicleWidth,
          h: shapeHeight,
        };
        outVehicle.push(vehicle);
      } else if (params.direction === AreaDirection.UP && lb.yIndex === 0) {
        const vehicle = {
          id: `vehicleBox-${lb.id}`,
          x: toCanvasX(lb.x + lb.w / 2, vehicleWidth),
          y: 0,
          w: vehicleWidth,
          h: shapeHeight,
        };
        outVehicle.push(vehicle);
      } else if (params.direction === AreaDirection.RIGHT && lb.xIndex === 0) {
        const vehicle = {
          id: `vehicleBox-${lb.id}`,
          x: 0,
          y: toCanvasY(lb.y + lb.h / 2, vehicleWidth),
          w: shapeWidth,
          h: vehicleWidth,
        };
        outVehicle.push(vehicle);
      } else if (params.direction === AreaDirection.LEFT && lb.xIndex === 0) {
        const vehicle = {
          id: `vehicleBox-${lb.id}`,
          x: 0,
          y: toCanvasY(lb.y + lb.h / 2, vehicleWidth),
          w: shapeWidth,
          h: vehicleWidth,
        };
        outVehicle.push(vehicle);
      }
    });
    setVehicleBoxes(outVehicle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadBoxes]);

  const { loadingRefs } = useLoadingAnimation(isLoading);

  return (
    <Group x={shapeX} y={shapeY} rotation={shapeR}>
      {!renderErrorShape ? (
        <Rect
          width={shapeWidth}
          height={shapeHeight}
          fill={shapeColor}
          listening={false}
          strokeEnabled={false}
        />
      ) : shapeWidth !== 0 || shapeHeight !== 0 ? (
        <InvalidShape
          width={shapeWidth}
          height={shapeHeight}
          message={t(AREA_RENDER_ERROR[currentError].i18nMessageKey)}
        />
      ) : null}

      {renderApproximations &&
        vehicleBoxes &&
        vehicleBoxes.map((v, index) => (
          <Rect
            ref={(node: Konva.Rect) => node && loadingRefs.current.set(`vehicle_${index}`, node)}
            key={v.id}
            x={v.x}
            y={v.y}
            width={v.w}
            height={v.h}
            fill={laneColor}
            opacity={isLoading ? LOADING_ANIMATION_MIN_OPACITY : 1}
            listening={false}
            strokeEnabled={false}
          />
        ))}

      {renderApproximations &&
        loadBoxes &&
        loadBoxes.map((v, index) => (
          <Rect
            ref={(node: Konva.Rect) => node && loadingRefs.current.set(`load_${index}`, node)}
            key={v.id}
            x={v.x}
            y={v.y}
            width={v.w}
            height={v.h}
            fill={loadBoxColor}
            opacity={isLoading ? LOADING_ANIMATION_MIN_OPACITY : 1}
            listening={false}
            strokeEnabled={false}
          />
        ))}
    </Group>
  );
};

export const StorageRender = memo(StorageRenderComponent);
