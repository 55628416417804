import { useRecoilCallback } from 'recoil';

import { AngledHighwayShape } from '@/modules/angledHighways/types';
import { HighwayShape } from '@/store/recoil/shape';
import { Crossing } from '@modules/common/types/connections';
import { isAngledHighwayShape, isHighwayShape } from '@modules/common/types/guards';
import { getRelatedAndUnrelatedConnectionIds } from '@modules/connections/common/helpers';
import { allCrossingSelector, updateCrossingsSelector } from '@modules/connections/crossings/store';
import { allShapesSelector } from '@recoil/shapes';
import { crossingPositionsToCrossings, findCrossingsPositions } from '../helpers/crossings';

export const useUpdateCrossings = () => {
  const updateCrossings = useRecoilCallback(
    ({ set, snapshot }) =>
      async (shapeIds: string[]) => {
        const [allCrossings, allShapes] = await Promise.all([
          snapshot.getPromise(allCrossingSelector),
          snapshot.getPromise(allShapesSelector),
        ]);

        const allRoads = allShapes.filter(
          (item): item is HighwayShape | AngledHighwayShape => isHighwayShape(item) || isAngledHighwayShape(item),
        );
        const allRoadsMap = new Map<string, (HighwayShape | AngledHighwayShape)>();
        allRoads.forEach((shape) => allRoadsMap.set(shape.id, shape));

        // (1) remove all related crossings to shapeIds
        const result = getRelatedAndUnrelatedConnectionIds(allCrossings, shapeIds);
        const crossingIdsToRemove = new Set(
          result.relatedConnections.map((crossing) => crossing.id),
        );

        // (2) recalculated crossings of shapeIds
        const crossingsToAddOrUpdate: Crossing[] = [];
        for (let i = 0; i < shapeIds.length; ++i) {
          const thisRoadId = shapeIds[i];
          const thisRoad = allRoadsMap.get(thisRoadId);
          if (!thisRoad) continue;
          const computedCrossings = findCrossingsPositions(thisRoad, allRoads);
          const crossings = crossingPositionsToCrossings(computedCrossings);
          crossingsToAddOrUpdate.push(...crossings);
        }
        set(updateCrossingsSelector, {
          crossings: crossingsToAddOrUpdate,
          crossingIdsToRemove,
        });
      },
    [],
  );

  return {
    updateCrossings,
  };
};
