import { LayoutFlow } from '../flows/types';
import { OrdersPerHour } from '../simulation/helpers/types';
import { MAX_ORDER_PROFILE_HOURS } from '@/modules/common/constants/orderProfile';

export const fillFlatOrderProfile = (flow: LayoutFlow, hours: number): LayoutFlow => {
  const loadsCount = flow.totalNumLoads ?? 0;
  const newFlow = { ...flow };
  newFlow.loadProfile = new Array(hours).fill(loadsCount);
  return newFlow;
};

export const fitOrderProfileHours = (flow: LayoutFlow, hours: number): LayoutFlow => {
  const newFlow = { ...flow };
  if (hours > MAX_ORDER_PROFILE_HOURS) hours = MAX_ORDER_PROFILE_HOURS;
  if (!newFlow.loadProfile || newFlow.loadProfile.length === hours) return newFlow;

  let newLoadProfile = Array.from(flow.loadProfile);
  if (newLoadProfile.length < hours) {
    for (let hour = newLoadProfile.length; hour < hours; ++hour) {
      newLoadProfile.push(0);
    }
  } else if (newLoadProfile.length > hours) {
    newLoadProfile = newLoadProfile.slice(0, hours);
  }
  newFlow.loadProfile = newLoadProfile;
  return newFlow;
};

export const prepareSimulationFlow = (flow: LayoutFlow, hours: number): LayoutFlow => {
  if (flow.loadProfile) {
    return fitOrderProfileHours(flow, hours);
  }
  return fillFlatOrderProfile(flow, hours);
};

export const prepareSimulationFlows = (flows: LayoutFlow[], hours: number): LayoutFlow[] => {
  const newFlows: LayoutFlow[] = [];
  flows.forEach((flow) => {
    let newFlow: LayoutFlow = prepareSimulationFlow(flow, hours);
    newFlows.push(newFlow);
  });
  return newFlows;
};

export const ordersPerHourToLoadProfile = (ordersPerHours: OrdersPerHour[]): number[] => {
  const maxHour = ordersPerHours.reduce((prevMax, b) => Math.max(prevMax, b.hour), -Infinity);
  const loadProfile: number[] = [];

  // make sure that loadProfile represent all loadsCount from hour = 0 to maxHour
  for (let i = 0; i < maxHour; ++i) {
    const orderPerHour = ordersPerHours.find((x) => x.hour === i + 1);
    if (!orderPerHour) {
      loadProfile.push(0);
    } else {
      loadProfile.push(orderPerHour.loadsCount);
    }
  }
  return loadProfile;
};

export const isZeroFlow = (flow: LayoutFlow): boolean => {
  if (!flow.loadProfile) return flow.totalNumLoads === 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const orders of flow.loadProfile) {
    if (orders > 0) return false;
  }
  return true;
};

export const removeZeroOrderFlows = (flows: LayoutFlow[]): LayoutFlow[] => {
  const newFlows: LayoutFlow[] = [];
  flows.forEach((flow) => {
    if (!isZeroFlow(flow)) newFlows.push(flow);
  });
  return newFlows;
};
