import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import HighwayGap from '@/components/PropertiesPanel/layout/HighwayGap';
import HighwayMargin from '@/components/PropertiesPanel/layout/HighwayMargin';
import VehicleLimit from '@/components/PropertiesPanel/layout/VehicleLimit';
import { roadVehicleLimitSelector } from '@/components/PropertiesPanel/store/highway';
import { Accordion } from '@common/components/Accordion';
import { PropertiesLabel } from '@modules/common/components/PropertiesLabel';
import LaneDirection from './LaneDirection';

export default function HighwayProperty() {
  const { t } = useTranslation(['interface']);
  const [vehicleLimit, setVehicleLimit] = useRecoilState(roadVehicleLimitSelector);

  const onVehicleLimitChange = (value: number) => {
    setVehicleLimit(value);
  };

  return (
    <Accordion
      title={t('interface:properties.highway.highway_title', 'Highway')}
      titleSx={{ textTransform: 'capitalize' }}
      sx={{ px: 2 }}
      defaultExpanded
    >
      <Stack direction='column' spacing={1}>
        <PropertiesLabel i18nkey='interface:properties.highway.lane_direction'>
          <LaneDirection />
        </PropertiesLabel>
        <PropertiesLabel skipFocus i18nkey='interface:properties.highway.vehicles'>
          <VehicleLimit vehicleLimit={vehicleLimit} onChange={onVehicleLimitChange} />
        </PropertiesLabel>
        <PropertiesLabel i18nkey='interface:properties.highway.min_margin'>
          <HighwayMargin />
        </PropertiesLabel>
        <PropertiesLabel i18nkey='interface:properties.highway.min_gap'>
          <HighwayGap />
        </PropertiesLabel>
      </Stack>
    </Accordion>
  );
}
