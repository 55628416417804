import { ReactNode } from 'react';
import { useRecoilValue } from 'recoil';
import { viewportSelector } from '../../store/recoil/workspace';
import { BUILDER_BAR_HEIGHT } from '../NavigationBar/BuilderBar';

type Props = {
  children: ReactNode;
  alignVertical?: 'top' | 'center' | 'bottom';
  alignHorizontal?: 'left' | 'center' | 'right';
  cover?: boolean;
  gutterWidth?: number;
  additionalStyles?: any;
};

export const WorkspaceOverlayPosition = ({
  children,
  alignVertical = 'center',
  alignHorizontal = 'center',
  cover = false,
  gutterWidth = 30,
  additionalStyles,
}: Props) => {
  const viewportDimensions = useRecoilValue(viewportSelector);

  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 4,
        top: `${
          alignVertical === 'bottom'
            ? 'unset'
            : alignVertical === 'center'
            ? BUILDER_BAR_HEIGHT + viewportDimensions.h / 2
            : BUILDER_BAR_HEIGHT + gutterWidth
        }px`,
        bottom: `${
          alignVertical === 'center' ? 'unset' : alignVertical === 'bottom' ? 0 : 'unset'
        }`,
        left: `${
          alignHorizontal === 'right'
            ? viewportDimensions.w - gutterWidth
            : alignHorizontal === 'center'
            ? viewportDimensions.w / 2
            : gutterWidth
        }px`,
        transform: `translate(
          ${alignHorizontal === 'left' ? '0' : alignHorizontal === 'center' ? '-50%' : '-100%'},
          ${alignVertical === 'top' ? '0' : alignVertical === 'center' ? '-50%' : '-100%'}
        )`,
        ...(cover
          ? {
              height: viewportDimensions.h,
              width: viewportDimensions.w,
            }
          : {}),
        ...additionalStyles,
      }}
    >
      {children}
    </div>
  );
};
