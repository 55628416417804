import { ListItemButton, ListItemIcon } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import { memo, useCallback, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ArrowSmallDown, ArrowSmallRight } from '@/assets/icons';
import { LayerNames } from '@/modules/common/types/layers';
import { Label } from '@/modules/layers/components/common/Label';
import { LayerNameItem } from '@/modules/layers/components/names/LayerNameItem';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { useAutoSave } from '@modules/floorplan';
import { useUpdateLayerUserPreference } from '@modules/layers/hooks';
import { layersShowSelector } from '@modules/layers/store/layersSelector';
import { VisibilityBtn } from '../VisibilityBtn';

type NamesProps = {
  name: LayerNames;
  label: string;
  groups: { name: LayerNames; label: string; elements: any[] }[];
};

const NamesComponent = ({ name, label, groups = [] }: NamesProps) => {
  const { updateVisibility } = useUpdateLayerUserPreference();
  const { save } = useAutoSave();

  const [layerIsVisible, setLayerIsVisible] = useRecoilState(layersShowSelector(name));
  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(false);
  const mode = useRecoilValue(modeSelector);

  const toggleShow = useCallback(() => {
    setLayerIsVisible(!layerIsVisible);
    updateVisibility(name, !layerIsVisible);
    if (mode == WorkspaceMode.EDITABLE) {
      save();
    }
  }, [layerIsVisible, mode, name, save, setLayerIsVisible, updateVisibility]);

  const startHover = useCallback(() => {
    setHover(true);
  }, []);

  const endHover = useCallback(() => {
    setHover(false);
  }, []);

  const handleClick = useCallback(() => {
    setOpen((open) => !open);
  }, [setOpen]);

  const containerStyle = useMemo(
    () => ({
      color: !layerIsVisible ? 'primary.light' : 'primary.main',
      backgroundColor: 'shades.light',
      borderColor: 'shades.light',

      '&:hover': {
        backgroundColor: 'shades.light',
      },
    }),
    [layerIsVisible],
  );

  return (
    <>
      <ListItemButton
        alignItems='center'
        onMouseOver={startHover}
        onMouseLeave={endHover}
        disableRipple
        sx={containerStyle}
      >
        <ListItemIcon
          onClick={handleClick}
          sx={{
            minWidth: (theme) => theme.spacing(3),
            fontSize: 24,
          }}
        >
          {groups.length > 0 ? open ? <ArrowSmallDown /> : <ArrowSmallRight /> : null}
        </ListItemIcon>
        <Label labelText={label} />

        {(!layerIsVisible || hover) && (
          <VisibilityBtn eyeCrossed={!layerIsVisible} onClick={toggleShow} />
        )}
      </ListItemButton>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {groups.map((group) => (
            <LayerNameItem
              key={group.name}
              name={group.name}
              label={group.label}
              elements={group.elements}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export const Names = memo(NamesComponent);
