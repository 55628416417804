import FolderZipIcon from '@mui/icons-material/FolderZip';
import ImageIcon from '@mui/icons-material/Image';
import {
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
} from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { download } from '@modules/common/helpers/browser';
import { groupNameSelector } from '@modules/floorplan';
import { OutputFiles } from '@modules/floorplanService';
import { useZoomButton } from '@modules/workspace/components/ZoomButton/useZoomButton';
import { selectedShapesIdsState } from '@recoil/shapes/selected';
import { FormattedWarning, Warning } from '@/modules/floorplanService/types';

type Props = {
  output: OutputFiles;
  warnings: Warning[];
  formattedWarnings: FormattedWarning[];
};

export function ExportOutput({ output, warnings, formattedWarnings }: Props) {
  const { t } = useTranslation(['interface']);
  const { zoomFitShape } = useZoomButton();
  const setSelectedShapesIds = useSetRecoilState(selectedShapesIdsState);
  const name = useRecoilValue(groupNameSelector);

  const handleWarningClick = useCallback(
    (id) => {
      zoomFitShape(id);
      setSelectedShapesIds([id]);
    },
    [setSelectedShapesIds, zoomFitShape],
  );

  const handleDownloadZip = useCallback(() => {
    download(output.zipFile, `${name}.zip`);
  }, [name, output.zipFile]);

  const openWindow = useCallback((file: File) => {
    window.open(URL.createObjectURL(file), '_blank').focus();
  }, []);

  return (
    <List sx={{ maxWidth:'500px', marginTop: '0.4em' }}>
      {output.zipFile && (
        <ListItem disablePadding>
          <ListItemButton onClick={handleDownloadZip}>
            <ListItemIcon>
              <FolderZipIcon />
            </ListItemIcon>
            <ListItemText
              primary={t('interface:export.download_prompt', 'Download the output zip file')}
            />
          </ListItemButton>
        </ListItem>
      )}
      <Divider />
      <ListSubheader sx={{ '&:hover': { cursor: 'default' } }}>
        {t('interface:export.files_label', 'Files')}
      </ListSubheader>
      {output.files &&
        output.files.map((file: File) => (
          <ListItem disablePadding key={file.name}>
            <ListItemButton onClick={() => openWindow(file)}>
              <ListItemIcon>
                <ImageIcon />
              </ListItemIcon>
              <ListItemText primary={file.name} />
            </ListItemButton>
          </ListItem>
        ))}
      <Divider />
      <ListSubheader sx={{ '&:hover': { cursor: 'default' } }}>
        {t('interface:export.warnings_label', 'Warnings')}
      </ListSubheader>
      {warnings.length > 0 && formattedWarnings.length === 0 
        ? <Stack alignItems="center"> 
          <CircularProgress /> 
        </Stack>
        : formattedWarnings.map((warning) => (
          <div key={warning.sequence}>
            <Divider variant='middle' />
            <ListItem sx={{ pr: 2, pl: 2 }}>
              <ListItemText>{warning.description}</ListItemText>
            </ListItem>
            {warning.shapes.map((shape) => (
              <ListItemButton
                onClick={() => handleWarningClick(shape.id)}
                key={`${warning.sequence}${shape.sequence}`}
                sx={{ paddingTop: 0, paddingBottom: 0 }}
              >
                <ListItemText>{shape.name}</ListItemText>
              </ListItemButton>
            ))
          }
        </div>
      ))}
    </List>
  );
}
