export enum Type {
  Primitive = 'Primitive',
  Pointcloud = 'Pointcloud',
  Gltf = 'Gltf',
  Model = 'Model',
  Path = 'Path',
  Racking = 'Racking',
}

export enum Variant {
  Cylinder = 'Cylinder',
  Pillar = 'pillar',
  Plane = 'Plane',
  Ply = 'Ply',
  Polyline = 'Polyline',
  Rectangle = 'Rectangle',
  Sphere = 'Sphere',
  None = '',
}

export enum TextureVariant {
  Floor = 'material-floor',
  Highway = 'material-tire_stain',
  Debug = 'material-debug_texture',
  Brick = 'material-brick_wall',
  Concrete = 'material-concrete',
  Stone = 'material-stone',
  WarningZone = 'material-warning_zone',
  ScanField = 'material-scanfield',
}

export enum ERenderType {
  Dynamic = 1,
  Static = 2,
}
