import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { relatedComponentIdsState } from '@recoil/workspace/contextMenu/state/relatedComponentIdsState';
import { selector } from 'recoil';
import { allDistconIds } from '@/modules/connections/distant';

export const showDeleteOptionSelector = selector<boolean>({
  key: 'contextMenu/workspace/option/delete/show',
  get: ({ get }) => {
    const componentIds = get(relatedComponentIdsState)
    return componentIds.length > 0 && get(allDistconIds).includes(componentIds[0])
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
