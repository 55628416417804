import { useRecoilCallback } from 'recoil';

import { allAngledHighwaysSelector } from '@/modules/angledHighways';
import { usesControlPoints } from '@/modules/common/helpers/shapes';
import { ShapeType } from '@/modules/common/types/shapes';
import { allProcessTwoEndPointSelector } from '@/modules/processTwoEndPoint/store';
import { REFERENCE_TYPE, referenceSelected, referenceType } from '@/modules/referenceImage';
import { selectedGroupIdsState, shapeGroupState } from '@/modules/shapeGroups';
import { useShapeGroup } from '@/modules/shapeGroups/hooks/useShapeGroup';
import { latestShape } from '@/modules/workspace/helpers/shape';
import { useWorkspaceStore } from '@/modules/workspace/store';
import { selectedShapesIdsState } from '@/store/recoil/shapes/selected';
import { allWallsSelector } from '@/store/recoil/shapes/wall';
import { toolButtonState } from '@/store/recoil/tool';

export enum SelectShapeOperation {
  OVERWRITE = 'OVERWRITE',
  ADD = 'ADD',
  REMOVE = 'REMOVE',
}

export function useSelectedShape() {
  const { findShapeGroupId } = useShapeGroup();

  const deselect = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const tool = await snapshot.getPromise(toolButtonState);
        const activePointsDrawing = useWorkspaceStore.getState().activePointsDrawingState;
        const latestShapeMap: Map<string, string> = new Map([
          [ShapeType.WALL, latestShape(await snapshot.getPromise(allWallsSelector))],
          [
            ShapeType.HIGHWAY_ANGLED,
            latestShape(await snapshot.getPromise(allAngledHighwaysSelector)),
          ],
          [
            ShapeType.PROCESS_TWO_EP,
            latestShape(await snapshot.getPromise(allProcessTwoEndPointSelector)),
          ],
        ]);

        if (usesControlPoints(tool) && !activePointsDrawing.id && latestShapeMap.get(tool)) {
          set(selectedShapesIdsState, [latestShapeMap.get(tool)]);
        } else {
          set(selectedShapesIdsState, (current) => (current.length !== 0 ? [] : current));

          const currentEditModeShapeId = useWorkspaceStore.getState().shapeInEditModeIdState;
          useWorkspaceStore
            .getState()
            .setShapeInEditModeIdState(currentEditModeShapeId !== '' ? '' : currentEditModeShapeId);
        }
        set(selectedGroupIdsState, (current) => (current.length !== 0 ? [] : current));

        const rType = await snapshot.getPromise(referenceType);
        if (rType === REFERENCE_TYPE.IMAGE) {
          set(referenceSelected, false);
        }
      },
    [],
  );

  const deselectShapeInGroup = useRecoilCallback(
    ({ set }) =>
      async () => {
        set(selectedShapesIdsState, []);
      },
    [],
  );

  /**
   * @returns {string[]} group ids that shape ids are belonging to
   */
  const select = useRecoilCallback(
    ({ snapshot, set }) =>
      async (operation: SelectShapeOperation, shapeIds: string[]) => {
        let currentShapeIds = [...(await snapshot.getPromise(selectedShapesIdsState))];
        let currentGroupIds = [...(await snapshot.getPromise(selectedGroupIdsState))];
        let shapeIdsWithoutGroup: string[] = [];
        let groupIds = await Promise.all(shapeIds.map((shapeId) => findShapeGroupId(shapeId)));
        groupIds.forEach((groupId, index) => {
          if (!groupId) {
            shapeIdsWithoutGroup.push(shapeIds[index]);
          }
        });
        const groups = await Promise.all(
          groupIds.map((id) => snapshot.getPromise(shapeGroupState(id))),
        );

        if (operation === SelectShapeOperation.OVERWRITE) {
          const currentGroupIdsSet = new Set<string>();
          const currentShapeIdsSet = new Set<string>();
          groups.forEach((group) => {
            if (group) {
              group.children.forEach((shapeId) => currentShapeIdsSet.add(shapeId));
              currentGroupIdsSet.add(group.id);
            }
          });
          shapeIdsWithoutGroup.forEach((id) => currentShapeIdsSet.add(id));
          currentGroupIds = Array.from(currentGroupIdsSet);
          currentShapeIds = Array.from(currentShapeIdsSet);
        } else if (operation === SelectShapeOperation.ADD) {
          groups.forEach((group) => {
            if (group) {
              currentShapeIds = currentShapeIds.concat(group.children);
              currentGroupIds.push(group.id);
            }
          });
          currentShapeIds = currentShapeIds.concat(shapeIdsWithoutGroup);
          currentGroupIds = [...new Set(currentGroupIds)];
          currentShapeIds = [...new Set(currentShapeIds)];
        } else if (operation === SelectShapeOperation.REMOVE) {
          const currentGroupIdsSet = new Set(currentGroupIds);
          const currentShapeIdsSet = new Set(currentShapeIds);
          groups.forEach((group) => {
            if (group) {
              group.children.forEach((id) => currentShapeIdsSet.delete(id));
              currentGroupIdsSet.delete(group.id);
            }
          });
          shapeIdsWithoutGroup.forEach((id) => currentShapeIdsSet.delete(id));
          currentGroupIds = Array.from(currentGroupIdsSet);
          currentShapeIds = Array.from(currentShapeIdsSet);
        } else {
          console.error('Unsupported select operation');
          return;
        }
        set(selectedShapesIdsState, currentShapeIds);
        set(selectedGroupIdsState, currentGroupIds);
      },
    [],
  );

  const selectShapesInAGroup = useRecoilCallback(
    ({ snapshot, set }) =>
      async (operation: SelectShapeOperation, shapeIds: string[]) => {
        let currentShapeIds = [...(await snapshot.getPromise(selectedShapesIdsState))];
        const currentGroupIds = await snapshot.getPromise(selectedGroupIdsState);
        if (currentGroupIds.length !== 1) return;
        if (operation === SelectShapeOperation.OVERWRITE) {
          currentShapeIds = shapeIds;
        } else if (operation === SelectShapeOperation.ADD) {
          currentShapeIds = Array.from(new Set([...currentShapeIds, ...shapeIds]));
        } else if (operation === SelectShapeOperation.REMOVE) {
          const currentShapeIdsSet = new Set(currentShapeIds);
          shapeIds.forEach((id) => currentShapeIdsSet.delete(id));
          currentShapeIds = Array.from(currentShapeIdsSet);
        }
        set(selectedShapesIdsState, currentShapeIds);
      },
    [],
  );

  return { deselect, deselectShapeInGroup, select, selectShapesInAGroup };
}
