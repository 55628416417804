import { SvgIcon } from '@mui/material';

const SquareIcon = ({ className = '', size = 24, color = 'currentColor', ...rest }) => (
  <SvgIcon
    viewBox='0 0 24 24'
    sx={{ width: size, height: size, fontSize: `${size}px`, fill: color }}
    className={className}
    {...rest}
  >
    <path
      d='M5.61538 20.5C5.15513 20.5 4.77083 20.3458 4.4625 20.0375C4.15417 19.7292 4 19.3449 4 18.8846V6.11537C4 5.65512 4.15417 5.27083 4.4625 4.9625C4.77083 4.65417 5.15513 4.5 5.61538 4.5H18.3846C18.8449 4.5 19.2292 4.65417 19.5375 4.9625C19.8458 5.27083 20 5.65512 20 6.11537V18.8846C20 19.3449 19.8458 19.7292 19.5375 20.0375C19.2292 20.3458 18.8449 20.5 18.3846 20.5H5.61538ZM5.61538 19.5H18.3846C18.5641 19.5 18.7115 19.4423 18.8269 19.3269C18.9423 19.2115 19 19.0641 19 18.8846V6.11537C19 5.93589 18.9423 5.78846 18.8269 5.67308C18.7115 5.55769 18.5641 5.5 18.3846 5.5H5.61538C5.43589 5.5 5.28846 5.55769 5.17308 5.67308C5.05769 5.78846 5 5.93589 5 6.11537V18.8846C5 19.0641 5.05769 19.2115 5.17308 19.3269C5.28846 19.4423 5.43589 19.5 5.61538 19.5Z'
      fill='#222222'
    />
  </SvgIcon>
);

export default SquareIcon;
