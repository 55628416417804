import { useRecoilValue } from 'recoil';
import { Unit } from '@modules/common/types/general';
import { unitSelector } from '@recoil/workspace';
import { useCallback } from 'react';

export const useUnitSync = () => {
  const unit = useRecoilValue(unitSelector);

  const toBaseUnit = useCallback(
    (value: number) => {
      if (unit === Unit.CENTIMETERS) {
        return value * 10;
      }

      if (unit === Unit.METERS) {
        return value * 1000;
      }

      return value;
    },
    [unit],
  );

  return {
    toBaseUnit,
  };
};
