import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../common';
import { positionsAtom } from './atom';

export const deletePositionIdsSelector = selector<string[]>({
  key: 'position/ids/delete',
  get: () => null,
  set: ({ get, set }, deleteIds: string[]) => {
    const ids = new Set(get(positionsAtom));
    deleteIds.forEach((id) => ids.delete(id));
    set(positionsAtom, Array.from(ids));
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
