import { AreaDirection } from '@/modules/common/types/shapes';

export enum RequiredElementType {
  AREA = 'AREA',
  ROAD = 'ROAD',
  CHARGING = 'CHARGING',
  PARKING = 'PARKING',
  FLOW = 'FLOW',
};

export const shapeDirectionToDegreesMap = {
  [AreaDirection.UP]: 0,
  [AreaDirection.RIGHT]: 90,
  [AreaDirection.DOWN]: 180,
  [AreaDirection.LEFT]: 270,
};

export const enum PrevalidationDebounceMs {
  DEFAULT = 0,
  INPUT = 800,
  INPUT_SPACIOUS = 2000,
}

export const enum PreValidationAspect {
  DISCONNECTED_AREA_IDS = 'disconnectedAreaIds',
  CONNECTION_LACKING_ROADS = 'connectionLackingRoadIds',
  REQUIRED_ELEMENTS = 'missingRequiredElements',
  FLOWLESS_AREAS = 'flowlessAreas',
  DISCONNECTED_FLOW_STOPS = 'disconnectedFlowStopIds',
  INCORRECTLY_CONNECTED_SHAPES = 'incorrectlyConnectedShapeToRoadIds',
}
