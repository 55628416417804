import { useAxios } from '@modules/api/hooks';
import { useConfig } from '@modules/common/hooks';
import { useCallback } from 'react';

import { UserPreference } from '../types';
import { UserPreferenceName } from '../constants';

export const useUserPreferencesApi = () => {
  const { api } = useConfig();
  const { get, post, put } = useAxios({
    baseURL: api.userPreferences.url,
    scopes: api.userPreferences.scope,
  });
  const fetch = useCallback(async (): Promise<UserPreference[]> => (await get('')).data, [get]);

  const update = useCallback(
    async (name: UserPreferenceName, value: any) => {
      try {
        await put(`setting/${name}`, JSON.stringify(value), {
          headers: { 'Content-Type': 'application/json' },
        });
      } catch (e) {
        await post(`setting/${name}`, JSON.stringify(value), {
          headers: { 'Content-Type': 'application/json' },
        });
      }
    },
    [post, put],
  );

  return {
    fetch,
    update,
  };
};
