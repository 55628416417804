import { selector } from 'recoil';
import shapeAtom from '../../shape/atom';
import { wallIdsAtom } from './wallIdsAtom';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../common';
import { WallShape } from '../../shape';

export const allWallsSelector = selector<WallShape[]>({
  key: 'allWallsSelector',
  get: ({ get }) => {
    const output = [];
    get(wallIdsAtom).forEach((id) => {
      const wall = get(shapeAtom(id));
      output.push(wall);
    });
    return output;
  },
  set: ({ set }, newWalls: WallShape[]) => {
    const uuids = [];
    newWalls.forEach((wall) => {
      set(shapeAtom(wall.id), wall);
      uuids.push(wall.id);
    });
    set(wallIdsAtom, uuids);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
