import { Menu } from '@mui/material';
import { useResetRecoilState, useRecoilValue } from 'recoil';
import { positionState } from './store';
import { DeleteOption } from './DeleteOption';

// TODO -> add mouse out timer

export const FloorplanContextMenu = () => {
  const position = useRecoilValue(positionState);
  const hide = useResetRecoilState(positionState);

  if (!position) return null;

  return (
    <Menu
      open
      onClick={hide}
      onClose={hide}
      anchorReference='anchorPosition'
      anchorPosition={{ top: position.y, left: position.x }}
      disableAutoFocusItem
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      PaperProps={{
        sx: {
          width: '260px',
          minHeight: '64px',
          boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.16), 0px 2px 12px 1px rgba(0,0,0,0.12)',
          alignItems: 'center',
          display: 'flex',
        },
      }}
      MenuListProps={{
        'aria-labelledby': 'context-menu',
        onMouseLeave: hide,
        sx: {
          width: '100%',
        },
      }}
      onBackdropClick={hide}
      BackdropProps={{
        style: {
          background: 'transparent',
        },
        onContextMenu: (e) => {
          e.preventDefault();
          hide();
        },
      }}
    >
      <DeleteOption />
    </Menu>
  );
};
