import { SvgIcon } from '@mui/material';
import { memo } from 'react';

function SvgComponent(props) {
  return (
    <SvgIcon fill='none' stroke="none" {...props}>
      <path
        d='M1 9v11a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1V9M1 9h22M1 9s0-6 11-6 11 6 11 6M10.5 6h3M5 12h14v9H5v-9Z'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  );
}

const Memo = memo(SvgComponent);
export default Memo;
