import { ListItemButton } from '@mui/material';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { modeSelector } from '@/modules/common/store/workspace';
import { WorkspaceMode } from '@/modules/common/types/general';
import { useAutoSave } from '@/modules/floorplan';
import { Label } from '@/modules/layers/components/common/Label';
import {
  addInvisibleNameLabelsSelector,
  invisibleNameLabelSelector,
  removeInvisibleNameLabelsSelector,
} from '@/modules/layers/store/layersSelector';
import { selectedShapesIdsState } from '@/store/recoil/shapes/selected';
import { VisibilityBtn } from '../VisibilityBtn';

type ShapeNameItemProps = {
  label: string;
  id: string;
};

const ShapeNameItemComponent = ({ label, id }: ShapeNameItemProps) => {
  const nameLabelIsVisible = useRecoilValue(invisibleNameLabelSelector(id));
  const hideNameLabels = useSetRecoilState(addInvisibleNameLabelsSelector);
  const showNameLabels = useSetRecoilState(removeInvisibleNameLabelsSelector);
  const [hover, setHover] = useState(false);
  const selectedShapesIds = useRecoilValue(selectedShapesIdsState);
  const [selected, setSelected] = useState(false);
  const mode = useRecoilValue(modeSelector);
  const { save } = useAutoSave();

  const toggleShow = useCallback(() => {
    if (nameLabelIsVisible) {
      hideNameLabels([id]);
    } else {
      showNameLabels([id]);
    }

    if (mode == WorkspaceMode.EDITABLE) {
      save();
    }
  }, [nameLabelIsVisible, mode, hideNameLabels, id, showNameLabels, save]);

  const startHover = useCallback(() => {
    setHover(true);
  }, []);

  const endHover = useCallback(() => {
    setHover(false);
  }, []);

  useEffect(() => {
    if (selectedShapesIds.length) {
      if (selectedShapesIds.includes(id)) {
        setSelected(true);
        return;
      }
    }
    setSelected(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedShapesIds]);

  const containerStyle = useMemo(
    () => ({
      color: !nameLabelIsVisible ? 'neutral.main' : 'neutral.darkest',
      backgroundColor: selected ? 'neutral.grey' : 'shades.light',
      borderColor: selected ? 'neutral.grey' : 'shades.light',
      paddingLeft: 8,

      '&:hover': {
        backgroundColor: selected ? 'neutral.grey' : 'shades.light',
      },
    }),
    [nameLabelIsVisible, selected],
  );

  return (
    <ListItemButton
      alignItems='center'
      onMouseOver={startHover}
      onMouseLeave={endHover}
      sx={containerStyle}
    >
      <Label labelText={label} />

      {(!nameLabelIsVisible || hover) && (
        <VisibilityBtn eyeCrossed={!nameLabelIsVisible} onClick={toggleShow} />
      )}
    </ListItemButton>
  );
};

export const ShapeNameItem = memo(ShapeNameItemComponent);
