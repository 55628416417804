import shapeState, { PositionShape, shapePropertyCanvas } from '@recoil/shape';
import { memo, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useConnections } from '@modules/connections';
import { isVertical } from '@modules/workspace/helpers/shape';
import { useShapeHover } from '@/modules/common/hooks/useShapeHover';
import {
  supportedVehiclesLengthSelector,
  supportedVehiclesWidthSelector,
} from '@/modules/vehicles';
import { shapeTypeShowSelector } from '@/modules/layers';
import { ShapeProxy } from '../ShapeProxy';
import { PositionRenderer } from './PositionRenderer';

export type PositionProps = {
  id: string;
};

const PositionComponent = ({ id }: PositionProps) => {
  const shape = useRecoilValue(shapeState(id)) as PositionShape;
  const { onMouseEnter, onMouseLeave } = useShapeHover(id);
  const { updateConnections } = useConnections();
  const [properties, setProperties] = useRecoilState(shapePropertyCanvas(id));
  const vehicleWidth = useRecoilValue(
    supportedVehiclesWidthSelector(shape.parameters.supportedVehicleIds),
  );
  const vehicleLength = useRecoilValue(
    supportedVehiclesLengthSelector(shape.parameters.supportedVehicleIds),
  );
  const layerShown = useRecoilValue(shapeTypeShowSelector(shape?.type));

  useEffect(() => {
    // Adjust the size to match the selected vehicle
    const { direction } = shape.parameters;
    setProperties({
      ...properties,
      width: (isVertical(direction) ? vehicleWidth + 2 : vehicleLength) / 1,
      height: (isVertical(direction) ? vehicleLength : vehicleWidth + 2) / 1,
    });

    updateConnections([id]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleWidth, vehicleLength]);

  if ( !layerShown) return null;

  return (
    <>
      <PositionRenderer shapeId={id} shapeState={shape} />

      {/* Show Shape ID on rectangle. Handy for debugging */}
      {/* <Text {...propertiesCanvas} fontSize={80} listening={false} text={id} /> */}

      <ShapeProxy
        id={id}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        x={shape.properties.x / 10}
        y={shape.properties.y / 10}
        width={shape.properties.width / 10}
        height={shape.properties.height / 10}
      />
    </>
  );
};

export const Position = memo(PositionComponent);
