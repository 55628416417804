import { Vector2 } from 'three';

import { BoundingBox } from '@/helpers/types';
import { ControlPoint, ShapeType } from '@/modules/common/types/shapes';
import { ProcessTwoEPShape } from '@/modules/processTwoEndPoint';
import { getShapeColor, hexToRgb } from '@/modules/workspace/helpers/colors';
import { SOURCE_NAME } from '../consts';
import { Type, Variant } from '../enum';
import { Vector3, VisualizationObject } from '../types';
import { pointAlongVector } from '@/modules/workspace/helpers/shape';
import { UnifiedVehicleDetails } from '@/modules/vehicles';

function calculateControlPoints(
  startPoint: Vector2,
  referencePoint: Vector2,
  z: number,
  supportedVehicleIds: string[],
  vehicles: UnifiedVehicleDetails[],
): Vector3[] {
  const supportedVehicleType = vehicles.find((vehicle) => vehicle.id === supportedVehicleIds[0])
  const vehicleLength = supportedVehicleType.length + 500
  const endPoint = pointAlongVector(startPoint, referencePoint, vehicleLength)
  return [
    { X: startPoint.x, Y: startPoint.y, Z: z },
    { X: endPoint.x, Y: endPoint.y, Z: z }
  ]
}

export function createProcessObject(
  shape: ProcessTwoEPShape,
  vehicles: UnifiedVehicleDetails[],
  workspaceBox: BoundingBox,
): VisualizationObject[] {
  const z = 0;
  const tags = [shape.type];

  const deliveryStdColor = hexToRgb(getShapeColor(ShapeType.DELIVERY, true));
  const deliveryColor = { Alpha: deliveryStdColor.a, R: deliveryStdColor.r, G: deliveryStdColor.g, B: deliveryStdColor.b };
  const intakeStdColor = hexToRgb(getShapeColor(ShapeType.INTAKE, true));
  const intakeColor = { Alpha: intakeStdColor.a, R: intakeStdColor.r, G: intakeStdColor.g, B: intakeStdColor.b };

  const points: Vector2[] = shape.properties.controlPoints.map((cp: ControlPoint) => {
    const pos = cp.position;
    return new Vector2(
      pos.x - workspaceBox.x,
      workspaceBox.height - (pos.y - workspaceBox.y),
    );
  });

  const deliveryControlPoints = calculateControlPoints(
    points[0],
    points[1],
    z,
    shape.parameters.deliveryParameters.supportedVehicleIds,
    vehicles
  )

  const intakeControlPoints = calculateControlPoints(
    points[points.length - 1],
    points[points.length - 2],
    z,
    shape.parameters.intakeParameters.supportedVehicleIds,
    vehicles
  )

  return [{
    Name: `${shape.name}-delivery`,
    Type: Type.Path,
    Variant: Variant.Polyline,
    Source: SOURCE_NAME,
    Color: deliveryColor,
    Tags: tags,
    Data: {
      ControlPoints: deliveryControlPoints,
      Width: shape.parameters.width / 1000 / 2, // divide by 2 is a bug in FT
    },  
  }, {
    Name: `${shape.name}-intake`,
    Type: Type.Path,
    Variant: Variant.Polyline,
    Source: SOURCE_NAME,
    Color: intakeColor,
    Tags: tags,
    Data: {
      ControlPoints: intakeControlPoints,
      Width: shape.parameters.width / 1000 / 2, // divide by 2 is a bug in FT
    },  
  }]
}
