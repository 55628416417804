import * as React from 'react';

function ArrowSmallRight({ className = '', ...rest }) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
      style={{ fill: 'currentColor' }}
      className={className}
      {...rest}
    >
      <path d='M10.5 15.8V8.19995L14.3 12L10.5 15.8Z' />
    </svg>
  );
}

const ArrowSmallRightIcon = React.memo(ArrowSmallRight);
export default ArrowSmallRightIcon;
