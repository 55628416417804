import { Button, Grid, Typography } from '@mui/material';

import { DEBUG_ACTION_SIZE, DEBUG_DESCRIPTION_SIZE } from './DebugPanel';
import { useDebugStore } from '../store';

export const AutodeskViewer = () => {
  const toggleDebug = useDebugStore((state) => state.toggleAutodeskDebug);

  return (
    <>
      <Grid item xs={DEBUG_DESCRIPTION_SIZE}>
        <Typography>Toggle Autodesk viewer</Typography>
      </Grid>
      <Grid item xs={DEBUG_ACTION_SIZE}>
        <Button onClick={() => toggleDebug()}>Click</Button>
      </Grid>
    </>
  );
};
