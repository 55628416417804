import { useShallow } from 'zustand/react/shallow';

import { UserPreferences } from '../types';
import { UserPreferenceName } from '../constants';
import { useUserPreferencesStore } from '../store';

/**
 * Used to get a reactive user preference value for the given preference name
 */
export const useUserPreference = <T extends UserPreferenceName>(userPreference: T): UserPreferences[T] =>
  useUserPreferencesStore(useShallow((state) => state.getPreference(userPreference)));
