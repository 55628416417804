import { useConfig } from '@/modules/common/hooks';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PackageVersionChip from './PackageVersionChip';

type Version = {
  dt: string;
  ftLib: string;
  ftWebGl: string;
  ftAsset: string;
};

const defaultVersion: Version = {
  dt: '?',
  ftLib: '?',
  ftWebGl: '?',
  ftAsset: '?',
};

export default function VersionList() {
  const { app } = useConfig();
  const [version, setVersion] = useState<Version>(defaultVersion);
  const { t } = useTranslation('common');

  useEffect(() => {
    let newVersion = { ...version };

    axios.get(`/package-lock.json?${app.version}`).then((res) => {
      const dependencies = res.data?.dependencies;
      if (!dependencies) return;
      newVersion.ftAsset = dependencies?.['@thive/fleet-tracker.asset'].version;
      newVersion.ftWebGl = dependencies?.['@thive/fleet-tracker.web-gl'].version;
      newVersion.ftLib = dependencies?.['@thive/fleet-tracker.web-gl.react'].version;
      newVersion.dt = app.version;
      setVersion(newVersion);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app]);

  return (
    <Stack direction='column'>
      <Stack direction='row' marginBottom={1}>
        <Box flexGrow={1}>{t('version', 'Version')}</Box>
        <Box>{version.dt}</Box>
      </Stack>
      <Stack direction='row' flexWrap='wrap'>
        <PackageVersionChip name='FT.Lib' version={version.ftLib} />
        <PackageVersionChip name='FT.WebGL' version={version.ftWebGl} />
        <PackageVersionChip name='FT.Asset' version={version.ftAsset} />
      </Stack>
    </Stack>
  );
}
