import { OperatingSystem, getOperatingSystem } from '@/modules/common/helpers/browser';
import { KonvaEventObject, Node } from 'konva/lib/Node';
import { Vector2d } from 'konva/lib/types';
import { Vector2, Vector3 } from 'three';

export enum ElementName {
  SHAPE_PROXY = 'shapeProxy',
  SHAPE_GROUP_CONTAINER = 'shapeGroupContainer',
  CONTROL_POINT = 'controlPoint',
  POTENTIAL_CONTROL_POINT = 'potentialControlPoint',
  HIGHWAY_ARROW = 'laneDirectionArrow',
  POINTS_SHAPE_PROXY_LINE = 'pointsShapeProxyLine',
  POINTS_SHAPE_PROXY_BOX = 'pointsShapeProxyBox',
  POINTS_SHAPE_PROXY_GROUP = 'pointsShapeProxyGroup',
  CONTEXT_NODE_NAME = 'rightClickable',
}

export enum ContainerId {
  SHAPE_RENDERING_LAYER = 'shapeRenderingLayer',
  INTAKE = 'intakeLayer',
  DELIVERY = 'deliveryLayer',
  STORAGE = 'storageLayer',
  CHARGING = 'chargingLayer',
  PARKING = 'parkingLayer',
  PROCESS = 'processLayer',
  PROCESS_TWO_EP = 'processTwoEpLayer',
  POSITIONS = 'positionsLayer',
  HIGHWAYS = 'highwaysLayer',
  ANGLED_HIGHWAYS = 'angledHighwaysLayer',
  WALLS = 'wallsLayer',
  OBSTACLES = 'obstaclesLayer',
  GROUPS = 'shapeGroupsLayer',
}

export enum TempLayerId {
  ACTIVE_ARROW_LAYER = 'activeArrowLayer',
}

export const findParentNode = (konvaNode: Node, name: string): Node =>
  konvaNode.findAncestor(`.${name}`, false);

export const getRelativeMousePosition = (e: KonvaEventObject<MouseEvent>): Vector2d => {
  if (!e || !e.currentTarget) {
    return { x: 0, y: 0 };
  }
  const stage = e.currentTarget.getStage();
  return stage.getRelativePointerPosition();
};

export const getRelativeMousePositionVector2 = (e: KonvaEventObject<MouseEvent>): Vector2 => {
  const pos = getRelativeMousePosition(e);
  return new Vector2(pos.x, pos.y);
};

export const isLeftClickOnStage = (e: KonvaEventObject<MouseEvent>): boolean =>
  isLeftClick(e) && e.target.getType() === 'Stage';

export const isLeftClick = (e: KonvaEventObject<MouseEvent>): boolean => e.evt.button === 0;

export const isRightClick = (e: KonvaEventObject<MouseEvent>): boolean => e.evt.button === 2;

export const isDragCopy = (e: KonvaEventObject<MouseEvent>): boolean =>
  getOperatingSystem() === OperatingSystem.Mac ? e.evt.altKey : e.evt.ctrlKey; // only mac use option key (alt) to copy

export const konvaVectorToThreeVector = (v: Vector2d): Vector3 => new Vector3(v.x, -v.y, 0);
export const threeVectorToKonvaVector = (v: Vector3): Vector2d => ({ x: v.x, y: -v.y });

export const findNodefromNodeId = (konvaNodes: Node[], id: string) =>
  konvaNodes.find((node) => node.id() === id);

export const SHAPE_TO_CANVAS_SCALE = 0.1;
export const CANVAS_TO_SHAPE_SCALE = 10;

export const PROXY_STROKE_WIDTH = 100;

export const AUTOSCALE_NAME = 'autoScale';
export const AUTOSCALE_MAX = 'autoScaleMax';
export const AUTOSCALE_MIN = 'autoScaleMin';
export const AUTOSCALE_DEFAULT = 'autoScaleDefault';

export const AUTOSCALE_STROKE_NAME = 'autoScaleStroke';
export const AUTOSCALE_STROKE_MAX = 'autoScaleStrokeMax';
export const AUTOSCALE_STROKE_MIN = 'autoScaleStrokeMin';
export const AUTOSCALE_STROKE_DEFAULT = 'autoScaleStrokeDefault';

export const SHAPE_GROUP_INTERACTIVITY = 'groupInteractivity';
