import { produce } from 'immer';
import { Draft } from 'immer/src/types/types-external';
import { useRecoilCallback } from 'recoil';

import { mergeVersions } from '../helpers/simulation';
import { Simulation, SimulationStatus } from '../helpers/types';
import {
  currentGroupSelector,
  currentGroupSimulationIdsSelector,
  currentGroupSimulationSelector,
} from '../store/group';
import { errorMessageSelector, isLoadingSelector } from '../store/module';
import {
  floorPlanVersionsSelector,
  simulationIdsSelector,
  simulationSelector,
} from '../store/simulations';
import { useSimulationApi } from './useSimulationApi';
import { useSimulationCallbacks } from './useSimulationCallbacks';

export const useSimulationGroupCallbacks = () => {
  const { fetchAllSimulations } = useSimulationApi();
  const { abortGroup: abortRemote, removeGroup: removeRemote } = useSimulationApi();
  const { closeSimulationGroupPanel } = useSimulationCallbacks();

  const updateGroup = useRecoilCallback(
    ({ set }) =>
      async (func: (draft: Draft<Simulation>) => void) => {
        set(currentGroupSelector, (state) => produce(state, func));
      },
    [],
  );

  const updateSimulation = useRecoilCallback(
    ({ set }) =>
      async (id: string, func: (draft: Draft<Simulation>) => void) => {
        set(currentGroupSimulationSelector(id), (state) => produce(state, func));
      },
    [],
  );

  const abort = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const simulation = await snapshot.getPromise(currentGroupSelector);
        await updateGroup((simulation) => {
          simulation.status = SimulationStatus.ABORTING;
        });

        const simulationIds = await snapshot.getPromise(currentGroupSimulationIdsSelector);
        simulationIds.forEach((item) =>
          updateSimulation(item, (simulation) => {
            simulation.status = SimulationStatus.ABORTING;
          }),
        );

        await abortRemote(simulation.floorPlanId, simulation.id);
        await updateGroup((simulation) => {
          simulation.status = SimulationStatus.ABORTED;
        });
        simulationIds.forEach((item) =>
          updateSimulation(item, (simulation) => {
            simulation.status = SimulationStatus.ABORTED;
          }),
        );
      },
    [abortRemote, updateGroup],
  );

  const loadAll = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        set(isLoadingSelector, true);
        set(errorMessageSelector, null);

        try {
          const simulationGroup = await snapshot.getPromise(currentGroupSelector);
          const simulationsInGroup = await fetchAllSimulations(
            simulationGroup.floorPlanId,
            simulationGroup.id,
          );
          const versions = await snapshot.getPromise(floorPlanVersionsSelector);

          mergeVersions(simulationsInGroup, versions).forEach((item) =>
            set(currentGroupSimulationSelector(item.id), item),
          );
          set(
            currentGroupSimulationIdsSelector,
            simulationsInGroup.map((item) => item.id),
          );
        } catch (error) {
          set(currentGroupSimulationIdsSelector, []);
          set(errorMessageSelector, 'errors:simulation.list.fetch');
          console.error(error);
          throw error;
        } finally {
          set(isLoadingSelector, false);
        }
      },
    [],
  );

  const remove = useRecoilCallback(
    ({ snapshot, set, reset }) =>
      async () => {
        const simulation = await snapshot.getPromise(currentGroupSelector);
        try {
          await updateGroup((simulation) => {
            simulation.status = SimulationStatus.DELETING;
          });
          await closeSimulationGroupPanel();
          await removeRemote(simulation.floorPlanId, simulation.id);
          set(simulationIdsSelector, (state) => state.filter((item) => item !== simulation.id));
          reset(simulationSelector(simulation.id));
        } catch (error) {
          // TODO common error ocmponent
          console.error(error);
        }
      },
    [removeRemote, closeSimulationGroupPanel, updateGroup],
  );

  return {
    abort,
    loadAll,
    updateGroup,
    updateSimulation,
    remove,
  };
};
