export const filterOptionStyles = {
  height: '32px',
  display: 'flex',
  justifyContent: 'space-between',
  gap: '8px',
  paddingRight: '8px',
  '&.MuiMenuItem-root:active': {
    backgroundColor: 'initial',
  },
};

export const clearBtnStyles = {
  height: '32px',
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  gap: '8px',
  paddingRight: '9px',

  // hide static blue background when active
  '&.MuiMenuItem-root:active': {
    backgroundColor: 'initial',
  },
};
