import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import referenceApsUrn from './referenceApsUrn';
import atom from './atom';
import { REFERENCE_TYPE, ReferenceType } from '../types';

const referenceType = selector<ReferenceType>({
  key: 'reference/type',
  get: ({ get }) => {
    const urn = get(referenceApsUrn);
    const { file } = get(atom);

    return urn ? REFERENCE_TYPE.APS : file ? REFERENCE_TYPE.IMAGE : REFERENCE_TYPE.NONE;
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default referenceType;
