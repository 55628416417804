import { LayoutFlow } from '@/modules/flows/types';
import { HistoryManager } from '@/store/recoil/history';
import { atom, atomFamily } from 'recoil';
import { DEFAULT_FLOW_LOADS_AMOUNT, DEFAULT_FLOW_VEHICLE_LIMIT } from './constants';

const DEFAULT_IDS_AMOUNT = 0;

export const flow = atomFamily<LayoutFlow, string>({
  key: 'flow/layout',
  default: {
    id: null,
    name: '',
    intakeFlowStop: null,
    deliveryFlowStop: null,
    totalNumLoads: DEFAULT_FLOW_LOADS_AMOUNT,
    loadProfile: null,
    vehicleLimit: DEFAULT_FLOW_VEHICLE_LIMIT,
  },
});

export const allFlowIds = atom<string[]>({
  key: 'flow/layout/allFlowIds',
  default: [],
  effects: [
    ({ onSet, setSelf }) => {
      onSet((newValue, oldValue) => {
        const newIdsAmount = newValue.length;
        const oldIdsAmount = ('length' in oldValue && oldValue.length) || DEFAULT_IDS_AMOUNT;

        if (newIdsAmount > oldIdsAmount) {
          HistoryManager.track(`Flow added`, newValue, oldValue, setSelf);
        } else if (newIdsAmount < oldIdsAmount) {
          HistoryManager.track(`Flow removed`, newValue, oldValue, setSelf);
        }
      });
    },
  ],
});

export const activeFlowState = atom<LayoutFlow>({
  key: 'flow/active',
  default: null,
});
