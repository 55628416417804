import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import { Simulation } from '../../helpers/types';
import { simulationGroupIdAtom } from './simulationAtom';
import { simulationSelector } from '../simulations';

/**
 * Currently loaded simulation group
 */
export const currentGroupSelector = selector<Simulation>({
  key: 'simulations/group/current',
  get: ({ get }) => get(simulationSelector(get(simulationGroupIdAtom))),
  set: ({ set, get }, simulation: Simulation) =>
    set(simulationSelector(get(simulationGroupIdAtom)), simulation),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
