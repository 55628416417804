import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../../../store/recoil/common';
import { VIEWER_DIMENSION_CONVERSION_RATIO } from '../constants';
import { REFERENCE_TYPE } from '../types';
import dwgAtom from './atom';
import referenceType from './referenceType';

const isReferenceScaledSelector = selector<boolean>({
  key: 'isReferenceScaledSelector',
  get: ({ get }) => {
    const refType = get(referenceType);

    const { original, height: currentHeight, width: currentWidth } = get(dwgAtom);

    switch (refType) {
      case REFERENCE_TYPE.IMAGE:
        return original.height !== currentHeight || original.width !== currentWidth;
      case REFERENCE_TYPE.APS:
        return (
          original.height !== currentHeight / VIEWER_DIMENSION_CONVERSION_RATIO ||
          original.width !== currentWidth / VIEWER_DIMENSION_CONVERSION_RATIO
        );
      case REFERENCE_TYPE.NONE:
      default:
        return null;
    }
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default isReferenceScaledSelector;
