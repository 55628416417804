import { useCanvasStore } from '@modules/canvas';
import { create } from 'zustand';
import { Vector3 } from 'three';
import { devtools } from 'zustand/middleware';

import { calculateZoomInRange } from '@/modules/canvas/helpers';
import { DEVTOOLS_OPTIONS } from '@/modules/debug/constants/zustand';
import { deserialize } from '../converters/navitech';
import { map } from '../mappers/navitech';

type NavitechState = {
  fileName: string;
  ids: string[];
};

type NavitechActions = {
  deleteFile(): void;
  initialize(file?: File): Promise<void>;
  setFile(file?: File): Promise<void>;
  scale(zoom: number): void;
  reset(): void;
};

const INITIAL_STATE: NavitechState = {
  fileName: null,
  ids: [],
};

export const useNavitechStore = create<NavitechState & NavitechActions>()(
  devtools(
    (set, get) => ({
      ...INITIAL_STATE,

      deleteFile() {
        set(
          {
            fileName: null,
          },
          undefined,
          {
            type: 'deleteFile',
          },
        );

        useCanvasStore.getState().instance.removeElements(get().ids);
      },

      async initialize(file?: File) {
        await get().setFile(file);
      },

      reset() {
        set(INITIAL_STATE, undefined, {
          type: 'reset',
        });
      },

      scale(zoom: number) {
        const size = calculateZoomInRange(zoom, 0, 75, 20, 500);
        useCanvasStore.getState().instance.updateTransformation(get().ids, {
          size: new Vector3(size, size, 1),
        });
      },

      async setFile(file?: File) {
        if (!file) {
          return;
        }

        const { instance } = useCanvasStore.getState();
        const elements = map(deserialize(await file.text()));

        get().deleteFile();
        set(
          {
            ids: elements.map((item) => item.id),
            fileName: file.name,
          },
          undefined,
          {
            type: 'setFile',
          },
        );

        instance.updateElements(elements);
        get().scale(instance.getZoom());
      },
    }),
    { store: 'commissioning/navitechStore', ...DEVTOOLS_OPTIONS },
  ),
);
