import { Crossing } from '@modules/common/types/connections';
import { atom, atomFamily } from 'recoil';
import { Vector2 } from 'three';

export const crossingState = atomFamily<Crossing, string>({
  key: 'connections/crossing/atom',
  default: {
    id: null,
    from: null,
    to: null,
    position: new Vector2(0, 0)
  },
});

export const crossingIdsState = atom<string[]>({
  key: 'connections/crossing/ids/atom',
  default: [],
});
