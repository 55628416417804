import { IconButton, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledTooltip } from '@common/components/StyledTooltip';
import { useDialog } from '@modules/common/hooks';
import { useCallback } from 'react';
import { AbortIcon } from '@/assets/icons';

import { Simulation, SimulationStatus } from '../helpers/types';
import { IN_PROGRESS_STATUSES } from '../helpers/constants';
import { AbortConfirmDialog } from './ConfirmDialog';

type Props = {
  simulation: Simulation;
  onAbortConfirm: () => void;
};

/**
 * Buttons for deleting and aborting a simulation group
 */
export const BottomButtons = ({ simulation, onAbortConfirm }: Props) => {
  const { t } = useTranslation('interface');
  const { name, status } = simulation;
  const { open: abortDialogOpen, setOpen: toggleAbortDialog } = useDialog();

  const onAbortClick = useCallback(() => toggleAbortDialog(true), [toggleAbortDialog]);

  return (
    <Stack>
      {IN_PROGRESS_STATUSES.includes(status) && (
        <StyledTooltip
          disabled={status === SimulationStatus.ABORTING}
          title={t('simulation.run_card.abort_button.tooltip')}
        >
          <IconButton
            onClick={onAbortClick}
            sx={{ marginTop: 'auto', p: 0, mr: '3px' }}
            disabled={status === SimulationStatus.ABORTING}
          >
            <AbortIcon />
          </IconButton>
        </StyledTooltip>
      )}
      <AbortConfirmDialog
        open={abortDialogOpen}
        runName={name}
        onConfirm={() => {
          onAbortConfirm();
          toggleAbortDialog(false);
        }}
        onCancel={() => toggleAbortDialog(false)}
      />
    </Stack>
  );
};
