import { useZoomButton } from '@/modules/workspace/components/ZoomButton/useZoomButton';
import CircleIcon from '@mui/icons-material/Circle';
import { Button, Stack, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { selectedShapesIdsState } from '../../../../store/recoil/shapes/selected';

const textSx = {
  fontWeight: 300,
  fontSize: '12px',
  lineHeight: '18px',
  color: 'neutral.darker',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export type SummaryGroupObject = {
  name: string;
  id: string;
};

export type SummaryGroupProps = {
  label: string;
  objects: SummaryGroupObject[];
};

const SummaryGroup: React.FC<SummaryGroupProps> = ({ label, objects }) => {
  const { zoomFitShape } = useZoomButton();
  const setSelectedShapesIds = useSetRecoilState(selectedShapesIdsState);

  const handleClick = useCallback(
    (id) => {
      zoomFitShape(id);
      setSelectedShapesIds([id]);
    },
    [setSelectedShapesIds, zoomFitShape],
  );

  return (
    <Stack direction='column' justifyContent='flex-start' alignItems='flex-start'>
      <Typography sx={{ ...textSx, color: 'neutral.darker' }}>{label}</Typography>
      {objects.map((object) => (
        <Button
          onClick={() => handleClick(object.id)}
          key={object.name}
          variant='text'
          sx={{
            paddingLeft: '0px',
            border: 0,
            height: '16px',
            backgroundColor: 'others.lighter',
            borderColor: 'others.lighter',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: 'others.lighter',
            },
          }}
        >
          <CircleIcon
            sx={{ fontSize: '3px', color: 'primary.main', ml: 1 }}
            fontSize='inherit'
          />
          <Typography sx={{ ...textSx, color: 'primary.main', ml: 1 }}>
            {object.name}
          </Typography>
        </Button>
      ))}
    </Stack>
  );
};

export default SummaryGroup;
