import { atom, selectorFamily } from "recoil";
import { RECOIL_SELECTOR_CACHE_POLICY } from "../../../../store/recoil/common";

export const floorplanIdsBeingDeletedState = atom<Set<string>>({
  key: 'project/floorplans/deleting/ids',
  default: new Set(),
});

// TODO move to own file
export const isFloorplanBeingDeleted = selectorFamily<boolean, string>({
  key: 'project/floorplan/isBeingDeleted',
  get:
    (id: string) =>
    ({ get }) =>
      get(floorplanIdsBeingDeletedState).has(id),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
