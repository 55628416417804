import { Vector2 } from 'three';
import { UNIT_RADIUS } from '../draw/drawLayoutPoints';
import { Connections, LayoutPoints, UnitData, UnitId } from './types';

export const parseLayoutPointFile = (text: string): LayoutPoints => {
  const lines = text.trim().split(/\r?\n/);
  const units: LayoutPoints = new Map();
  lines.forEach((line) => {
    const col = line.trim().split(',');
    const unitId = parseInt(col[0]);
    if (!units.has(unitId)) {
      units.set(unitId, {
        id: unitId,
        positions: [],
      });
    }
    const layoutPoint = units.get(unitId);
    layoutPoint.positions.push(new Vector2(parseInt(col[4]), parseInt(col[5])));
  });

  // calculate center point that represent unit
  const offset = new Vector2(UNIT_RADIUS, 0);
  units.forEach((unit) => {
    if (unit.positions.length === 1) {
      unit.center = unit.positions[0].clone().add(offset);
    } else if (unit.positions.length === 2) {
      unit.center = unit.positions[0].clone().add(unit.positions[1]).divideScalar(2);
    } else {
      const cm = new Vector2();
      unit.positions.forEach((p) => cm.add(p));
      cm.divideScalar(unit.positions.length);
      cm.normalize();
      const median = unit.positions[Math.floor(unit.positions.length / 2)].clone();
      unit.center = median.add(cm.multiplyScalar(UNIT_RADIUS));
    }
  });
  return units;
};

export const parseUnitDataFile = (text: string): UnitData => {
  const lines = text.trim().split(/\r?\n/);
  const unitData: UnitData = new Map();
  lines.forEach((line) => {
    const col = line.trim().split(',');
    const target: Set<UnitId> = new Set();
    for (let i = 3; i < col.length; ++i) {
      target.add(parseInt(col[i]));
    }
    const connection: Connections = {
      from: parseInt(col[0]),
      to: target,
    };
    unitData.set(connection.from, connection);
  });
  return unitData;
};
