import { SVGProps, memo } from 'react';

function SvgComponent({ className = '', ...rest }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      style={{ fill: 'currentColor' }}
      className={className}
      {...rest}
    >
      <path d='M16.6512 20V16.8372H8.59213C8.19272 16.8372 7.85465 16.6988 7.57792 16.4221C7.30117 16.1453 7.1628 15.8073 7.1628 15.4079V7.3488H4V6.16279H7.1628V3H8.34881V15.4079C8.34881 15.4687 8.37416 15.5244 8.42485 15.5751C8.47554 15.6258 8.53129 15.6512 8.59213 15.6512H21V16.8372H17.8372V20L16.6512 20ZM16.6512 14.4651V7.59212C16.6512 7.53129 16.6258 7.47553 16.5751 7.42485C16.5245 7.37415 16.4687 7.3488 16.4079 7.3488H9.53484V6.16279H16.4079C16.8073 6.16279 17.1454 6.30117 17.4221 6.57791C17.6988 6.85464 17.8372 7.19271 17.8372 7.59212V14.4651H16.6512Z' />
    </svg>
  );
}

const CropIcon = memo(SvgComponent);
export default CropIcon;
