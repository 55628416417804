import { MouseEventHandler, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, ListSubheader, Menu, MenuItem, Typography } from '@mui/material';
import { Stack } from '@mui/system';

import {
  DEFAULT_SORT_ORDER_OPTIONS,
  Order,
  SORTING_OPTIONS,
  SortingCriteria,
  gridViewEnabledSortingCriteria,
} from './constants';
import { theme } from '@modules/common/components/theme';
import { UserPreferenceName } from '@/modules/userPreferences';
import { useUpdateUserPreferences, useUserPreference } from '@/modules/userPreferences/hooks';

const listSubheaderStyles = {
  color: theme.palette.neutral.main,
  fontWeight: 400,
  fontSize: 10,
  lineHeight: '16px',
  p: 1,
};

const menuItemStyle = {
  fontWeight: 400,
  fontSize: 10,
  lineHeight: '16px',
  p: 1,
  pl: 2,

  '&:hover, &.Mui-selected': {
    backgroundColor: theme.palette.primary.light,
  },
};

const SortingMenuComponent = () => {
  const { t } = useTranslation();
  const { updateUserPreference } = useUpdateUserPreferences();
  const sortCriteria = useUserPreference(UserPreferenceName.FLOORPLAN_ADMIN_SORT_BY);
  const sortOrder = useUserPreference(UserPreferenceName.FLOORPLAN_ADMIN_SORT_ORDER);

  const updateSortCriteria: MouseEventHandler<HTMLLIElement> = useCallback(
    (e) => {
      const newVal = e.currentTarget.dataset.value as SortingCriteria;
      updateUserPreference(UserPreferenceName.FLOORPLAN_ADMIN_SORT_BY, newVal, false);
      if (sortOrder !== DEFAULT_SORT_ORDER_OPTIONS[newVal]) {
        updateUserPreference(
          UserPreferenceName.FLOORPLAN_ADMIN_SORT_ORDER,
          DEFAULT_SORT_ORDER_OPTIONS[newVal],
        );
      }
    },
    [updateUserPreference, sortOrder],
  );
  const updateSortOrder: MouseEventHandler<HTMLLIElement> = useCallback(
    (e) => {
      const newVal = e.currentTarget.dataset.value as Order;
      updateUserPreference(UserPreferenceName.FLOORPLAN_ADMIN_SORT_ORDER, newVal);
    },
    [updateUserPreference],
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <Stack alignItems='center' direction='row'>
        <Typography color={theme.palette.neutral.main} fontSize={10}>
          {t('common:sorting.sort')}
        </Typography>
        <Button
          onClick={handleClick}
          variant='text'
          component='button'
          sx={{
            textTransform: 'unset',
            p: 1,
            '&:hover': {
              backgroundColor: theme.palette.others.lighter,
              borderColor: theme.palette.others.lighter,
            },
            '&:active': {
              backgroundColor: theme.palette.others.medium,
              borderColor: theme.palette.others.medium,
            },
            '&.Mui-focused': {
              backgroundColor: theme.palette.others.medium,
              borderColor: theme.palette.others.medium,
            },
          }}
        >
          <Typography fontSize={10}>{t(SORTING_OPTIONS[sortCriteria].i18nKey)}</Typography>
        </Button>
      </Stack>
      <Menu
        anchorEl={anchorEl}
        id='sorting-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          sx: {
            overflow: 'visible',
            mt: '6px',
            minWidth: '164px',
            boxShadow: '0px 0px 8px 1px rgba(0, 0, 0, 0.2)',
            backgroundColor: theme.palette.others.lighter,
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <ListSubheader sx={listSubheaderStyles}>
          {t('common:sorting.sort_by', 'Sort by')}
        </ListSubheader>
        {Object.values(SORTING_OPTIONS)
          .filter((option) => gridViewEnabledSortingCriteria.includes(option.id))
          .map((item) => (
            <MenuItem
              key={item.id}
              data-value={item.id}
              onClick={updateSortCriteria}
              sx={menuItemStyle}
              selected={sortCriteria === item.id}
            >
              {t(item.i18nKey)}
            </MenuItem>
          ))}

        <Divider sx={{ margin: 0 }} />
        <ListSubheader sx={listSubheaderStyles}>{t('common:order', 'Order')}</ListSubheader>
        {Object.values(SORTING_OPTIONS[sortCriteria].orderOptions).map((item) => (
          <MenuItem
            key={item.id}
            data-value={item.id}
            onClick={updateSortOrder}
            sx={menuItemStyle}
            selected={item.id === sortOrder}
          >
            {t(item.i18nKey)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export const SortingMenu = memo(SortingMenuComponent);
