import { useEffect } from 'react';
import { useCanvasStore } from '@modules/canvas';

import { useTransformEventListeners } from './useTransformEventListeners';
import { useSelectEventListeners } from './useSelectEventListeners';
import { useZoomEventListeners } from '@modules/commissioning/hooks/useZoomEventListeners';

/**
 * Updates a canvas data source based on values from the stores
 */
export const useEvents = () => {
  const { onTransform, onTransformEnd, onTransformStart } = useTransformEventListeners();
  const { onSelected } = useSelectEventListeners();
  const { onCameraZoom } = useZoomEventListeners();

  useEffect(() => {
    const canvas = useCanvasStore.getState().instance;

    canvas
      .on('ElementEvents.TransformStart', onTransformStart)
      .on('ElementEvents.Transform', onTransform, { throttle: 50 })
      .on('ElementEvents.TransformEnd', onTransformEnd)
      .on('Canvas.Zoom', onCameraZoom, { throttle: 100 })
      .on('ElementEvents.Select', onSelected);
    return () => {
      canvas
        .off('ElementEvents.TransformStart', onTransformStart)
        .off('ElementEvents.Transform', onTransform)
        .off('ElementEvents.TransformEnd', onTransformEnd)
        .off('Canvas.Zoom', onCameraZoom)
        .off('ElementEvents.Select', onSelected);
    };
  }, [onTransform, onTransformEnd, onTransformStart, onSelected, onCameraZoom]);
};
