import { durationFromSeconds, durationToSeconds } from '@/modules/common/helpers/date';
import { useConfig } from '@/modules/common/hooks';
import { projectIdSelector } from '@/modules/floorplan';
import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import {
  CheckpointSet,
  DependentFlow,
  Flow,
  FmsConfig,
  OrderDistributionStrategy,
  ParkingAndChargingLocation,
  Simulation,
  SimulationConfiguration,
  SimulationDraft,
  SimulationError,
  SimulationErrorType,
  SimulationStatus,
  SimulationType,
} from '../helpers/types';
import { downloadFile } from '@/modules/common/helpers/browser';
import { useAxiosWithErrorNotifications } from '@/modules/api/hooks';

export const useSimulationApi = () => {
  const projectId = useRecoilValue(projectIdSelector);
  const { api } = useConfig();
  const scopes = useMemo(() => [api.simulation.scope], [api.simulation.scope]);

  const axios = useAxiosWithErrorNotifications(
    {
      baseURL: `${api.simulation.url}${projectId}`,
      scopes,
    },
    {
      serviceName: 'Simulation API',
      errorCode: (response) => response.data.errors[0].code,
      errorArgs: (response) => response.data.errors[0].args,
      errorCondition: (response) => response.data?.errors?.length > 0,
    },
  );

  const abortGroup = useCallback(
    (floorPlanId: string, simulationId: string) =>
      axios.post(`/floorPlan/${floorPlanId}/simulationGroup/${simulationId}/abort`),
    [axios],
  );

  const fetchGroup = useCallback(
    async (floorPlanId: string, simulationId: string, abortSignal?: AbortSignal) =>
      mapToSimulation(
        (
          await axios.get(`/floorPlan/${floorPlanId}/simulationGroup/${simulationId}`, {
            signal: abortSignal,
          })
        ).data,
      ),

    [axios],
  );

  const fetchGroupDetails = useCallback(
    async (floorPlanId: string, simulationId: string, abortSignal?: AbortSignal) =>
      mapToSimulationDetails(
        (
          await axios.get(`/floorPlan/${floorPlanId}/simulationGroup/${simulationId}/details`, {
            signal: abortSignal,
          })
        ).data,
      ),
    [axios],
  );

  const fetchAllGroups = useCallback(
    async (groupId: string): Promise<Simulation[]> =>
      (await axios.get(`/floorPlanGroup/${groupId}/simulationGroup`)).data.map(mapToSimulation),
    [axios],
  );

  const fetchAllSimulations = useCallback(
    async (floorPlanId: string, simulationId: string): Promise<Simulation[]> =>
      (
        await axios.get(`/floorPlan/${floorPlanId}/simulationGroup/${simulationId}/simulation`)
      ).data.map(mapToSimulation),
    [axios],
  );

  const fetchGroupConfiguration = useCallback(
    async (floorPlanId: string, simulationGroupId: string) =>
      mapToSimulationConfiguration(
        (
          await axios.get(
            `/floorPlan/${floorPlanId}/simulationGroup/${simulationGroupId}/configuration`,
          )
        ).data,
      ),
    [axios],
  );

  const removeGroup = useCallback(
    (floorPlanId: string, simulationId: string) =>
      axios.delete(`/floorPlan/${floorPlanId}/simulationGroup/${simulationId}`),
    [axios],
  );

  const renameGroup = useCallback(
    (floorPlanId: string, simulationId: string, name: string) =>
      axios.put(`/floorPlan/${floorPlanId}/simulationGroup/${simulationId}/name`, { name }),
    [axios],
  );

  const rerunGroup = useCallback(
    async (floorPlanId: string, simulationGroupId: string) =>
      mapToSimulation(
        (await axios.post(`/floorPlan/${floorPlanId}/simulationGroup/${simulationGroupId}/rerun`))
          .data,
      ),
    [axios],
  );

  const runGroup = useCallback(
    async (floorPlanId: string, draft: SimulationDraft) => {
      const response = await axios.post(
        `/floorPlan/${floorPlanId}/simulationGroup/`,
        mapToCreatePayload(draft),
      );

      return mapToSimulation(response.data);
    },
    [axios],
  );

  const downloadFMSConfig = useCallback(
    async (floorPlanId: string, draft: SimulationDraft) => {
      try {
        const response = await axios.post(
          `/floorPlan/${floorPlanId}/downloadFMSConfig`,
          mapToCreatePayload(draft),
          {
            responseType: 'blob',
          },
        );

        const jsonData = await response.data.text();
        const prettyJson = JSON.stringify(JSON.parse(jsonData), null, 2);

        const blob = new Blob([prettyJson], { type: 'application/json' });
        downloadFile(blob, '.json', `FMSConfig-${floorPlanId}.json`);
      } catch {
        // handled in useAxiosWithErrorNotifications
      }
    },
    [axios],
  );
  return {
    abortGroup,
    fetchGroup,
    fetchGroupDetails,
    fetchAllGroups,
    fetchAllSimulations,
    fetchGroupConfiguration,
    removeGroup,
    renameGroup,
    rerunGroup,
    runGroup,
    downloadFMSConfig,
  };
};

const mapToSimulation = (response: any): Simulation => {
  const errors: SimulationError[] =
    response.errors?.map((item) => ({
      code: item.code,
      args: item.args,
      type: mapErrorType(item.type),
    })) ?? [];

  return {
    id: response.id,
    name: response.name,
    status: mapStatus(response.status),
    created: new Date(response.created),
    simulationRunId: response.simulationRunId,
    generatedFloorPlanId: response.generatedFloorplanId,
    floorPlanId: response.floorPlanId,
    errors,
    results: response.results ? mapToSimulationResults(response.results) : null,
    details: {
      vehicleTypes: response.details.vehicleTypes,
    },
    trafficManagementDisabled: response.trafficManagementDisabled,
    failOnNoRouteFound: response.failOnNoRouteFound,
    manualIntervention: response.manualIntervention,
    obstructionTimeOutInSeconds: response.obstructionTimeOutInSeconds,
    orderDistributionStrategy: response.orderDistributionStrategy,
    isSelected: false,
    type:
      response.details.vehicleTypes.at(0).range.length > 1
        ? SimulationType.GROUP
        : SimulationType.SINGLE,
  };
};

const mapToSimulationResults = (response: any) => ({
  currentTime: durationFromSeconds(response.simulatedDuration ?? 0),
  endTime: durationFromSeconds(response.totalDuration ?? 0),
  dashboard: response.dashboardUrl && {
    url: response.dashboardUrl.url,
    urlExpirationDate: response.dashboardUrl.expires
      ? new Date(response.dashboardUrl.expires)
      : null,
  },
});

const mapToSimulationConfiguration = (response: any): SimulationConfiguration => ({
  duration: durationFromSeconds(response.duration),
  transportWindow: durationFromSeconds(response.transportWindow),
  vehicleTypes: response.vehicleTypes.map((item: any) => ({
    name: item.name,
    range: item.range,
    loadTime: item.loadTime,
    unloadTime: item.unloadTime,
    hue: item.hue,
  })),
  flows: response.flows.map(
    (item: any): Flow => ({
      id: item.intakeName + item.deliveryName,
      name: item.name,
      deliveryName: item.deliveryName,
      intakeName: item.intakeName,
      ordersPerHours: item?.ordersPerHours,
      loadsCount: item?.loadsCount, // should be removed (obsolete) when new Simulation.Api support orderprofile
      vehicleLimit: item.vehicleLimit,
    }),
  ),
  dependentFlows: response.dependentFlows,
  checkpointSets: response.checkpoints,
  chargingParkingPriority: response.chargingParkingPriority,
  trafficManagementDisabled: response.trafficManagementDisabled,
  failOnNoRouteFound: response.failOnNoRouteFound,
  manualIntervention: response.manualIntervention,
  obstructionTimeOutInSeconds: response.obstructionTimeOutInSeconds,
  orderDistributionStrategy: response.orderDistributionStrategy,
});

const mapToSimulationDetails = (
  response: any,
): {
  group: Simulation;
  simulations: Simulation[];
} => ({
  group: mapToSimulation(response.group),
  simulations: response.simulations.map(mapToSimulation),
});

const mapToCreatePayload = (simulation: SimulationDraft): Payload => ({
  name: simulation.name,
  duration: durationToSeconds(simulation.duration),
  transportWindow: durationToSeconds(simulation.transportWindow),
  flows: simulation.flows.map((item) => ({
    name: item.name,
    intakeName: item.intakeName,
    deliveryName: item.deliveryName,
    ordersPerHours: item.ordersPerHours,
    vehicleLimit: item.vehicleLimit,
  })),
  dependentFlows: simulation.dependentFlows,
  vehicleTypes: simulation.vehicleTypes.map((item) => ({
    name: item.name,
    range: item.range,
    loadTime: item.loadTime,
    unloadTime: item.unloadTime,
    hue: item.hue,
  })),
  checkpointSets: simulation.checkpointSets,
  parkingAndChargingLocations: simulation.chargingParkingPriority,
  dynamicSplines: simulation.dynamicSplines,
  trafficManagementDisabled: simulation.trafficManagementDisabled,
  failOnNoRouteFound: simulation.failOnNoRouteFound,
  manualIntervention: simulation.manualIntervention,
  obstructionTimeOutInSeconds: simulation.obstructionTimeOutInSeconds,
  orderDistributionStrategy: simulation.orderDistributionStrategy,
  generatedFloorplanId: simulation.generatedFloorPlanId,
  definition: simulation.definition,
  fmsConfig: simulation.fmsConfig,
});

const mapStatus = (status: string): SimulationStatus => {
  if (status.toLowerCase() === 'draft') {
    return SimulationStatus.DRAFT;
  }
  if (status.toLowerCase() === 'preparing') {
    return SimulationStatus.PREPARING;
  }
  if (status.toLowerCase() === 'aborted') {
    return SimulationStatus.ABORTED;
  }
  if (status.toLowerCase() === 'failed') {
    return SimulationStatus.FAILED;
  }
  if (status.toLowerCase() === 'scheduled') {
    return SimulationStatus.SCHEDULED;
  }
  if (status.toLowerCase() === 'running') {
    return SimulationStatus.RUNNING;
  }
  if (status.toLowerCase() === 'completed') {
    return SimulationStatus.COMPLETED;
  }
  if (status.toLowerCase() === 'partiallycompleted') {
    return SimulationStatus.PARTIALLY_COMPLETED;
  }
};

const mapErrorType = (type: string) => {
  if (type.toLowerCase() === 'general') {
    return SimulationErrorType.GENERAL;
  }
  if (type.toLowerCase() === 'runtime') {
    return SimulationErrorType.RUNTIME;
  }
  if (type.toLowerCase() === 'configuration') {
    return SimulationErrorType.CONFIGURATION;
  }
};

export type Payload = {
  name: string;
  duration: number;
  transportWindow: number;
  vehicleTypes: {
    name: string;
    range: number[];
    loadTime: number;
    unloadTime: number;
  }[];
  flows: {
    name: string;
    intakeName: string;
    deliveryName: string;
    ordersPerHours: {
      hour: number;
      loadsCount: number;
    }[];
  }[];
  dependentFlows: DependentFlow[];
  checkpointSets: CheckpointSet[];
  parkingAndChargingLocations: ParkingAndChargingLocation[];
  dynamicSplines?: boolean;
  trafficManagementDisabled?: boolean;
  failOnNoRouteFound?: boolean;
  manualIntervention?: boolean;
  obstructionTimeOutInSeconds?: number;
  orderDistributionStrategy?: OrderDistributionStrategy;
  generatedFloorplanId?: string;
  definition?: any;
  fmsConfig?: FmsConfig;
};
