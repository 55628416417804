import { Mode } from '@modules/referenceImage/types';

export const VIEWER_DIMENSION_CONVERSION_RATIO = 100;

export const DEFAULT_REFERENCE_IMAGE_SETTINGS = {
  cacheRef: '',
  mode: Mode.MOVE,
  file: null,
  imageName: null,
  x: 20,
  y: 20,
  width: 100,
  height: 100,
  opacity: 100,
  dist: 0,
  set: false,
  free: true,
  pointA: null,
  pointB: null,
  setPointA: null,
  setPointB: null,
  setDist: 0,
  original: {
    width: 100,
    height: 100,
  },
  crop: { x: 0, y: 0, width: 100, height: 100 },
  selected: false,
  pan: { x: 0, y: 0 },
  apsUrn: null,
  type: null,
};

export const SCALE_LABEL_X_OFFSET = 8;
export const SCALE_LABEL_Y_OFFSET = 24;
export const SCALE_LABEL_HEIGHT = 32;
