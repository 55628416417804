import { useRecoilState, useResetRecoilState } from "recoil";
import { dwgJustGotUploadedState } from "../store";

export const useDwgJustGotUploaded = () => {
  const [dwgJustGotUploaded, setDwgJustGotUploaded] = useRecoilState(dwgJustGotUploadedState);
  const resetNewDwgUploadIndicator = useResetRecoilState(dwgJustGotUploadedState);

  return {
    dwgJustGotUploaded,
    setDwgJustGotUploaded,
    resetNewDwgUploadIndicator,
  };
};
