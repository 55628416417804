import { selector } from 'recoil';
import { Position } from '@helpers/types';
import workspaceAtom, { WorkspaceAtom } from './atom';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../common';

export const initialMousePositionSelector = selector<Position>({
  key: 'initialMousePositionSelector',
  get: ({ get }) => get<WorkspaceAtom>(workspaceAtom).initialMousePosition,
  set: ({ get, set }, newValue) => {
    set(workspaceAtom, { ...get(workspaceAtom), initialMousePosition: newValue });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
