import { memo, SVGProps } from 'react';

function SvgComponent(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='11.642mm'
      height='13.128mm'
      version='1.1'
      viewBox='0 0 11.642 13.128'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g transform='translate(-64.449 -93.707)'>
        <g transform='matrix(.17779 0 0 .17779 58.01 76.038)'>
          <g transform='translate(-18.499 16.725)' fill='#0A0AA1'>
            <path
              transform='rotate(90)'
              d='m95.766-78.518v11.086h47.621v-11.086zm2 2h43.621v7.0859h-43.621z'
              color='#0A0AA1'
            />
            <path
              transform='scale(-1)'
              d='m-98.398-106.85v11.088h30.967v-11.088zm2 2h26.967v7.0879h-26.967z'
              color='#0A0AA1'
            />
            <path
              d='m107.48 104.85h-11.086v29.447h11.086zm-2 2v25.447h-7.0859v-25.447z'
              color='#0A0AA1'
            />
            <path
              transform='scale(-1)'
              d='m-98.398-143.39v11.086h30.967v-11.086zm2 2h26.967v7.0859h-26.967z'
              color='#0A0AA1'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

const AppLogoIcon = memo(SvgComponent);
export default AppLogoIcon;
