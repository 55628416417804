import { memo } from 'react';
import { Group } from 'react-konva';
import { useRecoilValue } from 'recoil';
import { LayerNames } from '@modules/common/types/layers';
import { layersLockSelector, layersShowSelector } from '@modules/layers';

import { allConnectionIds } from '../store';
import { ConnectionBubble } from './ConnectionBubble';

type Props = {
  saveFloorPlan: () => Promise<void>;
};

const ConnectionsGroupComponent = ({ saveFloorPlan }: Props) => {
  const show = useRecoilValue(layersShowSelector(LayerNames.CONNECTIONS));
  const locked = useRecoilValue(layersLockSelector(LayerNames.CONNECTIONS));
  const ids = useRecoilValue(allConnectionIds);

  if (!show || !ids?.length) return null;

  return (
    <Group listening={!locked}>
      {ids.map((id) => (
        <ConnectionBubble key={id} id={id} saveFloorPlan={saveFloorPlan} />
      ))}
    </Group>
  );
};

export const ConnectionsGroup = memo(ConnectionsGroupComponent);
