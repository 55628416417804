import {
  AUTOSCALE_DEFAULT,
  AUTOSCALE_MAX,
  AUTOSCALE_MIN,
  AUTOSCALE_NAME,
} from '@/modules/workspace/helpers/konva';

export const areaIconAutoScaleAttr = {
  Name: AUTOSCALE_NAME,
  [AUTOSCALE_MAX]: 4.0,
  [AUTOSCALE_MIN]: 0.05,
  [AUTOSCALE_DEFAULT]: 0.8,
};

export const ROW_GAP = 50;
