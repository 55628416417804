import { VehicleSpec } from '@/modules/floorplanService/helpers/mapping/types';
import {
  UnifiedVehicle,
  UnifiedVehicleDetails,
  FLOORPLAN_ASSET_FILE_CONTAINER,
  VehicleAssets,
} from '@/modules/vehicles';
import { buildAssetFileDownloadUrl } from '@/modules/vehicles/helpers/general';

export const vehiclesToVehicleSpecs = (
  vehicles: UnifiedVehicle[],
  vehiclesDetails: UnifiedVehicleDetails[],
  vehiclesAssets: VehicleAssets[],
  fileUrl,
): VehicleSpec[] =>
  vehicles.map((v): VehicleSpec => {
    const uvtDownloadUrl = buildAssetFileDownloadUrl(
      fileUrl,
      FLOORPLAN_ASSET_FILE_CONTAINER,
      vehiclesAssets.find((assets) => assets.vehicleVariantId === v.id)?.uvtReference,
    );

    if (!uvtDownloadUrl) {
      console.log('Could not provide uvtDownloadUrl to FPS. VehicleId: ', v.id);
    }

    const details = vehiclesDetails.find((item) => item.id === v.id);

    return {
      vehicleSpecId: v.id,
      databaseId: v.databaseId,
      name: v.name,
      width: details.width || 0,
      length: details.length || 0,
      pointCommsIdGeneratorBk: 'PointIdGen',
      connectionCommsIdGeneratorBk: 'ConnIdGen',
      uvtDownloadUrl,
      areas: [],
      endPointGenerationSettings: [],
      checkPointGenerationSettings: [],
      gateMappingSettings: {
        mapCheckPointTwinsInAllAreas: true,
        mapCheckPointNeighborsInAllAreas: true,
        areaMappings: [],
      },
      locationGroups: [],
      fixedEndPointGenerationSettings: [],
      locationAdjustments: [],
    };
  });
