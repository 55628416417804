import React from 'react';
import { Stack, Typography, IconButton, Tooltip } from '@mui/material';
import { DownloadIcon, ReplayIcon } from '@/assets/icons';
import { StyledTooltip } from '@/modules/common/components/StyledTooltip';
import { OverflowTypography } from '@/modules/common/components/OverflowTypography';

interface FileActionsProps {
  fileName: string;
  fileType: 'json' | 'mdb';
  onUpload: () => void;
  onDownload: () => void;
  onReset: () => void;
}

const FileActions: React.FC<FileActionsProps> = ({
  fileName,
  fileType,
  onUpload,
  onDownload,
  onReset,
}) => (
  <Stack direction='row' alignItems='center' justifyContent='space-between' gap={1}>
    <Stack direction='row' alignItems='center' maxWidth='70%' gap={1}>
      <StyledTooltip title='Revert' placement='bottom'>
        <IconButton onClick={onReset}>
          <ReplayIcon />
        </IconButton>
      </StyledTooltip>

      <OverflowTypography>
        {fileName}.{fileType}
      </OverflowTypography>
    </Stack>
    <Stack direction='row'>
      <Stack direction='row' alignItems='center' justifyContent='space-between' gap={1}>
        <StyledTooltip title='Upload' placement='bottom'>
          <IconButton onClick={onUpload}>
            <DownloadIcon sx={{ transform: 'rotate(180deg)' }} />
          </IconButton>
        </StyledTooltip>
        <input type='file' accept={`.${fileType}`} hidden />
      </Stack>
      <StyledTooltip title='Download' placement='bottom'>
        <IconButton onClick={onDownload}>
          <DownloadIcon />
        </IconButton>
      </StyledTooltip>
    </Stack>
  </Stack>
);

export default FileActions;
