import CircleIcon from '@mui/icons-material/Circle';
import { Button, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { selectedShapesIdsState } from '../../../../store/recoil/shapes/selected';
import { useZoomButton } from '../../../workspace/components/ZoomButton/useZoomButton';

export default function ValidationItem({ label, id }) {
  const { zoomFitShape } = useZoomButton();
  const setSelectedShapesIds = useSetRecoilState(selectedShapesIdsState);

  const handleClick = useCallback(
    (id) => {
      zoomFitShape(id);
      setSelectedShapesIds([id]);
    },
    [setSelectedShapesIds, zoomFitShape],
  );

  return (
    <Button
      onClick={() => handleClick(id)}
      disabled={id === null}
      key={label}
      variant='text'
      sx={{
        pl: 2,
        height: '40px',
        backgroundColor: 'warning.light',
        border: '1px solid',
        borderColor: 'warning.light',
        '&:disabled': {
          backgroundColor: 'warning.light',
          border: '1px solid',
          borderColor: 'warning.light',
        },
        '&:hover': {
          backgroundColor: 'warning.light',
          border: '1px solid',
          borderColor: 'warning.light'
        }
      }}
      className='validationMsg'
    >
      <CircleIcon
        sx={{ fontSize: '6px', color: 'warning.main', marginLeft: '9px', marginRight: '9px' }}
        fontSize='inherit'
      />
      <Typography
        sx={{
          marginLeft: '16px',
          marginRight: '50px',
          fontWeight: 400,
          fontSize: '10px',
          lineHeight: '160%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {label}
      </Typography>
    </Button>
  );
}
