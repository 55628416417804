import { Accordion } from '@common/components/Accordion';
import { InfoText } from '@common/components/InfoText';
import { Box, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import SelectedSimulationsInfo from './SelectedSimulationsInfo';

type Props = {
  ids: string[];
  hasRunningSimulations: boolean;
  renderCard: (id: string) => ReactNode;
};

const containerSx = {
  overflowY: 'scroll',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

export const SimulationList = ({ ids, hasRunningSimulations, renderCard }: Props) => {
  const { t } = useTranslation('interface');

  return (
    <>
      <Accordion title={t('simulation.simulation_list.header')} defaultExpanded sx={{ px: 2 }}>
        <SelectedSimulationsInfo sx={{ margin: '15px 0 20px' }} />
        {ids.length ? (
          <Stack sx={containerSx}>
            {hasRunningSimulations && (
              <Box sx={{ mt: 2, p: 2, backgroundColor: 'others.lighter' }}>
                <Typography fontSize='10px'>
                  {t('simulation.simulation_list.in_progress')}
                </Typography>
              </Box>
            )}
            {ids.map((id) => renderCard(id))}
          </Stack>
        ) : (
          <InfoText
            header={t('simulation.simulation_list.no_simulations.title')}
            subHeader={t('simulation.simulation_list.no_simulations.subtitle')}
            sx={{ mt: 4, mb: 4, ml: 2, mr: 2 }}
          />
        )}
      </Accordion>
    </>
  );
};
