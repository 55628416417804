import { StyledTooltip } from '@helpers/styles';
import { IconButton, Box } from '@mui/material';
import { AppLogoIcon } from '@/assets/icons';
import { useTranslation } from 'react-i18next';
import { useHome } from '@components/NavigationBar/Logo/useHome';

type Props = {
  displayTooltip?: boolean
}

export function Logo({ displayTooltip = true }: Props) {
  const { t } = useTranslation(['interface', 'common']);
  const { goHome } = useHome();

  return (
    displayTooltip ? (<StyledTooltip
      title={t('interface:tabs.dashboard.label', 'Go to My floorplans')}
      placement='bottom'
    >
      <IconButton
        sx={{
          border: 'none',
          borderRadius: 0,
          height: '47px',
          paddingRight: 0,
          '&:hover, &:active, &:focus': {
            backgroundColor: (theme) => theme.palette.shades.light,
          }

        }}
        onClick={goHome}
      >
        <AppLogoIcon width='24px' height='24px' />
      </IconButton>
    </StyledTooltip>) : (
      <Box sx={{
        height: '47px',
        p: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <AppLogoIcon style={{
          border: 'none',
          borderRadius: 0,
          width: '24px',
          height: '24px',
        }} />
      </Box>

    )

  );
}
