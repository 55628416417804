import { IconButton } from '@mui/material';
import { Stack, ThemeProvider } from '@mui/system';
import { useCallback, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { PreferenceIcon } from '@/assets/icons/PreferenceIcon';
import { RackPropertiesAdvanced } from '@/components/PropertiesPanel/layout/rack/RackPropertiesAdvanced';
import { PropertiesTitle } from '@/modules/common/components/PropertiesTitle';
import { StyledTooltip } from '@/modules/common/components/StyledTooltip';
import { moreMenuTheme } from '@/modules/common/components/theme';
import GenericRackProperties from './GenericRackProperties';

function RackProperties() {
  const { t } = useTranslation();
  const panelRef = useRef<HTMLDivElement>();
  const [isAdvancedSettingsOpen, setAdvancedSettingsOpen] = useState(false);
  const toggleAdvancedSettings = useCallback(
    () => setAdvancedSettingsOpen((current) => !current),
    [],
  );

  return (
    <>
      {isAdvancedSettingsOpen && (
        <RackPropertiesAdvanced anchor={panelRef} toggleOpen={toggleAdvancedSettings} isOpen />
      )}

      <Stack
        ref={panelRef}
        direction='row'
        justifyContent='space-between'
        gap='12px'
        alignItems='center'
        width='100%'
      >
        <PropertiesTitle
          fontSize={14}
          fontWeight={400}
          value={t('interface:properties.area.rack.label', 'Racking')}
        />

        <StyledTooltip
          placement='bottom'
          title={
            <Trans
              i18nKey='interface:properties.area.rack.racking_preferences'
              ns='interface'
              components={{
                s: <span />,
              }}
            />
          }
        >
          <ThemeProvider theme={moreMenuTheme}>
            <IconButton onClick={toggleAdvancedSettings} className='btn-icon'>
              <PreferenceIcon width='36px' height='36px' />
            </IconButton>
          </ThemeProvider>
        </StyledTooltip>
      </Stack>
      <GenericRackProperties />
    </>
  );
}

export default RackProperties;
