import { useRecoilCallback } from 'recoil';
import { Connection } from '@modules/common/types/connections';

import { allConnectionIds, allConnectionsSelector, updateConnectionsSelector } from '../store';

export type UndoRedoConnectionsState = {
  connections: Connection[];
};

export const useUndoRedoConnections = () => {
  const getConnectionDeleteState = useRecoilCallback(
    ({ snapshot }) =>
      async (
        ids: string[],
      ): Promise<{
        newState: UndoRedoConnectionsState;
        oldState: UndoRedoConnectionsState;
      }> => {
        const connections = await snapshot.getPromise(allConnectionsSelector);

        return {
          newState: { connections: connections.filter((item) => !ids.includes(item.id)) },
          oldState: { connections },
        };
      },
    [],
  );

  const restoreConnectionsState = useRecoilCallback(
    ({ snapshot, set }) =>
      async ({ connections }: UndoRedoConnectionsState) => {
        const existingIds = await snapshot.getPromise(allConnectionIds);
        const newIds = connections.map((item) => item.id);
        const idsToRemove = existingIds.filter((item) => !newIds.includes(item));

        set(updateConnectionsSelector, {
          connections,
          connectionIdsToRemove: new Set(idsToRemove),
        });
      },
    [],
  );

  return {
    getConnectionDeleteState,
    restoreConnectionsState,
  };
};
