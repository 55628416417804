import { selector } from 'recoil';
import { LoadCarrierType } from '@modules/common/types/floorPlan';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import { enabledLoadCarrierTypeAtomFamily, enabledLoadCarrierTypesIdsState } from './atom';

export const enabledLoadCarrierTypesSelector = selector<LoadCarrierType[]>({
  key: 'loadCarrierTypes/enabled',
  get: ({ get }) => {
    const loadTypes = [];
    const enabledLoadCarrierTypesIds: string[] = get(enabledLoadCarrierTypesIdsState);
    enabledLoadCarrierTypesIds.forEach((id) => {
      const item: LoadCarrierType = get(enabledLoadCarrierTypeAtomFamily(id));
      loadTypes.push(item);
    });

    return loadTypes;
  },
  set: ({ set }, newLoadTypes: LoadCarrierType[]) => {
    const newLoadTypeIds = [];

    newLoadTypes.forEach((item) => {
      set(enabledLoadCarrierTypeAtomFamily(item.id), item);
      newLoadTypeIds.push(item.id);
    });

    set(enabledLoadCarrierTypesIdsState, newLoadTypeIds);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
