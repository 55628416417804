/* eslint-disable default-case */
import { Vector2 } from 'three';
import { calculateOrientedBoundingBoxExtent } from '@/modules/common/helpers/boundingBox';
import { mod } from '@/modules/common/helpers/math';
import {
  DeadZone,
  Guide,
  ObjectSnappingEdges,
  Snap,
  SnapCollection,
  SnappingEdge,
  SnappingSide,
  SnappingState,
} from '@/modules/snapping/types';
import { BoundingBox, SizeShort, StageProps } from '@helpers/types';
import {
  checkForBoundingBoxCollision,
  convertToRelativeBoundingBox,
  pointsToUniquePoints,
} from '@helpers/utils';
import { getHighwayDirection } from '@modules/workspace/helpers/shape';
import { GUIDELINE_OFFSET } from './constants';
import { isHighwayShape } from '@/modules/common/types/guards';

export const findSnapPosition = (
  boundingBox: BoundingBox,
  snapCollections: SnapCollection[],
  sides: SnappingSide[],
  deadzone?: DeadZone,
  rotation = 0,
): Snap => {
  // find snapping points of current object
  const activeShapeSnaps = getObjectSnappingEdges(boundingBox, sides, rotation);

  // now find where can we snap current object
  const guides = getGuides(snapCollections, activeShapeSnaps, deadzone);
  if (!guides.length) {
    return null;
  }

  const snap: Snap = { x: boundingBox.x, y: boundingBox.y, lines: [] };
  setSnap(guides, snap);

  return snap;
};

export function getObjectSnappingEdges(
  boundingBox: BoundingBox,
  sides: SnappingSide[],
  rotation = 0,
): ObjectSnappingEdges {
  const absPos = { x: boundingBox.x, y: boundingBox.y };
  const vertical: SnappingEdge[] = [];
  const horizontal: SnappingEdge[] = [];
  boundingBox = calculateOrientedBoundingBoxExtent({ ...boundingBox, r: rotation });

  sides.forEach((side) => {
    if (side === 'all' || side === 'left') {
      vertical.push({
        guide: Math.round(boundingBox.x),
        offset: Math.round(absPos.x - boundingBox.x),
        snap: 'start',
        pos: { s: boundingBox.y, l: boundingBox.height },
      });
    }
    if (side === 'all' || side === 'right') {
      vertical.push({
        guide: Math.round(boundingBox.x + boundingBox.width),
        offset: Math.round(absPos.x - boundingBox.x - boundingBox.width),
        snap: 'end',
        pos: { s: boundingBox.y, l: boundingBox.height },
      });
    }
    if (side === 'all' || side === 'top') {
      horizontal.push({
        guide: Math.round(boundingBox.y),
        offset: Math.round(absPos.y - boundingBox.y),
        snap: 'start',
        pos: { s: boundingBox.x, l: boundingBox.width },
      });
    }
    if (side === 'all' || side === 'bot') {
      horizontal.push({
        guide: Math.round(boundingBox.y + boundingBox.height),
        offset: Math.round(absPos.y - boundingBox.y - boundingBox.height),
        snap: 'end',
        pos: { s: boundingBox.x, l: boundingBox.width },
      });
    }
    if (side === 'all') {
      vertical.push({
        guide: Math.round(boundingBox.x + boundingBox.width / 2),
        offset: Math.round(absPos.x - boundingBox.x - boundingBox.width / 2),
        snap: 'center',
        pos: { s: boundingBox.y, l: boundingBox.height },
      });
      horizontal.push({
        guide: Math.round(boundingBox.y + boundingBox.height / 2),
        offset: Math.round(absPos.y - boundingBox.y - boundingBox.height / 2),
        snap: 'center',
        pos: { s: boundingBox.x, l: boundingBox.width },
      });
    }
  });

  return {
    vertical,
    horizontal,
  };
}

// find all snapping possibilities
export function getGuides(
  snapCollections: SnapCollection[],
  activeShapeSnaps: ObjectSnappingEdges,
  deadzone?: DeadZone,
): Guide[] {
  const resultV: Array<Guide & { diff: number }> = [];
  const resultH: Array<Guide & { diff: number }> = [];
  const guides: Guide[] = [];

  snapCollections.forEach((snapCollection) => {
    if (snapCollection.vertical) {
      activeShapeSnaps.vertical.forEach((activeShapeSnap) => {
        const diff = Math.abs(snapCollection.guide - activeShapeSnap.guide);

        if (deadzone && Math.abs(snapCollection.guide - deadzone.startVert) <= deadzone.lengthVert)
          return;

        let dynamicOffset = verticalDynamicOffset(snapCollection, activeShapeSnap);
        // if the distance between guide line and object snap point is close we can consider this for snapping
        if (diff >= dynamicOffset) return;

        resultV.push({
          lineGuide: snapCollection.guide,
          diff,
          snap: activeShapeSnap.snap,
          offset: activeShapeSnap.offset,
          orientation: 'V',
          line: {
            x1: snapCollection.guide,
            y1: minPos(snapCollection, activeShapeSnap),
            x2: snapCollection.guide,
            y2: maxPos(snapCollection, activeShapeSnap),
            points: pointsToUniquePoints([
              new Vector2(snapCollection.guide, snapCollection.pos.s),
              new Vector2(snapCollection.guide, snapCollection.pos.s + snapCollection.pos.l),
              new Vector2(snapCollection.guide, activeShapeSnap.pos.s),
              new Vector2(snapCollection.guide, activeShapeSnap.pos.s + activeShapeSnap.pos.l),
            ]),
          },
        });
      });
    } else {
      activeShapeSnaps.horizontal.forEach((activeShapeSnap) => {
        const diff = Math.abs(snapCollection.guide - activeShapeSnap.guide);

        if (deadzone && Math.abs(snapCollection.guide - deadzone.startHori) <= deadzone.lengthHori)
          return;

        let dynamicOffset = horizontalDynamicOffset(snapCollection, activeShapeSnap);
        if (diff >= dynamicOffset) return;

        resultH.push({
          lineGuide: snapCollection.guide,
          diff,
          snap: activeShapeSnap.snap,
          offset: activeShapeSnap.offset,
          orientation: 'H',
          line: {
            x1: minPos(snapCollection, activeShapeSnap),
            y1: snapCollection.guide,
            x2: maxPos(snapCollection, activeShapeSnap),
            y2: snapCollection.guide,
            points: pointsToUniquePoints([
              new Vector2(snapCollection.pos.s, snapCollection.guide),
              new Vector2(snapCollection.pos.s + snapCollection.pos.l, snapCollection.guide),
              new Vector2(activeShapeSnap.pos.s, snapCollection.guide),
              new Vector2(activeShapeSnap.pos.s + activeShapeSnap.pos.l, snapCollection.guide),
            ]),
          },
        });
      });
    }
  });

  // find closest snap
  // eslint-disable-next-line prefer-destructuring
  const minV = resultV.sort((a, b) => a.diff - b.diff)[0];
  // eslint-disable-next-line prefer-destructuring
  const minH = resultH.sort((a, b) => a.diff - b.diff)[0];
  if (minV) {
    guides.push({
      lineGuide: minV.lineGuide,
      offset: minV.offset,
      orientation: minV.orientation,
      snap: minV.snap,
      line: minV.line,
    });
  }
  if (minH) {
    guides.push({
      lineGuide: minH.lineGuide,
      offset: minH.offset,
      orientation: minH.orientation,
      snap: minH.snap,
      line: minH.line,
    });
  }

  return guides;
}

export function setSnap(guides: Guide[], snap: Snap): Snap {
  guides.forEach((lg) => {
    switch (lg.snap) {
      case 'start': {
        switch (lg.orientation) {
          case 'V': {
            snap.x = lg.lineGuide + lg.offset;
            snap.lines.push(lg.line);
            break;
          }
          case 'H': {
            snap.y = lg.lineGuide + lg.offset;
            snap.lines.push(lg.line);
            break;
          }
        }
        break;
      }
      case 'center': {
        switch (lg.orientation) {
          case 'V': {
            snap.x = lg.lineGuide + lg.offset;
            snap.lines.push(lg.line);
            break;
          }
          case 'H': {
            snap.y = lg.lineGuide + lg.offset;
            snap.lines.push(lg.line);
            break;
          }
        }
        break;
      }
      case 'end': {
        switch (lg.orientation) {
          case 'V': {
            snap.x = lg.lineGuide + lg.offset;
            snap.lines.push(lg.line);
            break;
          }
          case 'H': {
            snap.y = lg.lineGuide + lg.offset;
            snap.lines.push(lg.line);
            break;
          }
        }
        break;
      }
    }
  });

  return snap;
}

function minPos(lineGuide: SnapCollection, itemBound: SnappingEdge) {
  return Math.min(
    lineGuide.pos.s,
    lineGuide.pos.s + lineGuide.pos.l,
    itemBound.pos.s,
    itemBound.pos.s + itemBound.pos.l,
  );
}

function maxPos(lineGuide: SnapCollection, itemBound: SnappingEdge) {
  return Math.max(
    lineGuide.pos.s,
    lineGuide.pos.s + lineGuide.pos.l,
    itemBound.pos.s,
    itemBound.pos.s + itemBound.pos.l,
  );
}

function verticalDynamicOffset(lineGuide: SnapCollection, itemBound: SnappingEdge) {
  if (
    isHighwayShape(lineGuide.shape) &&
    getHighwayDirection(lineGuide.shape.properties.width, lineGuide.shape.properties.height) ===
      'updown'
  ) {
    const otherShapeProps = lineGuide.shape.properties;
    const itemBoundingBox = {
      width: 1,
      height: itemBound.pos.l,
      x: itemBound.guide,
      y: itemBound.pos.s,
    };

    const otherBox = {
      x: otherShapeProps.x / 10,
      y: otherShapeProps.y / 10,
      width: otherShapeProps.width / 10,
      height: otherShapeProps.height / 10,
    };

    if (checkForBoundingBoxCollision(otherBox, itemBoundingBox)) {
      if (lineGuide.snap === 'center') {
        return -1;
      }
      return otherShapeProps.width / 20;
    }
  }

  return GUIDELINE_OFFSET;
}

export function horizontalDynamicOffset(lineGuide: SnapCollection, itemBound: SnappingEdge) {
  if (
    isHighwayShape(lineGuide.shape) &&
    getHighwayDirection(lineGuide.shape.properties.width, lineGuide.shape.properties.height) ===
      'leftright'
  ) {
    const otherShapeProps = lineGuide.shape.properties;
    const itemBoundingBox = {
      width: itemBound.pos.l,
      height: 1,
      x: itemBound.pos.s,
      y: itemBound.guide,
    };

    const otherBox = {
      x: otherShapeProps.x / 10,
      y: otherShapeProps.y / 10,
      width: otherShapeProps.width / 10,
      height: otherShapeProps.height / 10,
    };

    if (checkForBoundingBoxCollision(otherBox, itemBoundingBox)) {
      if (lineGuide.snap === 'center') {
        return -1;
      }
      return otherShapeProps.height / 20;
    }
  }

  return GUIDELINE_OFFSET;
}

export function calculateGuideLines(
  ids: string[],
  shapes: any[],
  stageProps: StageProps,
  viewport: SizeShort,
): SnapCollection[] {
  const snapCollections: SnapCollection[] = [];

  const viewportBox = convertToRelativeBoundingBox(
    {
      x: 0,
      y: 0,
      width: viewport.w,
      height: viewport.h,
    },
    stageProps,
  );

  // and we snap over edges and center of each object on the canvas
  shapes.forEach((otherShape) => {
    const boundingBox: BoundingBox = calculateOrientedBoundingBoxExtent({
      x: otherShape.properties.x / 10,
      y: otherShape.properties.y / 10,
      width: otherShape.properties.width / 10,
      height: otherShape.properties.height / 10,
      r: otherShape.properties.r,
    });

    // only snap if the shape is in view
    if (checkForBoundingBoxCollision(boundingBox, viewportBox)) {
      if (!ids.includes(otherShape.id)) {
        snapCollections.push({
          vertical: true,
          shape: otherShape,
          guide: boundingBox.x,
          snap: 'start',
          pos: { s: boundingBox.y, l: boundingBox.height },
        });
        snapCollections.push({
          vertical: true,
          shape: otherShape,
          guide: boundingBox.x + boundingBox.width / 2,
          snap: 'center',
          pos: { s: boundingBox.y, l: boundingBox.height },
        });
        snapCollections.push({
          vertical: true,
          shape: otherShape,
          guide: boundingBox.x + boundingBox.width,
          snap: 'end',
          pos: { s: boundingBox.y, l: boundingBox.height },
        });
        snapCollections.push({
          vertical: false,
          shape: otherShape,
          guide: boundingBox.y,
          snap: 'start',
          pos: { s: boundingBox.x, l: boundingBox.width },
        });
        snapCollections.push({
          vertical: false,
          shape: otherShape,
          guide: boundingBox.y + boundingBox.height / 2,
          snap: 'center',
          pos: { s: boundingBox.x, l: boundingBox.width },
        });
        snapCollections.push({
          vertical: false,
          shape: otherShape,
          guide: boundingBox.y + boundingBox.height,
          snap: 'end',
          pos: { s: boundingBox.x, l: boundingBox.width },
        });
      }
    }
  });

  return snapCollections;
}

export const getSingleDrawingSide = (
  boundingBox: BoundingBox,
  previousBoundingBox: BoundingBox,
  rotation: number,
): SnappingSide => {
  const deltaX = Math.abs(boundingBox.x - previousBoundingBox.x);
  const deltaY = Math.abs(boundingBox.y - previousBoundingBox.y);
  const deltaW = Math.abs(boundingBox.width - previousBoundingBox.width);
  const deltaH = Math.abs(boundingBox.height - previousBoundingBox.height);

  if ((deltaX > 0.1 && deltaW > 0.1) || (deltaY > 0.1 && deltaW > 0.1)) {
    return angleToSide(mod(rotation + 90, 360));
  }
  if ((deltaY > 0.1 && deltaH > 0.1) || (deltaX > 0.1 && deltaH > 0.1)) {
    return angleToSide(mod(rotation + 180, 360));
  }
  if (deltaW > 0.1) {
    return angleToSide(mod(rotation + 270, 360));
  }
  if (deltaH > 0.1) {
    return angleToSide(mod(rotation + 0, 360));
  }

  return 'all';
};

const angleToSide = (angle: number) => {
  switch (angle) {
    case 90:
      return 'left';
    case 270:
      return 'right';
    case 180:
      return 'top';
    case 0:
      return 'bot';
    default:
      return 'all';
  }
};

export const getMultipleDrawingSides = (
  boundingBox: BoundingBox,
  previousBoundingBox: BoundingBox,
): SnappingSide[] => {
  const sides: SnappingSide[] = [];

  if (Math.abs(boundingBox.x - previousBoundingBox.x) > 0.001) {
    sides.push('left');
  } else if (Math.abs(boundingBox.width - previousBoundingBox.width) > 0.001) {
    sides.push('right');
  }

  if (Math.abs(boundingBox.y - previousBoundingBox.y) > 0.001) {
    sides.push('top');
  } else if (Math.abs(boundingBox.height - previousBoundingBox.height) > 0.001) {
    sides.push('bot');
  }

  if (sides.length === 0) sides.push('all');

  return sides;
};

export const createSnapShape = (
  sides: SnappingSide[],
  newPosition: Snap,
  boundingBox: BoundingBox,
  rotation: number,
): SnappingState => {
  rotation = mod(rotation, 360);

  if (rotation === 0) {
    return {
      x: sides.includes('right') ? boundingBox.x : newPosition.x,
      y: sides.includes('bot') ? boundingBox.y : newPosition.y,
      width: sides.includes('left')
        ? boundingBox.width - newPosition.x + boundingBox.x
        : sides.includes('right')
        ? boundingBox.width + newPosition.x - boundingBox.x
        : boundingBox.width,
      height: sides.includes('top')
        ? boundingBox.height - newPosition.y + boundingBox.y
        : sides.includes('bot')
        ? boundingBox.height + newPosition.y - boundingBox.y
        : boundingBox.height,
      offsetX: newPosition.x - boundingBox.x,
      offsetY: newPosition.y - boundingBox.y,
      lines: newPosition.lines,
      r: rotation,
    };
  }
  if (rotation === 90) {
    return {
      x: sides.includes('right') ? newPosition.x : boundingBox.x,
      y: sides.includes('top') ? newPosition.y : boundingBox.y,
      width: sides.includes('top')
        ? boundingBox.width - newPosition.y + boundingBox.y
        : sides.includes('bot')
        ? boundingBox.width + newPosition.y - boundingBox.y
        : boundingBox.width,
      height: sides.includes('left')
        ? boundingBox.height - newPosition.x + boundingBox.x
        : sides.includes('right')
        ? boundingBox.height + newPosition.x - boundingBox.x
        : boundingBox.height,
      offsetX: newPosition.x - boundingBox.x,
      offsetY: newPosition.y - boundingBox.y,
      lines: newPosition.lines,
      r: rotation,
    };
  }
  if (rotation === 180) {
    return {
      x: sides.includes('right') ? newPosition.x : boundingBox.x,
      y: sides.includes('bot') ? newPosition.y : boundingBox.y,
      width: sides.includes('left')
        ? boundingBox.width - newPosition.x + boundingBox.x
        : sides.includes('right')
        ? boundingBox.width + newPosition.x - boundingBox.x
        : boundingBox.width,
      height: sides.includes('top')
        ? boundingBox.height - newPosition.y + boundingBox.y
        : sides.includes('bot')
        ? boundingBox.height + newPosition.y - boundingBox.y
        : boundingBox.height,
      offsetX: newPosition.x - boundingBox.x,
      offsetY: newPosition.y - boundingBox.y,
      lines: newPosition.lines,
      r: rotation,
    };
  }
  if (rotation === 270) {
    return {
      x: sides.includes('right') ? boundingBox.x : newPosition.x,
      y: sides.includes('top') ? boundingBox.y : newPosition.y,
      width: sides.includes('top')
        ? boundingBox.width - newPosition.y + boundingBox.y
        : sides.includes('bot')
        ? boundingBox.width + newPosition.y - boundingBox.y
        : boundingBox.width,
      height: sides.includes('left')
        ? boundingBox.height - newPosition.x + boundingBox.x
        : sides.includes('right')
        ? boundingBox.height + newPosition.x - boundingBox.x
        : boundingBox.height,
      offsetX: newPosition.x - boundingBox.x,
      offsetY: newPosition.y - boundingBox.y,
      lines: newPosition.lines,
      r: rotation,
    };
  }

  return null;
};
