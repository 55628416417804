import { LaneLeftIcon, LaneLeftRightIcon, LaneRightIcon } from '@/assets/icons';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { HighwayDirection, LaneDirection as HighwayLaneDirection } from '@modules/common/types/shapes';
import { highwayDirectionSelector, highwayLaneDirection } from '../store/highway';
import { useCallback } from 'react';
import { useAutoSave } from '../../../modules/floorplan';

function LaneDirection() {
  const mode = useRecoilValue(modeSelector);
  const direction = useRecoilValue(highwayDirectionSelector);
  const [laneDirection, setLineDirection] = useRecoilState(highwayLaneDirection);
  const { save } = useAutoSave();

  const onChange = useCallback(
    (_, value) => {
      if (value != null) {
        setLineDirection(value);
        save();
      }
    },
    [setLineDirection, save],
  );

  const iconSx = {
    transform: direction === HighwayDirection.LEFT_RIGHT ? 'rotate(0deg)' : 'rotate(270deg)',
  };

  return (
    <ToggleButtonGroup
      value={laneDirection}
      exclusive
      onChange={onChange}
      disabled={mode !== WorkspaceMode.EDITABLE}
    >
      <ToggleButton value={HighwayLaneDirection.LEFT_RIGHT} aria-label='Left right'>
        <LaneLeftRightIcon />
      </ToggleButton>
      <ToggleButton value={HighwayLaneDirection.LEFT} aria-label='Left'>
        <LaneLeftIcon sx={iconSx} />
      </ToggleButton>
      <ToggleButton value={HighwayLaneDirection.RIGHT} aria-label='Right'>
        <LaneRightIcon sx={iconSx} />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export default LaneDirection;
