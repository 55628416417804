import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import { simulationAtom } from './simulationAtom';

export const nameSelector = selector<string>({
  key: 'simulations/draft/name',
  get: ({ get }) => get(simulationAtom).name,
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
