import {  useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import { formControlClasses, inputClasses, selectClasses } from '@mui/material';
import { Stack } from '@mui/system';
import { t } from 'i18next';

import { SelectInput } from '@/modules/common/components/inputs';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { useFloorPlanState } from '@modules/floorplan';
import { areaLoadPlacement } from '../store/area';
import { AreaLoadPlacement, ShapeType } from '@/modules/common/types/shapes';
import { useUpdateUserPreferences } from '@/modules/userPreferences/hooks';
import { UserPreferenceName } from '@/modules/userPreferences';
import { selectedShapesState } from '@/store/recoil/shapes/selected';

export const LoadPlacement = () => {
  const [loadPlacement, setLoadPlacement] = useRecoilState(areaLoadPlacement);
  const { saveFloorPlan } = useFloorPlanState();
  const mode = useRecoilValue(modeSelector);
  const { updateUserPreference } = useUpdateUserPreferences();

  const handleChange = useRecoilCallback(
    ({ snapshot }) =>
      async (newValue: AreaLoadPlacement) => {
        setLoadPlacement(newValue);
        saveFloorPlan();

        const selectedShapes = await snapshot.getPromise(selectedShapesState);

        switch (selectedShapes[0].type) {
          case ShapeType.HANDOVER:
            updateUserPreference(UserPreferenceName.HANDOVER_LOAD_PLACEMENT, newValue);
            break;
          case ShapeType.PROCESS_ONE_EP:
          case ShapeType.PROCESS_ONE_EP_POSITION:
          case ShapeType.PROCESS_TWO_EP:
            updateUserPreference(UserPreferenceName.PROCESS_LOAD_PLACEMENT, newValue);
            break;
          default:
        }
      },
    [saveFloorPlan, updateUserPreference, setLoadPlacement],
  );

  return (
    <Stack sx={containerSx}>
      <SelectInput
        disabled={mode !== WorkspaceMode.EDITABLE}
        options={[
          {
            label: t('interface:properties.area.load_placement.on_vehicle'),
            value: AreaLoadPlacement.ON,
          },
          {
            label: t('interface:properties.area.load_placement.off_vehicle'),
            value: AreaLoadPlacement.OFF,
          },
        ]}
        onChange={handleChange}
        value={loadPlacement}
      />
    </Stack>
  );
};

const containerSx = {
  // Wrap value-string to new line, don't truncate.
  [`& .${selectClasses.multiple}`]: {
    whiteSpace: 'normal!important',
  },

  // because value-string is wrapped, correct the height and padding of the options container.
  [`& .${formControlClasses.root} .${inputClasses.root}`]: {
    height: 'auto',
    padding: '4px 12px!important',
  },
};
