import { isPointsShape, isProcessAreaTwoEp } from '@/modules/common/types/guards';
import { useFloorPlanState } from '@/modules/floorplan/hooks/useFloorPlanState';
import { calculateShapesBoundingBox } from '@modules/common/helpers/shapes';
import { ShapeProperties, shapePropertyCanvas } from '@recoil/shape';
import { selectedShapesState } from '@recoil/shapes/selected';
import { useCallback, useRef } from 'react';
import { useRecoilCallback, useSetRecoilState } from 'recoil';

import { useConnections } from '@/modules/connections';
import { BoundingBoxMap } from '@helpers/types';
import { PreValidationAspect, useRunPreValidation } from '@modules/floorplanValidation/clientSide';
import { AlignMode } from '@modules/workspace/types/align';
import { HistoryManager } from '@recoil/history';
import { shapesProperty } from '@recoil/shape/shapesProperty';
import { SHAPE_TO_CANVAS_SCALE } from '@/modules/workspace/helpers/konva';

export const useAlign = () => {
  const setShapesProperty = useSetRecoilState(shapesProperty);
  const { updateConnections } = useConnections();
  const { saveFloorPlan } = useFloorPlanState();
  const { runPreValidation } = useRunPreValidation();
  const previousBoundingBoxMap = useRef<BoundingBoxMap>(new Map());

  const setPreviousBoundingBox = useCallback(
    (selectedShapes) => {
      selectedShapes.forEach((selectedShape) => {
        previousBoundingBoxMap.current.set(selectedShape.id, {
          x: selectedShape.properties.x * SHAPE_TO_CANVAS_SCALE,
          y: selectedShape.properties.y * SHAPE_TO_CANVAS_SCALE,
          width: selectedShape.properties.width * SHAPE_TO_CANVAS_SCALE,
          height: selectedShape.properties.height * SHAPE_TO_CANVAS_SCALE,
        });
      });
    },
    [previousBoundingBoxMap],
  );

  const trackHistory = useCallback(
    (currState: BoundingBoxMap, prevState: BoundingBoxMap, set) => {
      HistoryManager.track(`shape moved`, currState, prevState, (historyState: BoundingBoxMap) => {
        const newBoundingBoxMap: BoundingBoxMap = new Map();
        historyState.forEach((boundingBox, shapeId) => {
          set(shapePropertyCanvas(shapeId), (prop) => ({
            ...prop,
            ...boundingBox,
          }));
          newBoundingBoxMap.set(shapeId, boundingBox);
        });
        updateConnections([...newBoundingBoxMap.keys()]);
      });
    },
    [updateConnections],
  );

  const align = useRecoilCallback(
    ({ snapshot, set }) =>
      async (mode: AlignMode) => {
        const newBoundingBoxMap: BoundingBoxMap = new Map();
        const updatedShapeProperties: {id: string, props: ShapeProperties}[] = [];
        const selectedShapes = [...(await snapshot.getPromise(selectedShapesState))];
        if (selectedShapes.length === 0) return;

        const box = calculateShapesBoundingBox(selectedShapes);
        setPreviousBoundingBox(selectedShapes);

        // TODO: support alignment of angled highways

        switch (mode) {
          case AlignMode.TOP:
            selectedShapes.forEach((shape) => {
              if (isPointsShape(shape)) return;
              // TODO: process two ep. alignment
              if (isProcessAreaTwoEp(shape)) return;

              updatedShapeProperties.push({
                id: shape.id,
                props: { ...shape.properties, y: box.y },
              });
            });
            break;
          case AlignMode.MIDDLE:
            selectedShapes.forEach((shape) => {
              if (isPointsShape(shape)) return;
              // TODO: process two ep. alignment
              if (isProcessAreaTwoEp(shape)) return;

              updatedShapeProperties.push({
                id: shape.id,
                props: {
                  ...shape.properties,
                  y: box.y + box.height / 2 - shape.properties.height / 2,
                },
              });
            });
            break;
          case AlignMode.BOTTOM:
            selectedShapes.forEach((shape) => {
              if (isPointsShape(shape)) return;
              // TODO: process two ep. alignment
              if (isProcessAreaTwoEp(shape)) return;

              updatedShapeProperties.push({
                id: shape.id,
                props: { ...shape.properties, y: box.y + box.height - shape.properties.height },
              });
            });
            break;
          case AlignMode.LEFT:
            selectedShapes.forEach((shape) => {
              if (isPointsShape(shape)) return;
              // TODO: process two ep. alignment
              if (isProcessAreaTwoEp(shape)) return;

              updatedShapeProperties.push({
                id: shape.id,
                props: { ...shape.properties, x: box.x },
              });
            });
            break;
          case AlignMode.CENTER:
            selectedShapes.forEach((shape) => {
              if (isPointsShape(shape)) return;
              // TODO: process two ep. alignment
              if (isProcessAreaTwoEp(shape)) return;

              updatedShapeProperties.push({
                id: shape.id,
                props: {
                  ...shape.properties,
                  x: box.x + box.width / 2 - shape.properties.width / 2,
                },
              });
            });
            break;
          case AlignMode.RIGHT:
            selectedShapes.forEach((shape) => {
              if (isPointsShape(shape)) return;
              // TODO: process two ep. alignment
              if (isProcessAreaTwoEp(shape)) return;

              updatedShapeProperties.push({
                id: shape.id,
                props: { ...shape.properties, x: box.x + box.width - shape.properties.width },
              });
            });
            break;
          default:
            break;
        }

        setShapesProperty(updatedShapeProperties);

        updatedShapeProperties.forEach((shape) => {
          newBoundingBoxMap.set(shape.id, {
            x: shape.props.x * SHAPE_TO_CANVAS_SCALE,
            y: shape.props.y * SHAPE_TO_CANVAS_SCALE,
            width: shape.props.width * SHAPE_TO_CANVAS_SCALE,
            height: shape.props.height * SHAPE_TO_CANVAS_SCALE,
          });
        });

        updateConnections([...newBoundingBoxMap.keys()]);
        trackHistory(newBoundingBoxMap, previousBoundingBoxMap.current, set);
        previousBoundingBoxMap.current = new Map();

        saveFloorPlan();
        runPreValidation([
          PreValidationAspect.REQUIRED_ELEMENTS,
          PreValidationAspect.FLOWLESS_AREAS,
        ]);
      },
    [
      setPreviousBoundingBox,
      setShapesProperty,
      updateConnections,
      trackHistory,
      saveFloorPlan,
      runPreValidation,
    ],
  );

  return {
    align,
  };
};
