import { OperationCode } from '@/modules/commissioning/components/gate/OperationCode';
import { useGateStore } from '@/modules/commissioning/store';
import { PropertiesTitle } from '@/modules/common/components/PropertiesTitle';
import { Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { AdjustPosition } from './AdjustPosition';
import { GateList } from './GateList';
import { StationPosition } from './StationPosition';
import { ImportExport } from './ImportExport';

const nothingSelectedSx = {
  display: 'flex',
  padding: '46px 16px 46px 16px',
  height: '113px',
  justifyContent: 'center',
};

export const Gates = () => {
  const { t } = useTranslation('interface');
  const id = useGateStore((state) => state.currentId);
  const name = useGateStore((state) => state.currentShapeName);
  const title = id ? name : t('commissioning.gate.none_selected');

  return (
    <Stack divider={<Divider />}>
      <PropertiesTitle
        value={title}
        fontSize={14}
        editable={false}
        lineHeight={id ? '1.5rem' : '1rem'}
        sx={id ? { pb: 2, pt: 2, px: 2 } : nothingSelectedSx}
      />
      <Stack
        divider={<Divider />}
        sx={{
          overflowY: 'scroll',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {id && <StationPosition />}
        {id && <AdjustPosition />}
        <GateList />
        <OperationCode />
        <ImportExport />
      </Stack>
    </Stack>
  );
};
