import cloneDeep from 'clone-deep';
import { useRecoilCallback } from 'recoil';
import { Vector2 } from 'three';

import { ControlPoint } from '@/modules/common/types/shapes';
import { getOrderedControlPoints } from '@/modules/common/helpers/shapes';
import { useConnections } from '@/modules/connections';
import { AngledHighwayShape } from '@modules/angledHighways/types';
import shapeAtom from '@recoil/shape/atom';

export const useControlPointCallbacks = () => {
  const { updateConnections } = useConnections();

  const updateControlPointPos = useRecoilCallback(
    ({ set }) =>
      (highwayId: string, controlPointId: string, newPosition: Vector2) => {
        set(shapeAtom(highwayId), (current: AngledHighwayShape) => {
          const newCPoints = getOrderedControlPoints(current.properties.controlPoints).map((cP) =>
            cP.id === controlPointId
              ? {
                  ...cP,
                  position: newPosition.round(),
                }
              : cP,
          );

          return {
            ...current,
            properties: {
              ...current.properties,
              controlPoints: newCPoints,
            },
          };
        });
      },
    [],
  );

  const insertControlPoint = useRecoilCallback(
    ({ set }) =>
      (
        highwayId: string,
        controlPointId: string,
        prevId: string,
        nextId: string,
        position: Vector2,
      ) => {
        // make new control point
        const newControlPoint: ControlPoint = {
          id: controlPointId,
          prev: prevId,
          next: nextId,
          position,
        };

        set(shapeAtom(highwayId), (current: AngledHighwayShape) => {
          const newCPoints = getOrderedControlPoints(current.properties.controlPoints);
          const indexOfPreviousCP = newCPoints.findIndex((cp) => cp.id === newControlPoint.prev);

          // copy the preceeding and following cp
          const prevCPCopy = cloneDeep(newCPoints[indexOfPreviousCP]);
          const nextCPCopy = cloneDeep(newCPoints[indexOfPreviousCP + 1]);

          // update the copies
          prevCPCopy.next = newControlPoint.id;
          nextCPCopy.prev = newControlPoint.id;

          newCPoints.splice(indexOfPreviousCP, 2, prevCPCopy, newControlPoint, nextCPCopy);

          return {
            ...current,
            properties: {
              ...current.properties,
              controlPoints: newCPoints,
            },
          };
        });
        updateConnections([highwayId]);
      },
    [updateConnections],
  );

  return {
    updateControlPointPos,
    insertControlPoint,
  };
};
