import { MenuItem, Stack, ThemeProvider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useVersioningCallbacks } from '../hooks';
import { moreMenuTheme } from '@/modules/common/components/theme';
import { Menu } from '@/modules/simulation/components/Menu';

type Props = {
  id: string;
};

export const TopButtons = ({ id }: Props) => {
  const { t } = useTranslation('interface');
  const { changeVersion } = useVersioningCallbacks();
  const onLoadClick = useCallback(async () => changeVersion(id), [id, changeVersion]);

  return (
    <Stack
      sx={{
        alignItems: 'center',
        flexDirection: 'row',
        height: '100%',
        justifyContent: 'flex-end',
        gap: 1,
      }}
    >
      <ThemeProvider theme={moreMenuTheme}>
        <Menu>
          <MenuItem onClick={onLoadClick}>{t('versioning.version_card.load')}</MenuItem>
        </Menu>
      </ThemeProvider>
    </Stack>
  );
};
