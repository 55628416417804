import * as React from 'react';

function VisibilityOff({ className = '', ...rest }) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      style={{ fill: 'currentColor' }}
      className={className}
      {...rest}
    >
      <path d='M15.45 12.65L14.65 11.85C14.8 10.9833 14.5543 10.229 13.913 9.58695C13.271 8.94562 12.5166 8.69995 11.65 8.84995L10.85 8.04995C11.0166 7.98328 11.1916 7.93328 11.375 7.89995C11.5583 7.86662 11.7666 7.84995 12 7.84995C13.0166 7.84995 13.8793 8.20395 14.588 8.91195C15.296 9.62062 15.65 10.4833 15.65 11.5C15.65 11.7333 15.6333 11.9456 15.6 12.137C15.5666 12.329 15.5166 12.5 15.45 12.65ZM18.6 15.725L17.85 15.05C18.4833 14.5666 19.0459 14.0373 19.538 13.462C20.0293 12.8873 20.4499 12.2333 20.7999 11.5C19.9666 9.81662 18.7706 8.47895 17.212 7.48695C15.654 6.49562 13.9166 5.99995 12 5.99995C11.5166 5.99995 11.0416 6.03328 10.575 6.09995C10.1083 6.16662 9.64995 6.26662 9.19995 6.39995L8.42495 5.62495C9.00829 5.40828 9.59995 5.24995 10.2 5.14995C10.8 5.04995 11.4 4.99995 12 4.99995C14.1666 4.99995 16.15 5.59562 17.95 6.78695C19.75 7.97895 21.0749 9.54995 21.9249 11.5C21.5583 12.3 21.104 13.054 20.562 13.762C20.0206 14.4706 19.3666 15.125 18.6 15.725ZM19.725 21.15L15.825 17.25C15.3916 17.45 14.846 17.625 14.188 17.775C13.5293 17.925 12.8 18 12 18C9.81662 18 7.83328 17.404 6.04995 16.212C4.26662 15.0206 2.94162 13.45 2.07495 11.5C2.45828 10.6166 2.97095 9.78728 3.61295 9.01195C4.25428 8.23728 4.93328 7.59162 5.64995 7.07495L2.84995 4.27495L3.57495 3.57495L20.4249 20.4249L19.725 21.15ZM6.34995 7.77495C5.78328 8.19162 5.19995 8.73328 4.59995 9.39995C3.99995 10.0666 3.53328 10.7666 3.19995 11.5C4.03328 13.1833 5.22895 14.5206 6.78695 15.512C8.34562 16.504 10.0833 17 12 17C12.5833 17 13.1666 16.9456 13.75 16.837C14.3333 16.729 14.775 16.6166 15.075 16.5L13.425 14.85C13.275 14.9333 13.0583 15.004 12.775 15.062C12.4916 15.1206 12.2333 15.15 12 15.15C10.9833 15.15 10.121 14.7956 9.41295 14.087C8.70428 13.379 8.34995 12.5166 8.34995 11.5C8.34995 11.2833 8.37928 11.0373 8.43795 10.762C8.49595 10.4873 8.56662 10.2583 8.64995 10.075L6.34995 7.77495Z' />
    </svg>
  );
}

const VisibilityOffIcon = React.memo(VisibilityOff);
export default VisibilityOffIcon;
