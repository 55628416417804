import { Duration } from '@/modules/common/helpers/date';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';
import { selector } from 'recoil';

import { simulationAtom } from './simulationAtom';

export const durationSelector = selector<Duration>({
  key: 'simulations/draft/duration',
  get: ({ get }) => get(simulationAtom)?.duration,
  set: ({ set }, duration: Duration) =>
    set(simulationAtom, (state) => ({
      ...state,
      duration,
      transportWindow: duration,
    })),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
