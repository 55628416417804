import { referenceSelected } from '@modules/referenceImage/store';
import { Divider, Stack } from '@mui/material';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { FilePanel } from './FilePanel';
import { ScalePanel } from './ScalePanel';

export function PropertyPanel() {
  const setReferenceSelected = useSetRecoilState(referenceSelected);

  useEffect(
    () => () => {
      setReferenceSelected(false);
    },
    [setReferenceSelected],
  );

  return (
    <Stack
      gap={2}
      divider={<Divider />}
      data-testid='ReferenceProperties'
      sx={{ '&:hover': { cursor: 'default'} }}
    >
      <FilePanel />
      <ScalePanel />
    </Stack>
  );
}
