import { Stack } from '@mui/system';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { GapHorizontalIcon, GapVerticalIcon } from '@/assets/icons';
import {
  roadMinGapSelector,
  roadMinRoutingPointGroupGapSelector,
} from '@/components/PropertiesPanel/store/highway';
import { convertMetricUnit } from '@/helpers/units/metric';
import { HIGHWAY_MIN_GAP, HIGHWAY_ROUTING_POINT_GROUP_MIN_GAP_MIN } from '@/modules/common/constants/shapes';
import { modeSelector } from '@/modules/common/store/workspace';
import { Unit, WorkspaceMode } from '@/modules/common/types/general';
import { useAutoSave } from '@/modules/floorplan';
import { UserPreferenceName } from '@/modules/userPreferences';
import { useUpdateUserPreferences } from '@/modules/userPreferences/hooks';
import { unitSelector } from '@/store/recoil/workspace';
import { NumberInput } from '@common/components/inputs';
import { CONTEXT, contextState } from '@recoil/input';

function HighwayGap() {
  const [minGap, setMinGap] = useRecoilState(roadMinGapSelector);
  const [groupGap, setGroupGap] = useRecoilState(roadMinRoutingPointGroupGapSelector);
  const unit = useRecoilValue(unitSelector);
  const mode = useRecoilValue(modeSelector);
  const { save } = useAutoSave();
  const { updateUserPreference } = useUpdateUserPreferences();
  const disabled = mode !== WorkspaceMode.EDITABLE;
  const setContext = useSetRecoilState(contextState);
  const { t } = useTranslation(['interface']);

  const onMinGapChange = useCallback(
    (value: number) => {
      if (value >= convertMetricUnit(HIGHWAY_MIN_GAP, Unit.MILLIMETERS, unit)) {
        setMinGap(value);
        save();
        updateUserPreference(UserPreferenceName.HIGHWAY_GAP, value);
      }
    },
    [unit, setMinGap, save, updateUserPreference],
  );

  const onGroupGapChange = useCallback(
    (value: number) => {
      if (
        value >= convertMetricUnit(HIGHWAY_ROUTING_POINT_GROUP_MIN_GAP_MIN, Unit.MILLIMETERS, unit)
      ) {
        setGroupGap(value);
        save();
      }
    },
    [unit, setGroupGap, save],
  );

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      spacing={1}
      sx={{ width: '100%' }}
    >
      <NumberInput
        value={minGap}
        disabled={disabled}
        prefix={<GapVerticalIcon />}
        onFocus={() => setContext(CONTEXT.PROPERTYPANEL)}
        onBlur={() => setContext(CONTEXT.WORKSPACE)}
        onChange={onMinGapChange}
        tooltip={t('properties.highway.tooltips.gap')}
      />
      <NumberInput
        value={groupGap}
        disabled={disabled}
        prefix={<GapHorizontalIcon />}
        onFocus={() => setContext(CONTEXT.PROPERTYPANEL)}
        onBlur={() => setContext(CONTEXT.WORKSPACE)}
        onChange={onGroupGapChange}
        tooltip={t('properties.highway.tooltips.routing_point_group_min_gap')}
      />
    </Stack>
  );
}

export default HighwayGap;
