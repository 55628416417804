import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { Trans } from 'react-i18next';

type Props = {
  i18nKey: string;
  value: number | string;
  unit?: string;
};

export const SpecificationItem = ({ i18nKey, value, unit }: Props) => (
  <Stack direction='row' alignItems='baseline'>
    <Trans
      i18nKey={i18nKey}
      values={{
        value: value.toLocaleString(),
        unit,
      }}
      components={{
        name: <Typography fontWeight='600' whiteSpace='pre-wrap' />,
        value: <Typography color='neutral.main' />,
      }}
    />
  </Stack>
);
