import { IconButton, Stack } from '@mui/material';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';

import { RemoveIcon } from '@/assets/icons';
import VehicleLimit from '@/components/PropertiesPanel/layout/VehicleLimit';
import { PropertiesLabel } from '@/modules/common/components/PropertiesLabel';
import { StyledTooltip } from '@/modules/common/components/StyledTooltip';
import { modeSelector } from '@/modules/common/store/workspace';
import { WorkspaceMode } from '@/modules/common/types/general';
import { FlowDirection } from '@/modules/flows/components';
import { layoutFlowsSelector } from '@/modules/flows/store/layout';
import { PropertiesTitle } from '@common/components/PropertiesTitle';
import { useFloorPlanState } from '@modules/floorplan/hooks/useFloorPlanState';
import { CONTEXT, contextState } from '@recoil/input';
import { useFlowUpdaters } from '../common/hooks';
import { getScopedFlowSelector } from '../helpers';
import { useFlow } from '../hooks/useFlow';
import { useSimulationFlows } from '../simulation/hooks';
import { flowInfoIdSelector } from '../store/flowInfoId';
import { FlowScope } from '../types';
import { FlowGraph } from './FlowGraph';
import { LoadPerHourInput } from './LoadPerHourInput';

type Props = {
  id: string;
  tooltipTitle: string;
  flowScope: FlowScope;
  onDelete?: () => void;
};

const FlowComponent = ({ id, tooltipTitle, flowScope, onDelete }: Props) => {
  const flowSelector = getScopedFlowSelector(flowScope);
  const { name, sourceName, targetName, totalNumLoads, loadProfile, vehicleLimit } = useRecoilValue(
    flowSelector(id),
  );
  const setContext = useSetRecoilState(contextState);
  const { removeSimulationFlow } = useSimulationFlows();
  const setFlowInfoId = useSetRecoilState(flowInfoIdSelector);
  const { saveFloorPlan } = useFloorPlanState();
  const { deleteFlows } = useFlow();
  const { updateFlow } = useFlowUpdaters(id, flowScope);
  const mode = useRecoilValue(modeSelector);
  const disabled = mode !== WorkspaceMode.EDITABLE;
  const { onFlowVehicleLimitChange } = useFlowUpdaters(id, flowScope);
  const { t } = useTranslation();

  const onRemoveClick = useCallback(() => {
    if (flowScope === FlowScope.LAYOUT) {
      setFlowInfoId((currentValue) => (currentValue === id ? null : currentValue));
      deleteFlows([id]);
      saveFloorPlan();
    } else if (flowScope === FlowScope.SIMULATION) {
      removeSimulationFlow(id);
    }
    if (onDelete) onDelete();
  }, [flowScope, onDelete, setFlowInfoId, deleteFlows, id, saveFloorPlan, removeSimulationFlow]);

  const onNameChange = useRecoilCallback(
    ({ snapshot }) =>
      async (value: string) => {
        if (!value) {
          return { error: t('errors:flow.name_required') };
        }

        const flows = await snapshot.getPromise(layoutFlowsSelector);
        const unique = flows.filter((item) => item.id !== id).every((item) => item.name !== value);
        if (!unique) {
          return { error: t('errors:flow.name_duplicated') };
        }

        updateFlow({ name: value });
      },
    [t],
  );

  return (
    <Stack spacing={1} sx={{ flexDirection: 'column', mb: 2 }}>
      <Stack direction='row' width='100%' alignItems='center'>
        <PropertiesTitle
          editable={!disabled}
          fontSize={14}
          value={name}
          sx={{ flex: '1 1 auto' }}
          onChange={onNameChange}
          onFocus={() => setContext(CONTEXT.PROPERTYPANEL)}
          onBlur={() => setContext(CONTEXT.WORKSPACE)}
        />
        <StyledTooltip title={tooltipTitle} disabled={disabled}>
          <IconButton
            onClick={onRemoveClick}
            sx={{ marginY: 'auto', p: 0, flex: '0 0 auto' }}
            disabled={disabled}
          >
            <RemoveIcon />
          </IconButton>
        </StyledTooltip>
      </Stack>
      <FlowDirection sourceName={sourceName} targetName={targetName} />
      {!loadProfile || loadProfile.length === 0 ? (
        <PropertiesLabel
          labelRatio={70}
          i18nkey='interface:properties.flows.flow_info.total_loads.label'
        >
          <LoadPerHourInput
            id={id}
            flowScope={flowScope}
            totalNumLoads={totalNumLoads}
            disabled={disabled}
          />
        </PropertiesLabel>
      ) : (
        <FlowGraph loadProfile={loadProfile} flowScope={flowScope} />
      )}
      <PropertiesLabel labelRatio={70} skipFocus i18nkey='interface:properties.highway.vehicles'>
        <VehicleLimit vehicleLimit={vehicleLimit} onChange={onFlowVehicleLimitChange} />
      </PropertiesLabel>
    </Stack>
  );
};

export const Flow = memo(FlowComponent);
