import { useRecoilCallback } from 'recoil';

import { LoadingType } from '../types/general';
import { loadingAtom } from '../store/loading/loadingAtom';

export const useLoading = () => {
  const showLoader = useRecoilCallback(
    ({ set }) =>
      (type: LoadingType = LoadingType.OPAQUE) => {
        set(loadingAtom, (state) => ({
          ...state,
          isLoading: true,
          loadingType: type,
        }));
      },
    [],
  );

  const hideLoader = useRecoilCallback(
    ({ set }) =>
      () => {
        set(loadingAtom, (state) => ({
          ...state,
          isLoading: false,
          initialLoading: false,
        }));
      },
    [],
  );

  return {
    showLoader,
    hideLoader,
  };
};
