import { useFlow } from '@/modules/flows/hooks/useFlow';
import { flow } from '@/modules/flows/store/layout/atom';
import selectedShapesIdsState from '@/store/recoil/shapes/selected/selectedShapesIdsState';
import { Divider, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { areaPalletCountInfo } from '../../store/area';
import { ContentRow } from './ContentRow';

function LoadContent() {
  const selectedIds = useRecoilValue(selectedShapesIdsState);
  const palletInfo = useRecoilValue(areaPalletCountInfo);
  const { getRelatedFlowIdsOfShapes } = useFlow();

  const [orderPerHour, setOrderPerHour] = useState<number>(0);

  const calculateOrderPerHour = useRecoilCallback(
    ({ snapshot }) =>
      async (shapeIds: string[]) => {
        const flowIds = await getRelatedFlowIdsOfShapes(shapeIds);
        if (flowIds.length === 0) {
          setOrderPerHour(0);
          return;
        }
        const flows = await Promise.all(flowIds.map((id) => snapshot.getPromise(flow(id))));
        const total = flows
          .map((flow) => flow.totalNumLoads)
          .reduce((sum, current) => sum + current);
        setOrderPerHour(total);
      },
    [setOrderPerHour],
  );

  useEffect(() => {
    if (selectedIds.length === 0) setOrderPerHour(0);
    else calculateOrderPerHour(selectedIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIds]);

  if (!palletInfo) return null;

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='stretch'
      sx={{ mt: 2, height: '100%' }}
    >
      <Stack
        direction='column'
        alignItems='start'
        justifyContent='space-between'
        sx={{ width: '50%' }}
        spacing={1}
      >
        <ContentRow label='Total' value={palletInfo.total} unit='' />
        <ContentRow label='Lanes' value={palletInfo.columns} unit='' />
      </Stack>

      <Divider sx={{ ml: 2, mr: 2 }} orientation='vertical' flexItem />

      <Stack
        direction='column'
        alignItems='start'
        justifyContent='space-between'
        sx={{ width: '50%' }}
        spacing={1}
      >
        <ContentRow label='Per lane' value={palletInfo.rows} unit='' />
        <ContentRow label='Orders/h' value={orderPerHour} unit='' />
      </Stack>
    </Stack>
  );
}

export default LoadContent;
