import { memo } from 'react';

import { Visibility, VisibilityOff } from '@/assets/icons';
import { theme } from '@common/components/theme';
import IconButton from '@mui/material/IconButton';

type VisibilityBtnProps = {
  onClick: () => void;
  eyeCrossed: boolean;
};

const VisibilityBtnComponent = memo(({ onClick, eyeCrossed }: VisibilityBtnProps) => (
  <IconButton
    onClick={onClick}
    className='btn-icon'
    sx={{
      padding: 0,
      color: eyeCrossed ? theme.palette.disabled.main : theme.palette.neutral.darker,
      borderRadius: '4px',
      '&:hover': {
        color: theme.palette.neutral.darker,
      },
    }}
  >
    {eyeCrossed ? (
      <VisibilityOff data-testid='Eye Crossed icon' />
    ) : (
      <Visibility data-testid='Eye Detail icon' />
    )}
  </IconButton>
));

export const VisibilityBtn = memo(VisibilityBtnComponent);
