import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@recoil/common';

import { simulationAtom } from './simulationAtom';

export const existsSelector = selector<boolean>({
  key: 'simulations/draft/exists',
  get: ({ get }) => !!get(simulationAtom),
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
