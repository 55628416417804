import { atom } from 'recoil';
import { PreValidationMeta } from '../../types';

export const preValidationMetaState = atom({
  key: 'preValidationStatus',
  default: <PreValidationMeta>{
    show: false,
    valid: true,
  },
});
