import { SvgIcon } from '@mui/material';
import { memo } from 'react';

function SvgComponent({ className = '', ...rest }) {
  return (
    <SvgIcon viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' className={className} {...rest}>
      <rect y='0.5' width='24' height='24' rx='4' fill='none' stroke='none' />
      <path d='M11.5 19V13H5.5V12H11.5V6H12.5V12H18.5V13H12.5V19H11.5Z' fill='currentColor' />
    </SvgIcon>
  );
}

const AddIcon = memo(SvgComponent);
export default AddIcon;
