import { DebugPanel } from '@/modules/debug';
import { closeModalSelector } from '@modules/common/store/modal';
import { Dialog } from '@mui/material';
import { Box } from '@mui/system';
import { useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { MultiCopyModal } from './modals/MultiCopyModal';

export function ModalManager() {
  const [modal, closeModalAction] = useRecoilState(closeModalSelector);
  const showModal = useMemo(() => modal && modal.length > 0, [modal]);

  const closeModalCallback = useCallback(() => {
    closeModalAction(modal[0].id);
  }, [closeModalAction, modal]);

  return (
    <>
      <DebugPanel />
      <Dialog open={showModal} onBackdropClick={closeModalCallback}>
        <Box sx={{ padding: '1rem' }}>
          {showModal && modal[0].type === 'MULTI_COPY' && (
            <MultiCopyModal closeModal={closeModalCallback} />
          )}
        </Box>
      </Dialog>
    </>
  );
}
