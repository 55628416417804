export enum FloorplanServiceOption {
  Artefacts,
  Partial,
  TStackExport,
  Maestro,
  Validate,
  Splines,
  All,
}

export enum Status {
  Done,
  Error,
  WaitingForResponse,
  WaitingToExecute,
  Idle,
}
