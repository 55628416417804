import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '@/store/recoil/common';
import { enabledVehicleIdsState } from './atom';
import { vehicleLengthSelector } from '../vehicleLengthSelector';

export const enabledVehiclesLengthSelector = selector<number>({
  key: 'vehicles/enabled/length',
  get: ({ get }) => {
    const lengths = get(enabledVehicleIdsState).map((id) => get(vehicleLengthSelector(id)));
    return Math.max(...lengths);
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});
